export default {
  signup: {
    application: {
      header: {
        title: 'Firmenkredit anfragen',
        description:
          'Stellen Sie in vier einfachen Schritten eine unverbindliche Anfrage für Ihren Firmenkredit. Das dauert nur wenige Minuten und funktioniert komplett online.',
      },
      form: {
        creditSection: {
          title: '1. Kreditwunsch',
          para1:
            'Einfach Ihre gewünschte Kreditsumme und die Laufzeit eingeben. Mit der Einschätzung Ihrer Bonität bekommen Sie dann schon einen ersten Eindruck des möglichen Zinssatzes und der Höhe der monatlichen Rate.',
          fields: {
            purpose: {
              label: 'Verwendungszweck',
              placeHolder: '– Bitte auswählen –',
            },
          },
        },
        companySection: {
          title: '2. Firmeninformationen',
          para1:
            'Wir brauchen noch ein paar Informationen zu Ihrem Unternehmen.',
          para2:
            'Wenn Sie Ihren Firmennamen eingeben und aus der Liste auswählen, wird das Anfrageformular <em>automatisch mit Ihren Firmendaten vervollständigt</em>. So sparen Sie noch mehr Zeit.',
          fields: {
            purpose: {
              label: 'Verwendungszweck',
              placeHolder: '– Bitte auswählen –',
            },
          },
        },
        representativeSection: {
          title: '3. Vertretungs{shyCharacter}berechtigte/-r',
          para1:
            'Um eine Kreditanfrage zu stellen, müssen Sie Vertretungsberechtigte/-r oder Inhaber/-in der Firma sein.',
          para2:
            'Als Vertretungsberechtigte werden derzeit <em>nur Geschäftsführer/-innen oder Vorstandsmitglieder</em> akzeptiert. Eine Anfrage durch Prokurist/-innen und sonstige Bevollmächtigte ist leider nicht möglich.',
          fields: {
            emailInfo:
              'Keine Sorge, wir nutzen Ihre E-Mail-Adresse, um Ihnen wichtige Unterlagen zu Ihrem Produkt zu senden und Sie über unsere Services zu informieren. Dem können Sie jederzeit per E-Mail an <a href="mailto: info@ing-diba.de">info@ing-diba.de</a> widersprechen.',
          },
        },
        termsSection: {
          title: '4. Kreditanfrage abschicken',
          para1: {
            description:
              'Mit dem Klicken auf „{boldContent}” bestätige ich,  dass ich sowie alle (weiteren) im Handelsregister eingetragenen Geschäftsführer/-innen und Gesellschafter/-innen und wirtschaftlich Berechtigte im Sinne des § 3 Abs. 1 GwG - Geldwäschegesetz die {linkText} zur Kenntnis genommen haben.',
            boldContent: 'Firmenkredit anfragen',
            linkText: 'Datenschutzerklärung',
          },
          para2: {
            description:
              'Zur Bereitstellung der sog. Autofill-Funktion sowie zur Durchführung einer Vorabprüfung erhält die ING Informationen von der Creditreform. Zur Beurteilung der Kreditwürdigkeit holt die ING Auskünfte bei Creditreform und SCHUFA ein. Hierfür ist die Übermittlung personenbezogener Daten an {linkText1} und {linkText2} erforderlich.',
            linkText1: 'Creditreform',
            linkText2: 'SCHUFA',
          },
        },
      },
    },
    success: {
      header: {
        title: 'Vielen Dank für Ihre Anfrage',
        description:
          'Wir bestätigen Ihre Finanzierungsanfrage noch einmal in einer separaten E-Mail und informieren Sie über die nächsten Schritte.',
      },
      details: {
        title: 'Ihre Daten auf einen Blick:',
      },
      nextSteps: {
        title: 'Und so geht’s weiter',
        step1: {
          title: 'Schritt 1',
          text: 'Schicken Sie uns bitte folgende Unterlagen per E-Mail an {linkText}:',
          linkText: 'business@ing.de',
          list: {
            item1: {
              text: '{boldContent} inkl. aller Kontennachweise. Wenn zu Jahresbeginn für das letzte Geschäftsjahr keine finalen Dokumente vorliegen, bitte stattdessen Ihre Betriebswirtschaftliche Auswertung mit Summen- und Saldenliste',
              boldContent: 'Jahresabschlüsse der letzten zwei Geschäftsjahre',
            },
            item2: {
              text: '{boldContent} mit Summen- und Saldenliste, nicht älter als drei Monate.',
              boldContent:
                'Betriebswirtschaftliche Auswertung des aktuellen Geschäftsjahres',
            },
            item3: {
              text: '{boldContent} der letzten 90 Tage.',
              boldContent: 'Kontoauszüge',
            },
          },
        },
        step2: {
          title: 'Schritt 2',
          text: 'Sobald Ihre Unterlagen vollständig sind, senden wir Ihnen {0} einen {1} und unverbindlichen {2}.',
          boldContents: [
            'in wenigen Tagen',
            'kostenfreien',
            'Finanzierungsvorschlag',
          ],
        },
        step3: {
          title: 'Schritt 3',
          text: 'Wenn Sie diesen annehmen, {0} Sie sich noch kurz und {1}.',
          boldContents: ['identifizieren', 'unterschreiben den Kreditvertrag'],
        },

        step4: {
          title: 'Schritt 4',
          text: 'Dann wird das {boldContent} überwiesen.',
          boldContent: 'Geld direkt auf Ihr Konto',
        },
        step5: {
          title: 'Schritt 5',
          text: 'Parallel erhalten Sie Ihre Zugangsdaten zum {boldContent}, dem Kundenportal der ING. Hier können Sie Ihren Kredit bequem verwalten und haben jederzeit einen Überblick über Ihre Buchungen.',
          boldContent: 'Business Banking Home',
        },
        dataSection: [
          {
            title: 'Kreditdetails',
            dataList: {
              amount: 'Kreditbetrag:',
              years: 'Laufzeit:',
              duration: '{n} Jahr | {n} Jahre',
              firstPayout: 'Erste Auszahlung',
              purpose: 'Verwendungszweck:',
            },
          },
          {
            title: 'Firmeninformationen',
            dataList: {
              companyName: 'Firmenname:',
              address: 'Adresse:',
              foundedDate: 'Gründungsdatum:',
              revenue: 'Umsatz des letzten Jahres:',
              legalForm: 'Rechtsform:',
              billingMethod: 'Bilanzierungsmethode:',
              iban: 'IBAN:',
              bic: 'BIC:',
            },
          },
          {
            title: 'Vertretungsberechtigte/-r',
            dataList: {
              gender: 'Anrede:',
              firstName: 'Vorname:',
              lastName: 'Nachname:',
              address: 'Adresse:',
              dateOfBirth: 'Geburtsdatum:',
              nationality: 'Staatsangehörigkeit:',
              mobile: 'Mobilfunknummer:',
              email: 'E-Mail-Adresse:',
            },
          },
        ],
      },
      amazon: {
        nextSteps: {
          step1: {
            title: 'Schritt 1',
            text: 'Wenn die {0} durch unsere Kreditexperten {1} ausfällt, schicken wir Ihnen innerhalb von {2} einen {3} und {4}. {5}',
            boldContents: [
              'Bonitätsprüfung',
              'positiv',
              '48 Stunden',
              'kostenfreien',
              'unverbindlichen Finanzierungsvorschlag',
            ],
            flexkreditExtraText:
              'Hier können Sie auch Ihren gewünschten Auszahlungsbetrag der Höhe nach festlegen.',
          },
          step2: {
            title: 'Schritt 2',
            text: 'Wenn Sie den {0}, brauchen Sie sich nur noch zu identifizieren und den {1} zu {2}.',
            boldContents: [
              'Finanzierungsvorschlag annehmen',
              'Kreditvertrag',
              'unterschreiben',
            ],
          },
          step3: {
            title: 'Schritt 3',
            text: 'Passt alles, überweisen wir die von Ihnen festgelegte {0} innerhalb von {1} direkt {2}.',
            boldContents: ['Kreditsumme', 'wenigen Tagen', 'auf Ihr Konto'],
            flexkreditBoldContent: 'Auszahlungssumme',
          },
          step4: {
            title: 'Schritt 4',
            flexkredit: {
              text: 'Parallel erhalten Sie Ihre Zugangsdaten zum {0}, unserem Online-Banking für Geschäftskundinnen und -kunden. Hier können Sie Ihren {1} und weitere Auszahlungen vornehmen.',
              boldContents: [
                'Business Banking Home',
                'Flexkredit bequem verwalten',
              ],
            },
            firmenkredit: {
              text: 'Parallel erhalten Sie Ihre Zugangsdaten zum {0}, unserem Online-Banking für Geschäftskundinnen und -kunden. Hier können Sie Ihren {1} und haben jederzeit einen Überblick über Ihre Buchungen.',
              boldContents: [
                'Business Banking Home',
                'Kredit bequem verwalten',
              ],
            },
          },
          returnButton: 'Zurück zum Seller Central',
        },
      },
    },
  },
};
