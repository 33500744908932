<template>
  <Modal ref="authenticationModal" :showCloseBtn="false" :closeOnEscape="false">
    <form class="ing-form" @submit.prevent="handleSubmit()">
      <div class="ing-form__message">
        <h3 class="auth-form--title">
          {{ authMessage.label }}
        </h3>
        <p class="auth-form--explanation">
          {{ authMessage.message }}
        </p>
      </div>
      <div class="ing-form__fields" :class="{ hidden: isBlockedOrExpired }">
        <TextInput
          v-model="password"
          :isPassword="true"
          :label="$t('auth.form.password.label')"
          :error="errorMessage.message"
          :displayErrorIfDirty="submitClicked"
          inputmode="password"
          name="password"
          @input="clearErrorMessage()"
        />
        <IngButton class="button-right" type="submit">
          {{ $t('auth.form.submit') }}
        </IngButton>
      </div>
    </form>
    <IngFormLoader storeModule="authentication" />
  </Modal>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import Modal from '@/components/common/modal';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import APPLICATION_PAGE from '@/constants/APPLICATION_PAGE';

  import {
    isValidPassword,
    errorI18nMessage,
    serverI18nErrorMessage,
    showEmail,
  } from '@/mixins/authentication/authenticationModalMixin';
  import {
    TOO_MANY_WRONG_ATTEMPTS,
    LINK_EXPIRED,
    LINK_DEACTIVATED,
  } from '@/constants/ERROR';

  export default {
    name: 'IngAuthentication',
    components: {
      Modal,
      TextInput,
      IngButton,
      IngFormLoader,
    },
    mixins: [isValidPassword, showEmail],
    props: {
      show: {
        type: Boolean,
        default: false,
      },
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        submitClicked: false,
      };
    },
    computed: {
      ...mapState('authentication', ['error', 'success']),
      errorMessage() {
        return (
          this.submitClicked &&
          this.$t(errorI18nMessage(this.password, 'auth', this.error))
        );
      },
      serverErrorMessage() {
        return this.$t(serverI18nErrorMessage('auth', this.error));
      },
      isBlockedOrExpired() {
        const { data: errorData } = this.error || {};
        const { errorType } = errorData || {};
        return (
          this.submitClicked &&
          (errorType === TOO_MANY_WRONG_ATTEMPTS || errorType === LINK_EXPIRED)
        );
      },
      authMessage() {
        const { label, message } = this.errorMessage;
        return {
          label: this.isBlockedOrExpired ? label : this.$t('auth.form.title'),
          message: this.isBlockedOrExpired
            ? message
            : this.$t('auth.form.explanation'),
        };
      },
      password: {
        get() {
          return this.$store.state.authentication.password;
        },
        set(newValue) {
          this.SET_PASSWORD(newValue);
        },
      },
    },
    mounted() {
      this.SET_ID(this.id);
    },
    methods: {
      ...mapActions('authentication', ['authenticate']),
      ...mapMutations('authentication', [
        'SET_PASSWORD',
        'SET_ID',
        'SET_LOADING',
        'CLEAR_ERROR',
      ]),
      async handleSubmit() {
        this.submitClicked = true;
        if (!this.isValidPassword) {
          return;
        }
        this.CLEAR_ERROR();
        this.SET_LOADING(true);
        await this.authenticate();
        this.SET_LOADING(false);
        if (this.success && this.success.data) {
          this.$emit('onResponse', 'success');
          this.$refs.authenticationModal.closeModal();
        } else if (
          this.$route.meta.page === APPLICATION_PAGE.CLIENT &&
          this.error?.data?.errorType === LINK_DEACTIVATED
        ) {
          this.$emit('onResponse', LINK_DEACTIVATED);
          this.$refs.authenticationModal.closeModal();
        }
      },
      clearErrorMessage() {
        this.CLEAR_ERROR();
        this.submitClicked = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';

  .ing-modal {
    display: block;

    &__dialog {
      width: calc(100% - 1rem);
      max-width: 1022px;
      border-radius: 1rem;
      overflow: hidden;
      height: fit-content;
      z-index: 1041;
    }
  }

  .ing-form {
    text-align: left;
    padding: px2rem(24) px2rem(18);

    @include mq-size(s) {
      padding: px2rem(40) px2rem(68) px2rem(40) px2rem(64);
    }

    @include mq-size(m) {
      padding: px2rem(32) px2rem(80) px2rem(104) px2rem(80);
    }

    &__message {
      margin-bottom: px2rem(68);
      @include mq-size(s) {
        margin-bottom: px2rem(40);
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;

      @include mq-size(m) {
        padding: 0 px2rem(105);
      }

      > .button-right {
        align-self: flex-end;
      }
    }
  }

  .auth-form {
    &--title {
      font-size: px2rem(36);
      color: $Primary-Orange;
      line-height: px2rem(48);
      font-weight: 400;
      padding-bottom: 10px;
    }

    &--explanation {
      font-size: px2rem(19);
      line-height: px2rem(28);
      color: $Primary-Grey600;
      margin: 0 auto;
    }
  }
</style>
