<template>
  <PageSection class="section-form">
    <template #section-content>
      <FormSectionHeader section="signers.clientDetails" :paraCount="1" />
      <div v-for="(signer, id, index) in signers" :key="id">
        <ClientInformationOverview
          v-if="isClientEditable !== id"
          :signerIndex="index"
          :signerId="id"
          :isInvalid="isClosedFormInvalid(id)"
          @edit-button-clicked="handleEdit(id)"
        />
        <ClientInformationForm
          v-if="isClientEditable === id"
          :signerId="clientToBeEdited"
          :isInvalid="isOpenFormInvalid"
          @cancel-button-clicked="handleClientCancel()"
          @submit-button-clicked="handleClientSubmission()"
        />
      </div>
    </template>
  </PageSection>
</template>

<script>
  import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import ClientInformationOverview from '@/components/signers/ClientInformation/ClientInformationOverview';
  import ClientInformationForm from '@/components/signers/ClientInformation/ClientInformationForm';
  export default {
    components: {
      PageSection,
      FormSectionHeader,
      ClientInformationOverview,
      ClientInformationForm,
    },
    data() {
      return {
        clientToBeEdited: null,
      };
    },
    computed: {
      ...mapGetters('signers', ['isClientValid']),
      ...mapState('signers', [
        'signers',
        'errors',
        'submitClicked',
        'isClientEditable',
      ]),
      isOpenFormMissingData() {
        return (
          this.signers[this.clientToBeEdited] &&
          this.signers[this.clientToBeEdited].submitClicked &&
          !this.isClientValid(this.clientToBeEdited)
        );
      },
      isOpenFormInvalid() {
        if (!this.clientToBeEdited) {
          return false;
        }
        return this.isOpenFormMissingData;
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CLIENT_SUBMIT_CLICKED',
        'SET_IS_CLIENT_EDITABLE',
        'SET_LOADING',
        'CLEAR_CLIENT_REQUEST_ERROR',
        'SET_CLIENT_SNAPSHOT',
        'REVERT_CLIENT_CHANGES',
        'CLEAR_CLIENT_SNAPSHOT',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
      ...mapActions('signers', [
        'updateSignerMissingDetails',
        'getSignersMissingDetails',
      ]),
      isClosedFormInvalid(signerId) {
        return (
          this.submitClicked &&
          this.isClientEditable !== signerId &&
          !this.isClientValid(signerId)
        );
      },
      async handleClientSubmission() {
        this.CLEAR_CLIENT_REQUEST_ERROR();
        this.SET_CLIENT_SUBMIT_CLICKED({
          signerId: this.clientToBeEdited,
          submitClicked: true,
        });
        if (!this.isClientValid(this.clientToBeEdited)) {
          return;
        }
        this.submitSigner();
      },
      async submitSigner() {
        this.SET_LOADING(true);
        await this.updateSignerMissingDetails(this.clientToBeEdited);
        if (!this.errors.clientServer) {
          await this.getSignersMissingDetails();
          this.updateSignersPostalAddress();
          this.CLEAR_CLIENT_SNAPSHOT();
          this.SET_IS_CLIENT_EDITABLE(null);
          this.clientToBeEdited = null;
        }
        this.SET_LOADING(false);
      },
      updateSignersPostalAddress() {
        Object.keys(this.signers).forEach((signerId) =>
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(signerId)
        );
      },
      async handleClientCancel() {
        this.CLEAR_CLIENT_REQUEST_ERROR();
        this.REVERT_CLIENT_CHANGES(this.clientToBeEdited);
        this.CLEAR_CLIENT_SNAPSHOT();
        this.SET_IS_CLIENT_EDITABLE(null);
        this.clientToBeEdited = null;
      },
      handleEdit(signerId) {
        this.SET_CLIENT_SNAPSHOT(signerId);
        this.SET_IS_CLIENT_EDITABLE(signerId);
        this.clientToBeEdited = signerId;
      },
    },
  };
</script>
