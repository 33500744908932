<template>
  <len-modal :show="show" :class="{ 'is-visible': !isRendered }" @close="close">
    <section class="white-section centered">
      <div class="len-modal-download-btn-bar">
        <div class="len-modal-download-btn">
          <a
            :download="downloadName"
            :href="downloadLink"
            class="len-download-btn-text"
            >{{ $t('borrowerJourney.contractModal.download') }}</a
          >
        </div>
      </div>

      <canvas id="the-canvas" class="centered" />

      <div class="len-content-bar len-arrow">
        <div class="len-arrow-left" @click="goLeft">
          <div class="len-arrow-head-left" />
          <div class="len-arrow-base" />
        </div>
        <div class="len-current-page">
          <div class="len-current-page-text">
            {{ activePage }}
          </div>
        </div>
        <div class="len-arrow-right" @click="goRight">
          <div class="len-arrow-base" />
          <div class="len-arrow-head-right" />
        </div>
      </div>
    </section>
  </len-modal>
</template>
<script>
  import LenModal from '@/components/common/len-modal';
  import i18n from '@/i18n';
  import debounce from 'lodash/debounce';
  import Window from '@/common/window';
  import pdfjs from '@/utils/pdfjsLib.js';

  export default {
    name: 'ContractModal',
    components: { LenModal },
    // eslint-disable-next-line vue/require-prop-types
    props: ['show', 'contract'],
    data() {
      return {
        isForgotPasswordSucceed: false,
        loadingTask: '',
        maxPage: null,
        activePage: 1,
        scale: 1,
        isRendered: false,
      };
    },
    computed: {
      downloadName() {
        return this.contract.fileName;
      },
      downloadLink() {
        return 'data:application/octet-stream;base64,' + this.contract.data;
      },
    },
    watch: {
      show: function (newValue) {
        if (newValue) {
          this.loadingTask = pdfjs.getDocument({
            data: atob(this.contract.data),
          });
          this.renderPdf(this.activePage, this);
        }
      },
    },
    beforeMount() {
      if (!Window.innerWidth) {
        return;
      }
      this.resizeListener();
      Window.addEventListener('resize', this.resizeListener, false);
    },
    beforeDestroy() {
      Window.removeEventListener('resize', this.resizeListener);
    },
    methods: {
      resizeListener: debounce(function () {
        if (window.innerWidth < 600) {
          this.scale = (window.innerWidth - 45) / 595;
          this.renderPdf(this.activePage, this);
        }
        if (window.innerWidth > 599) {
          if (this.scale !== 1) {
            this.scale = 1;
            this.renderPdf(this.activePage, this);
          }
          this.scale = 1;
        }
      }, 200),
      success() {
        this.$emit('success');
      },
      close() {
        this.$emit('close');
      },
      goRight() {
        if (this.activePage === this.maxPage) {
          return;
        }
        this.activePage++;
        this.renderPdf(this.activePage, this);
      },
      goLeft() {
        if (this.activePage <= 1) {
          return;
        }
        this.activePage--;
        this.renderPdf(this.activePage, this);
      },
      renderPdf(pageNumber, vm) {
        // Using DocumentInitParameters object to load binary data.
        this.loadingTask.promise.then(
          (pdf) => {
            vm.maxPage = pdf.numPages;

            // Fetch the first page
            pdf.getPage(pageNumber).then((page) => {
              let viewport = page.getViewport({ scale: vm.scale });

              // Prepare canvas using PDF page dimensions
              let canvas = document.getElementById('the-canvas');
              let context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;

              // Render PDF page into canvas context
              let renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              let renderTask = page.render(renderContext).promise;
              renderTask.then(function () {
                if (vm.show) {
                  vm.isRendered = true;
                }
              });
            });
          },
          () => {
            // PDF loading error
            this.close();
          }
        );
      },
    },
    i18n,
  };
</script>

<style scoped>
  a {
    text-decoration: none !important;
  }

  .is-visible {
    display: none;
  }

  .centered {
    /* display: block;
        margin-left: auto;
        margin-right: auto */
  }

  .len-content-bar {
    position: static;
    bottom: 0px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .len-arrow {
    margin-top: 20px;
    margin-right: 30px;
    margin-left: 30px;
  }

  .len-download {
    margin-right: 30px;
    margin-left: 35px;
  }

  .len-current-page {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 30px;
    height: 30px;
    margin-right: 20px;
    margin-left: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: #3cbdb9;
    cursor: default;
  }

  .len-current-page-text {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
  }

  .len-arrow-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .div-block-4 {
    width: 15px;
    height: 15px;
    background-color: #3cbdb9;
  }

  .len-arrow-head-right {
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 30px solid #3cbdb9;
  }

  .len-arrow-left {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
  }

  .len-arrow-head-left {
    border-top: 15px solid transparent;
    border-right: 30px solid #3cbdb9;
    border-bottom: 15px solid transparent;
  }

  .len-arrow-base {
    width: 15px;
    height: 15px;
    background-color: #3cbdb9;
  }

  .len-modal-download-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 120px;
    height: 40px;
    margin-left: 55px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 3px;
    background-color: #3cbdb9;
    cursor: pointer;
  }

  .len-modal-download-btn:hover {
    background-color: #004055;
  }

  .len-download-btn-text {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    text-transform: none;
  }

  .len-modal-download-btn-bar {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
</style>
