export default function ValidateIdentProcess(identificationProcess) {
  if (!identificationProcess) {
    return { identificationProcess: 'IdentificationProcessIsRequired' };
  }
  if (['MANUAL', 'AUTOMATION'].indexOf(identificationProcess) === -1) {
    return { identificationProcess: 'IdentificationProcessIsNotCorrect' };
  }

  return { identificationProcess: undefined };
}
