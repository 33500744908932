import Vue from 'vue';
import VueI18n from 'vue-i18n';
import de from '@/i18n/messages/de';
Vue.use(VueI18n);

// Create VueI18n instance with options
export default new VueI18n({
  locale: 'de',
  messages: {
    de,
  },
  silentFallbackWarn: true,
});
