<template>
  <div>
    <len-header-big-logo />
    <div v-if="!!message && !loading" class="container">
      <h1 class="container-title">
        {{ $t(message.title) }}
      </h1>
      <p class="container-subtitle">
        {{ $t(message.subtitle) }}
      </p>
      <p class="container-text">
        {{ $t(message.text) }}
      </p>
    </div>
    <len-footer />
  </div>
</template>

<script>
  import { mapActions } from 'vuex';

  const SUBSCRIPTION_MESSAGES = {
    REGISTERED: {
      title: 'subscription.messages.registered.title',
      subtitle: 'subscription.messages.registered.subtitle',
      text: 'subscription.messages.registered.text',
    },
    ALREADY_REGISTERED: {
      title: 'subscription.messages.already.registered.title',
      subtitle: 'subscription.messages.already.registered.subtitle',
      text: 'subscription.messages.already.registered.text',
    },
  };

  export default {
    name: 'SubscriptionConfirmationPage',
    data: () => ({
      message: null,
      loading: false,
    }),

    async mounted() {
      const vm = this;
      const { $router, $route } = vm;
      const token = $route.query.token;

      if (!token) {
        $router.push('/404');
      }

      vm.loading = true;

      try {
        const response = await vm.validateSubscription({ payload: token });
        vm.message = SUBSCRIPTION_MESSAGES[response];
      } catch (error) {
        $router.push('/404');
      } finally {
        vm.loading = false;
      }
    },
    methods: {
      ...mapActions('subscription', ['validateSubscription']),
    },
  };
</script>

<style scoped lang="scss">
  @import '../sass/custom/variables';

  .container {
    font-family: Source Sans Pro, Sans-Serif;
    font-style: normal;
    width: 100%;
    margin: 250px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .container-title {
    font-weight: 600;
    font-size: 64px;
    line-height: 64px;
    color: $ing-black;
  }

  .container-subtitle {
    padding-top: 50px;
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: #29bdba;
  }

  .container-text {
    padding-top: 20px;
    font-weight: normal;
    font-size: 18px;
    line-height: 18px;
    color: $ing-dark-gray;
  }

  @media (max-width: 991px) {
    .container {
      margin: 98px 0;
      padding: 0 15px;
    }

    .container-title {
      font-size: 32px;
      line-height: 38px;
    }

    .container-subtitle {
      padding-top: 20px;
      font-size: 18px;
      line-height: 24px;
    }

    .container-text {
      font-size: 14px;
      line-height: 16px;
    }
  }
</style>
