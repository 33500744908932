export const showSectionDependingOnPosition = {
  methods: {
    showSectionDependingOnPosition() {
      const topSpacer1Ref = this.$refs['spacer-1'];
      const topSpacer2Ref = this.$refs['spacer-2'];
      const topSpacer3Ref = this.$refs['spacer-3'];
      if (topSpacer1Ref && topSpacer2Ref) {
        const { top: topSpacer1 } = topSpacer1Ref.getBoundingClientRect();
        const { top: topSpacer2 } = topSpacer2Ref.getBoundingClientRect();
        const { top: topSpacer3 } = topSpacer3Ref.getBoundingClientRect();

        const wh = window.innerHeight;
        if (topSpacer1 >= 0.45 * wh) {
          this.sectionToDisplay = 1;
        } else if (topSpacer2 >= 0.45 * wh) {
          this.sectionToDisplay = 2;
        } else if (topSpacer3 >= 0.45 * wh) {
          this.sectionToDisplay = 3;
        } else {
          this.sectionToDisplay = 4;
        }
      }
    },
  },
};

export const goToElement = {
  methods: {
    goToElement(referenceForField) {
      const element = this.$refs[referenceForField];
      const elementRect = element.getBoundingClientRect();
      const absoluteElementTop = elementRect.top + window.pageYOffset;
      const middle = absoluteElementTop - window.innerHeight / 2;
      window.scrollTo({ left: 0, top: middle, behavior: 'smooth' }); // Smooth behavior needs to be polyfilled https://github.com/iamdustan/smoothscroll
      element.focus();
    },
  },
};
