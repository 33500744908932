import { isEmpty } from '@/validators/helpers/string';
import { isValidGermanPhone } from '@/validators/helpers/phone';

export default function ValidatePhone(mobilePhoneBorrower) {
  if (isEmpty(mobilePhoneBorrower)) {
    return { mobilePhoneBorrower: 'PhoneIsRequired' };
  }
  if (!isValidGermanPhone(mobilePhoneBorrower)) {
    return { mobilePhoneBorrower: 'PhoneIsNotCorrect' };
  }

  return { mobilePhoneBorrower: undefined };
}
