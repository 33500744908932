import { isEmpty } from '../helpers/string';
import { isPastDate } from '../helpers/date';

const FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export default function ValidateIdentificationIssuingDate(
  identificationIssuingDate
) {
  if (isEmpty(identificationIssuingDate)) {
    return { identificationIssuingDate: 'IdentificationIssuingDateIsRequired' };
  }

  if (!isPastDate(identificationIssuingDate, FORMAT)) {
    return {
      identificationIssuingDate: 'IdentificationIssuingDateIsNotCorrect',
    };
  }

  return { identificationIssuingDate: undefined };
}
