import { initialState } from './index';
import { identValidator } from '@/validators';

export const mutateIdentField = (state, fieldValue) => {
  state.ident = { ...state.ident, ...fieldValue };
  state.errors = {
    ...state.errors,
    ...identValidator(state.ident),
  };
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_REFERENCE_NUMBER(state, referenceNumber) {
    mutateIdentField(state, { referenceNumber: referenceNumber });
  },
  SET_REFERENCE_NUMBER_EDITABLE(state, editable) {
    state.referenceNumberEditable = editable;
  },
  SET_FIRST_NAME(state, firstName) {
    mutateIdentField(state, { firstName: firstName });
  },
  SET_LAST_NAME(state, lastName) {
    mutateIdentField(state, { lastName: lastName });
  },
  SET_MOBILE_PHONE(state, mobilePhone) {
    mutateIdentField(state, { mobilePhone: mobilePhone });
  },
  SET_EMAIL(state, email) {
    mutateIdentField(state, { email: email });
  },
  SET_SUBMIT_CLICKED(state, submitClicked) {
    state.submitClicked = submitClicked;
  },
  SET_REDIRECT_URL(state, url) {
    state.postIdentRedirectUrl = url;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  },
  SET_REQUEST_ERROR(state, error) {
    state.errors.server = error;
  },
  CLEAR_REQUEST_ERROR(state) {
    state.errors.server = null;
  },
  CLEAR(state) {
    Object.assign(state, initialState);
  },
};
