<!-- eslint-disable vue/v-bind-style -->
<template>
  <div>
    <div class="ing-header-container">
      <img
        alt="Company Icon"
        class="company-icon header-icon"
        src="/ing_calculator_icon.svg"
      />
      <div class="ing-header-container__content">
        <h2 class="header">
          {{ $t(`ingBorrowerSignupForm.calculatorHeader.${productKey}.title`) }}
        </h2>
        <p>
          {{
            $t(
              `ingBorrowerSignupForm.calculatorHeader.${productKey}.explanation`
            )
          }}
        </p>
      </div>
    </div>
    <div v-if="blackSeaOfferId && offer" class="ing-calculator-wrapper">
      <lendico-ing-loan-calculator v-if="isTermLoan" />
      <lendico-wcl-loan-calculator v-else-if="isCreditLineLoan" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import { getUrlParameterByName } from '@/mixins/urlParamMixin';
  import { calculatorLoaderMixin } from '@/mixins/calculatorLoaderMixin';
  export default {
    mixins: [getUrlParameterByName, calculatorLoaderMixin],
    computed: {
      ...mapGetters('blacksea', [
        'isTermLoan',
        'isCreditLineLoan',
        'productKey',
      ]),
      ...mapState('blacksea', ['offer']),
      blackSeaOfferId() {
        return this.getUrlParameterByName('offerId');
      },
      blackSeaReturnUrl() {
        return this.getUrlParameterByName('returnUrl');
      },
    },
    beforeMount() {
      this.loadCalculator();
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .ing-header-container {
    margin-bottom: 40px;
  }
  h2.header {
    font-weight: bold;
  }
</style>
