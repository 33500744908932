<template>
  <div>
    <ident-type-header />
    <ident-type-form />
  </div>
</template>

<script>
  import identTypeHeader from '@/components/signers/ident-type-information/ident-type-header';
  import identTypeForm from '@/components/signers/ident-type-information/ident-type-form';
  export default {
    components: {
      identTypeHeader,
      identTypeForm,
    },
  };
</script>
