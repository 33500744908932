<template>
  <fieldset
    :class="{ error: displayError }"
    class="form-field addMaterialDesign fieldset-container"
  >
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !company,
      }"
      class="form-group label-floating"
      data-qa-id="company-info-name"
    >
      <label class="control-label">
        {{ label }}
      </label>
      <!-- please see: https: gist.github.com/niksumeiko/360164708c3b326bd1c8 for the explanation about autocomplete property hack -->
      <input
        id="companyName"
        :value="company"
        type="text"
        class="form-control"
        :name="safariAutocompleteOffName"
        autocorrect="off"
        autocomplete="nofill"
        aria-autocomplete="none"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <company-loader />
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{ errors.company && $t('forms.errors.' + errors.company) }}
        </p>
      </div>

      <dropdown
        :open="displayCompanyDropdown"
        :options="companiesOptions"
        :maxOptions="10"
        class="dropdown company-lookup"
        @select="onSelectCompany"
      />
    </div>
  </fieldset>
</template>

<script>
  import { companyFieldFunctionality } from '@/mixins/forms/company-information/companyFieldMixin';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    mixins: [companyFieldFunctionality, lenFormFieldGTMTrackingMixin],
    computed: {
      label: function () {
        if (!this.isPartner) {
          return this.$t('borrowerSignupForm.company.label');
        }
        return this.$t('partnerSubmissionPageNew.form.company.label');
      },
    },
  };
</script>

<style scoped lang="scss">
  .error-container {
    height: 25px;
  }

  .validation {
    margin-bottom: 0px;
  }

  .dropdown {
    margin-top: -17px;
    margin-bottom: 17px;
  }
</style>

<style lang="scss">
  @import '@/variables.scss';

  .dropdown.company-lookup {
    z-index: 99;

    ul.dropdown-menu {
      box-shadow: 0 2px 4px rgba(46, 46, 46, 0.15) !important;
      border-radius: 0 0 4px 4px !important;
      background: #fff;

      &:after {
        display: none;
      }
    }
    li.item-wrapper {
      background: transparent;

      &:last-child {
        a.item {
          border-radius: 0 0 4px 4px;
        }
      }

      > a.item {
        padding: 12px 10px;
        display: flex;
        align-items: center;
        background: transparent;
        font-size: 16px;
        line-height: 16px;
        transition: none;
        border: none;

        &:hover {
          background: #2cbdb7 !important;

          span.company-name,
          span.company-address {
            color: #ffffff !important;
          }
        }
        span.company-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        span.company-address {
          flex: 1;
          margin-left: 10px;
          font-size: 12px;
          line-height: 14px;
          color: #575757;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-top: 2px;
          font-weight: normal;
        }
      }

      &.selected-item {
        > a.item {
          span.company-name,
          span.company-address {
            color: #2cbdb7;
          }
          span.company-name {
            font-weight: 600;
          }
        }
      }
    }
  }

  @media screen and (max-width: $mobile-l) {
    .dropdown.company-lookup {
      li.item-wrapper > a.item {
        flex-direction: column;
        align-items: self-start;
        justify-content: center;

        span.company-name {
          width: 100%;
        }
        span.company-address {
          flex: 0 0 auto;
          margin-left: 0;
          margin-top: 10px;
          display: block;
          width: 100%;
          color: #767676;
        }
      }
    }
  }
</style>
