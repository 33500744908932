import { isEmpty } from '@/validators/helpers/string';
import { isPastDate, isDateAboveMinAge } from '@/validators/helpers/date';
const FORMAT = 'DD.MM.YYYY';

export default function ValidateDob(dob) {
  if (isEmpty(dob)) {
    return { dob: 'DobIsRequired' };
  }
  if (!isPastDate(dob, FORMAT)) {
    return { dob: 'DobIsNotCorrect' };
  }
  if (!isDateAboveMinAge(dob, FORMAT)) {
    return { dob: 'DobIsNotAboveMinAge' };
  }

  return { dob: undefined };
}
