import Page404 from '@/views/page-404.vue';
import BorrowerSignupPage from '@/views/borrower-signup-page.vue';
import BorrowerSignupThankYouPage from '@/views/borrower-signup-thankyou-page';
import BorrowerDealSuccessPage from '@/views/borrower-success-page.vue';
import BorrowerDealFailPage from '@/views/borrower-fail-page.vue';
import BecomePartnerForm from '@/views/become-partner-form.vue';
import PartnerSubmissionPage from '@/views/partner-submission-page.vue';
import PartnerSubmissionThankyouPage from '@/views/partner-submission-thankyou-page.vue';
import SignersMissingInfoForm from '@/views/signers-missing-form-page.vue';
import SignersMissingInfoSuccess from '@/views/signers-success-page.vue';
import SubscriptionConfirmationPage from '@/views/subscription-confirmation-page.vue';
import OfferPage from '@/views/offer-page.vue';
import APPLICATION_CHANNELS from '@/constants/APPLICATION_CHANNELS';

// TODO: "/newsletter/confirm" can be removed after finishing BT we would no longer need it
// TODO: "/success" and "/fail" can be deleted after finishing BT BUT confirm it with Acquisition team
export default [
  {
    path: '/',
    name: 'project-root',
    redirect: { name: 'borrower-signup-page' },
  },
  {
    path: '/new',
    name: 'borrower-signup-page',
    component: BorrowerSignupPage,
    props: true,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/ready',
    name: 'borrower-signup-thankyou-page',
    component: BorrowerSignupThankYouPage,
    props: false,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },

  {
    path: '/signers/form/:opportunityId',
    props: true,
    name: 'signers-missing-info-form',
    component: SignersMissingInfoForm,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/signers/success',
    name: 'signers-missing-info-success',
    component: SignersMissingInfoSuccess,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/vertragsunterlagen/:loanRequestId',
    props: true,
    name: 'ing-offer-page',
    component: OfferPage,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },

  {
    path: '/success',
    name: 'borrower-success-page',
    component: BorrowerDealSuccessPage,
    props: false,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/fail',
    name: 'borrower-fail-page',
    component: BorrowerDealFailPage,
    props: false,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },

  {
    path: '/partner-werden-form',
    name: 'become-partner-form',
    component: BecomePartnerForm,
    props: false,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/partner-antrag',
    name: 'partner-antrag',
    component: PartnerSubmissionPage,
    props: false,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/partner-ready',
    name: 'partner-submission-thankyou-page',
    component: PartnerSubmissionThankyouPage,
    props: false,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '/newsletter/confirm',
    name: 'subscription-confirmation-page',
    component: SubscriptionConfirmationPage,
    props: true,
    meta: {
      channel: APPLICATION_CHANNELS.BRIDGE,
    },
  },
  {
    path: '*',
    name: '404-page',
    component: Page404,
    props: false,
  },
];
