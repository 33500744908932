<template>
  <div class="ing-company-name-field" @click.stop>
    <TextInput
      ref="lookupInput"
      v-bind="{ ...$attrs, ...$props }"
      :placeholder="$t('formFields.company.placeholder')"
      data-test-id="ingLookupInput"
      @focus="handleFocus"
      v-on="$listeners"
    >
      <template #label>
        <em v-if="companyLookupEnabled">{{
          $t('formFields.company.lookup.label')
        }}</em>
        <em v-if="!companyLookupEnabled">{{
          $t('formFields.company.manual.label')
        }}</em>
      </template>
      <template #prefix>
        <template v-if="showLookupIcon">
          <IconLookup />
        </template>
      </template>
      <template #info>
        <template v-if="!mappingId">
          <i18n
            v-if="companyLookupEnabled"
            path="formFields.company.lookup.info.text"
          >
            <template #boldText>
              <em>{{ $t('formFields.company.lookup.info.boldText') }}</em>
            </template>
          </i18n>
          <i18n
            v-if="!companyLookupEnabled"
            path="formFields.company.manual.info.text"
          >
            <template #switchToLookupMode>
              <a
                href="#"
                data-test-id="toggleManualModeLink"
                @click.prevent="toggleManualMode"
              >
                {{ $t('formFields.company.manual.info.switchToLookupMode') }}</a
              >
            </template>
          </i18n>
        </template>
      </template>
    </TextInput>

    <div v-if="showDropdown" class="ing-dropdown-menu">
      <div
        v-if="keepTyping"
        class="ing-dropdown-item ing-dropdown-item--keep-typing"
      >
        {{ $t('formFields.company.lookup.keepTyping') }}
      </div>
      <template v-if="!keepTyping">
        <IngFormLoader
          v-if="searchingCompany"
          storeModule="signupForm"
          :manuallyShow="true"
          medium
          class="spinner"
        >
          <template #label>
            {{ $t('formFields.company.lookup.loading') }}
          </template>
        </IngFormLoader>
        <div v-if="!searchingCompany">
          <div
            v-if="wrongCompanyName"
            class="ing-dropdown-item ing-dropdown-item--wrong-company-name"
          >
            <IconSmilingLion />
            <i18n path="formFields.company.lookup.wrongCompanyName">
              <template #newline><br /></template>
              <template #switchToManualMode>
                <a
                  href="#"
                  data-test-id="wrongCompanyNameToggleLink"
                  @click.prevent="toggleManualMode"
                >
                  {{ $t('formFields.company.lookup.switchToManualMode') }}</a
                >
              </template>
            </i18n>
          </div>
          <div v-if="!wrongCompanyName" data-test-id="ingLookupDropdown">
            <div
              v-for="company in companiesOptions"
              :key="company.id"
              class="ing-dropdown-item"
              :class="{
                'ing-dropdown-item--selected': company.id === selectedItem,
              }"
              @click="handleSelectCompany(company.id)"
            >
              {{ company.label }}
            </div>
            <div
              key="no-company-found"
              class="ing-dropdown-item ing-dropdown-item--no-company-found"
            >
              <i18n path="formFields.company.lookup.noCompanyFound.text">
                <template #boldText>
                  <em>
                    {{
                      $t('formFields.company.lookup.noCompanyFound.boldText')
                    }}
                  </em>
                </template>
                <template #switchToManualMode>
                  <a
                    href="#"
                    data-test-id="noCompanyFoundToggleLink"
                    @click.prevent="toggleManualMode"
                  >
                    {{ $t('formFields.company.lookup.switchToManualMode') }}</a
                  >
                </template>
              </i18n>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import IconSmilingLion from '@/assets/icon-smiling-lion';
  import IconLookup from '@/assets/icon-lookup.vue';

  import { mapActions, mapMutations, mapState } from 'vuex';
  import debounce from 'lodash/debounce';

  export const MAX_COMPANIES_IN_THE_LIST = 10;
  export const MIN_CHARS_REQUIRED_FOR_LOOKUP = 3;

  export default {
    name: 'IngCompanyLookupField',
    components: {
      TextInput,
      IconLookup,
      IngFormLoader,
      IconSmilingLion,
    },
    inheritAttrs: false,
    data() {
      return {
        isFocused: false,
        isDirty: false,
        selectedItem: null,
      };
    },
    computed: {
      ...mapState('signupForm', [
        'companies',
        'searchingCompany',
        'mappingId',
        'company',
        'companyLookupEnabled',
      ]),
      showDropdown() {
        return (
          this.isFocused &&
          this.companyLookupEnabled &&
          this.company?.length >= 1
        );
      },
      keepTyping() {
        return this.company?.length < MIN_CHARS_REQUIRED_FOR_LOOKUP;
      },
      wrongCompanyName() {
        return this.companies === null;
      },
      showLookupIcon() {
        return !this.isFocused && this.companyLookupEnabled && !this.mappingId;
      },
      companiesOptions() {
        if (!Array.isArray(this.companies)) {
          return [];
        }

        return this.companies
          .map(({ mappingId, companyName, city, postalCode }) => ({
            id: mappingId,
            label: `${companyName}, ${postalCode} ${city}`,
          }))
          .slice(0, MAX_COMPANIES_IN_THE_LIST);
      },
    },
    watch: {
      '$attrs.value': {
        handler(value, oldValue) {
          if (this.companyLookupEnabled) {
            if (!value || value.length < MIN_CHARS_REQUIRED_FOR_LOOKUP) {
              this.SET_COMPANIES(null);
              return;
            }
            if (value !== oldValue && !this.mappingId) {
              this.setSearchingCompany(true);
              this.getFWCompaniesDebounced(this);
            }
          }
        },
        deep: true,
      },
      mappingId() {
        if (!this.mappingId) {
          this.selectedItem = null;
        }
      },
      isFocused() {
        if (this.isFocused) {
          window.addEventListener('click', this.closeMenu);
          window.addEventListener('keydown', this.handleKeyDown);
        } else {
          this.removeEventListeners();
        }
      },
      companyLookupEnabled() {
        /*
        This is in order to call the api when users switch to lookup mode from manual mode
         */
        if (this.companyLookupEnabled) {
          this.setSearchingCompany(true);
          this.getFWCompanies().finally(() => {
            this.setSearchingCompany(false);
          });
        }
      },
    },
    beforeDestroy() {
      this.removeEventListeners();
    },
    methods: {
      ...mapActions('signupForm', ['getFWCompanies', 'getCompanyLegalForm']),
      ...mapMutations('signupForm', [
        'SET_FW_COMPANY',
        'SET_SEARCHING_COMPANY',
        'SET_COMPANIES',
        'RESET_PREFILLED_FIELDS',
        'SET_COMPANY_NAME',
        'SET_COMPANY_LOOK_UP_ENABLED',
      ]),
      removeEventListeners() {
        window.removeEventListener('click', this.closeMenu);
        window.removeEventListener('keydown', this.handleKeyDown);
      },
      handleKeyDown(e) {
        if (e.code === 'Tab') {
          this.closeMenu();
        }
      },
      closeMenu() {
        /*
        even though the flag isFocused is being set to false here
        the name of the method was set to closeMenu deliberately
        to make it clear wherever it is called
         */
        this.isFocused = false;
      },
      toggleManualMode() {
        this.SET_COMPANY_LOOK_UP_ENABLED(!this.companyLookupEnabled);
        this.RESET_PREFILLED_FIELDS();
        this.$refs.lookupInput.focus();
      },
      handleFocus() {
        /*
        We need to set this tiny timeout to make the component render lookup icon properly
        when the field is empty and the error message is being shown
         */
        setTimeout(() => {
          this.isFocused = true;
          this.isDirty = true;
        }, 10);
      },
      handleSelectCompany(mappingId) {
        const companyItem = this.companies.find(
          (c) => c.mappingId === mappingId
        );

        if (companyItem) {
          this.setFwCompany(companyItem);
          this.getCompanyLegalForm(companyItem.mappingId);
          this.selectedItem = companyItem.mappingId;
          this.closeMenu();
        }
      },
      setFwCompany(companyItem) {
        this.SET_FW_COMPANY(companyItem);
      },
      setSearchingCompany(searchingCompany) {
        this.SET_SEARCHING_COMPANY(searchingCompany);
      },
      getFWCompaniesDebounced: debounce(function () {
        this.getFWCompanies().finally(() => this.setSearchingCompany(false));
      }, 500),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';

  .ing-company-name-field {
    position: relative;
  }
  .spinner {
    position: relative;
  }

  .ing-dropdown-menu {
    position: absolute;
    top: 71px;
    bottom: auto;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 5;
    background: $Primary-white;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    margin: 0;
    padding: 8px 0;
  }
  .ing-dropdown-item {
    font-size: 16px;
    line-height: 24px;
    color: $Primary-Grey600;
    padding: 3px 12px;
    white-space: normal;
    cursor: pointer;
    &:hover {
      background: $Secondary-Indigo500;
      color: $Primary-white;
    }

    &--selected {
      font-weight: bold;
    }

    &--keep-typing,
    &--wrong-company-name,
    &--no-company-found {
      padding-block: 0;
      &:hover {
        background: transparent;
        color: $Primary-Grey600;
        cursor: default;
      }
    }

    &--keep-typing {
      text-align: center;
    }

    &--wrong-company-name {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 8px;
      gap: 11px;
    }

    &--no-company-found {
      border-top: 5px solid $Primary-white;
      box-shadow: 0px -1px 0px 0px $Primary-Grey600;
      margin: 8px 12px 0;
      padding: 0 0 8px 0;
    }
  }
</style>
