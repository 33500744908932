import { isValidNationality } from '../helpers/nationality';
import { isEmpty } from '../helpers/string';

export default function ValidateCountry(iso) {
  if (isEmpty(iso)) {
    return { country: 'CountryIsRequired' };
  }

  if (!isValidNationality(iso)) {
    return { country: 'CountryIsNotCorrect' };
  }
  return { country: undefined };
}
