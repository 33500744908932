import numeral from 'numeral';

/**
 * Implements format function from numeral package.
 *
 * @param {string|int} value
 * @param {string} format
 * @return {string}
 */
export function numeraljsUnsafe(value, format) {
  return numeral(Number(value)).format(format);
}

/**
 * Implements format function from numeral package with a check for empty value.
 *
 * @param {string|int} value
 * @param {string} format
 * @return {string}
 */
export function numeraljs(value, format) {
  if (!value) {
    return '';
  }

  return numeraljsUnsafe(value, format);
}

/**
 * European currency filter.
 *
 * @param {int} value
 * @return {string}
 */
export function europeanCurrency(value) {
  let newValue = value;

  if (newValue === 0) {
    newValue = '0.00';
  }
  if (!newValue) {
    return '0';
  }
  return numeraljs(newValue, '0.0,');
}

/**
 * Global Short Currency filter.
 *
 * @param {int} value
 * @return {string}
 */
export function currencyShort(value) {
  if (!value) {
    return '0';
  }

  return numeraljs(value, '0,0');
}

export function euroFormatter(value) {
  if (value) {
    return '€ ' + numeraljs(value, '0,0.[00]');
  }
}

export function removeEuroFormat(value) {
  if (value) {
    return value.replace(/\./g, '').replace(/€/g, '').replace(/,/g, '.').trim();
  }
}

export function formatPhone(phoneNumber) {
  if (!phoneNumber) {
    return;
  }
  const regex = /^(\+\d{2})(\d{3})(\d{8})$/;

  return phoneNumber.replace(regex, '$1 $2 $3');
}

/**
 * Install function.
 *
 * @param {Object} Vue
 */
export default function install(Vue) {
  Vue.filter('currencyShort', currencyShort);
  Vue.filter('numeraljs', numeraljs);
  Vue.filter('numeraljsUnsafe', numeraljsUnsafe);
  Vue.filter('europeanCurrency', europeanCurrency);
  Vue.filter('euroFormatter', euroFormatter);
  Vue.filter('removeEuroFormat', removeEuroFormat);
  Vue.filter('formatPhone', formatPhone);
}
