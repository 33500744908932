export default {
  signers: {
    client: {
      header: {
        title: 'Ihr Firmenkreditantrag',
        para1:
          'Gute Nachrichten: Wir haben einen Finanzierungsvorschlag für Sie. Sind Sie mit den Konditionen einverstanden? Wunderbar! Dann vervollständigen Sie bitte die Informationen zu Ihrem Unternehmen und wählen Sie ein Identifizierungs-verfahren. Wenn alles passt, erhalten Sie schon bald Ihre Vertragsunterlagen.',
      },
    },
    meta: {
      title: 'Firmenkredit anfragen',
      description:
        'Fragen Sie hier direkt unseren Firmenkredit bis 750.000 Euro für kleine und mittlere Unternehmen an. Das klappt vollständig digital und geht ganz schnell.',
    },
    offerConditions: {
      title: 'Unverbindlicher Finanzierungsvorschlag',
      para1:
        'Wir freuen uns, Ihnen eine Finanzierung zu folgenden Konditionen anbieten zu können:',
      loanInfo: {
        loanAmount: 'Kreditbetrag',
        duration: 'Laufzeit',
        interestRate: 'Gebundener Sollzinssatz',
        monthlyRate: 'Monatliche Rate',
      },
    },
    companyDetails: {
      title: 'Firmeninformationen',
      para1:
        'Überprüfen und vervollständigen Sie die Angaben aus Ihrer Anfrage. ',
      para2:
        'Für die Kreditanfrage nutzen wir den Firmennamen Ihres Unternehmens. Diesen haben wir nach Ihren Angaben aus Auskunfteien entnommen. Wenn der Firmenname aus Ihrer Sicht korrigiert werden muss, melden Sie sich bitte unter business@ing.de oder unter 030 / 398 205 269 (Montag bis Freitag zwischen 9 und 18 Uhr).',
      name: 'Firmenname',
      taxNumber: 'Steuernummer',
      companyTaxNumber: 'Steuernummer des Unternehmens',
      tradeName: 'Handelsname',
      countryOfIncorporation: 'Gründungsland',
      businessAddress: 'Geschäftsadresse',
      mailingAddress: 'Postanschrift',
      controllingCompany: {
        title: 'Kreditnehmer/-in',
        name: 'Komplementär GmbH',
      },
      MLLFControllingCompany: {
        name: 'Muttergesellschaft',
      },
      addressCheck: 'Die Postanschrift entspricht der Geschäftsadresse.',
      cashIntensity: {
        title: 'Unternehmensumsätze',
        label: 'Unternehmensumsätze\r\n als Bargeldzahlungen:',
        message:
          'Wie hoch ist der Anteil der eingehenden Bargeldzahlungen an Ihren Unternehmensumsätzen?',
        options: {
          LOW: 'bis 25 %',
          HIGH: 'mehr als 25 %',
        },
        tooltip:
          'Es handelt sich um die Zahlungen, die von Ihren Kunden bar an Sie getätigt werden.',
      },
    },
    clientDetails: {
      title: 'Ermittlung der wirtschaftlich Berechtigten',
      para1:
        'Überprüfen und vervollständigen Sie die Angaben aus Ihrer Anfrage.',
      client: {
        salutation: 'Anrede',
        person: 'Person',
        title: 'Titel ',
        firstName: 'Vorname',
        lastName: 'Nachname',
        lastNameAtBirth: 'Geburtsname',
        lastNameAtBirthInput: 'Geburtsname (optional)',
        dateOfBirth: 'Geburtsdatum',
        dateOfBirthInput: 'Geburtsdatum (TT.MM.JJJJ)',
        cityOfBirth: 'Geburtsort',
        countryOfBirth: 'Geburtsland',
        nationality: 'Staatsangehörigkeit',
        secondNationality: 'Zweite Staatsangehörigkeit',
        secondNationalityInput: 'Zweite Staatsangehörigkeit (falls vorhanden)',
        contact: 'Kontakt',
        mobile: 'Mobilfunknummer',
        email: 'E-Mail',
        registrationAddress: 'Meldeadresse (Hauptwohnsitz)',
        mailingAddress: 'Postanschrift',
        proofOfIdentity: 'Identitätsnachweis',
        identificationDocument: 'Ausweisdokument',
        identDocumentNumber: 'Ausweis- / Passnummer',
        identityHint:
          'Bitte schicken Sie uns eine Kopie Ihrer aktuellen Meldebescheinigung an ',
        authority: 'Ausstellungsbehörde',
        placeOfIssue: 'Ausstellungsland',
        dateOfIssue: 'Ausstellungsdatum',
        dateOfIssueInput: 'Ausstellungsdatum (TT.MM.JJJJ)',
        dateOfExpiry: 'Gültig bis',
        dateOfExpiryInput: 'Gültig bis (TT.MM.JJJJ)',
        taxIdNumber: 'Pers. Steueridentifikationsnummer',
        personTaxId: 'Persönliche Steueridentifikationsnummer',
        documentTypes: {
          identityCard: 'Personalausweis',
          passport: 'Reisepass',
        },
      },
      footNote:
        'Fehlen Geschäftsführer/-innen und/oder wirtschaftlich Berechtigte mit direktem Kapital- oder Stimmrechtsanteil von über 25 Prozent? Dann schicken Sie uns eine E-Mail an ',
    },
    identInformation: {
      title: 'Identifizierungsverfahren auswählen',
      para1:
        'Entscheiden Sie sich für ein Identifizierungsverfahren. Sie können Ihre Auswahl nachträglich ändern.',
      note: 'Sie können Ihre Auswahl nachträglich ändern.',
      identOptions: {
        video: {
          label: 'Online per Videochat',
          hint: 'Identifizierung mit einem gültigen Ausweisdokument – jederzeit von überall per Smartphone, Tablet, Laptop oder Computer.',
        },
        postIdent: {
          label: 'In der Postfiliale mit POSTIDENT Coupon',
          hint: 'Identifizierung durch Vorlage des POSTIDENT Coupons in der Postfiliale.',
          warning:
            'Bitte schicken Sie uns züsatzlich eine Kopie Ihres Ausweisdokuments (Vorder- und Rückseite) an ',
        },
      },
    },
    termsAgreement: {
      title: 'Antrag abschicken',
      para1: {
        description:
          'Mit dem Klicken auf „{boldText}” bestätige ich, dass es keine weiteren Geschäftsführer/-innen und wirtschaftliche Berechtigten gibt.',
        boldText: 'Abschicken',
      },
      para2:
        'Aktuell erhält nur der antragstellende Geschäftsführer oder die Geschäftsführerin Zugang zu unserem Business Banking Home.',
    },

    form: {
      buttons: {
        cancel: 'Abbrechen',
        save: 'Speichern',
        edit: 'Bearbeiten',
        submit: 'Daten abschicken',
      },
      address: {
        isDifferentCheck: 'Abweichende Postanschrift.',
        street: 'Straße',
        houseNumber: 'Hausnummer',
        postalCode: 'PLZ',
        city: 'Stadt',
        country: 'Land',
      },
    },
    successPage: {
      title: 'Sie haben Ihre Daten erfolgreich übermittelt.',
      message:
        'Wir melden uns mit Ihren Vertragsunterlagen und Informationen zur Identifizierung bei Ihnen zurück, wenn alles passt.',
    },
    maintenancePage: {
      title: 'Die Webseite ist vorübergehend nicht verfügbar.',
      message:
        'Derzeit finden planmäßige Wartungsarbeiten statt. Bis unsere Webseite wieder online ist, bitten wir Sie um ein wenig Geduld. Bitte schauen Sie bald wieder vorbei. Wir entschuldigen uns für die Unannehmlichkeiten. ',
    },
  },
};
