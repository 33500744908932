<template>
  <div>
    <IngButton class="outlined small open-preview-text" @click="showContract">
      {{ $t('offer.contractSection.contracts.preview') }}
    </IngButton>
    <div :class="{ hidden: !show }" class="modal">
      <div class="modal-dialog modal-content">
        <div class="close-modal-container" @click="hideContract">
          {{ $t('offer.contractSection.contracts.close') }}
          <img src="/ing_close_icon.svg" alt="close" class="close-icon" />
        </div>
        <canvas ref="contact-canvas" />
        <div class="pagination-container">
          <img
            class="pagination-arrow"
            src="/ing_chevron_left.svg"
            alt="back"
            @click="goBack"
          />
          <p class="footer-pagination">
            {{ $t('offer.contractSection.contracts.page') }}
            {{ activePage }} / {{ maxPage }}
          </p>
          <img
            class="pagination-arrow"
            src="/ing_chevron_right.svg"
            alt="forward"
            @click="goNext"
          />
        </div>
      </div>
      <div class="modal-backdrop fade in" />
    </div>
  </div>
</template>

<script>
  import pdfjs from '@/utils/pdfjsLib.js';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';

  export default {
    name: 'ContractModal',
    components: { IngButton },
    props: {
      contractDataString: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        activePage: 1,
        scale: 1,
        show: false,
        maxPage: '',
      };
    },
    methods: {
      async showContract() {
        await this.renderPdfPage();
        this.show = true;
      },
      hideContract() {
        this.show = false;
      },
      goNext() {
        if (this.activePage === this.maxPage) {
          return;
        }
        this.activePage++;
        this.renderPdfPage();
      },
      goBack() {
        if (this.activePage <= 1) {
          return;
        }
        this.activePage--;
        this.renderPdfPage();
      },
      renderDocument() {
        return pdfjs.getDocument({
          data: atob(this.contractDataString),
        });
      },
      async renderPdfPage() {
        this.renderDocument().promise.then(
          (pdf) => {
            this.maxPage = pdf.numPages;
            pdf.getPage(this.activePage).then((page) => {
              const viewport = page.getViewport({ scale: this.scale });
              const canvas = this.$refs['contact-canvas'];
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext);
            });
          },
          (error) => {
            // PDF loading error
            // eslint-disable-next-line
            console.error(error);
          }
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  @import 'src/sass/components/common/ing.orange.juice.scss';

  .open-preview-text {
    margin-right: 10px;
    cursor: pointer;
    height: 36px;
    font-size: 16px;
  }
  .modal {
    &.hidden {
      visibility: hidden;
    }

    display: block;

    .modal-content {
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 100vh;
    }
    .pagination-container {
      display: flex;
      flex-direction: row;
      width: 200px;
      justify-content: space-between;
      position: relative;
      bottom: 20px;
      margin: 0 auto;
      padding: 5px;
      .pagination-arrow {
        cursor: pointer;
        height: 20px;
      }
      .footer-pagination {
        color: $Primary-Grey800;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .close-icon {
      padding-left: 5px;
    }
    .close-modal-container {
      display: flex;
      flex-direction: row;
      position: relative;
      top: 24px;
      right: 24px;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
      color: $Primary-Orange;
      font-weight: bold;
      font-size: 14px;
    }
  }
</style>
