<template>
  <svg
    :class="{ up: up }"
    class="arrow-down"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L7 7L13 1"
      stroke="#2E2E2E"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      up: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped lang="scss">
  .arrow-down {
    transform: rotate(0deg);
    transition: transform 0.2s ease;

    &.up {
      transform: rotate(180deg);
      transition: transform 0.2s ease;
    }
  }
</style>
