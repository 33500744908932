<template>
  <div>
    <label class="label-checkbox">
      <input v-model="homeAddressIsPostalAddress" type="checkbox" />
      <span class="check" />
      <p class="label-text ing-label">
        {{ $t('forms.signupForm.postalAddress.checkbox.client') }}
      </p>
    </label>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';

  export default {
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['signers']),
      homeAddressIsPostalAddress: {
        get() {
          return this.signers[this.signerId].homeAddressIsPostalAddress;
        },
        set(newValue) {
          this.SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS({
            signerId: this.signerId,
            homeAddressIsPostalAddress: newValue,
          });
          this.handleAddressChange(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'CLEAR_CLIENT_POSTAL_ADDRESS',
      ]),
      handleAddressChange(newAddressvalue) {
        if (newAddressvalue) {
          this.CLEAR_CLIENT_POSTAL_ADDRESS(this.signerId);
        } else {
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(this.signerId);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../../sass/custom/variables';
  .label-text {
    line-height: 22px;
    padding-left: 30px;
  }
</style>
