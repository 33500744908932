export const cashIntensityValues = {
  LOW: 'bis 25 %',
  HIGH: 'mehr als 25 %',
};

export const cashIntensityOptions = [
  {
    label: cashIntensityValues.LOW,
    value: Object.keys(cashIntensityValues)[0],
  },
  {
    label: cashIntensityValues.HIGH,
    value: Object.keys(cashIntensityValues)[1],
  },
];

export const IsValidCashIntensity = (cashIntensityValue) => {
  const validCashIntensityValues = Object.keys(cashIntensityValues);
  return validCashIntensityValues.includes(cashIntensityValue);
};
