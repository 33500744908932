import {
  isEmpty,
  hasDigit,
  hasSpecialCharsExceptHyphenAndDot,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateLastName(lastName) {
  if (isEmpty(lastName)) {
    return { lastName: 'LastNameIsRequired' };
  }

  if (
    hasForbiddenCharacters(lastName) ||
    hasDigit(lastName) ||
    hasSpecialCharsExceptHyphenAndDot(lastName)
  ) {
    return { lastName: 'ForbiddenCharacters' };
  }

  return { lastName: undefined };
}
