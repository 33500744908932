<template>
  <len-country-input
    :name="'country-primary-address-' + signerId"
    :label="$t('forms.signupForm.country.label')"
    :initialCountry="signers[signerId].country"
    :error="errorMessage"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    :preferredCountries="['DE']"
    @change="onChange($event)"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenCountryInput from '@/components/common/len-country-input';

  export default {
    components: { LenCountryInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      errorMessage() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].country &&
          this.$t('forms.errors.' + this.errors[this.signerId].country)
        );
      },
    },
    methods: {
      onChange(iso) {
        this.SET_COUNTRY({
          signerId: this.signerId,
          country: iso,
        });
      },
      ...mapMutations('signers', ['SET_COUNTRY']),
    },
  };
</script>
