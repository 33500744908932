<template>
  <len-text-input
    v-model="partnerLastName"
    :label="$t('partnerSubmissionPageNew.form.partnerLastName.label')"
    :error="
      validationErrors.partnerLastName &&
      $t('forms.errors.' + validationErrors.partnerLastName)
    "
    :displayErrorIfDirty="!submitClicked"
    name="PartnerLastName"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      partnerLastName: {
        get() {
          return this.$store.state.partner.partnerLastName;
        },
        set(value) {
          this.SET_PARTNER_LAST_NAME(value);
        },
      },
      ...mapState('partner', ['validationErrors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('partner', ['SET_PARTNER_LAST_NAME']),
    },
  };
</script>
