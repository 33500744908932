<template>
  <div class="ing-wrapper">
    <TheHeader />
    <PageSection
      class="section-form"
      :class="{ 'section-form--full-height': !yetToRegister }"
      sectionPadding="no"
    >
      <template #section-content>
        <IngBecomePartnerForm @partner-register-success="partnerRegistered" />
      </template>
    </PageSection>
    <TheContact v-if="yetToRegister" container="partnerRegister" />
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import TheContact from '@/components/ingOrangeJuice/Structure/TheContact';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngBecomePartnerForm from '@/components/partner/forms/IngBecomePartnerForm';
  export default {
    name: 'IngBecomePartnerPage',
    components: {
      TheHeader,
      PageSection,
      IngBecomePartnerForm,
      TheFooter,
      TheContact,
    },
    metaInfo() {
      return {
        title: this.$i18n.t('becomePartner.meta.title'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('becomePartner.meta.title'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('becomePartner.meta.description'),
          },
        ],
      };
    },
    data() {
      return {
        yetToRegister: true,
      };
    },
    methods: {
      partnerRegistered() {
        this.yetToRegister = false;
      },
    },
  };
</script>
<style scoped lang="scss">
  .section-form {
    &--full-height {
      flex-grow: 1;
    }
  }
</style>
