<template>
  <len-text-input
    v-model="city"
    :name="'city-' + signerId"
    :label="$t('forms.signupForm.city.label')"
    :error="
      errors[signerId] &&
      errors[signerId].city &&
      $t('forms.errors.' + errors[signerId].city)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      city: {
        get() {
          return this.signers[this.signerId].city;
        },
        set(value) {
          this.SET_CITY({ signerId: this.signerId, city: value });
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(this.signerId);
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CITY',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
    },
  };
</script>
