<template>
  <ing-text-input
    v-model="city"
    :name="'city-' + signerId"
    :label="$t('forms.signupForm.city.label')"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].city &&
          this.$t('forms.errors.' + this.errors[this.signerId].city)
        );
      },
      city: {
        get() {
          return this.signers[this.signerId].city;
        },
        set(value) {
          this.SET_CITY({ signerId: this.signerId, city: value });
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(this.signerId);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CITY',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
    },
  };
</script>
