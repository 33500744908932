import IMask, { MaskedRange } from 'imask';
export const dateInputFunctionality = {
  mounted() {
    this.maskOptions = {
      mask: this.maskFormat,
      lazy: false,
      overwrite: true,
      autofix: true,
      blocks: {
        TT: {
          mask: MaskedRange,
          placeholderChar: 'T',
          from: 0,
          to: 39,
          maxLength: 2,
        },
        MM: {
          mask: MaskedRange,
          placeholderChar: 'M',
          from: 0,
          to: 19,
          maxLength: 2,
        },
        JJJJ: {
          mask: MaskedRange,
          placeholderChar: 'J',
          from: this.minYear,
          to: this.maxYear,
          maxLength: 4,
        },
      },
    };

    if (this.maskFormat === 'MM.JJJJ') {
      delete this.maskOptions.blocks.TT;
    }

    this._initMask();
  },
  beforeDestroy() {
    //TODO: find a way to properly destroy the input, "destroy" is not a valid function
    this.maskRef = null;
  },
  props: {
    maskFormat: {
      type: String,
      default: 'TT.MM.JJJJ',
    },
    error: {
      type: String,
      default: null,
    },
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    displayErrorIfDirty: {
      type: Boolean,
      default: true,
    },
    longErrorMessage: {
      type: Boolean,
      default: false,
    },
    maxYear: {
      type: Number,
      required: false,
      default: 2999,
    },
    minYear: {
      type: Number,
      required: false,
      default: 1900,
    },
  },
  data() {
    return {
      isFocused: false,
      isDirty: false,
      maskRef: null,
    };
  },
  computed: {
    shouldShowText() {
      return this.isFocused || this.value;
    },
    hasError() {
      return !!this.error && this.error.length > 0;
    },
    displayError() {
      const result = this.hasError && !this.isFocused;
      if (this.displayErrorIfDirty) {
        return this.isDirty && result;
      }
      return result;
    },
  },
  methods: {
    _initMask() {
      this.maskRef = new IMask(this.$refs['date-input'], this.maskOptions).on(
        'accept',
        this.onAccept.bind(this)
      );
      this._updateValue();
    },
    onAccept() {
      this.$emit('accept', this.maskRef.value);
    },
    _updateValue() {
      const value = this.value == null ? '' : this.value;
      this.maskRef.value = value;
    },
    handleFocus() {
      this.isFocused = true;
      this.isDirty = true;

      this.$emit('focus');
    },
  },
  watch: {
    value: function (newVal) {
      if (newVal !== this.maskRef.value) {
        this._updateValue();
      }
    },
  },
};
