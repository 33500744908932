<template>
  <fieldset v-if="isBookkeepingVisible" :class="{ error: displayError }">
    <div>
      <div class="bookkeeping-header-container">
        <label>{{ label }}</label>
        <div class="tooltip-wrapper">
          <span @mouseover="showTooltip()" @mouseleave="hideTooltip()">
            <ing-tooltip-icon class="ing-tooltip" />
          </span>
        </div>
        <div
          :class="{ active: mouseOver }"
          class="ing-tooltip-message"
          @mouseover="showTooltip()"
          @mouseleave="hideTooltip()"
          v-html="$t('forms.signupForm.bookkeeping.ingTooltip')"
        />
      </div>

      <div class="form-group revenue-input-container">
        <len-radio
          v-for="item in bookkeepingOptions"
          :key="item.id"
          v-model="bookkeepingStandard"
          :label="item.name"
          :value="item.name"
          name="loanPurpose__c"
          :hasError="displayError"
          :data-qa-id="item.dataQaId"
        />
      </div>
    </div>
    <div :class="{ active: displayError }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p>
        {{
          errors.bookkeepingStandard &&
          $t('forms.errors.' + errors.bookkeepingStandard)
        }}
      </p>
    </div>
  </fieldset>
</template>

<script>
  import { mapGetters, mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import IngTooltipIcon from '@/components/ing/ing-tooltip-icon';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTooltipIcon, LenRadio },
    mixins: [ingFormFieldGTMTrackingMixin],
    props: {
      isTermLoan: {
        required: true,
        type: Boolean,
      },
    },
    data() {
      return {
        bookkeepingOptions: [
          {
            id: this.$t('forms.signupForm.bookkeeping.dropdown.hgb'),
            name: this.$t('forms.signupForm.bookkeeping.dropdown.hgb'),
            dataQaId: 'balance-sheet',
          },
          {
            id: this.$t(
              'forms.signupForm.bookkeeping.dropdown.gewinnUndVerlustrechnung'
            ),
            name: this.$t(
              'forms.signupForm.bookkeeping.dropdown.gewinnUndVerlustrechnung'
            ),
            dataQaId: 'income-statement',
          },
          {
            id: this.$t('forms.signupForm.bookkeeping.dropdown.unbekannt'),
            name: this.$t('forms.signupForm.bookkeeping.dropdown.unbekannt'),
            dataQaId: 'unknown',
          },
        ],
        mouseOver: false,
        timeoutId: null,
        bilanzLegalStatus: [
          'GmbH',
          'GmbH & Co. KG',
          'AG',
          'KG',
          'OHG',
          'UG (haftungsbeschränkt)',
          'Limited',
        ],
      };
    },
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked', 'legalStatus']),
      ...mapState('signupForm', { bkStandard: 'bookkeepingStandard' }),
      ...mapGetters('signupForm', ['isBookkeepingVisible']),
      label() {
        return this.$t(
          `forms.signupForm.bookkeeping.label.${
            this.isTermLoan ? 'termLoan' : 'wcl'
          }`
        );
      },
      displayError() {
        return this.submitClicked && !!this.errors.bookkeepingStandard;
      },
      bookkeepingStandard: {
        get() {
          return this.bkStandard;
        },
        set(newValue) {
          this.SET_BOOKKEEPING_STANDARD(newValue);
          this.$emit('select');
          this.sendFieldTrackingEvent(`radio:${this.label}`, newValue);
        },
      },
    },
    watch: {
      legalStatus: function (newVal, oldVal) {
        this.SET_BOOKKEEPING_VISIBILITY();
        if (this.bilanzLegalStatus.includes(newVal)) {
          this.SET_BOOKKEEPING_STANDARD('Bilanz (HGB/Steuerrecht)');
        } else if (
          this.bilanzLegalStatus.includes(oldVal) &&
          !this.bilanzLegalStatus.includes(newVal)
        ) {
          this.SET_BOOKKEEPING_STANDARD(null);
        }
      },
    },
    beforeDestroy() {
      clearTimeout(this.timeoutId);
    },
    methods: {
      ...mapMutations('signupForm', [
        'SET_BOOKKEEPING_STANDARD',
        'SET_BOOKKEEPING_VISIBILITY',
      ]),
      showTooltip() {
        this.mouseOver = true;
        clearTimeout(this.timeoutId);
      },
      hideTooltip() {
        this.timeoutId = setTimeout(() => (this.mouseOver = false), 500);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .bookkeeping-header-container {
    display: flex;
    flex-direction: row;
    position: relative;

    .tooltip-wrapper {
      margin-left: 15px;
    }
    .ing-tooltip-message {
      height: fit-content;
      bottom: 35px;
      right: auto;
      left: -50px;
      width: 400px;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 100%;
        margin-left: -3px;
        height: 0;
        width: 0;
        border: 5px solid transparent;
        border-top: 5px solid #767676;
        display: block;
        z-index: 200;
      }
    }

    @media screen and (max-width: $tablet) {
      .ing-tooltip-message {
        left: 50px;
        width: 300px;

        &:before {
          left: 99px;
        }
      }
    }

    @media screen and (max-width: $mobile-l) {
      .ing-tooltip-message {
        width: 100%;
        left: auto;

        &:before {
          left: 150px;
        }
      }
    }
  }
</style>
