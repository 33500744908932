<template>
  <div>
    <div class="controlling-company-overview-container">
      <div class="col-lg-7 col-sm-10">
        <controlling-company-title />
        <div id="controllingLegalNameOverview" class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.companyName.label') }}:</em>
            <tooltip
              key="company-name-tooltip"
              :tooltipMessage="$t('forms.signupForm.companyName.tooltip')"
              messageClass="company-name-tooltip-message"
            />
          </p>
          <p class="col-sm-6">
            {{ controllingCompany.legalName | emptyValueReplacer }}
          </p>
        </div>
        <div id="controllingCountryOfIncorporationOverview" class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.countryOfIncorporation.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              controllingCompany.countryOfIncorporation
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import ControllingCompanyTitle from '@/components/signers/signers-company-information/controlling-company-title';
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: {
      ControllingCompanyTitle,
      Tooltip,
    },
    computed: {
      ...mapState('signers', ['controllingCompany']),
    },
  };
</script>

<style scoped>
  .controlling-company-overview-container {
    display: flex;
    justify-content: space-between;
    padding: 50px 0 30px 0;
  }
</style>
