import mutations from './mutations';
import * as actions from './actions';
import { partnerInformationValidator } from '@/validators';
import * as getters from './getters';

export const initialState = () => {
  return {
    partnerCompanyName: null,
    partnerEmail: null,
    partnerFirstName: null,
    partnerLastName: null,
    partnerAccountId: null,
    partnerContactId: null,
    partnerAccepted: null,
    requestError: {},
    validationErrors: {},
    submitClicked: false,
    loading: false,
  };
};

const state = initialState();

state.validationErrors = partnerInformationValidator(state);

export default { state, actions, mutations, getters, namespaced: true };
