<template>
  <len-text-input
    v-model="city"
    :label="label"
    :error="errors.city && $t('forms.errors.' + errors.city)"
    :displayErrorIfDirty="!submitClicked"
    name="city"
    data-qa-id="company-info-city"
    @focus="handleFocus"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenTextInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.city.label');
      },
      city: {
        get() {
          return this.$store.state.signupForm.city;
        },
        set(value) {
          this.SET_CITY(value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_CITY']),
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
