<template>
  <fieldset
    class="form-field addMaterialDesign fieldset-container"
    data-qa-id="company-info-revenue"
  >
    <div class="form-group">
      <div class="radio__wrapper form-group">
        <div class="radio__label">
          {{ label }}
        </div>
        <len-radio
          v-model="revenue"
          :label="$t('forms.signupForm.revenue.dropdown.after50K')"
          value="after50K"
          name="Revenue__c"
          :hasError="displayError"
        />
        <len-radio
          v-model="revenue"
          :label="$t('forms.signupForm.revenue.dropdown.before50K')"
          value="before50K"
          name="Revenue__c"
          :hasError="displayError"
        />
      </div>
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{ errors.revenue && $t('forms.errors.' + errors.revenue) }}
        </p>
      </div>
    </div>
  </fieldset>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  import LenRadio from '@/components/common/len-radio';
  export default {
    components: { LenRadio },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.revenue.label');
      },
      displayError() {
        return this.submitClicked && !!this.errors.revenue;
      },
      revenue: {
        get() {
          return this.$store.state.signupForm.revenue;
        },
        set(value) {
          this.SET_REVENUE(value);
          this.sendFieldTrackingEvent(
            `radio:${this.label}`,
            this.$t(`forms.signupForm.revenue.dropdown.${value}`)
          );
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_REVENUE']),
    },
  };
</script>
<style scoped lang="scss">
  @import '@/variables.scss';

  .radio {
    &__wrapper {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      align-items: center;
      min-height: 34px;

      > label.radio-container {
        margin: 0;
        font-size: 16px;
      }
    }

    &__label {
      font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
      display: inline-block;
      font-size: 16px;
      line-height: 18px;
      color: #2e2e2e;
    }
  }

  .error-container {
    height: 20px;
    padding-left: 193px;
    width: 100%;
    margin-top: -4px;
    margin-bottom: 9px;
  }
  .validation {
    margin-bottom: 0;
    margin-top: 0;
  }

  @media (max-width: $desktop-s) {
    .radio {
      &__wrapper {
        justify-content: flex-start;
        > label.radio-container {
          flex: 0.35;
        }
      }
      &__label {
        width: 100%;
        margin-bottom: 15px;
        margin-right: 0;
      }
    }

    .error-container {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 5px;
    }
    .validation {
      margin-top: 0;
    }
  }

  @media (max-width: $tablet) {
    .radio {
      &__wrapper {
        > label.radio-container {
          flex: 0.5;
        }
      }
    }
  }

  @media (max-width: $mobile-l) {
    .radio {
      &__wrapper {
        flex-direction: column;
        align-items: flex-start;
        > label.radio-container {
          flex: auto;

          &:last-child {
            margin-top: 15px;
          }
        }
      }
    }
  }
</style>
