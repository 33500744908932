import { render, staticRenderFns } from "./ing-tax-number-field.vue?vue&type=template&id=614a5dbe&scoped=true"
import script from "./ing-tax-number-field.vue?vue&type=script&lang=js"
export * from "./ing-tax-number-field.vue?vue&type=script&lang=js"
import style0 from "./ing-tax-number-field.vue?vue&type=style&index=0&id=614a5dbe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "614a5dbe",
  null
  
)

export default component.exports