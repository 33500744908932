<template>
  <div class="tax-number-wrapper">
    <ing-text-input-tooltip
      v-model="taxNumber"
      :label="$t('forms.signupForm.companyTaxNumber.label')"
      :error="
        errors.company &&
        errors.company.companyTaxNumber &&
        $t('forms.errors.' + errors.company.companyTaxNumber)
      "
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-tax-number"
      :tooltipMessage="$t('forms.signupForm.companyTaxNumber.tooltip')"
      messageClass="tax-number-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';
  export default {
    components: { IngTextInputTooltip },
    computed: {
      taxNumber: {
        get() {
          return this.company.companyTaxNumber;
        },
        set(value) {
          this.SET_COMPANY_TAX_NUMBER(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_TAX_NUMBER']),
    },
  };
</script>

<style lang="scss" scoped>
  .tax-number-wrapper {
    position: relative;
  }
  .tax-number-tooltip {
    position: absolute;
    left: 90px;
    top: 5px;
    &.on-input-focus {
      top: -12px;
    }
  }
</style>
