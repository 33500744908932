import {
  isEmpty,
  hasWrongLength,
  isNotNumeric,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';
import {
  WRONG_CREDENTIALS,
  TOO_MANY_WRONG_ATTEMPTS,
  LINK_EXPIRED,
  LINK_DEACTIVATED,
  BAD_PARAMS,
} from '@/constants/ERROR';

export const validatePassword = (password) => {
  if (isEmpty(password)) {
    return false;
  }
  if (isNotNumeric(password)) {
    return false;
  }
  if (hasWrongLength(password, 5)) {
    return false;
  }
  if (hasForbiddenCharacters(password)) {
    return false;
  }
  return true;
};

export const isValidPassword = {
  computed: {
    isValidPassword() {
      return validatePassword(this.password);
    },
  },
};

export const serverI18nErrorMessage = (i18lang, error) => {
  if (!error.status) {
    return '';
  }
  switch (error.data.errorType) {
    case BAD_PARAMS:
      return `${i18lang}.form.errors.passWordIsIncorrect`;
    case WRONG_CREDENTIALS:
      return `${i18lang}.form.errors.passWordIsIncorrect`;
    case TOO_MANY_WRONG_ATTEMPTS:
      return `${i18lang}.form.errors.passWordIsBlocked`;
    case LINK_EXPIRED:
    case LINK_DEACTIVATED:
      return `${i18lang}.form.errors.passwordHasExpired`;
    default:
      return `${i18lang}.form.errors.generalError`;
  }
};

export const errorI18nMessage = (password, i18lang, error) => {
  if (!validatePassword(password)) {
    return `${i18lang}.form.errors.passWordFormatIsInvalid`;
  } else {
    return serverI18nErrorMessage(i18lang, error);
  }
};

export const errorMessage = {
  computed: {
    errorMessage() {
      return this.$t(
        errorI18nMessage(this.password, 'authentication', this.error)
      );
    },
  },
};

export const serverErrorMessage = {
  computed: {
    serverErrorMessage() {
      return this.$t(serverI18nErrorMessage('authentication', this.error));
    },
  },
};

export const showEmail = {
  computed: {
    showEmail() {
      if (!this.error.status) {
        return false;
      }
      return [TOO_MANY_WRONG_ATTEMPTS, LINK_EXPIRED, LINK_DEACTIVATED].includes(
        this.error.data.errorType
      );
    },
  },
};
