<template>
  <len-text-input
    v-model="partnerCompanyName"
    :label="$t('partnerSubmissionPageNew.form.partnerCompanyName.label')"
    :error="
      validationErrors.partnerCompanyName &&
      $t('forms.errors.' + validationErrors.partnerCompanyName)
    "
    :displayErrorIfDirty="!submitClicked"
    class="partner-company-name"
    name="PartnerCompanyName"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      partnerCompanyName: {
        get() {
          return this.$store.state.partner.partnerCompanyName;
        },
        set(value) {
          this.SET_PARTNER_COMPANY_NAME(value);
        },
      },
      ...mapState('partner', ['validationErrors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('partner', ['SET_PARTNER_COMPANY_NAME']),
    },
  };
</script>

<style scoped lang="scss">
  .partner-company-name {
    :deep(.form-group) {
      padding-bottom: 0;
    }
  }
</style>
