<template>
  <len-text-input
    v-model="partnerFirstName"
    :label="$t('partnerSubmissionPageNew.form.partnerFirstName.label')"
    :error="
      validationErrors.partnerFirstName &&
      $t('forms.errors.' + validationErrors.partnerFirstName)
    "
    :displayErrorIfDirty="!submitClicked"
    name="PartnerFirstName"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      partnerFirstName: {
        get() {
          return this.$store.state.partner.partnerFirstName;
        },
        set(value) {
          this.SET_PARTNER_FIRST_NAME(value);
        },
      },
      ...mapState('partner', ['validationErrors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('partner', ['SET_PARTNER_FIRST_NAME']),
    },
  };
</script>
