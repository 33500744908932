import {
  isEmpty,
  hasWrongLength,
  isNotNumeric,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidatePostcodeDe(postcodeDe) {
  if (isEmpty(postcodeDe)) {
    return { postcodeDe: 'PostcodeDeIsRequired' };
  }
  if (hasForbiddenCharacters(postcodeDe)) {
    return { postcodeDe: 'ForbiddenCharacters' };
  }
  if (isNotNumeric(postcodeDe)) {
    return { postcodeDe: 'PostcodeDeIsNAN' };
  }
  if (hasWrongLength(postcodeDe, 5)) {
    return { postcodeDe: 'PostcodeDeIsInvalidLength' };
  }

  return { postcodeDe: undefined };
}
