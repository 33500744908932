<template>
  <div ref="container" class="form-group addMaterialDesign">
    <label :for="'identificationIdType-' + signerId" class="id-label">
      {{ $t('forms.signupForm.identificationIdType.label') }}
    </label>
    <div class="radio-input-container">
      <len-radio
        v-model="identificationIdType"
        :label="$t('forms.signupForm.identificationIdType.dropdown.ID')"
        :name="'identificationIdType-' + signerId"
        :value="
          idTypeValues[$t('forms.signupForm.identificationIdType.dropdown.ID')]
        "
      />
      <len-radio
        v-model="identificationIdType"
        :label="$t('forms.signupForm.identificationIdType.dropdown.PASSPORT')"
        :name="'identificationIdType-' + signerId"
        :value="
          idTypeValues[
            $t('forms.signupForm.identificationIdType.dropdown.PASSPORT')
          ]
        "
      />
      <p
        :class="{ active: identificationIdType === 'PASSPORT' }"
        class="extra-document-explanation"
      >
        {{ $t('forms.signupForm.identificationIdType.footnote') }}
        <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a>
      </p>
    </div>
    <div
      role="alert"
      class="text-block text-align--right display--block error-container"
    >
      <p v-if="displayError" class="validation">
        {{
          errors[signerId] &&
          errors[signerId].identificationIdType &&
          $t('forms.errors.' + errors[signerId].identificationIdType)
        }}
      </p>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { idTypeValues } from '@/validators/helpers/identificationIdType';
  import { isPartnerChannel } from '@/mixins/signersMixin';
  export default {
    components: { LenRadio },
    mixins: [isPartnerChannel],
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        idTypeValues,
      };
    },
    computed: {
      displayError() {
        return (
          (this.submitClicked || this.signers[this.signerId].submitClicked) &&
          !!this.errors[this.signerId] &&
          !!this.errors[this.signerId].identificationIdType
        );
      },
      identificationIdType: {
        get() {
          return this.signers[this.signerId].identificationIdType;
        },
        set(value) {
          this.SET_IDENTIFICATION_ID_TYPE({
            signerId: this.signerId,
            identificationIdType: value,
          });
        },
      },
      contactEmail() {
        if (this.isPartnerChannel) {
          return this.$t('partnerContactEmail');
        } else {
          return this.$t('companiesEmail');
        }
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENTIFICATION_ID_TYPE']),
    },
  };
</script>
<style scoped>
  a {
    color: #3cbdb9;
  }
  .id-label {
    line-height: 2;
    font-size: 14px;
    font-weight: 400;
  }
  .radio-input-container {
    display: flex;
    justify-content: flex-start;
    gap: 1rem;
  }
  .error-container {
    height: 25px;
  }
  .validation {
    text-align: left;
  }
  .extra-document-explanation {
    visibility: hidden;
    font-size: 12px;
    color: #2e2e2e;
    width: 252px;
  }
  .extra-document-explanation.active {
    visibility: visible;
  }

  @media (max-width: 767px) {
    .radio-input-container {
      flex-direction: column;
    }
    .extra-document-explanation {
      width: fit-content;
      max-width: 252px;
    }
  }
</style>
