import {
  hasDigit,
  hasSpecialCharsExceptHyphenAndDot,
  isEmpty,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidatePartnerFirstName(partnerFirstName) {
  if (isEmpty(partnerFirstName)) {
    return { partnerFirstName: 'PartnerFirstNameIsRequired' };
  }
  if (
    hasForbiddenCharacters(partnerFirstName) ||
    hasDigit(partnerFirstName) ||
    hasSpecialCharsExceptHyphenAndDot(partnerFirstName)
  ) {
    return { partnerFirstName: 'ForbiddenCharacters' };
  }
  return { partnerFirstName: undefined };
}
