<template>
  <div class="company-representative-container">
    <div class="col-sm-12">
      <representative-information-header />
    </div>
    <div ref="salutation-field">
      <salutation-field />
    </div>
    <div class="representative-info-container row">
      <div class="col-sm-6 first-column">
        <div class="row">
          <firstname-field />
          <lastname-field />
          <date-of-birth-field />
          <nationality-field />
          <mobile-phone-field />
        </div>
      </div>
      <div class="col-sm-6 second-column">
        <div class="row">
          <email-field />
          <user-street-field />
          <user-postcode-field />
          <user-city-field @filled="$emit('last-field-filled')" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RepresentativeInformationHeader from './representative-information/representative-information-header.vue';
  import SalutationField from './representative-information/salutation-field.vue';
  import FirstnameField from './representative-information/firstname-field.vue';
  import LastnameField from './representative-information/lastname-field.vue';
  import EmailField from './representative-information/email-field.vue';
  import DateOfBirthField from './representative-information/date-of-birth-field.vue';
  import UserStreetField from './representative-information/user-street-field.vue';
  import UserPostcodeField from './representative-information/user-postcode-field.vue';
  import UserCityField from './representative-information/user-city-field.vue';
  import NationalityField from './representative-information/nationality-field.vue';
  import MobilePhoneField from './representative-information/mobile-phone-field.vue';
  import { goToElement } from '@/mixins/scrollMixin';

  export default {
    components: {
      RepresentativeInformationHeader,
      SalutationField,
      FirstnameField,
      LastnameField,
      EmailField,
      DateOfBirthField,
      UserStreetField,
      UserPostcodeField,
      UserCityField,
      NationalityField,
      MobilePhoneField,
    },
    mixins: [goToElement],
    methods: {
      goTo() {
        this.goToElement('salutation-field');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .company-representative-container {
    margin-bottom: 50px;
  }
  .representative-info-container {
    margin-top: 30px;
  }
  .first-column {
    vertical-align: top;
    padding-right: 3.5%;
  }
  .second-column {
    vertical-align: top;
    padding-left: 3.5%;
  }

  @media (max-width: $desktop-s) {
    .first-column {
      padding-right: 3.5%;
    }
    .second-column {
      padding-left: 3.5%;
    }
  }
  @media (max-width: $mobile-l) {
    .company-representative-container {
      margin-bottom: 12px;
    }
    .representative-info-container {
      margin-top: 25px;
    }
    .first-column {
      margin-top: 0;
      padding-right: 0;
    }
    .second-column {
      margin-top: 0;
      padding-left: 0;
    }
  }
</style>
