<template>
  <len-country-input
    :name="'second-nationality-' + signerId"
    :label="$t('forms.signupForm.secondNationality.label')"
    :initialCountry="signers[signerId].secondNationality"
    :error="error || duplicatedNationalityMessage"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    :preferredCountries="dropdownPreferredCountries"
    :longErrorMessage="true"
    @change="onChange"
  />
</template>
<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import LenCountryInput from '@/components/common/len-country-input';
  import { dropdownPreferredCountries } from '@/utils/dropdownPreferredCountries';

  export default {
    components: { LenCountryInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dropdownPreferredCountries,
      };
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      ...mapGetters('signers', ['nationalityEqualsSecondNationality']),
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].secondNationality &&
          this.$t(
            'forms.errors.' + this.errors[this.signerId].secondNationality
          )
        );
      },
      duplicatedNationalityMessage() {
        if (this.nationalityEqualsSecondNationality(this.signerId)) {
          return this.$t('forms.errors.NationalityEqualsSecondNationality');
        } else {
          return null;
        }
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_SECOND_NATIONALITY']),
      onChange(iso) {
        this.SET_SECOND_NATIONALITY({
          signerId: this.signerId,
          secondNationality: iso,
        });
      },
    },
  };
</script>

<style scoped>
  :deep(ul) {
    top: -6px;
  }
</style>
