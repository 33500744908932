<template>
  <div class="company-assets-wrapper">
    <ing-text-input-tooltip
      v-model="assets"
      :label="$t('forms.signupForm.companyAssets.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-assets"
      data-unit-test="ing-company-assets-field"
      :tooltipMessage="$t('forms.signupForm.companyAssets.tooltip')"
      messageClass="company-assets-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';

  export default {
    components: { IngTextInputTooltip },
    computed: {
      assets: {
        get() {
          if (this.company.assets) {
            return this.$options.filters.euroFormatter(this.company.assets);
          } else {
            return null;
          }
        },
        set(value) {
          value = this.$options.filters.removeEuroFormat(value);
          if (isNaN(value) || !value) {
            this.SET_COMPANY_ASSETS('');
          } else {
            value = parseFloat(value).toFixed(2);
            this.SET_COMPANY_ASSETS(value);
          }
        },
      },
      error() {
        return (
          this.errors.company &&
          this.errors.company.assets &&
          this.$t('forms.errors.' + this.errors.company.assets)
        );
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_ASSETS']),
    },
  };
</script>

<style lang="css" scoped>
  .company-assets-wrapper {
    position: relative;
  }
</style>
