import axios from 'axios';
import { controllingCompanyLegalForm } from '@/validators/helpers/legalStatus';
import { trimPayloadData } from '@/utils/trimPayloadData';
const axiosWithCredentials = axios.create({ withCredentials: true });

function createSignerPayload(signer) {
  const homeAddress = {
    street: signer.street,
    num: signer.streetNumber,
    zip: signer.zip,
    city: signer.city,
    country: signer.country,
  };
  const postalAddress = {
    street: signer.postalStreet,
    num: signer.postalStreetNumber,
    zip: signer.postalZip,
    city: signer.postalCity,
    country: signer.postalCountry,
  };
  const signerPayload = {
    clientId: signer.clientId,
    gender: signer.gender,
    roles: signer.roles,
    title: signer.title,
    firstName: signer.firstName,
    lastName: signer.lastName,
    lastNameAtBirth: signer.lastNameAtBirth,
    birthday: signer.birthday,
    countryOfBirth: signer.countryOfBirth,
    homeAddressIsPostalAddress: !signer.homeAddressIsPostalAddress,
    cityOfBirth: signer.cityOfBirth,
    nationality: signer.nationality,
    secondNationality: signer.secondNationality,
    email: signer.email,
    mobilePhone: signer.mobilePhone,
    identificationIdType: signer.identificationIdType,
    identificationIdNumber: signer.identificationIdNumber,
    identificationIssuingAuthority: signer.identificationIssuingAuthority,
    identificationPlaceOfIssue: signer.identificationPlaceOfIssue,
    identificationIssuingDate: signer.identificationIssuingDate,
    identificationExpiryDate: signer.identificationExpiryDate,
    taxNumber: signer.taxNumber,
    homeAddress,
    postalAddress,
  };

  return trimPayloadData(signerPayload);
}

function resolvePath(target) {
  return `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/loan-request/kyc/${target}`;
}

export async function getSignersMissingDetails({ commit }) {
  await axiosWithCredentials
    .get(resolvePath('persons'))
    .then(({ data }) => {
      if (data) {
        commit('SET_SIGNERS', data);
      }
      return data;
    })
    .catch((error) => {
      const { status, data } = error.response || '';
      commit('SET_REQUEST_ERROR', { status, data });
    });
}

export async function getCompanyDetails({ commit }) {
  await axiosWithCredentials
    .get(resolvePath('company'))
    .then(({ data }) => {
      if (data) {
        commit('SET_OFFER_INFORMATION', data);
        commit('SET_COMPANY_INFORMATION', data);
        commit('SET_CONTROLLING_COMPANY', data);
        commit('SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS');
      }
      return data;
    })
    .catch((error) => {
      const { status, data } = error.response || '';
      commit('SET_REQUEST_ERROR', { status, data });
    });
}

export async function updateSignerMissingDetails({ state, commit }, signerId) {
  const { opportunityId, signers } = state;

  try {
    const response = await axiosWithCredentials.patch(resolvePath('persons'), {
      opportunityId,
      clients: [createSignerPayload(signers[signerId])],
      identificationProcess: 'OTHER',
    });
    commit('SET_SUCCESS', response.data.time);
  } catch (error) {
    const { status, data } = error.response;
    commit('SET_CLIENT_REQUEST_ERROR', { status, data });
  }
}

export async function updateSignersMissingDetails({ state, commit }) {
  const { opportunityId, signers, identificationProcess } = state;
  const signerValues = Object.values(signers);
  let clients = [];
  if (signerValues.length) {
    clients = signerValues.map((signer) => createSignerPayload(signer));
  }
  let response;

  try {
    response = await axiosWithCredentials.patch(resolvePath('persons'), {
      opportunityId,
      clients,
      identificationProcess,
    });
    commit('SET_SUCCESS', response.data.stateChanged);
  } catch (error) {
    const { status, data } = error.response;
    commit('SET_REQUEST_ERROR', { status, data });
  }
}

export async function updateCompanyMissingDetails({
  state,
  commit,
  rootGetters,
}) {
  const { opportunityId, company } = state;
  const {
    companyClientId,
    companyName,
    companyTradingName,
    countryOfIncorporation,
    companyTaxNumber,
    businessAddressIsPostalAddress,
    legalForm,
    employeesNumber,
    revenues,
    assets,
  } = company;
  const businessAddress = {
    street: company.street,
    num: company.streetNumber,
    city: company.city,
    zip: company.zip,
    country: company.country,
  };
  let postalAddress = null;

  if (company.postalStreet) {
    postalAddress = {
      street: company.postalStreet,
      num: company.postalStreetNumber,
      city: company.postalCity,
      zip: company.postalZip,
      country: company.postalCountry,
    };
  }

  const bankAccount = {
    bic: company.bic ? company.bic : state.nonEditableBankData.bic,
    iban: company.iban ? company.iban : state.nonEditableBankData.iban,
  };

  let response;

  const companyDetails = {
    companyClientId,
    companyName,
    companyTradingName,
    countryOfIncorporation,
    companyTaxNumber,
    bankAccount,
    businessAddressIsPostalAddress,
    businessAddress,
    postalAddress,
    employeesNumber,
    revenues,
    assets,
  };
  let financialDetails = {
    cashIntensity: company.cashIntensity,
    assets: company.assets,
    revenues: company.revenues,
  };
  if (
    process.env.VUE_APP_IS_BLACKSEA === 'true' &&
    process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true' &&
    rootGetters['appSettings/isAmazonApplication']
  ) {
    Object.assign(financialDetails, {
      onlineRevenuesAtLeast75Percent: company.onlineRevenuesAtLeast75Percent,
    });
  }
  Object.assign(companyDetails, {
    employeesNumber: company.employeesNumber,
    financialDetails: financialDetails,
  });

  if (
    legalForm === controllingCompanyLegalForm ||
    (process.env.VUE_APP_FEATURE_FLAG_MLLF_ENABLED === 'true' &&
      rootGetters['signers/isComplexLegalForm'])
  ) {
    Object.assign(companyDetails, {
      controllingCompanies: [state.controllingCompany],
    });
  }

  /**
   * This change is some work around for EVA-1900 and description included in
   * PR (https://github.com/Lendico/antrag/pull/763).
   */
  Object.assign(companyDetails, {
    businessAddressIsPostalAddress: !businessAddressIsPostalAddress,
  });

  trimPayloadData(companyDetails);

  try {
    response = await axiosWithCredentials.patch(resolvePath('company'), {
      opportunityId,
      companyDetails,
    });
    commit('SET_SUCCESS', response.data.time);
  } catch (error) {
    const { status, data } = error.response;
    commit('SET_COMPANY_REQUEST_ERROR', { status, data });
  }
}

export async function updateDisbursementAmount({ getters, commit }, amount) {
  if (!getters.isCreditLineProduct) {
    return;
  }

  try {
    await axiosWithCredentials.patch(resolvePath('disbursement'), {
      amount,
    });
  } catch (error) {
    const { status, data } = error.response;
    commit('SET_OFFER_CONDITIONS_REQUEST_ERROR', { status, data });
  }
}
