<template>
  <FormSubSection v-show="hasControllingCompany">
    <template #sub-section-content>
      <h3
        v-if="isFeatureFlagMLLFEnabled && isComplexLegalForm"
        class="section-title"
      >
        {{ $t('signers.companyDetails.MLLFControllingCompany.name') }}
      </h3>
      <h3 v-else class="section-title">
        {{ $t('signers.companyDetails.controllingCompany.name') }}
      </h3>
      <TextInput
        v-model="legalName"
        :label="$t('signers.companyDetails.name')"
        :error="getError('legalName')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="controllingCompanyTaxNumber"
        :label="$t('forms.signupForm.controllingCompanyTaxNumber.label')"
        :error="getError('controllingCompanyTaxNumber')"
        :displayErrorIfDirty="displayError()"
      />
      <Dropdown
        id="controllingCountryOfIncorporationField"
        v-model="controllingCountryOfIncorporation"
        :label="$t('signers.companyDetails.countryOfIncorporation')"
        :disabled="true"
        :options="countryDropdownOptions()"
        :error="getError('countryOfIncorporation')"
        :displayErrorIfSubmitted="displayError()"
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import {
    hasControllingCompany,
    isFeatureFlagMLLFEnabled,
  } from '@/mixins/signersMixin';
  import countryDropdownMixin from '@/mixins/countryDropdownMixin';
  export default {
    components: {
      TextInput,
      Dropdown,
      FormSubSection,
    },
    mixins: [
      hasControllingCompany,
      countryDropdownMixin,
      isFeatureFlagMLLFEnabled,
    ],
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'controllingCompany',
      ]),
      ...mapGetters('signers', ['isComplexLegalForm']),
      legalName: {
        get() {
          return this.controllingCompany.legalName;
        },
        set(newValue) {
          this.SET_CONTROLLING_LEGAL_NAME(newValue);
        },
      },
      controllingCompanyTaxNumber: {
        get() {
          return this.controllingCompany.controllingCompanyTaxNumber;
        },
        set(newValue) {
          this.SET_CONTROLLING_COMPANY_TAX_NUMBER(newValue);
        },
      },
      controllingCountryOfIncorporation: {
        get() {
          return this.controllingCompany.countryOfIncorporation;
        },
        set(value) {
          this.SET_CONTROLLING_COUNTRY_OF_INCORPORATION(value);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CONTROLLING_LEGAL_NAME',
        'SET_CONTROLLING_COMPANY_TAX_NUMBER',
        'SET_CONTROLLING_COUNTRY_OF_INCORPORATION',
      ]),
      getError(field) {
        return (
          this.errors.controllingCompany &&
          this.errors.controllingCompany[field] &&
          this.$t('forms.errors.' + this.errors.controllingCompany[field])
        );
      },
      displayError() {
        return !this.submitClicked && !this.companySubmitClicked;
      },
    },
  };
</script>
