export default {
  authentication: {
    form: {
      title: 'Bitte melden Sie sich mit Ihrem Passwort an.',
      explanation:
        'Verwenden Sie das Passwort, das Sie telefonisch von Ihrem Kundenbetreuer erhalten haben.',
      password: {
        label: 'Passwort',
      },
      submit: 'Einloggen',
      errors: {
        passWordFormatIsInvalid: {
          label: 'Ihr eingegebenes Passwort ist nicht korrekt.',
          message: 'Ungültiges Passwort. Bitte überprüfen Sie Ihre Eingabe.',
        },
        passWordIsIncorrect: {
          label: 'Ihr eingegebenes Passwort ist nicht korrekt.',
          message:
            'Die Anmeldung war leider nicht erfolgreich. Bitte überprüfen Sie das Passwort und wiederholen Sie Ihre Eingabe. ',
        },
        passWordIsBlocked: {
          label: 'Ihr eingegebenes Passwort ist nicht korrekt.',
          message:
            'Das Passwort wurde wiederholt falsch eingegeben. Aus Sicherheitsgründen wird Ihr Zugang für 24 Stunden gesperrt.' +
            ' Bitte versuchen Sie es nach dieser Frist erneut. Wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte an  ',
        },
        passwordHasExpired: {
          label: 'Ihr eingegebenes Passwort ist nicht korrekt.',
          message: 'Die Seite existiert nicht mehr. Bitte wenden Sie sich an ',
        },
        generalError: {
          label: 'Ihr eingegebenes Passwort ist nicht korrekt.',
          message:
            'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        },
      },
    },
  },
};
