<template>
  <div class="company-revenues-wrapper">
    <ing-text-input-tooltip
      v-model="revenues"
      :label="$t('forms.signupForm.companyRevenues.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-revenues"
      data-unit-test="ing-company-revenues-field"
      :tooltipMessage="$t('forms.signupForm.companyRevenues.tooltip')"
      messageClass="company-revenues-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';

  export default {
    components: { IngTextInputTooltip },
    computed: {
      revenues: {
        get() {
          if (this.company.revenues) {
            return this.$options.filters.euroFormatter(this.company.revenues);
          } else {
            return null;
          }
        },
        set(value) {
          value = this.$options.filters.removeEuroFormat(value);
          if (isNaN(value) || value === '') {
            this.SET_COMPANY_REVENUES('');
          } else {
            value = parseFloat(value).toFixed(2);
            this.SET_COMPANY_REVENUES(value);
          }
        },
      },
      error() {
        return (
          this.errors.company &&
          this.errors.company.revenues &&
          this.$t('forms.errors.' + this.errors.company.revenues)
        );
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_REVENUES']),
    },
  };
</script>

<style lang="css" scoped>
  .company-revenues-wrapper {
    position: relative;
  }
</style>
