<template>
  <len-text-input
    v-model="street"
    :label="$t('forms.signupForm.streetOnly.label')"
    :error="
      errors.company &&
      errors.company.street &&
      $t('forms.errors.' + errors.company.street)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="business-street"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      street: {
        get() {
          return this.company.street;
        },
        set(value) {
          this.SET_COMPANY_STREET(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_STREET',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
    },
  };
</script>
