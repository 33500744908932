import { mapGetters } from 'vuex';
import Window from '@/common/window';

export const lenFormFieldGTMTrackingMixin = {
  computed: {
    ...mapGetters('signupForm', { formTypeIsPartner: 'isPartner' }),
  },
  methods: {
    sendFieldTrackingEvent(label, value) {
      if (Window.dataLayer) {
        Window.dataLayer.push({
          event: 'GTMevent',
          eventCategory: 'application-form-fields',
          eventAction: value ? `${label}|${value}` : `${label}`,
          eventLabel: this.formTypeIsPartner ? 'partner-loan' : 'customer-loan',
          applicationLocation: 'Lendico',
        });
      }
    },
  },
};

export const ingFormFieldGTMTrackingMixin = {
  methods: {
    sendFieldTrackingEvent(label, value) {
      if (Window.dataLayer) {
        Window.dataLayer.push({
          event: 'GTMevent',
          eventCategory: 'application-form-fields',
          eventAction: value ? `${label}|${value}` : `${label}`,
          eventLabel: 'customer-loan',
          applicationLocation: 'Blacksea',
        });
      }
    },
  },
};
