<template>
  <ing-country-input
    :name="'identification-place-of-issue-' + signerId"
    :label="$t('forms.signupForm.identificationPlaceOfIssue.label')"
    :initialCountry="signers[signerId].identificationPlaceOfIssue"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    :preferredCountries="['DE']"
    @change="onChange"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngCountryInput from '@/components/ing/ing-country-input';

  export default {
    components: { IngCountryInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].identificationPlaceOfIssue &&
          this.$t(
            'forms.errors.' +
              this.errors[this.signerId].identificationPlaceOfIssue
          )
        );
      },
    },
    methods: {
      onChange(iso) {
        this.SET_IDENTIFICATION_PLACE_OF_ISSUE({
          signerId: this.signerId,
          identificationPlaceOfIssue: iso,
        });
      },
      ...mapMutations('signers', ['SET_IDENTIFICATION_PLACE_OF_ISSUE']),
    },
  };
</script>
