<template>
  <div>
    <div v-if="!userAlreadyApplied">
      <h2 v-if="isTestAndLearnEnabled">
        {{ $t('ingSignupFormThankYouPage.sellerNextSteps.testAndLearnHeader') }}
      </h2>
      <h2 v-if="!isTestAndLearnEnabled">
        {{ $t('ingSignupFormThankYouPage.sellerNextSteps.header') }}
      </h2>
      <ol v-if="!isTestAndLearnEnabled">
        <li v-html="$t('ingSignupFormThankYouPage.sellerNextSteps.step1')" />
        <li>{{ $t('ingSignupFormThankYouPage.sellerNextSteps.step2') }}</li>
        <li>{{ $t('ingSignupFormThankYouPage.sellerNextSteps.step3') }}</li>
        <li>{{ $t('ingSignupFormThankYouPage.sellerNextSteps.step4') }}</li>
      </ol>
      <ol v-if="isTestAndLearnEnabled">
        <li v-for="i in nextStepCount" :key="`step_${i}`">
          {{
            $t(
              `ingSignupFormThankYouPage.sellerNextSteps.testAndLearn.${productKey}.step${i}`
            )
          }}
        </li>
      </ol>
    </div>
    <div class="submit-button-wrapper">
      <button class="ing-submit-button" @click="redirect">
        {{ $t('ingSignupFormThankYouPage.sellerNextSteps.button') }}
      </button>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { successMixin } from '@/mixins/successMixin';

  export default {
    mixins: [successMixin],
    props: {
      userAlreadyApplied: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('blacksea', ['returnUrl']),
      isTestAndLearnEnabled() {
        return process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true';
      },
      nextStepCount() {
        return this.isCreditLineProduct ? 4 : 3;
      },
    },
    methods: {
      redirect: function () {
        window.location.href = this.returnUrl;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../sass/custom/variables';
  h2 {
    font-weight: bold;
  }
  ol {
    list-style: decimal outside;
    color: $ing-gray-advantages;
    margin: 40px 0 40px 20px;
  }

  li {
    line-height: 24px;
  }
  .submit-button-wrapper {
    display: flex;
    justify-content: center;
  }
</style>
