<template>
  <span class="tooltip-wrapper">
    <span @mouseover="showTooltip()" @mouseleave="hideTooltip()">
      <ing-tooltip-icon class="ing-tooltip" />
    </span>
    <div
      :class="[{ active: mouseOver }, messageClass]"
      class="ing-tooltip-message"
    >
      <p v-html="tooltipMessage" />
    </div>
    <div :class="{ 'ing-tooltip-arrow': mouseOver }" />
  </span>
</template>

<script>
  /**
   * @deprecated
   *
   * Please use ing-bs-tooltip
   */
  import IngTooltipIcon from '@/components/ing/ing-tooltip-icon';

  export default {
    components: { IngTooltipIcon },
    props: {
      tooltipMessage: {
        type: String,
        required: true,
      },
      messageClass: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        mouseOver: false,
      };
    },
    methods: {
      showTooltip() {
        this.mouseOver = true;
      },
      hideTooltip() {
        this.mouseOver = false;
      },
    },
  };
</script>
