import { mapState, mapMutations } from 'vuex';
import { noOfferForThisLegalForm } from '@/validators/helpers/legalStatus';

export default {
  data() {
    return {
      legalStatusOptions: [
        {
          value: 'GmbH',
          label: this.$t('forms.signupForm.legalStatus.dropdown.gmbH'),
        },
        {
          value: 'GmbH & Co. KG',
          label: this.$t('forms.signupForm.legalStatus.dropdown.gmbHCoKG'),
        },
        {
          value: 'Freie Berufe',
          label: this.$t('forms.signupForm.legalStatus.dropdown.freieBerufe'),
        },
        {
          value: 'Einzelfirma',
          label: this.$t('forms.signupForm.legalStatus.dropdown.einzelfirma'),
        },
        {
          value: 'KG',
          label: this.$t('forms.signupForm.legalStatus.dropdown.kg'),
        },
        {
          value: 'OHG',
          label: this.$t('forms.signupForm.legalStatus.dropdown.ohg'),
        },
        {
          value: 'GbR',
          label: this.$t('forms.signupForm.legalStatus.dropdown.gbr'),
        },
        {
          value: 'AG',
          label: this.$t('forms.signupForm.legalStatus.dropdown.ag'),
        },
        {
          value: 'UG (haftungsbeschränkt)',
          label: this.$t('forms.signupForm.legalStatus.dropdown.ug'),
        },
        {
          value: 'Limited',
          label: this.$t('forms.signupForm.legalStatus.dropdown.ltd'),
        },
        {
          value: 'Andere',
          label: this.$t('forms.signupForm.legalStatus.dropdown.other'),
        },
      ],
    };
  },
  computed: {
    ...mapState('signupForm', ['errors', 'legalStatus', 'submitClicked']),
    notSupported() {
      return noOfferForThisLegalForm(this.legalStatus);
    },
    label() {
      return this.$t('forms.signupForm.legalStatus.label');
    },
    displayError() {
      return this.submitClicked && !!this.errors.legalStatus;
    },
    errorMessage() {
      return (
        this.errors.legalStatus &&
        this.$t('forms.errors.' + this.errors.legalStatus)
      );
    },
  },
  methods: {
    ...mapMutations('signupForm', ['SET_LEGAL_STATUS']),
    handleChange(selectedValue) {
      this.SET_LEGAL_STATUS(selectedValue);
      this.sendFieldTrackingEvent(`select:${this.label}`, selectedValue);
    },
  },
};
