import umlauts from '@/constants/UMLAUT_CHARACTERS';
const alphabeticCharacters = 'A-Za-z';
const hyphensSpaceDots = '- .';
const slashesSpaceHyphens = '/ -';
const digits = '0-9';

const hasOnlyWhitespaces = (str) => {
  return str.replace(/ /g, '').length === 0;
};

export const isEmpty = (str) => {
  return !str || hasOnlyWhitespaces(str);
};

export const hasWrongLength = (str, length) => {
  return str && str.length !== length;
};

export const lengthIsOutOfRange = (str, min, max) => {
  return str && (str.length < min || str.length > max);
};

export const isNotNumeric = (str) => {
  return isNaN(str);
};

export const hasDigit = (str) => {
  return /\d/.test(str);
};

export const hasSpecialCharsExceptHyphen = (str = '') => {
  return hasSpecialChars(str, ['-']);
};

export const hasSpecialCharsExceptHyphenAndDot = (str = '') => {
  return hasSpecialChars(str, ['-', '.']);
};

export function hasSpecialChars(str, allowedChars = []) {
  const specialChars = `!@#$%^§~&*()\\-_+=[\\]{};':"\\\\/|,.<>?`;
  if (allowedChars.length === 0) {
    const specialCharsPattern = new RegExp(`[${specialChars}]`);
    return specialCharsPattern.test(str);
  } else {
    const allowedCharsPattern = new RegExp(`[${allowedChars.join('')}]`, 'g');
    const acceptedSpecialChars = specialChars.replace(allowedCharsPattern, '');
    const acceptedSpecialCharsPattern = new RegExp(`[${acceptedSpecialChars}]`);
    return acceptedSpecialCharsPattern.test(str);
  }
}

export const hasAtLeastTwoAlphabeticChars = (str) => {
  const allowedCharacters = `[${umlauts}${alphabeticCharacters}]`;
  const alphabeticCharactersRegex = new RegExp(allowedCharacters, 'g');
  const matches = str.match(alphabeticCharactersRegex);
  if (matches) {
    return matches.length >= 2;
  }
  return false;
};

export const matchesCityOfBirthPattern = (str) => {
  const allowedCharacters = `^[${umlauts}${alphabeticCharacters}${hyphensSpaceDots}]*$`;
  const cityOfBirthPattern = new RegExp(allowedCharacters);
  return cityOfBirthPattern.test(str);
};

export const matchesStreetNumberPattern = (str) => {
  const allowedCharacters = `^[${digits}${umlauts}${alphabeticCharacters}${slashesSpaceHyphens}]*$`;
  const streetNumberPattern = new RegExp(allowedCharacters);
  return streetNumberPattern.test(str);
};
