<template>
  <div id="controllingLegalNameField" class="legal-name-wrapper">
    <len-text-input
      v-model="legalName"
      :label="$t('forms.signupForm.companyName.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="legal-name"
      @focus="legalNameHasFocus = true"
      @blur="legalNameHasFocus = false"
    />
    <tooltip
      :class="{ 'on-input-focus': legalNameHasFocus || !!legalName }"
      :tooltipMessage="$t('forms.signupForm.companyName.tooltip')"
      class="legal-name-tooltip"
      messageClass="company-name-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: { LenTextInput, Tooltip },
    data() {
      return {
        legalNameHasFocus: false,
      };
    },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'controllingCompany',
      ]),
      legalName: {
        get() {
          return this.controllingCompany.legalName;
        },
        set(newValue) {
          this.SET_CONTROLLING_LEGAL_NAME(newValue);
        },
      },
      error() {
        if (
          this.errors.controllingCompany &&
          this.errors.controllingCompany.legalName
        ) {
          return this.$t(
            `forms.errors.${this.errors.controllingCompany.legalName}`
          );
        }
        return '';
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_CONTROLLING_LEGAL_NAME']),
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/settings';
  .legal-name-wrapper {
    position: relative;
    line-height: 1.75;
  }
  .legal-name-tooltip {
    position: absolute;
    left: 80px;
    top: 5px;
    &.on-input-focus {
      top: -12px;
    }
  }
</style>
