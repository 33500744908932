<template>
  <ing-text-input
    v-model="employeesNumber"
    :label="$t('forms.signupForm.employeesNumber.label')"
    :error="
      errors.company &&
      errors.company.employeesNumber &&
      $t('forms.errors.' + errors.company.employeesNumber)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="employees-number"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      employeesNumber: {
        get() {
          return this.company.employeesNumber;
        },
        set(value) {
          this.SET_EMPLOYEES_NUMBER(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_EMPLOYEES_NUMBER']),
    },
  };
</script>
