<template>
  <len-date-input
    :label="$t('forms.signupForm.identificationIssuingDate.label')"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    :error="
      errors[signerId] &&
      errors[signerId].identificationIssuingDate &&
      $t('forms.errors.' + errors[signerId].identificationIssuingDate)
    "
    :value="identificationIssuingDate"
    :name="'identification-issuing-date-' + signerId"
    data-qa-id="identification-issuing-date"
    @accept="onAccept"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenDateInput from '@/components/common/len-date-input';
  export default {
    components: { LenDateInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      identificationIssuingDate: {
        get() {
          return this.$options.filters.momentjsFormat(
            this.signers[this.signerId].identificationIssuingDate,
            'L',
            'YYYY-MM-DDTHH:mm:ss'
          );
        },
        set(value) {
          this.SET_IDENTIFICATION_ISSUING_DATE({
            signerId: this.signerId,
            identificationIssuingDate: this.$options.filters.momentjsFormat(
              value,
              'YYYY-MM-DDTHH:mm:ss',
              'L'
            ),
          });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      onAccept(value) {
        this.identificationIssuingDate = value;
      },
      ...mapMutations('signers', ['SET_IDENTIFICATION_ISSUING_DATE']),
    },
  };
</script>

<style scoped></style>
