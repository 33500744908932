import { mutations } from './mutations';
import * as actions from './actions';
import { getters } from './getters';

export const initialState = {
  ident: {
    referenceNumber: '',
    firstName: '',
    lastName: '',
    mobilePhone: '',
    email: '',
  },
  errors: {
    server: null,
  },
  referenceNumberEditable: true,
  postIdentRedirectUrl: '',
  submitClicked: false,
  success: null,
  loading: false,
};

export default {
  state: initialState,
  mutations,
  actions,
  getters,
  namespaced: true,
};
