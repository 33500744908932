<template>
  <div>
    <div class="company-info-container">
      <div class="company-info-container__col">
        <IngCompanyLookupField
          v-model="company"
          name="companyName"
          data-qa-id="company-name"
          :error="getError('company')"
          :displayErrorIfDirty="!submitClicked"
        />
        <TextInput
          v-model="street"
          name="BillingStreet"
          :label="$t('formFields.street.label')"
          data-qa-id="company-street-name-and-number"
          :error="getError('street')"
          :displayErrorIfDirty="!submitClicked"
        />
        <TextInput
          v-model="postcodeDe"
          name="postcodeDe"
          :label="$t('formFields.postcode.label')"
          inputmode="numeric"
          inputSize="xs"
          data-qa-id="company-postcode"
          :error="getError('postcodeDe')"
          :displayErrorIfDirty="!submitClicked"
        />
        <TextInput
          v-model="city"
          name="city"
          :label="$t('formFields.city.label')"
          data-qa-id="company-city"
          :error="getError('city')"
          :displayErrorIfDirty="!submitClicked"
        />
      </div>
      <div class="company-info-container__col">
        <DateInput
          v-model="dateFounded"
          name="Date_Founded__c"
          :label="
            $t('formFields.companyDateFounded.label', {
              maskFormat,
            })
          "
          :inputSize="dateInputSize"
          :minYear="1000"
          :maskFormat="maskFormat"
          data-qa-id="foundation-date"
          :error="getError('dateFounded')"
          :displayErrorIfDirty="!submitClicked"
        />
        <RadioGroup
          v-model="revenue"
          name="Revenue__c"
          :title="$t('formFields.companyRevenue.label')"
          :radioOptions="revenueOptions"
          :rowList="true"
          :error="getError('revenue')"
          :displayErrorIfSubmitted="submitClicked"
        />
        <Dropdown
          v-model="legalStatus"
          name="legalStatus__c"
          :value="legalStatus"
          :options="legalStatusOptions"
          maxVisibleOptions="5"
          :label="$t('formFields.companyLegalStatus.label')"
          :error="getError('legalStatus')"
          :info="notSupported"
          :displayErrorIfSubmitted="displayError('legalStatus')"
          data-qa-id="legal-form"
        />
        <Dropdown
          v-model="loanPurposeBorrower"
          name="loanPurpose__c"
          :value="loanPurposeBorrower"
          :options="loanPurposeOptions"
          :label="$t('formFields.loanPurpose.label')"
          :error="getError('loanPurposeBorrower')"
          :displayErrorIfSubmitted="displayError('loanPurposeBorrower')"
          data-qa-id="loan-purpose"
        />
        <RadioGroup
          v-if="isBookkeepingVisible"
          v-model="bookkeepingStandard"
          name="loanPurpose__c"
          :title="$t('formFields.bookkeepingStandard.label')"
          :toolTip="
            $t('forms.signupForm.bookkeeping.ingToolTip', { br: '\n\n' })
          "
          :radioOptions="bookKeepingOptions"
          :error="getError('bookkeepingStandard')"
          :displayErrorIfSubmitted="submitClicked"
        />
      </div>
    </div>

    <div v-if="channel === 'amazon'" class="form-sub-section">
      <h3 class="form-sub-section__title">{{
        $t('amazon.application.form.companySection.subSection.title')
      }}</h3>
      <p class="form-sub-section__para">{{
        $t('amazon.application.form.companySection.subSection.para1')
      }}</p>
      <div class="company-info-container">
        <IbanInput
          v-model="iban"
          name="iban"
          class="company-info-container__col"
          data-qa-id="iban"
          :label="$t('forms.signupForm.iban.label')"
          :error="getError('iban')"
          :displayErrorIfDirty="!submitClicked"
        />
        <TextInput
          v-model="bic"
          name="bic"
          class="company-info-container__col"
          :label="$t('forms.signupForm.bic.label')"
          data-qa-id="bic"
          :error="getError('bic')"
          :displayErrorIfDirty="!submitClicked"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import DateInput from '@/components/ingOrangeJuice/Base/DateInput';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import RadioGroup from '@/components/ingOrangeJuice/Base/RadioGroup';
  import IbanInput from '@/components/ingOrangeJuice/Base/IbanInput';
  import IngCompanyLookupField from '@/components/common/forms/company-information/IngCompanyLookupField';

  import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
  import { removeDayFromDate } from '@/utils/stringUtilities';
  import { removeSpacesFromIban } from '@/utils/formatIban';
  import { noOfferForThisLegalForm } from '@/validators/helpers/legalStatus';

  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  import BALANCE_SHEET_LEGAL_STATUSES from '@/constants/BALANCE_SHEET_LEGAL_STATUSES';
  import BOOK_KEEPING_VALUES from '@/constants/BOOK_KEEPING_VALUES';
  import DATE_FORMAT from '@/constants/DATE_FORMAT';

  export default {
    components: {
      TextInput,
      DateInput,
      Dropdown,
      RadioGroup,
      IbanInput,
      IngCompanyLookupField,
    },
    mixins: [dropdownOptionsMixin],
    computed: {
      ...mapState('signupForm', [
        'legalStatus',
        'loanPurposeBorrower',
        'errors',
        'submitClicked',
      ]),
      ...mapState('signupForm', {
        bkStandard: 'bookkeepingStandard',
        companyLookupEnabled: 'companyLookupEnabled',
      }),
      ...mapState('appSettings', ['channel']),
      ...mapGetters('signupForm', ['isPartner', 'isBookkeepingVisible']),
      maskFormat() {
        return this.isPartner
          ? DATE_FORMAT.FULL_DATE
          : DATE_FORMAT.MONTH_AND_YEAR;
      },
      dateInputSize() {
        return this.maskFormat === DATE_FORMAT.FULL_DATE ? 's' : 'xs';
      },
      company: {
        get() {
          return this.$store.state.signupForm.company;
        },
        set(value) {
          this.SET_COMPANY_NAME(value);
          if (this.companyLookupEnabled) {
            this.RESET_PREFILLED_FIELDS();
          }
        },
      },
      street: {
        get() {
          return this.$store.state.signupForm.street;
        },
        set(value) {
          this.SET_STREET(value);
        },
      },
      postcodeDe: {
        get() {
          return this.$store.state.signupForm.postcodeDe;
        },
        set(value) {
          this.SET_POSTCODE_DE(value);
        },
      },
      city: {
        get() {
          return this.$store.state.signupForm.city;
        },
        set(value) {
          this.SET_CITY(value);
        },
      },
      dateFounded: {
        get() {
          const dateFounded = this.$store.state.signupForm.dateFounded;

          if (this.isPartner) {
            return dateFounded;
          }

          // remove the day from the date as it has to be shown like MM.JJJJ
          return removeDayFromDate(dateFounded);
        },
        set(value) {
          if (this.isPartner) {
            this.SET_DATE_FOUNDED(value);
            return;
          }

          // Add the first day of the month as a fixed value according to ACQ-410 specs
          this.SET_DATE_FOUNDED(`01.${value}`);
        },
      },
      revenue: {
        get() {
          return this.$store.state.signupForm.revenue;
        },
        set(value) {
          this.SET_REVENUE(value);
        },
      },
      legalStatus: {
        get() {
          return this.$store.state.signupForm.legalStatus;
        },
        set(value) {
          this.SET_LEGAL_STATUS(value);
        },
      },
      notSupported() {
        return noOfferForThisLegalForm(this.legalStatus)
          ? this.$t('formFields.companyLegalStatus.infoMessage')
          : '';
      },
      loanPurposeBorrower: {
        get() {
          return this.$store.state.signupForm.loanPurposeBorrower;
        },
        set(value) {
          this.SET_LOAN_PURPOSE(value);
        },
      },
      bookkeepingStandard: {
        get() {
          return this.bkStandard;
        },
        set(newValue) {
          this.SET_BOOKKEEPING_STANDARD(newValue);
          this.$emit('select');
        },
      },
      iban: {
        get() {
          return this.$store.state.signupForm.iban;
        },
        set(value) {
          this.SET_IBAN(removeSpacesFromIban(value));
        },
      },
      bic: {
        get() {
          return this.$store.state.signupForm.bic;
        },
        set(value) {
          this.SET_BIC(value.toUpperCase());
        },
      },
    },
    watch: {
      legalStatus: function (newVal, oldVal) {
        this.SET_BOOKKEEPING_VISIBILITY();
        if (BALANCE_SHEET_LEGAL_STATUSES.includes(newVal)) {
          this.SET_BOOKKEEPING_STANDARD(BOOK_KEEPING_VALUES.BALANCE_SHEET);
        } else if (
          BALANCE_SHEET_LEGAL_STATUSES.includes(oldVal) &&
          !BALANCE_SHEET_LEGAL_STATUSES.includes(newVal)
        ) {
          this.SET_BOOKKEEPING_STANDARD(null);
        }
      },
    },
    methods: {
      getError(field) {
        return (
          this.errors[field] && this.$t('forms.errors.' + this.errors[field])
        );
      },
      displayError(field) {
        return this.submitClicked && !!this.errors[field];
      },
      ...mapActions('signupForm', ['getFWCompanies']),
      ...mapMutations('signupForm', [
        'SET_STREET',
        'SET_POSTCODE_DE',
        'SET_CITY',
        'SET_DATE_FOUNDED',
        'SET_REVENUE',
        'SET_LEGAL_STATUS',
        'SET_LOAN_PURPOSE',
        'SET_BOOKKEEPING_STANDARD',
        'SET_BOOKKEEPING_VISIBILITY',
        'SET_COMPANY_NAME',
        'RESET_PREFILLED_FIELDS',
        'SET_COMPANIES',
        'SET_IBAN',
        'SET_BIC',
      ]),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';
  @import '@/sass/components/common/ing.orange.juice';

  .company-info-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    &__col {
      flex-basis: 100%;
      box-sizing: content-box;
      @include mq-size(s) {
        flex-basis: 87.15%;
      }
      @include mq-size(m) {
        flex-basis: 48.5%;
      }
    }
  }
  .form-sub-section {
    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 28px;
      color: $Primary-Grey600;
      padding: 0.75rem 0;
    }
    &__para {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 20px;
    }
  }
</style>
