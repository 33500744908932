<template>
  <form class="ing-form" name="registration" @submit.prevent="submit">
    <ing-form-loader key="blackseaLoader" storeModule="signupForm" />
    <ing-section
      :darkTheme="false"
      :class="{ fade: shouldFadeSection(1) }"
      maxWidth="ing-section-content"
      class="section-form"
    >
      <template slot="section-content">
        <div class="form-header">
          {{ $t(`ingBorrowerSignupForm.formHeader.${productKey}`) }}
        </div>
        <ing-wcl-calculator-wrapper />
      </template>
    </ing-section>
    <span ref="spacer-1" />
    <ing-section
      :darkTheme="true"
      :class="{ fade: shouldFadeSection(2) }"
      maxWidth="ing-section-content"
      class="section-form"
    >
      <template slot="section-content">
        <ing-company-information />
      </template>
    </ing-section>
    <span ref="spacer-2" />
    <ing-section
      :darkTheme="false"
      :class="{ fade: shouldFadeSection(3) }"
      maxWidth="ing-section-content"
      class="section-form"
    >
      <template slot="section-content">
        <ing-representative-information ref="representative-information" />
      </template>
    </ing-section>
    <span ref="spacer-3" />
    <ing-section
      :darkTheme="true"
      :class="{ fade: shouldFadeSection(4) }"
      maxWidth="ing-section-content"
      class="section-form"
    >
      <template slot="section-content">
        <ing-submit-form ref="submit-form" />
      </template>
    </ing-section>
    <span ref="spacer-4" />
    <ing-section :darkTheme="false" maxWidth="ing-section-content">
      <template slot="section-content">
        <ing-contact-us />
      </template>
    </ing-section>
  </form>
</template>

<script>
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import IngContactUs from '@/components/ing/ing-contact-us';
  import IngSection from '@/components/ing/ing-section';
  import IngWclCalculatorWrapper from '@/components/ing/ing-wcl-calculator-wrapper';
  import IngCompanyInformation from '@/components/ing/forms/ing-company-information';
  import IngRepresentativeInformation from '@/components/ing/forms/ing-representative-information';
  import IngSubmitForm from '@/components/ing/forms/ing-submit-form';
  import {
    eventListeners,
    setAmountAndLength,
    setDisbursementAmount,
    mediaCodesExtraction,
    utmParamsExtraction,
    wtCookieRemove,
  } from '@/mixins/forms/formSubmissionMixin';
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import { showSectionDependingOnPosition } from '@/mixins/scrollMixin';
  import throttle from 'lodash/throttle';
  import cookie from '@/common/cookie';
  import { routerPushByName } from '@/mixins/routerMixin';
  import { INVALID_SIGNATURE } from '@/constants/ERROR';
  import { EXPIRED_OFFER, SUCCESS } from '@/constants/OFFER_STATUS';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';

  export default {
    components: {
      IngFormLoader,
      IngContactUs,
      IngSection,
      IngWclCalculatorWrapper,
      IngCompanyInformation,
      IngRepresentativeInformation,
      IngSubmitForm,
    },
    mixins: [
      utmParamsExtraction,
      mediaCodesExtraction,
      wtCookieRemove,
      showSectionDependingOnPosition,
      eventListeners,
      setAmountAndLength,
      setDisbursementAmount,
      routerPushByName,
      webtrekkMixin,
    ],
    data() {
      return {
        sectionToDisplay: 1,
      };
    },
    computed: {
      ...mapGetters('signupForm', ['isValid']),
      ...mapState('signupForm', ['submitter']),
      ...mapGetters('blacksea', ['productKey']),
    },
    mounted() {
      this.addEventListeners();
      this.SET_SUBMITTER('applicant');
    },
    beforeDestroy() {
      this.removeEventListeners();
    },
    methods: {
      ...mapActions('signupForm', ['signUp']),
      ...mapActions('blacksea', ['verifySignature']),
      ...mapMutations('signupForm', [
        'SET_GC_ID',
        'SET_SUBMIT_CLICKED',
        'SET_SUBMITTER',
      ]),
      onScroll() {
        this.showSectionDependingOnPosition();
      },
      throttledOnScroll: throttle(function () {
        this.onScroll();
      }, 300),
      shouldFadeSection(s) {
        return this.sectionToDisplay !== s;
      },
      async submit(event) {
        this.addExtraFields();
        // by click submit they accept
        this.SET_SUBMIT_CLICKED(true);

        if (!this.isValid) {
          return;
        }

        /**
         * TODO: based on FUN-1403 and its sub tasks we switched off the signature validation
         * for the Blacksea first launch.
         * As soon as AMZ come up with a solution to give us ability to create valid links
         * with the right signature, all we need to do to enable signature validation is to
         * uncomment the following if condition. Then we can test everything on different
         * environments
         */
        // if (await this.signatureIsVerified()) {
        try {
          await this.signUp();
          this.trackClick(event.submitter.attributes['wtlink'].value);
          this.$emit('success');
          this.removeWTCookies();
          window.scrollTo(0, 0);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
        // }
      },
      async signatureIsVerified() {
        let verified = false;
        const result = await this.verifySignature();

        if (result === SUCCESS) {
          verified = true;
        } else if (result === INVALID_SIGNATURE) {
          this.routerPushByName('ing-technical-error-page');
        } else if (result === EXPIRED_OFFER) {
          this.routerPushByName('ing-offer-expired-error-page');
        }

        return verified;
      },
      addExtraFields() {
        this.setAmountAndLength();
        this.setDisbursementAmount();
        this.extractUTMParams();
        this.extractMediaCodes();
        this.SET_GC_ID(cookie.get('_ga'));
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .form-header {
    font-size: 40px;
    line-height: 50px;
    font-weight: bold;
    margin-bottom: 60px;

    @media screen and (max-width: $tablet) {
      font-size: 32px;
      line-height: 38px;
      margin-bottom: 40px;
    }

    @media screen and (max-width: $mobile-l) {
      font-size: 27px;
      line-height: 36px;
      text-align: center;
    }
  }
  .ing-form .section-form {
    transition: opacity 0.3s ease;
    opacity: 1;
  }
  .ing-form .section-form.fade {
    opacity: 0.15;
  }
</style>
