<template>
  <div :class="{ 'addMaterialDesign fieldset-container': !isNative }">
    <len-dropdown-input
      v-show="isNative"
      :name="safariAutocompleteOffName"
      :options="dropdownOptions"
      :value="country"
      :label="label"
      :error="error"
      :displayErrorIfSubmitted="displayError"
      @input="handleChange"
    />

    <div
      v-show="!isNative"
      v-click-outside="clickedOutside"
      class="vue-country-input"
    >
      <fieldset
        :class="{ error: displayError }"
        class="form-field addMaterialDesign"
      >
        <div
          :class="{
            'is-focused': isFocused,
            'is-empty': !country,
          }"
          class="form-group addMaterialDesign label-floating"
        >
          <label class="control-label" v-html="label" />
          <input
            ref="input"
            :value="country"
            :name="safariAutocompleteOffName"
            type="text"
            autocomplete="nofill"
            class="form-control"
            @focus="onFocus"
            @blur="onBlur"
            @input="onInput"
            @keydown="keyboardNav"
          />
        </div>
        <div
          role="alert"
          class="text-block text-align--left display--block error-container"
          :class="{ 'long-error': longErrorMessage && error }"
        >
          <p v-if="displayError" class="validation">
            {{ error }}
          </p>
        </div>
      </fieldset>
      <div :class="{ open: open }" class="dropdown" @keydown="keyboardNav">
        <ul
          v-show="open"
          ref="list"
          class="dropdown-list"
          :class="{
            'is-empty': filteredCountries.length === 0,
            'dropdown-list-error': longErrorMessage && error,
          }"
        >
          <li
            v-for="(pb, index) in filteredCountries"
            :key="pb.iso + (pb.preferred ? '-preferred' : '')"
            :class="getItemClass(index, pb.iso)"
            class="dropdown-item"
            @click="choose(pb)"
            @mousemove="selectedIndex = index"
          >
            {{ pb.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  import LenDropdownInput from '@/components/common/len-dropdown-input';
  import { countryInputFunctionality } from '@/mixins/forms/countryInputMixin';
  import { countryDropdownInputFunctionality } from '@/mixins/forms/countryDropdownInputMixin';
  import MOBILE_USER_AGENT_REGEX from '@/constants/MOBILE_USER_AGENT_REGEX';
  import Window from '@/common/window';

  export default {
    components: { LenDropdownInput },
    mixins: [countryInputFunctionality, countryDropdownInputFunctionality],
    computed: {
      isNative: function () {
        return MOBILE_USER_AGENT_REGEX.test(Window.navigator.userAgent);
      },
    },
  };
</script>

<style src="./assets/sprite.css"></style>

<style scoped lang="scss">
  .form-group {
    padding-bottom: 0;
  }
  .error-container {
    height: 25px;
  }
  .validation {
    margin-bottom: 0px;
  }

  :local {
    --border-radius: 2px;
  }
  .vue-country-input {
    display: flex;
    flex-direction: column;
    text-align: left;
    font-size: 16px;
  }

  input {
    border: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    width: 100%;
    outline: none;
    padding-left: 7px;
  }
  ul.dropdown-list {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 276px;
    overflow-y: scroll;
    position: absolute;
    top: -25px;
    left: 0;
    background-color: #fff;
    width: 100%;
    z-index: 1;
    box-shadow: 0px 2px 4px rgba(46, 46, 46, 0.15);
    border-radius: 0 0 4px 4px;
  }
  ul.dropdown-list.is-empty {
    border: none;
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
  }
  .dropdown-item {
    cursor: pointer;
    padding: 12px 10px;
    display: flex;
    align-items: center;

    &.highlighted {
      background: #29bdba;
      color: #fff;
      font-weight: 600;
    }
  }

  .dropdown-menu.show {
    max-height: 300px;
    overflow: scroll;
  }

  .long-error {
    height: 50px;
  }
  ul.dropdown-list-error {
    top: -50px;
  }
</style>
