export const hasEmailFormat = (email) => {
  /**
   * The email couldn't start or finish with a dot
   * The email shouldn't contain spaces into the string
   * The email shouldn't contain special chars (<:, *,ecc) as well as umlaut
   * The email could contain dots in the middle of mail address before the @
   * The email could contain a double domain ( '.de.org' or similar rarity)
   */

  const localPart = '((?!\\W)([\\w-_.+]+))';
  const atDomain = '(@[\\w-]+)';
  const domainPostfix = '((\\.\\w+)(\\.\\w+)?)';

  const emailRegex = new RegExp(`^${localPart}${atDomain}${domainPostfix}$`);

  return emailRegex.test(email);
};

export const hasDotBeforeAtSign = (email) => {
  return /\.@/.test(email);
};

export const areEmailsUnique = (signers) => {
  const emailsArray = Object.keys(signers)
    .filter((signer) => signers[signer].email)
    .map((signer) => signers[signer].email);
  const emailsSet = new Set(emailsArray);
  return emailsArray.length === emailsSet.size;
};
