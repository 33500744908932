<template>
  <len-date-input
    :label="$t('forms.signupForm.identificationExpiryDate.label')"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    :error="error"
    :value="identificationExpiryDate"
    :name="`identification-expiry-date-${signerId}`"
    data-qa-id="identification-expiry-date"
    @accept="onAccept"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenDateInput from '@/components/common/len-date-input';
  export default {
    components: { LenDateInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      identificationExpiryDate: {
        get() {
          return this.$options.filters.momentjsFormat(
            this.signers[this.signerId].identificationExpiryDate,
            'L',
            'YYYY-MM-DDTHH:mm:ss'
          );
        },
        set(value) {
          this.SET_IDENTIFICATION_EXPIRY_DATE({
            signerId: this.signerId,
            identificationExpiryDate: this.$options.filters.momentjsFormat(
              value,
              'YYYY-MM-DDTHH:mm:ss',
              'L'
            ),
          });
        },
      },
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].identificationExpiryDate &&
          this.$t(
            'forms.errors.' +
              this.errors[this.signerId].identificationExpiryDate
          )
        );
      },
    },
    methods: {
      onAccept(value) {
        this.identificationExpiryDate = value;
      },
      ...mapMutations('signers', ['SET_IDENTIFICATION_EXPIRY_DATE']),
    },
  };
</script>

<style scoped></style>
