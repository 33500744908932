<template>
  <ing-text-input
    v-model="postalStreet"
    :label="$t('forms.signupForm.streetOnly.label')"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="company-postal-street"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
      postalStreet: {
        get() {
          return this.company.postalStreet;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_STREET(value);
        },
      },
      error() {
        return (
          this.errors.company &&
          this.errors.company.postalStreet &&
          this.$t('forms.errors.' + this.errors.company.postalStreet)
        );
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_POSTAL_STREET']),
    },
  };
</script>
