<template>
  <div :class="{ alert: isInvalid }" class="form-container">
    <div class="company-section-container">
      <loan-owner-company-title v-show="hasControllingCompany" />
      <div class="col-sm-6">
        <div class="col-sm-11">
          <company-name />
          <company-trading-name />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <company-tax-number />
          <country-of-incorporation v-show="isRegularLoanRequest" />
        </div>
      </div>
    </div>
    <form-separator />
    <div class="company-section-container">
      <h3>{{ $t('forms.signupForm.businessAddress.label') }}</h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <business-street />
          <business-zip />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <business-street-number />
          <business-city />
        </div>
      </div>
      <postal-address-checkbox />
    </div>
    <form-separator />
    <div
      v-show="!company.businessAddressIsPostalAddress"
      class="company-section-container"
    >
      <h3>{{ $t('forms.signupForm.postalAddress.label') }}</h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <postal-street />
          <postal-zip />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <postal-street-number />
          <postal-city />
        </div>
      </div>
    </div>
    <form-separator v-if="!company.businessAddressIsPostalAddress" />
    <div v-show="hasControllingCompany" class="company-section-container">
      <controlling-company-title />
      <div class="col-sm-6">
        <div class="col-sm-11">
          <controlling-legal-name />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <controlling-country-of-incorporation />
        </div>
      </div>
    </div>
    <form-separator v-show="hasControllingCompany" />
    <div class="company-section-container">
      <cash-intensity-title />
      <cash-intensity-field />
    </div>

    <div class="submit-section-container">
      <div
        v-if="errorMessage !== ''"
        role="alert"
        class="general-error-container"
      >
        <p class="validation" v-html="errorMessage" />
      </div>
      <submit-form
        @cancel-button-clicked="$emit('cancel-button-clicked')"
        @submit-button-clicked="$emit('submit-button-clicked')"
      />
    </div>
  </div>
</template>

<script>
  import CompanyName from '@/components/signers/signers-company-information/signers-company-form/signers-company-name-field';
  import CompanyTradingName from '@/components/signers/signers-company-information/signers-company-form/company-trading-name-field';
  import CountryOfIncorporation from '@/components/signers/signers-company-information/signers-company-form/country-of-incorporation';
  import CompanyTaxNumber from '@/components/signers/signers-company-information/signers-company-form/company-tax-number-field';
  import BusinessStreet from '@/components/signers/signers-company-information/signers-company-form/business-street-field';
  import BusinessStreetNumber from '@/components/signers/signers-company-information/signers-company-form/business-street-number-field';
  import BusinessZip from '@/components/signers/signers-company-information/signers-company-form/business-zip-field';
  import BusinessCity from '@/components/signers/signers-company-information/signers-company-form/business-city-field';
  import PostalStreet from '@/components/signers/signers-company-information/signers-company-form/company-postal-street-field';
  import PostalStreetNumber from '@/components/signers/signers-company-information/signers-company-form/company-postal-street-number-field';
  import PostalZip from '@/components/signers/signers-company-information/signers-company-form/company-postal-zip-field';
  import PostalCity from '@/components/signers/signers-company-information/signers-company-form/company-postal-city-field';
  import PostalAddressCheckbox from '@/components/signers/signers-company-information/signers-company-form/postal-address-checkbox-field';
  import FormSeparator from '@/components/signers/form-separator';
  import CashIntensityTitle from '@/components/signers/signers-company-information/cash-intensity-title';
  import CashIntensityField from '@/components/signers/signers-company-information/signers-company-form/cash-intensity-field';
  import SubmitForm from '@/components/signers/section-submit-form';
  import ControllingCompanyTitle from '@/components/signers/signers-company-information/controlling-company-title';
  import ControllingCountryOfIncorporation from '@/components/signers/signers-company-information/signers-company-form/controlling-country-of-incorporation';
  import ControllingLegalName from '@/components/signers/signers-company-information/signers-company-form/controlling-legal-name';
  import LoanOwnerCompanyTitle from '@/components/signers/signers-company-information/loan-owner-company-title';
  import {
    isRegularLoanRequest,
    hasControllingCompany,
  } from '@/mixins/signersMixin';
  import { mapState } from 'vuex';
  export default {
    components: {
      CompanyName,
      CompanyTradingName,
      CountryOfIncorporation,
      CompanyTaxNumber,
      BusinessStreet,
      BusinessStreetNumber,
      BusinessZip,
      BusinessCity,
      PostalAddressCheckbox,
      PostalStreet,
      PostalStreetNumber,
      PostalZip,
      PostalCity,
      FormSeparator,
      CashIntensityTitle,
      CashIntensityField,
      SubmitForm,
      ControllingCompanyTitle,
      ControllingLegalName,
      ControllingCountryOfIncorporation,
      LoanOwnerCompanyTitle,
    },
    mixins: [isRegularLoanRequest, hasControllingCompany],
    props: {
      isInvalid: {
        type: Boolean,
      },
    },
    computed: {
      ...mapState('signers', ['company', 'errors']),
      errorMessage() {
        let serverError = this.errors.companyServer;
        if (serverError) {
          if (serverError.status >= 400 && serverError.status < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError.status >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/settings';
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: #2e2e2e;
    margin-bottom: 40px;
  }
  .company-section-container {
    width: 100%;
    padding: 40px;
  }
  .form-container {
    &.alert {
      border: solid 1px $red;
    }
  }
  .submit-section-container {
    width: 100%;
    padding: 0 40px 40px 40px;
  }
  .short-column-alignment {
    vertical-align: top;
  }
  .general-error-container {
    height: 25px;
  }
  .general-error-container p {
    text-align: center;
    font-size: 0.8rem;
  }
</style>
