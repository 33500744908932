<template>
  <div class="contract-download-container">
    <a
      :download="downloadName"
      :href="contractIsAvailable ? downloadLink : null"
      ><img src="/ing_download_icon.png" alt="Download Icon"
    /></a>
    <div class="contract-text">
      <p>
        {{
          $t(
            `borrowerJourney.borrowerContractsComponent.${contractType}Contract`
          )
        }}
      </p>
      <div class="contract-actions">
        <ing-contract-modal
          v-if="contractIsAvailable"
          :contract-data-string="contractData"
        />
        <a
          v-if="contractIsAvailable"
          :download="downloadName"
          :href="downloadLink"
          >{{ $t('ingOfferPage.contractSection.contracts.download') }}</a
        >
        <p v-else-if="!loading" class="unavailable-contract">
          {{ $t('ingOfferPage.contractSection.contracts.missingContract') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import IngContractModal from '@/components/ing/offer/ing-contract-modal';
  export default {
    components: { IngContractModal },
    props: {
      contractType: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState('offer', ['loading']),
      ...mapState('contract', [
        'credit',
        'micro_credit',
        'blanket_assignment',
        'power_of_attorney',
      ]),
      contractIsAvailable() {
        return (
          !this[this.contractType].getReqErrorCode &&
          this[this.contractType].data
        );
      },
      downloadName() {
        return this[this.contractType].fileName;
      },
      contractData() {
        return this[this.contractType].data;
      },
      downloadLink() {
        return 'data:application/octet-stream;base64,' + this.contractData;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/variables';
  img {
    height: max-content;
    width: max-content;
  }
  .contract-download-container {
    display: flex;
    padding: 40px 0;
  }
  .contract-text {
    margin-left: 20px;
    font-size: 20px;
  }
  .contract-actions {
    display: flex;
    flex-direction: row;
  }
  .unavailable-contract {
    font-size: 16px;
    color: $ing-red;
  }
</style>
