<template>
  <div>
    <h1 class="offer-details-heading">
      {{ $t('offer.detailsSection.heading') }}
    </h1>
    <h2 class="offer-details-title">
      {{ $t('offer.detailsSection.title') }}
    </h2>
    <div class="offer-details-container">
      <div class="offer-details-container__col">
        <div class="section">
          <div class="section__list">
            <div class="section__row">
              <span>
                {{ $t(`offer.detailsSection.regular.loanAmount`) }}
              </span>
              <span>
                {{ currencyAmountWithCode(loanAmount) }}
              </span>
            </div>
            <div class="section__row">
              <span> {{ $t(`offer.detailsSection.regular.duration`) }} </span>
              <span>
                {{ duration | momentjsDuration('month') | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t(`offer.detailsSection.regular.interestRate`) }}
              </span>
              <span>
                {{ interestRate | europeanCurrency | emptyValueReplacer }}
                %
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('offer.detailsSection.regular.monthlyRate') }}
              </span>
              <span>
                {{ currencyAmountWithCode(monthlyRate) }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AcceptOfferButton />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import AcceptOfferButton from '@/components/ingOffer/AcceptOfferButton';
  import { currencyAmountWithCode } from '@/utils/currency';

  export default {
    name: 'OfferDetails',
    components: { AcceptOfferButton },
    computed: {
      ...mapState('offer', [
        'loanAmount',
        'duration',
        'interestRate',
        'monthlyRate',
      ]),
    },
    methods: {
      currencyAmountWithCode,
    },
  };
</script>

<style scoped lang="scss">
  @import 'src/sass/components/common/ing.orange.juice.scss';

  .offer-details-heading {
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    color: $Primary-Orange;
    margin-bottom: 40px;
    word-wrap: anywhere;
  }
  .offer-details-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
    padding-bottom: 2rem;
    color: $Primary-Grey600;
    word-wrap: anywhere;
  }
  .explanatory-text {
    font-size: 16px;
    line-height: 24px;
    color: $Primary-Grey400;
  }

  .offer-details-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    &__col {
      flex-basis: 100%;
      box-sizing: content-box;
      @include mq-size(s) {
        flex-basis: 87.15%;
      }
      @include mq-size(m) {
        flex-basis: 48.5%;
      }
      @include mq-size(l) {
        flex-basis: 48.5%;
      }
    }
    .section {
      display: flex;
      column-gap: 3%;
      row-gap: 48px;
      flex-wrap: wrap;
      padding-bottom: 48px;
      &:last-child {
        padding-bottom: 0;
      }
      &__list {
        flex-basis: 100%;
        @include mq-size(xl) {
          flex-basis: 98.5%;
        }
      }
      &__row {
        display: flex;
        column-gap: 3%;
        flex-wrap: wrap;
        @include mq-size(s) {
          column-gap: 3%;
        }
        @include mq-size(xl) {
          column-gap: 5%;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          flex-basis: 100%;
          word-break: break-all;
          @include mq-size(s) {
            flex-basis: 48.5%;
          }
          @include mq-size(xl) {
            flex-basis: 47.5%;
          }
          &:first-child {
            @include mq-size(s) {
              text-align: right;
            }
          }
          &:last-child {
            font-weight: 700;
            padding-bottom: 5px;
            min-height: 24px;
            @include mq-size(s) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
