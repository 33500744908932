<template>
  <div class="ing-wrapper">
    <ing-header />
    <ing-form-skeleton v-if="isLoadingOffer" />
    <div v-else>
      <ing-hero-container />
      <ing-borrower-signup-form @success="borrowerSignupSuccess()" />
    </div>
    <ing-footer />
  </div>
</template>

<script>
  import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
  import IngHeader from '@/components/ing/ing-header';
  import IngHeroContainer from '@/components/ing/ing-hero-container';
  import IngBorrowerSignupForm from '@/components/ing/ing-borrower-signup-form';
  import IngFormSkeleton from '@/components/ing/ing-form-skeleton';
  import IngFooter from '@/components/ing/ing-footer';
  import { getUrlParameterByName } from '@/mixins/urlParamMixin';
  import { routerPushByName } from '@/mixins/routerMixin';
  import { enableGTM } from '@/mixins/gtmMixins';
  import url from 'url';
  import { APPLIED, EXPIRED_OFFER, USED_OFFER } from '@/constants/OFFER_STATUS';
  import { RESOURCE_EXPIRED } from '@/constants/ERROR';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import {
    TRACK_EVENTS,
    TRACK_PRODUCT_NAME,
    TRACK_PRODUCT_STATUS,
    TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
  } from '@/constants/WEBTREKK';
  import APPLICATION_CHANNELS from '@/constants/APPLICATION_CHANNELS';

  export default {
    components: {
      IngHeader,
      IngHeroContainer,
      IngFooter,
      IngBorrowerSignupForm,
      IngFormSkeleton,
    },
    mixins: [enableGTM, routerPushByName, getUrlParameterByName, webtrekkMixin],
    data() {
      return {
        url,
        isLoadingOffer: true,
      };
    },
    computed: {
      ...mapState('blacksea', {
        offerStatus: 'offerStatus',
        offerDetailsError: 'error',
      }),
      ...mapGetters('blacksea', ['isTermLoan', 'isCreditLineLoan']),
      blackSeaOfferId: function () {
        return this.getUrlParameterByName('offerId');
      },
      blackSeaReturnUrl: function () {
        return this.getUrlParameterByName('returnUrl');
      },
      blackSeaRedirectURLQueryString: function () {
        const queryString = this.url.parse(window.location.href).search;
        return queryString ? queryString.substring(1) : '';
      },
    },
    watch: {
      isTermLoan() {
        this.trackEvent(
          TRACK_EVENTS.AMAZON_FIRMENKREDIT_APPLICATION,
          TRACK_PRODUCT_NAME.AMAZON_FIRMENKREDIT_APPLICATION,
          TRACK_PRODUCT_STATUS.ADD
        );
      },
      isCreditLineLoan() {
        this.trackEvent(
          TRACK_EVENTS.AMAZON_FLEXKREDIT_APPLICATION,
          TRACK_PRODUCT_NAME.AMAZON_FLEXKREDIT_APPLICATION,
          TRACK_PRODUCT_STATUS.ADD
        );
      },
      blackSeaReturnUrl() {
        this.SET_BS_RETURN_URL(this.blackSeaReturnUrl);
      },
      offerStatus() {
        if (this.offerStatus === APPLIED) {
          // If the Offer has the status “applied”, display the success page to the customer
          this.borrowerSignupSuccess();
        }
      },
      offerDetailsError() {
        if (
          [RESOURCE_EXPIRED, EXPIRED_OFFER].includes(
            this.offerDetailsError.errorCode
          )
        ) {
          this.routerPushByName('ing-offer-expired-error-page');
        }
        if (USED_OFFER === this.offerDetailsError.errorCode) {
          this.routerPushByName('ing-offer-already-used-error-page');
        }
      },
    },
    async mounted() {
      this.SET_BS_RETURN_URL(this.blackSeaReturnUrl);
      this.setBSQueryStringIntoStore(this.blackSeaRedirectURLQueryString);
      await this.getBSOfferDetails(this.blackSeaOfferId);
      this.isLoadingOffer = false;
    },
    methods: {
      ...mapMutations('blacksea', ['SET_BS_RETURN_URL', 'SET_BS_QUERY_STRING']),
      ...mapActions('blacksea', ['getBSOfferDetails']),
      borrowerSignupSuccess() {
        const { loanAmount } = this.$store.state.signupForm;
        const params = {
          orderValue: loanAmount,
          ...TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
        };
        if (this.isTermLoan) {
          this.trackEvent(
            TRACK_EVENTS.AMAZON_FIRMENKREDIT_APPLICATION_SUCCESS,
            TRACK_PRODUCT_NAME.AMAZON_FIRMENKREDIT_APPLICATION,
            TRACK_PRODUCT_STATUS.SUCCESS,
            params
          );
        } else {
          this.trackEvent(
            TRACK_EVENTS.AMAZON_FLEXKREDIT_APPLICATION_SUCCESS,
            TRACK_PRODUCT_NAME.AMAZON_FLEXKREDIT_APPLICATION,
            TRACK_PRODUCT_STATUS.SUCCESS,
            params
          );
        }
        this.routerPushByName('ing-borrower-thankyou-page');
      },
      includesAllMandatoryFields() {
        const mandatoryParams = [
          'offerId',
          'returnUrl',
          'expireOn',
          'signature',
          'keyId',
        ];
        return mandatoryParams.every((param) =>
          this.blackSeaRedirectURLQueryString.includes(param)
        );
      },
      setBSQueryStringIntoStore() {
        if (this.includesAllMandatoryFields()) {
          this.SET_BS_QUERY_STRING(this.blackSeaRedirectURLQueryString);
        } else {
          // if for any reason one of mandatory params is missing, means the link is not valid so, go to 404
          this.routerPushByName('not-found', {
            channel: APPLICATION_CHANNELS.AMAZON,
          });
        }
      },
    },
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerSignupPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerSignupPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerSignupPage.metaDescription'),
          },
        ],
      };
    },
  };
</script>
