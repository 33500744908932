<template>
  <ing-text-input
    v-model="firstName"
    :label="$t('forms.signupForm.firstName.label')"
    name="firstname"
    :error="error"
    :maxlength="35"
    data-qa-id="ident-firstname"
    data-unit-test="ing-ident-first-name-field"
    :displayErrorIfDirty="!submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      ...mapState('ident', ['ident', 'errors', 'submitClicked']),
      firstName: {
        get() {
          return this.ident.firstName;
        },
        set(value) {
          this.SET_FIRST_NAME(value);
        },
      },
      error() {
        return (
          this.errors &&
          this.errors.firstName &&
          this.$t(`forms.errors.${this.errors.firstName}`)
        );
      },
    },
    methods: {
      ...mapMutations('ident', ['SET_FIRST_NAME']),
    },
  };
</script>
