<template>
  <div class="ing-select">
    <label v-if="label" class="ing-select__label">
      {{ label }}
    </label>
    <div v-if="caption" class="ing-select__caption">
      {{ caption }}
    </div>
    <Native
      class="ing-select__element"
      :options="options"
      :disabled="disabled"
      :displayError="displayError"
      v-bind="$attrs"
      v-on="$listeners"
    />
    <FieldStatus v-if="displayError">
      <template #error>
        <slot name="error">
          {{ error }}
        </slot>
      </template>
    </FieldStatus>
    <FieldStatus v-if="displayInfo">
      <template #info>
        <slot name="info">
          {{ info }}
        </slot>
      </template>
    </FieldStatus>
  </div>
</template>

<script>
  import Native from '@/components/ingOrangeJuice/Base/Dropdown/select/Native';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';

  export default {
    name: 'Dropdown',
    components: {
      FieldStatus,
      Native,
    },
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      options: {
        required: true,
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: null,
      },
      info: {
        type: String,
        default: null,
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: false,
      },
      displayErrorIfSubmitted: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      hasError() {
        const error = this.$slots.error || this.error;
        return !!error && error.length > 0;
      },
      displayInfo() {
        return this.$slots.info || this.info;
      },
      displayError() {
        if (this.displayErrorIfDirty) {
          return this.isDirty && this.hasError;
        }
        if (!this.displayErrorIfSubmitted) {
          return false;
        }
        return this.hasError;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .ing-select {
    font-family: inherit;
    align-items: stretch;
    padding-bottom: px2rem(33px);
    &__label {
      display: block;
      font-family: inherit;
      color: $Primary-Grey600;
      font-size: px2rem(16px);
      font-weight: 400;
      line-height: px2rem(24px);
    }
    &__caption {
      font-family: inherit;
      font-size: px2rem(14px);
      font-weight: 400;
      line-height: px2rem(20px);
      margin-bottom: 4px;
      color: $Primary-Grey400;
      text-align: left;
      width: 100%;
    }
    &__status {
      font-size: px2rem(16px);
      margin: 6px 0 0;
    }
  }
</style>
