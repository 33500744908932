<template>
  <div class="header-container">
    <len-icon
      icon="factory"
      align="left"
      iconSvg="false"
      marginBottom="18"
      marginTop="0"
    />
    <template v-if="isPartner">
      <h1 class="title">
        {{ $t('partnerSubmissionPageNew.form.companyHeader.title') }}
      </h1>
      <p class="explanation">
        {{ $t('partnerSubmissionPageNew.form.companyHeader.explanation') }}
      </p>
    </template>
    <template v-else>
      <h1 class="title">
        {{ $t('borrowerSignupForm.companyHeader.title') }}
      </h1>
      <p class="explanation">
        {{ $t('borrowerSignupForm.companyHeader.explanation') }}
      </p>
    </template>
  </div>
</template>
<script>
  import LenIcon from '@/components/common/len-icon';
  import { mapGetters } from 'vuex';

  export default {
    components: {
      LenIcon,
    },
    computed: {
      ...mapGetters('signupForm', ['isPartner']),
    },
  };
</script>
<style scoped lang="scss">
  @import '@/variables.scss';

  .header-container {
    margin-top: 40px;
    margin-bottom: 53px;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .title {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 38px;
    letter-spacing: normal;
    color: #29bdba;
    margin-left: 12px;
    margin-bottom: 18px;
    margin-top: 0px;
  }
  .explanation {
    text-align: left;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #2e2e2e;
    width: 100%;
  }
  @media screen and (max-width: $mobile-l) {
    .header-container {
      flex-direction: column;
      margin-top: 5px;
      margin-bottom: 40px;
    }
    .title {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      margin-left: 0;
    }
    .explanation {
      font-size: 16px;
      line-height: 18px;
    }
  }
</style>
