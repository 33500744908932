import axios from 'axios';
const axiosWithCredentials = axios.create({ withCredentials: true });

export async function startIdentCase({ state, commit }) {
  const { ident } = state;
  const payload = createIdentPayload(ident);
  await axiosWithCredentials
    .post(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/ident/startIdentCase`,
      payload
    )
    .then(({ data }) => {
      if (data.redirectUrl) {
        commit('SET_SUCCESS', true);
        commit('SET_REDIRECT_URL', data.redirectUrl);
      }
      return data;
    })
    .catch((error) => {
      const { status, data } = error.response;
      commit('SET_REQUEST_ERROR', { status, data });
    });
}

function createIdentPayload(ident) {
  const phonePrefix = '+49';
  return {
    referenceNumber: ident.referenceNumber.trim(),
    firstName: ident.firstName.trim(),
    lastName: ident.lastName.trim(),
    email: ident.email.trim(),
    cellphone: phonePrefix + ident.mobilePhone.trim(),
  };
}
