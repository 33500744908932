export const routerPushByName = {
  methods: {
    routerPushByName(name, params = {}) {
      let route = this.$router.resolve({ name });
      if (route) {
        return this.$router.push({ name, params });
      }
      return this.$router.replace({ name: 'not-found' });
    },
  },
};

export function getIsRouteActive(name) {
  let currentRouteName = this.$router.app.$route.name;
  if (currentRouteName.indexOf(name) !== -1) {
    return true;
  }
  let route = this.$router.resolve({ name });
  return !!(
    route &&
    route.route &&
    route.route.meta.activeRoutes &&
    route.route.meta.activeRoutes.indexOf(currentRouteName) !== -1
  );
}
