<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('forms.signupForm.smeHeader.h3') }}
      </h3>
      <p class="sme-section__description">
        {{ $t('forms.signupForm.smeHeader.p') }}
      </p>
      <FieldStatus
        data-unit-test="ing-mllf-sme-header-info"
        class="sme-section__mllf-info"
        :info="$t('forms.signupForm.smeHeader.info')"
        inputmode="numeric"
      />
      <TextInput
        v-model="employeesNumber"
        :label="$t('forms.signupForm.employeesNumber.label')"
        :error="getError('employeesNumber')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="revenues"
        :label="$t('forms.signupForm.companyRevenues.label')"
        :error="getError('revenues')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-if="isRegularLoanRequest"
        v-model="assets"
        :label="$t('forms.signupForm.companyAssets.label')"
        :error="getError('assets')"
        :displayErrorIfDirty="displayError()"
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import {
    hasControllingCompany,
    isFeatureFlagMLLFEnabled,
    isRegularLoanRequest,
  } from '@/mixins/signersMixin';

  export default {
    components: {
      TextInput,
      FormSubSection,
      FieldStatus,
    },
    mixins: [
      hasControllingCompany,
      isFeatureFlagMLLFEnabled,
      isRegularLoanRequest,
    ],
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
      ...mapGetters('signers', ['isComplexLegalForm']),
      employeesNumber: {
        get() {
          return this.company.employeesNumber;
        },
        set(value) {
          this.SET_EMPLOYEES_NUMBER(value);
        },
      },
      revenues: {
        get() {
          if (this.company.revenues) {
            return this.$options.filters.euroFormatter(this.company.revenues);
          } else {
            return null;
          }
        },
        set(value) {
          value = this.$options.filters.removeEuroFormat(value);
          if (isNaN(value) || value === '') {
            this.SET_COMPANY_REVENUES('');
          } else {
            value = parseFloat(value).toFixed(2);
            this.SET_COMPANY_REVENUES(value);
          }
        },
      },
      assets: {
        get() {
          if (this.company.assets) {
            return this.$options.filters.euroFormatter(this.company.assets);
          } else {
            return null;
          }
        },
        set(value) {
          value = this.$options.filters.removeEuroFormat(value);
          if (isNaN(value) || !value) {
            this.SET_COMPANY_ASSETS('');
          } else {
            value = parseFloat(value).toFixed(2);
            this.SET_COMPANY_ASSETS(value);
          }
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_EMPLOYEES_NUMBER',
        'SET_COMPANY_REVENUES',
        'SET_COMPANY_ASSETS',
      ]),
      getError(field) {
        return (
          this.errors.company &&
          this.errors.company[field] &&
          this.$t('forms.errors.' + this.errors.company[field])
        );
      },
      displayError() {
        return !this.submitClicked && !this.companySubmitClicked;
      },
    },
  };
</script>
<style scoped lang="scss">
  .sme-section {
    &__description {
      margin-bottom: 16px;
    }
    &__mllf-info {
      margin-bottom: 24px;
    }
  }
</style>
