<template>
  <len-text-input
    v-model="postalStreet"
    :name="'postal-street-' + signerId"
    :label="$t('forms.signupForm.streetOnly.label')"
    :error="
      errors[signerId] &&
      errors[signerId].postalStreet &&
      $t('forms.errors.' + errors[signerId].postalStreet)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      postalStreet: {
        get() {
          return this.signers[this.signerId].postalStreet;
        },
        set(value) {
          this.SET_POSTAL_STREET({
            signerId: this.signerId,
            postalStreet: value,
          });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_POSTAL_STREET']),
    },
  };
</script>
