import { isEmpty } from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateCompany(company) {
  if (isEmpty(company)) {
    return { company: 'CompanyIsRequired' };
  }
  if (hasForbiddenCharacters(company)) {
    return { company: 'ForbiddenCharacters' };
  }

  return { company: undefined };
}
