<template>
  <footer class="ing-the-footer">
    <div class="ing-the-footer__linklist">
      <a
        :aria-label="$t('theFooter.links.cookieSettings.ariaLabel')"
        :href="$translationConstantValues.theFooter.cookieSettings"
        class="ing-the-footer__link"
        @click="openCookieBanner"
        >{{ $t('theFooter.links.cookieSettings.text') }}</a
      >
      <a
        :href="$translationConstantValues.theFooter.termsAndConditions"
        target="_blank"
        class="ing-the-footer__link"
        >{{ $t('theFooter.links.termsAndConditions.text') }}</a
      >
      <a
        :href="$translationConstantValues.theFooter.privacyPolicy"
        target="_blank"
        class="ing-the-footer__link"
        >{{ $t('theFooter.links.privacy.text') }}</a
      >
      <a
        :href="$translationConstantValues.theFooter.imprintUrl"
        target="_blank"
        class="ing-the-footer__link"
        >{{ $t('theFooter.links.imprint.text') }}</a
      >
    </div>
  </footer>
</template>

<script>
  export default {
    name: 'TheFooter',
    methods: {
      openCookieBanner() {
        window.dispatchEvent(
          new CustomEvent('ingde-cookie-consent:open', { detail: { level: 1 } })
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ing-the-footer {
    background: $Primary-Grey200;
    font-size: 16px;
    &__linklist {
      margin: 0 auto;
      display: flex;
      padding: 24px 16px;
      flex-direction: column;
      @include mq-size(m) {
        flex-direction: row;
        padding: 24px;
      }
    }
    &__link {
      color: $Primary-Grey600;
      line-height: 24px;
      display: inline-block;
      padding-bottom: 12px;
      @include mq-size(m) {
        padding-right: 22px;
        margin-right: 22px;
        padding-bottom: 0;
        border-right: 1px solid $Primary-Grey400;
      }
      &:last-child {
        border: none;
        padding-bottom: 0;
      }
    }
  }
</style>
