var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{class:{ error: _vm.displayError }},[_c('div',{class:{
      'is-focused': _vm.isFocused,
      'is-empty': !_vm.mobilePhoneBorrower,
    }},[_c('label',{staticClass:"ing-label"},[_vm._v(" "+_vm._s(_vm.label)+" ")]),_c('div',{staticClass:"form-control input-container",class:{
        'ing-input-error': _vm.displayError,
        'ing-input-focus': _vm.isFocused,
      }},[_c('div',{staticClass:"prefix"},[_vm._v(" +49 ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.mobilePhoneBorrower),expression:"mobilePhoneBorrower"}],attrs:{"inputmode":"numeric","name":_vm.safariAutocompleteOffName,"autocomplete":"nofill","data-qa-id":"mobile-number"},domProps:{"value":(_vm.mobilePhoneBorrower)},on:{"focus":_vm.onFocus,"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.mobilePhoneBorrower=$event.target.value}}})]),_c('div',{staticClass:"ing-error",class:{ active: _vm.displayError },attrs:{"role":"alert"}},[_c('img',{attrs:{"src":"/alert_icon.png","alt":"Alert Icon"}}),_c('p',[_vm._v(" "+_vm._s(_vm.errors.mobilePhoneBorrower && _vm.$t('forms.errors.' + _vm.errors.mobilePhoneBorrower))+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }