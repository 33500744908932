<template>
  <div class="subscription-section">
    <form
      v-if="!formSuccessfullySent"
      method="post"
      accept-charset="utf-8"
      @submit.prevent="onSubmit"
    >
      <IngFormLoader :manuallyShow="showLoader" class="loader-style" />

      <PageSection>
        <template #section-content>
          <div class="subscription-section__content">
            <h4 class="subscription-section__title">
              {{ $t('subscriptionPage.form.header.title') }}
            </h4>

            <div class="subscription-section__subtitle">{{
              $t('subscriptionPage.form.header.description1')
            }}</div>

            <div class="subscription-section__subtitle">{{
              $t('subscriptionPage.form.header.description2')
            }}</div>

            <div class="subscription-section__subtitle bold-text">{{
              $t('subscriptionPage.form.header.description3')
            }}</div>
            <div class="subscription-section__form">
              <TextInput
                v-model="subscription.email"
                :error="errorMessage"
                :displayErrorIfDirty="!submitClicked"
                :label="$t('subscriptionPage.form.email.label')"
                :name="$t('subscriptionPage.form.email.label')"
                inputmode="email"
              />
              <IngButton type="submit">
                {{ $t('subscriptionPage.form.submit') }}
              </IngButton>
            </div>
            <div class="subscription-section__more-info">
              <i18n path="subscriptionPage.form.warning.label" tag="div">
                <a
                  :href="$translationConstantValues.dataProtectionUrl"
                  place="linkText"
                  target="_blank"
                >
                  {{ $t('subscriptionPage.form.warning.linkText') }}</a
                >
              </i18n>
            </div>
          </div>
        </template>
      </PageSection>
    </form>
    <div v-else>
      <PageSection>
        <template #section-content>
          <div class="subscription-section__content">
            <h4 class="subscription-section__title">
              {{ $t('subscriptionPage.success.header.title') }}
            </h4>

            <div class="subscription-section__subtitle">
              <i18n path="subscriptionPage.success.body.part1.text" tag="div">
                <em place="boldContent">
                  {{ $t('subscriptionPage.success.body.part1.boldContent') }}
                </em>
              </i18n>
            </div>

            <div class="subscription-section__subtitle">
              <i18n path="subscriptionPage.success.body.part2.text" tag="div">
                <em place="boldContent">
                  {{ $t('subscriptionPage.success.body.part2.boldContent') }}
                </em>
              </i18n>
            </div>

            <div class="subscription-section__subtitle">
              <i18n path="subscriptionPage.success.body.part3.label" tag="div">
                <a
                  place="linkText"
                  :href="$translationConstantValues.businessBankingUrl"
                  target="_blank"
                >
                  {{ $t('subscriptionPage.success.body.part3.linkText') }}</a
                >
              </i18n>
            </div>
          </div>
        </template>
      </PageSection>
    </div>
  </div>
</template>

<script>
  import findKey from 'lodash/findKey';

  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';

  import { subscriptionValidator } from '@/validators';
  import { subscribeNewsletter } from '@/webservice/subscription';
  export default {
    name: 'SubscriptionNewsletter',
    components: {
      TextInput,
      IngButton,
      IngFormLoader,
      PageSection,
    },
    data() {
      return {
        subscription: {
          email: '',
        },
        submitClicked: false,
        errors: {},
        serverError: '',
        showLoader: false,
        formSuccessfullySent: false,
      };
    },
    computed: {
      errorMessage() {
        return (
          (this.errors.email && this.$t('forms.errors.' + this.errors.email)) ||
          this.serverError
        );
      },
    },
    watch: {
      subscription: {
        deep: true,
        handler() {
          this.validateForm();
        },
      },
    },
    mounted() {
      this.validateForm();
    },
    methods: {
      async onSubmit() {
        this.submitClicked = true;
        if (this.formDataIsValid()) {
          try {
            this.showLoader = true;
            await subscribeNewsletter({ email: this.subscription.email });
            this.formSuccessfullySent = true;
          } catch (error) {
            this.serverError = this.$t('forms.signupForm.errors.500');
          } finally {
            this.showLoader = false;
          }
        }
      },
      validateForm() {
        this.errors = subscriptionValidator(this.subscription);
      },
      formDataIsValid() {
        return !findKey(this.errors);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .subscription-section {
    &__content {
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__form {
      padding-top: px2rem(40px);
      @include mq-size(s) {
        width: 86.6%;
      }
      @include mq-size(m) {
        width: 57.1%;
      }
      @include mq-size(xl) {
        width: 48.8%;
      }
    }
    &__title {
      font-size: px2rem(36px);
      line-height: px2rem(48px);
      color: $Primary-Orange;
      font-weight: normal;
      flex: 0 0 100%;
      margin: 0 0 px2rem(18px) 0;
    }
    &__subtitle {
      color: $Primary-Grey600;
      font-size: px2rem(19px);
      line-height: px2rem(28px);
      font-weight: 400;
      margin-top: px2rem(14px);
      display: block;
    }
    &__more-info {
      margin: px2rem(80px) 0;
    }
  }
  .bold-text {
    font-weight: 700;
  }
</style>
