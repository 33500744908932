<template>
  <div class="cash-intensity-title-container">
    <p class="field-label">
      <em>{{ $t('forms.signupForm.cashIntensity.label') }}</em>
      <ing-bs-tooltip
        :tooltipMessage="$t('forms.signupForm.cashIntensity.tooltip')"
      />
    </p>
  </div>
</template>

<script>
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  export default {
    components: {
      IngBsTooltip,
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/settings';
  @import '../../../../sass/custom/variables';
  .field-label {
    display: inline-flex;
    align-items: baseline;
  }
  em {
    color: $ing-dark-gray;
  }
  @media (max-width: 870px) {
    .cash-intensity-title-container {
      max-width: 80%;
    }
  }
</style>
