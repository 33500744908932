<template>
  <fieldset :class="{ error: displayError }">
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !value,
        'is-readonly': readonly,
      }"
    >
      <div class="ing-label-wrapper">
        <label class="ing-label" v-html="label" />
        <ing-tooltip
          v-if="info"
          class="ing-input-tooltip"
          :tooltipMessage="info"
          messageClass="ing-input-tooltip-message"
        />
      </div>
      <input
        ref="input"
        v-bind="$attrs"
        :data-qa-id="dataQaId"
        :name="safariAutocompleteOffName"
        :value="inputValue"
        :readonly="readonly"
        :inputmode="inputmode"
        :class="{ 'ing-input-error': displayError }"
        :type="inputType"
        class="form-control"
        autocomplete="nofill"
        :maxlength="maxlength"
        @input="onInputHandler($event)"
        @keypress="onKeypressHandler($event)"
        @focus="onFocus"
        @keydown="$emit('keydown', $event)"
        @blur="onBlur"
      />
      <div
        v-if="!isPassword && displayErrorMessage"
        :class="{ active: displayError }"
        role="alert"
        class="ing-error"
      >
        <img src="/alert_icon.png" alt="Alert Icon" />
        <p>
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { textInputFunctionality } from '@/mixins/forms/textInputMixin';
  import IngTooltip from '@/components/ing/ing-tooltip';
  import IMask from 'imask';

  export default {
    components: { IngTooltip },
    mixins: [textInputFunctionality],
    inheritAttrs: false,
    props: {
      info: {
        type: String,
        default: '',
      },
      maskOptions: {
        type: Object,
        default: undefined,
      },
      dataQaId: {
        type: String,
        default: undefined,
      },
    },
    data() {
      return {
        inputValue: this.value,
      };
    },
    watch: {
      value: function (newVal) {
        this.inputValue = newVal;
      },
    },
    methods: {
      formatWithMask(value, selection) {
        if (this.maskOptions) {
          const maskedValue = IMask.pipe(value, this.maskOptions);
          this.inputValue = maskedValue;
          this.$refs.input.value = maskedValue;
          if (value.length < maskedValue.length && selection === value.length) {
            this.$refs.input.setSelectionRange(
              maskedValue.length,
              maskedValue.length
            );
          } else {
            this.$refs.input.setSelectionRange(selection, selection);
          }
        }
      },
      onInputHandler(event) {
        this.formatWithMask(event.target.value, event.target.selectionStart);
        this.onInput(event);
        this.inputValue = event.target.value;
      },
      onKeypressHandler(event) {
        if (
          this.maskOptions &&
          this.maskOptions.maxLength <= this.$refs.input.value.length &&
          this.$refs.input.selectionStart === this.$refs.input.selectionEnd
        ) {
          event.preventDefault();
          return false;
        }
      },
    },
  };
</script>

<style lang="scss">
  .ing-label-wrapper {
    padding-right: 5px;
    display: flex;
  }

  .ing-input-tooltip {
    margin: -2px 0 0 5px;
    height: 16px;
    width: 100%;
    padding-left: 3px;
  }

  .ing-input-tooltip-message {
    visibility: hidden;
    word-break: normal;
    font-weight: normal;
    position: absolute;
    background-color: #767676;
    border-radius: 4px;
    border: 1px solid #a8a8a8;
    font-size: 12px;
    padding: 10px;
    color: white;
    top: -79px;
    z-index: 100;
  }
  .is-readonly input[readonly] {
    opacity: 0.5;
  }
</style>
