<template>
  <div>
    <CompanyInformationOverview
      v-if="!isCompanyEditable"
      :isInvalid="isClosedFormInvalid"
      @edit-button-clicked="handleEdit()"
    />
    <CompanyInfomationEditForm
      v-if="isCompanyEditable"
      :isInvalid="isOpenFormMissingData"
      @cancel-button-clicked="handleCancel()"
      @submit-button-clicked="handleCompanySubmission()"
    />
  </div>
</template>

<script>
  import { mapMutations, mapGetters, mapActions, mapState } from 'vuex';
  import CompanyInformationOverview from '@/components/signers/CompanyInformation/CompanyInfomationOverview';
  import CompanyInfomationEditForm from '@/components/signers/CompanyInformation/CompanyInfomationEditForm';
  export default {
    components: {
      CompanyInformationOverview,
      CompanyInfomationEditForm,
    },

    computed: {
      ...mapGetters('signers', ['isCompanyValid']),
      ...mapState('signers', [
        'company',
        'errors',
        'isCompanyEditable',
        'companySubmitClicked',
        'submitClicked',
      ]),
      isOpenFormMissingData() {
        return this.companySubmitClicked && !this.isCompanyValid;
      },
      isClosedFormInvalid() {
        return (
          this.submitClicked && !this.isCompanyEditable && !this.isCompanyValid
        );
      },
    },
    watch: {
      isCompanyEditable() {
        this.RUN_COMPANY_VALIDATOR();
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_SUBMIT_CLICKED',
        'SET_IS_COMPANY_EDITABLE',
        'SET_LOADING',
        'SET_COMPANY_REQUEST_ERROR',
        'CLEAR_COMPANY_REQUEST_ERROR',
        'SET_COMPANY_SNAPSHOT',
        'REVERT_COMPANY_CHANGES',
        'CLEAR_COMPANY_SNAPSHOT',
        'RUN_COMPANY_VALIDATOR',
      ]),
      ...mapActions('signers', [
        'updateCompanyMissingDetails',
        'getCompanyDetails',
      ]),
      async handleCompanySubmission() {
        this.CLEAR_COMPANY_REQUEST_ERROR();
        this.SET_COMPANY_SUBMIT_CLICKED(true);
        if (!this.isCompanyValid) {
          return;
        }
        this.submitCompany();
      },
      async submitCompany() {
        this.SET_LOADING(true);
        await this.updateCompanyMissingDetails();
        if (!this.errors.companyServer) {
          await this.getCompanyDetails();
          this.CLEAR_COMPANY_SNAPSHOT();
          this.SET_IS_COMPANY_EDITABLE(false);
        }
        this.SET_LOADING(false);
      },
      async handleCancel() {
        this.CLEAR_COMPANY_REQUEST_ERROR();
        this.REVERT_COMPANY_CHANGES();
        this.CLEAR_COMPANY_SNAPSHOT();
        this.SET_IS_COMPANY_EDITABLE(false);
      },
      handleEdit() {
        this.SET_COMPANY_SNAPSHOT();
        this.SET_IS_COMPANY_EDITABLE(true);
      },
    },
  };
</script>
