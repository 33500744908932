<template>
  <div>
    <div class="addMaterialDesign">
      <div class="checkbox">
        <label>
          <input
            :value="value"
            type="checkbox"
            @change="$emit('input', $event.target.checked)"
          />
          <span class="checkbox-material">
            <span class="check" :class="{ error: displayError }" />
          </span>
          <p v-html="label" />
        </label>
      </div>
    </div>

    <div
      role="alert"
      class="text-block text-align--left display--block error-container"
    >
      <p v-if="displayError" class="validation">
        {{ error }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'LenCheckbox',
    props: {
      value: {
        type: Boolean,
        default: false,
      },
      label: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: null,
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      hasError() {
        return !!this.error && this.error.length > 0;
      },
      displayError() {
        if (this.displayErrorIfDirty) {
          return this.isDirty && this.hasError;
        }
        return this.hasError;
      },
    },
  };
</script>

<style scoped lang="scss">
  .checkbox {
    .checkbox-material span.check {
      z-index: 0;
    }
    span.check.error {
      border: 2px solid #d64245;
    }
    p {
      font-size: 14px;
      line-height: 16px;
      margin-bottom: 0;
    }
  }
  .validation {
    text-align: left;
    padding-left: 30px;
    padding-top: 5px;
    margin-bottom: 0;
    font-size: 14px;
    line-height: 14px;
  }
</style>
