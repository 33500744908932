<template>
  <div class="field-status">
    <div v-if="displayError" class="field-status__content">
      <IconError class="field-status__icon" />
      <slot name="error">
        {{ error }}
      </slot>
    </div>
    <div v-if="displayInfo" class="field-status__content">
      <IconInfo class="field-status__icon field-status__icon--info-icon" />
      <slot name="info">
        {{ info }}
      </slot>
    </div>
    <div v-if="displayWarning" class="field-status__content">
      <IconWarning
        class="field-status__icon field-status__icon--warning-icon"
      />
      <slot name="warning">
        {{ warning }}
      </slot>
    </div>
  </div>
</template>
<script>
  import IconError from '@/assets/icon-error';
  import IconInfo from '@/assets/icon-info';
  import IconWarning from '@/assets/icon-warning';
  export default {
    name: 'FieldStatus',
    components: {
      IconError,
      IconInfo,
      IconWarning,
    },
    props: {
      error: {
        type: String,
        default: null,
      },
      info: {
        type: String,
        default: null,
      },
      warning: {
        type: String,
        default: null,
      },
    },
    computed: {
      displayError() {
        return this.$slots.error || this.error;
      },
      displayInfo() {
        return this.$slots.info || this.info;
      },
      displayWarning() {
        return this.$slots.warning || this.warning;
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .field-status {
    font-size: px2rem(16px);
    margin: 6px 0 0;
    &__content {
      display: flex;
      align-items: center;
    }
    &__icon {
      margin-right: 10px;
      flex-basis: px2rem(18);
      flex-shrink: 0;
      align-self: flex-start;
      margin-top: 3px;
    }
  }
</style>
