<template>
  <div class="header-container">
    <img
      alt="Identity Icon"
      class="identity-icon-size"
      src="/ing_identity.png"
      data-unit-test="ing-ident-type-icon"
    />
    <div class="text-container" data-unit-test="ing-ident-type-header">
      <h2>
        {{
          $t('signersMissingInfo.formPage.identificationTypeSection.title.h2')
        }}
      </h2>
      <p>
        {{
          $t('signersMissingInfo.formPage.identificationTypeSection.title.p1')
        }}
      </p>
      <p>
        {{
          $t('signersMissingInfo.formPage.identificationTypeSection.title.p2')
        }}
      </p>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'IngIdentTypeHeader',
  };
</script>
<style scoped>
  .identity-icon-size {
    width: 64px;
    height: 46px;
  }
</style>
