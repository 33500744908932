import {
  isEmpty,
  hasDigit,
  hasSpecialCharsExceptHyphen,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateFirstName(firstName) {
  if (isEmpty(firstName)) {
    return { firstName: 'FirstNameIsRequired' };
  }

  if (
    hasForbiddenCharacters(firstName) ||
    hasDigit(firstName) ||
    hasSpecialCharsExceptHyphen(firstName)
  ) {
    return { firstName: 'ForbiddenCharacters' };
  }

  return { firstName: undefined };
}
