<template>
  <div class="ing-text-input">
    <label v-if="displayLabel" class="ing-text-input__label">
      <slot name="label">
        {{ label }}
      </slot>
    </label>
    <div v-if="caption" class="ing-text-input__caption">
      {{ caption }}
    </div>
    <div v-if="displayPrefix" class="ing-text-input__prefix">
      <slot name="prefix">
        {{ prefix }}
      </slot>
    </div>
    <input
      v-if="!textArea"
      ref="self"
      class="ing-text-input__element"
      :class="[
        inputSize ? 'ing-text-input__element--' + inputSize : '',
        { 'ing-text-input__element--error': displayError },
        { 'ing-text-input__element--prefixPadding': displayPrefix },
      ]"
      :disabled="disabled"
      :value="value"
      :readonly="readonly"
      :inputmode="inputmode"
      :type="inputType"
      :placeholder="placeholder"
      autocomplete="nofill"
      data-lpignore="true"
      :data-qa-id="dataQaId"
      :data-test-id="dataTestId"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <textarea
      v-else
      class="ing-text-input__element ing-text-input__element--textarea"
      :class="[
        inputSize ? 'ing-text-input__element--' + inputSize : '',
        { 'ing-text-input__element--error': displayError },
      ]"
      :disabled="disabled"
      :value="value"
      :readonly="readonly"
      :inputmode="inputmode"
      :type="inputType"
      autocomplete="nofill"
      data-lpignore="true"
      :data-qa-id="dataQaId"
      :data-test-id="dataTestId"
      @input="onInput"
      @focus="onFocus"
      @blur="onBlur"
    />
    <FieldStatus v-if="displayError">
      <template #error>
        <slot name="error">
          {{ error }}
        </slot>
      </template>
    </FieldStatus>
    <FieldStatus v-if="displayInfo">
      <template #info>
        <slot name="info">
          {{ info }}
        </slot>
      </template>
    </FieldStatus>
  </div>
</template>

<script>
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import removeEmojis from '@/utils/emojis';

  export default {
    name: 'TextInput',
    components: { FieldStatus },
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      textArea: {
        type: Boolean,
        default: false,
      },
      inputSize: {
        type: String,
        default: '',
      },
      prefix: {
        type: String,
        default: null,
      },
      info: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: null,
      },
      value: {
        type: String,
        default: '',
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: true,
      },
      inputmode: {
        type: String,
        default: 'text',
        required: false,
      },
      isPassword: {
        type: Boolean,
        default: false,
      },
      maxlength: {
        type: Number,
        default: -1,
      },
      dataQaId: {
        type: String,
        default: '',
      },
      dataTestId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isFocused: false,
        isDirty: false,
      };
    },
    computed: {
      hasError() {
        const error = this.$slots.error || this.error;
        return !!error && error.length > 0;
      },
      displayLabel() {
        return this.$slots.label || this.label;
      },
      displayPrefix() {
        return this.$slots.prefix || this.prefix;
      },
      displayInfo() {
        return this.$slots.info || this.info;
      },
      displayError() {
        const result = this.hasError && !this.isFocused;
        if (this.displayErrorIfDirty) {
          return this.isDirty && result;
        }
        return result;
      },
      inputType() {
        if (this.isPassword) {
          return 'password';
        } else {
          return 'text';
        }
      },
    },
    methods: {
      onInput(event) {
        const value = removeEmojis(event.target.value);
        event.target.value = value;
        this.$emit('input', value);
      },
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;
        this.$emit('focus');
      },
      onBlur() {
        this.isFocused = false;
        this.$emit('blur');
      },
      focus() {
        this.$refs.self.focus();
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .ing-text-input {
    position: relative;
    align-items: center;
    width: 100%;
    font-family: inherit;
    padding-bottom: px2rem(33px);
    &__element {
      font-family: inherit;
      font-weight: bold;
      background: $Primary-white;
      padding: 8px 12px;
      border: 1px solid $Primary-Grey400;
      box-shadow: inset 0 2px 2px $Primary-Grey200;
      border-radius: 4px;
      width: 100%;
      min-width: 150px;
      outline: none;
      box-sizing: border-box;
      color: $Primary-Grey600;
      height: px2rem(40px);
      font-size: px2rem(16px);
      line-height: px2rem(24px);
      &:focus {
        border: 3px solid $Secondary-Indigo500;
        padding: 6px 10px;
      }
      &:disabled {
        border: 1px solid $Primary-Grey200;
        background: $Primary-Grey100;
      }
      &:read-only {
        border: 1px solid $Primary-Grey300;
        background: $Primary-Grey100;
      }
      &--textarea {
        height: px2rem(80px);
      }
      &--error {
        border: 3px solid $Functional-Minus500;
        padding: 6px 10px;
      }
      &--prefixPadding {
        padding-left: 42px;
      }
      &--error#{&}--prefixPadding,
      &:focus#{&}--prefixPadding {
        padding-left: 40px;
      }
      &--xs {
        width: 25%;
        min-width: 100px;
      }
      &--s {
        width: 50%;
      }
      &--m {
        width: 75%;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Primary-Grey300;
        opacity: 1; /* Firefox */
      }

      &:-ms-input-placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Primary-Grey300;
      }

      &::-ms-input-placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: $Primary-Grey300;
      }
    }
    &__label {
      display: block;
      font-family: inherit;
      color: $Primary-Grey600;
      font-weight: 400;
      font-size: px2rem(16px);
      line-height: px2rem(24px);
    }
    &__caption {
      font-family: inherit;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      margin-bottom: 4px;
      color: $Primary-Grey400;
      text-align: left;
      width: 100%;
    }
    &__prefix {
      position: absolute;
      padding: px2rem(8px) px2rem(12px);
      font-size: px2rem(16px);
      line-height: px2rem(24px);
      font-weight: 700;
    }
  }
</style>
