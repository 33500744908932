<template>
  <len-text-input
    v-model="postalZip"
    :name="'postal-zip-' + signerId"
    :label="$t('forms.signupForm.postcodeDe.label')"
    :error="
      errors[signerId] &&
      errors[signerId].postalZip &&
      $t('forms.errors.' + errors[signerId].postalZip)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      postalZip: {
        get() {
          return this.signers[this.signerId].postalZip;
        },
        set(value) {
          this.SET_POSTAL_ZIP({ signerId: this.signerId, postalZip: value });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_POSTAL_ZIP']),
    },
  };
</script>
