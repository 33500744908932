import { createApp } from '@/app';
import numeral from '@/utils/registerNumeralLocale';
import Vue from 'vue';

const { app, router, store } = createApp();

// load a locale
numeral.locale('de');

router.onReady(() => {
  if (window.__INITIAL_STATE__) {
    store.replaceState(window.__INITIAL_STATE__);
  } else if (process.env.NODE_ENV !== 'production') {
    // eslint-disable-next-line no-undef
    const config = require('../dev-envs');
    store.commit('config/INITIALIZE', config);
    store.commit('locale/INITIALIZE', config);
  }

  // add a pointer to Vue for loading the calculator widget
  window.Vue = Vue;

  app.$mount('#app');
});

export { store };
