<template>
  <len-text-input
    v-model="postalCity"
    :name="'postal-city-' + signerId"
    :label="$t('forms.signupForm.city.label')"
    :error="
      errors[signerId] &&
      errors[signerId].postalCity &&
      $t('forms.errors.' + errors[signerId].postalCity)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      postalCity: {
        get() {
          return this.signers[this.signerId].postalCity;
        },
        set(value) {
          this.SET_POSTAL_CITY({ signerId: this.signerId, postalCity: value });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_POSTAL_CITY']),
    },
  };
</script>
