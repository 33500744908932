<template>
  <ing-text-input
    v-model="cityOfBirth"
    :name="'city-of-birth-' + signerId"
    :label="$t('forms.signupForm.cityOfBirth.label')"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].cityOfBirth &&
          this.$t('forms.errors.' + this.errors[this.signerId].cityOfBirth)
        );
      },
      cityOfBirth: {
        get() {
          return this.signers[this.signerId].cityOfBirth;
        },
        set(value) {
          this.SET_CITY_OF_BIRTH({
            signerId: this.signerId,
            cityOfBirth: value,
          });
        },
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_CITY_OF_BIRTH']),
    },
  };
</script>
