<template>
  <div>
    <div id="lenUserLogin" :class="{ 'modal-active': show }" class="modal in">
      <transition name="fade-transform">
        <div
          v-if="show"
          class="modal-dialog"
          role="document"
          style="
            max-height: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            padding-left: inherit;
            padding-right: inherit;
            width: auto;
          "
        >
          <div class="modal-content">
            <div class="close" @click="$emit('close')">
              <svg viewbox="0 0 20 20">
                <path class="close-x" d="M 5,5 L 15,15 M 15,5 L 5,15" />
              </svg>
            </div>
            <div class="modal-body">
              <slot />
            </div>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div
          v-if="show"
          class="modal-backdrop fade in"
          @click="$emit('close')"
        />
      </transition>
    </div>
  </div>
</template>
<script>
  import Window from '@/common/window';

  export default {
    name: 'Modal',
    // eslint-disable-next-line vue/require-prop-types
    props: ['show'],
    beforeMount() {
      if (Window.isFake) {
        return;
      }
      Window.document.addEventListener('keyup', this.escListener, false);
    },
    beforeDestroy() {
      if (Window.isFake) {
        return;
      }
      Window.document.removeEventListener('keyup', this.escListener, false);
    },
    methods: {
      escListener(e) {
        if (e.keyCode === 27) {
          this.$emit('close');
        }
      },
    },
  };
</script>
<style>
  .modal-body {
    margin: 30px 5px 40px !important;
  }
  .modal-dialog {
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .modal-active {
    display: block !important;
  }
  .fade-transform-enter-active,
  .fade-transform-leave-active {
    -webkit-transition: opacity 0.3s linear, transform 0.5s ease-out;
    -o-transition: opacity 0.3s linear, transform 0.5s ease-out;
    transition: opacity 0.3s linear, transform 0.5s ease-out;
  }
  .fade-transform-enter,
  .fade-transform-leave-to {
    opacity: 0;
    transform: translate(0, -10%);
  }
  .fade-enter-active,
  .fade-leave-active {
    -webkit-transition: opacity 0.15s linear;
    -o-transition: opacity 0.15s linear;
    transition: opacity 0.15s linear;
  }
  .fade-enter,
  .fade-leave-to {
    opacity: 0;
  }
  .modal-dialog {
    z-index: 1041;
  }
  .close {
    margin: 10px;
    float: right;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .close svg {
    display: block;
  }
  .close .close-x {
    stroke: black;
    fill: transparent;
    stroke-linecap: round;
    stroke-width: 3;
  }
</style>
