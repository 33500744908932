export const mutations = {
  SET_BS_OFFER(state, offer) {
    state.offer = offer;
  },
  SET_BS_OFFER_STATUS(state, offerStatus) {
    state.offerStatus = offerStatus;
  },
  SET_BS_RETURN_URL(state, returnUrl) {
    state.returnUrl = returnUrl;
  },
  SET_BS_QUERY_STRING(state, queryString) {
    // we need to add baseUrl to the queryString for the verifySignature Api
    const baseUrl = window.location.origin;
    state.blackSeaRedirectURLQueryString = queryString.concat(
      `&baseUrl=${baseUrl}`
    );
  },
  SET_BS_OFFER_DETAILS_ERROR(state, error) {
    state.error = error;
  },
};
