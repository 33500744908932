<template>
  <div id="tradingNameField" class="company-trading-name-wrapper">
    <len-text-input
      v-model="companyTradingName"
      :label="$t('forms.signupForm.tradingName.label')"
      :error="
        errors.company &&
        errors.company.companyTradingName &&
        $t('forms.errors.' + errors.company.companyTradingName)
      "
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-trading-name"
      @focus="companyTradingNameHasFocus = true"
      @blur="companyTradingNameHasFocus = false"
    />
    <tooltip
      :class="{
        'on-input-focus': companyTradingNameHasFocus || !!companyTradingName,
      }"
      :tooltipMessage="$t('forms.signupForm.tradingName.tooltip')"
      class="company-name-tooltip"
      messageClass="trading-name-tooltip-message"
    />
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import Tooltip from '@/components/signers/signers-tooltip';

  export default {
    components: { LenTextInput, Tooltip },
    data() {
      return {
        companyTradingNameHasFocus: false,
      };
    },
    computed: {
      companyTradingName: {
        get() {
          return this.company.companyTradingName;
        },
        set(value) {
          this.SET_COMPANY_TRADING_NAME(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_TRADING_NAME']),
    },
  };
</script>

<style lang="scss" scoped>
  .company-trading-name-wrapper {
    position: relative;
    line-height: 1.75;
  }

  .company-name-tooltip {
    position: absolute;
    left: 89px;
    top: 10px;
    transition: all 0.2s ease;
    &.on-input-focus {
      top: -12px;
      left: 66px;
    }
  }
</style>
