import { isEmpty } from '@/validators/helpers/string';
import { hasEmailFormat } from '@/validators/helpers/email';

export default function ValidateSubscriptionEmail(subscriptionEmail) {
  if (isEmpty(subscriptionEmail)) {
    return { subscriptionEmail: 'SubscriptionEmailIsRequired' };
  }
  if (!hasEmailFormat(subscriptionEmail)) {
    return { subscriptionEmail: 'SubscriptionEmailIsNotCorrect' };
  }

  return { subscriptionEmail: undefined };
}
