<script>
  export default {
    name: 'SkeletonLoader',
    props: {
      type: {
        type: String,
        default: null,
      },
      height: {
        type: [Number, String],
        default: null,
      },
    },
    methods: {
      generateBone(h, names, children = []) {
        names = Array.isArray(names) ? names : [names];
        const classes = names
          .map((name) => `skeleton-loader__${name}`)
          .concat(['skeleton-loader__bone'])
          .join(' ');
        const style = {};

        if (this.height) {
          style.height =
            typeof this.height === 'number' ? `${this.height}px` : this.height;
        }

        return h('div', { class: classes, style }, children);
      },
      generateLoader(h, name, children = []) {
        return h(
          'div',
          { class: `skeleton-loader skeleton-loader__${name}` },
          children
        );
      },
      renderHeader(h) {
        return this.generateLoader(h, 'header', [
          this.generateBone(h, 'icon'),
          this.generateBone(h, 'paragraph', [
            this.generateBone(h, 'heading'),
            this.generateBone(h, 'text'),
            this.generateBone(h, 'text'),
          ]),
        ]);
      },
      renderTwoLine(h) {
        return this.generateBone(h, 'paragraph', [
          this.generateBone(h, 'text'),
          this.generateBone(h, ['text', 'text--large']),
        ]);
      },
      renderOneLine(h) {
        return this.generateBone(h, 'paragraph', [
          this.generateBone(h, ['text', 'text--large']),
        ]);
      },
      renderBlock(h) {
        return this.generateBone(h, 'block');
      },
    },
    render(h) {
      switch (this.type) {
        case 'header':
          return this.renderHeader(h);
        case 'two-line':
          return this.renderTwoLine(h);
        case 'one-line':
          return this.renderOneLine(h);
        case 'block':
          return this.renderBlock(h);
        default:
          return this.generateBone(h);
      }
    },
  };
</script>

<style lang="scss" scoped>
  .skeleton-loader {
    &__bone {
      overflow: hidden;
      position: relative;

      &:after {
        animation: loading 1.5s infinite;
        background: linear-gradient(
          90deg,
          hsla(0, 0%, 100%, 0),
          hsla(0, 0%, 100%, 0.3),
          hsla(0, 0%, 100%, 0)
        );
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(-100%);
        z-index: 1;
      }
    }

    &__header {
      display: flex;
    }

    &__paragraph {
      display: flex;
      flex: 1 0 auto;
      flex-direction: column;
    }

    &__icon {
      background-color: #ebebeb;
      height: 80px;
      width: 60px;
      margin-right: 32px;
    }

    &__heading {
      background-color: #ebebeb;
      flex: 1 0 auto;
      height: 24px;
      width: 243px;
      margin-bottom: 12px;
    }

    &__text {
      background-color: #ebebeb;
      flex: 1 0 auto;
      height: 18px;
      margin-bottom: 6px;
      width: 100%;

      &--large {
        height: 24px;
      }

      &:first-child {
        width: 288px;
        margin-bottom: 12px;
      }
    }

    &__block {
      background-color: #ebebeb;
      flex: 1 0 auto;
      height: 40px;
    }

    @keyframes loading {
      100% {
        transform: translateX(100%);
      }
    }
  }
</style>
