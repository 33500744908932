export default {
  GMBH: 'GmbH',
  GMBH_AND_COKG: 'GmbH & Co. KG',
  FREE_PROFESSION: 'Freie Berufe',
  INDIVIDUAL_COMPANY: 'Einzelfirma',
  KG: 'KG',
  OHG: 'OHG',
  GBR: 'GbR',
  AG: 'AG',
  UG_LIMITED_LIABILITY: 'UG (haftungsbeschränkt)',
  LIMITED: 'Limited',
  OTHER: 'Andere',
};
