<template>
  <div
    class="display--inline-block grid__item col-xs-12"
    data-qa-id="partner-privacy-policy-div"
  >
    <p data-qa-id="partner-privacy-policy-text" class="explanation">
      {{ $t('partnerSubmissionPageNew.form.privacyPolicy.preHighlightedText') }}
      <b>
        {{ $t('partnerSubmissionPageNew.form.privacyPolicy.highlightedText') }}
      </b>
      {{ $t('partnerSubmissionPageNew.form.privacyPolicy.preLinkTitleText') }}
      <a
        :href="
          process.env.VUE_APP_LENDICO_BASE_URL_CMS_API +
          $t('partnerSubmissionPageNew.form.privacyPolicy.linkTitle')
        "
        target="_blank"
        data-qa-id="partner-privacy-policy-link1"
        class="base-link"
        >{{
          $t('partnerSubmissionPageNew.form.privacyPolicy.linkTitleText')
        }}</a
      >
      {{ $t('partnerSubmissionPageNew.form.privacyPolicy.preLinkPageText') }}
      <a
        :href="
          process.env.VUE_APP_LENDICO_BASE_URL_CMS_API +
          $t('partnerSubmissionPageNew.form.privacyPolicy.link')
        "
        target="_blank"
        data-qa-id="partner-privacy-policy-link2"
        class="base-link"
        >{{ $t('partnerSubmissionPageNew.form.privacyPolicy.linkPageText') }}</a
      >
      {{
        $t('partnerSubmissionPageNew.form.privacyPolicy.preLinkDocumentText')
      }}
      <a
        :href="$t('partnerSubmissionPageNew.form.privacyPolicy.linkIng')"
        target="_blank"
        data-qa-id="partner-privacy-policy-link3"
        class="base-link"
        >{{
          $t('partnerSubmissionPageNew.form.privacyPolicy.linkDocumentText')
        }}</a
      >
      {{ $t('partnerSubmissionPageNew.form.privacyPolicy.postLinkText') }}
    </p>

    <div class="explanation">
      <p>
        {{ $t('partnerSubmissionPageNew.form.privacyPolicy.disclaimer.p1') }}
        <a
          href="#"
          class="base-link"
          @click.prevent="openModal('CreditreformModal')"
          >{{
            $t(
              'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.cta1Text'
            )
          }}</a
        >
        {{ $t('partnerSubmissionPageNew.form.privacyPolicy.disclaimer.p2') }}
        <br />
        {{ $t('partnerSubmissionPageNew.form.privacyPolicy.disclaimer.p3') }}
        <a
          href="#"
          class="base-link"
          @click.prevent="openModal('CreditreformModal')"
          >{{
            $t(
              'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.cta1Text'
            )
          }}</a
        >
        {{ $t('partnerSubmissionPageNew.form.privacyPolicy.disclaimer.p4') }}
        <a
          href="#"
          class="base-link"
          @click.prevent="openModal('SchufaModal')"
          >{{
            $t(
              'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.cta2Text'
            )
          }}</a
        >
        {{ $t('partnerSubmissionPageNew.form.privacyPolicy.disclaimer.p5') }}
      </p>

      <Modal v-if="isSchufaModalOpen" @onClose="closeModal('SchufaModal')">
        <div class="modal-inner-content">
          <h2 class="modal-inner-header">
            {{
              $t(
                'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.schufaModalContent.title'
              )
            }}
          </h2>
          <div
            v-html="
              $t(
                'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.schufaModalContent.content'
              )
            "
          />
        </div>
      </Modal>
      <Modal
        v-if="isCreditreformModalOpen"
        @onClose="closeModal('CreditreformModal')"
      >
        <div class="modal-inner-content">
          <h2 class="modal-inner-header">
            {{
              $t(
                'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.creditreFormModalContent.title'
              )
            }}
          </h2>
          <div
            v-html="
              $t(
                'partnerSubmissionPageNew.form.privacyPolicy.disclaimer.creditreFormModalContent.content'
              )
            "
          />
        </div>
      </Modal>
    </div>
  </div>
</template>

<script>
  import Modal from '@/components/common/modal';

  export default {
    components: { Modal },
    data() {
      return {
        isSchufaModalOpen: false,
        isCreditreformModalOpen: false,
      };
    },
    methods: {
      openModal(target) {
        this[`is${target}Open`] = true;
      },
      closeModal(target) {
        this[`is${target}Open`] = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .explanation {
    font-size: 18px;
    line-height: 24px;
    color: #2e2e2e;
    margin-top: 30px;
  }

  @media only screen and (max-width: $mobile-l) {
    .explanation {
      font-size: 16px;
      line-height: 18px;
      margin-top: 20px;
    }
  }

  .modal-inner-content {
    font-size: 16px;
    line-height: 20px;
    color: #333;
    letter-spacing: -0.02em;
    padding-bottom: 50px;
  }
  .modal-inner-header {
    text-transform: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
</style>
