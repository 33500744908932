<template>
  <div class="ing-wrapper">
    <TheHeader />
    <div class="page-container">
      <PageHeader page="offer.dpagContractFailure.message" />
      <PageSection class="section-form" sectionPadding="sm">
        <template #section-content>
          <IngButton type="submit" @click="handleOnClick">
            {{ $t('offer.dpagContractFailure.support') }}
          </IngButton>
        </template>
      </PageSection>
    </div>
    <TheFooter />
  </div>
</template>
<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  export default {
    name: 'IngSignContractFailurePage',
    components: {
      TheHeader,
      TheFooter,
      PageHeader,
      IngButton,
      PageSection,
    },
    methods: {
      handleOnClick: function () {
        window.location = `mailto:${
          this.$translationConstantValues.theContact[this.$route.meta.channel]
            .contact.email
        }`;
      },
    },
  };
</script>
<style scoped lang="scss">
  .page-container {
    flex-grow: 1;
  }
</style>
