import { getUrlParameterByName } from '@/mixins/urlParamMixin';
import { mapActions, mapMutations } from 'vuex';
import cookie from '@/common/cookie';
import {
  isAmountWithinRange,
  isDisbursementAmountWithinRange,
  isLengthWithinRange,
} from '@/mixins/calculatorValuesMixin';
import { removeUrlProtocol } from '@/utils/stringUtilities';
export const eventListeners = {
  methods: {
    addEventListeners() {
      window.addEventListener(
        'scroll',
        this.throttledOnScroll.bind(this),
        false
      );
      window.addEventListener(
        'resize',
        this.throttledOnScroll.bind(this),
        false
      );
    },
    removeEventListeners() {
      window.removeEventListener(
        'scroll',
        this.throttledOnScroll.bind(this),
        false
      );
      window.removeEventListener(
        'resize',
        this.throttledOnScroll.bind(this),
        false
      );
    },
  },
};

export const utmParamsExtraction = {
  mixins: [getUrlParameterByName],
  methods: {
    ...mapActions('signupForm', ['setUTMParams']),
    extractUTMParams() {
      const urlContentInfo = this.getUrlParameterByName('wt_ga');
      const cookieContentInfo = cookie.get('openBusiness_wt_ga');
      const contentInfo = urlContentInfo ?? cookieContentInfo ?? null;

      const urlKeywordInfo = this.getUrlParameterByName('wt_kw');
      const cookieKeywordInfo = cookie.get('openBusiness_wt_kw');
      const keywordInfo = urlKeywordInfo ?? cookieKeywordInfo ?? null;

      const utmParams = {
        medium: null,
        source: null,
        product: null,
        campaignName: null,
        content: null,
        term: null,
        contentInfo: contentInfo ? contentInfo : null,
        keywordInfo: keywordInfo ? keywordInfo : null,
      };
      if (contentInfo) {
        const [, creative] = contentInfo.split('_');
        if (creative) {
          utmParams.content = creative;
        }
      }
      if (keywordInfo) {
        const [, , keyword] = keywordInfo.split('_');

        if (keyword) {
          utmParams.term = keyword;
        }
      }
      if (contentInfo || keywordInfo) {
        utmParams.medium = 'cpc';
      } else if (!contentInfo && !keywordInfo) {
        utmParams.medium = 'organic';
        // NOTE: protocol in document referrer blocks the application submission.
        //       with an 403 due to an azure WAF prevention policy.
        //       Removing the protocol and sending only the domain and params to fix it
        utmParams.source = removeUrlProtocol(document.referrer);
      }
      this.setUTMParams(utmParams);
    },
  },
};

/* analyticsDataExtraction and SET_UTMZ_DATA should be deleted while GTM clean up */
export const analyticsDataExtraction = {
  methods: {
    ...mapMutations('signupForm', ['SET_UTMZ_DATA']),
    extractAnalyticsData() {
      let z = this._uGC(document.cookie, '__utmz=', ';');
      let source = this._uGC(z, 'utmcsr=', '|');
      let medium = this._uGC(z, 'utmcmd=', '|');
      let term = this._uGC(z, 'utmctr=', '|');
      let content = this._uGC(z, 'utmcct=', '|');
      let campaign = this._uGC(z, 'utmccn=', '|');
      let gclid = this._uGC(z, 'utmgclid=', '|');
      if (gclid !== '-') {
        source = 'google';
        medium = 'cpc';
      }
      let csegment = this._uGC(document.cookie, '__utmv=', ';');
      if (csegment !== '-') {
        let csegmentex = /[1-9]*?\.(.*)/;
        csegment = csegment.match(csegmentex);
        csegment = csegment[1];
      } else {
        csegment = '';
      }

      let analData = { medium, source, campaign, term, content };
      this.SET_UTMZ_DATA(analData);
    },
    _uGC(l, n, s) {
      if (!l || l === '' || !n || n === '' || !s || s === '') {
        return '-';
      }
      let i = '-';
      let i2 = '-';
      let i3 = '-';
      let c = '-';
      i = l.indexOf(n);
      i3 = n.indexOf('=') + 1;
      if (i > -1) {
        i2 = l.indexOf(s, i);
        if (i2 < 0) {
          i2 = l.length;
        }
        c = l.substring(i + i3, i2);
      }
      return c;
    },
  },
};

export const setAmountAndLength = {
  mixins: [getUrlParameterByName, isAmountWithinRange, isLengthWithinRange],

  methods: {
    ...mapMutations('signupForm', ['SET_LENGTH', 'SET_AMOUNT']),
    setAmountAndLength() {
      let length = this.getUrlParameterByName('length');
      let amount = this.getUrlParameterByName('amount');
      this.isLengthWithinRange(length)
        ? this.SET_LENGTH(length)
        : this.SET_LENGTH(null);
      this.isAmountWithinRange(amount)
        ? this.SET_AMOUNT(amount)
        : this.SET_AMOUNT(null);
    },
  },
};

export const mediaCodesExtraction = {
  mixins: [getUrlParameterByName],
  methods: {
    ...mapActions('signupForm', ['setAnalyticsData']),
    extractMediaCodes() {
      const urlMediaCode = this.getUrlParameterByName('wt_mc');
      const cookieMediaCode = cookie.get('openBusiness_wt_mc');
      const mediaCodeInfo = urlMediaCode ?? cookieMediaCode ?? null;
      if (mediaCodeInfo) {
        const [
          medium = null,
          source = null,
          product = null,
          campaignName = null,
          content = null,
        ] = mediaCodeInfo.split('.');

        // Bing Tracking on Keyword-Level
        // eg.: wt_mc=sem.yahoo-bing.{Campaign}.{AdGroup}.{adGroupId}
        if (mediaCodeInfo.includes('sem')) {
          const [, , campaignName = null] = mediaCodeInfo.split('.');

          this.setAnalyticsData({
            medium: 'cpc',
            source: 'yahoo-bing',
            product: null,
            campaignName: campaignName,
            content: null,
            mediaCodeInfo,
          });

          return;
        }

        this.setAnalyticsData({
          medium,
          source,
          product,
          campaignName,
          content,
          mediaCodeInfo,
        });
      }
    },
  },
};

export const setDisbursementAmount = {
  mixins: [getUrlParameterByName, isDisbursementAmountWithinRange],

  methods: {
    ...mapMutations('signupForm', ['SET_DISBURSEMENT_AMOUNT']),
    setDisbursementAmount() {
      let disbursementAmount = this.getUrlParameterByName('disbursementAmount');
      let amount = this.getUrlParameterByName('amount');
      this.isDisbursementAmountWithinRange(disbursementAmount, amount)
        ? this.SET_DISBURSEMENT_AMOUNT(disbursementAmount)
        : this.SET_DISBURSEMENT_AMOUNT(null);
    },
  },
};

export const wtCookieRemove = {
  methods: {
    removeWTCookies() {
      const wtCodes = ['wt_mc', 'wt_ga', 'wt_kw'];
      wtCodes.forEach((code) => {
        cookie.remove(`openBusiness_${code}`, '.ing.de');
      });
    },
  },
};
