<template>
  <div class="company-name-wrapper">
    <len-text-input
      v-model="companyName"
      :label="$t('forms.signupForm.companyName.label')"
      :error="
        errors.company &&
        errors.company.company &&
        $t('forms.errors.' + errors.company.company)
      "
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-name"
      :readonly="true"
      @focus="companyNameHasFocus = true"
      @blur="companyNameHasFocus = false"
    />
    <tooltip
      :class="{ 'on-input-focus': companyNameHasFocus || !!companyName }"
      :tooltipMessage="$t('forms.signupForm.companyName.tooltipBridge')"
      class="company-name-tooltip"
      messageClass="company-name-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: { LenTextInput, Tooltip },
    data() {
      return {
        companyNameHasFocus: false,
      };
    },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
      companyName: {
        get() {
          return this.company.companyName;
        },
        set(newValue) {
          this.SET_COMPANY_NAME(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_NAME']),
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/settings';
  .company-name-wrapper {
    position: relative;
    line-height: 1.75;
  }
  .company-name-tooltip {
    position: absolute;
    left: 80px;
    top: 10px;
    transition: all 0.2s ease;
    &.on-input-focus {
      top: -12px;
      left: 60px;
    }
  }
</style>
