const state = {};

const actions = {};
const getters = {};

const mutations = {
  INITIALIZE: (state, config) => {
    Object.assign(state, config);
  },
  USE_OLD_FUNNEL_GATEWAY_API_URL: () => {
    Object.assign(state, {
      VUE_APP_FUNNEL_GATEWAY_API: process.env.VUE_APP_OLD_FUNNEL_GATEWAY_API,
    });
  },
};

export default { state, getters, actions, mutations, namespaced: true };
