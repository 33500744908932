import Window from '@/common/window';
import find from 'lodash/find';
import trim from 'lodash/trim';

/**
 * Class uses to work with cookies.
 */
export default class {
  /**
   * @return {boolean}
   */
  static windowIsDefined() {
    return !Window.isFake;
  }

  /**
   * @param {string} name
   * @param {string} value
   * @param {int} expirationDays
   * @param {string} domain
   */
  static set(name, value, expirationDays, domain = '') {
    if (!this.windowIsDefined()) {
      return;
    }

    let date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expires = `;expires=${date.toUTCString()}`;
    const cookieDomain = domain ? `;domain=${domain}` : domain;
    Window.document.cookie = `${name}=${value}${expires}${cookieDomain};path=/`;
  }

  /**
   * @param {string} name
   * @return {string|undefined}
   */
  static get(name) {
    if (!this.windowIsDefined()) {
      return;
    }

    let cookies = (decodeURIComponent(Window.document.cookie) || '').split(';');

    if (!cookies.length) {
      return;
    }

    let cookie = find(
      cookies,
      (item) => trim(item || '').indexOf(name + '=') === 0
    );

    if (!cookie) {
      return;
    }

    cookie = trim(cookie, ' ;').split('=');

    if (cookie.length < 2) {
      return;
    }

    return cookie[1];
  }

  /**
   * @param {string} name
   * @param {string} domain
   */
  static remove(name, domain = '') {
    return this.set(name, '', -1, domain);
  }
}
