<template>
  <label
    class="radio-container"
    :class="{
      'radio-container--focus': isFocused,
    }"
    :data-unit-test="dataUnitTest"
  >
    <div class="radio-container__checkmark">
      <span
        class="radio-checkmark"
        :class="{
          'radio-checkmark--checked': isChecked,
          'radio-checkmark--error': hasError,
        }"
      />
    </div>
    <div class="radio-container__label">
      <slot name="label">{{ label }}</slot>
    </div>
    <input
      :value="value"
      :checked="isChecked"
      :name="name"
      type="radio"
      class="form-radio"
      :data-qa-id="dataQaId"
      @change="onChange"
      @focus="isFocused = true"
      @blur="isFocused = false"
    />
  </label>
</template>
<script>
  export default {
    model: {
      prop: 'selectedValue',
      event: 'change',
    },

    props: {
      selectedValue: {
        type: [String, Number, Boolean],
        default: null,
      },
      value: {
        type: [String, Boolean],
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      hasError: {
        type: Boolean,
        default: false,
      },
      dataUnitTest: {
        type: String,
        default: '',
      },
      dataQaId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isFocused: false,
      };
    },
    computed: {
      isChecked() {
        return this.selectedValue === this.value;
      },
    },
    methods: {
      onChange() {
        this.$emit('change', this.value);
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .radio-container {
    display: flex;
    cursor: pointer;
    font-size: 16px;
    line-height: 18px;
    user-select: none;
    font-weight: normal;
    padding: 3px;
    clear: left;
    &--focus {
      .radio-checkmark {
        border: 1px solid $Primary-Orange;
      }
    }

    &__label {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
    }

    &__checkmark {
      display: flex;
      flex: 0 1 auto;
      margin-right: 8px;
      align-items: center;
    }
  }

  /* Hide the browser's default radio button */
  .radio-container input.form-radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    width: 0;
    height: 0;
  }

  /* Create a custom radio button */
  .radio-checkmark {
    position: relative;
    height: 18px;
    width: 18px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #9b9b9b;
    box-sizing: border-box;

    &--error {
      border-color: #d64245;
    }
  }

  /* On mouse-over, add a grey background color */
  .radio-container:hover input.form-radio ~ .radio-checkmark {
    background-color: white;
  }

  /* When the radio button is checked, add a lendico color background */
  .radio-container input.form-radio:checked ~ .radio-checkmark {
    background-color: white;
    border-color: #2e2e2e;
  }

  .radio-checkmark {
    &:after {
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: none;
    }

    &--checked::after {
      display: block;
    }
  }

  /*end custom radio button */
</style>
