import LenRouterLink from './len-router-link';
import LenHeader from './len-header';
import LenSection from './len-section';
import LenPagination from './len-pagination';
// import NoSSR from 'vue-no-ssr'
import LenImage from './len-image';
import Window from '@/common/window';

export default function install(Vue) {
  Vue.component('LenRouterLink', LenRouterLink);
  Vue.component('LenHeader', LenHeader);
  Vue.component('LenSection', LenSection);
  Vue.component('LenPagination', LenPagination);
  // Vue.component('no-ssr', NoSSR)
  Vue.component('LenImage', LenImage);

  if (Window.document) {
    let VueSlider = require('vue-slider-component');
    Vue.component('VueSlider', VueSlider);

    let Datepicker = require('vuejs-datepicker');
    Vue.component('VueDatepicker', Datepicker);
  }
}
