<template>
  <div class="header-container">
    <img
      alt="Representative Icon"
      class="representative-icon-size"
      src="/ing_representative_icon.svg"
    />
    <div v-if="isRegularLoanRequest" class="text-container">
      <h2>
        {{
          $t(
            'signersMissingInfo.formPage.clientsSection.regularLoanRequest.title.h2'
          )
        }}
      </h2>
      <p
        v-html="
          $t(
            'signersMissingInfo.formPage.clientsSection.regularLoanRequest.title.p'
          )
        "
      />
    </div>
    <div v-else class="text-container">
      <h2>
        {{
          $t(
            'signersMissingInfo.formPage.clientsSection.microLoanRequest.title.h2'
          )
        }}
      </h2>
      <p
        v-html="
          $t(
            'signersMissingInfo.formPage.clientsSection.microLoanRequest.title.p'
          )
        "
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { isRegularLoanRequest } from '@/mixins/signersMixin';

  export default {
    mixins: [isRegularLoanRequest],
    computed: {
      ...mapState('signers', ['company']),
    },
  };
</script>

<style scoped>
  .representative-icon-size {
    width: 64px;
  }
</style>
