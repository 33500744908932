import ibanValidator from 'iban';

export default function ValidateIban(iban) {
  if (iban === 'DE') {
    return { iban: 'IbanIsRequired' };
  }
  if (!ibanValidator.isValid(iban.replace(/\s+/g, ''))) {
    return { iban: 'IbanIsNotCorrect' };
  }
  return { iban: undefined };
}
