import { isEmpty } from '@/validators/helpers/string';
import { hasEmailFormat, hasDotBeforeAtSign } from '@/validators/helpers/email';

export default function ValidateIdentEmail(email) {
  if (isEmpty(email)) {
    return { email: 'EmailIsRequired' };
  }
  if (hasDotBeforeAtSign(email)) {
    return { email: 'EmailHasWrongStructure' };
  }
  if (!hasEmailFormat(email)) {
    return { email: 'EmailIsNotCorrect' };
  }

  return { email: undefined };
}
