<template>
  <fieldset :class="{ error: displayError }">
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !value,
      }"
    >
      <label class="ing-label" v-html="label" />
      <input
        ref="date-input"
        :name="name"
        :class="{
          'show-text': shouldShowText,
          'ing-input-error': displayError,
        }"
        inputmode="numeric"
        class="form-control imask-input"
        v-bind="$attrs"
        @focus="handleFocus"
        @blur="isFocused = false"
      />
    </div>
    <div
      :class="{ active: displayError, 'long-error-message': longErrorMessage }"
      role="alert"
      class="ing-error"
    >
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p>
        {{ error }}
      </p>
    </div>
  </fieldset>
</template>

<script>
  import { dateInputFunctionality } from '@/mixins/forms/dateInputMixin';
  export default {
    mixins: [dateInputFunctionality],
    inheritAttrs: false,
  };
</script>

<style scoped>
  .long-error-message {
    height: auto;
  }
</style>
