<template>
  <ing-text-input
    v-model="postalStreetNumber"
    :label="$t('forms.signupForm.streetNumber.label')"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="company-postal-street-number"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
      postalStreetNumber: {
        get() {
          return this.company.postalStreetNumber;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_STREET_NUMBER(value);
        },
      },
      error() {
        return (
          this.errors.company &&
          this.errors.company.postalStreetNumber &&
          this.$t('forms.errors.' + this.errors.company.postalStreetNumber)
        );
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_POSTAL_STREET_NUMBER']),
    },
  };
</script>
