<template>
  <div class="overview-container offer-data-container">
    <div class="col-lg-6 col-sm-10">
      <div class="row">
        <p class="field-label col-sm-6">
          <em>
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.regular.loanAmount'
              )
            }}:
          </em>
        </p>
        <p class="col-sm-6">
          {{
            offerConditions.loanAmount | emptyValueReplacer | europeanCurrency
          }}
          EUR
        </p>
      </div>
      <div class="row">
        <p class="field-label col-sm-6">
          <em>
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.regular.duration'
              )
            }}:
          </em>
        </p>
        <p class="col-sm-6">
          {{
            offerConditions.durationInMonths
              | momentjsDuration('month')
              | emptyValueReplacer
          }}
        </p>
      </div>
      <div class="row">
        <p class="field-label col-sm-6">
          <em>
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.regular.interestRate'
              )
            }}:
          </em>
        </p>
        <p class="col-sm-6">
          {{
            offerConditions.interestRate | emptyValueReplacer | europeanCurrency
          }}
          %
        </p>
      </div>
      <div class="row">
        <p class="field-label col-sm-6">
          <em>
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.regular.monthlyRate'
              )
            }}:
          </em>
        </p>
        <p class="col-sm-6">
          {{
            offerConditions.installment | emptyValueReplacer | europeanCurrency
          }}
          EUR
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    computed: {
      ...mapState('signers', ['offerConditions']),
    },
  };
</script>

<style scoped>
  .offer-data-container {
    font-size: 14px;
    font-weight: bold;
    flex-direction: column;
    color: #2e2e2e;
  }
</style>
