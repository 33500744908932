<template>
  <ing-text-input
    v-model="identificationIdNumber"
    :label="$t(`forms.signupForm.identificationIdNumber.${idTypeLabel}`)"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    name="tax-number"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  export default {
    components: { IngTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      identificationIdNumber: {
        get() {
          return this.signers[this.signerId].identificationIdNumber;
        },
        set(value) {
          this.SET_IDENTIFICATION_ID_NUMBER({
            signerId: this.signerId,
            identificationIdNumber: value,
          });
        },
      },
      idTypeLabel() {
        return this.signers[this.signerId].identificationIdType || 'ID';
      },
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].identificationIdNumber &&
          this.$t(
            'forms.errors.' + this.errors[this.signerId].identificationIdNumber
          )
        );
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENTIFICATION_ID_NUMBER']),
    },
  };
</script>
