<template>
  <div>
    <ing-ident-type-header />
    <ing-ident-type-form />
  </div>
</template>

<script>
  import IngIdentTypeHeader from '@/components/ing/signers/ing-ident-type-information/ing-ident-type-header';
  import IngIdentTypeForm from '@/components/ing/signers/ing-ident-type-information/ing-ident-type-form';
  export default {
    components: {
      IngIdentTypeHeader,
      IngIdentTypeForm,
    },
  };
</script>
