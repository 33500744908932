var render = function render(){var _vm=this,_c=_vm._self._c;return _c('fieldset',{class:{ error: _vm.displayError }},[_c('div',{class:{
      'is-focused': _vm.isFocused,
      'is-empty': !_vm.value,
    }},[_c('label',{staticClass:"ing-label",domProps:{"innerHTML":_vm._s(_vm.label)}}),_c('input',_vm._b({ref:"date-input",staticClass:"form-control imask-input",class:{
        'show-text': _vm.shouldShowText,
        'ing-input-error': _vm.displayError,
      },attrs:{"name":_vm.name,"inputmode":"numeric"},on:{"focus":_vm.handleFocus,"blur":function($event){_vm.isFocused = false}}},'input',_vm.$attrs,false))]),_c('div',{staticClass:"ing-error",class:{ active: _vm.displayError, 'long-error-message': _vm.longErrorMessage },attrs:{"role":"alert"}},[_c('img',{attrs:{"src":"/alert_icon.png","alt":"Alert Icon"}}),_c('p',[_vm._v(" "+_vm._s(_vm.error)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }