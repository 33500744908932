export default {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  UNAUTHORIZED_CODE: 70001,
  ACTION_NOT_ALLOWED_CODE: 70002,
  GENERAL_ERROR_CODE: 70003,
  ENTITY_NOT_FOUND_CODE: 70004,
  CONFLICT_CODE: 70005,
  HIBERNATE_EXCEPTION_CODE: 70006,
  INTERNAL_EXCEPTION_CODE: 70007,
  EXTERNAL_EXCEPTION_CODE: 70008,
  STATEMACHINE_EXCEPTION_CODE: 70009,
  UNSUPPORTED_OPERATION_EXCEPTION_CODE: 70010,
  CLIENT_EMAIL_ALREADY_EXISTS: 70011,
};
