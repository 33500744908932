<template>
  <div>
    <label class="label-checkbox">
      <input v-model="businessAddressIsPostalAddress" type="checkbox" />
      <span class="check" />
      <p class="label-text ing-label">
        {{ $t('forms.signupForm.postalAddress.checkbox.company') }}
      </p>
    </label>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('signers', ['company']),
      businessAddressIsPostalAddress: {
        get() {
          return this.company.businessAddressIsPostalAddress;
        },
        set(newValue) {
          this.SET_IS_COMPANY_POSTAL_ADDRESS_SAME(newValue);
          this.handleAddressChange(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_IS_COMPANY_POSTAL_ADDRESS_SAME',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'CLEAR_COMPANY_POSTAL_ADDRESS',
      ]),
      handleAddressChange(newAddressvalue) {
        if (newAddressvalue) {
          this.CLEAR_COMPANY_POSTAL_ADDRESS();
        } else {
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../../sass/custom/variables';
  .label-text {
    line-height: 22px;
    padding-left: 30px;
  }
</style>
