<template>
  <div class="company-tax-number-wrapper">
    <len-text-input
      v-model="taxNumber"
      :label="$t('forms.signupForm.companyTaxNumber.label')"
      :error="
        errors.company &&
        errors.company.companyTaxNumber &&
        $t('forms.errors.' + errors.company.companyTaxNumber)
      "
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-tax-number"
      @focus="taxNumberHasFocus = true"
      @blur="taxNumberHasFocus = false"
    />
    <tooltip
      :class="{ 'on-input-focus': taxNumberHasFocus || !!taxNumber }"
      :tooltipMessage="$t('forms.signupForm.companyTaxNumber.tooltip')"
      class="company-tax-number-tooltip"
      messageClass="company-tax-number-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: { LenTextInput, Tooltip },
    data() {
      return {
        taxNumberHasFocus: false,
      };
    },
    computed: {
      taxNumber: {
        get() {
          return this.company.companyTaxNumber;
        },
        set(value) {
          this.SET_COMPANY_TAX_NUMBER(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_TAX_NUMBER']),
    },
  };
</script>

<style lang="scss" scoped>
  .company-tax-number-wrapper {
    position: relative;
    line-height: 1.75;
  }
  .company-tax-number-tooltip {
    position: absolute;
    left: 95px;
    top: 10px;
    transition: all 0.2s ease;
    &.on-input-focus {
      top: -12px;
      left: 72px;
    }
  }
</style>
