<template>
  <fieldset
    :class="{ error: displayError }"
    class="form-field addMaterialDesign fieldset-container"
  >
    <div
      :class="{ 'is-focused': isFocused, 'is-empty': !$attrs.value }"
      class="form-group label-floating"
    >
      <label class="control-label" v-html="label" />
      <Native
        v-show="isNative"
        :options="options"
        v-bind="$attrs"
        :disabled="disabled"
        :name="safariAutocompleteOffName"
        v-on="$listeners"
        @focus="
          isFocused = true;
          isDirty = true;
        "
        @blur="isFocused = false"
      />
      <Custom
        v-show="!isNative"
        :options="options"
        :maxVisibleOptions="maxVisibleOptions"
        v-bind="$attrs"
        :disabled="disabled"
        :name="safariAutocompleteOffName"
        v-on="$listeners"
        @focus="
          isFocused = true;
          isDirty = true;
        "
        @blur="isFocused = false"
      />
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import safariAutocompleteName from '@/utils/safariAutocompleteName';
  import Custom from '@/components/common/len-dropdown-input/select/custom';
  import Native from '@/components/common/len-dropdown-input/select/native';
  import MOBILE_USER_AGENT_REGEX from '@/constants/MOBILE_USER_AGENT_REGEX';
  import Window from '@/common/window';

  export default {
    name: 'Dropdown',
    components: {
      Custom,
      Native,
    },
    inheritAttrs: false,
    props: {
      label: {
        type: String,
        default: '',
      },
      options: {
        required: true,
        type: Array,
      },
      maxVisibleOptions: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      name: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: null,
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: false,
      },
      displayErrorIfSubmitted: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isDirty: false,
        isFocused: false,
        selectedItem: null,
      };
    },
    computed: {
      isNative: function () {
        return MOBILE_USER_AGENT_REGEX.test(Window.navigator.userAgent);
      },
      hasError() {
        return !!this.error;
      },
      displayError() {
        if (this.displayErrorIfDirty) {
          return this.hasError && this.isDirty;
        }
        if (!this.displayErrorIfSubmitted) {
          return false;
        }
        return this.hasError && !this.isFocused;
      },
      safariAutocompleteOffName() {
        return safariAutocompleteName(this.name);
      },
    },
  };
</script>

<style lang="scss" scoped>
  .error-container {
    height: 23px;
    margin: 0;
  }
  .validation {
    margin-bottom: 0;
  }
</style>
