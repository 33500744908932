<template>
  <div class="container-fluid">
    <header class="row">
      <len-header class="col-lg-12" />
    </header>
    <main class="row centered-container">
      <form
        class="signer-form-container col-lg-9 col-md-10 col-xs-11"
        @submit.prevent="submit"
      >
        <form-loader storeModule="signers" />
        <section>
          <offer-conditions-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <company-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <client-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <ident-type-information class="col-lg-12 col-md-12" />
        </section>
        <section v-if="isRegularLoanRequest" class="centered-container">
          <no-other-md-checkbox class="col-lg-6 col-md-6" />
        </section>
        <section class="centered-container">
          <submit-form class="col-lg-7 col-md-7" />
        </section>
        <section class="last-container">
          <privacy-policy class="centered-child col-lg-9 col-md-9" />
        </section>
      </form>
      <authentication-modal
        :id="opportunityId"
        :show="isUserUnauthorised"
        @success="clearServerError()"
      />
    </main>
    <len-footer class="row" />
  </div>
</template>

<script>
  import $ from 'jquery/dist/jquery.slim.min';
  import Window from '@/common/window';
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
  import OfferConditionsInformation from '@/components/signers/offer-conditions-information';
  import CompanyInformation from '@/components/signers/signers-company-information';
  import ClientInformation from '@/components/signers/signers-client-information';
  import identTypeInformation from '@/components/signers/ident-type-information';
  import noOtherMdCheckbox from '@/components/signers/no-other-md-checkbox';
  import submitForm from '@/components/signers/submit-form';
  import privacyPolicy from '@/components/signers/signers-privacy-policy';
  import formLoader from '@/components/common/forms/form-loader';
  import { enableGTM } from '@/mixins/gtmMixins';
  import { isRegularLoanRequest } from '@/mixins/signersMixin';
  import authenticationModal from '@/components/authentication/authentication-modal';
  import lenHeader from '@/components/common/len-header';
  export default {
    name: 'SignersMissingFormPage',
    components: {
      lenHeader,
      OfferConditionsInformation,
      CompanyInformation,
      ClientInformation,
      identTypeInformation,
      noOtherMdCheckbox,
      submitForm,
      privacyPolicy,
      formLoader,
      authenticationModal,
    },
    mixins: [enableGTM, isRegularLoanRequest],
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
    props: {
      opportunityId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['signers', 'company', 'success', 'errors']),
      ...mapGetters('signers', ['isValid']),
      isUserUnauthorised() {
        return (
          this.getDataReturnsUnauthorised ||
          this.companyUpdateReturnsUnauthorised ||
          this.clientUpdateReturnsUnauthorised
        );
      },
      getDataReturnsUnauthorised() {
        return !!this.errors.server && this.errors.server.status === 401;
      },
      companyUpdateReturnsUnauthorised() {
        return (
          !!this.errors.companyServer &&
          this.errors.companyServer.status === 401
        );
      },
      clientUpdateReturnsUnauthorised() {
        return (
          !!this.errors.clientServer && this.errors.clientServer.status === 401
        );
      },
      isMobile: function () {
        return $(Window).width() < 768;
      },
    },
    mounted() {
      this.setOpportunityId();
      this.fetchClientsInfo();
    },
    methods: {
      ...mapMutations('signers', [
        'SET_OPPORTUNITY_ID',
        'SET_SUBMIT_CLICKED',
        'SET_LOADING',
        'CLEAR_REQUEST_ERROR',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'SET_NO_OTHER_MD_CHECKBOX',
      ]),
      ...mapActions('signers', [
        'getSignersMissingDetails',
        'getCompanyDetails',
        'updateSignersMissingDetails',
      ]),
      ...mapActions('authentication', ['logOut']),
      setOpportunityId() {
        this.SET_OPPORTUNITY_ID(this.opportunityId);
      },
      clearServerError() {
        this.fetchClientsInfo();
      },
      async fetchClientsInfo() {
        this.SET_LOADING(true);
        this.CLEAR_REQUEST_ERROR();
        await this.getSignersMissingDetails();
        await this.getCompanyDetails();
        this.redirectIfNoMissingData();
        this.updateSignersPostalAddress();
        this.SET_NO_OTHER_MD_CHECKBOX(false);
        this.SET_LOADING(false);
      },

      redirectIfNoMissingData() {
        if (
          !this.isUserUnauthorised &&
          this.errors.server &&
          this.errors.server.data &&
          this.errors.server.data.code === 70004
        ) {
          this.$router.push({ name: 'signers-missing-info-success' });
        }
      },

      updateSignersPostalAddress() {
        Object.keys(this.signers).forEach((signerId) =>
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(signerId)
        );
      },

      async submit() {
        this.CLEAR_REQUEST_ERROR();
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        this.SET_LOADING(true);
        await this.updateSignersMissingDetails();
        this.SET_LOADING(false);
        if (this.success) {
          this.$router.push({ name: 'signers-missing-info-success' });
          this.logOut();
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  .centered-container {
    flex-grow: 1;
    margin-top: 53px;
    text-align: center;
    * {
      font-family: 'Source Sans Pro', Calibri, Helvetica, sans-serif;
      font-weight: normal;
    }
  }
  .centered-child {
    margin: 0 auto;
  }
  .last-container {
    margin-top: 20px;
    margin-bottom: 80px;
  }
  section {
    margin-top: 60px;
  }
</style>
