import { isEmpty } from '@/validators/helpers/string';
import { isValidGermanPhone } from '@/validators/helpers/phone';

export default function ValidatePhone(phone) {
  if (isEmpty(phone)) {
    return { phone: 'PhoneIsRequired' };
  }
  if (!isValidGermanPhone(phone)) {
    return { phone: 'PhoneIsNotCorrect' };
  }

  return { phone: undefined };
}
