<template>
  <ing-dropdown-input
    :name="'title-signer-' + signerId"
    :options="dropdownOptions"
    :value="title"
    :label="$t('forms.signupForm.title.label')"
    :placeholder="$t('ingDropdownDefault')"
    :error="errorMessage"
    :displayErrorIfSubmitted="displayError"
    @input="onSelect"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { titleValues } from '@/validators/helpers/title';
  import IngDropdownInput from '@/components/ing/ing-dropdown-input';

  export default {
    components: { IngDropdownInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        titleValues,
      };
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      displayError() {
        return (
          (this.submitClicked || this.signers[this.signerId].submitClicked) &&
          !!this.errors[this.signerId] &&
          !!this.errors[this.signerId].title
        );
      },
      errorMessage() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].title &&
          this.$t('forms.errors.' + this.errors[this.signerId].title)
        );
      },
      title() {
        return Object.keys(this.titleValues).find(
          (key) => this.titleValues[key] === this.signers[this.signerId].title
        );
      },
      dropdownOptions() {
        return Object.keys(this.titleValues).map((titleValue) => {
          return { value: titleValue, label: titleValue };
        });
      },
    },
    methods: {
      onSelect(value) {
        this.SET_TITLE({
          signerId: this.signerId,
          title: this.titleValues[value],
        });
        this.open = false;
        this.$emit('select');
      },
      ...mapMutations('signers', ['SET_TITLE']),
    },
  };
</script>
