<template>
  <div class="len-logo-wrapper">
    <img :src="logoRGBUrl" alt="Lendico ING Logo" class="len-logo" />
  </div>
</template>

<script>
  export default {
    name: 'LenHeaderBigLogo',
    computed: {
      logoRGBUrl() {
        return `${process.env.VUE_APP_LENDICO_BASE_URL_CONTENT}/de/images/logo_rgb.png`;
      },
    },
  };
</script>

<style scoped>
  .len-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
  }

  .len-logo {
    margin: 20px 0 20px 20px;
    height: 46px;
  }

  @media (max-width: 767px) {
    .len-logo {
      height: 20px;
    }
  }
</style>
