<template>
  <div class="ing-wrapper borrower-success-page">
    <TheHeader />
    <PageHeader page="signup.success" />
    <PageSection class="section-form" :darkTheme="true">
      <template #section-content>
        <FormSectionHeader section="signup.success.details" :paraCount="0" />
        <div class="data-section">
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('signup.success.nextSteps.dataSection[0].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[0].dataList.amount')
                }}
              </span>
              <span> {{ loanAmount | currencyShort }} EUR</span>
            </div>
            <div v-if="!isCreditLineLoan" class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[0].dataList.years')
                }}
              </span>
              <span>
                {{
                  $tc(
                    'signup.success.nextSteps.dataSection[0].dataList.duration',
                    length,
                    { count: length }
                  )
                }}
              </span>
            </div>
            <div v-else class="data-section__row">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[0].dataList.firstPayout'
                  )
                }}</span
              >
              <span> {{ firstPayout | currencyShort }} EUR</span>
            </div>

            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[0].dataList.purpose')
                }}
              </span>
              <span>{{ loanPurpose }}</span>
            </div>
          </div>
        </div>
        <div class="data-section">
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('signup.success.nextSteps.dataSection[1].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[1].dataList.companyName'
                  )
                }}
              </span>
              <span> {{ companyName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[1].dataList.address')
                }}
              </span>
              <span class="data-section__address">
                {{ address }}
              </span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[1].dataList.foundedDate'
                  )
                }}
              </span>
              <span> {{ dateFounded }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[1].dataList.revenue')
                }}
              </span>
              <span> {{ revenueData }} </span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[1].dataList.legalForm'
                  )
                }}
              </span>
              <span>{{ legalForm }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[1].dataList.billingMethod'
                  )
                }}
              </span>
              <span>{{ billingMethod }}</span>
            </div>
            <div v-if="isAmazonApplication">
              <div class="data-section__row data-section__row--divider">
                <span>
                  {{
                    $t('signup.success.nextSteps.dataSection[1].dataList.iban')
                  }}
                </span>
                <span>{{ iban | formatIBAN }}</span>
              </div>
              <div class="data-section__row">
                <span>
                  {{
                    $t('signup.success.nextSteps.dataSection[1].dataList.bic')
                  }}
                </span>
                <span>{{ bic }}</span>
              </div>
            </div>
          </div>
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('signup.success.nextSteps.dataSection[2].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[2].dataList.gender')
                }}
              </span>
              <span> {{ salutation }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[2].dataList.firstName'
                  )
                }}
              </span>
              <span> {{ firstName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[2].dataList.lastName'
                  )
                }}
              </span>
              <span> {{ lastName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[2].dataList.address')
                }}
              </span>
              <span class="data-section__address">
                {{ userAddress }}
              </span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[2].dataList.dateOfBirth'
                  )
                }}
              </span>
              <span> {{ dob }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'signup.success.nextSteps.dataSection[2].dataList.nationality'
                  )
                }}
              </span>
              <span> {{ nationality }}</span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[2].dataList.mobile')
                }}
              </span>
              <span> {{ mobilePhone | formatPhone }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('signup.success.nextSteps.dataSection[2].dataList.email')
                }}
              </span>
              <span> {{ email }} </span>
            </div>
          </div>
        </div>
      </template>
    </PageSection>
    <PageSection
      v-if="isPrechecksPassed"
      class="section-form"
      sectionPadding="bottom-no"
    >
      <template #section-content>
        <FormSectionHeader section="signup.success.nextSteps" :paraCount="0">
          <template #form-content>
            <div v-if="!isAmazonApplication">
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.nextSteps.step1.title')
                }}</h3>
                <i18n path="signup.success.nextSteps.step1.text" tag="p">
                  <a
                    place="linkText"
                    :href="
                      'mailto:' + $t('signup.success.nextSteps.step1.linkText')
                    "
                    >{{ $t('signup.success.nextSteps.step1.linkText') }}</a
                  >
                </i18n>
                <ul class="form-section__unordered-list">
                  <li class="form-section__unordered-list__item">
                    <i18n
                      path="signup.success.nextSteps.step1.list.item1.text"
                      tag="p"
                    >
                      <em place="boldContent">{{
                        $t(
                          'signup.success.nextSteps.step1.list.item1.boldContent'
                        )
                      }}</em>
                    </i18n>
                  </li>
                  <li class="form-section__unordered-list__item">
                    <i18n
                      path="signup.success.nextSteps.step1.list.item2.text"
                      tag="p"
                    >
                      <em place="boldContent">{{
                        $t(
                          'signup.success.nextSteps.step1.list.item2.boldContent'
                        )
                      }}</em>
                    </i18n>
                  </li>
                  <li class="form-section__unordered-list__item">
                    <i18n
                      path="signup.success.nextSteps.step1.list.item3.text"
                      tag="p"
                    >
                      <em place="boldContent">{{
                        $t(
                          'signup.success.nextSteps.step1.list.item3.boldContent'
                        )
                      }}</em>
                    </i18n>
                  </li>
                </ul>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.nextSteps.step2.title')
                }}</h3>
                <i18n path="signup.success.nextSteps.step2.text" tag="p">
                  <em
                    v-for="(content, index) in $t(
                      'signup.success.nextSteps.step2.boldContents'
                    )"
                    :key="'step3' + index"
                    :place="index"
                    >{{ content }}</em
                  >
                </i18n>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.nextSteps.step3.title')
                }}</h3>
                <i18n path="signup.success.nextSteps.step3.text" tag="p">
                  <em
                    v-for="(content, index) in $t(
                      'signup.success.nextSteps.step3.boldContents'
                    )"
                    :key="'step3' + index"
                    :place="index"
                    >{{ content }}</em
                  >
                </i18n>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.nextSteps.step4.title')
                }}</h3>
                <i18n path="signup.success.nextSteps.step4.text" tag="p">
                  <em place="boldContent">{{
                    $t('signup.success.nextSteps.step4.boldContent')
                  }}</em>
                </i18n>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.nextSteps.step5.title')
                }}</h3>
                <i18n path="signup.success.nextSteps.step5.text" tag="p">
                  <em place="boldContent">{{
                    $t('signup.success.nextSteps.step5.boldContent')
                  }}</em>
                </i18n>
              </div>
            </div>
            <div v-else-if="isAmazonApplication">
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.amazon.nextSteps.step1.title')
                }}</h3>
                <i18n
                  path="signup.success.amazon.nextSteps.step1.text"
                  tag="span"
                >
                  <span
                    v-for="(content, index) in $t(
                      'signup.success.amazon.nextSteps.step1.boldContents'
                    )"
                    :key="'step2' + index"
                    :place="index"
                  >
                    <em>{{ content }}</em>
                  </span>
                  <span v-if="isCreditLineLoan">{{
                    $t(
                      'signup.success.amazon.nextSteps.step1.flexkreditExtraText'
                    )
                  }}</span>
                </i18n>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.amazon.nextSteps.step2.title')
                }}</h3>
                <i18n path="signup.success.amazon.nextSteps.step2.text" tag="p">
                  <em
                    v-for="(content, index) in $t(
                      'signup.success.amazon.nextSteps.step2.boldContents'
                    )"
                    :key="'step2' + index"
                    :place="index"
                    >{{ content }}</em
                  >
                </i18n>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.amazon.nextSteps.step3.title')
                }}</h3>
                <i18n path="signup.success.amazon.nextSteps.step3.text" tag="p">
                  <em
                    v-for="(content, index) in amazonStep3BoldContents"
                    :key="'step3' + index"
                    :place="index"
                    >{{ content }}</em
                  >
                </i18n>
              </div>
              <div class="form-section__para">
                <h3 class="form-section__para__title">{{
                  $t('signup.success.amazon.nextSteps.step4.title')
                }}</h3>
                <i18n
                  v-if="isCreditLineLoan"
                  path="signup.success.amazon.nextSteps.step4.flexkredit.text"
                  tag="p"
                >
                  <em
                    v-for="(content, index) in $t(
                      'signup.success.amazon.nextSteps.step4.flexkredit.boldContents'
                    )"
                    :key="'step4' + index"
                    :place="index"
                    >{{ content }}</em
                  >
                </i18n>
                <i18n
                  v-else-if="isTermLoan"
                  path="signup.success.amazon.nextSteps.step4.firmenkredit.text"
                  tag="p"
                >
                  <em
                    v-for="(content, index) in $t(
                      'signup.success.amazon.nextSteps.step4.firmenkredit.boldContents'
                    )"
                    :key="'step4' + index"
                    :place="index"
                    >{{ content }}</em
                  >
                </i18n>
              </div>
              <IngButton @click="redirect">
                {{ $t('signup.success.amazon.nextSteps.returnButton') }}
              </IngButton>
            </div>
          </template>
        </FormSectionHeader>
      </template>
    </PageSection>
    <TheContact :container="isAmazonApplication ? 'amazon' : 'bridge'" />

    <TheFooter />
  </div>
</template>

<script>
  import { mapGetters, mapState } from 'vuex';
  import {
    successPage,
    formatDate,
    removeLocalStorageUserData,
  } from '@/mixins/successPageMixins';

  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import TheContact from '@/components/ingOrangeJuice/Structure/TheContact';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';

  export default {
    name: 'IngBorrowerSuccessPage',
    components: {
      TheHeader,
      PageHeader,
      PageSection,
      FormSectionHeader,
      TheContact,
      TheFooter,
      IngButton,
    },
    mixins: [successPage, formatDate, removeLocalStorageUserData],
    data() {
      return {
        mappingId: null,
        revenueData: null,
        revenue: null,
        partnerCompanyName: null,
        salutation: null,
        firstName: null,
        lastName: null,
        dob: null,
        email: null,
        mobilePhone: null,
        userAddress: null,
        loanAmount: null,
        length: null,
        companyName: '',
        dateFounded: null,
        address: null,
        nationality: null,
        loanPurpose: null,
        legalForm: null,
        billingMethod: null,
        iban: null,
        bic: null,
        firstPayout: null,
        userData: {
          type: Object,
          default: () => {},
        },
      };
    },
    metaInfo() {
      return {
        title: this.$t('borrowerSignupPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$t('borrowerSignupPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$t('borrowerSignupPage.metaDescription'),
          },
        ],
      };
    },
    computed: {
      ...mapGetters('signupForm', ['isPrechecksPassed']),
      ...mapGetters('appSettings', ['isAmazonApplication']),
      ...mapState('blacksea', ['returnUrl']),
      ...mapGetters('blacksea', ['isTermLoan', 'isCreditLineLoan']),
      amazonStep3BoldContents() {
        const arrays = this.$t(
          'signup.success.amazon.nextSteps.step3.boldContents'
        );
        arrays[0] = this.isTermLoan
          ? arrays[0]
          : this.$t(
              'signup.success.amazon.nextSteps.step3.flexkreditBoldContent'
            );
        return arrays;
      },
      getPreviousYears: function () {
        return {
          currentYearMinusOne: this.currentYearMinusOne,
          currentYearMinusTwo: this.currentYearMinusTwo,
        };
      },
    },
    beforeMount() {
      this.loadUserData();
    },
    methods: {
      redirect: function () {
        if (this.returnUrl) {
          window.location.href = this.returnUrl;
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';

  .borrower-success-page {
    .form-section {
      &__unordered-list {
        margin-top: px2rem(18px);
        padding-bottom: 0px;
        margin-left: px2rem(25px);
        font-size: px2rem(18px);
        line-height: px2rem(27px);
        &__item {
          &::marker {
            font-size: px2rem(14px);
          }
        }
      }

      &__para {
        font-size: px2rem(18px);
        margin: 19px 0 27px;
        padding-bottom: 0;
        &__title {
          font-size: px2rem(28px);
          color: $Primary-Orange;
          padding-bottom: px2rem(3px);
        }
      }
    }
    .data-section {
      display: flex;
      column-gap: 3%;
      row-gap: 48px;
      flex-wrap: wrap;
      padding-bottom: 48px;
      &:last-child {
        padding-bottom: 0;
      }
      &__list {
        flex-basis: 100%;
        @include mq-size(xl) {
          flex-basis: 48.5%;
        }
      }
      &__address {
        white-space: pre-line;
      }
      &__row {
        display: flex;
        column-gap: 3%;
        flex-wrap: wrap;
        @include mq-size(s) {
          column-gap: 3%;
        }
        @include mq-size(xl) {
          column-gap: 5%;
        }
        &--divider {
          padding-top: 24px;
        }
        &--header {
          padding-bottom: 8px;
          @include mq-size(s) {
            text-align: right;
          }
        }
        &--header#{&} span {
          font-size: 19px;
          line-height: 28px;
          font-weight: 700;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          flex-basis: 100%;
          word-break: break-all;
          @include mq-size(s) {
            flex-basis: 48.5%;
          }
          @include mq-size(xl) {
            flex-basis: 47.5%;
          }
          &:first-child {
            @include mq-size(s) {
              text-align: right;
            }
          }
          &:last-child {
            font-weight: 700;
            padding-bottom: 5px;
            min-height: 24px;
            @include mq-size(s) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
