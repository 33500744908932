<template>
  <fieldset :class="{ error: displayError }">
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !mobilePhoneBorrower,
      }"
    >
      <label class="ing-label">
        {{ label }}
      </label>
      <div
        :class="{
          'ing-input-error': displayError,
          'ing-input-focus': isFocused,
        }"
        class="form-control input-container"
      >
        <div class="prefix"> +49 </div>
        <input
          v-model="mobilePhoneBorrower"
          inputmode="numeric"
          :name="safariAutocompleteOffName"
          autocomplete="nofill"
          data-qa-id="mobile-number"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div :class="{ active: displayError }" role="alert" class="ing-error">
        <img src="/alert_icon.png" alt="Alert Icon" />
        <p>
          {{
            errors.mobilePhoneBorrower &&
            $t('forms.errors.' + errors.mobilePhoneBorrower)
          }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import safariAutocompleteName from '@/utils/safariAutocompleteName';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    mixins: [ingFormFieldGTMTrackingMixin],
    data() {
      return {
        isFocused: false,
        isDirty: false,
      };
    },
    computed: {
      label() {
        return this.$t('forms.signupForm.phone.label');
      },
      displayError() {
        const c = this.errors.mobilePhoneBorrower && !this.isFocused;
        if (!this.submitClicked) {
          return c && this.isDirty;
        }
        return c;
      },
      mobilePhoneBorrower: {
        get() {
          return this.$store.state.signupForm.mobilePhoneBorrower;
        },
        set(value) {
          this.SET_MOBILE_PHONE(value);
        },
      },
      safariAutocompleteOffName() {
        return safariAutocompleteName('mobilePhone');
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_MOBILE_PHONE']),
      onBlur() {
        setTimeout(() => {
          this.isFocused = false;
        }, 200); // On some browsers (Chrome macOS) it's impossible to click on suggestions without this
      },
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;

        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>

<style scoped>
  .input-container {
    display: flex;
    align-items: center;
  }
  .input-container input,
  .input-container div {
    font-size: 16px;
  }
  .input-container input {
    border: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    width: 100%;
    outline: none;
    padding-left: 5px;
  }
  .input-container input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
    opacity: 1; /* Firefox */
  }
  .input-container input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b5b5b5;
  }
  .input-container input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b5b5b5;
  }
</style>
