<template>
  <len-text-input
    v-model="partnerEmail"
    :label="$t('partnerSubmissionPageNew.form.partnerEmail.label')"
    :error="
      validationErrors.partnerEmail &&
      $t('forms.errors.' + validationErrors.partnerEmail)
    "
    :displayErrorIfDirty="!submitClicked"
    name="PartnerEmail"
    inputmode="email"
    @focus="justLeftField = false"
    @keydown="onKeyDown"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    data() {
      return {
        justLeftField: false,
      };
    },
    computed: {
      partnerEmail: {
        get() {
          return this.$store.state.partner.partnerEmail;
        },
        set(value) {
          this.SET_PARTNER_EMAIL(value);
        },
      },
      ...mapState('partner', ['validationErrors', 'submitClicked']),
    },
    methods: {
      onKeyDown(e) {
        if (e.key === 'Enter' || e.key === 'Tab') {
          e.preventDefault();
          this.justLeftField = true;
          return false;
        } else {
          this.justLeftField = false;
        }
      },
      ...mapMutations('partner', ['SET_PARTNER_EMAIL']),
    },
  };
</script>
