<template>
  <div class="terms-and-conditions-container">
    <div class="terms-and-conditions-container__para1">
      <i18n
        path="signup.application.form.termsSection.para1.description"
        :tag="false"
      >
        <a
          :href="$translationConstantValues.dataProtectionUrl"
          place="linkText"
          target="_blank"
          >{{ $t('signup.application.form.termsSection.para1.linkText') }}</a
        >
        <em place="boldContent">{{
          $t('signup.application.form.termsSection.para1.boldContent')
        }}</em>
      </i18n>
    </div>
    <div class="terms-and-conditions-container__para2">
      <i18n
        path="signup.application.form.termsSection.para2.description"
        tag="p"
      >
        <a
          href="#"
          place="linkText1"
          @click.prevent="openModal('CreditreformModal')"
          >{{ $t('signup.application.form.termsSection.para2.linkText1') }}</a
        >
        <a
          href="#"
          place="linkText2"
          @click.prevent="openModal('SchufaModal')"
          >{{ $t('signup.application.form.termsSection.para2.linkText2') }}</a
        >
      </i18n>
    </div>
    <CreditReformModal v-if="isCreditreformModalOpen" @onClose="closeModal" />
    <SchufaModal v-if="isSchufaModalOpen" @onClose="closeModal" />
  </div>
</template>

<script>
  import CreditReformModal from '@/components/CreditReformModal';
  import SchufaModal from '@/components/SchufaModal';
  import { mapState } from 'vuex';

  export default {
    name: 'IngBorrowerPrivacyPolicy',
    components: { SchufaModal, CreditReformModal },
    data() {
      return {
        isSchufaModalOpen: false,
        isCreditreformModalOpen: false,
      };
    },
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      openModal(target) {
        this[`is${target}Open`] = true;
      },
      closeModal(target) {
        this[`is${target}Open`] = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';

  .terms-and-conditions-container {
    padding-top: px2rem(29px);
    word-spacing: px2rem(1.8px);
    &__para1 {
      margin-bottom: px2rem(44px);
    }
  }
</style>
