import axios from 'axios';

const axiosWithCredentials = axios.create({ withCredentials: true });

export async function getOfferDetails({ commit }) {
  await axiosWithCredentials
    .get(`${process.env.VUE_APP_FUNNEL_GATEWAY_API}/loan-request/offer`)
    .then(({ data }) => {
      if (data && data.result) {
        commit('SET_OFFER_DETAILS', data.result);
      }
      return data;
    })
    .catch((error) => {
      const { status, data } = error.response || '';
      commit('SET_ERROR', { status, data });
    });
}

export async function acceptOffer({ commit }) {
  await axiosWithCredentials
    .patch(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/loan-request/offer/resolve`,
      {
        decision: 'ACCEPTED',
      }
    )
    .then(({ data }) => {
      if (data.result && data.result.stateChanged) {
        commit('SET_SUCCESS', data.result.stateChanged);
      } else if (data.error) {
        commit('SET_ERROR', { status: 500, data: data.error });
      }
    })
    .catch((error) => {
      const { status, data } = error.response || '';
      commit('SET_ERROR', { status, data });
    });
}
