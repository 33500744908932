<template>
  <div :class="{ hidden: !show }" class="addMaterialDesign modal col-xs-12">
    <div class="modal-dialog modal-content authentication-modal">
      <div
        :class="{ active: submitClicked && errorMessage }"
        class="error-message col-lg-12 col-md-12 col-sm-10 col-xs-12"
      >
        <p>
          {{ errorMessage.message }}
          <a v-if="showEmail" :href="'mailto:' + $t('contactEmail')">{{
            $t('contactEmail')
          }}</a>
        </p>
      </div>
      <form
        class="authentication-content form"
        @submit.prevent="handleSubmit()"
      >
        <h3>{{ $t('authentication.form.title') }}</h3>
        <p class="col-lg-8 col-md-8 col-sm-8 col-xs-10">
          {{ $t('authentication.form.explanation') }}
        </p>
        <span class="password-input col-lg-6 col-md-7 col-sm-7 col-xs-11">
          <len-text-input
            v-model="password"
            :isPassword="true"
            :label="$t('authentication.form.password.label')"
            :error="errorMessage.label"
            :displayErrorIfDirty="!submitClicked"
            inputmode="password"
            name="password"
            @input="clearErrorMessage()"
          />
        </span>

        <button class="button" type="button" @click="handleSubmit()">
          {{ $t('authentication.form.submit') }}
        </button>
      </form>
    </div>
    <div class="modal-backdrop fade in" />
    <form-loader storeModule="authentication" />
  </div>
</template>

<script>
  import { mapActions, mapMutations, mapState } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import FormLoader from '@/components/common/forms/form-loader';
  import {
    isValidPassword,
    errorMessage,
    serverErrorMessage,
    showEmail,
  } from '@/mixins/authentication/authenticationModalMixin';
  export default {
    components: {
      LenTextInput,
      FormLoader,
    },
    mixins: [isValidPassword, errorMessage, serverErrorMessage, showEmail],
    props: {
      show: {
        type: Boolean,
      },
      id: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        submitClicked: false,
      };
    },
    computed: {
      ...mapState('authentication', ['error', 'success']),
      password: {
        get() {
          return this.$store.state.authentication.password;
        },
        set(newValue) {
          this.SET_PASSWORD(newValue);
        },
      },
    },
    mounted() {
      this.SET_ID(this.id);
    },
    methods: {
      ...mapActions('authentication', ['authenticate']),
      ...mapMutations('authentication', [
        'SET_PASSWORD',
        'SET_ID',
        'SET_LOADING',
        'CLEAR_ERROR',
      ]),
      async handleSubmit() {
        this.submitClicked = true;
        if (!this.isValidPassword) {
          return;
        }
        this.CLEAR_ERROR();
        this.SET_LOADING(true);
        await this.authenticate();
        this.SET_LOADING(false);
        if (this.success) {
          this.$emit('success');
        }
      },
      clearErrorMessage() {
        this.CLEAR_ERROR();
        this.submitClicked = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .modal {
    &.hidden {
      visibility: hidden;
    }
    display: block;
    .authentication-modal {
      height: fit-content;
      min-height: 492px;
      z-index: 1041;
    }
    .authentication-content {
      padding: 10px;
      text-align: center;
      h3 {
        font-size: 24px;
        color: black;
        font-weight: bold;
        padding-bottom: 10px;
      }
      p {
        font-size: 16px;
        color: #808080;
        margin: 0 auto;
      }
      fieldset {
        display: block;
      }
    }
    .password-input {
      display: block;
      padding: 55px 0 20px 0;
      text-align: left;
      margin: 0 auto;
    }
    .error-message {
      visibility: hidden;
      min-height: 102px;
      height: fit-content;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px;
      background: #ffd5cc;
      margin: 0;
      border-radius: 3px 3px 0 0;
      p {
        color: #f55937;
        font-weight: bold;
        font-size: 16px;
      }
    }
    .error-message.active {
      visibility: visible;
    }
  }
</style>
