<template>
  <ing-text-input
    v-model="iban"
    :label="label"
    :error="errors.iban && $t('forms.errors.' + errors.iban)"
    :displayErrorIfDirty="!submitClicked"
    name="iban"
    data-qa-id="iban"
    :maskOptions="maskOptions"
    @input="iban = forceDEformat($event)"
    @focus="handleFocus"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import {
    IBAN_FORMAT,
    IBAN_LENGTH_WITH_SPACES,
    DEFAULT_IBAN_VALUE,
  } from '@/constants/IBAN_FORMAT';
  import {
    getFullIbanWithSpaces,
    removeSpacesFromIban,
    forceDEformat,
  } from '@/utils/formatIban';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTextInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    data: () => {
      return {
        ibanValue: DEFAULT_IBAN_VALUE,
        maskOptions: {
          mask: IBAN_FORMAT,
          maxLength: IBAN_LENGTH_WITH_SPACES,
        },
      };
    },
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.iban.label');
      },
      iban: {
        get() {
          return this.ibanValue;
        },
        set(value) {
          this.ibanValue = getFullIbanWithSpaces(value);
          this.SET_IBAN(removeSpacesFromIban(value));
        },
      },
    },
    mounted() {
      this.SET_IBAN(removeSpacesFromIban(this.ibanValue));
    },
    methods: {
      ...mapMutations('signupForm', ['SET_IBAN']),
      forceDEformat,
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
