<template>
  <len-text-input
    v-model="userStreet"
    :label="label"
    :error="errors.userStreet && $t('forms.errors.' + errors.userStreet)"
    :displayErrorIfDirty="!submitClicked"
    name="UserStreet"
    data-qa-id="representative-info-street"
    @focus="onFocus"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenTextInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.userStreet.label');
      },
      userStreet: {
        get() {
          return this.$store.state.signupForm.userStreet;
        },
        set(value) {
          this.SET_USER_STREET(value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_USER_STREET']),
      onFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
