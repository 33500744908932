import { isEmpty } from '../helpers/string';
import { IsValidCashIntensity } from '../helpers/cashIntensity';

export default function ValidateCashIntensity(cashIntensity) {
  if (isEmpty(cashIntensity)) {
    return { cashIntensity: 'CashIntensityIsRequired' };
  }

  if (!IsValidCashIntensity(cashIntensity)) {
    return { cashIntensity: 'CashIntensityIsIncorrect' };
  }

  return { cashIntensity: undefined };
}
