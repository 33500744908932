import { isEmpty } from '@/validators/helpers/string';

export default function ValidateBic(bic) {
  const bicRegex = /^[A-Za-z]{4}[A-Za-z]{2}[A-Za-z0-9]{2}([A-Za-z0-9]{3})?$/;

  if (isEmpty(bic)) {
    return { bic: 'BicIsRequired' };
  }
  if (!bicRegex.test(bic)) {
    return { bic: 'BicIsNotCorrect' };
  }
  return { bic: undefined };
}
