<template>
  <fieldset
    :class="{ error: displayError }"
    class="form-field addMaterialDesign fieldset-container"
  >
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !value,
        'is-readonly': readonly,
      }"
      class="form-group label-floating"
    >
      <label class="control-label" v-html="label" />
      <input
        ref="input"
        :name="safariAutocompleteOffName"
        :value="value"
        :readonly="readonly"
        :inputmode="inputmode"
        :type="inputType"
        class="form-control"
        autocomplete="nofill"
        @input="onInput"
        @focus="onFocus"
        @keydown="$emit('keydown', $event)"
        @blur="onBlur"
      />
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
        :class="{ 'long-error': longErrorMessage && error }"
      >
        <p v-if="displayError" class="validation">
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { textInputFunctionality } from '@/mixins/forms/textInputMixin';
  export default {
    mixins: [textInputFunctionality],
  };
</script>

<style scoped>
  .error-container {
    height: 25px;
  }
  .validation {
    margin-bottom: 0px;
  }
  .is-readonly input {
    cursor: not-allowed;
    opacity: 0.5;
  }
  .long-error {
    height: 50px;
  }
</style>
