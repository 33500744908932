<template>
  <div class="col-sm-offset-2 submit-container">
    <div
      v-if="errorMessage !== ''"
      role="alert"
      class="general-error-container"
    >
      <p class="validation" v-html="errorMessage" />
    </div>
    <button
      class="u-mv button full-width"
      type="submit"
      v-html="$t('signersMissingInfo.formPage.submit')"
    />
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex';

  export default {
    props: {
      applicant: {
        type: String,
        default: '',
      },
    },
    computed: {
      errorMessage() {
        const serverError = this.errors.server;
        if (serverError) {
          if (serverError >= 400 && serverError < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          if (serverError === 70007) {
            return this.$t('forms.signupForm.errors.70007');
          }
          if (serverError === 70011) {
            return this.$t('forms.signupForm.errors.70011');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        if (
          this.submitClicked &&
          (this.isCompanyEditable || this.isClientEditable)
        ) {
          return this.$t('forms.signupForm.errors.IsEditableOpen');
        }
        if (this.submitClicked && !this.isValid) {
          const extraMsg =
            !this.areSignersPhonesUnique || !this.areSignersEmailsUnique
              ? this.$t('forms.errors.repetitions')
              : '';
          return this.$t('forms.signupForm.errors.fields') + ' ' + extraMsg;
        }
        return '';
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'isCompanyEditable',
        'isClientEditable',
      ]),
      ...mapGetters('signers', [
        'isValid',
        'areSignersEmailsUnique',
        'areSignersPhonesUnique',
      ]),
    },
  };
</script>
<style scoped>
  .submit-container {
    padding-left: 5%;
    padding-right: 5%;
  }
  .no-padding {
    padding-right: 0;
    padding-left: 0;
  }
  .general-error-container {
    height: fit-content;
  }
  .general-error-container p {
    text-align: center;
    font-size: 0.8rem;
  }
</style>
