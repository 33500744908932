<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 14.1465C4.691 14.1465 2 11.4555 2 8.14648C2 4.83748 4.691 2.14648 8 2.14648C11.309 2.14648 14 4.83748 14 8.14648C14 11.4555 11.309 14.1465 8 14.1465ZM19.707 18.4395L14.312 13.0435C15.365 11.6885 16 9.99248 16 8.14648C16 3.73548 12.411 0.146484 8 0.146484C3.589 0.146484 0 3.73548 0 8.14648C0 12.5575 3.589 16.1465 8 16.1465C9.846 16.1465 11.543 15.5115 12.897 14.4585L18.293 19.8535L19.707 18.4395Z"
      fill="#FF6200"
    />
  </svg>
</template>
<script>
  export default {
    name: 'IconSearch',
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  svg {
    width: px2rem(20);
    height: px2rem(20);
    vertical-align: text-bottom;
  }
</style>
