<template>
  <a :href="linkHref" class="len--link">{{ text }}</a>
</template>
<script>
  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['lenTarget', 'route', 'text'],
    computed: {
      linkHref() {
        const {
          VUE_APP_LENDICO_BASE_URL_CMS_API,
          VUE_APP_LENDICO_BASE_URL_CONTENT,
          VUE_APP_LENDICO_BASE_URL_LENDICO_BLOG,
        } = process.env;
        switch (this.lenTarget) {
          case 'static-webpage':
            return VUE_APP_LENDICO_BASE_URL_CMS_API + this.route;
          case 'content-service':
            return `${VUE_APP_LENDICO_BASE_URL_CONTENT}/de${this.route}`;
          case 'lendico-blog':
            return VUE_APP_LENDICO_BASE_URL_LENDICO_BLOG + this.route;
          default:
            return '';
        }
      },
    },
  };
</script>
<style scoped>
  .len--link {
    direction: ltr;
    font-family: inherit;
    color: #3cbdb9;
    font-size: inherit;
    font-weight: inherit;
    text-decoration: none;
    cursor: pointer;
  }

  .len--link:hover {
    color: #0c3f55;
    text-decoration: underline;
  }
</style>
