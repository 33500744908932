<template>
  <div :class="{ alert: isInvalid }" class="form-container">
    <div class="company-section-container">
      <h3 class="padding-left-alignment">
        {{
          $t('signersMissingInfo.formPage.companySection.loanOwnerCompany.h3')
        }}
      </h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <ing-company-name />
          <ing-company-trading-name />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <ing-company-tax-number />
          <ing-country-of-incorporation v-show="isRegularLoanRequest" />
        </div>
      </div>
    </div>
    <div class="company-section-container">
      <h3 class="padding-left-alignment">
        {{ $t('forms.signupForm.businessAddress.label') }}
      </h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <ing-business-street />
          <ing-business-zip />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <ing-business-street-number />
          <ing-business-city />
        </div>
      </div>
      <ing-postal-address-checkbox class="padding-left-alignment" />
    </div>
    <div
      v-if="company.businessAddressIsPostalAddress"
      class="company-section-container narrow-bottom-padding"
    >
      <h3 class="padding-left-alignment">
        {{ $t('forms.signupForm.postalAddress.label') }}
      </h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <ing-postal-street />
          <ing-postal-zip />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <ing-postal-street-number />
          <ing-postal-city />
        </div>
      </div>
    </div>
    <div v-if="isTestAndLearnEnabled" class="company-section-container">
      <h3 class="padding-left-alignment">
        {{ $t('forms.signupForm.ingAccountDetails.header') }}
      </h3>
      <p class="company-section__iban-label padding-left-alignment">
        {{ $t('forms.signupForm.iban.tooltip') }}
      </p>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <ing-company-iban />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <ing-company-bic />
        </div>
      </div>
    </div>
    <div v-if="hasControllingCompany" class="company-section-container">
      <h3
        v-if="isFeatureFlagMLLFEnabled && isComplexLegalForm"
        data-unit-test="ing-mllf-controlling-company-title"
        class="ing-controlling-title-alignment padding-left-alignment"
      >
        {{
          $t(
            'signersMissingInfo.formPage.companySection.MLLFControllingCompany.h3'
          )
        }}
        <ing-bs-tooltip
          key="ing-mllf-controlling-company-title-tooltip"
          :tooltipMessage="
            $t(
              'signersMissingInfo.formPage.companySection.MLLFControllingCompany.tooltip'
            )
          "
        />
      </h3>
      <h3
        v-else
        data-unit-test="ing-controlling-company-title"
        class="ing-controlling-title-alignment padding-left-alignment"
      >
        {{
          $t('signersMissingInfo.formPage.companySection.controllingCompany.h3')
        }}
        <ing-bs-tooltip
          key="ing-controlling-company-title-tooltip"
          :tooltipMessage="
            $t(
              'signersMissingInfo.formPage.companySection.controllingCompany.tooltip'
            )
          "
        />
      </h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <ing-controlling-legal-name />
        </div>
      </div>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <ing-controlling-tax-number />
        </div>
      </div>
      <div class="col-sm-6 short-column-alignment">
        <div class="col-sm-11">
          <ing-controlling-country-of-incorporation />
        </div>
      </div>
    </div>
    <div
      class="company-section-container extra-top-padding padding-extra-left-alignment"
    >
      <ing-cash-intensity-title class="ing-label" />
      <ing-cash-intensity-field />
      <div v-if="isTestAndLearnEnabled">
        <ing-online-revenue-title class="ing-label" />
        <ing-online-revenue-field />
      </div>
    </div>
    <div
      v-if="isTestAndLearnEnabled"
      class="company-section-container padding-extra-left-alignment"
    >
      <ing-sme-header />
      <FieldStatus
        data-unit-test="ing-mllf-sme-header-info"
        class="mllf-info"
        :info="$t('forms.signupForm.smeHeader.info')"
      />
      <br />
      <div class="row">
        <div class="col-sm-6">
          <ing-employees-number-field />
          <ing-company-revenues-field />
        </div>
      </div>
      <div class="row assets-container">
        <div class="col-sm-6">
          <ing-company-assets-field />
        </div>
        <div v-if="!isRegularLoanRequest" class="col-sm-6">
          <div class="col-sm-11">
            <ing-company-assets-checkbox />
          </div>
        </div>
      </div>
    </div>

    <div class="submit-section-container">
      <div
        v-if="errorMessage !== ''"
        role="alert"
        class="general-error-container"
      >
        <p class="validation" v-html="errorMessage" />
      </div>
      <ing-section-submit-form
        @cancel-button-clicked="$emit('cancel-button-clicked')"
        @submit-button-clicked="$emit('submit-button-clicked')"
      />
    </div>
  </div>
</template>

<script>
  import IngCompanyName from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-signers-company-name-field';
  import IngCompanyTradingName from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-trading-name-field';
  import IngCompanyIban from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-iban';
  import IngCompanyBic from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-bic';
  import IngCountryOfIncorporation from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-country-of-incorporation';
  import IngCompanyTaxNumber from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-tax-number-field';
  import IngBusinessStreet from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-business-street-field';
  import IngBusinessStreetNumber from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-business-street-number-field';
  import IngBusinessZip from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-business-zip-field';
  import IngBusinessCity from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-business-city-field';
  import IngPostalStreet from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-postal-street-field';
  import IngPostalStreetNumber from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-postal-street-number-field';
  import IngPostalZip from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-postal-zip-field';
  import IngPostalCity from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-postal-city-field';
  import IngPostalAddressCheckbox from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-postal-address-checkbox-field';
  import IngCashIntensityTitle from '@/components/ing/signers/ing-signers-company-information/ing-cash-intensity-title';
  import IngCashIntensityField from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-cash-intensity-field';
  import IngOnlineRevenueTitle from '@/components/ing/signers/ing-signers-company-information/ing-online-revenue-title';
  import IngOnlineRevenueField from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-online-revenue-field';
  import IngControllingCountryOfIncorporation from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-controlling-country-of-incorporation';
  import IngControllingTaxNumber from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-controlling-tax-number-field.vue';
  import IngControllingLegalName from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-controlling-legal-name';
  import IngSmeHeader from '@/components/ing/signers/ing-signers-company-information/ing-sme-header';
  import IngEmployeesNumberField from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-employees-number-field';
  import IngCompanyRevenuesField from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-revenues-field';
  import IngCompanyAssetsField from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-assets-field';
  import IngCompanyAssetsCheckbox from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-form/ing-company-assets-checkbox';
  import IngSectionSubmitForm from '@/components/ing/signers/ing-section-submit-form';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import {
    isRegularLoanRequest,
    hasControllingCompany,
    isTestAndLearnEnabled,
    isFeatureFlagMLLFEnabled,
  } from '@/mixins/signersMixin';
  import { mapState, mapGetters } from 'vuex';
  export default {
    components: {
      IngCompanyName,
      IngCompanyTradingName,
      IngCompanyIban,
      IngCompanyBic,
      IngCountryOfIncorporation,
      IngCompanyTaxNumber,
      IngBusinessStreet,
      IngBusinessStreetNumber,
      IngBusinessZip,
      IngBusinessCity,
      IngPostalAddressCheckbox,
      IngPostalStreet,
      IngPostalStreetNumber,
      IngPostalZip,
      IngPostalCity,
      IngCashIntensityTitle,
      IngCashIntensityField,
      IngOnlineRevenueTitle,
      IngOnlineRevenueField,
      IngControllingCountryOfIncorporation,
      IngControllingTaxNumber,
      IngControllingLegalName,
      IngSmeHeader,
      IngEmployeesNumberField,
      IngCompanyRevenuesField,
      IngCompanyAssetsField,
      IngCompanyAssetsCheckbox,
      IngSectionSubmitForm,
      IngBsTooltip,
      FieldStatus,
    },
    mixins: [
      isRegularLoanRequest,
      hasControllingCompany,
      isTestAndLearnEnabled,
      isFeatureFlagMLLFEnabled,
    ],
    props: {
      isInvalid: {
        type: Boolean,
      },
    },
    computed: {
      ...mapState('signers', ['company', 'errors']),
      ...mapGetters('signers', ['isComplexLegalForm']),
      errorMessage() {
        let serverError = this.errors.companyServer;
        if (serverError) {
          if (serverError.status >= 400 && serverError.status < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError.status >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_settings.scss';
  @import '@/sass/custom/_variables.scss';
  h3 {
    margin-bottom: 40px;
  }
  .company-section-container {
    width: 100%;
    padding: 40px 20px;
    &:nth-child(even) {
      background-color: $ing-signer-section-background;
    }
    &.extra-top-padding {
      padding-top: 40px;
    }
    &.narrow-bottom-padding {
      padding-bottom: 20px;
    }
  }
  .company-section__iban-label {
    font-size: 14px;
    padding-bottom: 24px;
  }
  .form-container {
    &.alert {
      border: solid 1px $red;
    }
  }
  .col-sm-11 {
    margin-left: 4%;
  }
  .ing-controlling-title-alignment {
    display: flex;
    align-items: baseline;
  }
  .padding-left-alignment {
    padding-left: 2%;
  }
  .padding-extra-left-alignment {
    padding-left: 4%;
  }
  .submit-section-container {
    width: 100%;
    padding: 20px 40px 40px 40px;
  }
  .short-column-alignment {
    vertical-align: top;
  }
  .general-error-container {
    height: 25px;
  }
  .general-error-container p {
    text-align: center;
    font-size: 0.8rem;
  }
  .assets-container {
    display: flex;
    align-items: center;
  }
  .mllf-info {
    font-size: 14px;
    margin-bottom: 24px;
  }
</style>
