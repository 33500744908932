import safariAutocompleteName from '@/utils/safariAutocompleteName';
import removeEmojis from '@/utils/emojis';

export const textInputFunctionality = {
  props: {
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    value: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    displayErrorMessage: {
      type: Boolean,
      default: true,
    },
    displayErrorIfDirty: {
      type: Boolean,
      default: true,
    },
    inputmode: {
      type: String,
      required: false,
    },
    isPassword: {
      type: Boolean,
      default: false,
    },
    longErrorMessage: {
      type: Boolean,
      default: false,
    },
    maxlength: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
      isFocused: false,
      isDirty: false,
    };
  },
  computed: {
    hasError() {
      return !!this.error && this.error.length > 0;
    },
    displayError() {
      const result = this.hasError && !this.isFocused;
      if (this.displayErrorIfDirty) {
        return this.isDirty && result;
      }
      return result;
    },
    inputType() {
      if (this.isPassword) {
        return 'password';
      } else {
        return 'text';
      }
    },
    safariAutocompleteOffName() {
      return safariAutocompleteName(this.name);
    },
  },
  methods: {
    onInput(event) {
      const value = removeEmojis(event.target.value);
      event.target.value = value;
      this.$emit('input', value);
    },
    onFocus() {
      this.isFocused = true;
      this.isDirty = true;
      this.$emit('focus');
    },
    onBlur() {
      setTimeout(() => {
        this.isFocused = false;
        this.$emit('blur');
      }, 200); // On some browsers (Chrome macOS) it's impossible to click on suggestions without this
    },
  },
};
