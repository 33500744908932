<template>
  <len-dropdown-input
    :value="title"
    :name="'title-signer-' + signerId"
    :options="titleOptions"
    :label="$t('forms.signupForm.title.label')"
    :error="errorMessage"
    :displayErrorIfSubmitted="displayError"
    @input="onSelect"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenDropdownInput from '@/components/common/len-dropdown-input';
  import { titleValues } from '@/validators/helpers/title';
  export default {
    components: { LenDropdownInput },
    props: {
      signerId: {
        type: String,
        default: () => '',
      },
    },
    data() {
      return {
        titleValues,
      };
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      errorMessage() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].title &&
          this.$t('forms.errors.' + this.errors[this.signerId].title)
        );
      },
      displayError() {
        return (
          (this.submitClicked || this.signers[this.signerId].submitClicked) &&
          !!this.errors[this.signerId] &&
          !!this.errors[this.signerId].title
        );
      },
      title() {
        return Object.keys(this.titleValues).find(
          (key) => this.titleValues[key] === this.signers[this.signerId].title
        );
      },
      titleOptions() {
        return Object.keys(this.titleValues).map((value) => {
          return { value: value, label: value };
        });
      },
    },
    methods: {
      onSelect(value) {
        this.SET_TITLE({
          signerId: this.signerId,
          title: this.titleValues[value],
        });
      },
      ...mapMutations('signers', ['SET_TITLE']),
    },
  };
</script>
