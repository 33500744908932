<template>
  <div>
    <div class="div-block-2">
      <img
        :src="bgImgStructures"
        :srcset="`${bgImgStructures} 500w,${bgImgStructures} 1600w`"
        sizes="100vw"
        class="image-2"
        alt="page not found"
      />
      <h1 class="heading-2">
        {{ $t('PageNotFound.h1') }}
      </h1>
    </div>
    <div class="div-block-3">
      <div class="text-block">
        {{ $t('PageNotFound.p1') }}
      </div>
      <div class="text-block-2">
        <i18n path="PageNotFound.p2" tag="p">
          <a :href="homeUrl" place="homePage" class="_404-link">{{
            $t('PageNotFound.homePage')
          }}</a>
          <a :href="mailLink" place="email" class="_404-link">{{
            $t('PageNotFound.email')
          }}</a>
        </i18n>
      </div>
    </div>
  </div>
</template>

<script>
  import i18n from '@/i18n';
  import { enableGTM } from '@/mixins/gtmMixins';
  export default {
    name: 'Page404',
    mixins: [enableGTM],
    metaInfo() {
      return {
        title: this.$t('PageNotFound.title'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$t('PageNotFound.title'),
          },
        ],
      };
    },
    i18n,
    data() {
      return {
        homeUrl: this.$t('PageNotFound.homeUrl'),
        mailLink: this.$t('PageNotFound.mailLink'),
      };
    },
    computed: {
      bgImgStructures() {
        return `${process.env.VUE_APP_LENDICO_BASE_URL_CONTENT}/de/images/structures/BGimg_structures-A2.jpg`;
      },
    },
  };
</script>

<style scoped></style>
