<template>
  <div class="ing-submission-overview">
    <h2 v-if="isTestAndLearnEnabled">
      {{ $t('borrowerSignupFormthankYouPage.content.testAndLearn.header') }}
    </h2>
    <h2 v-else>
      {{ $t('borrowerSignupFormthankYouPage.content.data.header') }}
    </h2>
    <div class="ing-data-overview-container">
      <div class="column--left">
        <column-section :section="sections[0]" />
      </div>
      <div class="column--right">
        <column-section :section="sections[1]" />
        <column-section :section="sections[2]" />
      </div>
    </div>
  </div>
</template>
<script>
  import {
    formatDate,
    removeLocalStorageUserData,
  } from '@/mixins/successPageMixins';
  import { successMixin } from '@/mixins/successMixin';
  import { currencyAmount } from '@/utils/currency';
  import { formatIBAN } from '@/filters/formatIban';

  const ColumnSection = {
    props: {
      section: {
        type: Object,
        required: true,
      },
    },
    render(h) {
      // TODO: the following inline css is not a good approach for setting the styles
      // this page need to be follow the code in the bridge thank you page in the Amazon harmonization
      const h3Style = 'font-size: 20px; margin: 10px 0;';
      const paraStyle = 'color: #808080; font-size: 18px; line-height: 27px;';

      return h('div', { class: 'column__section' }, [
        h('h3', {
          style: h3Style,
          domProps: { innerHTML: this.section.title },
        }),
        this.section.items.map(({ text, value }, i) =>
          h('div', { class: 'row', key: `row_${i}` }, [
            h('div', { class: 'col-xs-5 vertical-align--top' }, [
              h('p', { style: paraStyle, domProps: { innerHTML: text } }),
            ]),
            h('div', { class: 'col-xs-7' }, [
              h('p', { style: paraStyle, domProps: { innerHTML: value } }),
            ]),
          ])
        ),
      ]);
    },
  };

  export default {
    components: { ColumnSection },
    mixins: [formatDate, removeLocalStorageUserData, successMixin],
    computed: {
      isTestAndLearnEnabled() {
        return process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true';
      },
      loanLength() {
        const years = this.smeSignUpToThankYouPage.length || 0;
        return years > 1 ? `${years} Jahre` : `${years} Jahr`;
      },
      formattedDob() {
        return this.formatDate(this.smeSignUpToThankYouPage.dob);
      },
      formattedDateFounded() {
        return this.formatDate(this.smeSignUpToThankYouPage.dateFounded, true);
      },
      sections() {
        const sections = [
          {
            title: this.$t(
              `borrowerSignupFormthankYouPage.content.${
                this.isTestAndLearnEnabled ? 'testAndLearn' : 'data'
              }.sectionHeader1`
            ),
            items: [
              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.prefix'
                ),
                value: this.smeSignUpToThankYouPage.salutation,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.firstName'
                ),
                value: this.smeSignUpToThankYouPage.firstName,
              },

              {
                text: this.$t(
                  'ingSignupFormThankYouPage.content.data.lastName'
                ),
                value: this.smeSignUpToThankYouPage.lastName,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.dob'
                ),
                value: this.formattedDob,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.email'
                ),
                value: this.smeSignUpToThankYouPage.email,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.tel'
                ),
                value: this.smeSignUpToThankYouPage.mobilePhone,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.address'
                ),
                value: this.constructAddress(
                  this.smeSignUpToThankYouPage,
                  true
                ),
              },
            ],
          },
          {
            title: this.$t(
              'ingSignupFormThankYouPage.content.data.sectionHeader2'
            ),
            items: [
              {
                text: this.$t(
                  `borrowerSignupFormthankYouPage.content.data.loanAmount.${this.productKey}`
                ),
                value: currencyAmount(this.smeSignUpToThankYouPage.loanAmount, {
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }),
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.loanDuration'
                ),
                value: this.isCreditLineProduct
                  ? this.$t(
                      'borrowerSignupFormthankYouPage.content.data.loanDurationWcl'
                    )
                  : this.loanLength,
              },
            ],
          },
          {
            title: this.$t(
              'ingSignupFormThankYouPage.content.data.sectionHeader3'
            ),
            items: [
              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.firmName'
                ),
                value: this.smeSignUpToThankYouPage.companyName,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.firmStartDate'
                ),
                value: this.formattedDateFounded,
              },

              {
                text: this.$t(
                  'borrowerSignupFormthankYouPage.content.data.address'
                ),
                value: this.constructAddress(this.smeSignUpToThankYouPage),
              },
            ],
          },
        ];

        if (this.isTestAndLearnEnabled) {
          sections[2].items.push({
            text: this.$t(
              'borrowerSignupFormthankYouPage.content.testAndLearn.iban'
            ),
            value: formatIBAN(this.smeSignUpToThankYouPage.iban),
          });

          sections[2].items.push({
            text: this.$t(
              'borrowerSignupFormthankYouPage.content.testAndLearn.bic'
            ),
            value: this.smeSignUpToThankYouPage.bic,
          });
        }

        return sections;
      },
    },
    methods: {
      constructAddress(completeAddress, userAddress = false) {
        const {
          userStreet = '',
          userPostcode = '',
          userCity = '',
          street = '',
          postcode = '',
          city = '',
        } = completeAddress;

        if (userAddress) {
          return [userStreet, `${userPostcode}, ${userCity}`].join('<br/>');
        }
        return [street, `${postcode}, ${city}`].join('<br/>');
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@/variables.scss';
  @import '@/sass/custom/_variables';

  .ing-submission-overview {
    padding: 0 20px 0;

    @media (min-width: $desktop-l) {
      padding: 0;
    }
  }

  .ing-data-overview-container {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    word-break: break-all;
    flex-direction: column;

    @media (min-width: $tablet) {
    }

    @media (min-width: 1280px) {
      flex-direction: row;
      width: 1100px;
    }

    @media (max-width: 1280px) {
      p {
        margin: 5px 0 10px;
      }
    }
  }
  h2 {
    font-weight: bold;
  }

  .column {
    &--left,
    &--right {
      flex: 1 1 auto;
    }

    @media (min-width: 1280px) {
      &--left {
        flex-basis: 40%;
        padding-right: 10px;
      }

      &--right {
        flex-basis: 50%;
        padding-left: 10px;
      }
    }

    &__section {
      margin-bottom: 20px;
    }
  }
</style>
