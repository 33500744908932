<template>
  <form
    class="borrower-form-container"
    name="registration"
    @submit.prevent="submit"
  >
    <form-loader key="antragLoader" storeModule="signupForm" />

    <company-information
      :class="{ fade: shouldFadeSection(1) }"
      class="section-form first-section"
      @last-field-filled="scrollTo('representative-information')"
    />
    <span ref="spacer-1">
      <form-spacer />
    </span>

    <representative-information
      ref="representative-information"
      :class="{ fade: shouldFadeSection(2) }"
      class="section-form"
      @last-field-filled="scrollTo('terms-and-conditions')"
    />
    <span ref="spacer-2">
      <form-spacer />
    </span>

    <div :class="{ fade: shouldFadeSection(3) }" class="section-form">
      <submit-header />
      <terms-and-conditions ref="terms-and-conditions" />
      <marketing-agreement />
      <borrower-privacy-policy />
      <submit-form />
    </div>
    <span ref="spacer-3" />
  </form>
</template>

<script>
  import FormLoader from '@/components/common/forms/form-loader';
  import CompanyInformation from '@/components/common/forms/company-information';
  import RepresentativeInformation from '@/components/common/forms/representative-information';
  import SubmitHeader from '@/components/common/forms/submit-header';
  import TermsAndConditions from '@/components/common/forms/terms-and-conditions';
  import MarketingAgreement from '@/components/common/forms/marketing-agreement';
  import BorrowerPrivacyPolicy from '@/components/borrower/forms/borrower-privacy-policy';
  import SubmitForm from '@/components/common/forms/submit-form';
  import FormSpacer from '@/components/common/forms/form-spacer';
  import { getUrlParameterByName } from '@/mixins/urlParamMixin';
  import { showSectionDependingOnPosition } from '@/mixins/scrollMixin';
  import {
    analyticsDataExtraction,
    eventListeners,
    setAmountAndLength,
  } from '@/mixins/forms/formSubmissionMixin';
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import throttle from 'lodash/throttle';
  import cookie from '@/common/cookie';

  export default {
    components: {
      FormLoader,
      SubmitHeader,
      TermsAndConditions,
      MarketingAgreement,
      RepresentativeInformation,
      CompanyInformation,
      BorrowerPrivacyPolicy,
      SubmitForm,
      FormSpacer,
    },
    mixins: [
      getUrlParameterByName,
      showSectionDependingOnPosition,
      eventListeners,
      analyticsDataExtraction,
      setAmountAndLength,
    ],
    data() {
      return {
        sectionToDisplay: 1,
        isMobile: false,
      };
    },
    computed: {
      ...mapGetters('signupForm', ['isValid']),
      ...mapState('signupForm', ['submitter']),
      marketingPartnerId: function () {
        return this.getUrlParameterByName('id');
      },
    },
    mounted() {
      this.addEventListeners();
      this.setNavigationIfMarketingPartner();
      this.SET_SUBMITTER('applicant');
    },
    beforeDestroy() {
      this.removeEventListeners();
    },
    methods: {
      ...mapActions('signupForm', ['signUp']),
      ...mapMutations('signupForm', [
        'SET_GC_ID',
        'SET_SUBMIT_CLICKED',
        'SET_SUBMITTER',
      ]),
      ...mapMutations('marketingPartner', [
        'SET_MARKETING_PARTNER_ID',
        'SET_MARKETING_PARTNER_RETURN_URL',
      ]),
      scrollTo(destinationRef) {
        this.$refs[destinationRef].goTo();
      },
      onScroll() {
        this.showSectionDependingOnPosition();
      },
      throttledOnScroll: throttle(function () {
        this.onScroll();
      }, 300),
      shouldFadeSection(s) {
        return this.sectionToDisplay !== s;
      },
      setNavigationIfMarketingPartner() {
        const marketingPartnerId = this.marketingPartnerId;
        const marketingPartnerReturnUrl =
          this.getUrlParameterByName('returnurl');
        if (marketingPartnerId) {
          this.SET_MARKETING_PARTNER_ID(marketingPartnerId);
          this.SET_MARKETING_PARTNER_RETURN_URL(marketingPartnerReturnUrl);
        }
      },
      async submit() {
        this.addExtraFields();
        // by click submit they accept
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        try {
          await this.signUp();
          this.$emit('success');
          window.scrollTo(0, 0);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
      },
      addExtraFields() {
        this.setAmountAndLength();
        this.extractAnalyticsData();
        this.SET_GC_ID(cookie.get('_ga'));
      },
    },
  };
</script>

<style>
  .borrower-form-container input[type='text'].form-control,
  .borrower-form-container input[type='tel'] {
    font-size: 16px;
    line-height: 16px;
  }
  .borrower-form-container .section-form {
    transition: opacity 0.3s ease;
    opacity: 1;
  }
  .borrower-form-container .section-form.fade {
    opacity: 0.15;
  }
  .borrower-form-container input::-webkit-contacts-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }
</style>
