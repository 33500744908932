<template>
  <div class="intro">
    <picture>
      <source media="(min-width: 1024px)" :srcset="heroImageSrc.desktop" />
      <source media="(min-width: 426px)" :srcset="heroImageSrc.tablet" />
      <source :srcset="heroImageSrc.mobile" />
      <img :src="heroImageSrc.desktop" alt="hero image" class="intro__image" />
    </picture>

    <div class="intro__body">
      <h1
        class="intro__headline"
        :class="{ 'intro__headline--wcl': isCreditLineLoan }"
      >
        <span class="first-line-animation">
          <span class="first-line-inner-animation">
            <span class="intro__headline__bg">
              {{ $t(`ingHeroText.header.${productKey}.first`) }}
            </span>
          </span>
        </span>
        <span class="second-line-animation">
          <span class="second-line-inner-animation">
            <span class="intro__headline__bg">
              {{ $t(`ingHeroText.header.${productKey}.second`) }}
            </span>
          </span>
        </span>
      </h1>
      <ing-campaign-advantages v-if="isCampaignActive" />
      <ing-advantages v-else :heroStyle="true" />
    </div>
  </div>
</template>

<script>
  import IngAdvantages from '@/components/ing/forms/ing-submit-form/ing-advantages';
  import IngCampaignAdvantages from '@/components/ing/forms/ing-submit-form/ing-campaign-advantages';
  import { mapGetters } from 'vuex';

  export default {
    name: 'INGHeroContainer',
    components: {
      IngAdvantages,
      IngCampaignAdvantages,
    },
    data() {
      return {
        campaignEndDate: '2022-11-15 23:59',
      };
    },
    computed: {
      ...mapGetters('blacksea', ['isCreditLineLoan', 'productKey']),
      heroImageSrc() {
        if (this.isCreditLineLoan) {
          return {
            desktop: '/wcl-hero-ing.jpg',
            tablet: '/wcl-hero-ing-tablet.jpg',
            mobile: '/wcl-hero-ing-mobile.jpg',
          };
        } else {
          return {
            desktop: '/hero-ing.jpg',
            tablet: '/hero-ing-tablet.jpg',
            mobile: '/hero-ing-mobile.jpg',
          };
        }
      },
      isCampaignActive() {
        const campaignEnd = new Date(`${this.campaignEndDate}`).toLocaleString(
          'en-US',
          {
            timeZone: 'Europe/Berlin',
          }
        );
        const today = new Date().toLocaleString('en-US', {
          timeZone: 'Europe/Berlin',
        });
        return new Date(today) <= new Date(campaignEnd);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../sass/custom/variables';
  @import '@/variables.scss';

  .intro {
    padding: 80px 115px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: $desktop-l) {
      padding: 40px 40px 0 40px;
    }

    @media screen and (max-width: $tablet) {
      padding: 40px 20px 0 20px;
    }

    @media screen and (max-width: $mobile-l) {
      padding: 0;
      flex-direction: column;
      align-items: center;
    }

    &__image {
      width: 860px;
      border-radius: 8px;

      @media screen and (max-width: $desktop-l) {
        width: 560px;
      }

      @media screen and (max-width: $desktop-s) {
        width: 460px;
      }

      @media screen and (max-width: $tablet) {
        width: 326px;
      }

      @media screen and (max-width: $mobile-l) {
        width: 100%;
        border-radius: 0 0 12px 12px;
      }
    }

    &__body {
      padding-left: 60px;
      padding-top: 75px;

      @media screen and (max-width: $desktop-l) {
        padding-left: 45px;
        padding-top: 50px;
      }

      @media screen and (max-width: $desktop-s) {
        padding-left: 20px;
        padding-top: 40px;
      }

      @media screen and (max-width: $mobile-l) {
        width: 320px;
        padding: 0 16px 0 16px;
      }
    }

    &__headline {
      line-height: 70px;
      font-size: 41px;
      font-style: normal;
      font-weight: bold;
      color: #fff;
      transform: translateX(-160px);
      width: calc(100% + 180px);
      -webkit-font-smoothing: antialiased;

      .first-line-animation {
        animation: first-row 0.5s ease-out 0.38s 1 normal forwards;
      }
      .first-line-inner-animation {
        opacity: 0;
        display: block;
        animation: width 0.38s ease-out 0.35s normal forwards,
          opacity 0.15s ease-out 0.38s normal forwards;
      }

      .second-line-animation {
        animation: second-row 0.5s ease 0.6s 1 normal forwards;
      }
      .second-line-inner-animation {
        opacity: 0;
        display: block;
        animation: width 0.38s ease-out 0.55s normal forwards,
          opacity 0.15s ease-out 0.6s normal forwards;
      }

      @media screen and (max-width: $desktop-l) {
        font-size: 40px;
      }

      @media screen and (max-width: $desktop-s) {
        font-size: 30px;
        line-height: 54px;
        transform: translateX(-110px);
        width: calc(100% + 110px);
      }

      @media screen and (max-width: $tablet) {
        font-size: 21px;
        line-height: 38px;
        transform: translateX(-100px);
        width: calc(100% + 100px);
      }

      @media screen and (max-width: $mobile-l) {
        margin: -13px 0 0 0;
        padding: 0;
        font-size: 15.23px;
        line-height: 28px;
        width: 100%;
        transform: translateX(0);
      }

      &--wcl {
        transform: translateX(-100px);
        width: calc(100% + 130px);

        @media screen and (max-width: $tablet) {
          font-size: 21px;
          line-height: 38px;
          transform: translateX(-50px);
          width: calc(100% + 50px);
        }

        @media screen and (max-width: $mobile-l) {
          margin: -13px 0 0 0;
          padding: 0;
          font-size: 15.23px;
          line-height: 28px;
          width: 100%;
          transform: translateX(0);
        }
      }

      &__bg {
        border-radius: 5.5px;
        background: $ing-orange;
        padding: 5px 14px;
        -webkit-box-decoration-break: clone;
        box-decoration-break: clone;

        @media screen and (max-width: $tablet) {
          padding: 3px 8px;
          border-radius: 3px;
        }

        @media screen and (max-width: $mobile-l) {
          padding: 3px 5px;
          border-radius: 2px;
        }
      }
    }
  }
</style>
