<template>
  <div class="ing-wrapper ing-partner">
    <TheHeader />
    <IngPartnerSubmissionForm />
    <TheContact container="partner" />
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheContact from '@/components/ingOrangeJuice/Structure/TheContact';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngPartnerSubmissionForm from '@/components/partner/forms/IngPartnerSubmissionForm';

  export default {
    name: 'IngPartnerSubmissionPage',
    components: {
      TheHeader,
      IngPartnerSubmissionForm,
      TheFooter,
      TheContact,
    },
    metaInfo() {
      return {
        title: this.$i18n.t('partner.login.meta.title'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('partner.login.meta.title'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('partner.login.meta.description'),
          },
        ],
      };
    },
  };
</script>
