import countries from './countries.json';
const c = new Intl.Collator();

const nationalities = countries
  .filter(({ independent }) => independent)
  .map(
    ({
      translations: {
        deu: { common: name },
      },
      cca2: iso,
    }) => ({
      name,
      iso,
    })
  )
  .sort((a, b) => c.compare(a.name, b.name));

export default nationalities;
