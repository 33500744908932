export const isValidLegalStatus = (legalForm) => {
  const validLegalForm = [
    'GmbH',
    'GmbH & Co. KG',
    'Freie Berufe',
    'Einzelfirma',
    'KG',
    'OHG',
    'GbR',
    'AG',
    'UG (haftungsbeschränkt)',
    'Limited',
    'Andere',
  ];
  return !!(legalForm && validLegalForm.includes(legalForm));
};

export const noOfferForThisLegalForm = (legalForm) => {
  const validLegalForm = ['GbR', 'AG', 'UG (haftungsbeschränkt)', 'Limited'];
  return !!(legalForm && validLegalForm.includes(legalForm));
};

export const limitedLiabilityLegalForm = ['GMBH', 'UG', 'GMBH_CO_KG'];
export const controllingCompanyLegalForm = 'GMBH_CO_KG';
export const complexLegalForm = 'GMBH';
export const personLegalForms = [
  'Freie Berufe',
  'Einzelfirma',
  'GbR',
  'Andere',
];
