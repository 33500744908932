import {
  hasDigit,
  hasSpecialChars,
  isEmpty,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidatePartnerLastName(partnerLastName) {
  if (isEmpty(partnerLastName)) {
    return { partnerLastName: 'PartnerLastNameIsRequired' };
  }
  if (
    hasForbiddenCharacters(partnerLastName) ||
    hasDigit(partnerLastName) ||
    hasSpecialChars(partnerLastName, ['.', '-'])
  ) {
    return { partnerLastName: 'ForbiddenCharacters' };
  }

  return { partnerLastName: undefined };
}
