<template>
  <h3 id="controllingCompanyTitle">
    {{ $t('signersMissingInfo.formPage.companySection.controllingCompany.h3') }}
    <tooltip
      key="controlling-company-title-tooltip"
      :tooltipMessage="
        $t(
          'signersMissingInfo.formPage.companySection.controllingCompany.tooltip'
        )
      "
      messageClass="controlling-tooltip-message"
    />
  </h3>
</template>

<script>
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: {
      Tooltip,
    },
  };
</script>
