import { isEmpty } from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateCity(city) {
  if (isEmpty(city)) {
    return { city: 'CityIsRequired' };
  }
  if (hasForbiddenCharacters(city)) {
    return { city: 'ForbiddenCharacters' };
  }
  return { city: undefined };
}
