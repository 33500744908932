<template>
  <len-text-input
    v-model="firstName"
    :label="label"
    :error="errors.firstName && $t('forms.errors.' + errors.firstName)"
    :displayErrorIfDirty="!submitClicked"
    name="FirstName"
    data-qa-id="representative-info-firstname"
    @focus="handleFocus"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenTextInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.firstName.label');
      },
      firstName: {
        get() {
          return this.$store.state.signupForm.firstName;
        },
        set(value) {
          this.SET_FIRST_NAME(value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_FIRST_NAME']),
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
