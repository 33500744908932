<template>
  <div class="display--flex warning-section">
    <img src="/warning_icon.png" alt="Warning Icon" class="warning-icon" />
    <span class="warning-wrapper">
      <p
        class="warning-text"
        v-html="
          $t(
            'signersMissingInfo.formPage.identificationTypeSection.form.option2warning'
          )
        "
      />
      <a :href="'mailto:' + $t('ingEmail')" class="ing-link">
        {{ $t('ingEmail') }}
      </a>
    </span>
  </div>
</template>
<script>
  export default {
    name: 'IngIdentTypeWarning',
  };
</script>
<style scoped>
  .warning-section {
    padding: 20px 20px 20px 25px;
  }
  .warning-wrapper {
    padding-left: 10px;
  }
  .warning-text {
    display: inline;
  }
  .warning-icon {
    height: 20px;
  }
</style>
