<template>
  <ing-country-input
    :label="label"
    :error="errors.nationality && $t('forms.errors.' + errors.nationality)"
    :displayErrorIfDirty="!submitClicked"
    :preferredCountries="['DE']"
    name="nationality"
    data-qa-id="nationality"
    @change="onChange"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngCountryInput from '@/components/ing/ing-country-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngCountryInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.nationality.label');
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_NATIONALITY']),
      onChange(iso) {
        this.SET_NATIONALITY(iso);
        this.sendFieldTrackingEvent(`select:${this.label}`, iso);
      },
    },
  };
</script>
