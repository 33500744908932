<template>
  <svg viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.87 17.9007C12.87 18.1448 12.7575 18.2573 12.4763 18.2573H10.5053C10.2431 18.2573 10.1295 18.1448 10.1295 17.9007V15.9488C10.1295 15.7047 10.2431 15.5911 10.5053 15.5911H12.4763C12.7575 15.5911 12.87 15.7047 12.87 15.9488V17.9007ZM10.5986 4.74269H12.4009C12.663 4.74269 12.7575 4.85519 12.7575 5.08019L12.4954 13.5649C12.4954 13.7517 12.4211 13.8079 12.2141 13.8079H10.8248C10.6178 13.8079 10.5435 13.7517 10.5435 13.5649L10.2431 5.08019C10.2431 4.85519 10.3365 4.74269 10.5986 4.74269ZM11.4998 0.250565C5.29763 0.250565 0.249756 5.29619 0.249756 11.5006C0.249756 17.7027 5.29763 22.7506 11.4998 22.7506C17.703 22.7506 22.7498 17.7027 22.7498 11.5006C22.7498 5.29619 17.703 0.250565 11.4998 0.250565Z"
      fill="#D70000"
    />
  </svg>
</template>
<script>
  export default {
    name: 'IconError',
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  svg {
    width: px2rem(18);
    height: px2rem(18);
  }
</style>
