import { getStateValidator } from './getters';
import { initialState } from './index';
import { personLegalForms } from '@/validators/helpers/legalStatus';
import trim from 'lodash/trim';

export const mutateField = (state, data, fieldName) => {
  const StateValidator = getStateValidator(state);
  if (typeof data === 'string') {
    state[fieldName] = trim(data);
  } else {
    state[fieldName] = data;
  }
  state.errors = StateValidator(state);
};

export const mutations = {
  SET_AMOUNT(state, data) {
    mutateField(state, data, 'loanAmount');
  },
  SET_LENGTH(state, data) {
    mutateField(state, data, 'length');
  },
  SET_DISBURSEMENT_AMOUNT(state, data) {
    mutateField(state, data, 'disbursementAmount');
  },
  SET_COMPANY_NAME: (state, data) => {
    mutateField(state, data, 'company');
  },
  SET_STREET: (state, data) => {
    mutateField(state, data, 'street');
  },
  SET_IBAN: (state, data) => {
    mutateField(state, data, 'iban');
  },
  SET_BIC: (state, data) => {
    mutateField(state, data, 'bic');
  },
  SET_POSTCODE_DE: (state, data) => {
    mutateField(state, data, 'postcodeDe');
  },
  SET_CITY: (state, data) => {
    mutateField(state, data, 'city');
  },
  SET_DATE_FOUNDED: (state, data) => {
    mutateField(state, data, 'dateFounded');
  },
  SET_REVENUE: (state, data) => {
    mutateField(state, data, 'revenue');
  },
  SET_LEGAL_STATUS: (state, data) => {
    mutateField(state, data, 'legalStatus');
  },
  SET_LOAN_PURPOSE: (state, data) => {
    mutateField(state, data, 'loanPurposeBorrower');
  },
  SET_BOOKKEEPING_STANDARD: (state, data) => {
    mutateField(state, data, 'bookkeepingStandard');
  },
  SET_SALUTATION: (state, data) => {
    mutateField(state, data, 'salutation');
  },
  SET_FIRST_NAME: (state, data) => {
    mutateField(state, data, 'firstName');
  },
  SET_LAST_NAME: (state, data) => {
    mutateField(state, data, 'lastName');
  },
  SET_EMAIL: (state, data) => {
    mutateField(state, data, 'email');
  },
  SET_NATIONALITY: (state, data) => {
    mutateField(state, data, 'nationality');
  },
  SET_MOBILE_PHONE: (state, data) => {
    mutateField(state, data, 'mobilePhoneBorrower');
  },
  SET_DOB: (state, data) => {
    mutateField(state, data, 'dob');
  },
  SET_USER_STREET: (state, data) => {
    mutateField(state, data, 'userStreet');
  },
  SET_USER_POSTCODE_DE: (state, data) => {
    mutateField(state, data, 'userPostcodeDe');
  },
  SET_USER_CITY: (state, data) => {
    mutateField(state, data, 'userCity');
  },
  SET_MARKETING_AGREEMENT: (state, data) => {
    mutateField(state, data, 'marketingAgreement');
  },
  SET_COMPANIES: (state, companies) => {
    state.companies = companies;
  },
  SET_FW_COMPANY: (
    state,
    { companyName, street, postalCode, city, mappingId }
  ) => {
    const StateValidator = getStateValidator(state);
    state.company = companyName;
    state.street = street || '';
    state.postcodeDe = postalCode || '';
    state.city = city || '';
    state.mappingId = mappingId || '';

    state.errors = StateValidator(state);
  },
  RESET_PREFILLED_FIELDS: (state) => {
    const StateValidator = getStateValidator(state);
    state.street = null;
    state.postcodeDe = null;
    state.city = null;
    state.mappingId = null;
    state.legalStatus = null;
    state.errors = StateValidator(state);
  },
  SET_COMPANY_LOOK_UP_ENABLED: (state, value) => {
    state.companyLookupEnabled = value;
  },
  SET_UTMZ_DATA: (state, analData) => {
    state.medium = analData.medium;
    state.source = analData.source;
    state.campaignName = analData.campaign;
    state.term = analData.term;
    state.content = analData.content;
  },
  SET_ANALYTICS_DATA: (state, payload) => {
    state = Object.assign(state, payload);
  },
  SET_UTM_PARAMS: (state, payload) => {
    state = Object.assign(state, payload);
  },
  SET_GC_ID: (state, gcid) => {
    state.gcid = gcid;
  },
  SET_ERROR: (state, error) => {
    state.errors.server = error;
  },
  SET_SUBMIT_CLICKED: (state, submitClicked) => {
    state.submitClicked = submitClicked;
  },
  SET_SUBMITTER: (state, submitter) => {
    state.submitter = submitter;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_APPLICATION_STATUS: (state, status) => {
    state.applicationStatus = status;
  },
  SET_PROSPECT_ID: (state, prospectId) => {
    state.prospectId = prospectId;
  },
  SET_SEARCHING_COMPANY: (state, searchingCompany) => {
    state.searchingCompany = searchingCompany;
  },
  // @todo this should be a getter only
  SET_BOOKKEEPING_VISIBILITY: (state) => {
    state.isBookkeepingStandardVisible = personLegalForms.includes(
      state.legalStatus
    );
  },
  CLEAR: (state) => Object.assign(state, initialState),
};
