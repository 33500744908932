<template>
  <span>&nbsp;</span>
</template>
<script>
  import { confirmNewsletterSubscription } from '@/webservice/subscription';

  export default {
    name: 'NewsletterConfirmationForm',
    async mounted() {
      const {
        newsletterConfirmationSuccessUrl,
        newsletterConfirmationFailureUrl,
      } = this.$translationConstantValues;

      const token = this.$route.query?.token;
      let succeed = true;
      if (token) {
        try {
          await confirmNewsletterSubscription({ token });
          window.location.replace(newsletterConfirmationSuccessUrl);
        } catch (error) {
          succeed = false;
        }
      }

      if (!token || !succeed) {
        window.location.replace(newsletterConfirmationFailureUrl);
      }
    },
  };
</script>
