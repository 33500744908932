<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('signers.companyDetails.mailingAddress') }}
      </h3>
      <CheckBox
        v-model="businessAddressIsPostalAddress"
        :label="$t('signers.form.address.isDifferentCheck')"
      />
      <div v-show="company.businessAddressIsPostalAddress">
        <TextInput
          v-model="postalStreet"
          :label="$t('signers.form.address.street')"
          :error="getError('postalStreet')"
          :displayErrorIfDirty="displayError()"
        />
        <TextInput
          v-model="postalStreetNumber"
          inputSize="xs"
          :label="$t('signers.form.address.houseNumber')"
          :error="getError('postalStreetNumber')"
          :displayErrorIfDirty="displayError()"
          inputmode="number"
        />
        <TextInput
          v-model="postalZip"
          inputSize="xs"
          :label="$t('signers.form.address.postalCode')"
          :error="getError('postalZip')"
          :displayErrorIfDirty="displayError()"
          inputmode="number"
        />
        <TextInput
          v-model="postalCity"
          inputSize="m"
          :label="$t('signers.form.address.city')"
          :error="getError('postalCity')"
          :displayErrorIfDirty="displayError()"
        />
      </div>
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import CheckBox from '@/components/ingOrangeJuice/Base/CheckBox';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  export default {
    components: {
      TextInput,
      CheckBox,
      FormSubSection,
    },
    computed: {
      businessAddressIsPostalAddress: {
        get() {
          return this.company.businessAddressIsPostalAddress;
        },
        set(newValue) {
          this.SET_IS_COMPANY_POSTAL_ADDRESS_SAME(newValue);
          this.handleAddressChange(newValue);
        },
      },
      postalStreet: {
        get() {
          return this.company.postalStreet;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_STREET(value);
        },
      },
      postalStreetNumber: {
        get() {
          return this.company.postalStreetNumber;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_STREET_NUMBER(value);
        },
      },
      postalZip: {
        get() {
          return this.company.postalZip;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_ZIP(value);
        },
      },
      postalCity: {
        get() {
          return this.company.postalCity;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_CITY(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_POSTAL_CITY',
        'SET_COMPANY_POSTAL_ZIP',
        'SET_COMPANY_POSTAL_STREET',
        'SET_COMPANY_POSTAL_STREET_NUMBER',
        'SET_IS_COMPANY_POSTAL_ADDRESS_SAME',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'CLEAR_COMPANY_POSTAL_ADDRESS',
      ]),
      getError(field) {
        return (
          this.errors.company &&
          this.errors.company[field] &&
          this.$t('forms.errors.' + this.errors.company[field])
        );
      },
      displayError() {
        return !this.submitClicked && !this.companySubmitClicked;
      },
      handleAddressChange(newAddressvalue) {
        if (newAddressvalue) {
          this.CLEAR_COMPANY_POSTAL_ADDRESS();
        } else {
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        }
      },
    },
  };
</script>
