export default {
  contactEmail: 'unternehmen@lendico.de',
  finanzLexikonLink: 'finanzlexikon/jahresabschlussarten',
  borrowerDashboardSubMenu: {
    questionnaire: 'Anleger-Fragebogen',
    dashboard: 'Kontoübersicht',
    loanMarketplace: 'Aktuelle Kreditprojekte',
    myProfile: 'Mein Profil',
    pledgesInvestments: 'Meine Anlagen',
    myTransactions: 'Umsätze',
  },
  borrowerLoansAndLoanRequests: {
    title: 'Kredite und Kreditanfragen',
  },
  borrowerLoanDetailsBlock: {
    title: 'Zusammenfassung',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
    rows: {
      loanName: 'Kreditprojekte',
      amount: 'Betrag',
      interestRate: 'Zinssatz',
      duration: 'Laufzeit',
      payoutDate: 'Auszahlungsdatum',
    },
  },
  borrowerLoanListBlock: {
    title: 'Meine Kredite',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
    noItemsWarningMessage: 'Leider konnte derzeit kein Kredite geladen werden.',
    unknownErrorWarningMessage:
      'Leider konnte derzeit kein Kredite geladen werden.',
    pleaseWaitWarningMessage:
      'Bitte warten Sie, während die Kredite geladen werden.',
    columns: {
      loanNumber: 'Kreditnummer',
      loanName: 'Kreditprojekte',
      amount: 'Betrag',
      payoutDate: 'Auszahlungsdatum',
      status: 'Status',
    },
    statuses: {
      payback: 'aktiv',
      terminated: 'storniert',
      external_servicing: 'ausgefallen',
      sold: 'ausgefallen',
      rescinded: 'aufgehoben',
    },
  },
  borrowerLoanRequestList: {
    title: 'Meine Anfragen',
    noItemsWarningMessage: 'Leider konnte derzeit kein Anträge geladen werden.',
    pleaseWaitWarningMessage:
      'Bitte warten Sie, während die Kreditprojekte geladen werden.',
    columns: {
      loanNumber: 'Kreditnummer',
      loanName: 'Kreditprojekte',
      amount: 'Betrag',
      status: 'Status',
    },
  },
  borrowerLoanRequestDetailsPage: {
    title: 'Übersicht Kreditanfragen',
  },
  borrowerLoanRequestDetailsBlock: {
    title: 'Zusammenfassung',
    rows: {
      loanName: 'Kreditprojekte',
      amount: 'Betrag',
      interestRate: 'Zinssatz',
      duration: 'Laufzeit',
      payoutDate: 'Auszahlungsdatum',
      status: 'Status',
    },
  },
  borrowerLoanDetailsPage: {
    title: 'Übersicht Kreditdetails',
  },
  borrowerLoanPaymentsBlock: {
    title: 'Tilgungsplan',
    columns: {
      dueDate: 'Fälligkeitsdatum',
      residualPrincipal: 'Restbetrag Vormonat',
      totalPrincipal: 'Ratenkategorie',
      totalInterest: 'Zinsanteil',
      totalPayment: 'Ratenhöhe',
      paymentStatus: 'Tilgungsanteil',
    },
  },
  borrowerSignupPage: {
    metaTitle: 'Kredit beantragen',
    metaDescription:
      'Ein schneller und einfacher Kredit: Kreditentscheidung in 48 Stunden, ohne Businessplan. Auszahlung in einer Woche. Kostenlose vorzeitige Rückzahlung | Keine Besicherung | Feste monatliche Zahlung | Laufzeit: 1 bis 5 Jahre | € 10.000 bis € 450.000 | Zins ab 3%. Beantragen Sie unverbindlich einen Kredit!',
    googleSiteVerification: 'xMdLCDCPcbxtmgT-2lq9FCHF-T8iLwnYOVcxADQe2XI',
    title:
      '<strong>Ihre unverbindliche Kreditanfrage mit nur einem Klick</strong>',
    supportSection: {
      title: 'Unser Team ist persönlich für Sie da',
      description: {
        first:
          'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail.',
        second:
          'Unsere Kundenbetreuung ist <strong>Montag bis Freitag von 9 bis 18 Uhr</strong> für Sie da:',
      },
      phone: '030 398 205 269',
      union: 'oder',
      email: 'unternehmen@lendico.de',
    },
  },
  borrowerSignupForm: {
    companyHeader: {
      title: 'Firmeninformationen',
      explanation:
        'Um Ihnen einen individuellen Finanzierungsvorschlag machen zu können, benötigen wir ein paar Angaben zu Ihrem Unternehmen. Wenn Sie Ihren Firmennamen eingeben, wird das Anfrageformular automatisch mit Ihren Firmendaten vervollständigt. So sparen Sie noch mehr Zeit bei der Kreditanfrage.',
      ingExplanation:
        'Wir benötigen nur ein paar Informationen, um Ihr Unternehmen einschätzen zu können. Eine Übersendung von Unterlagen wie Jahresabschlüsse oder Kontoauszüge ist nicht notwendig.',
    },
    company: {
      label: 'Geben Sie bitte Ihren Firmennamen ein',
      tooltip:
        'Wenn Sie Ihren Firmennamen eingeben und aus der Liste auswählen, wird das Anfrageformular automatisch mit Ihren Firmendaten vervollständigt. So sparen Sie noch mehr Zeit.',
    },
    representativeHeader: {
      title: 'Firmenvertreter',
      explanation:
        'Um eine Kreditanfrage zu stellen, müssen Sie ein Vertretungsberechtigter oder Inhaber der Firma sein.',
      tooltip:
        'Als Vertretungsberechtigte werden derzeit nur Geschäftsführer oder Vorstandsmitglieder akzeptiert. Eine Anfrage durch Prokuristen und sonstige Bevollmächtigte ist leider nicht möglich.',
    },
    submitHeader: {
      title: 'Noch zwei Klicks',
    },
    privacyPolicy: {
      p1: 'Im Rahmen der sog. Autofill-Funktion sowie zum Zwecke einer Vorabprüfung erhält Lendico Informationen von der ',
      p2: 'Zur Beurteilung der Bonität übermittelt Lendico im Auftrag der ING Daten an die ',
      p3: ' und die ',
      p4: ' bzw. es werden Daten von den genannten Stellen abgefragt.',
      cta1Text: 'Creditreform',
      cta2Text: 'SCHUFA',
      schufaModalContent: {
        title: 'SCHUFA',
        content:
          'Lendico übermittelt im Auftrag der ING im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die <b>SCHUFA Holding AG</b>, Kormoranweg 5, 65201 Wiesbaden (im Folgenden "SCHUFA"). Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 lit. b und Artikel 6 Absatz 1 lit. f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 lit. f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.\n' +
          '<br /><br /><b>Der Kunde befreit die ING insoweit auch vom Bankgeheimnis.</b><br /><br />\n' +
          'Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht oder Standardvertragsklauseln vereinbart wurden, die unter www.schufa.de eingesehen werden können) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter <a target="_blank" href="https://www.ing.de/datenschutz/schufa" class="base-link">www.ing.de/datenschutz/schufa</a> eingesehen werden.',
      },
      creditreFormModalContent: {
        title: 'Creditreform',
        content:
          'Im Rahmen der sog. Autofill-Funktion und zum Zwecke einer Vorabprüfung erhält Lendico relevante Informationen (z.B. Adressdaten, Informationen) von der <b>Creditreform Berlin Brandenburg Wolfram GmbH & Co. KG;</b> Karl-Heinrich-Ulrichs-Straße 1, 10787 Berlin (im Folgenden „Crefo“). Nach erfolgreicher Vorabprüfung übermittelt Lendico im Auftrag der ING Daten (Name, Adresse und gegebenenfalls Geburtsdatum) zum Zwecke der Bonitätsprüfung an die Crefo. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.\n' +
          '<br /><br /><b>Der Kunde befreit die ING insoweit auch vom Bankgeheimnis.</b><br /><br />\n' +
          'Detaillierte Informationen zur Crefo im Sinne des Artikels 14 DSGVO, d.h. Informationen zum Geschäftszweck, zu Zwecken der Datenspeicherung, zu den Datenempfängern, zum Selbstauskunftsrecht, zum Anspruch auf Löschung oder Berichtigung etc. können online unter <a rel="noopener noreferrer" href="https://www.creditreform.de/berlin/datenschutz" class="base-link" target="_blank">Datenschutz | Creditreform Berlin</a> eingesehen werden.',
      },
    },
  },
  borrowerSignupFormthankYouPage: {
    mainHeader: 'Vielen Dank für Ihre Anfrage',
    subHeader:
      'Wir bestätigen Ihre Anfrage noch einmal in einer separaten E-Mail und kontaktieren Sie in den nächsten 48 Stunden. Um den Anfrageprozess zu beschleunigen, bitten wir Sie, sich den Abschnitt „Wie geht es weiter?“ durchzulesen und die benötigten Unterlagen zu übersenden.',
    experianFlowSubHeader:
      'Wir freuen uns über Ihr Interesse an Lendico und melden uns schnellstmöglich mit weiteren Informationen bei Ihnen.',
    content: {
      testAndLearn: {
        header: 'Ihre Daten auf einen Blick:',
        sectionHeader1: 'Persönliche Daten',
        iban: 'IBAN:',
        bic: 'BIC:',
      },
      data: {
        header: 'Ihre Daten im Überblick',
        sectionHeader1: 'Persönliche Daten',
        prefix: 'Anrede:',
        firstName: 'Vorname:',
        lastName: 'Nachname:',
        dob: 'Geburtsdatum:',
        email: 'E-Mail:',
        tel: 'Mobilfunknummer:',
        address: 'Adresse:',
        sectionHeader2: 'Kreditdetails',
        loanAmount: {
          termLoan: 'Betrag:',
          wcl: 'Flexkredit:',
        },
        year: 'Jahre',
        loanDuration: 'Laufzeit:',
        loanDurationWcl: 'unbefristet',
        sectionHeader3: 'Firmeninformationen',
        firmName: 'Firmenname:',
        firmStartDate: 'Gründungsdatum:',
        firmAddress: 'Adresse:',

        h3: 'Vielen Dank für Ihr Interesse an einer Firmenfinanzierung mit Lendico',
        p1: 'Damit wir Ihnen schnell einen Finanzierungsvorschlag machen können, schicken Sie uns bitte:',
        ul1p1:
          '<b>Jahresabschlüsse</b> der letzten zwei Geschäftsjahre (2018 und 2019). Sofern für 2019 keine finalen Dokumente vorliegen, senden Sie uns bitte Ihre Betriebswirtschaftliche Auswertung mit Summen- und Saldenliste.',
        ul1p2:
          '<b>Betriebswirtschaftliche Auswertung</b> mit Summen- und Saldenliste von 2020, nicht älter als 3 Monate.',
        ul1p3:
          '<b>Kontoauszüge</b> der letzten drei Monate, nicht älter als 14 Tage.',

        h4: 'Wie geht es weiter?',
        ol1p1:
          'Schicken Sie uns bitte folgende Unterlagen als PDF-Dateien per E-Mail an',
        sul1p1:
          '• <b>Jahresabschlüsse</b> der letzten zwei Geschäftsjahre ({currentYearMinusTwo} und {currentYearMinusOne}). Sofern für {currentYearMinusOne} keine finalen Dokumente vorliegen, senden Sie uns bitte Ihre <b>Betriebswirtschaftliche Auswertung</b> mit Summen- und Saldenliste.',
        sul1p2:
          '• <b>Betriebswirtschaftliche</b> Auswertung mit Summen- und Saldenliste von {currentYear}, nicht älter als drei Monate.',
        sul1p3: '• <b>Kontoauszüge</b> der letzten 90 Tage.',
        ol1p2:
          'Sobald Ihre Unterlagen vollständig sind, senden wir Ihnen <b>innerhalb von 48 Stunden</b> einen kostenfreien und unverbindlichen Finanzierungsvorschlag.',
        ol1p3:
          'Wenn Sie diesen annehmen, identifizieren Sie sich noch kurz und unterschreiben den Kreditvertrag.',
        ol1p4: 'Dann wird das Geld <b>direkt auf Ihr Konto</b> überwiesen.',
      },
    },
  },
  borrowerJourney: {
    metaTitle: 'Vertragsunterlagen',
    metaDescription: 'Identifikation und Vertragsunterzeichnung',
    footer: {
      copyright: {
        p1: 'Copyright',
        p2: 'Lendico Deutschland GmbH / Alle Rechte vorbehalten.',
      },
      dataProtection: {
        href: 'datenschutz',
        text: 'Datenschutz',
      },
      kreditnehmer: {
        href: 'firmenkredit-agb',
        text: 'AGB Kreditnehmer',
      },
      impressum: {
        href: 'impressum',
        text: 'Impressum',
      },
      cookieSettings: {
        text: 'Cookie-Einstellungen',
        ariaLabel: 'Gehe zu Cookie-Einstellungen',
      },
    },
    progressBar: {
      firstStep: 'Finanzierungsvorschlag',
      secondStep: 'Vertragsunterlagen',
    },
    borrowerOfferComponent: {
      title: 'IHRE FINANZIERUNGSKONDITIONEN AUF EINEN BLICK:',
      loanAmount: 'Kreditbetrag:',
      duration: 'Laufzeit:',
      monthlyPayment: 'Monatliche Rate:',
      fee: 'Gebundener Sollzinssatz:',
      resolveOffer: {
        label:
          'Klicken Sie auf den Button, um Ihre Vertragsunterlagen herunterzuladen und die Identifizierung zu starten.',
        button: 'Identifizierung starten',
      },
    },
    borrowerContractsComponent: {
      contractsTitle: 'IHRE VERTRAGSUNTERLAGEN',
      identification: {
        title: 'IDENTIFIZIEREN UND UNTERSCHREIBEN IN NUR EINEM SCHRITT',
        p1: 'In Ihrem E-Mail-Postfach finden Sie eine Schritt-für-Schritt-Anleitung zur Identifizierung per Videochat sowie eine weitere E-Mail der Deutschen Post AG. Diese enthält einen Link, mit dem Sie das Online-Verfahren starten.',
        p2: 'Wenn Sie und mögliche weitere Vertretungsberechtigte sich erfolgreich identifiziert und digital unterschrieben haben, erhalten Sie die Vertragsunterlagen per E-Mail. Bitte beachten Sie, dass für die Identitätsprüfung ein Computer, ein Smartphone oder ein Tablet mit Kamera und Mikrofon sowie eine stabile Internetverbindung notwendig sind.',
        p3: 'Wie geht es weiter?',
        li1: 'Prüfen Sie Ihre Vertragsunterlagen',
        li2: 'Klicken Sie auf den Link in der E-Mail der Deutschen Post AG',
        li3: 'Identifizieren Sie sich mit einem gültigen Ausweisdokument',
        li4: 'Unterschreiben Sie die Verträge durch Eingabe einer SMS-TAN',
      },
      creditContract: 'Kreditvertrag',
      micro_creditContract: 'Kreditvertrag',
      guaranteeContract: 'Bürgschaftsvertrag',
      blanket_assignmentContract: 'Globalzession',
      power_of_attorneyContract: 'Vollmacht',
    },
    contractModal: {
      download: 'Herunterladen',
    },
    sideBar: {
      section1: {
        title: 'Sichere Identifikation und Vertragsunterzeichnung',
        p1: '<b>Vertrauenswürdiger Partner:</b> Die Deutsche Post bietet ein sicheres Online-Verfahren zur Überprüfung Ihrer Identität und zur Vertragsunterzeichnung. Damit sparen Sie sich den Weg zum Postfiliale.',
        p2: '<b>Einfach und schnell:</b> Sie können die Identifikation und die Vertragsunterzeichnung jederzeit vornehmen und auch erneut starten, wann immer es Ihnen am besten passt. Alle Vertragsparteien und Bürgen unterschreiben nacheinander. Sie werden informiert, sobald alle Unterschriften eingegangen sind.',
      },
      section2: {
        title: 'Haben Sie noch Fragen?',
        p1: 'Sie erreichen uns von Montag bis Freitag zwischen 9 und 18 Uhr telefonisch unter ',
        p2: ' oder per E-Mail an ',
      },
    },
  },
  borrowerSuccessPage: {
    metaTitle: 'Success',
    metaDescription: 'Successfully accepted loan offer.',
    successText:
      'Vielen Dank für Ihr Vertrauen in Lendico!<br>\n' +
      'Wenn uns alle Unterschriften vorliegen, zahlen wir Ihr Darlehen innerhalb der nächsten Werktage aus.\n' +
      'Sollten wir noch Unterschriften von weiteren Vertragsparteien und/oder Bürgen benötigen, laden wir diese nun nacheinander zur Legitimierung bzw. Vertragsunterzeichnung ein.',
    contactText:
      'Bei Fragen rufen Sie uns an Werktagen zwischen 9 und 18 Uhr unter 030 - 3119 91 93 an.',
  },
  borrowerFailPage: {
    metaTitle: 'Fail',
    metaDescription: 'Something went wrong while accepting loan offer.',
    failHeader:
      'Die Vertragsunterzeichnung <br>konnte nicht abgeschlossen werden',
    failText:
      'Leider konnten wir die Vertragsunterzeichnung auf unserer Seite nicht abschließen. Dafür kann es unterschiedliche Gründe geben. Ihr Lendico-Kundenbetreuer wird sich Sie innerhalb von 48 Stunden telefonisch bei Ihnen melden. Wenn Sie nicht so lange warten möchten, können Sie uns auch direkt kontaktieren',
    CTA: 'Support kontaktieren',
  },
  borrowerTransactionsPage: {
    title: 'Meine Transaktionen',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
  },
  borrowerTransactionsListBlock: {
    filterButtonTitle: 'Filter',
    columns: {
      date: 'Datum',
      amount: 'Betrag',
      reference: 'Referenz',
      purpose: 'Zweck',
    },
    emptyValues: {
      referenceText: '<i style="font-weight: normal;">(Nicht verfügbar)</i>',
      type: 'Unbekannt',
    },
    type: {
      INCOMING_PAYMENT: 'Erhaltene Zahlung',
      REJECTED_DD: 'Rücklastschrift',
      REJECTION_FEE: 'Rücklastschriftgebühr',
      OUTGOING_PAYMENT: 'Rücküberweisung',
      INCOMING_FACTOR_PAYMENT: '',
      INCOMING_DCA_PAYMENT: '',
    },
    noItemsMessage: 'Leider konnte derzeit kein Transaktionen geladen werden.',
    pleaseWaitMessage:
      'Bitte warten Sie, während die Transaktionen geladen werden.',
  },
  borrowerTransactionsFilterBlock: {
    resetAction: 'Filter zurücksetzen',
    filterAction: 'Filter anwenden',
    loanNumber: 'Kreditnummer',
    type: 'Transactionen Kategorie',
    amount: 'Transaktionen Betrag',
    startDate: 'Datum (ab)',
    endDate: 'Datum (bis)',
  },
  borrowerMyProfilePage: {
    title: 'Persönliche Daten',
  },
};
