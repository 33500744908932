<template>
  <section
    class="ing-page-section"
    :class="{ 'ing-page-section--darkBg': darkTheme }"
  >
    <div
      class="ing-page-section__innerSection"
      :class="[
        { 'ing-page-section__innerSection--fullWidth': fullWidth },
        sectionPadding
          ? 'ing-page-section__innerSection--' + sectionPadding
          : '',
      ]"
    >
      <slot name="section-content" />
    </div>
  </section>
</template>

<script>
  export default {
    name: 'PageSection',
    props: {
      darkTheme: {
        type: Boolean,
        default: false,
      },
      sectionPadding: {
        type: String,
        default: '',
      },
      fullWidth: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ing-page-section {
    &--darkBg {
      background: $Primary-Grey100;
    }
    &__innerSection {
      padding: 64px 16px;
      margin: 0 auto;
      &--fullWidth {
        max-width: 100%;
      }
      &--no {
        padding: 0 16px;
      }
      &--sm {
        padding: 32px 16px;
      }
      &--md {
        padding: 64px 16px;
      }
      &--lg {
        padding: 80px 16px;
      }
      &--bottom-sm {
        padding-bottom: 40px;
      }
      &--bottom-no {
        padding-bottom: 0;
        margin-bottom: -40px;
      }
      &--top-no {
        padding-top: 0;
      }
      @include mq-size(m) {
        padding-left: 24px;
        padding-right: 24px;
      }
      @include mq-size(xl) {
        max-width: 1022px;
        box-sizing: content-box;
      }
    }
    &:deep(a) {
      color: $Secondary-Indigo500;
      text-decoration: underline;
      &:hover,
      &:focus {
        background: #e6e5ef;
      }
      &:visited {
        color: $Secondary-Fuchsia500;
        &:hover {
          background: #efdee8;
        }
      }
    }
  }
</style>
