<template>
  <div class="ing-submit-button-wrapper" data-qa-id="submit-form-button-div">
    <button
      class="ing-submit-button"
      data-qa-id="submit"
      type="submit"
      wtlink="firmenkredit_anfragen_antrag.content.link_intern"
    >
      {{ $t('ingSubmission.ingSubmitButton') }}
    </button>
    <div :class="{ active: !!errorMessage }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p v-html="errorMessage" />
    </div>
  </div>
</template>

<script>
  import { submitFormFunctionality } from '@/mixins/forms/submitFormMixin';
  export default {
    mixins: [submitFormFunctionality],
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .ing-error {
    margin-top: 12px;
    justify-content: center;
  }
  @media screen and (max-width: $mobile-l) {
    .ing-error {
      margin-top: 11px;
      font-size: 12px;
      line-height: 14px;
      height: auto;
      text-align: left;
    }
  }
</style>
