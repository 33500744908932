<template>
  <div class="ing-wrapper">
    <TheHeader />
    <IngAuthentication
      v-if="isUserUnauthorised"
      :id="loanRequestId"
      @onResponse="getOffer()"
    />
    <PageLoader storeModule="offer" />
    <IngButton
      type="button"
      class="outlined small align-right"
      @click="userLogoutMixin()"
      >{{ $t('logoutSection.logoutButton') }}</IngButton
    >
    <div class="offer-container">
      <PageSection :darkTheme="false" maxWidth="ing-section-content">
        <template slot="section-content">
          <OfferDetails v-if="shouldShowStep1" />
          <OfferContracts v-if="shouldShowStep2" />
        </template>
      </PageSection>
      <PageSection :darkTheme="true" maxWidth="ing-section-content">
        <template slot="section-content">
          <SigningInfo />
        </template>
      </PageSection>
      <PageSection :darkTheme="false" maxWidth="ing-section-content">
        <template slot="section-content">
          <OfferContactUs />
        </template>
      </PageSection>
    </div>
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngAuthentication from '@/components/IngAuthentication';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import OfferDetails from '@/components/ingOffer/OfferDetails';
  import OfferContracts from '@/components/ingOffer/OfferContracts.vue';
  import PageLoader from '@/components/ingOrangeJuice/Structure/PageLoader';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import { userLogoutMixin } from '@/mixins/userLogoutMixin';
  import { TRACK_EVENTS } from '@/constants/WEBTREKK';
  import SigningInfo from '@/components/ingOffer/SigningInfo';
  import OfferContactUs from '@/components/ingOffer/OfferContactUs';
  import { mapState, mapMutations, mapActions } from 'vuex';

  export default {
    name: 'IngOfferPage',
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerJourney.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerJourney.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerJourney.metaDescription'),
          },
        ],
      };
    },
    components: {
      IngAuthentication,
      OfferContracts,
      SigningInfo,
      OfferDetails,
      OfferContactUs,
      TheHeader,
      TheFooter,
      IngButton,
      PageSection,
      PageLoader,
    },
    mixins: [webtrekkMixin, userLogoutMixin],
    props: {
      loanRequestId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('offer', ['error', 'currentStep']),
      ...mapState('contract', ['credit', 'micro_credit']),
      isUserUnauthorised() {
        return (
          (this.error && this.error.status === 401) ||
          (this.credit.getReqErrorCode &&
            this.credit.getReqErrorCode.status === 401) ||
          (this.micro_credit.getReqErrorCode &&
            this.micro_credit.getReqErrorCode.status === 401)
        );
      },
      shouldShowStep1() {
        return this.currentStep === 1;
      },
      shouldShowStep2() {
        return this.currentStep === 2;
      },
    },
    mounted() {
      this.getOffer();
    },
    methods: {
      ...mapMutations('offer', ['CLEAR_ERROR', 'SET_LOADING']),
      ...mapActions('offer', ['getOfferDetails']),
      async getOffer() {
        this.SET_LOADING(true);
        this.CLEAR_ERROR();
        await this.getOfferDetails();
        this.SET_LOADING(false);
        if (!this.isUserUnauthorised) {
          this.trackEvent(TRACK_EVENTS.BRIDGE_OFFER_UI);
        }
      },
    },
  };
</script>
<style lang="scss" scoped>
  .offer-container {
    flex: 1;
  }
</style>
