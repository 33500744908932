<template>
  <PageSection class="section-form not-found">
    <template #section-content>
      <img
        alt="Page Not Found"
        class="not-found__icon"
        src="/ing_not_found.svg"
      />
      <h3 class="not-found__title">
        {{ $t('the404Page.title') }}
      </h3>
      <i18n path="the404Page.description" tag="p" class="not-found__content">
        <a
          :href="`tel:${$translationConstantValues.theContact[container].contact.phone}`"
          place="phone"
          class="contact__link"
          >{{
            $translationConstantValues.theContact[container].contact.phone
          }}</a
        >
        <a
          :href="`mailto:${$translationConstantValues.theContact[container].contact.email}`"
          place="email"
          class="contact__link"
          >{{
            $translationConstantValues.theContact[container].contact.email
          }}</a
        >
      </i18n>
    </template>
  </PageSection>
</template>

<script>
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import APPLICATION_CHANNELS from '@/constants/APPLICATION_CHANNELS';

  export default {
    name: 'The404',
    components: {
      PageSection,
    },
    props: {
      container: {
        type: String,
        required: true,
        validator: (prop) =>
          [APPLICATION_CHANNELS.AMAZON, APPLICATION_CHANNELS.BRIDGE].includes(
            prop
          ),
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .not-found {
    max-width: 740px;
    margin: 0 auto;
    &__title {
      font-size: 40px;
      line-height: 56px;
      color: $Primary-Orange;
      padding: 24px 0;
    }
    &__content {
      font-size: 16px;
      line-height: 24px;
      color: $Primary-Grey400;
    }
  }
</style>
