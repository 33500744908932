<template>
  <div class="identification-issuing-authority-wrapper">
    <len-text-input
      v-model="identificationIssuingAuthority"
      :label="$t('forms.signupForm.identificationIssuingAuthority.label')"
      :error="
        errors[signerId] &&
        errors[signerId].identificationIssuingAuthority &&
        $t('forms.errors.' + errors[signerId].identificationIssuingAuthority)
      "
      :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
      name="identification-issuing-authority"
      @focus="identificationIssuingAuthorityHasFocus = true"
      @blur="identificationIssuingAuthorityHasFocus = false"
    />
    <tooltip
      :class="{
        'on-input-focus':
          identificationIssuingAuthorityHasFocus ||
          !!identificationIssuingAuthority,
      }"
      :tooltipMessage="
        $t('forms.signupForm.identificationIssuingAuthority.tooltip')
      "
      class="identification-issuing-authority-tooltip"
      messageClass="issuing-authority-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: { LenTextInput, Tooltip },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        identificationIssuingAuthorityHasFocus: false,
      };
    },
    computed: {
      identificationIssuingAuthority: {
        get() {
          return this.signers[this.signerId].identificationIssuingAuthority;
        },
        set(value) {
          this.SET_IDENTIFICATION_ISSUING_AUTHORITY({
            signerId: this.signerId,
            identificationIssuingAuthority: value,
          });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENTIFICATION_ISSUING_AUTHORITY']),
    },
  };
</script>

<style scoped lang="scss">
  .identification-issuing-authority-wrapper {
    position: relative;
    line-height: 1.75;
  }
  .identification-issuing-authority-tooltip {
    position: absolute;
    left: 170px;
    top: 10px;
    transition: all 0.2s ease;
    &.on-input-focus {
      top: -12px;
      left: 128px;
    }
  }
</style>
