/**
 * Calculate the first month repayment amount
 *
 * This is the first month interest rate with the repayment portion added. The
 * repayment portion is the disbursement amount multiplied by a fixed repayment rate
 * which is 8%. In future this will be gathered from the risk class.
 *
 * Note that the interest rate must be the decimal value. For example, if
 * the interest rate is 4.99 %, then then you need to convert to a number and
 * divide by 100.
 *
 * The amount is rounded to two decimal places.
 *
 * @param Number disbursementAmount
 * @param Number interestRate
 * @returns Number
 */
export const firstMonthRepaymentAmount = (disbursementAmount, interestRate) => {
  const amount =
    firstMonthInterestRate(disbursementAmount, interestRate) +
    Number(disbursementAmount) * 0.08;

  return roundToTwoDecimalPlaces(amount);
};

/**
 * Calculate the interest rate in the first month
 *
 * Note that the interest rate must be the decimal value. For example, if
 * the interest rate is 4.99 %, then then you need to convert to a number and
 * divide by 100.
 *
 * The amount is rounded to two decimal places.
 *
 * @param Number disbursementAmount
 * @param Number interestRate
 * @returns Number
 */
export const firstMonthInterestRate = (disbursementAmount, interestRate) => {
  return roundToTwoDecimalPlaces((disbursementAmount * interestRate) / 12);
};

/**
 * Rounds number to two decimal places properly
 *
 * See https://www.jacklmoore.com/notes/rounding-in-javascript/
 *
 * @param Number num
 * @returns Number
 */
const roundToTwoDecimalPlaces = (num) => +(Math.round(num + 'e+2') + 'e-2');
