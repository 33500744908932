<template>
  <div :data-unit-test="dataUnitTest">
    <ing-dropdown-input
      v-show="isNative"
      :name="safariAutocompleteOffName"
      :options="dropdownOptions"
      :value="country"
      :label="label"
      v-bind="$attrs"
      :placeholder="$t('ingDropdownDefault')"
      :error="error"
      :displayErrorIfSubmitted="displayError"
      @input="handleChange"
    />

    <div
      v-show="!isNative"
      v-click-outside="clickedOutside"
      class="vue-country-input"
    >
      <fieldset :class="{ error: displayError }">
        <div
          :class="{
            'is-focused': isFocused,
            'is-empty': !country,
          }"
          class="country-field-wrapper"
        >
          <label class="ing-label" v-html="label" />
          <div class="country-input-wrapper">
            <input
              ref="input"
              :value="country"
              :name="safariAutocompleteOffName"
              class="country-input form-control"
              :class="{ 'ing-input-error': displayError }"
              :placeholder="$t('ingDropdownDefault')"
              type="text"
              autocomplete="nofill"
              @focus="onFocus"
              @blur="onBlur"
              @input="onInput"
              @keydown="keyboardNav"
            />
            <ing-down-arrow class="icon" @click.native="onFocus" />
          </div>

          <div
            :class="{ open: open }"
            class="ing-country-dropdown"
            @keydown="keyboardNav"
          >
            <ul
              v-show="open"
              ref="list"
              :class="{ 'is-empty': filteredCountries.length === 0 }"
            >
              <li
                v-for="(pb, index) in filteredCountries"
                :key="pb.iso + (pb.preferred ? '-preferred' : '')"
                :class="getItemClass(index, pb.iso)"
                class="dropdown-item"
                @click="choose(pb)"
                @mousemove="selectedIndex = index"
              >
                <div
                  v-if="enabledFlags"
                  :class="pb.iso.toLowerCase()"
                  class="iti-flag"
                />
                <strong>{{ pb.name }} </strong>
              </li>
            </ul>
          </div>
        </div>
        <div :class="{ active: displayError }" role="alert" class="ing-error">
          <img src="/alert_icon.png" alt="Alert Icon" />
          <p>
            {{ error }}
          </p>
        </div>
      </fieldset>
    </div>
  </div>
</template>

<script>
  import IngDownArrow from '@/components/ing/ing-down-arrow';
  import IngDropdownInput from '@/components/ing/ing-dropdown-input';
  import { countryInputFunctionality } from '@/mixins/forms/countryInputMixin';
  import { countryDropdownInputFunctionality } from '@/mixins/forms/countryDropdownInputMixin';
  import MOBILE_USER_AGENT_REGEX from '@/constants/MOBILE_USER_AGENT_REGEX';
  import Window from '@/common/window';

  export default {
    components: { IngDropdownInput, IngDownArrow },
    mixins: [countryInputFunctionality, countryDropdownInputFunctionality],
    inheritAttrs: false,
    props: {
      dataUnitTest: {
        type: String,
        default: '',
      },
    },
    computed: {
      isNative: function () {
        return MOBILE_USER_AGENT_REGEX.test(Window.navigator.userAgent);
      },
    },
  };
</script>

<style scoped lang="scss">
  .country-field-wrapper,
  .country-input-wrapper {
    position: relative;
  }
  .country-input {
    &::placeholder {
      color: #333;
      opacity: 1;
    }
    &:-ms-input-placeholder {
      color: #333;
    }
    &::-ms-input-placeholder {
      color: #333;
    }
  }
  .icon {
    position: absolute;
    cursor: pointer;
    width: 14px;
    height: 8px;
    right: 12px;
    top: 15px;
  }
  .form-control {
    cursor: pointer;
  }
  .ing-country-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 100%;
    width: 100%;
    ul {
      padding: 0;
      margin: 0;
      text-align: left;
      list-style: none;
      max-height: 200px;
      overflow-y: scroll;
      background-color: #fff;
      border: 1px solid #ccc;
      width: 100%;
      z-index: 1;
    }
    .dropdown-item {
      cursor: pointer;
      padding: 4px 0px;
      display: flex;
      align-items: center;
    }
    .dropdown-item.highlighted {
      background-color: #f3f3f3;
    }
    .iti-flag {
      margin-right: 5px;
      margin-left: 5px;
    }
    li.last-preferred {
      border-bottom: 1px solid #cacaca;
    }
    li {
      min-height: 30px;
      padding: 4px 0;
    }
    strong {
      font-size: 16px;
      font-weight: bold;
    }
  }
</style>
