<template>
  <div class="company-name-wrapper">
    <ing-text-input-tooltip
      v-model="legalName"
      :label="$t('forms.signupForm.companyName.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="ing-legal-name"
      data-unit-test="ing-controlling-legal-name-field"
      :tooltipMessage="$t('forms.signupForm.companyName.tooltip')"
      messageClass="name-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';
  export default {
    components: {
      IngTextInputTooltip,
    },
    data() {
      return {
        legalNameHasFocus: false,
      };
    },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'controllingCompany',
      ]),
      legalName: {
        get() {
          return this.controllingCompany.legalName;
        },
        set(newValue) {
          this.SET_CONTROLLING_LEGAL_NAME(newValue);
        },
      },
      error() {
        const { controllingCompany } = this.errors;
        if (controllingCompany && controllingCompany.legalName) {
          return this.$t('forms.errors.' + controllingCompany.legalName);
        }
        return '';
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_CONTROLLING_LEGAL_NAME']),
    },
  };
</script>
