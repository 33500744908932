<template>
  <form
    name="clinetInfo"
    autocomplete="off"
    @submit.prevent="$emit('submit-button-clicked')"
  >
    <PageSection class="section-form" :darkTheme="true">
      <template #section-content>
        <FormSectionHeader section="signers.companyDetails" :paraCount="2" />
        <DetailsSection />
        <BusinessAddressSection />
        <MailingAddressSection />
        <ControllingCompanyDetailsSection />
        <CashIntensitySection />
        <SmeClassificationSection />
        <SubmitCompanyInfoSection
          @cancel-button-clicked="$emit('cancel-button-clicked')"
          @submit-button-clicked="$emit('submit-button-clicked')"
        />
      </template>
    </PageSection>
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
  </form>
</template>

<script>
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import DetailsSection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/DetailsSection';
  import BusinessAddressSection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/BusinessAddressSection';
  import MailingAddressSection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/MailingAddressSection';
  import ControllingCompanyDetailsSection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/ControllingCompanyDetailsSection';
  import CashIntensitySection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/CashIntensitySection';
  import SmeClassificationSection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/SmeClassificationSection';
  import SubmitCompanyInfoSection from '@/components/signers/CompanyInformation/CompanyInfomationEditForm/SubmitCompanyInfoSection';
  export default {
    components: {
      PageSection,
      FormSectionHeader,
      DetailsSection,
      BusinessAddressSection,
      MailingAddressSection,
      CashIntensitySection,
      ControllingCompanyDetailsSection,
      SmeClassificationSection,
      SubmitCompanyInfoSection,
    },
    props: {
      isInvalid: {
        type: Boolean,
      },
    },
  };
</script>
