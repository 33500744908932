import axios from 'axios';

export function getBSOfferDetails({ commit }, offerId) {
  const baseUrl = process.env.VUE_APP_FUNNEL_GATEWAY_API;

  return axios
    .get(`${baseUrl}/bs/offer/${offerId}`)
    .then(({ data }) => {
      if (data && data.details) {
        commit('SET_BS_OFFER', data);
      } else {
        commit('SET_BS_OFFER_DETAILS_ERROR', 'Offer not found');
      }
    })
    .catch(({ response }) => {
      if (response) {
        commit('SET_BS_OFFER_DETAILS_ERROR', response.data);
      }
    });
}

export async function verifySignature({ state }) {
  let result = null;

  await axios
    .get(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/bs/offer/verify?${state.blackSeaRedirectURLQueryString}`
    )
    .then(({ data }) => {
      result = data.result;
    })
    .catch(({ response }) => {
      const errorMessage = response.data.errorCode;
      result = errorMessage;
    });

  return result;
}
