import { render, staticRenderFns } from "./ing-firstname-field.vue?vue&type=template&id=21bfc4b3"
import script from "./ing-firstname-field.vue?vue&type=script&lang=js"
export * from "./ing-firstname-field.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports