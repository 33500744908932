<template>
  <div>
    <ing-offer-conditions-header />
    <ing-offer-conditions-details />
    <div v-if="isCreditLineProduct" class="explanatory-text">
      <p>
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.explanatoryText.first'
          )
        }}
      </p>
      <p>
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.explanatoryText.second'
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import IngOfferConditionsHeader from '@/components/ing/signers/ing-offer-conditions-information/ing-offer-conditions-header';
  import IngOfferConditionsDetails from '@/components/ing/signers/ing-offer-conditions-information/ing-offer-conditions-details';
  import { mapGetters } from 'vuex';
  export default {
    components: {
      IngOfferConditionsHeader,
      IngOfferConditionsDetails,
    },
    computed: {
      ...mapGetters('signers', ['isCreditLineProduct']),
    },
  };
</script>

<style scoped>
  .explanatory-text {
    font-size: 14px;
    line-height: 22px;
    color: #696969;
    padding-left: 40px;
    margin-top: 16px;
  }
</style>
