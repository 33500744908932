<template>
  <div class="display--inline-block grid__item col-xs-12">
    <p ref="middle" class="explanation">
      {{ $t('partnerSubmissionPageNew.form.legalInformation.explanation') }}
    </p>
  </div>
</template>

<script>
  import { goToElement } from '@/mixins/scrollMixin';
  export default {
    mixins: [goToElement],
    methods: {
      goTo() {
        this.goToElement('middle');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .explanation {
    font-size: 18px;
    line-height: 24px;
    color: #2e2e2e;
  }

  @media only screen and (max-width: $mobile-l) {
    .explanation {
      font-size: 16px;
      line-height: 18px;
    }
  }
</style>
