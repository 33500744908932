import Vue from 'vue';
import VueRouter from 'vue-router';
import { amazonRoutes, ingRoutes } from '@/router/routes';
import VueMeta from 'vue-meta';
import * as PathToRegexp from 'path-to-regexp';
import Window from '@/common/window';
import { sanitizePath } from '@/utils/stringUtilities';
import { RESOURCE_EXPIRED } from '@/constants/ERROR';
import { EXPIRED_OFFER, USED_OFFER } from '@/constants/OFFER_STATUS';

Vue.use(VueRouter);
Vue.use(VueMeta, {
  keyName: 'metaInfo', // the component option name that vue-meta looks for meta info on.
  attribute: 'data-vue-meta', // the attribute name vue-meta adds to the tags it observes
  ssrAttribute: 'data-vue-meta-server-rendered', // the attribute name that lets vue-meta know that meta info has already been server-rendered
  tagIDKeyName: 'vmid', // the property name that vue-meta uses to determine whether to overwrite or append a tag
});

export function createRouter() {
  let router = new VueRouter({
    mode: 'history',
    // base: __dirname,
    base: process.env.VUE_APP_ING_URL_PREFIX,
    routes: [...amazonRoutes, ...ingRoutes],
  });
  router.beforeEach(async (to, from, next) => {
    if (to.meta.static && Window.location) {
      Window.location = to.path;
      return;
    }

    const { $store } = router.app;
    await $store.dispatch('appSettings/setApplicationChannel', to.meta.channel);
    await $store.dispatch('appSettings/setApplicationPage', to.meta.page);

    const amazonProductRoute = await handleAmazonProductsRouting(to, $store);
    if (amazonProductRoute) {
      await router.replace(amazonProductRoute);
      return;
    }
    const path = sanitizePath(to.path);
    const normalizedPath = PathToRegexp.compile(path)(to.params);
    if (
      to.meta.page === 'client' &&
      process.env.VUE_APP_FEATURE_FLAG_MAINTENANCE_ENABLED === 'true'
    ) {
      next({ path: '/maintenance' });
    } else if (to.path !== normalizedPath) {
      next({ path: normalizedPath, query: to.query });
    } else {
      next();
    }
  });
  return router;
}
async function handleAmazonProductsRouting(to, $store) {
  if (
    to.name === 'amazon' ||
    to.name === 'amazon-flexkredit' ||
    to.name === 'amazon-firmenkredit'
  ) {
    const { offerId } = to.query;
    if (offerId) {
      if ($store.getters['blacksea/offerDetails'] === null) {
        try {
          await $store.dispatch('blacksea/getBSOfferDetails', offerId);

          const isTermLoan = $store.getters['blacksea/isTermLoan'];
          const isCreditLineLoan = $store.getters['blacksea/isCreditLineLoan'];

          if (isTermLoan) {
            return { name: 'amazon-firmenkredit', query: to.query };
          } else if (isCreditLineLoan) {
            return { name: 'amazon-flexkredit', query: to.query };
          } else {
            const { errorCode } = $store.getters['blacksea/getError'];
            const routeNames = {
              [RESOURCE_EXPIRED]: 'amazon-angebot-abgelaufen',
              [EXPIRED_OFFER]: 'amazon-angebot-abgelaufen',
              [USED_OFFER]: 'amazon-link-abgelaufen',
            };
            const routeName = routeNames[errorCode];
            if (routeName === null) {
              return;
            }
            return { name: routeName || 'amazon-technischer-fehler' };
          }
        } catch (e) {
          return { name: 'amazon-technischer-fehler' };
        }
      }
    }
  }
}

export default createRouter;
