import { isEmpty, isNotNumeric } from '../helpers/string';
import { isValidMobileNumber } from '../helpers/phone';

export default function ValidatePhone(mobilePhone) {
  if (isEmpty(mobilePhone)) {
    return { mobilePhone: 'PhoneIsRequired' };
  }
  if (isNotNumeric(mobilePhone)) {
    return { mobilePhone: 'PhoneIsNotCorrect' };
  }

  if (!isValidMobileNumber(mobilePhone)) {
    return { mobilePhone: 'PhoneIsNotCorrect' };
  }
  return { mobilePhone: undefined };
}
