<template>
  <len-text-input
    v-model="identificationIdNumber"
    :label="$t(`forms.signupForm.identificationIdNumber.${idTypeLabel}`)"
    :error="
      errors[signerId] &&
      errors[signerId].identificationIdNumber &&
      $t('forms.errors.' + errors[signerId].identificationIdNumber)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    name="tax-number"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      identificationIdNumber: {
        get() {
          return this.signers[this.signerId].identificationIdNumber;
        },
        set(value) {
          this.SET_IDENTIFICATION_ID_NUMBER({
            signerId: this.signerId,
            identificationIdNumber: value,
          });
        },
      },
      idTypeLabel() {
        return this.signers[this.signerId].identificationIdType || 'ID';
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENTIFICATION_ID_NUMBER']),
    },
  };
</script>

<style scoped></style>
