import { isEmpty } from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateUserStreet(userStreet) {
  if (isEmpty(userStreet)) {
    return { userStreet: 'UserStreetIsRequired' };
  }
  if (hasForbiddenCharacters(userStreet)) {
    return { userStreet: 'ForbiddenCharacters' };
  }

  return { userStreet: undefined };
}
