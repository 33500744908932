<template>
  <len-country-input
    id="controllingCountryOfIncorporationField"
    :label="$t('forms.signupForm.countryOfIncorporation.label')"
    :initialCountry="controllingCompany.countryOfIncorporation"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    :preferredCountries="['DE']"
    name="country-of-incorporartion"
    @change="onChange"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenCountryInput from '@/components/common/len-country-input';

  export default {
    components: { LenCountryInput },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'controllingCompany',
      ]),
      error() {
        if (
          this.errors.controllingCompany &&
          this.errors.controllingCompany.countryOfIncorporation
        ) {
          return this.$t(
            'forms.errors.' +
              this.errors.controllingCompany.countryOfIncorporation
          );
        }
        return '';
      },
    },
    methods: {
      onChange(iso) {
        this.SET_CONTROLLING_COUNTRY_OF_INCORPORATION(iso);
      },
      ...mapMutations('signers', ['SET_CONTROLLING_COUNTRY_OF_INCORPORATION']),
    },
  };
</script>
