<template>
  <div class="legal-status">
    <len-dropdown-input
      :value="legalStatus"
      name="legalStatus__c"
      :options="legalStatusOptions"
      maxVisibleOptions="5"
      :label="label"
      :error="errorMessage"
      :displayErrorIfSubmitted="displayError"
      @input="handleChange"
    />
    <div v-if="notSupported" class="legal-status__not-supported">
      {{ $t('forms.signupForm.legalStatus.warningMessage') }}
    </div>
  </div>
</template>

<script>
  import LenDropdownInput from '@/components/common/len-dropdown-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';
  import legalStatusInputMixin from '@/mixins/legalStatusInputMixin';

  export default {
    components: { LenDropdownInput },
    mixins: [lenFormFieldGTMTrackingMixin, legalStatusInputMixin],
  };
</script>

<style lang="scss">
  .legal-status {
    position: relative;

    &__not-supported {
      position: absolute;
      left: 0;
      top: 40px;
      font-size: 14px;
      line-height: 14px;
      color: #3c31c4;
      padding-left: 20px;
      background: url('~@/assets/img/len_info_icon.svg') 0 0 no-repeat;
    }
  }
</style>
