<template>
  <ing-text-input
    v-model="postalCity"
    :name="'postal-city-' + signerId"
    :label="$t('forms.signupForm.city.label')"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      postalCity: {
        get() {
          return this.signers[this.signerId].postalCity;
        },
        set(value) {
          this.SET_POSTAL_CITY({ signerId: this.signerId, postalCity: value });
        },
      },
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].postalCity &&
          this.$t('forms.errors.' + this.errors[this.signerId].postalCity)
        );
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_POSTAL_CITY']),
    },
  };
</script>
