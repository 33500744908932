import IngOldAmazonSignupPage from '@/views/ing/ing-borrower-signup-page';
import IngAmazonSignupPage from '@/views/IngAmazonSignupPage';
import IngOldAmazonSuccessPage from '@/views/ing/ing-borrower-thankyou-page';
import IngAmazonSuccessPage from '@/views/IngBorrowerSuccessPage';
import IngSignersMissingInfoForm from '@/views/ing/ing-signers-missing-form-page';
import IngSignersMissingInfoSuccess from '@/views/ing/ing-signers-success-page';
import IngSignersMissingInfoLogoutPage from '@/views/ing/ing-signers-logout-page';
import IngOfferPage from '@/views/ing/ing-offer-page';
import IngOfferLogoutPage from '@/views/ing/ing-offer-logout-page';
import IngIdentPage from '@/views/ing/ing-ident-page';
import IngTechnicalErrorPage from '@/views/ing/ing-technical-error-page';
import IngOfferExpiredErrorPage from '@/views/ing/ing-offer-expired-error-page';
import IngOfferAlreadyUsedErrorPage from '@/views/ing/ing-offer-already-used-page';
import IngSignContractSuccessPage from '@/views/IngSignContractSuccessPage';
import IngSignContractFailurePage from '@/views/IngSignContractFailurePage';

import APPLICATION_CHANNELS from '@/constants/APPLICATION_CHANNELS';
import APPLICATION_PAGE from '@/constants/APPLICATION_PAGE';

// !*! --------------------------- INFO -------------------------- !*!
//
//    please refer the route object structure, before making any
//    modifications. Key-value pairs 'channel' & 'page' in 'meta'
//    are inserted using the 'Array.map' method.
//
// !*! ---------------- Route Object - Reference ----------------- !*!
//
//      {
//        path: '/url/path/to/page',
//        name: 'name-of-the-route',
//        component: ComponentPageName,
//        props: Boolean,
//        meta: { channel: CONSTANT.CHANNEL, page: CONSTANT.PAGE },
//      },
//
// !*! --------------------------- X ----------------------------- !*!

const amazonApplicationRoutes = [
  {
    path: '/amazon',
    name: 'amazon',
    component: IngOldAmazonSignupPage,
    props: true,
  },
  {
    path: '/amazon/firmenkredit',
    name: 'amazon-firmenkredit',
    component: IngAmazonSignupPage,
    props: true,
  },
  {
    path: '/amazon/firmenkredit/danke',
    name: 'amazon-firmenkredit-danke',
    component: IngAmazonSuccessPage,
    props: true,
  },
  {
    path: '/amazon/flexkredit',
    name: 'amazon-flexkredit',
    component: IngAmazonSignupPage,
    props: true,
  },
  {
    path: '/amazon/flexkredit/danke',
    name: 'amazon-flexkredit-danke',
    component: IngAmazonSuccessPage,
    props: true,
  },
].map((route) => ({
  ...route,
  meta: { page: APPLICATION_PAGE.ANTRAG },
}));

const amazonClientUIRoutes = [
  {
    path: '/amazon/firmenkredit/:opportunityId/vorschlag',
    name: 'amazon-firmenkredit-vorschlag',
    component: IngSignersMissingInfoForm,
    props: true,
  },
  {
    path: '/amazon/firmenkredit/:opportunityId/vorschlag/danke',
    name: 'amazon-firmenkredit-vorschlag-danke',
    component: IngSignersMissingInfoSuccess,
  },
  {
    path: '/amazon/firmenkredit/:opportunityId/vorschlag/logout',
    name: 'amazon-firmenkredit-vorschlag-logout',
    component: IngSignersMissingInfoLogoutPage,
  },
  {
    path: '/amazon/flexkredit/:opportunityId/vorschlag',
    name: 'amazon-flexkredit-vorschlag',
    component: IngSignersMissingInfoForm,
    props: true,
  },
  {
    path: '/amazon/flexkredit/:opportunityId/vorschlag/danke',
    name: 'amazon-flexkredit-vorschlag-danke',
    component: IngSignersMissingInfoSuccess,
  },
  {
    path: '/amazon/flexkredit/:opportunityId/vorschlag/logout',
    name: 'amazon-flexkredit-vorschlag-logout',
    component: IngSignersMissingInfoLogoutPage,
  },
].map((route) => ({
  ...route,
  meta: { page: APPLICATION_PAGE.CLIENT },
}));

const amazonOfferUIRoutes = [
  {
    path: '/amazon/firmenkredit/:loanRequestId/vertrag',
    name: 'amazon-firmenkredit-vertrag',
    component: IngOfferPage,
    props: true,
  },
  {
    path: '/amazon/firmenkredit/:loanRequestId/vertrag/logout',
    name: 'amazon-firmenkredit-vertrag-logout',
    component: IngOfferLogoutPage,
    props: true,
  },
  {
    path: '/amazon/firmenkredit/vertrag/danke',
    props: true,
    name: 'amazon-firmenkredit-vertrag-danke',
    component: IngSignContractSuccessPage,
  },
  {
    path: '/amazon/firmenkredit/vertrag/fehler',
    props: true,
    name: 'amazon-firmenkredit-vertrag-fehler',
    component: IngSignContractFailurePage,
  },

  {
    path: '/amazon/flexkredit/:loanRequestId/vertrag',
    name: 'amazon-flexkredit-vertrag',
    component: IngOfferPage,
    props: true,
  },
  {
    path: '/amazon/flexkredit/:loanRequestId/vertrag/logout',
    name: 'amazon-flexkredit-vertrag-logout',
    component: IngOfferLogoutPage,
    props: true,
  },
  {
    path: '/amazon/flexkredit/vertrag/danke',
    props: true,
    name: 'amazon-flexkredit-vertrag-danke',
    component: IngSignContractSuccessPage,
  },
  {
    path: '/amazon/flexkredit/vertrag/fehler',
    props: true,
    name: 'amazon-flexkredit-vertrag-fehler',
    component: IngSignContractFailurePage,
  },
].map((route) => ({
  ...route,
  meta: { page: APPLICATION_PAGE.OFFER },
}));

const amazonErrorsRoutes = [
  {
    path: '/amazon/technischer-fehler',
    name: 'amazon-technischer-fehler',
    component: IngTechnicalErrorPage,
    props: false,
  },
  {
    path: '/amazon/angebot-abgelaufen',
    name: 'amazon-angebot-abgelaufen',
    component: IngOfferExpiredErrorPage,
    props: false,
  },
  {
    path: '/amazon/link-abgelaufen',
    name: 'amazon-link-abgelaufen',
    component: IngOfferAlreadyUsedErrorPage,
    props: false,
  },
].map((route) => ({
  ...route,
  meta: { page: APPLICATION_PAGE.ERROR },
}));

const existingRoutes = [
  {
    path: '/ready',
    name: 'ing-borrower-thankyou-page',
    component: IngOldAmazonSuccessPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.ANTRAG,
    },
  },
  {
    path: '/signers/form/:opportunityId',
    name: 'ing-signers-missing-info-form',
    component: IngSignersMissingInfoForm,
    props: true,
    meta: {
      page: APPLICATION_PAGE.CLIENT,
    },
  },
  {
    path: '/signers/form/logout/:opportunityId',
    name: 'ing-signers-missing-info-form-logout',
    component: IngSignersMissingInfoLogoutPage,
    meta: {
      page: APPLICATION_PAGE.CLIENT,
    },
  },
  {
    path: '/signers/success',
    name: 'ing-signers-missing-info-success',
    component: IngSignersMissingInfoSuccess,
    meta: {
      page: APPLICATION_PAGE.CLIENT,
    },
  },
  {
    path: '/vertragsunterlagen/:loanRequestId',
    name: 'ing-offer-page',
    component: IngOfferPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
  {
    path: '/vertragsunterlagen/logout/:loanRequestId',
    name: 'ing-offer-page-logout',
    component: IngOfferLogoutPage,
    props: true,
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
  {
    path: '/legitimation/',
    name: 'ing-ident-page',
    component: IngIdentPage,
    props: (route) => ({ referenceNumber: route.query.referenceID }),
    meta: {
      page: APPLICATION_PAGE.OFFER,
    },
  },
  {
    path: '/technical-error',
    name: 'ing-technical-error-page',
    component: IngTechnicalErrorPage,
    props: false,
    meta: {
      page: APPLICATION_PAGE.ERROR,
    },
  },
  {
    path: '/offer-expired',
    name: 'ing-offer-expired-error-page',
    component: IngOfferExpiredErrorPage,
    props: false,
    meta: {
      page: APPLICATION_PAGE.ERROR,
    },
  },
  {
    path: '/offer-already-used',
    name: 'ing-offer-already-used-error-page',
    component: IngOfferAlreadyUsedErrorPage,
    props: false,
    meta: {
      page: APPLICATION_PAGE.ERROR,
    },
  },
];

export default [
  ...amazonApplicationRoutes,
  ...amazonClientUIRoutes,
  ...amazonOfferUIRoutes,
  ...amazonErrorsRoutes,
  ...existingRoutes,
].map((route) => ({
  ...route,
  meta: { ...route.meta, channel: APPLICATION_CHANNELS.AMAZON },
}));
