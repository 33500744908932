<template>
  <div class="company-iban-wrapper">
    <ing-text-input
      v-model="iban"
      :label="$t('forms.signupForm.iban.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-iban"
      data-unit-test="ing-company-iban-field"
      :tooltipMessage="$t('forms.signupForm.iban.tooltip')"
      messageClass="iban-tooltip-message"
      :maskOptions="maskOptions"
      @input="iban = forceDEformat($event)"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import {
    IBAN_FORMAT,
    IBAN_LENGTH_WITH_SPACES,
    DEFAULT_IBAN_VALUE,
  } from '@/constants/IBAN_FORMAT';
  import {
    getFullIbanWithSpaces,
    removeSpacesFromIban,
    forceDEformat,
    addSpacesToIban,
  } from '@/utils/formatIban';
  export default {
    components: { IngTextInput },
    data() {
      return {
        ibanValue: DEFAULT_IBAN_VALUE,
        maskOptions: {
          mask: IBAN_FORMAT,
          maxLength: IBAN_LENGTH_WITH_SPACES,
        },
      };
    },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
      iban: {
        get() {
          return this.ibanValue;
        },
        set(value) {
          this.ibanValue = getFullIbanWithSpaces(value);
          this.SET_COMPANY_IBAN(removeSpacesFromIban(value));
        },
      },
      error() {
        return (
          this.errors.company &&
          this.errors.company.iban &&
          this.$t('forms.errors.' + this.errors.company.iban)
        );
      },
    },
    mounted() {
      const ibanWithSpaces = this.addSpacesToIban(this.company.iban);
      this.ibanValue = this.forceDEformat(ibanWithSpaces);
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_IBAN']),
      forceDEformat,
      addSpacesToIban,
    },
  };
</script>

<style lang="scss" scoped>
  .company-iban-wrapper {
    position: relative;
  }
</style>
