import {
  isEmpty,
  hasWrongLength,
  isNotNumeric,
} from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateUserPostCodeDe(userPostcodeDe) {
  if (isEmpty(userPostcodeDe)) {
    return { userPostcodeDe: 'UserPostCodeDeIsRequired' };
  }
  if (hasForbiddenCharacters(userPostcodeDe)) {
    return { userPostcodeDe: 'ForbiddenCharacters' };
  }
  if (isNotNumeric(userPostcodeDe)) {
    return { userPostcodeDe: 'UserPostcodeDeIsNAN' };
  }
  if (hasWrongLength(userPostcodeDe, 5)) {
    return { userPostcodeDe: 'UserPostcodeDeIsInvalidLength' };
  }

  return { userPostcodeDe: undefined };
}
