<template>
  <div class="partner-policy-container">
    <p>{{ $t('partner.application.form.termsSection.para1') }}</p>
    <br />
    <i18n path="partner.application.form.termsSection.para2.text" tag="p">
      <a
        :href="$translationConstantValues.privacyPolicyUrl"
        place="linkText"
        target="_blank"
        >{{ $t('partner.application.form.termsSection.para2.linkText') }}</a
      >
      <em place="boldContent">
        {{ $t('partner.application.form.termsSection.para2.boldContent') }}
      </em>
    </i18n>
    <br />
    <i18n path="partner.application.form.termsSection.para3.text" tag="p">
      <a
        href="#"
        place="linkText1"
        @click.prevent="openModal('CreditreformModal')"
        >{{ $t('partner.application.form.termsSection.para3.linkText1') }}</a
      >
      <a href="#" place="linkText2" @click.prevent="openModal('SchufaModal')">{{
        $t('partner.application.form.termsSection.para3.linkText2')
      }}</a>
    </i18n>
    <CreditReformModal v-if="isCreditreformModalOpen" @onClose="closeModal" />
    <SchufaModal v-if="isSchufaModalOpen" @onClose="closeModal" />
  </div>
</template>

<script>
  import CreditReformModal from '@/components/CreditReformModal';
  import SchufaModal from '@/components/SchufaModal';

  export default {
    components: { CreditReformModal, SchufaModal },
    data() {
      return {
        isSchufaModalOpen: false,
        isCreditreformModalOpen: false,
      };
    },
    methods: {
      openModal(target) {
        this[`is${target}Open`] = true;
      },
      closeModal(target) {
        this[`is${target}Open`] = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .partner-policy-container {
    padding-top: 24px;
  }
  .modal-inner-content {
    font-size: 16px;
    line-height: 20px;
    color: #333;
    letter-spacing: -0.02em;
    padding-bottom: 50px;
  }
  .modal-inner-header {
    text-transform: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
</style>
