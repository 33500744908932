<template>
  <div>
    <div class="edit-button-container" @click="$emit('edit-button-clicked')">
      <len-icon icon="edit-pencil" iconSvg="false" />
      <p>{{ $t('signersMissingInfo.formPage.edit') }}</p>
    </div>
  </div>
</template>

<script>
  import LenIcon from '@/components/common/len-icon';
  export default {
    components: {
      LenIcon,
    },
  };
</script>

<style scoped lang="scss">
  @import '../../sass/custom/_settings.scss';
  .edit-button-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    cursor: pointer;
    align-items: baseline;
    p {
      font-size: 16px;
      color: $petroleum;
      margin-left: 10px;
    }
  }
</style>
