<template>
  <div id="app">
    <router-view class="view" />
  </div>
</template>
<script>
  import { mapMutations } from 'vuex';
  import { isBlackSeaMixin } from '@/mixins/blackSeaMixin';

  let buildStyles = (contentBaseUrl) => {
    return `.ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_444444_256x240.png"); }.ui-widget-content .ui-icon, .ui-widget-header .ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_444444_256x240.png"); }.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_555555_256x240.png"); }.ui-button:hover .ui-icon, .ui-button:focus .ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_555555_256x240.png"); }.ui-state-active .ui-icon, .ui-button:active .ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_ffffff_256x240.png"); }.ui-state-highlight .ui-icon, .ui-button .ui-state-highlight.ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_777620_256x240.png"); }.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_cc0000_256x240.png"); }.ui-button .ui-icon {background-image: url("${contentBaseUrl}/images/ui-icons_777777_256x240.png"); }
            .file-loading {background: transparent url("${contentBaseUrl}/images/loading.gif") top left no-repeat;}.file-thumb-loading {background: transparent url("${contentBaseUrl}/images/loading.gif") no-repeat scroll center center content-box !important; }.file-uploading {background: url("${contentBaseUrl}/images/loading-sm.gif") no-repeat center bottom 10px;}
            .filters dl.dropdown:after,.filters table.dropdown:after,.loan-calculator dl.dropdown:after,.loan-calculator table.dropdown:after {background-image: url("${contentBaseUrl}/images/img-arrow_down-white.svg");}
            .white-section .filters dl.dropdown:after,.white-section .filters table.dropdown:after {background-image: url("${contentBaseUrl}/images/img-arrow_down-dark.svg");}
            .main-header .our-logo-pf-logo::after {background-image: URL("${contentBaseUrl}/images/PF_Logo/pflogo-black-400px-DE.png");}
            .main-header .our-logo-pf-logo-en::after {background-image: URL("${contentBaseUrl}/images/PF_Logo/pflogo-black-400px-EN.png");}
            span.com, span.at, span.de, span.es, span.pl, span.nl, span.ch, span.za, span.br {background-image: url("${contentBaseUrl}/images/flags_all.png");}
            .checklist span.icon-ticked {background-image: url("${contentBaseUrl}/images/img-tick-dark.svg"); }.checklist span.icon-ticked.green {background-image: url("${contentBaseUrl}/images/img-tick-green.svg"); }.checklist span.icon-crossed {background-image: url("${contentBaseUrl}/images/img-cross-dark.svg"); }.checklist span.icon-crossed.green {background-image: url("${contentBaseUrl}/images/img-cross-green.svg"); }
            .checklist.dark span.icon-ticked {background-image: url("${contentBaseUrl}/images/img-tick-white.svg");}.checklist.dark span.icon-ticked.green {background-image: url("${contentBaseUrl}/images/img-tick-green.svg"); }.checklist.dark span.icon-crossed {background-image: url("${contentBaseUrl}/images/img-cross-white.svg"); }.checklist.dark span.icon-crossed.green {background-image: url("${contentBaseUrl}/images/img-cross-green.svg"); }
            .checklist.grey span.icon-ticked {background-image: url("${contentBaseUrl}/images/img-tick-dark.svg");}.checklist.grey span.icon-ticked.green {background-image: url("${contentBaseUrl}/images/img-tick-green.svg"); }.checklist.grey span.icon-crossed {background-image: url("${contentBaseUrl}/images/img-cross-dark.svg"); }.checklist.grey span.icon-crossed.green {background-image: url("${contentBaseUrl}/images/img-cross-green.svg"); }
            .filters dl.dropdown:after,.filters table.dropdown:after, .loan-calculator dl.dropdown:after,.loan-calculator table.dropdown:after {background-image: url("${contentBaseUrl}/images/img-arrow_down-white.svg");}
            .main-table td:first-child:after {background-image: url("${contentBaseUrl}/images/img-arrow_down-dark.svg");}
            .submenu.tabs nav .tab.dropdown:after {background-image: url("${contentBaseUrl}/images/img-arrow_down-white.svg");}
            .submenu.tabs nav .submenu-toggler {background-image: url("${contentBaseUrl}/images/img-arrow_down-white.svg");}
            .documents-state {background-image: url("${contentBaseUrl}/images/verification_status_sprite.png");`;
  };

  export default {
    name: 'App',
    mixins: [isBlackSeaMixin],
    computed: {
      contentUrlWithDe() {
        return `${process.env.VUE_APP_LENDICO_BASE_URL_CONTENT}/de`;
      },
      contentBaseUrlWithFavicon() {
        return `${this.contentUrlWithDe}/images/favicon`;
      },
      lendicoMeta() {
        return [
          { vmid: 'robots', name: 'robots', content: 'noindex' },
          {
            name: 'msapplication-config',
            content: `${this.contentBaseUrlWithFavicon}/browserconfig.xml`,
          },
          { name: 'msapplication-TileColor', content: '#ffffff' },
          {
            name: 'msapplication-TileImage',
            content: `${this.contentBaseUrlWithFavicon}/ms-icon-144x144.png`,
          },
          { name: 'theme-color', content: '#ffffff ' },
        ];
      },
      ingMeta() {
        return [
          { vmid: 'robots', name: 'robots', content: 'noindex' },
          { name: 'msapplication-config', content: 'none' },
          { name: 'msapplication-TileColor', content: '#fafafa' },
          {
            name: 'msapplication-TileImage',
            content: '/ing_favicons/apple-touch-icon-144x144.png',
          },
          { name: 'theme-color', content: '#ff6200' },
        ];
      },
      lendicoIcons() {
        return [
          {
            rel: 'apple-touch-icon',
            sizes: '57x57',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-57x57.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '60x60',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-60x60.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '72x72',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-72x72.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '76x76',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-76x76.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '114x114',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-114x114.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '120x120',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-120x120.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '144x144',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-144x144.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '152x152',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-152x152.png`,
          },
          {
            rel: 'apple-touch-icon',
            sizes: '180x180',
            href: `${this.contentBaseUrlWithFavicon}/apple-icon-180x180.png`,
          },
          {
            rel: 'icon',
            sizes: '192x192',
            type: 'image/png',
            href: `${this.contentBaseUrlWithFavicon}/android-icon-192x192.png`,
          },
          {
            rel: 'icon',
            sizes: '32x32',
            type: 'image/png',
            href: `${this.contentBaseUrlWithFavicon}/favicon-32x32.png`,
          },
          {
            rel: 'icon',
            sizes: '96x96',
            type: 'image/png',
            href: `${this.contentBaseUrlWithFavicon}/favicon-96x96.png`,
          },
          {
            rel: 'icon',
            sizes: '16x16',
            type: 'image/png',
            href: `${this.contentBaseUrlWithFavicon}/favicon-16x16.png`,
          },
          {
            rel: 'manifest',
            href: `${this.contentBaseUrlWithFavicon}/manifest.json`,
          },
        ];
      },
      ingIcons() {
        return [
          {
            rel: 'apple-touch-icon',
            sizes: '57x57',
            href: '/ing_favicons/apple-touch-icon-57x57.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '60x60',
            href: '/ing_favicons/apple-touch-icon-60x60.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '72x72',
            href: '/ing_favicons/apple-touch-icon-72x72.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '76x76',
            href: '/ing_favicons/apple-touch-icon-76x76.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '114x114',
            href: '/ing_favicons/apple-touch-icon-114x114.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '120x120',
            href: '/ing_favicons/apple-touch-icon-120x120.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '144x144',
            href: '/ing_favicons/apple-touch-icon-144x144.png',
          },
          {
            rel: 'apple-touch-icon',
            sizes: '152x152',
            href: '/ing_favicons/apple-touch-icon-152x152.png',
          },
          {
            rel: 'icon',
            sizes: '32x32',
            type: 'image/png',
            href: '/ing_favicons/favicon-32x32.png',
          },
          {
            rel: 'icon',
            sizes: '96x96',
            type: 'image/png',
            href: '/ing_favicons/favicon-96x96.png',
          },
          {
            rel: 'icon',
            sizes: '16x16',
            type: 'image/png',
            href: '/ing_favicons/favicon-16x16.png',
          },
          {
            rel: 'icon',
            sizes: '128x128',
            type: 'image/png',
            href: '/ing_favicons/favicon-128x128.png',
          },
          {
            rel: 'icon',
            sizes: '196x196',
            type: 'image/png',
            href: '/ing_favicons/favicon-196x196.png',
          },
          { rel: 'manifest', href: '/ing_favicons/manifest.json' },
        ];
      },
      metaTags() {
        if (this.isBlackSea) {
          return this.ingMeta;
        } else {
          return this.lendicoMeta;
        }
      },
      linkTags() {
        if (this.isBlackSea) {
          return this.ingIcons;
        } else {
          return this.lendicoIcons;
        }
      },
    },
    async mounted() {
      if (process.env.NODE_ENV === 'production' && !window.wt) {
        // this is to initiate the tracking and exposing wt object in the window object if it's not defined
        document.wt && document.wt.trackPage('');
      }
    },
    beforeMount() {
      /*
      use old (lendico) gateway url if ING app is loaded on old urls (lendico domain)
      to fix the login issue on client and offer ui (ACQ-1290)
      So, we replace VUE_APP_FUNNEL_GATEWAY_API with the OLD base URL which is pointing to lendico      
      */
      // TODO: this is a temporary fix and we would remove it once we are fully ING
      const { origin } = window.location || {};
      if (this.isBlackSea && origin.includes('lendico')) {
        this.USE_OLD_FUNNEL_GATEWAY_API_URL();
      }
    },
    methods: {
      ...mapMutations('config', ['USE_OLD_FUNNEL_GATEWAY_API_URL']),
    },
    metaInfo() {
      return {
        titleTemplate: (title) => {
          if (this.isBlackSea) {
            return title ? `${title} - ING` : 'ING';
          } else {
            return title ? `${title} | Lendico` : 'Lendico';
          }
        },
        meta: this.metaTags,
        link: this.linkTags,
        script: this.scriptTags,
        style: [
          {
            cssText: buildStyles(this.contentUrlWithDe),
            type: 'text/css',
          },
        ],
      };
    },
  };
</script>
