import * as actions from './contract-actions';

const initialState = {
  credit: {
    type: '',
    fileName: '',
    data: '',
    contractGetReqReady: false,
    getReqErrorCode: null,
  },
  blanket_assignment: {
    type: '',
    fileName: '',
    data: '',
    contractGetReqReady: false,
    getReqErrorCode: null,
  },
  power_of_attorney: {
    type: '',
    fileName: '',
    data: '',
    contractGetReqReady: false,
    getReqErrorCode: null,
  },
  micro_credit: {
    type: '',
    fileName: '',
    data: '',
    contractGetReqReady: false,
    getReqErrorCode: null,
  },
  guarantee: {
    type: '',
    fileName: '',
    data: '',
    contractGetReqReady: false,
    getReqErrorCode: null,
  },
};

const getters = {};

const mutations = {
  SET_CREDIT: (state, result) => {
    state.credit.type = result.type;
    state.credit.data = result.data;
    state.credit.fileName = result.fileName;
    state.credit.contractGetReqReady = true;
  },
  SET_CREDIT_ERROR: (state, error) => {
    state.credit.getReqErrorCode = error;
  },
  SET_BLANKET_ASSIGNMENT: (state, result) => {
    state.blanket_assignment.type = result.type;
    state.blanket_assignment.data = result.data;
    state.blanket_assignment.fileName = result.fileName;
    state.blanket_assignment.contractGetReqReady = true;
  },
  SET_BLANKET_ASSIGNMENT_ERROR: (state, error) => {
    state.blanket_assignment.getReqErrorCode = error;
  },
  SET_POWER_OF_ATTORNEY: (state, result) => {
    state.power_of_attorney.type = result.type;
    state.power_of_attorney.data = result.data;
    state.power_of_attorney.fileName = result.fileName;
    state.power_of_attorney.contractGetReqReady = true;
  },
  SET_POWER_OF_ATTORNEY_ERROR: (state, error) => {
    state.power_of_attorney.getReqErrorCode = error;
  },
  SET_MICRO_CREDIT: (state, result) => {
    state.micro_credit.type = result.type;
    state.micro_credit.data = result.data;
    state.micro_credit.fileName = result.fileName;
    state.micro_credit.contractGetReqReady = true;
  },
  SET_MICRO_CREDIT_ERROR: (state, error) => {
    state.micro_credit.getReqErrorCode = error;
  },
  SET_GUARANTEE: (state, result) => {
    state.guarantee.type = result.type;
    state.guarantee.data = result.data;
    state.guarantee.fileName = result.fileName;
    state.guarantee.contractGetReqReady = true;
  },
  SET_GUARANTEE_ERROR: (state, error) => {
    state.guarantee.getReqErrorCode = error;
  },
  CLEAR_CONTRACT(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};
const state = () => JSON.parse(JSON.stringify(initialState));
export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
