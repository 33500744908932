import * as actions from '@/vuex/appSettings/actions';
import * as getters from '@/vuex/appSettings/getters';
import { mutations } from '@/vuex/appSettings/mutations';

const state = {
  channel: null,
  page: null,
};

export default { state, actions, getters, mutations, namespaced: true };
