<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.962 18.734C12.962 18.932 12.875 19.024 12.657 19.024H11.131C10.927 19.024 10.839 18.932 10.839 18.734V17.144C10.839 16.947 10.927 16.854 11.131 16.854H12.657C12.875 16.854 12.962 16.947 12.962 17.144V18.734ZM11.203 8.02401H12.599C12.803 8.02401 12.875 8.11501 12.875 8.29901L12.672 15.205C12.672 15.357 12.613 15.403 12.454 15.403H11.377C11.218 15.403 11.159 15.357 11.159 15.205L10.927 8.29901C10.927 8.11501 11 8.02401 11.203 8.02401ZM21.532 17.964L13.697 4.24101C12.841 2.74001 10.83 2.97101 10.105 4.24201L2.27001 17.965C1.49601 19.323 2.47601 21.024 4.06601 21.024H19.735C21.339 21.024 22.301 19.313 21.532 17.964Z"
      fill="#FF6200"
    />
  </svg>
</template>
<script>
  export default {
    name: 'IconWarning',
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  svg {
    width: px2rem(18);
    height: px2rem(18);
  }
</style>
