<template>
  <div class="ing-text-input">
    <label v-if="label" class="ing-text-input__label" v-html="label" />
    <div v-if="caption" class="ing-text-input__caption" v-html="caption" />
    <input
      ref="self"
      class="ing-text-input__element"
      :class="[{ 'ing-text-input__element--error': displayError }]"
      :disabled="disabled"
      data-lpignore="true"
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
    />
    <FieldStatus v-if="displayError">
      <template #error>
        <slot name="error">
          {{ error }}
        </slot>
      </template>
    </FieldStatus>
    <FieldStatus v-if="displayInfo">
      <template #info>
        <slot name="info">
          {{ info }}
        </slot>
      </template>
    </FieldStatus>
  </div>
</template>

<script>
  import IMask from 'imask';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import {
    IBAN_FORMAT,
    IBAN_LENGTH_WITH_SPACES,
  } from '@/constants/IBAN_FORMAT';

  export default {
    name: 'IbanInput',
    components: { FieldStatus },
    inheritAttrs: false,
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      info: {
        type: String,
        default: null,
      },
      error: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: '',
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        isFocused: false,
        isDirty: false,
        maskRef: null,
      };
    },
    computed: {
      hasError() {
        const error = this.$slots.error || this.error;
        return !!error && error.length > 0;
      },
      displayInfo() {
        return this.$slots.info || this.info;
      },
      displayError() {
        const result = this.hasError && !this.isFocused;
        if (this.displayErrorIfDirty) {
          return this.isDirty && result;
        }
        return result;
      },
    },
    watch: {
      value(newVal) {
        if (newVal !== this.maskRef.value) {
          this._updateValue();
        }
      },
    },
    mounted() {
      this._initMask();
    },
    beforeDestroy() {
      //TODO: find a way to properly destroy the input, "destroy" is not a valid function
      this.maskRef = null;
    },
    methods: {
      _initMask() {
        const maskOptions = {
          mask: IBAN_FORMAT,
          maxLength: IBAN_LENGTH_WITH_SPACES,
          overwrite: true,
        };
        this.maskRef = new IMask(this.$refs.self, maskOptions).on(
          'accept',
          this.onAccept.bind(this)
        );
        this._updateValue();
      },
      onAccept() {
        this.$emit('input', this.maskRef.value);
      },
      _updateValue() {
        this.maskRef.value = this.value == null ? '' : this.value;
      },
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;

        this.$emit('focus');
      },
      onBlur() {
        setTimeout(() => {
          this.isFocused = false;
          this.$emit('blur');
        }, 200); // On some browsers (Chrome macOS) it's impossible to click on suggestions without this
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ing-text-input {
    position: relative;
    align-items: center;
    width: 100%;
    font-family: inherit;
    padding-bottom: px2rem(33px);
    &__element {
      font-family: inherit;
      font-weight: bold;
      background: $Primary-white;
      padding: 8px 12px;
      border: 1px solid $Primary-Grey400;
      box-shadow: inset 0 2px 2px $Primary-Grey200;
      border-radius: 4px;
      width: 100%;
      outline: none;
      box-sizing: border-box;
      color: $Primary-Grey600;
      height: px2rem(40px);
      font-size: px2rem(16px);
      line-height: px2rem(24px);
      &:focus {
        border: 3px solid $Secondary-Indigo500;
        padding: 6px 10px;
      }
      &:disabled {
        border: 1px solid $Primary-Grey200;
        background: $Primary-Grey100;
      }
      &--textarea {
        height: px2rem(80px);
      }
      &--error {
        border: 3px solid $Functional-Minus500;
        padding: 6px 10px;
      }
    }
    &__label {
      display: block;
      font-family: inherit;
      color: $Primary-Grey600;
      font-weight: 400;
      font-size: px2rem(16px);
      line-height: px2rem(24px);
    }
    &__caption {
      font-family: inherit;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      margin-bottom: 4px;
      color: $Primary-Grey400;
      text-align: left;
      width: 100%;
    }
  }
</style>
