<template>
  <ing-text-input
    v-model="email"
    :label="$t('forms.signupForm.emailAddress.label')"
    name="email"
    :error="error"
    :maxlength="320"
    data-qa-id="ident-email"
    data-unit-test="ing-ident-email-field"
    :displayErrorIfDirty="!submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      ...mapState('ident', ['ident', 'errors', 'submitClicked']),
      email: {
        get() {
          return this.ident.email;
        },
        set(value) {
          this.SET_EMAIL(value);
        },
      },
      error() {
        return (
          this.errors &&
          this.errors.email &&
          this.$t(`forms.errors.${this.errors.email}`)
        );
      },
    },
    methods: {
      ...mapMutations('ident', ['SET_EMAIL']),
    },
  };
</script>

<style scoped>
  :deep(.ing-error) {
    height: fit-content;
  }
</style>
