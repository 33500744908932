<template>
  <div
    :class="{ alert: isInvalid, 'dark-background': isOddSigner }"
    class="overview-container flex--column"
  >
    <p
      v-show="isFirstSigner"
      class="overview"
      v-html="$t('signersMissingInfo.formPage.clientsSection.overview.p')"
    />
    <div class="signer-header-wrapper">
      <div>
        <h3>
          {{ genderValue }} {{ signerInformation.firstName }}
          {{ signerInformation.lastName }}
        </h3>
        <p class="roles-wrapper">
          {{ roleValue }}
        </p>
      </div>
      <ing-edit-button
        class="edit-button-alignment"
        @edit-button-clicked="$emit('edit-button-clicked')"
      />
    </div>
    <div class="signer-information-container">
      <div class="signer-column-container">
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.salutation.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ genderValue | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.title.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ titleValue | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.firstName.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.firstName | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.lastName.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.lastName | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.lastNameAtBirth.shortLabel') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.lastNameAtBirth | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.dob.shortLabel') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.birthday
                | momentjsFormat('L')
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.countryOfBirth.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.countryOfBirth
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.cityOfBirth.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.cityOfBirth | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.nationality.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.nationality
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.secondNationality.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.secondNationality
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.email.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.email | emptyValueReplacer }}
          </p>
        </div>
      </div>
      <div class="signer-column-container">
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.userCurrentAddress.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ currentAddress }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.postalAddress.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ postalAddress }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.phone.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.mobilePhone | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.identificationIdType.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ idTypevalue | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationIdNumber.ID') }}/{{
                $t('forms.signupForm.identificationIdNumber.PASSPORT')
              }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.identificationIdNumber | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationIssuingAuthority.label') }}:
            </em>
            <ing-bs-tooltip
              key="identification-issuing-authority"
              :tooltipMessage="
                $t('forms.signupForm.identificationIssuingAuthority.tooltip')
              "
            />
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationIssuingAuthority
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationPlaceOfIssue.label') }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationPlaceOfIssue
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationIssuingDate.label') }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationIssuingDate
                | momentjsFormat('L')
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationExpiryDate.label') }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationExpiryDate
                | momentjsFormat('L')
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.taxNumber.label') }}:</em>
            <ing-bs-tooltip
              key="tax-number"
              :tooltipMessage="$t('forms.signupForm.taxNumber.tooltip')"
            />
          </p>
          <p class="col-sm-6">
            {{ signerInformation.taxNumber | emptyValueReplacer }}
          </p>
        </div>
      </div>
    </div>
    <p
      v-show="shouldShowMissingUBOsSentence"
      class="missing-ubo-sentence col-md-8 col-sm-9 col-xs-12"
    >
      {{
        $t('signersMissingInfo.formPage.clientsSection.overview.missingUbos')
      }}
      <a :href="'mailto:' + $t('ingEmail')" class="ing-link">{{
        $t('ingEmail')
      }}</a>
    </p>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { genderValues } from '@/validators/helpers/gender';
  import { idTypeValues } from '@/validators/helpers/identificationIdType';
  import { titleValues } from '@/validators/helpers/title';
  import { roleValues } from '@/validators/helpers/role';
  import IngEditButton from '@/components/ing/signers/ing-edit-button';
  import { isRegularLoanRequest } from '@/mixins/signersMixin';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  export default {
    components: {
      IngEditButton,
      IngBsTooltip,
    },
    mixins: [isRegularLoanRequest],
    props: {
      signerId: {
        type: String,
        default: '',
      },
      signerIndex: {
        type: Number,
        default: 0,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        genderValues,
        idTypeValues,
        titleValues,
        roleValues,
      };
    },
    computed: {
      ...mapState('signers', ['signers']),
      signerInformation() {
        return this.signers[this.signerId];
      },
      isFirstSigner() {
        return this.signerIndex === 0;
      },
      isOddSigner() {
        return this.signerIndex % 2;
      },
      isLastSigner() {
        return this.signerIndex === Object.keys(this.signers).length - 1;
      },
      shouldShowMissingUBOsSentence() {
        return (
          this.isLastSigner &&
          (this.isRegularLoanRequest || this.isPartnerChannel)
        );
      },
      currentAddress() {
        if (this.signerInformation.street) {
          const { street, streetNumber, zip, city, country } =
            this.signerInformation;
          return `${street} ${streetNumber},\r\n ${zip} ${city}, ${country}`;
        } else {
          return '-';
        }
      },
      postalAddress() {
        if (this.signerInformation.postalStreet) {
          const {
            postalStreet,
            postalStreetNumber,
            postalZip,
            postalCity,
            postalCountry,
          } = this.signerInformation;
          return `${postalStreet} ${postalStreetNumber}, ${postalZip} ${postalCity}, ${postalCountry}`;
        } else if (this.signerInformation.homeAddressIsPostalAddress) {
          return this.currentAddress;
        } else {
          return '-';
        }
      },
      genderValue() {
        return Object.keys(this.genderValues).find(
          (key) => this.genderValues[key] === this.signerInformation.gender
        );
      },
      titleValue() {
        return Object.keys(this.titleValues).find(
          (key) => this.titleValues[key] === this.signerInformation.title
        );
      },
      idTypevalue() {
        return Object.keys(this.idTypeValues).find(
          (key) =>
            this.idTypeValues[key] ===
            this.signerInformation.identificationIdType
        );
      },
      roleValue() {
        let deRoleValues = this.signerInformation.roles.map((role) => {
          return Object.keys(this.roleValues).find(
            (key) => this.roleValues[key] === role
          );
        });
        return deRoleValues.filter((role) => !!role).join(', ');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/settings';
  @import '../../../../sass/custom/variables';
  h3 {
    font-size: 19px;
    font-weight: bold;
  }
  p {
    font-size: 14px;
    line-height: 21px;
    color: $darkerGrey;
  }
  .missing-ubo-sentence {
    padding: 40px 40px 0 0;
    a {
      color: $petroleum;
    }
  }
  .overview {
    font-size: 16px;
    line-height: 20px;
    color: $ing-gray;
    margin-bottom: 40px;
  }
  .roles-wrapper {
    margin-bottom: 40px;
    margin-top: 10px;
    color: $ing-gray;
  }
  .signer-header-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .signer-column-container {
    flex-grow: 1;
    word-break: break-all;
  }

  .signer-information-container {
    font-size: 14px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
  }
  .overview-container {
    &.alert {
      border: solid 1px $red;
    }
    &.dark-background {
      border: none;
      background-color: $ing-signer-section-background;
      &.alert {
        border: solid 1px $red;
      }
    }
  }
  @media (max-width: 1024px) {
    .signer-information-container {
      flex-direction: column;
    }
  }
  @media (max-width: 476px) {
    .signer-header-wrapper {
      flex-direction: column;
      margin-bottom: 20px;
    }
    .roles-wrapper {
      margin-bottom: 10px;
    }
  }
</style>
