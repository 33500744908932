import moment from 'moment';
import '@/overrides/moment/de';
import Window from '@/common/window';

/**
 * Creates an instance of moment from moment package.
 *
 * @param {string|int} value
 * @param {string} initialFormat
 * @return {object}
 */
export function momentjs(value, initialFormat) {
  if (!value) {
    return '';
  }

  if (initialFormat) {
    return moment(value, initialFormat);
  }

  return moment(value);
}

/**
 * Implements format function from moment package.
 *
 * @param {string|int} value
 * @param {string} format
 * @param {string} initialFormat
 * @return {string}
 */
export function momentjsFormat(value, format, initialFormat) {
  if (!value) {
    return '';
  }

  if (initialFormat) {
    const initialDate = moment(value, initialFormat, true);

    if (initialDate.isValid()) {
      return moment(value, initialFormat).format(format);
    }
    return value;
  }
  return moment(value).format(format);
}

/**
 * Implements humanized duration function from moment package.
 *
 * @param {string|int} value
 * @param {string} format
 * @param {string} suffix
 * @return {string}
 */
export function momentjsDuration(value, format, suffix) {
  if (!value) {
    return '';
  }

  return moment.duration(parseInt(value), format).humanize(suffix);
}

/**
 * Install function.
 *
 * @param {Object} Vue
 */
export default function install(Vue) {
  Window.moment = moment;
  Vue.filter('momentjsFormat', momentjsFormat);
  Vue.filter('momentjsDuration', momentjsDuration);
}
