export default {
  partnerSubmissionPageNew: {
    metaTitle: ' Partnerantrag Lendico Deutschland GmbH',
    metaDescription:
      'Der Firmenkredit von Lendico ermöglicht kleinen und mittelgroßen Unternehmen eine schnelle, einfache und günstige Finanzierung.',
    googleSiteVerification: 'xMdLCDCPcbxtmgT-2lq9FCHF-T8iLwnYOVcxADQe2XI',
    title: '<strong>Jetzt online Ihre Partneranfrage stellen</strong>',
    supportSection: {
      title: 'Unser Team ist persönlich für Sie da',
      description: {
        first:
          'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail.',
        second:
          'Unsere Kundenbetreuung ist <strong>Montag bis Freitag von 9 bis 18 Uhr</strong> für Sie da:',
      },
      phone: '030 229 572 84',
      union: 'oder',
      email: 'partnerantrag@lendico.de',
    },
    informationHeader: {
      title: 'Partnerinformationen',
      explanation1:
        'Bitte machen Sie hier Angaben' +
        ' zu Ihrer eigenen Person. ' +
        'Beachten Sie, dass Sie nur mit Bevollmächtigung' +
        ' des zu finanzierenden Unternehmens eine Kreditanfrage' +
        ' bei Lendico stellen können. Sie müssen über',
      explanation2: ' registriert sein, um eine Anfrage einreichen zu können.',
    },
    form: {
      partnerCompanyName: {
        label: 'Partnername',
      },
      partnerFirstName: {
        label: 'Ansprechpartner - Vorname',
      },
      partnerLastName: {
        label: 'Ansprechpartner - Nachname',
      },
      partnerEmail: {
        label: 'Ansprechpartner - E-Mail',
      },
      partnerSubmissionButton: {
        label: 'Weiter zur Anfrage',
      },
      partnerHelpMessage: {
        label:
          'Sie benötigen Hilfe oder haben eine Frage? Kontaktieren Sie uns unter:',
        phone: '030 229 572 84',
      },
      changePartnerButton: {
        label: 'Partnerdaten ändern',
      },
      companyHeader: {
        title: 'Unternehmensprofil',
        explanation:
          'Vervollständigen Sie hier bitte das Profil des von Ihnen vertretenen Unternehmens.',
      },
      company: {
        label: 'Firmennamen',
      },
      representativeHeader: {
        title: 'Kreditnehmerdaten',
        explanation:
          'Bitte geben Sie hier alle Details zum Antragsteller des von Ihnen vertretenen Unternehmens ein.',
      },
      legalInformation: {
        explanation: `Durch die von Ihnen angegebenen Daten kann die Lendico Deutschland GmbH Ihrem/-r Mandanten/-in -
        falls die Kreditprüfung positiv ausfällt - ein unverbindliches Kreditangebot unterbreiten. Bitte beachten Sie,
        dass dieser Kreditantrag kostenlos und unverbindlich ist. Der/die Antragssteller/in ist nicht zu einer Annahme
        einer Finanzierung durch die Lendico Deutschland GmbH verpflichtet. Die Lendico Deutschland GmbH hat zudem keine
        Pflicht, dem/der Antragsteller/in eine Finanzierung anzubieten.`,
      },
      privacyPolicy: {
        preHighlightedText: 'Mit dem Klicken auf ',
        highlightedText: '„Anfragen“',
        preLinkTitleText: ` bestätige ich, mit umfassender Bevollmächtigung des von mir vertretenen Unternehmens zu
        handeln. Ich versichere, dass ich befugt bin, den `,
        linkTitleText: ' Allgemeinen Geschäftsbedingungen für Kreditnehmer ',
        linkTitle: 'agb',
        preLinkPageText: ` zuzustimmen. Zusätzlich versichere ich, dass das mich bevollmächtigende Unternehmen sowie
        die von der Datenverarbeitung betroffenen Personen, die Datenschutzerklärungen der  `,
        linkPageText: 'Lendico Deutschland GmbH',
        link: 'datenschutz',
        preLinkDocumentText: ' sowie der ',
        linkDocumentText: 'ING-DiBa AG',
        linkIng: 'https://ing.de/dokumente/datenschutz-geschaeftskunden/',
        postLinkText: ' zur Kenntnis genommen hat.',
        disclaimer: {
          p1: 'Abschließend bestätige ich, dass dem mich bevollmächtigenden Unternehmen sowie den von der Datenverarbeitung betroffenen Personen bekannt ist, dass die Lendico Deutschland GmbH im Rahmen der sog. Autofill-Funktion sowie zum Zwecke einer Vorabprüfung Informationen von der',
          p2: 'erhält.',
          p3: 'Zur Beurteilung der Bonität übermittelt Lendico im Auftrag der ING Daten an die',
          p4: 'und die',
          p5: 'bzw. es werden Daten von den genannten Stellen abgefragt.',
          cta1Text: 'Creditreform',
          cta2Text: 'SCHUFA',
          schufaModalContent: {
            title: 'SCHUFA',
            content:
              'Die Lendico Deutschland GmbH übermittelt im Auftrag der ING-DiBa AG im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die <b>SCHUFA Holding AG</b>, Kormoranweg 5, 65201 Wiesbaden (im Folgenden "SCHUFA"). Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 lit. b und Artikel 6 Absatz 1 lit. f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 lit. f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING-DiBa AG oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.\n' +
              '<br /><br /><b>Der Kunde befreit die ING insoweit auch vom Bankgeheimnis.</b><br /><br />\n' +
              'Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht oder Standardvertragsklauseln vereinbart wurden, die unter www.schufa.de eingesehen werden können) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter <a href="https://www.ing.de/datenschutz/schufa" target="_blank" class="base-link">www.ing.de/datenschutz/schufa</a> eingesehen werden.\n',
          },
          creditreFormModalContent: {
            title: 'Creditreform',
            content:
              'Im Rahmen der sog. Autofill-Funktion und zum Zwecke einer Vorabprüfung erhält Lendico relevante Informationen (z.B. Adressdaten, Informationen) von der <b>Creditreform Berlin Brandenburg Wolfram GmbH & Co. KG;</b> Karl-Heinrich-Ulrichs-Straße 1, 10787 Berlin (im Folgenden „Crefo“). Nach erfolgreicher Vorabprüfung übermittelt Lendico im Auftrag der ING Daten (Name, Adresse und gegebenenfalls Geburtsdatum) zum Zwecke der Bonitätsprüfung an die Crefo. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.\n' +
              '<br /><br /><b>Der Kunde befreit die ING insoweit auch vom Bankgeheimnis.</b><br /><br />\n' +
              'Detaillierte Informationen zur Crefo im Sinne des Artikels 14 DSGVO, d.h. Informationen zum Geschäftszweck, zu Zwecken der Datenspeicherung, zu den Datenempfängern, zum Selbstauskunftsrecht, zum Anspruch auf Löschung oder Berichtigung etc. können online unter <a href="https://www.creditreform.de/berlin/datenschutz" target="_blank" class="base-link">Datenschutz | Creditreform Berlin</a> eingesehen werden.',
          },
        },
      },
    },
  },
  partnerSubmissionThankyouPage: {
    mainHeader: 'Vielen Dank für Ihre Anfrage',
    experianFlowSubHeader:
      'Wir freuen uns über Ihr Interesse an Lendico und melden uns schnellstmöglich mit weiteren Informationen bei Ihnen.',
    content: {
      topText1: 'Wir bestätigen den Eingang Ihrer Anfrage für die Firma ',
      topText2:
        'Bitte senden Sie die benötigten Unterlagen Ihres Mandanten per E-Mail an ',
      data: {
        header: 'Ihre Daten im Überblick',
        sectionHeader1: 'Partnerinformation',
        partnerSection: {
          partnerCompanyName: 'Partnername:',
          partnerFirstName: 'Vorname:',
          partnerLastName: 'Nachname:',
          partnerEmail: 'E-Mail:',
        },
        sectionHeader2: 'Kreditnehmerdaten',
        prefix: 'Anrede:',
        firstName: 'Vorname:',
        lastName: 'Nachname:',
        dob: 'Geburtsdatum:',
        email: 'E-Mail:',
        tel: 'Telefonnummer:',
        address: 'Adresse:',
        sectionHeader3: 'Kreditdetails',
        loanAmount: 'Betrag:',
        year: 'Jahre',
        loanDuration: 'Laufzeit:',
        sectionHeader4: 'Firmeninformationen',
        firmName: 'Firmenname:',
        firmStartDate: 'Gründungsdatum:',
        firmAddress: 'Adresse:',

        h4: 'Wie geht es weiter?',
        ol1p1:
          'Schicken Sie uns bitte folgende Unterlagen als PDF-Dateien per E-Mail an',
        sul1p1:
          '• <b>Jahresabschlüsse</b> der letzten zwei Geschäftsjahre ({currentYearMinusTwo} und {currentYearMinusOne}). Sofern für {currentYearMinusOne} keine finalen Dokumente vorliegen, senden Sie uns bitte Ihre <b>Betriebswirtschaftliche Auswertung</b> mit Summen- und Saldenliste.',
        sul1p2:
          '• <b>Betriebswirtschaftliche</b> Auswertung mit Summen- und Saldenliste von {currentYear}, nicht älter als drei Monate.',
        sul1p3: '• <b>Kontoauszüge</b> der letzten 90 Tage.',
        ol1p2:
          'Sobald Ihre Unterlagen vollständig sind, senden wir Ihnen <b>in wenigen Tagen</b> einen kostenfreien und unverbindlichen Finanzierungsvorschlag.',
        ol1p3:
          'Wenn Ihr Kunde den Finanzierungsvorschlag annimmt, schicken wir ihm eine E-Mail mit allen Informationen zur Identifizierung.',
        ol1p4:
          'Nachdem die Identifizierung Ihres Kunden erfolgt ist, erhält dieser die Vertragsunterlagen zur Unterzeichnung.',
        ol1p5:
          'Das Geld wird dann <b>direkt auf das Konto Ihres Kunden</b> überwiesen.',
      },
    },
  },
  becomePartnerForm: {
    form: {
      header: {
        main: 'Jetzt Partner werden!',
        subHeader: '<span class="sup">*</span>Dieses Feld ist erforderlich.',
      },
      company: {
        label: 'Unternehmen<span class="sup">*</span>',
      },
      gender: {
        label: 'Anrede',
        dropdown: {
          m: 'Herr',
          f: 'Frau',
        },
      },
      firstName: {
        label: 'Vorname<span class="sup">*</span>',
      },
      lastName: {
        label: 'Nachname<span class="sup">*</span>',
      },
      email: {
        label: 'E-Mail<span class="sup">*</span>',
      },
      phone: {
        label: 'Telefonnummer<span class="sup">*</span>',
      },
      postcodeDe: {
        label: 'PLZ (Firma)<span class="sup">*</span>',
      },
      city: {
        label: 'Stadt (Firma)<span class="sup">*</span>',
      },
      message: {
        label: 'Nachricht',
        placeholder: 'Hier Ihren Text einfugen!',
      },
      privacyPolicy: {
        label:
          'Ich bestätige, dass ich sowie alle (weiteren) im Handelsregister ' +
          'eingetragenen Geschäftsführer und Gesellschafter die <a rel="noopener noreferrer" target="_blank" href="https://www.lendico.de/datenschutz/dienstleister" class="link">Datenschutzerklärung</a> ' +
          'der Lendico Deutschland GmbH für Kooperationspartner zur Kenntnis genommen haben.<span class="sup">*</span>',
      },
      submit: {
        label: 'Senden',
      },
      success: {
        title: 'Vielen Dank!',
        text: 'Wir haben Ihre Anfrage erhalten und<br/>melden uns in Kürze bei Ihnen.',
      },
    },
  },
};
