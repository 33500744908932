<template>
  <div class="addMaterialDesign">
    <div class="checkbox">
      <label>
        <input v-model="businessAddressIsPostalAddress" type="checkbox" />
        <span class="checkbox-material">
          <span class="check" />
        </span>
        <p class="label-text">
          {{ $t('forms.signupForm.postalAddress.checkbox.company') }}
        </p>
      </label>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapState('signers', ['company']),
      businessAddressIsPostalAddress: {
        get() {
          return this.company.businessAddressIsPostalAddress;
        },
        set(newValue) {
          this.SET_IS_COMPANY_POSTAL_ADDRESS_SAME(newValue);
          this.handleAddressChange(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_IS_COMPANY_POSTAL_ADDRESS_SAME',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'CLEAR_COMPANY_POSTAL_ADDRESS',
      ]),
      handleAddressChange(newAddressvalue) {
        if (newAddressvalue) {
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        } else {
          this.CLEAR_COMPANY_POSTAL_ADDRESS();
        }
      },
    },
  };
</script>

<style scoped>
  .label-text {
    line-height: 22px;
  }
</style>
