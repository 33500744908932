<template>
  <fieldset>
    <div ref="container" class="form-group">
      <label
        :for="'lastNameAtBirth-' + signerId"
        class="ing-label last-name-birth-label"
      >
        {{ $t('forms.signupForm.lastNameAtBirth.label') }}
      </label>
      <div class="radio-input-container">
        <len-radio
          v-model="isLastNameAtBirthSame"
          :label="$t('forms.signupForm.lastNameAtBirth.dropdown.yes')"
          :name="'lastNameAtBirth-' + signerId"
          class="last-name-birth-radio"
          value="true"
          :hasError="!!error"
        />
        <len-radio
          v-model="isLastNameAtBirthSame"
          :label="$t('forms.signupForm.lastNameAtBirth.dropdown.no')"
          :name="'lastNameAtBirth-' + signerId"
          class="last-name-birth-radio"
          value="false"
          :hasError="!!error"
        />
        <ing-text-input
          v-model="lastNameAtBirth"
          :error="error"
          :displayErrorMessage="false"
          :displayErrorIfDirty="
            !submitClicked && !signers[signerId].submitClicked
          "
          :class="{
            hidden: !isLastNameAtBirthSame || isLastNameAtBirthSame === 'true',
          }"
          :placeholder="$t('forms.signupForm.lastNameAtBirth.placeholder')"
          type="text"
        />
      </div>
      <div :class="{ active: error }" role="alert" class="ing-error">
        <img src="/alert_icon.png" alt="Alert Icon" />
        <p>
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import IngTextInput from '@/components/ing/ing-text-input';
  export default {
    components: {
      LenRadio,
      IngTextInput,
    },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isLastNameAtBirthSame: null,
      };
    },
    computed: {
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].lastNameAtBirth &&
          this.$t('forms.errors.' + this.errors[this.signerId].lastNameAtBirth)
        );
      },
      lastNameAtBirth: {
        get() {
          return this.signers[this.signerId].lastNameAtBirth;
        },
        set(value) {
          this.SET_LAST_NAME_AT_BIRTH({
            signerId: this.signerId,
            lastNameAtBirth: value,
          });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    watch: {
      isLastNameAtBirthSame(newValue) {
        if (newValue === 'true') {
          this.lastNameAtBirth = '';
        }
      },
    },
    mounted() {
      if (this.lastNameAtBirth) {
        this.isLastNameAtBirthSame = 'false';
      }
    },
    methods: {
      ...mapMutations('signers', ['SET_LAST_NAME_AT_BIRTH']),
    },
  };
</script>

<style scoped lang="scss">
  .last-name-birth-label {
    margin-bottom: 0;
  }
  .radio-input-container {
    display: flex;
    height: 41px;
  }
  :deep(.last-name-birth-radio) {
    margin-right: 20px;
    padding-top: 7px;
    text-align: center;
  }
  .validation {
    margin-bottom: 0px;
  }
  .hidden {
    visibility: hidden;
  }
  :deep(.ing-error) {
    height: fit-content;
    line-height: 15px;
  }
</style>
