<template>
  <FormSectionHeader section="signers.termsAgreement" :paraCount="0">
    <template #form-content>
      <i18n tag="p" path="signers.termsAgreement.para1.description">
        <template #boldText>
          <em>{{ $t('signers.termsAgreement.para1.boldText') }}</em>
        </template>
      </i18n>
      <p class="disclaimer_info">
        {{ $t('signers.termsAgreement.para2') }}
      </p>
    </template>
  </FormSectionHeader>
</template>
<script>
  import { mapState } from 'vuex';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';

  export default {
    components: { FormSectionHeader },
    computed: {
      ...mapState('signers', ['submitClicked', 'errors']),
    },
    methods: {
      getError(field) {
        return (
          this.errors &&
          this.errors[field] &&
          this.$t('forms.errors.' + this.errors[field])
        );
      },
      displayError() {
        return this.submitClicked;
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .disclaimer_info {
    padding-top: px2rem(33px);
    font-weight: bold;
  }
</style>
