import { mapGetters } from 'vuex';
import range from 'lodash.range';

export const isAmountWithinRange = {
  computed: {
    ...mapGetters('appSettings', ['isAmazonApplication']),
  },
  methods: {
    isAmountWithinRange(amount) {
      const {
        VUE_APP_REGULAR_MIN_AMOUNT,
        VUE_APP_REGULAR_MAX_AMOUNT,
        VUE_APP_BLACKSEA_MIN_AMOUNT,
        VUE_APP_BLACKSEA_MAX_AMOUNT,
      } = process.env;
      if (this.isAmazonApplication) {
        return (
          parseInt(amount) >= +VUE_APP_BLACKSEA_MIN_AMOUNT &&
          parseInt(amount) <= +VUE_APP_BLACKSEA_MAX_AMOUNT
        );
      } else {
        return (
          parseInt(amount) >= +VUE_APP_REGULAR_MIN_AMOUNT &&
          parseInt(amount) <= +VUE_APP_REGULAR_MAX_AMOUNT
        );
      }
    },
  },
};

export const isLengthWithinRange = {
  methods: {
    isLengthWithinRange(length) {
      const { VUE_APP_BLACKSEA_MAX_DURATION, VUE_APP_REGULAR_MAX_DURATION } =
        process.env;
      const oneYearInMonths = 12;
      const maxDuration = this.isAmazonApplication
        ? +VUE_APP_BLACKSEA_MAX_DURATION
        : +VUE_APP_REGULAR_MAX_DURATION;
      const maxDurationPlusOneYear = maxDuration + oneYearInMonths;
      const validDurationsRange = range(
        oneYearInMonths,
        maxDurationPlusOneYear,
        oneYearInMonths
      );
      return validDurationsRange.includes(parseInt(length));
    },
  },
};

export const isDisbursementAmountWithinRange = {
  methods: {
    isDisbursementAmountWithinRange(disbursementAmount, loanAmount) {
      if (this.isAmazonApplication) {
        return (
          parseInt(disbursementAmount) >= 0 &&
          parseInt(disbursementAmount) <= loanAmount
        );
      }
    },
  },
};
