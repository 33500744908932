export function formatIBAN(iban) {
  if (!iban) {
    return;
  }
  if (iban.length !== 22 || !/^[A-Z]{2}\d+$/.test(iban)) {
    return iban;
  }
  const regex = /^(\w{4})(\w{4})(\w{4})(\w{4})(\w{4})(\w{2})$/;

  return iban.replace(regex, '$1 $2 $3 $4 $5 $6');
}

export default function install(Vue) {
  Vue.filter('formatIBAN', formatIBAN);
}
