import moment from 'moment';

export const isInvalidDate = (date, format) => {
  const parsed = moment(date, format, true);
  return !parsed.isValid();
};

export const isPastDate = (date, format) => {
  if (isInvalidDate(date, format)) {
    return false;
  }
  const parsed = moment(date, format, true);
  const today = moment();
  return parsed.isSameOrBefore(today, 'day');
};

export const isDateAboveMinAge = (date, format) => {
  if (isInvalidDate(date, format)) {
    return false;
  }
  const minAge = 18;
  const parsed = moment(date, format, true);
  return parsed.clone().add(minAge, 'years').isSameOrBefore(moment(), 'day');
};

export const isDatePastExcludingToday = (date, format) => {
  const parsed = moment(date, format, true);
  const today = moment();
  return parsed.isBefore(today, 'day');
};
