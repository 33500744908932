import { render, staticRenderFns } from "./IngAmazonTermsAndConditions.vue?vue&type=template&id=1d3a178a&scoped=true"
import script from "./IngAmazonTermsAndConditions.vue?vue&type=script&lang=js"
export * from "./IngAmazonTermsAndConditions.vue?vue&type=script&lang=js"
import style0 from "./IngAmazonTermsAndConditions.vue?vue&type=style&index=0&id=1d3a178a&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d3a178a",
  null
  
)

export default component.exports