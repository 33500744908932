<template>
  <div :class="{ disabled: disabled }" class="vue-tel-input addMaterialDesign">
    <div
      v-click-outside="clickedOutside"
      :class="{ open: open, 'change-country-disabled': !canChangeCountry }"
      class="dropdown"
      tabindex="0"
      @click="toggleDropdown"
      @keydown="keyboardNav"
      @keydown.esc="reset"
    >
      <span class="selection">
        <div
          v-if="enabledFlags"
          :class="activeCountry && activeCountry.iso.toLowerCase()"
          class="iti-flag"
        />
        <span v-if="canChangeCountry" class="dropdown-arrow">
          {{ open ? '▲' : '▼' }}
        </span>
      </span>
      <ul v-show="open" ref="list">
        <li
          v-for="(pb, index) in sortedCountries"
          :key="pb.iso + (pb.preferred ? '-preferred' : '')"
          :class="getItemClass(index, pb.iso)"
          class="dropdown-item"
          @click="choose(pb)"
          @mousemove="selectedIndex = index"
        >
          <div
            v-if="enabledFlags"
            :class="pb.iso.toLowerCase()"
            class="iti-flag"
          />
          <strong>{{ pb.name }} </strong>
          <span>&#8203;</span>
          <span>+{{ pb.prefix }}</span>
        </li>
      </ul>
    </div>
    <len-text-input
      ref="input"
      v-model="phone"
      :name="'mobile-phone-' + signerId"
      :label="$t('forms.signupForm.phone.label')"
      :error="
        errors[signerId] &&
        errors[signerId].mobilePhone &&
        $t('forms.errors.' + errors[signerId].mobilePhone)
      "
      :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
      inputmode="numeric"
      @focus="activeInput = true"
      @blur="activeInput = false"
    />
  </div>
</template>

<script>
  import { parsePhoneNumber } from 'libphonenumber-js';
  import allCountriesPrefixes from '@/components/common/assets/all-countries-phone-prefix';
  import getCountry from '@/components/common/assets/default-country';
  import metadata from 'libphonenumber-js/metadata.full.json';
  import LenTextInput from '@/components/common/len-text-input';
  import { mapState, mapMutations } from 'vuex';
  import { getNumberWithoutLeadingZeros } from '@/validators/helpers/phone';

  export default {
    name: 'VueTelInput',
    components: {
      LenTextInput,
    },
    props: {
      signerId: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      disabledFetchingCountry: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      preferredCountries: {
        type: Array,
        default: () => [],
      },
      invalidMsg: {
        default: '',
        type: String,
      },
      required: {
        type: Boolean,
        default: false,
      },
      defaultCountry: {
        type: String,
        default: '',
      },
      enabledFlags: {
        type: Boolean,
        default: true,
      },
      canChangeCountry: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        allCountriesPrefixes,
        unformattedNumber: null,
        activeInput: false,
        activeCountry: { iso: '' },
        open: false,
        selectedIndex: null,
        typeToFindInput: '',
        typeToFindTimer: null,
      };
    },
    computed: {
      phone: {
        get() {
          if (this.activeInput) {
            return this.removePrefix(this.signers[this.signerId].mobilePhone);
          } else {
            return this.signers[this.signerId].mobilePhone;
          }
        },
        set(value) {
          this.unformattedNumber = value;
        },
      },

      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      sortedCountries() {
        // Sort the list countries: from preferred countries to all countries
        const preferredCountries = this.preferredCountries
          .map((country) => this.findCountry(country))
          .filter(Boolean)
          .map((country) => ({ ...country, preferred: true }));

        return [...preferredCountries, ...this.allCountriesPrefixes];
      },
    },
    watch: {
      activeInput(value) {
        if (!value && this.unformattedNumber) {
          this.SET_MOBILE_PHONE({
            signerId: this.signerId,
            mobilePhone: this.formatResult(),
          });
        } else if (!value && !this.unformattedNumber) {
          this.SET_MOBILE_PHONE({
            signerId: this.signerId,
            mobilePhone: this.unformattedNumber,
          });
        }
      },
    },
    mounted() {
      this.initializeCountry();
    },
    methods: {
      ...mapMutations('signers', ['SET_MOBILE_PHONE']),

      processNumberWithPrefix(numberWithPrefix) {
        const phoneNumber = parsePhoneNumber(numberWithPrefix, metadata);
        if (phoneNumber && phoneNumber.country) {
          this.activeCountry = this.findCountry(phoneNumber.country);
          this.unformattedNumber = this.removePrefix(numberWithPrefix);
        } else {
          this.unformattedNumber = numberWithPrefix;
        }
      },
      formatResult() {
        // Calculate phone number based on mode
        if (!this.allCountriesPrefixes || !this.unformattedNumber) {
          return '';
        }

        if (this.unformattedNumber.charAt(0) === '+') {
          this.processNumberWithPrefix(this.unformattedNumber);
        }
        if (this.activeCountry.iso === 'DE') {
          this.unformattedNumber = getNumberWithoutLeadingZeros(
            this.unformattedNumber
          );
        }
        return '+' + this.activeCountry.prefix + this.unformattedNumber;
      },
      initializeCountry() {
        const phoneFromLRM = this.signers[this.signerId].mobilePhone;
        if (phoneFromLRM) {
          this.processNumberWithPrefix(phoneFromLRM);
          return;
        }
        /**
         * 1. Use default country if passed from parent
         */
        if (this.defaultCountry) {
          const defaultCountry = this.findCountry(this.defaultCountry);
          if (defaultCountry) {
            this.activeCountry = defaultCountry;
            return;
          }
        }
        /**
         * 2. Use the first country from preferred list (if available) or all countries list
         */
        this.activeCountry =
          this.findCountry(this.preferredCountries[0]) ||
          this.allCountriesPrefixes[0];
        /**
         * 3. Check if fetching country based on user's IP is allowed, set it as the default country
         */
        if (!this.disabledFetchingCountry) {
          getCountry().then((res) => {
            this.activeCountry = this.findCountry(res) || this.activeCountry;
          });
        }
      },
      findCountry(iso = '') {
        return this.allCountriesPrefixes.find(
          (country) => country.iso === iso.toUpperCase()
        );
      },
      getItemClass(index, iso) {
        const highlighted = this.selectedIndex === index;
        const lastPreferred = index === this.preferredCountries.length - 1;
        const preferred = !!~this.preferredCountries
          .map((c) => c.toUpperCase())
          .indexOf(iso);
        return {
          highlighted,
          'last-preferred': lastPreferred,
          preferred,
        };
      },
      choose(country) {
        this.activeCountry = country;
        this.SET_MOBILE_PHONE({
          signerId: this.signerId,
          mobilePhone: this.formatResult(),
        });
      },
      toggleDropdown() {
        if (this.disabled || !this.canChangeCountry) {
          return;
        }
        this.open = !this.open;
      },
      clickedOutside() {
        this.open = false;
      },
      keyboardNav(e) {
        const downArrowKeyCode = 40;
        const upArrowKeyCode = 38;
        const enterKeyCode = 13;
        if (e.keyCode === downArrowKeyCode) {
          // down arrow
          this.open = true;
          if (this.selectedIndex === null) {
            this.selectedIndex = 0;
          } else {
            this.selectedIndex = Math.min(
              this.sortedCountries.length - 1,
              this.selectedIndex + 1
            );
          }
          let selEle = this.$refs.list.children[this.selectedIndex];
          if (
            selEle.offsetTop + selEle.clientHeight >
            this.$refs.list.scrollTop + this.$refs.list.clientHeight
          ) {
            this.$refs.list.scrollTop =
              selEle.offsetTop -
              this.$refs.list.clientHeight +
              selEle.clientHeight;
          }
        } else if (e.keyCode === upArrowKeyCode) {
          // up arrow
          this.open = true;
          if (this.selectedIndex === null) {
            this.selectedIndex = this.sortedCountries.length - 1;
          } else {
            this.selectedIndex = Math.max(0, this.selectedIndex - 1);
          }
          let selEle = this.$refs.list.children[this.selectedIndex];
          if (selEle.offsetTop < this.$refs.list.scrollTop) {
            this.$refs.list.scrollTop = selEle.offsetTop;
          }
        } else if (e.keyCode === enterKeyCode) {
          // enter key
          if (this.selectedIndex !== null) {
            this.choose(this.sortedCountries[this.selectedIndex]);
          }
          this.open = !this.open;
        } else {
          // typing a country's name
          this.typeToFindInput += e.key;
          clearTimeout(this.typeToFindTimer);
          this.typeToFindTimer = setTimeout(() => {
            this.typeToFindInput = '';
          }, 700);
          // don't include preferred countries so we jump to the right place in the alphabet
          let typedCountryI = this.sortedCountries
            .slice(this.preferredCountries.length)
            .findIndex((c) =>
              c.name.toLowerCase().startsWith(this.typeToFindInput)
            );
          if (~typedCountryI) {
            this.selectedIndex = this.preferredCountries.length + typedCountryI;
            let selEle = this.$refs.list.children[this.selectedIndex];
            if (
              selEle.offsetTop < this.$refs.list.scrollTop ||
              selEle.offsetTop + selEle.clientHeight >
                this.$refs.list.scrollTop + this.$refs.list.clientHeight
            ) {
              this.$refs.list.scrollTop =
                selEle.offsetTop - this.$refs.list.clientHeight / 2;
            }
          }
        }
      },

      removePrefix(phone) {
        if (!this.activeCountry) {
          return null;
        }
        if (phone && phone[0] === '+') {
          const stringToRemove = '+' + this.activeCountry.prefix;
          return phone.replace(stringToRemove, '');
        } else {
          return phone;
        }
      },
      reset() {
        this.selectedIndex = this.sortedCountries
          .map((c) => c.iso)
          .indexOf(this.activeCountry.iso);
        this.open = false;
      },
    },
  };
</script>

<style src="../../../common/assets/sprite.css"></style>

<style scoped>
  :local {
    --border-radius: 2px;
  }

  li.last-preferred {
    border-bottom: 1px solid #cacaca;
  }
  .iti-flag {
    margin-right: 5px;
    margin-left: 5px;
  }
  .dropdown-item .iti-flag {
    display: inline-block;
  }
  .selection {
    font-size: 0.8em;
    display: flex;
    align-items: center;
  }
  .vue-tel-input {
    display: flex;
    text-align: left;
    align-items: center;
    font-size: 14px;
  }
  .vue-tel-input:focus-within {
    /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
        0 0 8px rgba(102, 175, 233, 0.6);
        border-color: #66afe9; */
  }
  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    top: 24px;
    left: -1px;
    background-color: #fff;
    border: 1px solid #ccc;
    width: 390px;
    z-index: 1;
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    margin-bottom: 25px;
    cursor: pointer;
  }
  .dropdown.open {
    background-color: #f3f3f3;
  }
  .dropdown:hover {
    background-color: #f3f3f3;
  }
  .change-country-disabled.dropdown {
    background-color: white;
    cursor: auto;
  }
  .dropdown-arrow {
    transform: scaleY(0.5);
    display: inline-block;
    color: #666;
  }
  .dropdown-item {
    cursor: pointer;
    padding: 4px 0px;
    display: flex;
    align-items: center;
  }
  .dropdown-item.highlighted {
    background-color: #f3f3f3;
  }
  .dropdown-menu.show {
    max-height: 300px;
    overflow: scroll;
  }
  .vue-tel-input.disabled .selection,
  .vue-tel-input.disabled .dropdown,
  .vue-tel-input.disabled input {
    cursor: no-drop;
  }
</style>
