<template>
  <div>
    <div id="countryOfIncorporationOverview" class="row">
      <p class="field-label col-sm-6">
        <em>{{ $t('forms.signupForm.countryOfIncorporation.label') }}:</em>
      </p>
      <p class="col-sm-6">
        {{
          company.countryOfIncorporation
            | convertCountryIsoToName
            | emptyValueReplacer
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    computed: {
      ...mapState('signers', ['company']),
    },
  };
</script>
