<template>
  <div class="col-sm-offset-2">
    <fieldset
      :class="{ error: displayError }"
      class="form-field u-mt- u-pv- addMaterialDesign u-mv-"
    >
      <div class="checkbox">
        <label>
          <input v-model="noOtherMDCheckbox" type="checkbox" />
          <span class="checkbox-material">
            <span class="check" :class="{ 'check--error': displayError }" />
          </span>
          <div class="display--inline-table">
            <p align="justify ">
              {{ $t('signersMissingInfo.formPage.regularLoanRequestCheckbox') }}
            </p>
          </div>
        </label>
      </div>
      <div
        role="alert"
        class="text-block text-align--right display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{
            errors.noOtherMDCheckbox &&
            $t('forms.errors.' + errors.noOtherMDCheckbox)
          }}
        </p>
      </div>
    </fieldset>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    computed: {
      displayError() {
        return this.submitClicked && !!this.errors.noOtherMDCheckbox;
      },
      noOtherMDCheckbox: {
        get() {
          return this.$store.state.signers.noOtherMDCheckbox;
        },
        set(value) {
          this.SET_NO_OTHER_MD_CHECKBOX(value);
        },
      },
      ...mapState('signers', ['submitClicked', 'errors']),
    },
    methods: {
      ...mapMutations('signers', ['SET_NO_OTHER_MD_CHECKBOX']),
    },
  };
</script>
