<template>
  <div class="len-body-wrapper">
    <len-header-big-logo />
    <div class="fail-heading-wrapper">
      <div class="fail-logo-wrapper">
        <img
          src="/ICON_signingproblem.svg"
          width="250"
          alt=""
          class="fail-logo"
        />
      </div>
      <h1
        class="fail-heading-text"
        v-html="$t('borrowerFailPage.failHeader')"
      />
    </div>
    <div class="fail-text-wrapper">
      <div class="fail-text">
        {{ $t('borrowerFailPage.failText') }}
      </div>
      <div class="len-btn-wrapper">
        <a href="mailto:support@lendico.de" class="len-btn w-inline-block"
          ><div class="len-btn-text">{{ $t('borrowerFailPage.CTA') }}</div></a
        >
      </div>
    </div>

    <len-footer class="len-footer-custom" />
  </div>
</template>

<script>
  import { enableGTM } from '@/mixins/gtmMixins';
  export default {
    mixins: [enableGTM],
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerFailPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerFailPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerFailPage.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .len-body-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
  }

  .len-footer-custom {
    bottom: 0;
    width: 100%;
  }

  .fail-heading-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .fail-heading-text {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Source Sans Pro', sans-serif;
    color: #9d9d9d;
    line-height: 42px;
    font-weight: 600;
    text-align: justify;
  }

  .fail-text {
    font-family: 'Source Sans Pro', sans-serif;
    color: #9d9d9d;
    font-size: 30px;
    line-height: 36px;
    font-weight: 500;
    text-align: justify;
  }

  .fail-text-wrapper {
    width: 100%;
    padding: 50px 100px;
    padding-bottom: 300px !important;
  }
  .div-block-4 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #3cbdb9;
  }

  .div-block-4:hover {
    background-color: #004055;
  }

  .len-btn-text {
    font-family: 'Source Sans Pro', sans-serif;
    color: #fff;
    font-size: 22px;
    line-height: 24px;
    font-weight: 600;
  }

  .len-btn-wrapper {
    padding-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .len-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 250px;
    height: 50px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #3cbdb9;
    text-decoration: none;
  }

  .len-btn:hover {
    background-color: #004055;
  }

  @media (max-width: 991px) {
    .fail-heading-text {
      font-size: 34px;
      line-height: 34px;
      text-align: left;
    }
    .fail-text {
      text-align: justify;
      font-size: 23px;
    }
  }

  @media (max-width: 767px) {
    .fail-heading-text {
      font-size: 26px;
      line-height: 30px;
    }
    .fail-text {
      font-size: 20px;
      line-height: 24px;
    }
    .fail-text-wrapper {
      padding-right: 50px;
      padding-left: 50px;
    }
  }

  @media (max-width: 479px) {
    .fail-heading-text {
      padding-right: 20px;
      padding-left: 20px;
      font-size: 22px;
      line-height: 24px;
    }
    .fail-text {
      font-size: 18px;
      line-height: 20px;
    }
    .fail-text-wrapper {
      padding-right: 20px;
      padding-left: 20px;
    }
    .fail-logo {
      display: none;
    }
  }
</style>
