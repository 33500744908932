import { WEBTREKK_OPTIONS } from '@/constants/WEBTREKK';

export const webtrekkMixin = {
  methods: {
    trackEvent(eventName, productName, productStatus, params) {
      const { contentId } = WEBTREKK_OPTIONS[eventName];
      if (document.wt && document.wt.trackPage && contentId) {
        document.wt.trackPage(contentId, productName, productStatus, params);
      }
    },
    // * window.wt is different from document.wt
    // ! window.wt contains sendinfo method
    trackClick(wtlinkAttribute) {
      window?.wt?.sendinfo({
        contentId: window.wt.contentId,
        linkId: wtlinkAttribute,
      });
    },
  },
};
