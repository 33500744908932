import nationalities from '@/components/common/assets/nationalities';

export const countryDropdownInputFunctionality = {
  name: 'VueCountryDropdownInput',
  computed: {
    dropdownOptions: function () {
      const countries = nationalities.map((country) => ({
        label: country.name,
        value: country.iso,
      }));

      const preferredCountries = nationalities
        .filter((country) => this.preferredCountries.includes(country.iso))
        .map((country) => ({
          label: country.name,
          value: country.iso,
        }));
      return [...preferredCountries, ...countries];
    },
  },
  watch: {
    initialCountry: function () {
      if (this.isNative) {
        this.country = this.initialCountry;
      }
    },
  },
  mounted() {
    if (this.initialCountry && this.isNative) {
      this.country = this.initialCountry;
    }
  },
  methods: {
    handleChange: function (value) {
      this.country = value;
      this.$emit('change', value);
    },
  },
};
