<template>
  <div>
    <len-header :loanCalculator="false" />

    <len-section maxWidth="true" theme="white">
      <div class="top-header__wrapper">
        <h1 class="top-header__title">
          {{ $t('borrowerSignupFormthankYouPage.mainHeader') }}
        </h1>
        <div v-if="preChecksFailedOrNoCrefoId" class="top-header__caption">
          {{ $t('borrowerSignupFormthankYouPage.experianFlowSubHeader') }}
        </div>
        <div v-if="!preChecksFailedOrNoCrefoId" class="top-header__caption">
          {{ $t('borrowerSignupFormthankYouPage.subHeader') }}
        </div>
      </div>
    </len-section>

    <len-section maxWidth="true" theme="light">
      <div class="thank-you-content__wrapper">
        <h2 class="thank-you-content__header">
          {{ $t('borrowerSignupFormthankYouPage.content.data.header') }}
        </h2>

        <div class="thank-you-content__blocks">
          <div class="thank-you-content__block">
            <div class="thank-you-content__block-header">
              {{
                $t('borrowerSignupFormthankYouPage.content.data.sectionHeader1')
              }}
            </div>

            <div class="thank-you-content__data-block-wrapper">
              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{ $t('borrowerSignupFormthankYouPage.content.data.prefix') }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ salutation }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t('borrowerSignupFormthankYouPage.content.data.firstName')
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ firstName }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t('borrowerSignupFormthankYouPage.content.data.lastName')
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ lastName }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{ $t('borrowerSignupFormthankYouPage.content.data.dob') }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ dob }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{ $t('borrowerSignupFormthankYouPage.content.data.email') }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ email }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{ $t('borrowerSignupFormthankYouPage.content.data.tel') }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ mobilePhone }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t('borrowerSignupFormthankYouPage.content.data.address')
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ userAddress }}
                </div>
              </div>
            </div>
          </div>

          <div class="thank-you-content__block">
            <div class="thank-you-content__block-header">
              {{
                $t('borrowerSignupFormthankYouPage.content.data.sectionHeader2')
              }}
            </div>

            <div class="thank-you-content__data-block-wrapper">
              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t(
                      'borrowerSignupFormthankYouPage.content.data.loanAmount.termLoan'
                    )
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ loanAmount | europeanCurrency }} €
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t(
                      'borrowerSignupFormthankYouPage.content.data.loanDuration'
                    )
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ length }}
                  {{ $t('borrowerSignupFormthankYouPage.content.data.year') }}
                </div>
              </div>
            </div>

            <div class="thank-you-content__block-header">
              {{
                $t('borrowerSignupFormthankYouPage.content.data.sectionHeader3')
              }}
            </div>

            <div class="thank-you-content__data-block-wrapper">
              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t('borrowerSignupFormthankYouPage.content.data.firmName')
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ companyName }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t(
                      'borrowerSignupFormthankYouPage.content.data.firmStartDate'
                    )
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ dateFounded }}
                </div>
              </div>

              <div class="thank-you-content__data-block-row">
                <div class="thank-you-content__data-block-row__title">
                  {{
                    $t(
                      'borrowerSignupFormthankYouPage.content.data.firmAddress'
                    )
                  }}
                </div>
                <div class="thank-you-content__data-block-row__text">
                  {{ address }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </len-section>

    <len-section
      v-if="!preChecksFailedOrNoCrefoId"
      maxWidth="true"
      theme="white"
    >
      <div class="thank-you-content__wrapper">
        <h4 class="thank-you-content__header">
          {{ $t('borrowerSignupFormthankYouPage.content.data.h4') }}
        </h4>

        <ol class="thank-you-content__list">
          <li class="thank-you-content__list-item">
            {{ $t('borrowerSignupFormthankYouPage.content.data.ol1p1') }}
            <a :href="'mailto:' + $t('contactEmail')" class="base-link">{{
              $t('contactEmail')
            }}</a>
            :

            <ul class="thank-you-content__list__sub-list">
              <li
                class="thank-you-content__list-item"
                v-html="
                  $t('borrowerSignupFormthankYouPage.content.data.sul1p1', {
                    currentYearMinusTwo,
                    currentYearMinusOne,
                  })
                "
              />
              <li
                v-if="showBusinessBulletText"
                class="thank-you-content__list-item"
                v-html="
                  $t('borrowerSignupFormthankYouPage.content.data.sul1p2', {
                    currentYear,
                  })
                "
              />
              <li
                class="thank-you-content__list-item"
                v-html="
                  $t('borrowerSignupFormthankYouPage.content.data.sul1p3')
                "
              />
            </ul>
          </li>
          <li
            class="thank-you-content__list-item"
            v-html="$t('borrowerSignupFormthankYouPage.content.data.ol1p2')"
          />
          <li
            class="thank-you-content__list-item"
            v-html="$t('borrowerSignupFormthankYouPage.content.data.ol1p3')"
          />
          <li
            class="thank-you-content__list-item"
            v-html="$t('borrowerSignupFormthankYouPage.content.data.ol1p4')"
          />
        </ol>
      </div>
    </len-section>

    <len-contact
      :theme="preChecksFailedOrNoCrefoId ? 'white' : 'light'"
      rootTranslationKey="borrowerSignupPage"
    />

    <len-footer />
  </div>
</template>

<script>
  import LenHeader from '@/components/common/len-header';
  import LenSection from '@/components/common/len-section.vue';
  import LenContact from '@/components/contact';
  import LenFooter from '@/components/footer/footer.vue';
  import {
    successPage,
    formatDate,
    removeLocalStorageUserData,
  } from '@/mixins/successPageMixins';
  import { enableGTM } from '@/mixins/gtmMixins';

  export default {
    name: 'BorrowerThankyouPage',
    components: {
      LenHeader,
      LenSection,
      LenContact,
      LenFooter,
    },
    mixins: [enableGTM, successPage, formatDate, removeLocalStorageUserData],
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerSignupPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerSignupPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerSignupPage.metaDescription'),
          },
        ],
      };
    },
    data() {
      return {
        salutation: null,
        firstName: null,
        lastName: null,
        dob: null,
        email: null,
        mobilePhone: null,
        userAddress: null,
        loanAmount: null,
        length: null,
        companyName: null,
        dateFounded: null,
        address: null,
        mappingId: null,
        revenue: null,
      };
    },
    computed: {
      preChecksFailedOrNoCrefoId: function () {
        return this.revenue === 'before50K' || !this.mappingId;
      },
    },
    beforeMount() {
      this.loadUserData();
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_settings.scss';
  @import '@/variables.scss';

  .top-header {
    &__wrapper {
      padding: 120px 0;
    }
    &__title {
      font-weight: $semibold;
      font-size: 64px;
      line-height: 64px;
      text-align: center;
      color: $petroleum;
    }
    &__caption {
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: $darkGrey;
      margin: 40px auto 0;
      max-width: 900px;
    }
  }

  .thank-you-content {
    &__wrapper {
      margin: 80px 0;
      color: $darkerGrey;
    }

    &__header {
      font-size: 48px;
      line-height: 48px;
      font-weight: $semibold;
    }

    &__blocks {
      display: flex;
      flex-flow: row wrap;
      .thank-you-content__block {
        &:nth-child(1) {
          flex: 0 0 calc(50% - 40px);
          margin-right: 40px;
        }
        &:nth-child(2) {
          flex: 0 0 calc(50% - 40px);
          margin-left: 40px;
        }
      }
    }

    &__block-header {
      margin: 40px 0 13px 0;
      font-size: 24px;
      line-height: 28px;
      font-weight: $semibold;
    }

    &__data-block-row {
      display: flex;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      &__title,
      &__text {
        font-size: 18px;
        line-height: 24px;
        word-break: break-word;
      }

      &__title {
        flex: 0 0 190px;
      }
    }

    &__list {
      list-style: decimal inside;
      margin-top: 40px;
      color: $darkerGrey;

      &__sub-list {
        color: $darkGrey;
        margin-top: 20px;

        .thank-you-content__list-item {
          margin-bottom: 10px;
        }
      }
    }

    &__list-item {
      margin-bottom: 20px;
      font-size: 18px;
      line-height: 22px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .top-header__wrapper {
      padding: 80px 0;
    }

    .thank-you-content {
      &__blocks {
        .thank-you-content__block {
          &:nth-child(1) {
            flex: 0 0 calc(50% - 15px);
            margin-right: 15px;
          }
          &:nth-child(2) {
            flex: 0 0 calc(50% - 15px);
            margin-left: 15px;
          }
        }
      }

      &__data-block-row {
        &__title,
        &__text {
          font-size: 16px;
          line-height: 18px;
        }

        &__title {
          flex: 0 0 142px;
        }
      }

      &__list-item {
        line-height: 24px;
      }
    }
  }

  @media screen and (max-width: $mobile-l) {
    .top-header {
      &__wrapper {
        padding: 40px 0;
      }
      &__title {
        font-size: 32px;
        line-height: 38px;
      }
      &__caption {
        font-size: 16px;
        line-height: 22px;
        margin-top: 20px;
      }
    }

    .thank-you-content {
      &__wrapper {
        margin: 40px 0;
      }

      &__header {
        font-size: 24px;
        line-height: 24px;
      }

      &__blocks {
        .thank-you-content__block {
          &:nth-child(1),
          &:nth-child(2) {
            flex: 0 0 100%;
            margin: 0;
          }
        }
      }

      &__block-header {
        margin: 40px 0 18px 0;
        font-size: 18px;
        line-height: 21px;
      }

      &__data-block-row {
        &__title,
        &__text {
          font-size: 16px;
          line-height: 18px;
        }

        &__title {
          flex: 0 0 128px;
        }
      }

      &__list {
        margin-top: 20px;
      }

      &__list-item {
        font-size: 16px;
        line-height: 18px;
      }
    }
  }
</style>
