<template>
  <div class="col-sm-12">
    <div class="header-container" data-qa-id="submit-form-header">
      <len-icon
        icon="tick"
        align="left"
        iconSvg="false"
        marginBottom="18"
        marginTop="0"
      />
      <h1 class="title">
        {{ $t('borrowerSignupForm.submitHeader.title') }}
      </h1>
    </div>
  </div>
</template>

<script>
  import LenIcon from '@/components/common/len-icon';
  import { mapGetters } from 'vuex';

  export default {
    components: { LenIcon },
    computed: {
      ...mapGetters('signupForm', ['isPartner']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .header-container {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    margin-top: 80px;
    margin-bottom: 40px;
  }
  .title {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 38px;
    letter-spacing: normal;
    color: #29bdba;
    margin-left: 12px;
    margin-bottom: 18px;
    margin-top: 0;
  }
  @media only screen and (max-width: $mobile-l) {
    .header-container {
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 25px;
    }
    .title {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      margin-left: 0;
      margin-bottom: 0;
    }
  }
</style>
