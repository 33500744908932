<template>
  <form
    class="amazon-form-container"
    name="registration"
    autocomplete="off"
    @submit.prevent="submit"
  >
    <IngFormLoader key="amazonLoader" storeModule="signupForm" />
    <PageHeader :page="channel + '.application.' + productKey" />
    <PageSection sectionPadding="top-no">
      <template #section-content>
        <FormSectionHeader
          :section="channel + '.application.form.creditSection.' + productKey"
        />
        <lendico-ing-omni-loan-calculator :offer="offerAsString" />
      </template>
    </PageSection>
    <PageSection :darkTheme="true">
      <template #section-content>
        <FormSectionHeader
          section="signup.application.form.companySection"
          :paraCount="2"
        />
        <IngCompanyInformation ref="company-information" />
      </template>
    </PageSection>
    <PageSection>
      <template #section-content>
        <FormSectionHeader
          section="signup.application.form.representativeSection"
          :paraCount="2"
        />
        <IngRepresentativeInformation ref="representative-information" />
      </template>
    </PageSection>
    <PageSection :darkTheme="true">
      <template #section-content>
        <FormSectionHeader
          :section="channel + '.application.form.termsSection'"
          :paraCount="0"
        >
          <template #form-content>
            <IngAmazonTermsAndConditions />
          </template>
        </FormSectionHeader>
        <IngSubmitForm />
      </template>
    </PageSection>
    <div />
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
  </form>
</template>
<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import { calculatorLoaderMixin } from '@/mixins/calculatorLoaderMixin';
  import { routerPushByName } from '@/mixins/routerMixin';
  import {
    setAmountAndLength,
    setDisbursementAmount,
    utmParamsExtraction,
    mediaCodesExtraction,
    wtCookieRemove,
  } from '@/mixins/forms/formSubmissionMixin';
  import cookie from '@/common/cookie';
  import * as PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';
  import {
    TRACK_EVENTS,
    TRACK_PRODUCT_NAME,
    TRACK_PRODUCT_STATUS,
    TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
  } from '@/constants/WEBTREKK';

  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import IngCompanyInformation from '@/components/common/forms/IngCompanyInformation';
  import IngRepresentativeInformation from '@/components/common/forms/IngRepresentativeInformation';
  import IngSubmitForm from '@/components/common/forms/IngSubmitForm';
  import IngAmazonTermsAndConditions from '@/components/amazon/IngAmazonTermsAndConditions';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';

  export default {
    name: 'IngAmazonSignupForm',
    components: {
      IngFormLoader,
      PageHeader,
      FormSectionHeader,
      PageSection,
      IngCompanyInformation,
      IngRepresentativeInformation,
      IngSubmitForm,
      IngAmazonTermsAndConditions,
    },
    mixins: [
      mediaCodesExtraction,
      utmParamsExtraction,
      wtCookieRemove,
      setAmountAndLength,
      setDisbursementAmount,
      routerPushByName,
      webtrekkMixin,
      calculatorLoaderMixin,
    ],
    computed: {
      ...mapState('appSettings', ['channel']),
      ...mapState('signupForm', ['submitter']),
      ...mapGetters('signupForm', ['isValid']),
      ...mapGetters('signupForm', ['isBookkeepingVisible']),
      ...mapState('blacksea', ['offer']),
      ...mapGetters('blacksea', [
        'isTermLoan',
        'isCreditLineLoan',
        'offerDetails',
        'productKey',
      ]),
      offerAsString() {
        return JSON.stringify(this.offer);
      },
      loanTrack() {
        return (
          this.channel +
          '_' +
          PRODUCT_TYPES[this.offerDetails.productCode] +
          '_APPLICATION'
        ).toUpperCase();
      },
      loanSuccessTrack() {
        return this.loanTrack + '_SUCCESS';
      },
      loanThanksRedirect() {
        return (
          this.channel +
          '-' +
          PRODUCT_TYPES[this.offerDetails.productCode] +
          '-danke'
        ).toLowerCase();
      },
    },
    beforeMount() {
      this.loadCalculator();
    },
    mounted() {
      this.SET_SUBMITTER('applicant');
      this.trackEvent(
        TRACK_EVENTS[this.loanTrack],
        TRACK_PRODUCT_NAME[this.loanTrack],
        TRACK_PRODUCT_STATUS.ADD
      );
    },
    methods: {
      ...mapActions('signupForm', ['signUp']),
      ...mapMutations('signupForm', [
        'SET_GC_ID',
        'SET_SUBMIT_CLICKED',
        'SET_SUBMITTER',
      ]),
      async submit(event) {
        this.addExtraFields();
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        try {
          const amount = this.$route.query?.amount;

          await this.signUp();
          this.trackClick(event.submitter.attributes['wtlink'].value);
          this.$router.replace({ name: this.loanThanksRedirect });
          this.removeWTCookies();
          this.trackEvent(
            TRACK_EVENTS[this.loanSuccessTrack],
            TRACK_PRODUCT_NAME[this.loanTrack],
            TRACK_PRODUCT_STATUS.SUCCESS,
            {
              orderValue: amount,
              ...TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
            }
          );
          window.scrollTo(0, 0);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
      },
      addExtraFields() {
        this.setAmountAndLength();
        this.setDisbursementAmount();
        this.extractUTMParams();
        this.extractMediaCodes();
        this.SET_GC_ID(cookie.get('_ga'));
      },
    },
  };
</script>
