<template>
  <div class="cash-intensity-title-container">
    <p class="field-label">
      <em>{{ $t('forms.signupForm.cashIntensity.label') }}</em>
      <tooltip
        :tooltipMessage="$t('forms.signupForm.cashIntensity.tooltip')"
        messageClass="cash-intensity-tooltip-message"
      />
    </p>
  </div>
</template>

<script>
  import Tooltip from '@/components/signers/signers-tooltip';
  export default {
    components: {
      Tooltip,
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/_settings.scss';
  p {
    font-size: 14px;
    line-height: 21px;
  }
  @media (max-width: 870px) {
    .cash-intensity-title-container {
      max-width: 80%;
    }
  }
</style>
