<template>
  <PageSection class="ing-page-header" :sectionPadding="sectionPadding">
    <template #section-content>
      <h1
        class="ing-page-header__title"
        :class="{ 'ing-page-header__title--bottom-sm': description || hasSlot }"
      >
        {{ $t(`${page}.header.title`, i18nUserData) }}
      </h1>

      <p v-if="description && !hasSlot" class="ing-page-header__content">
        {{ $t(`${page}.header.description`, i18nUserData) }}
      </p>

      <div v-else-if="hasSlot" class="ing-page-header__content">
        <slot />
      </div>
    </template>
  </PageSection>
</template>

<script>
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  export default {
    name: 'PageHeader',
    components: {
      PageSection,
    },
    props: {
      page: {
        type: String,
        required: true,
      },
      sectionPadding: {
        type: String,
        default: '',
      },
      titlePadding: {
        type: String,
        default: '',
      },
      i18nUserData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      description() {
        return this.$t(`${this.page}.header`).description;
      },
      hasSlot() {
        return !!this.$slots.default;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ing-page-header {
    &__title {
      font-size: 36px;
      line-height: 48px;
      color: $Primary-Orange;
      font-weight: 400;

      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
      &--bottom-sm {
        padding-bottom: 32px;
      }
    }
    &__content {
      font-size: 19px;
      line-height: 28px;
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
  }
</style>
