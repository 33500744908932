var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"progress-bar-container col-lg-7 col-md-9 col-sm-10 col-xs-10"},[_c('div',{staticClass:"step-container"},[_c('div',{staticClass:"step-icon",class:{ active: _vm.shouldShowStep1, inactive: !_vm.shouldShowStep1 },on:{"click":function($event){return _vm.setCurrentStep(1)}}},[_vm._v(" 1 ")]),_c('p',{staticClass:"step-text",class:{ active: _vm.shouldShowStep1 },on:{"click":function($event){return _vm.setCurrentStep(1)}}},[_vm._v(" "+_vm._s(_vm.$t('borrowerJourney.progressBar.firstStep'))+" ")])]),_c('div',{staticClass:"progress-line",class:{
      'non-visited': _vm.offerIsNotResolved,
      visited: !_vm.offerIsNotResolved,
    }}),_c('div',{staticClass:"step-container"},[_c('div',{staticClass:"step-icon",class:{
        active: _vm.shouldShowStep2,
        inactive: !_vm.shouldShowStep2,
        disabled: _vm.offerIsNotResolved,
      },on:{"click":function($event){return _vm.setCurrentStep(2)}}},[_vm._v(" 2 ")]),_c('p',{staticClass:"step-text",class:{ active: _vm.shouldShowStep2, disabled: _vm.offerIsNotResolved },on:{"click":function($event){return _vm.setCurrentStep(2)}}},[_vm._v(" "+_vm._s(_vm.$t('borrowerJourney.progressBar.secondStep'))+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }