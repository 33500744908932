<template>
  <div class="company-trading-name-wrapper">
    <ing-text-input-tooltip
      v-model="companyTradingName"
      :label="$t('forms.signupForm.tradingName.label')"
      :error="
        errors.company &&
        errors.company.companyTradingName &&
        $t('forms.errors.' + errors.company.companyTradingName)
      "
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="company-trading-name"
      data-unit-test="ing-company-trading-name-field"
      :tooltipMessage="$t('forms.signupForm.tradingName.tooltip')"
      messageClass="trading-name-tooltip-message"
    />
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';

  export default {
    components: { IngTextInputTooltip },
    computed: {
      companyTradingName: {
        get() {
          return this.company.companyTradingName;
        },
        set(value) {
          this.SET_COMPANY_TRADING_NAME(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_TRADING_NAME']),
    },
  };
</script>

<style lang="scss" scoped>
  .company-trading-name-wrapper {
    position: relative;
  }

  .company-name-tooltip {
    position: absolute;
    left: 80px;
    top: 5px;
    &.on-input-focus {
      top: -12px;
    }
  }
</style>
