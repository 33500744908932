import Vue from 'vue';
import Vuex from 'vuex';
import config from './config';
import locale from '@/vuex/locale';
import vuexCache from './cache';
import signers from './signers';
import contract from './contract';
import signupForm from './signup-forms';
import blacksea from './blacksea';
import partner from './partner';
import authentication from './authentication';
import offer from './offer';
import subscription from './subscription';
import ident from './ident';
import appSettings from './appSettings';
Vue.use(Vuex);

export function createStore() {
  return new Vuex.Store({
    modules: {
      appSettings,
      config,
      locale,
      signers,
      contract,
      signupForm,
      blacksea,
      partner,
      authentication,
      offer,
      subscription,
      ident,
    },
    plugins: [vuexCache],
    strict: true,
  });
}

const store = createStore();
export default store;
