var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"display--inline-block grid__item col-sm-12 text-block",attrs:{"data-qa-id":"borrower-privacy-policy-div"}},[_c('p',{staticClass:"policy-text",attrs:{"data-qa-id":"borrower-privacy-policy-text"}},[_vm._v(" "+_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.p1'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('CreditreformModal')}}},[_vm._v(_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.cta1Text')))]),_vm._v(" ."),_c('br'),_vm._v(" "+_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.p2'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('CreditreformModal')}}},[_vm._v(_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.cta1Text')))]),_vm._v(" "+_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.p3'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('SchufaModal')}}},[_vm._v(_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.cta2Text')))]),_vm._v(" "+_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.p4'))+" ")])]),(_vm.isSchufaModalOpen)?_c('Modal',{on:{"onClose":function($event){return _vm.closeModal('SchufaModal')}}},[_c('div',{staticClass:"modal-inner-content"},[_c('h2',{staticClass:"modal-inner-header"},[_vm._v(" "+_vm._s(_vm.$t('borrowerSignupForm.privacyPolicy.schufaModalContent.title'))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('borrowerSignupForm.privacyPolicy.schufaModalContent.content')
        )}})])]):_vm._e(),(_vm.isCreditreformModalOpen)?_c('Modal',{on:{"onClose":function($event){return _vm.closeModal('CreditreformModal')}}},[_c('div',{staticClass:"modal-inner-content"},[_c('h2',{staticClass:"modal-inner-header"},[_vm._v(" "+_vm._s(_vm.$t( 'borrowerSignupForm.privacyPolicy.creditreFormModalContent.title' ))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'borrowerSignupForm.privacyPolicy.creditreFormModalContent.content'
          )
        )}})])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }