<template>
  <fieldset>
    <div>
      <label class="ing-label">
        {{ label }}
        <ing-bs-tooltip
          :tooltipMessage="$t('ingBorrowerSignupForm.company.tooltip')"
          placement="bottom"
        />
      </label>
      <!-- please see: https: gist.github.com/niksumeiko/360164708c3b326bd1c8 for the explanation about autocomplete property hack -->
      <input
        id="companyName"
        :value="company"
        :class="{ 'ing-input-error': displayError }"
        type="text"
        class="form-control"
        :name="safariAutocompleteOffName"
        autocorrect="off"
        aria-autocomplete="none"
        autocomplete="nofill"
        data-qa-id="company-name"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      />
      <ing-form-loader
        storeModule="signupForm"
        :manuallyShow="searchingCompany"
        small
        class="loader-style"
      />
    </div>
    <dropdown
      :open="displayCompanyDropdown"
      :options="companiesOptions"
      :maxOptions="10"
      class="ing-dropdown company-lookup"
      @select="onSelectCompany"
    />
    <div :class="{ active: displayError }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p>
        {{ errors.company && $t('forms.errors.' + errors.company) }}
      </p>
    </div>
  </fieldset>
</template>

<script>
  import { companyFieldFunctionality } from '@/mixins/forms/company-information/companyFieldMixin';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';

  export default {
    components: {
      IngBsTooltip,
    },
    mixins: [companyFieldFunctionality, ingFormFieldGTMTrackingMixin],
    data() {
      return {
        label: this.$t('ingBorrowerSignupForm.company.label'),
      };
    },
  };
</script>

<style scoped>
  .loader-style {
    position: absolute;
    top: auto;
    bottom: calc(50% - 13px);
    right: 10px;
    left: auto;
    display: inline-block;
  }
</style>

<style lang="scss">
  @import '@/variables.scss';

  .ing-dropdown.company-lookup {
    li > a.item {
      display: flex;
      align-items: center;

      span.company-name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      span.company-address {
        flex: 1;
        margin-left: 10px;
        font-size: 12px;
        color: #767676;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        padding-top: 2px;
      }
    }
  }

  @media screen and (max-width: $mobile-l) {
    .ing-label {
      padding-right: 20px;
    }
    .ing-dropdown.company-lookup {
      li > a.item {
        padding: 0 10px 0;
        flex-direction: column;
        align-items: self-start;
        justify-content: center;
        height: 50px;

        span.company-name {
          width: 100%;
          line-height: 16px;
        }
        span.company-address {
          flex: 0 0 auto;
          margin-left: 0;
          padding-top: 3px;
          display: block;
          width: 100%;
          line-height: 14px;
        }
      }
    }
  }
</style>
