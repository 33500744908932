<template>
  <div class="ident-type-warning">
    <FieldStatus
      :warning="$t('signers.identInformation.identOptions.postIdent.warning')"
    />
    <a
      class="ident-type-warning__link"
      :href="`mailto:${$translationConstantValues.businessEmail}`"
      >{{ $translationConstantValues.businessEmail }}</a
    >
  </div>
</template>
<script>
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';

  export default {
    components: {
      FieldStatus,
    },
  };
</script>
<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ident-type-warning {
    max-width: 500px;

    .field-status {
      margin-left: 2.4rem;
    }
    &__link {
      color: $Secondary-Indigo500;
      font-weight: 700;
      text-decoration: underline;
      margin-left: 4rem;
    }
  }
</style>
