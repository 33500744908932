import { isEmpty, hasWrongLength, isNotNumeric } from '../helpers/string';
import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';

export default function ValidatePostalZip(postalZip) {
  if (isEmpty(postalZip)) {
    return { postalZip: 'PostalZipIsRequired' };
  }
  if (isNotNumeric(postalZip)) {
    return { postalZip: 'PostalZipIsNAN' };
  }
  if (hasWrongLength(postalZip, 5)) {
    return { postalZip: 'PostalZipIsInvalidLength' };
  }
  if (hasForbiddenCharacters(postalZip)) {
    return { postalZip: 'ForbiddenCharacters' };
  }

  return { postalZip: undefined };
}
