var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"identification-issuing-authority-wrapper"},[_c('len-text-input',{attrs:{"label":_vm.$t('forms.signupForm.identificationIssuingAuthority.label'),"error":_vm.errors[_vm.signerId] &&
      _vm.errors[_vm.signerId].identificationIssuingAuthority &&
      _vm.$t('forms.errors.' + _vm.errors[_vm.signerId].identificationIssuingAuthority),"displayErrorIfDirty":!_vm.submitClicked && !_vm.signers[_vm.signerId].submitClicked,"name":"identification-issuing-authority"},on:{"focus":function($event){_vm.identificationIssuingAuthorityHasFocus = true},"blur":function($event){_vm.identificationIssuingAuthorityHasFocus = false}},model:{value:(_vm.identificationIssuingAuthority),callback:function ($$v) {_vm.identificationIssuingAuthority=$$v},expression:"identificationIssuingAuthority"}}),_c('tooltip',{staticClass:"identification-issuing-authority-tooltip",class:{
      'on-input-focus':
        _vm.identificationIssuingAuthorityHasFocus ||
        !!_vm.identificationIssuingAuthority,
    },attrs:{"tooltipMessage":_vm.$t('forms.signupForm.identificationIssuingAuthority.tooltip'),"messageClass":"issuing-authority-tooltip-message"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }