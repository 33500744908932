export default function ValidateSalutation(salutation) {
  if (!salutation) {
    return { salutation: 'SalutationIsRequired' };
  }
  if (['Herr', 'Frau', 'f', 'm'].indexOf(salutation) === -1) {
    return { salutation: 'SalutationIsNotCorrect' };
  }

  return { salutation: undefined };
}
