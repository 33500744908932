import numeraljs from '@/filters/numeraljs';
import momentjs from '@/filters/momentjs';
import emptyValueReplacer from '@/filters/emptyValueReplacer';
import convertCountryIsoToName from '@/filters/nationalities';
import formatIBAN from '@/filters/formatIban';

/**
 * General install function.
 *
 * @param {Object} Vue
 */
export default function install(Vue) {
  Vue.use(numeraljs);
  Vue.use(momentjs);
  Vue.use(emptyValueReplacer);
  Vue.use(convertCountryIsoToName);
  Vue.use(formatIBAN);
}
