import GENDERS from '@/constants/GENDER_VALUES';
import SALUTATIONS from '@/constants/SALUTATION_VALUES';
import LEGAL_STATUSES from '@/constants/LEGAL_STATUS_VALUES';
import REVENUE_VALUES from '@/constants/REVENUE_VALUES';
import LOAN_PURPOSE_VALUES from '@/constants/LOAN_PURPOSE_VALUES';
import BOOK_KEEPING_VALUES from '@/constants/BOOK_KEEPING_VALUES';
import IDENTITY_TYPES from '@/constants/IDENTITY_TYPES';
import TITLE_VALUES from '@/constants/TITLE_VALUES';
import CASH_INTENSITY_VALUES from '@/constants/CASH_INTENSITY_VALUES';
import IDENT_VALUES from '@/constants/IDENT_VALUES';

export default {
  computed: {
    genderOptions() {
      return [
        {
          value: GENDERS.FEMALE,
          label: this.$t('formFields.gender.radioButton.female'),
          dataQaId: 'female',
        },
        {
          value: GENDERS.MALE,
          label: this.$t('formFields.gender.radioButton.male'),
          dataQaId: 'male',
        },
      ];
    },
    salutationOptions() {
      return [
        {
          value: SALUTATIONS.FEMALE,
          label: this.$t('formFields.salutation.radioButton.female'),
          dataQaId: 'female',
        },
        {
          value: SALUTATIONS.MALE,
          label: this.$t('formFields.salutation.radioButton.male'),
          dataQaId: 'male',
        },
      ];
    },
    titleOptions() {
      return [
        {
          value: TITLE_VALUES.DR,
          label: this.$t('formFields.title.dr'),
        },
        {
          value: TITLE_VALUES.PROF,
          label: this.$t('formFields.title.prof'),
        },
        {
          value: TITLE_VALUES.ING,
          label: this.$t('formFields.title.ing'),
        },
        {
          value: TITLE_VALUES.DPL_ING,
          label: this.$t('formFields.title.dlp_ing'),
        },
        {
          value: TITLE_VALUES.SONSTIGES,
          label: this.$t('formFields.title.Sonstiges'),
        },
      ];
    },
    legalStatusOptions() {
      return [
        {
          value: LEGAL_STATUSES.GMBH,
          label: this.$t('formFields.companyLegalStatus.dropdown.gmbh'),
        },
        {
          value: LEGAL_STATUSES.GMBH_AND_COKG,
          label: this.$t('formFields.companyLegalStatus.dropdown.gmbhAndCoKg'),
        },
        {
          value: LEGAL_STATUSES.FREE_PROFESSION,
          label: this.$t(
            'formFields.companyLegalStatus.dropdown.freeProfession'
          ),
        },
        {
          value: LEGAL_STATUSES.INDIVIDUAL_COMPANY,
          label: this.$t(
            'formFields.companyLegalStatus.dropdown.individualCompany'
          ),
        },
        {
          value: LEGAL_STATUSES.KG,
          label: this.$t('formFields.companyLegalStatus.dropdown.kg'),
        },
        {
          value: LEGAL_STATUSES.OHG,
          label: this.$t('formFields.companyLegalStatus.dropdown.ohg'),
        },
        {
          value: LEGAL_STATUSES.GBR,
          label: this.$t('formFields.companyLegalStatus.dropdown.gbr'),
        },
        {
          value: LEGAL_STATUSES.AG,
          label: this.$t('formFields.companyLegalStatus.dropdown.ag'),
        },
        {
          value: LEGAL_STATUSES.UG_LIMITED_LIABILITY,
          label: this.$t(
            'formFields.companyLegalStatus.dropdown.ugLimitedLiability'
          ),
        },
        {
          value: LEGAL_STATUSES.LIMITED,
          label: this.$t('formFields.companyLegalStatus.dropdown.limited'),
        },
        {
          value: LEGAL_STATUSES.OTHER,
          label: this.$t('formFields.companyLegalStatus.dropdown.other'),
        },
      ];
    },
    revenueOptions() {
      return [
        {
          value: REVENUE_VALUES.ABOVE_50K,
          label: this.$t('formFields.companyRevenue.radioButton.after50K'),
          dataQaId: 'low-revenue',
        },
        {
          value: REVENUE_VALUES.BELOW_50K,
          label: this.$t('formFields.companyRevenue.radioButton.before50K'),
          dataQaId: 'high-revenue',
        },
      ];
    },
    loanPurposeOptions() {
      return [
        {
          value: LOAN_PURPOSE_VALUES.WORKING_CAPITAL,
          label: this.$t('formFields.loanPurpose.dropdown.workingCapital'),
        },
        {
          value: LOAN_PURPOSE_VALUES.DEBT_REFINANCING,
          label: this.$t('formFields.loanPurpose.dropdown.debtRefinancing'),
        },
        {
          value: LOAN_PURPOSE_VALUES.ASSET_PURCHASE,
          label: this.$t('formFields.loanPurpose.dropdown.assetPurchase'),
        },
        {
          value: LOAN_PURPOSE_VALUES.EXPANSION_GROWTH_FINANCING,
          label: this.$t(
            'formFields.loanPurpose.dropdown.expansionGrowthFinancing'
          ),
        },
      ];
    },
    bookKeepingOptions() {
      return [
        {
          value: BOOK_KEEPING_VALUES.BALANCE_SHEET,
          label: this.$t(
            'formFields.bookkeepingStandard.radioButton.balanceSheet'
          ),
          dataQaId: 'balance-sheet',
        },
        {
          value: BOOK_KEEPING_VALUES.INCOME_STATEMENT,
          label: this.$t(
            'formFields.bookkeepingStandard.radioButton.incomeStatement'
          ),
          dataQaId: 'income-statement',
        },
        {
          value: BOOK_KEEPING_VALUES.UNKNOWN,
          label: this.$t('formFields.bookkeepingStandard.radioButton.unknown'),
          dataQaId: 'unknown',
        },
      ];
    },
    identityOptions() {
      return [
        {
          label: this.$t(
            'signers.clientDetails.client.documentTypes.identityCard'
          ),
          value: IDENTITY_TYPES.ID,
        },
        {
          label: this.$t('signers.clientDetails.client.documentTypes.passport'),
          value: IDENTITY_TYPES.PASSPORT,
        },
      ];
    },
    cashIntensityOptions() {
      return [
        {
          label: this.$t('signers.companyDetails.cashIntensity.options.LOW'),
          value: CASH_INTENSITY_VALUES.LOW,
        },
        {
          label: this.$t('signers.companyDetails.cashIntensity.options.HIGH'),
          value: CASH_INTENSITY_VALUES.HIGH,
        },
      ];
    },
    identOptions() {
      return [
        {
          value: IDENT_VALUES.AUTOMATION,
          label: this.$t('signers.identInformation.identOptions.video.label'),
        },
        {
          value: IDENT_VALUES.MANUAL,
          label: this.$t(
            'signers.identInformation.identOptions.postIdent.label'
          ),
        },
      ];
    },
  },
};
