/**
 * Class uses as wrapper for local storage.
 */
export default class {
  /**
   * @return {boolean}
   */
  static windowIsDefined() {
    return typeof window !== 'undefined';
  }

  /**
   * @param {string} itemName
   * @param {object} itemValue
   */
  static setItem(itemName, itemValue) {
    if (this.windowIsDefined()) {
      window.localStorage.setItem(itemName, JSON.stringify(itemValue));
    }
  }

  /**
   * @param {string} itemName
   * @return {object|null}
   */
  static getItem(itemName) {
    if (this.windowIsDefined()) {
      const item = window.localStorage.getItem(itemName);
      return item !== 'undefined' ? JSON.parse(item) : null;
    }
    return null;
  }

  /**
   * @param {string} itemName
   */
  static removeItem(itemName) {
    if (this.windowIsDefined()) {
      window.localStorage.removeItem(itemName);
    }
  }

  static clear() {
    if (this.windowIsDefined()) {
      window.localStorage.clear();
    }
  }
}
