import {
  TERM_LOAN_EQUAL_INSTALLMENT,
  LINE_OF_CREDIT,
} from '@/constants/PRODUCT_CODES';

export function isTermLoan(state) {
  return (
    state.offer !== null &&
    state.offer.productCode === TERM_LOAN_EQUAL_INSTALLMENT
  );
}

export function isCreditLineLoan(state) {
  return state.offer !== null && state.offer.productCode === LINE_OF_CREDIT;
}

export function getError(state) {
  return state.error;
}
export function offerDetails(state) {
  return state.offer;
}

export function productKey(state) {
  return isCreditLineLoan(state) ? 'wcl' : 'termLoan';
}
