<template>
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.75 0C16.9905 0 18 1.0095 18 2.25V15.75C18 16.9905 16.9905 18 15.75 18H2.25C1.0095 18 0 16.9905 0 15.75V2.25C0 1.0095 1.0095 0 2.25 0H15.75ZM15.75 1.5H2.25C1.83675 1.5 1.5 1.83675 1.5 2.25V15.75C1.5 16.164 1.83675 16.5 2.25 16.5H15.75C16.1632 16.5 16.5 16.164 16.5 15.75V2.25C16.5 1.83675 16.1632 1.5 15.75 1.5ZM6.82627 7.83682C10.5215 6.34357 10.4488 8.62132 10.4465 8.68282C10.4465 10.5556 8.31353 13.9741 10.8133 13.2376C10.9385 13.2061 11.0608 13.1656 11.18 13.1146L11.0338 13.7138C10.1803 14.0513 8.84377 14.6626 7.82377 13.8563C7.05427 13.1986 7.38652 12.3196 8.06752 9.89482C8.21227 9.34057 8.79128 7.64932 6.68077 8.43682L6.82627 7.83682ZM10.0133 3.75C10.6936 3.738 11.3251 4.272 11.3198 4.97625C11.3258 5.685 10.6868 6.2085 10.0133 6.1965C9.33983 6.207 8.69483 5.6865 8.70083 4.97625C8.69558 4.27125 9.33158 3.73875 10.0133 3.75Z"
      fill="#525199"
    />
  </svg>
</template>
<script>
  export default {
    name: 'IconInfoLine',
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  svg {
    width: px2rem(18);
    height: px2rem(18);
    vertical-align: text-bottom;
  }
</style>
