import { render, staticRenderFns } from "./ing-signers-missing-form-page.vue?vue&type=template&id=4a4c5972&scoped=true"
import script from "./ing-signers-missing-form-page.vue?vue&type=script&lang=js"
export * from "./ing-signers-missing-form-page.vue?vue&type=script&lang=js"
import style0 from "./ing-signers-missing-form-page.vue?vue&type=style&index=0&id=4a4c5972&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a4c5972",
  null
  
)

export default component.exports