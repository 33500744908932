<template>
  <ing-text-input
    v-model="postalCity"
    :label="$t('forms.signupForm.city.label')"
    :error="
      errors.company &&
      errors.company.postalCity &&
      $t('forms.errors.' + errors.company.postalCity)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="company-postal-city"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      postalCity: {
        get() {
          return this.company.postalCity;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_CITY(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_POSTAL_CITY']),
    },
  };
</script>
