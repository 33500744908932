import {
  isEmpty,
  matchesStreetNumberPattern,
} from '@/validators/helpers/string';

export default function ValidateStreetNumber(streetNumber) {
  if (isEmpty(streetNumber)) {
    return { streetNumber: 'StreetNumberIsRequired' };
  }
  if (!matchesStreetNumberPattern(streetNumber)) {
    return { streetNumber: 'StreetNumberIsIncorrect' };
  }

  return { streetNumber: undefined };
}
