export default {
  logoTitle: 'LENDICO',
  genericPhone: '030 398 205 269',
  footerPhone: '030 / 3982052 – 60',
  partnerPhoneRef: '03022957284',
  partnerPhone: '030 229 572 84',
  m: 'Herr',
  f: 'Frau',
  gender: {
    m: 'Männlich',
    f: 'Weiblich',
  },
  email: 'info@lendico.de',
  investorEmail: 'investor@lendico.de',
  auszahlungEmail: 'auszahlung@lendico.de',
  partnerContactEmail: 'partnerantrag@lendico.de',
  companiesEmail: 'unternehmen@lendico.de',
  homeUrlText: 'www.lendico.de',
  loginModal: {
    login: {
      title: 'Anmelden',
      preForgotPasswordLinkTitleText: 'Haben Sie Ihr ',
      forgotPasswordLinkTitleText: 'Passwort vergessen',
      postForgotPasswordLinkText: '?',
      form: {
        email: {
          title: 'E-Mail-Adresse',
        },
        password: {
          title: 'Passwort',
        },
        error401: 'Das Passwort oder der Benutzer ist nicht korrekt.',
        error403: 'Bitte bestätigen Sie Ihre E-Mail-Adresse.',
        error500: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.',
        submitButtonText: 'Anmelden',
      },
    },
    forgotPassword: {
      title: 'Passwort vergessen?',
      successTitle: 'Wir haben Ihnen eine E-Mail geschickt',
      successText: 'Überprüfen Sie Ihr E-Mail-Postfach.',
      preBackToLoginLinkTitleText: 'Sie haben Ihr ',
      backToLoginLinkTitleText: 'Passwort doch nicht vergessen',
      postBackToLoginLinkText: '?',
      errorText: 'Bitte versuchen Sie es erneut.',
      errorTitle: 'Ein Fehler ist aufgetreten.',
      submitButtonText: 'Senden',
      form: {
        email: {
          label: 'E-Mail-Adresse',
        },
      },
    },
  },
  loginPage: {
    title: 'Login',
  },
  impersonatePage: {
    title: 'impersonate',
    header: 'Impersonation',
    impersonated: 'Impersonated user:',
    impersonator: 'Impersonator:',
  },
  header: {
    welcomeMessage: 'Willkommen bei LENDICO',
    mainNavigationLinks: {
      borrowerHomepage: { title: 'FIRMENKREDIT' },
      lenderHomepage: { title: 'INVESTIEREN' },
    },
    mainNavigationCtas: {
      minimalText: 'Wir sind für Sie da unter ',
      welcomeMessage:
        '@:header.welcomeMessage, <strong>{gender} {name}</strong>',
      impersonated: 'Impersonated user:',
      links: {
        smeSignUp: { title: 'ANGEBOT SICHERN' },
        dashboard: { title: 'Kontoübersicht' },
        login: { title: 'Anmelden' },
        logout: { title: 'Abmelden' },
      },
    },
    contactUs: {
      phoneTextAndSchedule:
        '<em>Noch Fragen? @:phone</em><br>Montag bis Freitag: 9 - 18 Uhr',
    },
  },
  footer: {
    teamImageSrc: 'team.png',
    contactLabel: 'FOLGEN SIE UNS',
    address:
      'Lendico Deutschland GmbH<br/>c/o Techspace<br/>Lobeckstrasse 36-40<br/>10969 Berlin',
    faqLinkTitle: 'FAQ BESUCHEN &raquo;',
    faqTitle: 'OFFENE FRAGEN?',
    faqDescription: 'Hier finden Sie Antworten zu allen Themenbereichen',
    internationalTitle: 'INTERNATIONAL',
    legalLinks: {
      borrowerTermsConditions: {
        title: 'AGB Kreditnehmer',
      },
      investorTermsConditions: {
        title: 'AGB Anleger',
      },
      dataProtection: {
        title: 'Datenschutz',
      },
      imprint: {
        title: 'Impressum',
      },
    },
    navigationLinks: {
      howItWorks: {
        title: 'So funktionierts &raquo;',
      },
      careers: {
        href: 'https://www.lendico.de/careers-53.html',
        title: 'Karriere &raquo;',
      },
      contact: {
        title: 'Kontakt &raquo;',
      },
      aboutUs: {
        title: 'Über uns &raquo;',
      },
      press: {
        title: 'Presse &raquo;',
      },
      partners: {
        title: 'Partner &raquo;',
      },
    },
  },
  submissionPages: {
    benefitsSection: {
      panel1: {
        imageSrc: 'icons_80x80/thumbsup-AI_icons_CIRCLE-80x80px.svg',
        title: 'EINFACH',
        content: 'Anfrage in nur 10 Minuten',
      },
      panel2: {
        imageSrc: 'icons_80x80/fast-AI_icons_CIRCLE-80x80px.svg',
        title: 'SCHNELL',
        content: 'Kreditentscheidung in 48 Stunden',
      },
      panel3: {
        imageSrc: 'icons_80x80/pricetag-AI_icons_CIRCLE-80x80px.svg',
        title: 'GÜNSTIG',
        content: 'Faire Zinsen und kostenfreie vorzeitige Rückzahlung',
      },
    },
    aboutUsSection: {
      title: 'LENDICO - DAS SIND WIR',
      description:
        'Lendico ist Ihre digitale Alternative zur Bank. Als Marktplatz verbinden wir kleine und mittelständische Unternehmen mit unseren Anlegern. So ermöglichen wir unseren Kreditnehmern günstige Zinsen und Anlegern interessante Renditen. Ihren Kreditantrag stellen Sie schnell und einfach online - ohne Umweg über die Bankfiliale.',
      linkTitle: 'MEHR ÜBER UNS »',
      imageAlt: 'Lendico: How it works',
      imageSrc: 'others/signup-process/how_it_works_ger.png',
    },
  },
  forms: {
    signupForm: {
      zefixWebLinkTitle: 'Handelsregister Internet-Auszug',
      submit: 'Anfragen',
      errors: {
        ibanOrBic:
          'Bitte überprüfen Sie Ihre Angabe. IBAN und/oder BIC sind nicht korrekt.',
        email: 'Bitte geben Sie Ihre E-Mail-Adresse korrekt ein.',
        400: 'Bitte überprüfen Sie Ihre Eingaben.',
        404: `Keine Treffer gefunden. Bitte registrieren Sie sich unter 
              <a rel="noopener noreferrer" href="https://www.lendico.de/partner-werden" target="_blank">https://www.lendico.de/partner-werden</a>, 
              bevor Sie einen Antrag einreichen.`,
        500: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
        70007: {
          emails:
            'Die angegebene E-Mail-Adresse wird bereits für eine der identifizierten Personen verwendet. Bitte korrigieren Sie Ihre Eingaben.',
          phones:
            'Die angegebene Mobilfunknummer wird bereits für eine der identifizierten Personen verwendet. Bitte korrigieren Sie Ihre Eingaben.',
          both: 'Die angegebenen E-Mail-Adresse und Mobilfunknummer werden bereits für eine der identifizierten Personen verwendet. Bitte korrigieren Sie Ihre Eingaben.',
        },
        70011:
          'Die E-Mail-Adresse muss mit der in Ihrer Finanzierungsanfrage übereinstimmen.',
        IsEditableOpen: 'Bitte auf "Speichern" oder "Abbrechen" klicken',
        fields:
          'Nicht alle Felder wurden korrekt ausgefüllt. Bitte überprüfen Sie Ihre Angaben.',
      },
      companyName: {
        label: 'Firmenname',
        tooltipBridge:
          'Für die Kreditanfrage nutzen wir den Firmennamen Ihres Unternehmens. Diesen haben wir nach Ihren Angaben aus Auskunfteien entnommen. Wenn der Firmenname aus Ihrer Sicht korrigiert werden muss, melden Sie \n' +
          'sich bitte unter <u>unternehmen@lendico.de</u> oder unter <u>030 / 398 205 269</u> (Montag bis Freitag zwischen 9 und 18 Uhr).',
        tooltip:
          'Für die Kreditanfrage nutzen wir den Firmennamen Ihres Unternehmens. Diesen haben wir nach Ihren Angaben aus Auskunfteien entnommen. Wenn der Firmenname aus Ihrer Sicht korrigiert werden muss, melden Sie \n' +
          'sich bitte unter <u>unternehmen@ing.de</u> oder unter <u>030 / 800 935 230</u> (Montag bis Freitag zwischen 9 und 18 Uhr).',
      },
      ingAccountDetails: {
        header: 'Kontodaten',
        subHeader:
          'Bitte verwenden Sie dieselbe IBAN und BIC, die in Ihrem Verkäuferkonto in Seller Central hinterlegt sind.',
      },
      iban: {
        label: 'IBAN',
        tooltip:
          'Bitte verwenden Sie dieselbe IBAN, die in Ihrem Verkäuferkonto in Seller Central hinterlegt ist.',
      },
      bic: {
        label: 'BIC',
      },
      tradingName: {
        label: 'Handelsname',
        tooltip:
          'Bitte ändern, falls Sie andere Markennamen gegenüber Ihren Kunden nutzen.',
      },
      countryOfIncorporation: {
        label: 'Gründungsland',
      },
      businessAddress: {
        label: 'Geschäftsadresse',
      },
      postalAddress: {
        label: 'Postanschrift',
        checkbox: {
          company: 'Abweichende Postanschrift.',
          client: 'Abweichende Postanschrift.',
        },
      },
      street: {
        label: 'Straße und Hausnummer',
      },
      streetNumber: {
        label: 'Hausnummer',
      },
      streetOnly: {
        label: 'Straße',
      },
      postcodeDe: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      country: {
        label: 'Land',
      },
      formerStreet: {
        label: 'Ehemalige Straße',
      },
      formerStreetNumber: {
        label: 'Ehemalige Hausnummer',
      },
      formerZip: {
        label: 'Ehemalige PLZ',
      },
      formerCity: {
        label: 'Ehemalige Stadt',
      },
      revenue: {
        label: 'Umsatz des letzten Jahres',
        dropdown: {
          before50K: 'Weniger als 50.000 €',
          after50K: 'Mehr als 50.000 €',
        },
      },
      legalStatus: {
        label: 'Rechtsform',
        dropdown: {
          gmbH: 'GmbH',
          gmbHCoKG: 'GmbH & Co. KG',
          freieBerufe: 'Freie Berufe',
          einzelfirma: 'Einzelfirma/Einzelunternehmen',
          kg: 'KG',
          ohg: 'OHG',
          gbr: 'GbR',
          ag: 'AG',
          ug: 'UG',
          ltd: 'Ltd.',
          other: 'Andere',
        },
        warningMessage:
          'Momentan bieten wir für diese Rechtsform leider keine Finanzierung an.',
      },
      cashIntensity: {
        label:
          'Wie hoch ist der Anteil Ihrer eingehenden Bargeldzahlungen aus den Unternehmensumsätzen?',
        options: {
          LOW: 'bis 25 %',
          HIGH: 'mehr als 25 %',
        },
        tooltip:
          'Es handelt sich um die Zahlungen, die von Ihren Kunden bar an Sie getätigt werden.',
      },
      onlineRevenuesAtLeast75Percent: {
        label:
          'Wie hoch ist der Anteil an Ihren jährlichen Unternehmensumsätzen, den Sie über Online-Kanäle erwirtschaften?',
        dropdown: {
          no: 'weniger als 75 %',
          yes: 'mehr als 75 %',
        },
        warning:
          'Dazu zählt der Verkauf über Plattformen wie Amazon. Bitte überprüfen Sie Ihre Antwort.',
      },
      smeHeader: {
        h3: 'Wir brauchen noch ein paar Daten',
        p: 'Bitte stellen Sie uns die nachfolgenden Daten des letzten Geschäftsjahres zur Verfügung. Sie finden diese in Ihren Finanzunterlagen (Jahresabschluss, Konzernbilanz bzw. Einnahmenüberschussrechnung). Falls Ihr Unternehmen Teil einer Unternehmensgruppe ist, geben Sie bitte die Konzernzahlen gemäß EU KMU Klassifikation an.',
        info: 'Kleinstunternehmen, kleine und mittlere Unternehmen (KMU) werden in der EU-Empfehlung 2003/361 definiert. Danach zählt ein Unternehmen zu den KMU, wenn es nicht mehr als 249 Beschäftigte hat und einen Jahresumsatz von höchstens 50 Millionen Euro erwirtschaftet oder eine Bilanzsumme von maximal 43 Millionen Euro aufweist.',
      },
      employeesNumber: {
        label: 'Wie viele Mitarbeiter sind in Ihrem Unternehmen beschäftigt?',
      },
      companyAssets: {
        label:
          'Wie hoch ist die Jahresbilanzsumme Ihres letzten Geschäftsjahres?',
        checkbox: 'Keine Information verfügbar',
        tooltip:
          'Es handelt sich um die Summe Ihres Vermögens bzw. der Aktiva und Passiva Ihres Unternehmens.',
      },
      companyRevenues: {
        label: 'Wie hoch ist der Jahresumsatz Ihres letzten Geschäftsjahres?',
        tooltip:
          'Es handelt sich um den Erlös der verkauften Waren und Dienstleistungen.',
      },
      authorisedSignatory: {
        label: 'Sind Sie vertretungsberechtigt?',
        dropdown: {
          yes: 'Ich bin vertretungsberechtigt',
          no: 'Ich bin nicht vertretungsberechtigt',
        },
      },
      salutation: {
        label: 'Anrede',
        dropdown: {
          m: 'Herr',
          f: 'Frau',
        },
      },
      personalDataText:
        'Um einen Kreditantrag zu stellen, müssen Sie ein Vertretungsberechtigter der Firma sein.',
      title: {
        label: 'Titel (optional)',
        dropdown: {
          none: '',
          dr: 'Dr.',
          prof: 'Prof.',
          profDr: 'Prof. Dr.',
        },
      },
      firstName: {
        label: 'Vorname',
      },
      lastName: {
        label: 'Nachname',
      },
      lastNameAtBirth: {
        shortLabel: 'Geburtsname (optional)',
        label: 'Sind Ihr Geburtsname und Ihr Nachname identisch?',
        placeholder: 'Geben Sie Ihren Mädchennamen ein',
        dropdown: {
          yes: 'Ja',
          no: 'Nein',
        },
      },
      email: {
        label: 'E-Mail',
      },
      phone: {
        label: 'Mobilfunknummer',
      },
      nationality: {
        label: 'Staatsangehörigkeit',
      },
      secondNationality: {
        label: 'Zweite Staatsangehörigkeit (falls vorhanden)',
      },
      countryOfBirth: {
        label: 'Geburtsland',
      },
      cityOfBirth: {
        label: 'Geburtsort',
      },
      mobilePhone: {
        label: 'Mobilnummer',
      },
      loanPurpose: {
        label: 'Verwendungszweck',
        dropdown: {
          workingCapital: 'Umlaufvermögen',
          debtRefinancing: 'Umschuldung',
          assetPurchase: 'Investition in Anlagevermögen',
          expansionGrowthFinancing: 'Wachstumskapital',
          debtFinancing: 'Schuldenfinanzierung',
          otherPurposes: 'Sonstige Zwecke',
        },
      },
      bookkeeping: {
        label: {
          termLoan: 'Finanzunterlagen',
          wcl: 'Bilanzierungsmethode',
        },
        tooltip:
          'Sie verwenden eine Einnahmenüberschussrechnung, wenn in Ihrem Jahresbericht die Worte „Einnahmen“, „Betriebseinnahmen“, „Ausgaben“, „Betriebsausgaben“ oder „Betrieblicher Gewinn“ stehen.\n' +
          '<br /><br />\n' +
          'Es handelt sich um eine Bilanz nach HGB bzw. Steuerrecht, wenn in Ihrem Jahresbericht die Worte „Bilanz nach Handelsgesetzbuch § 266“, „Handelsbilanz“, „Steuerbilanz“, „Aktiva“, „Passiva“, „Eigenkapital“ oder „Bilanzsumme“ stehen.',
        ingToolTip: `Um eine Bilanz nach HGB bzw. Steuerrecht handelt es sich, wenn in Ihrem Jahresbericht die Worte „Bilanz nach Handelsgesetzbuch § 266“, „Handelsbilanz“, „Steuerbilanz“, „Aktiva“, „Passiva“, „Eigenkapital“ oder „Bilanzsumme“ stehen.{br}Sie verwenden eine Einnahmenüberschussrechnung, wenn in Ihrem Jahresbericht die Worte „Einnahmen“, „Betriebseinnahmen“, „Ausgaben“, „Betriebsausgaben“ oder „Betrieblicher Gewinn“ stehen.`,
        dropdown: {
          hgb: 'Bilanz (HGB/Steuerrecht)',
          gewinnUndVerlustrechnung: 'Einnahmenüberschussrechnung',
          unbekannt: 'Unbekannt',
        },
      },
      uid: {
        label: 'UID (z.B. CHE-123.234.456)',
        branchOfficeUidError:
          'Your UID has been replaced by the UID of your main branche',
      },
      dateFounded: {
        label: 'Gründungsdatum (MM.JJJJ)',
        partnerLabel: 'Gründungsdatum (TT.MM.JJJJ)',
      },
      accountingType: {
        label: 'Buchführungspflicht',
        dropdown: {
          detailed: 'Ordentlich',
          simple: 'Eingeschränkt',
        },
      },
      dob: {
        shortLabel: 'Geburtsdatum',
        label: 'Geburtsdatum (TT.MM.JJJJ)',
      },
      schufaApproval: {
        preLabelText:
          'Hiermit willige ich ein, dass Lendico eine SCHUFA-Auskunft gem. der ',
        linkLabelText: 'Datenschutzerklärung',
        postLabelText: ' über meine Person einholt.',
        dropdown: {
          yes: 'Ja, Lendico duerft mein SCHUFA-dings angreifen',
          no: 'Ich bin nicht Vertretungsberechtigt',
        },
      },
      userCurrentAddress: {
        label: 'Meldeadresse (Hauptwohnsitz)',
      },
      userStreet: {
        label: 'Straße und Hausnummer',
      },
      userPostcodeDe: {
        label: 'PLZ',
      },
      userCity: {
        label: 'Stadt',
      },
      identificationIdType: {
        label: 'Ausweisdokument',
        dropdown: {
          ID: 'Personalausweis',
          PASSPORT: 'Reisepass',
        },
        footnote:
          'Bitte schicken Sie uns eine Kopie Ihrer aktuellen Meldebescheinigung an ',
      },
      identificationIdNumber: {
        ID: 'Ausweisnummer',
        PASSPORT: 'Pass-Nummer',
      },
      identificationIssuingAuthority: {
        label: 'Behörde / Ausstellungsort ',
        tooltip:
          'Geben Sie bitte den Namen der Behörde an, die das Ausweisdokument ausgestellt hat. Die Angabe muss mit der in Ihrem Ausweisdokument übereinstimmen.',
      },
      identificationPlaceOfIssue: {
        label: 'Ausstellungsland',
      },
      identificationIssuingDate: {
        shortLabel: 'Ausstellungsdatum',
        label: 'Ausstellungsdatum (TT.MM.JJJJ)',
      },
      identificationExpiryDate: {
        label: 'Gültig bis (TT.MM.JJJJ)',
      },
      taxNumber: {
        label: 'Pers. Steueridentifikationsnummer (Steuer-ID)',
        tooltip:
          'Die 11-stellige persönliche Steuer-Identifikationsnummer (nicht zu verwechseln mit der persönlichen Steuernummer oder der Steuernummer des Unternehmens).',
      },
      companyTaxNumber: {
        label: 'Steuernummer',
        tooltip:
          'Die 10- bis 13-stellige Steuernummer des Unternehmens (nicht zu verwechseln mit der persönlichen Steuer-Identifikationsnummer).',
      },
      controllingCompanyTaxNumber: {
        label: 'Steuernummer des Unternehmens',
        tooltip:
          'Die 10- bis 13-stellige Steuernummer des Unternehmens (nicht zu verwechseln mit der persönlichen Steuer-Identifikationsnummer).',
      },
      vatNumber: {
        label: 'Umsatzsteueridentifikationsnummer',
      },
      termsAndConditions: {
        text1: 'Ich akzeptiere die ',
        link1Text: 'Allgemeinen Geschäftsbedingungen für Kreditnehmer ',
        link1Src: 'agb',
        text2:
          'der Lendico Deutschland GmbH (im Folgenden „Lendico“) und bestätige, dass ich sowie alle (weiteren) im Handelsregister eingetragenen Geschäftsführer und Gesellschafter, die Datenschutzerklärungen von',
        link2Text: 'Lendico',
        link2Src: 'datenschutz',
        text3: 'sowie der',
        link3Text: 'ING-DiBa AG',
        link3Src: 'https://ing.de/dokumente/datenschutz-geschaeftskunden/',
        text4: '(im Folgenden „ING“) zur Kenntnis genommen habe.',
      },
      marketingAgreement: {
        preMailLabel: {
          label1:
            'Ich bin damit einverstanden, auf meine Bedürfnisse angepasste Angebote zu Finanzprodukten von Lendico, ING und ausgewählten ',
          label2: 'Finanzierungspartnern',
          label3:
            'per E-Mail und telefonisch zu erhalten - jederzeit widerrufbar (E-Mail an',
        },
        postMailLabel: ').',
      },
      ident: {
        title: 'Jetzt erneut legitimieren',
        info: {
          p1:
            'Sie sind Kunde bei uns und möchten sich legitimieren. Dazu benötigen wir bitte zunächst Ihre persönlichen Daten. ' +
            'Sie werden anschließend zum POSTIDENT Service der Deutschen Post weitergeleitet. Vielen Dank!',
          p2: 'In unserem Wissensbereich finden Sie <a href="https://www.ing.de/wissen/nachlegitimation/" target="_blank" class="ing-link">weitere Informationen zur erneuten Legitimation.</a>',
        },
        personInfoHeader: 'Person',
        contactInfoHeader: 'Kontakt',
        submit: 'Daten übernehmen',
        privacyPolicy:
          'Mit meinem Klick auf den Button akzeptiere ich, dass die ING-DiBa AG durch den Partner Deutsche Post AG meine persönlichen Daten, ' +
          'meine Ausweisdaten und ein Foto des Ausweises zur Überprüfung meiner Identität erhebt und verarbeitet. ' +
          'Bei der Identifikation durch Video-Chat bin ich damit einverstanden, dass das gesamte Gespräch mittels ' +
          'Video aufgezeichnet und ein Portraitfoto angefertigt wird. Die ING-DiBa AG speichert die erhobenen Daten, ' +
          'Fotos und das Video und löscht die Daten frühestens 5 Jahre nach Ende der Geschäftsbeziehung. ' +
          'Diese Einwilligung kann ich jederzeit mit Wirkung für die Zukunft gegenüber der ING-DiBa AG widerrufen.',
      },
      referenceNumber: {
        label: 'Referenznummer',
      },
      emailAddress: {
        label: 'E-Mail Adresse',
      },
    },
    errors: {
      repetitions:
        'Eine E-Mail-Adresse oder Mobilfunknummer wurde mehrmals verwendet.',

      GeneralPartnerError: {
        part1: 'Bitte überprüfen Sie Ihre Angaben. Sind Ihre Daten korrekt?',
        part2: 'Dann registrieren Sie sich unter ',
        link: 'Partner werden',
        part3: ', bevor Sie einen Antrag einreichen.',
      },

      DefaultIsRequired: 'Diese Angabe ist erforderlich.',
      DefaultIsNotCorrect: 'Diese Angabe ist nicht gültig.',
      PostcodeDeIsNotCorrect: 'Bitte geben Sie eine gültige Postleitzahl ein',
      FutureDateIsRequired:
        'Ihr Ausweis ist möglicherweise abgelaufen. Bitte überprüfen Sie Ihre Eingabe oder wählen Sie ein anderes Ausweisdokument',

      ForbiddenCharacters: 'Bitte verwenden Sie das lateinische Alphabet',
      DefaultIsNotAlphabetic: 'Bitte verwenden Sie nur Buchstaben von A bis Z.',
      NumericCharacters:
        'Die Eingabe von Zahlen ist in diesem Feld nicht möglich.',
      NonNumericCharacters: 'Bitte geben Sie in diesem Feld eine Zahl ein.',

      ServerError: 'Server Fehler. Bitte versuchen Sie es nochmals.',

      CityOfBirthIsRequired: '@:forms.errors.DefaultIsRequired',
      CityOfBirthIsIncorrect: '@:forms.errors.DefaultIsNotCorrect',
      CityOfBirthIsNotAlphabetic: '@:forms.errors.DefaultIsNotAlphabetic',
      ZipIsRequired: '@:forms.errors.DefaultIsRequired',
      ZipIsNAN: '@:forms.errors.PostcodeDeIsNotCorrect',
      ZipIsInvalidLength: '@:forms.errors.PostcodeDeIsNotCorrect',
      PostalZipIsNAN: '@:forms.errors.PostcodeDeIsNotCorrect',
      PostalZipIsRequired: '@:forms.errors.DefaultIsRequired',
      PostalZipIsInvalidLength: '@:forms.errors.PostcodeDeIsNotCorrect',
      PostcodeDeIsRequired: '@:forms.errors.DefaultIsRequired',
      PostcodeDeIsNAN: '@:forms.errors.PostcodeDeIsNotCorrect',
      PostcodeDeIsInvalidLength: '@:forms.errors.PostcodeDeIsNotCorrect',
      RevenueIsRequired: '@:forms.errors.DefaultIsRequired',
      AuthorisedSignatoryIsRequired: '@:forms.errors.DefaultIsRequired',
      UserStreetIsRequired: '@:forms.errors.DefaultIsRequired',
      UserPostCodeDeIsRequired: '@:forms.errors.DefaultIsRequired',
      UserPostcodeDeIsNAN: '@:forms.errors.PostcodeDeIsNotCorrect',
      UserPostcodeDeIsInvalidLength: '@:forms.errors.PostcodeDeIsNotCorrect',
      UserCityIsRequired: '@:forms.errors.DefaultIsRequired',
      DobIsRequired: '@:forms.errors.DefaultIsRequired',
      SchufaApprovalIsRequired: '@:forms.errors.DefaultIsRequired',
      DobIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      DobIsNotAboveMinAge: 'Ungültiges Geburtsdatum',

      PartnerCompanyNameIsRequired: '@:forms.errors.DefaultIsRequired',
      PartnerCompanyNameIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      PartnerFirstNameIsRequired: '@:forms.errors.DefaultIsRequired',
      PartnerFirstNameIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      PartnerLastNameIsRequired: '@:forms.errors.DefaultIsRequired',
      PartnerLastNameIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      PartnerEmailIsRequired: '@:forms.errors.DefaultIsRequired',
      PartnerEmailIsNotCorrect: '@:forms.errors.EmailIsNotCorrect',
      PartnerEmailAlreadyExists:
        'Diese E-Mail-Adresse wird bereits verwendet. Gerne können Sie direkt einen Kreditantrag einreichen',

      AccountingTypeIsRequired: '@:forms.errors.DefaultIsRequired',
      AccountingTypeIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      AssetsIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      AssetsIsRequired: '@:forms.errors.DefaultIsRequired',
      CashIntensityIsRequired: '@:forms.errors.DefaultIsRequired',
      CityIsRequired: '@:forms.errors.DefaultIsRequired',
      PostalCityIsRequired: '@:forms.errors.CityIsRequired',
      CompanyIsRequired: '@:forms.errors.DefaultIsRequired',
      LegalNameIsRequired: '@:forms.errors.DefaultIsRequired',
      CountryIsRequired: '@:forms.errors.DefaultIsRequired',
      DateFoundedRequired: '@:forms.errors.DefaultIsRequired',
      DateFoundedIsNotCorrect: 'Das Datum ist nicht gültig.',
      EmailIsRequired: '@:forms.errors.DefaultIsRequired',
      EmailIsNotCorrect: 'Bitte geben Sie eine gültige E-Mail-Adresse ein.',
      EmailHasWrongStructure:
        'Bitte überprüfen Sie, ob Sie die E-Mail-Adresse korrekt angegeben haben. Beispiel: ihrname@beispiel.de',
      EmployeesNumberIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      EmployeesNumberIsRequired: '@:forms.errors.DefaultIsRequired',
      FirstNameIsRequired: '@:forms.errors.DefaultIsRequired',
      GenderIsRequired: '@:forms.errors.DefaultIsRequired',
      GenderIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      IdentificationProcessIsRequired: '@:forms.errors.DefaultIsRequired',
      IdentificationIdNumberIsRequired: '@:forms.errors.DefaultIsRequired',
      IdentificationIdTypeIsRequired: '@:forms.errors.DefaultIsRequired',
      IdentificationIssuingAuthorityIsRequired:
        '@:forms.errors.DefaultIsRequired',
      IdentificationIssuingDateIsRequired: '@:forms.errors.DefaultIsRequired',
      IdentificationExpiryDateIsRequired: '@:forms.errors.DefaultIsRequired',
      IdentificationExpiryDateIsNotCorrect:
        '@:forms.errors.DefaultIsNotCorrect',
      IdentificationPlaceOfIssueIsRequired: '@:forms.errors.DefaultIsRequired',
      IbanIsRequired: '@:forms.errors.DefaultIsRequired',
      IbanIsNotCorrect: 'Bitte geben Sie eine gültige IBAN ein.',
      IbanServerError:
        'Die angegebene IBAN ist nicht gültig oder wird bereits verwendet. Bitte beachten Sie, dass die IBAN keine Leerzeichen enthalten darf.',
      LastNameIsRequired: '@:forms.errors.DefaultIsRequired',
      LastNameAtBirthHasNumeric: '@:forms.errors.NumericCharacters',
      LegalStatusIsRequired: '@:forms.errors.DefaultIsRequired',
      LegalStatusIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      LoanPurposeIsRequired: '@:forms.errors.DefaultIsRequired',
      LoanPurposeIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      BookkeepingStandardIsRequired: '@:forms.errors.DefaultIsRequired',
      BookkeepingStandardIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      PasswordIsRequired: 'Bitte geben Sie ein Passwort ein.',
      PasswordIsWeak: 'Bitte geben Sie ein stärkeres Passwort ein.',
      NationalityIsRequired: '@:forms.errors.DefaultIsRequired',
      PhoneIsRequired: '@:forms.errors.DefaultIsRequired',
      LandingPhoneIsRequired: 'Bitte geben Sie Ihre Telefonnummer ein.',
      PhoneIsNotCorrect: 'Bitte geben Sie eine gültige Mobilfunknummer ein.',
      PostalCodeIsRequired: 'Bitte geben Sie Ihre PLZ ein',
      PostalCityHasNumeric: '@:forms.errors.NumericCharacters',
      NoOtherMDCheckboxIsRequired: '@:forms.errors.DefaultIsRequired',
      SmeConfirmationCheckboxIsRequired:
        'Dieses Feld ist erforderlich. Sollte Ihr Unternehmen die genannten Mitarbeiter-, ' +
        'Umsatz- und Bilanzgrenzen übersteigen, schicken Sie uns bitte eine Nachricht an ',
      PrivacyPolicyCheckboxIsRequired: 'Bitte stimmen Sie zu.',
      ReferenceNumberIsRequired: '@:forms.errors.DefaultIsRequired',

      PostalCodeServerError: '@:forms.errors.DefaultIsNotCorrect',
      StreetServerError: '@:forms.errors.DefaultIsNotCorrect',
      StreetNumberServerError: '@:forms.errors.DefaultIsNotCorrect',
      AddressComplementServerError: '@:forms.errors.DefaultIsNotCorrect',
      CashIntensityIsIncorrect: '@:forms.errors.DefaultIsNotCorrect',
      CityServerError: '@:forms.errors.DefaultIsNotCorrect',
      IdentificationProcessIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      IdentificationIdNumberIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      IdentificationIdTypeIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      IdentificationIssuingDateIsNotCorrect:
        '@:forms.errors.DefaultIsNotCorrect',
      IdentificationExpiryDateMustBeFuture:
        '@:forms.errors.FutureDateIsRequired',
      NationalityIsNotCorrect:
        'Kein Ergebnis. Bitte geben Sie ein gültiges Land ein.',
      SecondNationalityIsNotCorrect: '@:forms.errors.NationalityIsNotCorrect',
      NationalityEqualsSecondNationality:
        'Bitte überprüfen Sie Ihre Angabe. Die erste und zweite Staatsangehörigkeit dürfen nicht identisch sein.',
      CountryIsNotCorrect: '@:forms.errors.NationalityIsNotCorrect',
      IdentificationPlaceOfIssueIsNotCorrect:
        '@:forms.errors.NationalityIsNotCorrect',
      PhoneServerError: '@:forms.errors.DefaultIsNotCorrect',
      UserIncomeEmploymentStatusServerError:
        '@:forms.errors.DefaultIsNotCorrect',
      UserIncomePositionServerError: '@:forms.errors.DefaultIsNotCorrect',
      UserIncomeEmployerNameServerError: '@:forms.errors.DefaultIsNotCorrect',
      UserIncomeIndustryServerError: '@:forms.errors.DefaultIsNotCorrect',
      UserIncomeAnnualIncomeServerError: '@:forms.errors.DefaultIsNotCorrect',
      UserNetWealthServerError: '@:forms.errors.DefaultIsNotCorrect',
      UserSourceOfWealthServerError: '@:forms.errors.DefaultIsNotCorrect',
      ActingOwnAccountServerError: '@:forms.errors.DefaultIsNotCorrect',
      CompanyTaxNumberIsNAN: '@:forms.errors.NonNumericCharacters',
      CompanyTaxNumberIsRequired: '@:forms.errors.DefaultIsRequired',
      CompanyTaxNumberIsIncorrect: '@:forms.errors.DefaultIsNotCorrect',
      VatNumberIsInvalidLength: '@:forms.errors.DefaultIsNotCorrect',
      VatNumberIsIncorrect: '@:forms.errors.DefaultIsNotCorrect',
      TaxNumberIsInvalidLength: '@:forms.errors.DefaultIsNotCorrect',
      TaxNumberIsRequired: '@:forms.errors.DefaultIsRequired',
      TaxNumberIsNAN: '@:forms.errors.NonNumericCharacters',
      PersonTaxNumberIncorrectLength:
        'Bitte geben Sie eine elfstellige Zahl als Steuernummer ein.',
      PersonTaxNumberNotUnique:
        'Sie haben diese Nummer bereits in einem anderen Feld eingegeben. Bitte geben Sie in diesem Feld die persönliche Steuer-Identifikationsnummer ein.',

      PrivacyPolicyIsRequired: 'Bitte Datenschutzbestimmungen akzeptieren!',
      SalutationIsRequired: '@:forms.errors.DefaultIsRequired',
      SalutationIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      StreetIsRequired: '@:forms.errors.DefaultIsRequired',
      PostalStreetIsRequired: '@:forms.errors.StreetIsRequired',
      StreetNumberIsRequired: 'Bitte geben Sie Ihre Hausnummer ein.',
      StreetNumberIsIncorrect: '@:forms.errors.DefaultIsNotCorrect',
      PostalStreetNumberIsRequired: '@:forms.errors.StreetNumberIsRequired',
      PostalStreetNumberIsIncorrect: '@:forms.errors.DefaultIsNotCorrect',
      RevenuesIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      RevenuesIsRequired: '@:forms.errors.DefaultIsRequired',
      TermsAndConditionsIsRequired:
        'Bitte bestätigen Sie, dass Sie die Datenschutzerklärung zur Kenntnis genommen haben.',
      AmazonTermsAndConditionsIsRequired:
        'Bitte bestätigen Sie, dass Sie die AGB und Datenschutzerklärung zur Kenntnis genommen haben.',
      TitleIsNotCorrect: '@:forms.errors.DefaultIsNotCorrect',
      UidIsRequired: '@:forms.errors.DefaultIsRequired',
      UidIsNotCorrect: '@:forms.errors.DefaultIsRequired',

      ActingOwnAccountIsRequired: '@:forms.errors.DefaultIsRequired',
      BankNameIsRequired: '@:forms.errors.DefaultIsRequired',
      BicIsRequired: '@:forms.errors.DefaultIsRequired',
      BicIsNotCorrect: 'Bitte geben Sie eine gültige BIC ein.',
      InvestorValidationFilesMinCountIsNotCorrect:
        'Sie müssen mehr als zwei Dateien hochladen',
      InvestorValidationFilesMaxSizeIsNotCorrect:
        'Sie können nicht mehr als fünf Dateien hochladen',
      InvestorValidationFilesTypeIsNotCorrect:
        '@:forms.errors.DefaultIsNotCorrect',
      OwnerIsRequired: '@:forms.errors.DefaultIsRequired',
      UserIncomeAnnualIncomeIsRequired: '@:forms.errors.DefaultIsRequired',
      UserIncomeEmployerNameIsRequired:
        "Diese Angabe ist erforderlich. Falls nicht zutreffend, 'keine' eintragen",
      UserIncomeEmploymentStatusIsRequired: '@:forms.errors.DefaultIsRequired',
      UserIncomeIndustryIsRequired:
        "Diese Angabe ist erforderlich. Falls nicht zutreffend, 'keine' eintragen",
      UserIncomePositionIsRequired:
        "Diese Angabe ist erforderlich. Falls nicht zutreffend, 'keine' eintragen",
      UserSourceOfWealthIsRequired: '@:forms.errors.DefaultIsRequired',
      UserNetWealthIsRequired: '@:forms.errors.DefaultIsRequired',
    },
  },
  status: {
    loanRequest: {
      open: 'In Verarbeitung',
      origination: 'In Auszahlung',
      canceled: 'Storniert',
    },
    payment: {
      late: 'teilweise bezahlt',
      paid: 'bezahlt',
      future: '--',
      partially_paid: 'teilweise bezahlt',
    },
    accountingType: {
      detailed: 'Ordentlich',
      simple: 'Eingeschränkt',
    },
  },
  fileField: {
    browserIsNotSupporting: 'Dieser Browser wird nicht unterstützt.',
    dragOverHere: 'Bitte hier hin ziehen.',
  },
  loanCalculator: {
    serverError:
      'Ihre Anfrage konnte nicht verarbeitet werden. Bitte versuchen Sie es später erneut oder {link}',
    serverErrorLinkTitle: 'kontaktieren Sie uns',
    amountLabel: 'Betrag',
    interestRateLabel: 'Zinssatz',
    monthlyRateLabel: 'Monatliche Rate',
    durationLabel: 'Laufzeit',
    step1: {
      buttonTitle: 'Weiter',
      buttonLabel: 'Kreditentscheidung in 48 Std.',
      buttonFooter: 'Unverbindlich und kostenlos!',
    },
    step2: {
      buttonTitle: 'ANFRAGE ÄNDERN',
      buttonLabel:
        'Diese unverbindliche Berechnung ist inklusive aller Gebühren und Zinsen in der besten Risikoklasse - es gibt keine versteckten Kosten.',
    },
    footer: '<sup>*</sup>zeigt die Risikoklasse A',
  },
  userMyProfileBlock: {
    panels: {
      yourDetails: {
        title: 'Ihre Daten',
        salutation: 'Anrede',
        firstName: 'Vorname',
        lastName: 'Name',
        email: 'E-Mail',
        resetPassword: 'Passwort zurücksetzen',
        mySubscriptions: 'Meine Newsletters',
      },
      bankDetails: {
        title: 'Kontodaten',
        accountHolder: 'Kontoinhaber',
        iban: 'IBAN',
        bic: 'BIC',
        bank: 'Name der Bank',
      },
    },
    wantToChangeTitle: 'SIE MÖCHTEN IHRE DATEN ÄNDERN?',
    wantToChangeMessage: {
      preLinkTitleText:
        'Wenn Sie Ihre persönlichen Daten ändern möchten, schreiben Sie bitte eine E-Mail an: ',
      linkTitleText: '@:email',
      postLinkText: '.',
    },
  },
  marketItemBlock: {
    footerTitle: '',
    footerContent:
      'Angezeigt werden die Rendite (unter Berücksichtigung der Service-Gebühr) und die planmäßigen Rückzahlungen der Anlage. Die ausgewiesene Rendite und Rückzahlung ist kein Zahlungsversprechen. Da der von Ihnen finanzierte Kredit ausfallen kann, besteht die Gefahr, dass die tatsächliche Rendite erheblich unter der ausgewiesenen Rendite liegt oder es sogar zu einem Totalverlust des investierten Kapitals kommt. Um Ihr Risiko zu reduzieren, raten wir dazu, Ihre Gesamt-Anlage auf verschiedene Kreditprojekte zu verteilen.<br/>Die gezeigten Informationen zum Kreditnehmer, zur Beschäftigung und zur Haushaltsrechnung wurden bei der Antragstellung vom Kreditnehmer eingegeben. Weder die vom Kreditnehmer gemachten Angaben noch dessen Zahlungsfähigkeit wurden von Lendico geprüft.',
    company: {
      title: 'Unternehmen',
      rows: {
        location: 'Sitz',
        industry: 'Branche',
        legalForm: 'Rechtsform',
        numberOfEmployees: 'Anzahl Mitarbeiter',
        date: 'Gründungsdatum',
      },
    },
    riskOverview: {
      title: 'Risikoübersicht',
      rows: {
        riskClass: 'Risikoklasse',
        intrumRiskIndicator: 'Intrum Risikoklasse',
        personalGuarantee: {
          title: 'Persönliche Bürgschaft',
          yes: 'Bürgschaft vorhanden',
          no: 'Bürgschaft nicht vorhanden',
        },
        crifRiskIndicator: 'Crif Risikoklasse',
      },
    },
    financialData: {
      title: 'Finanzdaten',
      rows: {
        equityRatio: 'Eigenkapitalquote',
        tooltipEquityRatio:
          'Die Eigenkapitalquote gibt an, wie hoch der Anteil des Eigenkapitals am Gesamtkapital eines Unternehmens ist.',
        interestCoverageRatio: 'Zinsdeckungsgrad',
        tooltipInterestCoverageRatio:
          'Der Zinsdeckungsgrad zeigt die Fähigkeit eines Unternehmens, Zinsen auf seine ausstehenden Schulden zu zahlen.',
        indebtednessRatio: 'Entschuldungsdauer in Jahren',
        tooltipIndebtednessRatio:
          'Die Entschuldungsdauer gibt an, in wie vielen Jahren ein Unternehmen das Fremdkapital zurückzahlen könnte, wenn das EBITDA (Erträge vor Steuern, Zinsen und Abschreibungen) ausschließlich zur hierfür verwendet wird.',
        returnOnInvestment: 'Kapitalrendite',
        tooltipReturnOnInvestment:
          'Die Kapitalrendite wird auch ROI (Return on Investment) genannt. Sie ergibt sich als Produkt aus den Faktoren Umsatzrendite und Kapitalumschlag.',
        liabilitiesToNetSales: 'Operative Effizienz',
        tooltipLiabilitiesToNetSales:
          'Mit der operativen Effizienz wird das Verhältnis der kurzfristigen Verbindlichkeiten in Bezug zu den Nettoumsätzen ermittelt.',
        growth: 'Umsatzwachstum',
        tooltipGrowth:
          'Das Umsatzwachstum bezeichnet die prozentuale Veränderung des Umsatzes eines Unternehmens im Vergleich zum Vorjahr.',
      },
      reachRows: {
        profitAndLossAccount: 'Gewinn und Verlustrechnung',
        balanceSheet: 'Bilanz',
        BWA: 'BWA',
        financialStatements: 'Jahresabschluss',

        overallPerformance: 'Gesamtleistung',
        goodsSold: 'Wareneinsatz',
        personnelCosts: 'Personalkosten',
        otherOperatingCosts: 'andere betriebliche Kosten',
        operatingResult: 'Operatives Ergebnis',
        earningsBeforeInterestTaxes: 'Ergebnis vor Zinsen und Steuern',
        netIncomeShortfall: 'Jahresüberschuss / Fehlbetrag',

        assets: 'Aktiva',
        currentAssets: 'Umlaufvermögen',
        fixedAssets: 'Anlagevermögen',
        otherAssets: 'Sonstige Aktiva',
        liabilities: 'Passiva',
        equity: 'Eigenkapital',
        accruals: 'Rückstellungen',
        currentLiabilities: 'kurzfristige Verbindlichkeiten',
        longTermLiabilities: 'langfristige Verbindlichkeiten',
        otherLiabilities: 'Sonstige Passiva',
      },
      faqLink: {
        preLinkTitleText: 'Sie haben Fragen zu den Finanzdaten? ',
        linkTitleText: 'Investor-FAQs',
      },
    },
    marketItem: {
      investButton: 'Anlegen',
      rows: {
        amount: 'Betrag',
        duration: 'Laufzeit',
        monthlyPayment: 'Monatliche Rate',
        interestPercentage: 'Zinssatz',
        fundedPercentage: '% finanziert',
        fundedAmount: 'Noch offen',
      },
    },
    info: {
      rows: {
        businessDescription: 'Was macht Ihr Unternehmen?',
        purposeDescription: 'Wofür soll der Kredit verwendet werden?',
        reliability: 'Warum ist es sicher, Ihnen einen Kredit zu gewähren?',
      },
      pleaseLoginMessage:
        'Bitte registrieren Sie sich oder loggen Sie sich ein, um mehr Details zu sehen.',
    },
  },
  pledgeForm: {
    bidLabel: 'Anlegen',
  },
  confirmBiddingModal: {
    title: 'GEBOT BESTÄTIGEN',
    serverError:
      'Es scheint ein Problem mit Ihrem Konto zu geben. Bitte kontaktieren Sie Lendico (Error 500).',
    description:
      'Bitte überprüfen und bestätigen Sie Ihr Gebot für das unten stehende Kreditprojekt.',
    agreeMessage:
      'Mit dem Klick auf die Schaltfläche unten bestätige ich, dass ich die Verträge zu meinen Investitionen per E-Mail erhalten habe und akzeptiere diese. Das mit meinen Investitionen verbundene Risiko habe ich verstanden.',
    rows: {
      loanId: 'Kreditprojekt ID',
      amount: 'Betrag',
      interestPercentage: 'Zinssatz p.a.',
      class: 'Klasse',
      bid: 'Gebot',
    },
    buttons: {
      cancel: 'Abbrechen',
      confirm: 'Jetzt kostenpflichtig anlegen',
    },
  },
  confirmBiddingSuccessModal: {
    title: 'VIELEN DANK!',
    description: 'Ihr Gebot wurde erfolgreich abgeschlossen',
    rows: {
      loanId: 'Kreditprojekt ID',
      amount: 'Betrag',
      interestPercentage: 'Zinssatz p.a.',
      class: 'Klasse',
      bid: 'Gebot',
    },
    buttons: {
      close: 'ZURÜCK ZUR ÜBERSICHT AKTUELLER KREDITPROJEKTE',
    },
  },
  marketItemPage: {
    metaTitle: 'Kreditübersicht',
    metaDescription: 'Kreditübersicht',
    title: 'Kreditübersicht',
    pleaseLoginMessage:
      'Bitte registrieren Sie sich oder loggen Sie sich ein,<br/>um mehr Details zu sehen.',
  },
  subscriptionPage: {
    title: 'Newsletterverwaltung',
    header: 'Wie möchten sie newsletter von uns empfangen?',
    chooseFrequency:
      'Wählen Sie Ihre gewünschte Frequenz, mit der Sie Newsletter von uns erhalten möchten.',
    unsubscribe: 'Abmelden',
    unsubscribeAll: 'Abmelden alle',
    newsletters: {
      newsletter_new_project: 'Benachrichtigung bei neuen Projekten',
      newsletter_weekly_portfolio: 'Wöchentlicher Portfolio-Überblick',
      newsletter_news_and_offers:
        'Neuigkeiten und exklusive Angeboten von Lendico',
    },
    frequency: {
      monthly: 'monatlich',
      weekly: 'wöchentlich',
      daily: 'täglich',
    },
  },
  pagination: {
    previous: 'Previous',
    next: 'Next',
    first: 'First',
    last: 'Last',
  },
  consumerLoanBlock: {
    borrowerInfo: {
      title: 'Informationen Kreditnehmer',
      purposeTitle: 'Kreditbeschreibung',
      gender: 'Geschlecht',
      age: 'Alter',
      martialStatus: 'Familienstand',
      livingSituation: 'Wohnsituation',
      numberFinancialDependents:
        'Anzahl der Personen, die finanziell unterstützt werden',
    },
    employmentInformation: {
      title: 'Informationen zur Beschäftigung',
      employmentStatus: 'Beschäftigungsstatus',
      employmentLength: 'Beschäftigungsdauer',
      contractType: 'Vertragstyp',
      industry: 'Branche',
    },
    costOfLiving: {
      title: 'Information zur Haushaltsrechnung',
      monthlyNetIncome: 'Monatliches Nettoeinkommen',
      selfEmploymentIncome: 'Einkünfte aus selbstständiger Tätigkeit',
      otherIncome: 'Weitere monatliche Einnahmen',
      childAssistance: 'Kindergeld',
      childBenefit: 'Kindergeld',
      any: 'Mieteinnahmen',
      pension: 'Rente',
      rent: 'Miete',
      leasing: 'Leasing',
      totalIncome: 'Gesamteinnahmen',
      mortgageExpenses: 'Baufinanziergungsbeitrag',
      rentalCosts: 'Mietkosten',
      healthInsurance: 'Krankenversicherung',
      existingCreditRepayments: 'Laufende Kredite',
      totalExpenses: 'Gesamtausgaben',
      disposableIncome: 'Verfügbares Einkommen',
    },
    maritalStatus: {
      divorced: 'geschieden',
      domestic_partnership: 'nichteheliche Lebensgemeinschaft',
      married: 'verheiratet',
      separated: 'getrennt',
      single: 'ledig',
      widowed: 'verwitwet',
    },
    home: {
      living_with_parents: 'wohnt mit den Eltern',
      own: 'Eigentum',
      rent: 'Zur Miete',
    },
    employmentStatus: {
      freelancer: 'Freiberufler',
      house_wife_husband: 'Hausfrau / -mann',
      manual_worker: 'Handwerker/- in',
      public_official: 'Beamtin / Beamte',
      retired: 'Ruhestand',
      salaried: 'Angestellt',
      self_employed: 'Selbstständig',
      soldier: 'Soldatin / Soldat',
      student: 'Studentin / Student',
      without_employment: 'Arbeitslos',
    },
    contractType: {
      permanent: 'dauerhaft',
      temporary: 'vorläufig',
    },
    industry: {
      agriculture: 'Landwirtschaft',
      arts_entertainment_and_recreation: 'Künste & Unterhaltung',
      banks: 'Bankwesen',
      construction: 'Bauwesen',
      Dienstleistungen: 'Dienstleistungen',
      education: 'Bildung',
      edv_beratung: 'IT-Beratung',
      Erziehung_und_Unterricht: 'Erziehung und Unterricht',
      financial_and_insurance_services: 'Finanz- & Versicherungswesen',
      health_and_social_care: 'Gesundheitswesen & Pflege',
      hospitality: 'Gastwirtschaft',
      hotels_gastronomy: 'Hotelwesen & Gastronomie',
      information_and_communication: 'Informatons- & Kommunikationswesen',
      internet: 'Internet',
      land_and_housing: 'Immobilienwirtschaft',
      manufacturing: 'Produktion & Fertigung',
      mining_and_mineral: 'Bergbau',
      other: 'anderes',
      power_supply: 'Energiewesen',
      processing_trade_and_crafts: 'Handel & Handwerk',
      professional_scientific_and_technical_services:
        'Wissenschaft & Forschung',
      public_administration_and_defense_compulsory_social_security:
        'Öffentliche Verwaltung',
      services: 'andere Dienstleistungen',
      sonstiges: 'sonstiges',
      trade: 'Handelswesen',
      transportation_and_storage: 'Logistik',
      Verkehr_und_Lagerei: 'Logistik',
    },
    bottomStatusBlock: {
      documentStateTitle: 'Überprüfung der Dokumente',
      documentState: {
        new: 'noch nicht hochgeladen',
        upload_started: 'noch nicht verifiziert',
        upload_reopened: 'noch nicht verifiziert',
        awaiting_verification: 'wartet auf Verifizierung',
        verified: 'Verifiziert',
      },
      shufaScoreTitle: 'Bestätigt',
      shufaScore: 'Score: {score}',
      shufaNoScore: 'Zur zeit kann kein Score gefunden werden.',
      postIndent: 'Kreditnehmer wird verifiziert durch',
    },
  },
  WithdrawPrepaidBalanceModal: {
    buttonClose: 'close',
    preLinkText:
      'Um Geld von Ihrem Premium-Konto abzuheben, senden Sie uns bitte eine E-Mail mit dem gewünschten Betrag an ',
    mailSubject: '?subject=Premium-Konto Auszahlung',
    linkTitle: 'auszahlung@lendico.de',
    postLinkText:
      '. Bitte beachten Sie, dass wir nur Abhebungen durchführen können, die von der E-Mailadresse angefordert wurden, die Sie auch für den Login in Ihr Anleger-Dashboard verwenden. Sobald die Zahlung angewiesen wurde, werden wir Sie per E-Mail darüber informieren.',
    amount: 'Kontostand',
  },
  infoModal: {
    title: '',
    p1: 'Lendico hat sein Corporate-Design und Investor-Dashboard erneuert. Informationen zu den Änderungen im Dashboard finden Sie unter der Registerkarte "Hilfe". Für weitere Informationen kontaktieren Sie uns an Werktagen von 9.00 bis 18.00 Uhr unter <a href="tel:08001420400">0800 14 20 400</a>.',
    p2: '',
    p3: '',
    closeButton: 'Schließen',
  },
  oweUsCash: {
    title: 'Hinweis:',
    p: '<p>Die im Dashboard angezeigte Kontoübersicht entspricht nicht Ihrem bestehenden Guthaben.</p><p>Wir hatten Sie bereits mit E-Mail vom 07. Juni 2017 darauf hingewiesen, dass Ihr Portfolio zurzeit aufgrund von fehlerhaften Buchungen überzahlt ist und Sie daher zur Rückzahlung aufgefordert. Leider ist trotz mehrfacher Bitte bis heute keine Rückzahlung an uns erfolgt.</p><p class="bold">Wir erklären daher ausdrücklich, dass wir an unserem Rückzahlungsanspruch gegen Sie festhalten.</p><p>Kontaktieren Sie uns bitte diesbezüglich schnellstmöglich per E-Mail an <a href="mailto:investor@lendico.de">investor@lendico.de</a> oder per Telefon unter <a href="tel:08001420400">0800 / 14 20 400</a>.</p>',
  },
  PageNotFound: {
    title: '404 Fehler',
    h1: 'Ein 404-Fehler ist aufgetreten',
    p1: 'EIN FEHLER IST AUFGETRETEN',
    p2: 'Bitte versuchen Sie es erneut oder kehren Sie zur {homePage} zurück. Sollte der Fehler wiederholt auftreten, kontaktieren Sie uns unter 030 398 205 260 oder {email}.',
    homePage: 'Startseite',
    email: 'unternehmen@lendico.de',
    homeUrl: 'https://www.lendico.de/',
    mailLink: 'mailto:unternehmen@lendico.de',
  },
  logoutSection: {
    offerUI: {
      title: 'Logout. Sie haben sich erfolgreich abgemeldet.',
      description:
        'Melden Sie sich erneut an, um Ihre Identifizierung und Vertragsunterzeichnung abzuschließen.',
    },
    clientUI: {
      title: 'Logout. Sie haben sich erfolgreich abgemeldet.',
      description:
        'Melden Sie sich erneut an, um Ihre Daten zu vervollständigen.',
    },
    loginButton: 'Log-in',
    logoutButton: 'Logout',
  },
};
