<template>
  <fieldset
    :class="{ error: displayError }"
    class="form-field addMaterialDesign fieldset-container"
  >
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !value,
      }"
      class="form-group label-floating"
    >
      <label class="control-label" v-html="label" />
      <input
        ref="date-input"
        :name="name"
        :class="{
          'show-text': shouldShowText,
        }"
        inputmode="numeric"
        class="form-control imask-input"
        data-lpignore="true"
        @focus="handleFocus"
        @blur="isFocused = false"
      />
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>
<script>
  import { dateInputFunctionality } from '@/mixins/forms/dateInputMixin';
  export default {
    mixins: [dateInputFunctionality],
  };
</script>
<style scoped>
  .form-group.imask-input {
    padding-bottom: 0;
  }
  .error-container {
    min-height: 20px;
    overflow: hidden;
  }
  .validation {
    margin-bottom: 0px;
  }
  .imask-input.form-control {
    color: white;
    font-size: 16px;
  }
  .imask-input.form-control.show-text {
    transition: color 0.5s ease;
    color: black;
  }
</style>
