<template>
  <div>
    <div class="overview-container__subsection dark-background">
      <h3>
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.firstInstallment.label'
          )
        }}
      </h3>
      <p>
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.firstInstallment.description.editMode'
          )
        }}
      </p>
      <div class="row">
        <div class="col-sm-8">
          <div class="disbursement-option">
            <len-radio
              v-model="isPartialDisbursement"
              data-unit-test="ing-offer-conditions-details-form-full-amount"
              name="full_amount"
              :value="false"
            >
              <template #label>
                <i18n
                  path="signersMissingInfo.formPage.offerConditions.form.wcl.fullDisbursement"
                >
                  <template #percentage> 100 </template>
                  <template #amount>
                    <em>{{ currencyAmount(loanAmount) }}</em>
                  </template>
                </i18n>
              </template>
            </len-radio>
          </div>
          <div class="disbursement-option">
            <len-radio
              v-model="isPartialDisbursement"
              data-unit-test="ing-offer-conditions-details-form-partial-amount"
              name="partial_amount"
              :value="true"
              :label="
                $t(
                  'signersMissingInfo.formPage.offerConditions.form.wcl.anyAmount'
                )
              "
            />
            <div class="amount-form">
              <div
                class="form-group amount-input"
                :class="{ 'amount-input--invalid': invalidDisbursementAmount }"
              >
                <input
                  ref="input_amount"
                  v-model="inputAmount"
                  type="text"
                  class="form-control"
                  :disabled="!isPartialDisbursement"
                  @blur="onInputAmountBlur"
                />
                <div class="amount-input__symbol"> € </div>
                <div class="amount-input__hint">
                  {{ amountHint }}
                </div>
              </div>
            </div>
          </div>
          <div
            v-if="hasAmountError"
            :class="{ active: hasAmountError }"
            role="alert"
            class="ing-error"
          >
            <img src="/alert_icon.png" alt="Alert Icon" />
            <p>
              {{ amountError }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="overview-container__subsection">
      <h3>
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.calculation.title'
          )
        }}
      </h3>
      <p>
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.calculation.content'
          )
        }}
      </p>
      <div class="rate-breakdown">
        <div class="rate-breakdown__row">
          <div class="rate-breakdown__label">
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.wcl.interestFirstMonth'
              )
            }}:
          </div>
          <div class="rate-breakdown__value">
            {{ firstMonthInterestRate }}
          </div>
        </div>
        <div class="rate-breakdown__row">
          <div class="rate-breakdown__label">
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.wcl.repaymentPortion'
              )
            }}:
          </div>
          <div class="rate-breakdown__value">
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.wcl.repaymentPortionValue'
              )
            }}
          </div>
        </div>
        <div class="rate-breakdown__row">
          <div class="rate-breakdown__divider" />
        </div>
        <div class="rate-breakdown__row">
          <div class="rate-breakdown__label">
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.wcl.firstMonthRepaymentAmount'
              )
            }}:
          </div>
          <div class="rate-breakdown__value">
            {{ firstMonthRepaymentAmount }} **
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import numeral from 'numeral';
  import LenRadio from '@/components/common/len-radio';
  import { mapMutations } from 'vuex';
  import { currencyAmount, decimalAmount } from '@/utils/currency';
  import {
    firstMonthRepaymentAmount,
    firstMonthInterestRate,
  } from '@/utils/wcl';

  export default {
    components: {
      LenRadio,
    },
    model: {
      prop: 'disbursementAmount',
      event: 'input',
    },
    props: {
      loanAmount: {
        type: Number,
        required: true,
      },
      disbursementAmount: {
        type: Number,
        default: null,
      },
      interestRate: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        originalDisbursementAmount:
          this.disbursementAmount === this.loanAmount
            ? this.loanAmount - 1
            : this.disbursementAmount,
      };
    },
    computed: {
      hasAmountError() {
        return this.amountError !== null;
      },
      amountError() {
        if (this.invalidDisbursementAmount) {
          return this.$t('forms.signupForm.errors.400');
        }

        if (this.isPartialDisbursement && this.inputAmount === '') {
          return this.$t('forms.errors.DefaultIsRequired');
        }

        return null;
      },
      amountHint() {
        return `${this.currencyAmount(0)} - ${this.currencyAmount(
          this.loanAmount - 1
        )}`;
      },
      invalidDisbursementAmount() {
        return (
          this.isPartialDisbursement &&
          (this.disbursementAmount < 0 ||
            this.disbursementAmount > this.loanAmount - 1)
        );
      },
      isPartialDisbursement: {
        get() {
          return this.loanAmount !== this.disbursementAmount;
        },
        set(value) {
          if (value) {
            this.$emit('input', this.originalDisbursementAmount);
          } else {
            this.$emit('input', this.loanAmount);
          }
        },
      },
      inputAmount: {
        get() {
          return !this.isPartialDisbursement || this.disbursementAmount === null
            ? ''
            : decimalAmount(this.disbursementAmount);
        },
        set(value) {
          const numeralValue = numeral(value).value();
          this.$emit('input', numeralValue);
        },
      },
      firstMonthInterestRate() {
        return currencyAmount(
          firstMonthInterestRate(
            Number(this.disbursementAmount),
            Number(this.interestRate) / 100
          )
        );
      },
      firstMonthRepaymentAmount() {
        return currencyAmount(
          firstMonthRepaymentAmount(
            Number(this.disbursementAmount),
            Number(this.interestRate) / 100
          )
        );
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_OFFER_DISBURSEMENT_AMOUNT',
        'SET_LOADING',
      ]),
      currencyAmount(amount) {
        return currencyAmount(amount, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
      onInputAmountBlur() {
        this.inputAmount = Math.floor(this.disbursementAmount);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../../sass/custom/variables';

  .overview-container {
    font-size: 16px;

    &.--no-padding {
      padding: 0;
    }

    &__subsection {
      padding: 40px;

      &.dark-background {
        background-color: $ing-signer-section-background;
      }
    }
  }

  .radio-container {
    padding-left: 0;
  }

  .disbursement-option {
    display: flex;
    margin-bottom: 12px;
    min-height: 36px;
  }

  .amount-form {
    display: flex;
    align-items: center;
  }

  .amount-input {
    position: relative;
    margin: 0 12px;

    .form-control {
      width: 127px;
    }

    &__symbol {
      position: absolute;
      width: 40px;
      height: 40px;
      line-height: 40px;
      vertical-align: middle;
      text-align: center;
      right: 0;
      top: 0;
    }

    &__hint {
      font-size: 14px;
      line-height: 22px;
      position: absolute;
      text-align: center;
      color: $ing-gray;
      width: 100%;
      top: 40px;
    }

    &--invalid {
      .form-control {
        border-color: #ff0000;
      }
    }

    &--invalid &__hint {
      color: #ff0000;
    }
  }

  p {
    margin-bottom: 20px;
  }

  .ing-error {
    margin-top: 40px;
  }

  .rate-breakdown {
    margin-top: 30px;
    width: 410px;

    &__row {
      display: flex;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &__label {
      flex: 1 1 200px;
    }

    &__value {
      font-weight: bold;
      flex: 1 1 210px;
    }

    &__divider {
      height: 1px;
      max-height: 1px;
      line-height: 1px;
      background-color: #333333;
      margin: 10px 0;
      width: 100%;
    }
  }
</style>
