<template>
  <NewsletterConfirmationForm />
</template>
<script>
  import NewsletterConfirmationForm from '@/components/subscription/NewsletterConfirmationForm';

  export default {
    name: 'NewsletterConfirmationPage',
    components: { NewsletterConfirmationForm },
  };
</script>
