<template>
  <ing-text-input
    v-model="lastName"
    :label="label"
    :error="errors.lastName && $t('forms.errors.' + errors.lastName)"
    :displayErrorIfDirty="!submitClicked"
    name="LastName"
    data-qa-id="last-name"
    @focus="handleFocus"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTextInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.lastName.label');
      },
      lastName: {
        get() {
          return this.$store.state.signupForm.lastName;
        },
        set(value) {
          this.SET_LAST_NAME(value);
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_LAST_NAME']),
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
