<template>
  <fieldset data-qa-id="company-info-revenue" class="revenue-container">
    <label>{{ label }} </label>
    <div class="revenue-input-container">
      <len-radio
        v-model="revenue"
        :label="$t('forms.signupForm.revenue.dropdown.after50K')"
        value="after50K"
        name="Revenue__c"
        :hasError="displayError"
        data-qa-id="low-revenue"
      />
      <len-radio
        v-model="revenue"
        :label="$t('forms.signupForm.revenue.dropdown.before50K')"
        value="before50K"
        name="Revenue__c"
        :hasError="displayError"
        data-qa-id="high-revenue"
      />
    </div>
    <div :class="{ active: displayError }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p>
        {{ errors.revenue && $t('forms.errors.' + errors.revenue) }}
      </p>
    </div>
  </fieldset>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenRadio },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.revenue.label');
      },
      displayError() {
        return this.submitClicked && !!this.errors.revenue;
      },
      revenue: {
        get() {
          return this.$store.state.signupForm.revenue;
        },
        set(value) {
          this.SET_REVENUE(value);
          this.sendFieldTrackingEvent(
            `radio:${this.label}`,
            this.$t(`forms.signupForm.revenue.dropdown.${value}`)
          );
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_REVENUE']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .revenue-input-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 9px 0 13px 0;
    width: fit-content;

    .radio-container {
      margin-bottom: 0;

      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }

  @media screen and (max-width: $tablet) {
    .revenue-input-container {
      .radio-container {
        font-size: 16px;

        &:nth-child(2) {
          margin-left: 10px;
        }
      }
    }
  }

  @media screen and (max-width: $mobile-l) {
    .revenue-input-container {
      flex-direction: column;
      margin: 7px 0 0 0;

      .radio-container {
        &:nth-child(2) {
          margin-left: 0;
          margin-top: 12px;
        }
      }
    }
  }
</style>
