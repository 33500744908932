<template>
  <FormSubSection>
    <template #sub-section-content>
      <ClientTitleSection :signerId="signerId" />
      <h3 class="section-title">
        {{ $t('signers.clientDetails.client.person') }}
      </h3>
      <Dropdown
        v-model="title"
        :options="titleOptions"
        :label="$t('signers.clientDetails.client.title')"
        :error="getError('title')"
        :displayErrorIfSubmitted="
          submitClicked || signers[signerId].submitClicked
        "
      />
      <TextInput
        v-model="firstName"
        :readonly="true"
        :label="$t('signers.clientDetails.client.firstName')"
        :error="getError('firstName')"
      />
      <TextInput
        v-model="lastName"
        :readonly="true"
        :label="$t('signers.clientDetails.client.lastName')"
        :error="getError('lastName')"
      />
      <TextInput
        v-model="lastNameAtBirth"
        :label="$t('signers.clientDetails.client.lastNameAtBirthInput')"
        :error="getError('lastNameAtBirth')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="cityOfBirth"
        :label="$t('signers.clientDetails.client.cityOfBirth')"
        :error="getError('cityOfBirth')"
        :displayErrorIfDirty="displayError()"
      />
      <Dropdown
        v-model="countryOfBirth"
        :label="$t('signers.clientDetails.client.countryOfBirth')"
        :options="countryDropdownOptions()"
        :error="getError('countryOfBirth')"
        :displayErrorIfSubmitted="
          submitClicked || signers[signerId].submitClicked
        "
      />
      <Dropdown
        v-model="nationality"
        :label="$t('signers.clientDetails.client.nationality')"
        :options="countryDropdownOptions()"
        :error="getError('nationality')"
        :displayErrorIfSubmitted="
          submitClicked || signers[signerId].submitClicked
        "
      />
      <Dropdown
        v-model="secondNationality"
        :label="$t('signers.clientDetails.client.secondNationalityInput')"
        :options="countryDropdownOptions()"
        :error="getError('secondNationality') || duplicatedNationalityMessage()"
        :displayErrorIfSubmitted="
          submitClicked || signers[signerId].submitClicked
        "
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import { titleValues } from '@/validators/helpers/title';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import countryDropdownMixin from '@/mixins/countryDropdownMixin';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import ClientTitleSection from '@/components/signers/ClientInformation/ClientTitleSection';
  export default {
    components: {
      Dropdown,
      TextInput,
      FormSubSection,
      ClientTitleSection,
    },
    mixins: [countryDropdownMixin, dropdownOptionsMixin],
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        titleValues,
      };
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      ...mapGetters('signers', ['nationalityEqualsSecondNationality']),
      title: {
        get() {
          return Object.keys(this.titleValues).find(
            (key) => this.titleValues[key] === this.signers[this.signerId].title
          );
        },
        set(value) {
          this.SET_TITLE({
            signerId: this.signerId,
            title: this.titleValues[value],
          });
        },
      },
      firstName: {
        get() {
          return this.signers[this.signerId].firstName;
        },
      },
      lastName: {
        get() {
          return this.signers[this.signerId].lastName;
        },
      },
      lastNameAtBirth: {
        get() {
          return this.signers[this.signerId].lastNameAtBirth;
        },
        set(value) {
          this.SET_LAST_NAME_AT_BIRTH({
            signerId: this.signerId,
            lastNameAtBirth: value,
          });
        },
      },
      cityOfBirth: {
        get() {
          return this.signers[this.signerId].cityOfBirth;
        },
        set(value) {
          this.SET_CITY_OF_BIRTH({
            signerId: this.signerId,
            cityOfBirth: value,
          });
        },
      },
      countryOfBirth: {
        get() {
          return this.signers[this.signerId].countryOfBirth;
        },
        set(value) {
          this.SET_COUNTRY_OF_BIRTH({
            signerId: this.signerId,
            countryOfBirth: value,
          });
        },
      },
      nationality: {
        get() {
          return this.signers[this.signerId].nationality;
        },
        set(value) {
          this.SET_NATIONALITY({
            signerId: this.signerId,
            nationality: value,
          });
        },
      },
      secondNationality: {
        get() {
          return this.signers[this.signerId].secondNationality;
        },
        set(value) {
          this.SET_SECOND_NATIONALITY({
            signerId: this.signerId,
            secondNationality: value,
          });
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_TITLE',
        'SET_CITY_OF_BIRTH',
        'SET_COUNTRY_OF_BIRTH',
        'SET_NATIONALITY',
        'SET_LAST_NAME_AT_BIRTH',
        'SET_SECOND_NATIONALITY',
      ]),
      getError(field) {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId][field] &&
          this.$t('forms.errors.' + this.errors[this.signerId][field])
        );
      },
      displayError() {
        return (
          !this.submitClicked && !this.signers[this.signerId].submitClicked
        );
      },
      duplicatedNationalityMessage() {
        if (this.nationalityEqualsSecondNationality(this.signerId)) {
          return this.$t('forms.errors.NationalityEqualsSecondNationality');
        } else {
          return null;
        }
      },
    },
  };
</script>
