import nationalities from '@/components/common/assets/nationalities';
import filter from 'lodash/filter';

export default {
  methods: {
    countryDropdownOptions(preferredCountries = ['DE']) {
      const countryList = nationalities.map((country) => {
        return {
          label: country.name,
          value: country.iso,
        };
      });

      if (Array.isArray(preferredCountries) && preferredCountries.length) {
        const preferredCountryList = filter(countryList, (country) =>
          preferredCountries.includes(country.value)
        );

        return [...preferredCountryList, ...countryList];
      }

      return countryList;
    },
  },
};
