import { render, staticRenderFns } from "./representative-information.vue?vue&type=template&id=5f48fe44&scoped=true"
import script from "./representative-information.vue?vue&type=script&lang=js"
export * from "./representative-information.vue?vue&type=script&lang=js"
import style0 from "./representative-information.vue?vue&type=style&index=0&id=5f48fe44&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f48fe44",
  null
  
)

export default component.exports