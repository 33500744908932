<template>
  <div class="ing-wrapper">
    <ing-header />
    <ing-authentication-modal
      :id="loanRequestId"
      :show="isUserUnauthorised"
      @success="getOffer()"
    />
    <ing-form-loader storeModule="offer" />
    <IngButton
      type="button"
      class="outlined small align-right"
      @click="userLogoutMixin(productName)"
      >{{ $t('logoutSection.logoutButton') }}</IngButton
    >
    <ing-section :darkTheme="false" maxWidth="ing-section-content">
      <template slot="section-content">
        <ing-progress-bar />
        <ing-offer-details v-if="shouldShowStep1" />
        <ing-contracts v-if="shouldShowStep2" />
      </template>
    </ing-section>
    <ing-section :darkTheme="true" maxWidth="ing-section-content">
      <template slot="section-content">
        <ing-signing-info />
      </template>
    </ing-section>
    <ing-section :darkTheme="false" maxWidth="ing-section-content">
      <template slot="section-content">
        <ing-offer-contact-us />
      </template>
    </ing-section>
    <ing-footer />
  </div>
</template>

<script>
  import IngHeader from '@/components/ing/ing-header';
  import IngFooter from '@/components/ing/ing-footer';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngAuthenticationModal from '@/components/ing/authentication/ing-authentication-modal';
  import IngSection from '@/components/ing/ing-section';
  import { mapState, mapMutations, mapActions } from 'vuex';
  import IngOfferDetails from '@/components/ing/offer/ing-offer-details';
  import IngSigningInfo from '@/components/ing/offer/ing-signing-info';
  import IngOfferContactUs from '@/components/ing/offer/ing-offer-contact-us';
  import IngContracts from '@/components/ing/offer/ing-contracts';
  import IngProgressBar from '@/components/ing/offer/ing-progress-bar';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import * as PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import { userLogoutMixin } from '@/mixins/userLogoutMixin';
  import { TRACK_EVENTS } from '@/constants/WEBTREKK';

  export default {
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerJourney.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerJourney.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerJourney.metaDescription'),
          },
        ],
      };
    },
    components: {
      IngProgressBar,
      IngSigningInfo,
      IngOfferDetails,
      IngContracts,
      IngOfferContactUs,
      IngHeader,
      IngFooter,
      IngButton,
      IngSection,
      IngAuthenticationModal,
      IngFormLoader,
    },
    mixins: [webtrekkMixin, userLogoutMixin],
    props: {
      loanRequestId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('offer', ['error', 'currentStep', 'productType']),
      ...mapState('contract', ['credit', 'micro_credit']),
      isTermLoan() {
        // if this is used anywhere else in the future, move to vuex module getter
        return [
          PRODUCT_TYPES.BLACKSEA_REGULAR,
          PRODUCT_TYPES.BLACKSEA_MICRO,
          PRODUCT_TYPES.REGULAR,
          PRODUCT_TYPES.MICRO,
        ].includes(this.productType);
      },
      productName() {
        return this.isTermLoan ? 'firmenkredit' : 'flexkredit';
      },
      isUserUnauthorised() {
        return (
          (this.error && this.error.status === 401) ||
          (this.credit.getReqErrorCode &&
            this.credit.getReqErrorCode.status === 401) ||
          (this.micro_credit.getReqErrorCode &&
            this.micro_credit.getReqErrorCode.status === 401)
        );
      },
      shouldShowStep1() {
        return this.currentStep === 1;
      },
      shouldShowStep2() {
        return this.currentStep === 2;
      },
    },
    mounted() {
      this.getOffer();
    },
    methods: {
      ...mapMutations('offer', ['CLEAR_ERROR', 'SET_LOADING']),
      ...mapActions('offer', ['getOfferDetails']),
      async getOffer() {
        this.SET_LOADING(true);
        this.CLEAR_ERROR();
        await this.getOfferDetails();
        if (!this.isUserUnauthorised) {
          if (this.isTermLoan) {
            this.trackEvent(TRACK_EVENTS.AMAZON_FIRMENKREDIT_OFFER_UI);
          } else {
            this.trackEvent(TRACK_EVENTS.AMAZON_FLEXKREDIT_OFFER_UI);
          }
        }
        this.SET_LOADING(false);
      },
    },
  };
</script>
