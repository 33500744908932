<template>
  <div>
    <form-loader storeModule="offer" />
    <authentication-modal
      :id="loanRequestId"
      :show="isUserUnauthorised"
      @success="getOffer()"
    />
    <div class="addMaterialDesign offer-container">
      <len-header />
      <main class="sidebar-and-offer-container">
        <side-bar />
        <div class="offer-content">
          <progress-bar />
          <div
            v-if="currentStep === 1"
            class="col-lg-6 col-md-9 col-sm-10 col-xs-12"
          >
            <offer-details />
            <accept-offer-button />
          </div>
          <div
            v-if="currentStep === 2"
            class="col-lg-6 col-md-9 col-sm-10 col-xs-12"
          >
            <offer-contracts />
          </div>
          <offer-footer class="offer-footer" />
        </div>
      </main>
    </div>
  </div>
</template>

<script>
  import LenHeader from '@/components/common/len-header';
  import SideBar from '@/components/offer/side-bar';
  import OfferFooter from '@/components/borrower/blocks/borrower-deal-footer';
  import ProgressBar from '@/components/offer/progress-bar';
  import AuthenticationModal from '@/components/authentication/authentication-modal';
  import FormLoader from '@/components/common/forms/form-loader';
  import OfferDetails from '@/components/offer/offer-details';
  import AcceptOfferButton from '@/components/offer/accept-offer-button';
  import OfferContracts from '@/components/offer/offer-contracts';
  import { mapState, mapMutations, mapActions } from 'vuex';
  export default {
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerJourney.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerJourney.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerJourney.metaDescription'),
          },
        ],
      };
    },
    components: {
      LenHeader,
      SideBar,
      OfferFooter,
      ProgressBar,
      AuthenticationModal,
      FormLoader,
      OfferDetails,
      AcceptOfferButton,
      OfferContracts,
    },
    props: {
      loanRequestId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('offer', ['error', 'currentStep']),
      ...mapState('contract', ['credit', 'micro_credit']),
      isUserUnauthorised() {
        return (
          (this.error && this.error.status === 401) ||
          (this.credit.getReqErrorCode &&
            this.credit.getReqErrorCode.status === 401) ||
          (this.micro_credit.getReqErrorCode &&
            this.micro_credit.getReqErrorCode.status === 401)
        );
      },
    },
    mounted() {
      this.getOffer();
    },
    methods: {
      ...mapMutations('offer', ['CLEAR_ERROR', 'SET_LOADING']),
      ...mapActions('offer', ['getOfferDetails']),
      async getOffer() {
        this.SET_LOADING(true);
        this.CLEAR_ERROR();
        await this.getOfferDetails();
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style scoped lang="scss">
  .sidebar-and-offer-container {
    display: flex;
    min-height: 100vh;
  }
  .offer-content {
    display: flex;
    flex-direction: column;
    padding: 50px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    overflow: auto;
  }
  @media (max-width: 932px) {
    .offer-content {
      padding: 20px;
    }
  }
</style>
