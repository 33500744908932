<template>
  <section :class="{ 'ing-dark-section': darkTheme }" class="ing-section">
    <div :class="maxWidth">
      <slot name="section-content" />
    </div>
  </section>
</template>

<script>
  export default {
    props: {
      darkTheme: {
        type: Boolean,
        default: false,
      },
      maxWidth: {
        type: String,
        default: '',
      },
    },
  };
</script>
