import axios from 'axios';
import { EMAIL_ALREADY_EXIST } from '@/constants/ERROR';

export const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};
const handleError = (error) => {
  const PartnerEmailAlreadyExists = 'PartnerEmailAlreadyExists';
  const {
    data: { message },
  } = error.response;
  if (EMAIL_ALREADY_EXIST === message) {
    return PartnerEmailAlreadyExists;
  }
  return null;
};

export const postPartnerApplication = async (payloadData) => {
  payloadData = JSON.stringify(payloadData);
  try {
    await axios.post(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/partner/application/de`,
      payloadData,
      config
    );
  } catch (error) {
    throw new Error(handleError(error));
  }
};
