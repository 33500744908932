import findKey from 'lodash/findKey';
import {
  BLACKSEA_REGULAR,
  BLACKSEA_MICRO,
  BLACKSEA_WCL_REGULAR,
  BLACKSEA_WCL_MICRO,
  REGULAR,
  MICRO,
} from '@/constants/PRODUCT_TYPES';
import { areEmailsUnique } from '@/validators/helpers/email';
import { arePhonesUnique } from '@/validators/helpers/phone';
import { areTaxNumberUnique } from '@/validators/helpers/taxNumber';
import {
  controllingCompanyLegalForm,
  complexLegalForm,
} from '@/validators/helpers/legalStatus';
import { signersRegularValidator, signersMicroValidator } from '@/validators';

export const getters = {
  isValid: (state, getters) => {
    if (state.isClientEditable || state.isCompanyEditable) {
      return false;
    }
    let isIdentProcessValid = true;
    let noOtherMDCheckboxValid = true;
    if (findKey(state.errors.identificationProcess)) {
      isIdentProcessValid = false;
    }
    if (findKey(state.errors.noOtherMDCheckbox)) {
      noOtherMDCheckboxValid = false;
    }
    const areAllClientsValid = Object.keys(state.signers).every((signerId) =>
      getters.isClientValid(signerId)
    );

    return (
      isIdentProcessValid &&
      noOtherMDCheckboxValid &&
      getters.isCompanyValid &&
      areAllClientsValid &&
      getters.areSignersEmailsUnique &&
      getters.areSignersPhonesUnique
    );
  },

  isClientValid: (state, getters) => (signerId) => {
    let isValid = true;
    for (const errorEntry in state.errors[signerId]) {
      if (findKey(state.errors[signerId][errorEntry])) {
        isValid = false;
      }
    }
    return (
      isValid &&
      !getters.nationalityEqualsSecondNationality(signerId) &&
      getters.areSignersTaxNumberUnique &&
      !getters.personTaxNumberEqualsCompanyTaxNumber(signerId)
    );
  },
  isCompanyValid: (state, getters) => {
    let isValid = true;
    for (const errorEntry in state.errors.company) {
      if (findKey(state.errors.company[errorEntry])) {
        isValid = false;
      }
    }
    if (
      state.company.legalForm === controllingCompanyLegalForm ||
      getters.isComplexLegalForm
    ) {
      return isValid && getters.isControllingCompanyValid;
    } else {
      return isValid;
    }
  },
  isControllingCompanyValid: (state) => {
    let isValid = true;
    for (const errorEntry in state.errors.controllingCompany) {
      if (findKey(state.errors.controllingCompany[errorEntry])) {
        isValid = false;
      }
    }
    return isValid;
  },
  isComplexLegalForm: (state) => {
    return (
      state.company.legalForm === complexLegalForm &&
      state.controllingCompany.legalName !== null
    );
  },
  areSignersEmailsUnique: (state) => {
    return areEmailsUnique(state.signers);
  },
  areSignersPhonesUnique: (state) => {
    return arePhonesUnique(state.signers);
  },
  areSignersTaxNumberUnique: (state) => {
    return areTaxNumberUnique(state.signers);
  },
  personTaxNumberEqualsCompanyTaxNumber: (state) => (signerId) => {
    const { taxNumber } = state.signers[signerId];
    const { companyTaxNumber } = state.company;
    return taxNumber === companyTaxNumber;
  },
  nationalityEqualsSecondNationality: (state) => (signerId) => {
    const { nationality, secondNationality } = state.signers[signerId];
    return nationality === secondNationality;
  },
  getSignersValidator: (state) => {
    const { productType } = state.offerConditions;
    if (productType && productType.includes('REGULAR')) {
      return signersRegularValidator;
    } else {
      return signersMicroValidator;
    }
  },
  isCreditLineProduct: (state) => {
    const { productType } = state.offerConditions;

    return (
      productType &&
      [BLACKSEA_WCL_REGULAR, BLACKSEA_WCL_MICRO].includes(productType)
    );
  },
  isTermLoanProduct: (state) => {
    const { productType } = state.offerConditions;

    return (
      productType &&
      [BLACKSEA_REGULAR, BLACKSEA_MICRO, REGULAR, MICRO].includes(productType)
    );
  },
};
