<template>
  <div :class="{ alert: isInvalid }" class="form-container addMaterialDesign">
    <div class="client-title-container">
      <p>
        {{
          $t(
            'signersMissingInfo.formPage.clientsSection.microLoanRequest.form.p'
          )
        }}
      </p>
      <h3>
        {{ genderValue }} {{ signerInformation.firstName }}
        {{ signerInformation.lastName }}
      </h3>
    </div>
    <div class="client-section-container">
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <last-name-at-birth
            :key="`last-name-${signerId}`"
            :signerId="signerId"
          />
          <city-of-birth
            :key="`city-of-birth-${signerId}`"
            :signerId="signerId"
          />
          <nationality-field
            :key="`nationality-${signerId}`"
            :signerId="signerId"
          />
        </div>
      </div>
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <title-dropdown-field
            :key="`title-${signerId}`"
            :signerId="signerId"
            class="title-field"
          />

          <country-of-birth
            :key="`country-of-birth-${signerId}`"
            :signerId="signerId"
          />
          <second-nationality-field
            :key="`second-nationality-${signerId}`"
            :signerId="signerId"
          />
        </div>
      </div>
    </div>
    <form-separator />
    <div class="client-section-container">
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <mobile-phone-field
            :key="`mobile-phone-${signerId}`"
            :signerId="signerId"
            :preferredCountries="['de']"
          />
        </div>
      </div>
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <email-field :key="'email-field-' + signerId" :signerId="signerId" />
        </div>
      </div>
    </div>
    <form-separator />
    <div class="client-section-container">
      <div class="col-lg-7 col-md-10 col-sm-12">
        <identification-id-type-field
          :key="`id-type-${signerId}`"
          :signerId="signerId"
        />
      </div>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <identification-id-number-field
            :key="`id-number-${signerId}`"
            :signerId="signerId"
          />
          <identification-place-of-issue-field
            :key="`place-of-issue-${signerId}`"
            :signerId="signerId"
          />
          <identification-expiry-date-field
            :key="`expiry-date-${signerId}`"
            :signerId="signerId"
          />
        </div>
      </div>
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <identification-issuing-authority-field
            :key="`issuing-auth-${signerId}`"
            :signerId="signerId"
          />
          <identification-issuing-date-field
            :key="`issuing-date-${signerId}`"
            :signerId="signerId"
          />
          <tax-number-field
            :key="`tax-number-${signerId}`"
            :signerId="signerId"
          />
        </div>
      </div>
    </div>
    <form-separator />
    <div class="client-section-container">
      <h3>{{ $t('forms.signupForm.userCurrentAddress.label') }}</h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <street-field :key="'street-' + signerId" :signerId="signerId" />
          <zip-field :key="'zip-' + signerId" :signerId="signerId" />
          <country-field :key="'country-' + signerId" :signerId="signerId" />
        </div>
      </div>
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <street-number-field
            :key="`street-number-${signerId}`"
            :signerId="signerId"
          />
          <city-field :key="'city-' + signerId" :signerId="signerId" />
        </div>
      </div>
      <signer-postal-address-checkbox-field
        :key="`postal-check-${signerId}`"
        :signerId="signerId"
      />
    </div>
    <form-separator v-if="!signerInformation.homeAddressIsPostalAddress" />
    <div
      v-show="!signerInformation.homeAddressIsPostalAddress"
      class="client-section-container"
    >
      <h3>{{ $t('forms.signupForm.postalAddress.label') }}</h3>
      <div class="col-sm-6">
        <div class="col-sm-11">
          <postal-street-field
            :key="`postal-street-${signerId}`"
            :signerId="signerId"
          />
          <postal-zip-field
            :key="`postal-zip-${signerId}`"
            :signerId="signerId"
          />
          <postal-country-field
            :key="`postal-country-${signerId}`"
            :signerId="signerId"
          />
        </div>
      </div>
      <div class="col-sm-6 vertical-align--top">
        <div class="col-sm-11">
          <postal-street-number-field
            :key="`postal-street-number-${signerId}`"
            :signerId="signerId"
          />
          <postal-city-field
            :key="`postal-city-${signerId}`"
            :signerId="signerId"
          />
        </div>
      </div>
    </div>
    <div class="submit-section-container">
      <div
        v-if="errorMessage !== ''"
        role="alert"
        class="general-error-container"
      >
        <p class="validation" v-html="errorMessage" />
      </div>
      <submit-form
        @cancel-button-clicked="$emit('cancel-button-clicked')"
        @submit-button-clicked="$emit('submit-button-clicked')"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { genderValues } from '@/validators/helpers/gender';
  import TitleDropdownField from '@/components/signers/signers-client-information/signers-client-information-form/title-dropdown-field';
  import LastNameAtBirth from '@/components/signers/signers-client-information/signers-client-information-form/last-name-at-birth-field';
  import CountryOfBirth from '@/components/signers/signers-client-information/signers-client-information-form/country-of-birth-field';
  import CityOfBirth from '@/components/signers/signers-client-information/signers-client-information-form/city-of-birth-field';
  import NationalityField from '@/components/signers/signers-client-information/signers-client-information-form/nationality-field';
  import SecondNationalityField from '@/components/signers/signers-client-information/signers-client-information-form/second-nationality-field';
  import TaxNumberField from '@/components/signers/signers-client-information/signers-client-information-form/tax-number-field';
  import MobilePhoneField from '@/components/signers/signers-client-information/signers-client-information-form/mobile-phone-field';
  import EmailField from '@/components/signers/signers-client-information/signers-client-information-form/email-field';
  import IdentificationIdTypeField from '@/components/signers/signers-client-information/signers-client-information-form/identification-id-type-field';
  import IdentificationIdNumberField from '@/components/signers/signers-client-information/signers-client-information-form/identification-id-number-field';
  import IdentificationIssuingAuthorityField from '@/components/signers/signers-client-information/signers-client-information-form/identification-issuing-authority-field';
  import IdentificationPlaceOfIssueField from '@/components/signers/signers-client-information/signers-client-information-form/identification-place-of-issue-field';
  import IdentificationIssuingDateField from '@/components/signers/signers-client-information/signers-client-information-form/identification-issuing-date-field';
  import IdentificationExpiryDateField from '@/components/signers/signers-client-information/signers-client-information-form/identification-expiry-date-field';
  import StreetField from '@/components/signers/signers-client-information/signers-client-information-form/street-field';
  import StreetNumberField from '@/components/signers/signers-client-information/signers-client-information-form/street-number-field';
  import ZipField from '@/components/signers/signers-client-information/signers-client-information-form/zip-field';
  import CityField from '@/components/signers/signers-client-information/signers-client-information-form/city-field';
  import CountryField from '@/components/signers/signers-client-information/signers-client-information-form/country-field';
  import SignerPostalAddressCheckboxField from '@/components/signers/signers-client-information/signers-client-information-form/signer-postal-address-checkbox-field';
  import PostalStreetField from '@/components/signers/signers-client-information/signers-client-information-form/postal-street-field';
  import PostalStreetNumberField from '@/components/signers/signers-client-information/signers-client-information-form/postal-street-number-field';
  import PostalZipField from '@/components/signers/signers-client-information/signers-client-information-form/postal-zip-field';
  import PostalCityField from '@/components/signers/signers-client-information/signers-client-information-form/postal-city-field';
  import PostalCountryField from '@/components/signers/signers-client-information/signers-client-information-form/postal-country-field';
  import SubmitForm from '@/components/signers/section-submit-form';
  import FormSeparator from '@/components/signers/form-separator';
  import { mapErrorMessage } from '@/mixins/signersMixin';
  export default {
    components: {
      TitleDropdownField,
      LastNameAtBirth,
      CountryOfBirth,
      CityOfBirth,
      NationalityField,
      TaxNumberField,
      MobilePhoneField,
      EmailField,
      FormSeparator,
      IdentificationIdTypeField,
      IdentificationIdNumberField,
      IdentificationIssuingAuthorityField,
      IdentificationPlaceOfIssueField,
      IdentificationIssuingDateField,
      IdentificationExpiryDateField,
      StreetField,
      StreetNumberField,
      ZipField,
      CityField,
      CountryField,
      SignerPostalAddressCheckboxField,
      PostalStreetField,
      PostalStreetNumberField,
      PostalZipField,
      PostalCityField,
      PostalCountryField,
      SubmitForm,
      SecondNationalityField,
    },
    mixins: [mapErrorMessage],
    props: {
      signerId: {
        type: String,
        default: '',
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        genderValues,
      };
    },
    computed: {
      ...mapState('signers', ['signers', 'errors']),
      signerInformation() {
        return this.signers[this.signerId];
      },
      errorMessage() {
        const serverError = this.errors.clientServer;
        if (serverError) {
          const hasErrorMessage = serverError.data && serverError.data.message;
          if (hasErrorMessage && serverError.data.statusCode === 500) {
            return this.mapErrorMessage(serverError.data);
          }
          if (serverError.status >= 400 && serverError.status < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError.status >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
      genderValue() {
        if (this.signerInformation) {
          return Object.keys(this.genderValues).find(
            (key) => this.genderValues[key] === this.signerInformation.gender
          );
        }
        return {};
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/_settings.scss';
  p {
    font-size: 14px;
    line-height: 21px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: $darkerGrey;
  }
  .client-title-container {
    padding: 40px 0 0 40px;
  }
  .client-section-container {
    width: 100%;
    padding: 40px;
  }
  .form-container {
    &.alert {
      border: solid 1px $red;
    }
  }
  .general-error-container {
    min-height: 25px;
    height: fit-content;
  }
  .general-error-container p {
    text-align: center;
    font-size: 0.8rem;
  }
  .submit-section-container {
    width: 100%;
    padding: 0 40px 40px 40px;
  }
  .title-field {
    margin-top: 23px;
  }
</style>
