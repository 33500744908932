<template>
  <div class="overview-container --no-padding flex--column">
    <div class="overview-container__subsection">
      <div class="col-lg-9 col-sm-10">
        <div class="row" data-unit-test="ing-client-loan-amount">
          <p class="field-label col-sm-4">
            <em>
              {{
                $t(
                  `signersMissingInfo.formPage.offerConditions.form.${productKey}.loanAmount`
                )
              }}:
            </em>
          </p>
          <p class="field-value col-sm-8">
            {{
              offerConditions.loanAmount | emptyValueReplacer | europeanCurrency
            }}
            €
          </p>
        </div>
        <div class="row" data-unit-test="ing-client-loan-duration">
          <p class="field-label col-sm-4">
            <em>
              {{
                $t(
                  `signersMissingInfo.formPage.offerConditions.form.${productKey}.duration`
                )
              }}:
            </em>
          </p>
          <p v-if="isProductLoaded" class="field-value col-sm-8">
            <template v-if="isTermLoanProduct">
              {{
                offerConditions.durationInMonths
                  | momentjsDuration('month')
                  | emptyValueReplacer
              }}
            </template>
            <template v-else>
              {{
                $t(
                  `signersMissingInfo.formPage.offerConditions.form.${productKey}.durationValue`
                )
              }}
            </template>
          </p>
        </div>
        <div class="row" data-unit-test="ing-client-loan-interest">
          <p class="field-label col-sm-4">
            <em>
              {{
                $t(
                  `signersMissingInfo.formPage.offerConditions.form.${productKey}.interestRate`
                )
              }}:
            </em>
          </p>
          <p class="field-value col-sm-8">
            {{
              offerConditions.interestRate
                | emptyValueReplacer
                | europeanCurrency
            }}
            % p.a. {{ isCreditLineProduct ? '*' : '' }}
          </p>
        </div>
        <div class="row" data-unit-test="ing-client-loan-installment">
          <p class="field-label col-sm-4">
            <em>
              {{
                $t(
                  `signersMissingInfo.formPage.offerConditions.form.${productKey}.monthlyRate`
                )
              }}:
            </em>
          </p>
          <p class="field-value col-sm-8">
            <template v-if="isCreditLineProduct">
              {{
                $t(
                  `signersMissingInfo.formPage.offerConditions.form.wcl.monthlyRateValue`
                )
              }}
            </template>
            <template v-else-if="isTermLoanProduct">
              {{
                offerConditions.installment
                  | emptyValueReplacer
                  | europeanCurrency
              }}
            </template>
          </p>
        </div>
      </div>
    </div>
    <div
      v-if="isCreditLineProduct"
      data-unit-test="ing-client-credit-line-details"
    >
      <ing-offer-conditions-details-information
        v-if="!isOfferEditable"
        data-unit-test="ing-client-credit-line-details-information"
        @edit="onEdit"
      />
      <ing-offer-conditions-details-form
        v-else
        v-model="disbursementAmount"
        data-unit-test="ing-client-credit-line-details-form"
        :loanAmount="loanAmount"
        :interestRate="offerConditions.interestRate"
      />
    </div>
    <div
      v-if="isCreditLineProduct && isOfferEditable"
      class="overview-container__subsection"
    >
      <div class="submit-section-container">
        <div v-if="errorMessage" role="alert" class="general-error-container">
          <p class="validation" v-html="errorMessage" />
        </div>
        <ing-section-submit-form
          @cancel-button-clicked="onCancelEdit"
          @submit-button-clicked="onSubmit"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
  import IngOfferConditionsDetailsInformation from './ing-offer-conditions-details-information';
  import IngOfferConditionsDetailsForm from './ing-offer-conditions-details-form';
  import IngSectionSubmitForm from '@/components/ing/signers/ing-section-submit-form';

  export default {
    components: {
      IngOfferConditionsDetailsInformation,
      IngOfferConditionsDetailsForm,
      IngSectionSubmitForm,
    },
    data() {
      return {
        loanAmount: 0,
        disbursementAmount: null,
      };
    },
    computed: {
      ...mapState('signers', ['offerConditions', 'isOfferEditable', 'errors']),
      ...mapGetters('signers', ['isCreditLineProduct', 'isTermLoanProduct']),
      invalidDisbursementAmount() {
        return (
          this.disbursementAmount !== this.loanAmount &&
          (this.disbursementAmount < 0 ||
            this.disbursementAmount > this.loanAmount - 1)
        );
      },
      isProductLoaded() {
        return this.offerConditions.productType !== '';
      },
      productKey() {
        return this.isCreditLineProduct ? 'wcl' : 'regular';
      },
      errorMessage() {
        const serverError = this.errors.offerConditions;
        if (serverError) {
          if (serverError.status >= 400 && serverError.status < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError.status >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
    watch: {
      offerConditions: {
        handler() {
          this.copyStoreValues();
        },
        deep: true,
        immediate: true,
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_IS_OFFER_EDITABLE',
        'SET_LOADING',
        'CLEAR_OFFER_CONDITIONS_REQUEST_ERROR',
      ]),
      ...mapActions('signers', [
        'updateDisbursementAmount',
        'getCompanyDetails',
      ]),
      copyStoreValues() {
        this.loanAmount = this.offerConditions
          ? Number(this.offerConditions.loanAmount)
          : 0;

        this.disbursementAmount = this.offerConditions
          ? Math.floor(Number(this.offerConditions.wclDisbursementAmount))
          : 0;
      },
      onEdit() {
        this.SET_IS_OFFER_EDITABLE(true);
      },
      onCancelEdit() {
        this.copyStoreValues();
        this.SET_IS_OFFER_EDITABLE(false);
        this.CLEAR_OFFER_CONDITIONS_REQUEST_ERROR();
      },
      async onSubmit() {
        if (this.invalidDisbursementAmount) {
          return;
        }

        this.SET_LOADING(true);
        this.CLEAR_OFFER_CONDITIONS_REQUEST_ERROR();
        await this.updateDisbursementAmount(this.disbursementAmount);

        if (!this.errors.offerConditions) {
          await this.getCompanyDetails();
          this.SET_IS_OFFER_EDITABLE(false);
          this.CLEAR_OFFER_CONDITIONS_REQUEST_ERROR();
        }
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/variables';

  .overview-container {
    font-size: 16px;

    &.--no-padding {
      padding: 0;
    }

    &__subsection {
      padding: 40px;

      &.dark-background {
        background-color: $ing-signer-section-background;
      }
    }
  }

  .flex--column {
    flex-direction: column;
  }

  .field-label {
    em {
      color: $ing-dark-gray;
    }
  }

  p {
    font-size: 14px;
    line-height: 21px;
  }
</style>
