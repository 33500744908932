<template>
  <form
    class="partner-form"
    name="registration"
    autocomplete="off"
    @submit.prevent="submit"
  >
    <IngFormLoader key="partnerSubmissionLoader" storeModule="signupForm" />
    <div v-if="partnerLoginIsVisible">
      <PageHeader key="partnerLoginPageHeader" page="partner.login">
        <p>
          <i18n path="partner.login.header.description.text">
            <a href="/ing-partner-werden" place="linkText" target="_blank">{{
              $t(`partner.login.header.description.linkText`)
            }}</a>
          </i18n>
        </p>
      </PageHeader>
      <PageSection class="section-form" sectionPadding="no">
        <template #section-content>
          <FormSectionHeader section="partner.login.form" />
          <IngPartnerLogin
            ref="partner-information"
            class="section-form"
            @partner-logged-in="hidePartner()"
          />
        </template>
      </PageSection>
    </div>
    <div v-else>
      <PageHeader
        key="partnerApplicationPageHeader"
        page="partner.application"
        :i18nUserData="getFullName"
      />
      <PageSection class="section-form" sectionPadding="top-no">
        <template #section-content>
          <FormSectionHeader section="partner.application.form.creditSection" />
          <lendico-loan-calculator theme="ING" />
        </template>
      </PageSection>
      <PageSection class="section-form" :darkTheme="true">
        <template #section-content>
          <FormSectionHeader
            section="partner.application.form.companySection"
            :paraCount="2"
          />
          <IngCompanyInformation class="section-form" />
        </template>
      </PageSection>
      <PageSection class="section-form">
        <template #section-content>
          <FormSectionHeader
            section="partner.application.form.representativeSection"
          />
          <IngRepresentativeInformation
            ref="representative-information"
            class="section-form"
          />
        </template>
      </PageSection>
      <PageSection class="section-form" :darkTheme="true">
        <template #section-content>
          <FormSectionHeader
            section="partner.application.form.termsSection"
            :paraCount="0"
          >
            <template #form-content>
              <IngPartnerPrivacyPolicy />
            </template>
          </FormSectionHeader>
          <IngSubmitForm />
        </template>
      </PageSection>
      <div />
    </div>
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
  </form>
</template>
<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import {
    eventListeners,
    setAmountAndLength,
    utmParamsExtraction,
    mediaCodesExtraction,
    wtCookieRemove,
  } from '@/mixins/forms/formSubmissionMixin';
  import { routerPushByName } from '@/mixins/routerMixin';
  import cookie from '@/common/cookie';
  import {
    TRACK_EVENTS,
    TRACK_PRODUCT_NAME,
    TRACK_PRODUCT_STATUS,
    TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
  } from '@/constants/WEBTREKK';

  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import IngPartnerLogin from '@/components/partner/forms/IngPartnerLogin';
  import IngCompanyInformation from '@/components/common/forms/IngCompanyInformation';
  import IngRepresentativeInformation from '@/components/common/forms/IngRepresentativeInformation';
  import IngSubmitForm from '@/components/common/forms/IngSubmitForm';
  import IngPartnerPrivacyPolicy from '@/components/partner/forms/IngPartnerPrivacyPolicy';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import { calculatorLoaderMixin } from '@/mixins/calculatorLoaderMixin';
  export default {
    name: 'IngPartnerSubmissionForm',
    components: {
      IngFormLoader,
      IngPartnerPrivacyPolicy,
      IngSubmitForm,
      PageHeader,
      FormSectionHeader,
      PageSection,
      IngPartnerLogin,
      IngCompanyInformation,
      IngRepresentativeInformation,
    },
    mixins: [
      eventListeners,
      utmParamsExtraction,
      mediaCodesExtraction,
      wtCookieRemove,
      setAmountAndLength,
      routerPushByName,
      webtrekkMixin,
      calculatorLoaderMixin,
    ],
    data() {
      return {
        partnerLoginIsVisible: true,
      };
    },
    computed: {
      ...mapState('signupForm', ['submitter']),
      ...mapState('partner', ['partnerAccepted']),
      ...mapGetters('signupForm', [
        'isValid',
        'isPartner',
        'isBookkeepingVisible',
      ]),
      getFullName() {
        return {
          fullName: `${this.$store.state.partner.partnerFirstName} ${this.$store.state.partner.partnerLastName}`,
        };
      },
    },
    beforeMount() {
      this.loadCalculator();
    },
    mounted() {
      this.SET_SUBMITTER('partner');
    },
    methods: {
      ...mapActions('signupForm', ['signUp']),
      ...mapMutations('signupForm', [
        'SET_GC_ID',
        'SET_SUBMIT_CLICKED',
        'SET_TERMS_AND_CONDITIONS',
        'SET_SUBMITTER',
      ]),
      hidePartner() {
        this.partnerLoginIsVisible = false;
        window.scrollTo(0, 0);
        this.trackEvent(
          TRACK_EVENTS.BRIDGE_PARTNER_APPLICATION,
          TRACK_PRODUCT_NAME.BRIDGE_PARTNER_APPLICATION,
          TRACK_PRODUCT_STATUS.ADD
        );
      },
      async submit(event) {
        this.SET_SUBMIT_CLICKED(true);
        this.addExtraFields();
        // by click submit they accept
        this.SET_TERMS_AND_CONDITIONS(true);
        if (!this.isValid) {
          return;
        }
        try {
          await this.signUp();
          this.trackClick(event.submitter.attributes['wtlink'].value);
          this.routerPushByName('partner-firmenkredit-danke');
          this.removeWTCookies();
          const { loanAmount } = this.$store.state.signupForm;

          this.trackEvent(
            TRACK_EVENTS.BRIDGE_PARTNER_APPLICATION_SUCCESS,
            TRACK_PRODUCT_NAME.BRIDGE_PARTNER_APPLICATION,
            TRACK_PRODUCT_STATUS.SUCCESS,
            {
              orderValue: loanAmount,
              ...TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
            }
          );
          window.scrollTo(0, 0);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
      },
      addExtraFields() {
        this.setAmountAndLength();
        this.extractUTMParams();
        this.extractMediaCodes();
        this.SET_GC_ID(cookie.get('_ga'));
      },
    },
  };
</script>
