import { render, staticRenderFns } from "./submit-form.vue?vue&type=template&id=7972d620&scoped=true"
import script from "./submit-form.vue?vue&type=script&lang=js"
export * from "./submit-form.vue?vue&type=script&lang=js"
import style0 from "./submit-form.vue?vue&type=style&index=0&id=7972d620&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7972d620",
  null
  
)

export default component.exports