var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"position--relative display--block"},[_c('div',{class:{
      'skewed-edge-top': _vm.skewedTop,
      'skewed-edge-bottom': _vm.skewedBottom,
      'join-top': _vm.joinTop,
    }},[(_vm.skewedTop)?_c('div',{staticClass:"skewed-top",class:{
        'to-left': _vm.topAscending,
        'to-right': !_vm.topAscending,
        dark: _vm.theme == 'dark',
        light: _vm.theme == 'light',
      }}):_vm._e(),_c('section',{staticClass:"position--relative display--block",class:{
        'skewed-content': _vm.skewedTop || _vm.skewedBottom,
        'dark-section':
          _vm.theme == 'dark' || _vm.theme == 'image' || _vm.theme == 'dark-overlay',
        'white-section': !_vm.theme || _vm.theme == 'white',
        'dark-overlay-section': _vm.theme == 'dark-overlay',
        'light-section': _vm.theme == 'light',
        image: _vm.theme == 'image',
        'top-border': _vm.borderTop,
        'bottom-border': _vm.borderBottom,
      },style:({
        paddingTop: (!!_vm.paddingTop ? _vm.paddingTop : _vm.paddingVertical) + 'px',
        paddingBottom:
          (!!_vm.paddingBottom ? _vm.paddingBottom : _vm.paddingVertical) + 'px',
        backgroundImage: _vm.background
          ? 'url(\'' + (_vm.path || '/') + _vm.background + '\')'
          : '',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      })},[_c('div',{class:{ 'max-width': _vm.maxWidth }},[_vm._t("default")],2)]),(_vm.skewedBottom)?_c('div',{staticClass:"skewed-bottom",class:{
        'to-right': _vm.bottomAscending,
        'to-left': !_vm.bottomAscending,
        dark: _vm.theme == 'dark',
        light: _vm.theme == 'light',
        'join-bottom': _vm.joinBottom,
      }}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }