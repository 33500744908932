<template>
  <div>
    <div class="flex--between options-container addMaterialDesign">
      <div class="option-box">
        <span class="display--flex text-padding">
          <len-radio
            id="AUTOMATION"
            key="AUTOMATION"
            v-model="identOption"
            :label="
              $t(
                'signersMissingInfo.formPage.identificationTypeSection.form.option1a'
              )
            "
            value="AUTOMATION"
            name="identType"
          />
        </span>
        <p class="text-padding">
          {{
            $t(
              'signersMissingInfo.formPage.identificationTypeSection.form.option1b'
            )
          }}
        </p>
      </div>
      <div class="option-box flex--between flex--column">
        <span class="display--flex text-padding">
          <len-radio
            id="MANUAL"
            key="MANUAL"
            v-model="identOption"
            :label="
              $t(
                'signersMissingInfo.formPage.identificationTypeSection.form.option2a'
              )
            "
            value="MANUAL"
            name="identType"
          />
        </span>
        <p class="text-padding">
          {{
            $t(
              'signersMissingInfo.formPage.identificationTypeSection.form.option2b'
            )
          }}
        </p>
        <div class="display--flex text-padding option-2-warning">
          <len-icon icon="info" />
          <span class="option-2-text-wrapper">
            <p
              class="option-2-text"
              v-html="
                $t(
                  'signersMissingInfo.formPage.identificationTypeSection.form.option2warning'
                )
              "
            />
            <a
              :href="'mailto:' + contactEmail"
              class="option-2-text mail-contact"
              >{{ contactEmail }}</a
            >
          </span>
        </div>
      </div>
    </div>
    <span v-if="displayError" class="validation validation-text">
      {{
        errors.identificationProcess &&
        $t('forms.errors.' + errors.identificationProcess)
      }}
    </span>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import LenIcon from '@/components/common/len-icon';
  import { isPartnerChannel } from '@/mixins/signersMixin';
  export default {
    components: {
      LenRadio,
      LenIcon,
    },
    mixins: [isPartnerChannel],
    computed: {
      ...mapState('signers', [
        'errors',
        'identificationProcess',
        'submitClicked',
      ]),
      identOption: {
        get() {
          return this.identificationProcess;
        },
        set(value) {
          this.SET_IDENT_PROCESS(value);
        },
      },
      contactEmail() {
        if (this.isPartnerChannel) {
          return this.$t('partnerContactEmail');
        } else {
          return this.$t('companiesEmail');
        }
      },
      displayError() {
        return this.submitClicked && !!this.errors.identificationProcess;
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENT_PROCESS']),
    },
  };
</script>

<style scoped lang="scss">
  p {
    margin-top: 20px;
  }
  .flex--between {
    display: flex;
    justify-content: space-between;
  }
  .flex--column {
    display: flex;
    flex-direction: column;
  }
  .display--flex {
    display: flex;
  }
  :deep(.icon-svg svg) {
    width: 24px;
    height: 24px;
  }
  .option-box {
    font-size: 14px;
    width: 429px;
    height: 181px;
    border: solid 1px #e3e3e3;
    padding-top: 20px;
  }
  .option-2-warning {
    display: flex;
    align-items: center;
    height: 54px;
    border-top: solid 1px #e3e3e3;
    background-color: #f6f6f6;
    margin-top: 20px;
  }
  .option-2-text-wrapper {
    padding-left: 10px;
    line-height: 0.5;
  }
  p.option-2-text {
    display: inline;
  }
  .option-2-text {
    font-size: 12px;
    color: #2e2e2e;
    margin: 0;
    line-height: normal;
  }
  .mail-contact {
    color: #3cbdb9;
  }
  .text-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .validation-text {
    font-size: 14px;
    margin: 2% 5%;
    display: block;
  }
  @media (max-width: 767px) {
    .options-container {
      flex-direction: column;
    }
  }
  @media (max-width: 767px) {
    .option-box {
      width: 100%;
      margin: 2% 0% 0% 0%;
    }
  }
  @media (max-width: 358px) {
    .option-2-text {
      font-size: 10px;
    }
  }
</style>
