<template>
  <len-text-input
    v-model="postalStreetNumber"
    :label="$t('forms.signupForm.streetNumber.label')"
    :error="
      errors.company &&
      errors.company.postalStreetNumber &&
      $t('forms.errors.' + errors.company.postalStreetNumber)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="company-postal-street-number"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      postalStreetNumber: {
        get() {
          return this.company.postalStreetNumber;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_STREET_NUMBER(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_POSTAL_STREET_NUMBER']),
    },
  };
</script>
