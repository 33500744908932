<template>
  <div>
    <div class="online-revenue-container col-sm-5">
      <len-radio
        v-model="onlineRevenuesAtLeast75Percent"
        :label="
          $t('forms.signupForm.onlineRevenuesAtLeast75Percent.dropdown.no')
        "
        name="onlineRevenue"
        :value="false"
      />
      <len-radio
        v-model="onlineRevenuesAtLeast75Percent"
        :label="
          $t('forms.signupForm.onlineRevenuesAtLeast75Percent.dropdown.yes')
        "
        name="onlineRevenue"
        :value="true"
      />
    </div>
    <div
      role="alert"
      class="text-block text-align--left display--block col-xs-12 error-container"
    >
      <div v-if="displayWarning" class="revenue-warning">
        <img
          src="/alert_icon.png"
          alt="Alert Icon"
          class="revenue-warning-icon"
        />
        <p class="revenue-warning-label">
          {{ $t('forms.signupForm.onlineRevenuesAtLeast75Percent.warning') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { onlineRevenueValues } from '@/validators/helpers/onlineRevenue';
  export default {
    components: { LenRadio },
    data() {
      return {
        onlineRevenueValues,
      };
    },
    computed: {
      ...mapState('signers', [
        'company',
        'submitClicked',
        'companySubmitClicked',
      ]),
      onlineRevenuesAtLeast75Percent: {
        get() {
          return this.company.onlineRevenuesAtLeast75Percent;
        },
        set(value) {
          value = value === true;
          this.SET_ONLINE_REVENUE(value);
        },
      },
      displayWarning() {
        return !this.company.onlineRevenuesAtLeast75Percent;
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_ONLINE_REVENUE']),
    },
  };
</script>

<style scoped>
  .online-revenue-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
  .error-container {
    height: 25px;
  }
  @media (max-width: 870px) {
    .online-revenue-container {
      flex-direction: column;
    }
    .error-container {
      height: 40px;
    }
  }
  .revenue-warning {
    display: flex;
    height: 25px;
  }
  .revenue-warning-icon {
    height: 15px;
    align-self: flex-start;
    margin: 2px 5px 0 3px;
  }
  .revenue-warning-label {
    color: #333333;
  }
</style>
