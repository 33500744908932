<template>
  <fieldset
    :class="{ error: displayError }"
    data-qa-id="ident-mobile-phone"
    data-unit-test="ing-ident-mobile-number-field"
  >
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !mobilePhone,
      }"
    >
      <label class="ing-label">
        {{ label }}
      </label>
      <div
        :class="{
          'ing-input-error': displayError,
          'ing-input-focus': isFocused,
        }"
        class="form-control input-container"
      >
        <div class="prefix"> +49 </div>
        <input
          v-model="mobilePhone"
          class="phone-input-field"
          inputmode="numeric"
          :name="safariAutocompleteOffName"
          autocomplete="nofill"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div :class="{ active: displayError }" role="alert" class="ing-error">
        <img src="/alert_icon.png" alt="Alert Icon" />
        <p>
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import safariAutocompleteName from '@/utils/safariAutocompleteName';

  export default {
    data() {
      return {
        isFocused: false,
        isDirty: false,
      };
    },
    computed: {
      ...mapState('ident', ['ident', 'errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.phone.label');
      },
      displayError() {
        const hasError =
          !this.isFocused && this.errors && this.errors.mobilePhone;
        if (!this.submitClicked) {
          return hasError && this.isDirty;
        }
        return hasError;
      },
      mobilePhone: {
        get() {
          return this.ident.mobilePhone;
        },
        set(value) {
          this.SET_MOBILE_PHONE(value);
        },
      },
      error() {
        return (
          this.errors &&
          this.errors.mobilePhone &&
          this.$t(`forms.errors.${this.errors.mobilePhone}`)
        );
      },
      safariAutocompleteOffName() {
        return safariAutocompleteName('mobilePhone');
      },
    },
    methods: {
      ...mapMutations('ident', ['SET_MOBILE_PHONE']),
      onBlur() {
        setTimeout(() => {
          this.isFocused = false;
        }, 200); // On some browsers (Chrome macOS) it's impossible to click on suggestions without this
      },
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;
      },
    },
  };
</script>

<style scoped>
  .input-container {
    display: flex;
    align-items: center;
  }

  .prefix {
    font-size: 16px;
  }

  .phone-input-field {
    font-size: 16px;
    border: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    width: 100%;
    outline: none;
    padding-left: 5px;
  }

  .input-container input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
    opacity: 1; /* Firefox */
  }

  .input-container input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b5b5b5;
  }

  .input-container input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b5b5b5;
  }
</style>
