<template>
  <fieldset
    :class="{ error: displayError }"
    class="form-field addMaterialDesign fieldset-container"
  >
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !value,
        'is-readonly': readonly,
      }"
      class="form-group label-floating"
    >
      <label class="control-label" v-html="label" />
      <textarea
        ref="input"
        :rows="rows"
        :name="safariAutocompleteOffName"
        :value="value"
        :readonly="readonly"
        :class="{ 'has-value': value }"
        class="form-control"
        autocomplete="nofill"
        :maxlength="maxLength"
        @input="$emit('input', $event.target.value)"
        @focus="onFocus"
        @keydown="$emit('keydown', $event)"
        @blur="onBlur"
      />
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import safariAutocompleteName from '@/utils/safariAutocompleteName';

  export default {
    name: 'LenTextareaInput',
    props: {
      error: {
        type: String,
        default: null,
      },
      label: {
        type: String,
        default: '',
      },
      name: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: true,
      },
      isPassword: {
        type: Boolean,
        default: false,
      },
      maxLength: {
        type: String,
        default: null,
      },
      rows: {
        type: String,
        default: '4',
      },
    },
    data() {
      return {
        isFocused: false,
        isDirty: false,
      };
    },
    computed: {
      hasError() {
        return !!this.error && this.error.length > 0;
      },
      displayError() {
        const result = this.hasError && !this.isFocused;

        if (this.displayErrorIfDirty) {
          return this.isDirty && result;
        }

        return result;
      },
      safariAutocompleteOffName() {
        return safariAutocompleteName(this.name);
      },
    },
    methods: {
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;
        this.$emit('focus');
      },
      onBlur() {
        this.isFocused = false;
        this.$emit('blur');
      },
    },
  };
</script>

<style scoped lang="scss">
  .error-container {
    height: 25px;
  }
  .validation {
    margin-bottom: 0;
  }
  .is-readonly input {
    cursor: not-allowed;
  }
  .form-group {
    textarea.form-control {
      transition: max-height 0.25s ease-in-out;
      max-height: 36px;
      height: auto;
      line-height: 14px;

      &:focus,
      &.has-value {
        max-height: 78px;
      }
    }
  }
</style>
