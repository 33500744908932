import getCurrentQuarter from '@/utils/getCurrentQuarter';
import { removeDayFromDate } from '@/utils/stringUtilities';
import LocalStorage from '@/common/localStorage';
import { LOCAL_STORAGE_KEYS } from '@/constants/LOCAL_STORAGE_KEYS';
import Window from '@/common/window';
import { convertCountryIsoToName } from '@/filters/nationalities';

export const successPage = {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
  computed: {
    currentQuarter: function () {
      return getCurrentQuarter();
    },
    currentYearMinusTwo: function () {
      return this.currentYear - 2;
    },
    currentYearMinusOne: function () {
      return this.currentYear - 1;
    },
    showBusinessBulletText: function () {
      // show the third bullet if we are not in the first quarter
      return this.currentQuarter > 1;
    },
  },
};
export const formatDate = {
  methods: {
    formatDate(date, removeDay = false, isPartner = false) {
      let formattedDate = this.$options.filters.momentjsFormat(
        date,
        'DD.MM.YYYY'
      );
      if (!isPartner && removeDay) {
        return removeDayFromDate(formattedDate);
      }

      return formattedDate;
    },
    loadUserData() {
      const applicantData = LocalStorage.getItem(LOCAL_STORAGE_KEYS.SME_SIGNUP);

      if (applicantData) {
        const {
          userStreet,
          userPostcode,
          userCity,
          street,
          postcode,
          city,
          dob,
          dateFounded,
          userCountry,
          revenue,
          ...restOfData
        } = applicantData;
        this.userAddress = `${userStreet}\r\n${userPostcode} ${
          userCity || null
        }`;
        this.address = `${street}\r\n${postcode} ${city || null}`;
        this.nationality = convertCountryIsoToName(userCountry);
        this.revenueData = this.$t(
          `formFields.companyRevenue.radioButton.${revenue}`
        );
        this.revenue = revenue;
        // if it is a partner
        if (restOfData.partnerEmail) {
          this.dob = this.formatDate(dob, false, true) || null;
          this.dateFounded = this.formatDate(dateFounded, false, true) || null;
        } else {
          this.dob = this.formatDate(dob) || null;
          this.dateFounded = this.formatDate(dateFounded, true) || null;
        }

        Object.keys(restOfData).forEach((key) => {
          this[key] = restOfData[key] || null;
        });
      }
    },
  },
};

export const removeLocalStorageUserData = {
  created() {
    Window.addEventListener('unload', this.removeLocalStorage);
  },
  destroyed() {
    Window.removeEventListener('unload', this.removeLocalStorage);
  },
  methods: {
    removeLocalStorage() {
      LocalStorage.removeItem(LOCAL_STORAGE_KEYS.SME_SIGNUP);
    },
  },
};
