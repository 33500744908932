<template>
  <div v-show="loading" class="loading-wrapper">
    <div class="loading" :class="{ small: small, medium: medium }">
      <svg class="circular">
        <circle class="path-bg" />
        <circle class="path" />
      </svg>
    </div>
    <div v-if="$slots.label" class="loading-label">
      <slot name="label" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      storeModule: {
        type: String,
        default: '',
      },
      manuallyShow: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
      medium: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      loading: function () {
        const storeLoadingStatus =
          this.storeModule && this.$store.state[this.storeModule].loading;
        return storeLoadingStatus || this.manuallyShow;
      },
    },
  };
</script>

<style scoped lang="scss">
  @use 'sass:math';
  @import '../../../sass/custom/variables';
  @import '@/sass/components/common/ing.orange.juice';

  $color: $ing-orange;
  $bgColor: #dadada;
  $defaultWidth: 60px;
  $defaultBorderSize: math.div(
    stripUnit($defaultWidth) * 3,
    32
  ); // base width is 32px and the base border width is 3pt

  $mediumWidth: 42px;
  $mediumBorderSize: math.div(stripUnit($mediumWidth) * 3, 32);

  $smallWidth: 24px;
  $smallBorderSize: math.div(stripUnit($smallWidth) * 3, 32);

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=);
    z-index: 9999;

    .loading {
      position: relative;
      width: $defaultWidth;
      height: $defaultWidth;

      .circular {
        animation: rotate 2s linear infinite;
        height: inherit;
        position: relative;
        width: inherit;

        .path,
        .path-bg {
          cx: 50%;
          cy: 50%;
          fill: none;
          r: #{math.div(stripUnit($defaultWidth - $defaultBorderSize), 2)}px;
          stroke-width: $defaultBorderSize;
          stroke-linecap: round;
        }
        .path {
          animation: dash 1.5s ease-in-out infinite;
          stroke: $color;
        }

        .path-bg {
          stroke: $bgColor;
        }
      }

      &.small {
        width: $smallWidth;
        height: $smallWidth;

        .circular {
          .path,
          .path-bg {
            r: #{math.div(stripUnit($smallWidth - $smallBorderSize), 2)}px;
            stroke-width: $smallBorderSize;
          }
        }
      }

      &.medium {
        width: $mediumWidth;
        height: $mediumWidth;

        .circular {
          .path,
          .path-bg {
            r: #{math.div(stripUnit($mediumWidth - $mediumBorderSize), 2)}px;
            stroke-width: $mediumBorderSize;
          }
        }
      }
    }

    .loading-label {
      margin-top: 9px;
      font-size: 16px;
      line-height: 24px;
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1%, 300%;
      stroke-dashoffset: 0%;
    }

    50% {
      stroke-dasharray: 190%, 300%;
      stroke-dashoffset: -100%;
    }

    100% {
      stroke-dasharray: 190%, 300%;
      stroke-dashoffset: -280%;
    }
  }
</style>
