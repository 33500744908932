import nationalities from '@/components/common/assets/nationalities';

export function convertCountryIsoToName(nationalityIso) {
  const country = nationalities.find(
    (element) => element.iso === nationalityIso
  );
  if (country) {
    return country.name;
  } else {
    return null;
  }
}

export default function install(Vue) {
  Vue.filter('convertCountryIsoToName', convertCountryIsoToName);
}
