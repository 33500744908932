<template>
  <div class="progress-bar-container col-lg-7 col-md-9 col-sm-10 col-xs-10">
    <div class="step-container">
      <div
        :class="{ active: shouldShowStep1, inactive: !shouldShowStep1 }"
        class="step-icon"
        @click="setCurrentStep(1)"
      >
        1
      </div>
      <p
        :class="{ active: shouldShowStep1 }"
        class="step-text"
        @click="setCurrentStep(1)"
      >
        {{ $t('borrowerJourney.progressBar.firstStep') }}
      </p>
    </div>
    <div
      :class="{
        'non-visited': offerIsNotResolved,
        visited: !offerIsNotResolved,
      }"
      class="progress-line"
    />
    <div class="step-container">
      <div
        :class="{
          active: shouldShowStep2,
          inactive: !shouldShowStep2,
          disabled: offerIsNotResolved,
        }"
        class="step-icon"
        @click="setCurrentStep(2)"
      >
        2
      </div>
      <p
        :class="{ active: shouldShowStep2, disabled: offerIsNotResolved }"
        class="step-text"
        @click="setCurrentStep(2)"
      >
        {{ $t('borrowerJourney.progressBar.secondStep') }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapState('offer', ['state', 'currentStep']),
      offerIsNotResolved() {
        return this.state === 'OFFER_SENT';
      },

      shouldShowStep1() {
        return this.currentStep === 1;
      },
      shouldShowStep2() {
        return this.currentStep === 2;
      },
    },
    methods: {
      ...mapMutations('offer', ['SET_CURRENT_STEP']),
      setCurrentStep(step) {
        if (!this.offerIsNotResolved) {
          this.SET_CURRENT_STEP(step);
        }
      },
    },
  };
</script>

<style scoped>
  .progress-bar-container {
    display: flex;
    padding: 50px;
    align-items: center;
  }
  .progress-line {
    flex-grow: 1;
    width: 450px;
    height: 2px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  .progress-line.non-visited {
    background-color: #e3e3e3;
  }
  .progress-line.visited {
    background-color: #3cbdb9;
  }

  .step-icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    border: 2px solid #3cbdb9;
    font-weight: bold;
    cursor: pointer;
  }
  .step-icon.active {
    background-color: #3cbdb9;
    color: white;
  }
  .step-icon.inactive {
    background-color: transparent;
    color: #3cbdb9;
  }
  .step-icon.disabled {
    background-color: #e3e3e3;
    border: 2px solid #e3e3e3;
    color: white;
    cursor: default;
  }
  .step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .step-text {
    position: absolute;
    margin-top: 40px;
    cursor: pointer;
  }
  .step-text.active {
    font-weight: bold;
  }
  .step-text.disabled {
    cursor: default;
  }
  @media (max-width: 767px) {
    .progress-line {
      width: 200px;
    }
  }
  @media (max-width: 800px) {
    .progress-bar-container {
      padding: 50px 10px;
      font-size: 11px;
    }
    .step-icon {
      width: 20px;
      height: 20px;
    }
    .step-text {
      margin-top: 30px;
    }
  }
</style>
