<template>
  <div>
    <h2>{{ $t('ingOfferPage.contractSection.contracts.title') }}</h2>
    <ing-contract-block :key="contractType" :contractType="contractType" />
    <ing-contract-block
      v-show="!!blanket_assignment.data"
      key="blanket_assignment"
      contractType="blanket_assignment"
    />
    <ing-contract-block
      v-show="!!power_of_attorney.data"
      key="power_of_attorney"
      contractType="power_of_attorney"
    />
    <h2>{{ $t('ingOfferPage.contractSection.identification.title') }}</h2>
    <p>{{ $t('ingOfferPage.contractSection.identification.p1') }}</p>
    <p>{{ $t('ingOfferPage.contractSection.identification.p2') }}</p>
    <h3>{{ $t('ingOfferPage.contractSection.nextSteps.title') }}</h3>
    <ul>
      <li>{{ $t('ingOfferPage.contractSection.nextSteps.li1') }}</li>
      <li>{{ $t('ingOfferPage.contractSection.nextSteps.li2') }}</li>
      <li>{{ $t('ingOfferPage.contractSection.nextSteps.li3') }}</li>
      <li>{{ $t('ingOfferPage.contractSection.nextSteps.li4') }}</li>
    </ul>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngContractBlock from '@/components/ing/offer/ing-contract-block';
  export default {
    components: { IngContractBlock },
    computed: {
      ...mapState('offer', ['productType']),
      ...mapState('contract', ['blanket_assignment']),
      ...mapState('contract', [
        'credit',
        'micro_credit',
        'blanket_assignment',
        'power_of_attorney',
      ]),
      contractToFetch() {
        if (this.productType === 'BLACKSEA_WCL_REGULAR') {
          return 'getWclRegularContracts';
        } else if (this.productType === 'BLACKSEA_WCL_MICRO') {
          return 'getWclMicroContract';
        } else if (this.productType === 'BLACKSEA_REGULAR') {
          return 'getIngRegularContracts';
        } else {
          return 'getIngMicroContract';
        }
      },
      contractType() {
        if (
          this.productType === 'BLACKSEA_REGULAR' ||
          this.productType === 'BLACKSEA_WCL_REGULAR'
        ) {
          return 'credit';
        } else {
          return 'micro_credit';
        }
      },
    },
    mounted() {
      this.getContracts();
    },
    methods: {
      ...mapMutations('offer', ['SET_LOADING']),
      async getContracts() {
        this.SET_LOADING(true);
        await this.$store.dispatch(`contract/${this.contractToFetch}`);
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/variables';

  h2 {
    font-weight: bold;
  }

  h3 {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 40px;
  }

  p,
  li {
    color: $ing-gray-advantages;
  }

  li {
    line-height: 24px;
  }
  li::before {
    background: no-repeat left url('~@/assets/img/ing_submit_icon.png');
    background-size: contain;
    width: 16px;
    height: 14px;
    content: '';
    display: inline-block;
    margin-right: 20px;
  }
</style>
