<template>
  <len-dropdown-input
    :value="loanPurposeBorrower"
    name="loanPurpose__c"
    :options="loanPurposeOptions"
    :label="label"
    :error="errorMessage"
    :displayErrorIfSubmitted="displayError"
    @input="handleChange"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenDropdownInput from '@/components/common/len-dropdown-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenDropdownInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    data() {
      return {
        loanPurposeOptions: [
          {
            value: this.$t(
              'forms.signupForm.loanPurpose.dropdown.workingCapital'
            ),
            label: this.$t(
              'forms.signupForm.loanPurpose.dropdown.workingCapital'
            ),
          },
          {
            value: this.$t(
              'forms.signupForm.loanPurpose.dropdown.debtRefinancing'
            ),
            label: this.$t(
              'forms.signupForm.loanPurpose.dropdown.debtRefinancing'
            ),
          },
          {
            value: this.$t(
              'forms.signupForm.loanPurpose.dropdown.assetPurchase'
            ),
            label: this.$t(
              'forms.signupForm.loanPurpose.dropdown.assetPurchase'
            ),
          },
          {
            value: this.$t(
              'forms.signupForm.loanPurpose.dropdown.expansionGrowthFinancing'
            ),
            label: this.$t(
              'forms.signupForm.loanPurpose.dropdown.expansionGrowthFinancing'
            ),
          },
        ],
      };
    },
    computed: {
      ...mapState('signupForm', [
        'errors',
        'loanPurposeBorrower',
        'submitClicked',
      ]),
      label() {
        return this.$t('forms.signupForm.loanPurpose.label');
      },
      errorMessage() {
        return (
          this.errors.loanPurposeBorrower &&
          this.$t('forms.errors.' + this.errors.loanPurposeBorrower)
        );
      },
      displayError() {
        return this.submitClicked && !!this.errors.loanPurposeBorrower;
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_LOAN_PURPOSE']),
      handleChange(selectedValue) {
        this.SET_LOAN_PURPOSE(selectedValue);
        this.sendFieldTrackingEvent(`select:${this.label}`, selectedValue);
      },
    },
  };
</script>
