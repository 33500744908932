<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('signers.clientDetails.client.mailingAddress') }}
      </h3>
      <CheckBox
        v-model="homeAddressIsPostalAddress"
        :label="$t('signers.form.address.isDifferentCheck')"
      />
      <div v-show="signerInformation.homeAddressIsPostalAddress">
        <TextInput
          v-model="postalStreet"
          :label="$t('signers.form.address.street')"
          :error="getError('postalStreet')"
          :displayErrorIfDirty="displayError()"
        />
        <TextInput
          v-model="postalStreetNumber"
          inputSize="xs"
          :label="$t('signers.form.address.houseNumber')"
          :error="getError('postalStreetNumber')"
          :displayErrorIfDirty="displayError()"
          inputmode="numeric"
        />
        <TextInput
          v-model="postalZip"
          inputSize="xs"
          :label="$t('signers.form.address.postalCode')"
          :error="getError('postalZip')"
          :displayErrorIfDirty="displayError()"
          inputmode="numeric"
        />
        <TextInput
          v-model="postalCity"
          inputSize="m"
          :label="$t('signers.form.address.city')"
          :error="getError('postalCity')"
          :displayErrorIfDirty="displayError()"
        />
        <Dropdown
          v-model="postalCountry"
          :label="$t('signers.form.address.country')"
          :options="countryDropdownOptions()"
          :error="getError('postalCountry')"
          :displayErrorIfSubmitted="displayError()"
        />
      </div>
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import CheckBox from '@/components/ingOrangeJuice/Base/CheckBox';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import countryDropdownMixin from '@/mixins/countryDropdownMixin';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  export default {
    components: {
      TextInput,
      CheckBox,
      Dropdown,
      FormSubSection,
    },
    mixins: [countryDropdownMixin],
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['submitClicked', 'signers', 'errors']),
      signerInformation() {
        return this.signers[this.signerId];
      },
      homeAddressIsPostalAddress: {
        get() {
          return this.signers[this.signerId].homeAddressIsPostalAddress;
        },
        set(newValue) {
          this.SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS({
            signerId: this.signerId,
            homeAddressIsPostalAddress: newValue,
          });
          this.handleAddressChange(newValue);
        },
      },
      postalStreet: {
        get() {
          return this.signers[this.signerId].postalStreet;
        },
        set(value) {
          this.SET_POSTAL_STREET({
            signerId: this.signerId,
            postalStreet: value,
          });
        },
      },
      postalStreetNumber: {
        get() {
          return this.signers[this.signerId].postalStreetNumber;
        },
        set(value) {
          this.SET_POSTAL_STREET_NUMBER({
            signerId: this.signerId,
            postalStreetNumber: value,
          });
        },
      },
      postalZip: {
        get() {
          return this.signers[this.signerId].postalZip;
        },
        set(value) {
          this.SET_POSTAL_ZIP({ signerId: this.signerId, postalZip: value });
        },
      },
      postalCity: {
        get() {
          return this.signers[this.signerId].postalCity;
        },
        set(value) {
          this.SET_POSTAL_CITY({ signerId: this.signerId, postalCity: value });
        },
      },
      postalCountry: {
        get() {
          return this.signers[this.signerId].postalCountry;
        },
        set(value) {
          this.SET_POSTAL_COUNTRY({
            signerId: this.signerId,
            postalCountry: value,
          });
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_POSTAL_STREET',
        'SET_POSTAL_ZIP',
        'SET_POSTAL_CITY',
        'SET_POSTAL_COUNTRY',
        'SET_POSTAL_STREET_NUMBER',
        'SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'CLEAR_CLIENT_POSTAL_ADDRESS',
      ]),
      getError(field) {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId][field] &&
          this.$t('forms.errors.' + this.errors[this.signerId][field])
        );
      },
      displayError() {
        return (
          !this.submitClicked && !this.signers[this.signerId].submitClicked
        );
      },
      handleAddressChange(newAddressvalue) {
        if (newAddressvalue) {
          this.CLEAR_CLIENT_POSTAL_ADDRESS(this.signerId);
        } else {
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(this.signerId);
        }
      },
    },
  };
</script>
