import { getStateValidator } from './getters';
import * as actions from './actions';
import * as getters from './getters';
import { mutations } from './mutations';
import { DEFAULT_IBAN_VALUE } from '@/constants/IBAN_FORMAT';

export const initialState = {
  loanAmount: null,
  length: null,
  disbursementAmount: null,
  company: null,
  street: null,
  postcodeDe: null,
  city: null,
  dateFounded: null,
  revenue: null,
  legalStatus: null,
  loanPurposeBorrower: null,
  bookkeepingStandard: null,
  salutation: null,
  title: null,
  firstName: null,
  lastName: null,
  email: null,
  nationality: '',
  mobilePhoneBorrower: null,
  dob: null,
  userStreet: null,
  userPostcodeDe: null,
  userCity: null,
  privacyPolicy: true,
  marketingAgreement: false,
  companies: [],
  mappingId: null,
  server: null,
  errors: {},
  submitClicked: false,
  loading: false,
  submitter: null,
  searchingCompany: false,
  isBookkeepingStandardVisible: false,
  medium: null,
  source: null,
  product: null,
  campaignName: null,
  content: null,
  term: null,
  contentInfo: null,
  keywordInfo: null,
  mediaCodeInfo: null,
  gcid: null,
  applicationStatus: null,
  prospectId: null,
  companyLookupEnabled: true,
  iban: DEFAULT_IBAN_VALUE,
  bic: null,
};

const StateValidator = getStateValidator(initialState);
initialState.errors = StateValidator(initialState);

export default {
  state: initialState,
  getters,
  actions,
  mutations,
  namespaced: true,
};
