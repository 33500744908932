export const calculatorLoaderMixin = {
  methods: {
    async loadCalculator() {
      const calculatorUrl = `${process.env.VUE_APP_WIDGETS_URL}/lendico-loan-calculator.js?cdnCache=0`;
      try {
        await this.$loadScript(calculatorUrl);
      } catch (e) {
        setTimeout(async () => {
          await this.$loadScript(calculatorUrl);
        }, 5000);
      }
    },
  },
};
