export default {
  offer: {
    detailsSection: {
      heading: 'Finanzierungsvorschlag',
      title: 'Ihre Finanzierungskonditionen auf einen Blick',
      regular: {
        loanAmount: 'Kreditbetrag:',
        duration: 'Laufzeit:',
        interestRate: 'Gebundener Sollzinssatz:',
        monthlyRate: 'Monatliche Rate:',
      },
      wcl: {
        loanAmount: 'Flexkredit:',
        duration: 'Laufzeit:',
        durationValue: 'unbefristet',
        interestRate: 'Gebundener Sollzinssatz p.a.:',
        firstPayoutAmount: 'Erster Auszahlungsbetrag:',
        rateOnFirstMonth: 'Rate im ersten Monat:',
        rateTooltip:
          'Die Rate im ersten Monat besteht aus den Zinsen auf den ersten Auszahlungsbetrag sowie einem Rückzahlungsanteil von 8 % des ersten Auszahlungsbetrags. Falls Sie zusätzliche Auszahlungen oder Rückzahlungen tätigen, kann sich dieser Betrag ändern. ',
        explanatoryText:
          '*Zinssatz ist für 12 Monate gebunden. Weitere Infos entnehmen Sie dem Vertragsangebot.',
      },
      acceptOfferText:
        'Bitte klicken Sie auf „Identifizierung starten”, um Ihre Vertragsunterlagen herunterzuladen und die Identifizierung zu starten.',
      p: 'Klicken Sie auf den Button, um Ihre Vertragsunterlagen herunterzuladen und die Identifizierung zu starten.',
      acceptButton: 'Identifizierung starten',
    },
    contractSection: {
      contracts: {
        title: 'Ihre Vertragsunterlagen',
        missingContract: 'Nicht verfügbar',
        preview: 'Vorschau',
        download: 'Herunterladen',
        page: 'Seite',
        close: 'Schließen',
      },
      identification: {
        title: 'Identifizieren und unterschreiben in nur einem Schritt',
        p1:
          'In Ihrem E-Mail-Postfach finden Sie eine Schritt-für-Schritt-Anleitung zur Identifizierung per Videochat ' +
          'sowie eine weitere E-Mail der Deutschen Post. Diese enthält einen Link, mit dem Sie das Online-Verfahren starten.',
        p2:
          'Wenn Sie und mögliche weitere Vertretungsberechtigte sich erfolgreich identifiziert und digital unterschrieben haben,' +
          ' erhalten Sie die Vertragsunterlagen per E-Mail. Bitte beachten Sie, dass für die Identitätsprüfung ein Computer, ' +
          'ein Smartphone oder ein Tablet mit Kamera und Mikrofon sowie eine stabile Internetverbindung notwendig sind.',
      },
      nextSteps: {
        title: 'Wie geht es weiter?',
        li1: 'Prüfen Sie Ihre Vertragsunterlagen.',
        li2: 'Klicken Sie auf den Link in der E-Mail der Deutschen Post.',
        li3: 'Identifizieren Sie sich mit einem gültigen Ausweisdokument.',
        li4: 'Unterschreiben Sie die Verträge durch Eingabe einer SMS-TAN.',
      },
      contractDocuments: {
        creditContract: 'Kreditvertrag',
        micro_creditContract: 'Kreditvertrag',
        guaranteeContract: 'Bürgschaftsvertrag',
        blanket_assignmentContract: 'Globalzession',
        power_of_attorneyContract: 'Vollmacht',
      },
    },
    signingInfoSection: {
      title: 'Sichere Identifikation und Vertragsunterzeichnung',
      p1: {
        title: 'Vertrauenswürdiger Partner: ',
        content:
          'Die Deutsche Post bietet ein sicheres Online-Verfahren zur Überprüfung Ihrer Identität und zur Vertragsunterzeichnung. ' +
          'Damit sparen Sie sich den Weg zur Postfiliale.',
      },
      p2: {
        title: 'Einfach und schnell: ',
        content:
          'Sie können die Identifizierung und die Vertragsunterzeichnung jederzeit vornehmen und auch erneut starten, ' +
          'wann immer es Ihnen am besten passt. Alle Geschäftsführer/-innen und mögliche weitere Vertretungsberechtigte ' +
          'unterschreiben nacheinander. Sie werden von der Deutschen Post informiert, sobald alle Unterschriften eingegangen sind.',
      },
    },
    contactSection: {
      title: 'Unser Team ist persönlich für Sie da',
      p1:
        'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail. ' +
        'Unsere Kundenbetreuung ist <b>Montag bis Freitag von 9 bis 18 Uhr</b> für Sie da:',
      p2: ' oder ',
    },
    dpagContractSuccess: {
      message: {
        header: {
          title: 'Vielen Dank für Ihr Vertrauen!',
          description:
            'Wenn uns alle Unterschriften vorliegen, zahlen wir Ihr Darlehen innerhalb der nächsten Werktage aus. Sollten wir noch Unterschriften von weiteren Vertragsparteien oder Vertretungsberechtigten benötigen, laden wir diese nun nacheinander zur Legitimierung bzw. Vertragsunterzeichnung ein.',
        },
      },
      contact: {
        title: 'Unser Team ist persönlich für Sie da',
        para1:
          'Haben Sie noch Fragen? Von {boldText} sind wir telefonisch oder per E-Mail für Sie da:',
        boldText: 'Montag bis Freitag zwischen 9 bis 18 Uhr',
        para2: {
          text: '{phoneNumber} oder {emailAddress}',
        },
      },
    },
    dpagContractFailure: {
      support: 'Support kontaktieren',
      message: {
        header: {
          title:
            'Die Vertragsunterzeichnung konnte nicht abgeschlossen werden.',
          description:
            'Leider konnten wir die Vertragsunterzeichnung nicht abschließen. Dafür kann es unterschiedliche Gründe geben. Unser Service-Team wird sich innerhalb von 48 Stunden telefonisch bei Ihnen melden. Wenn Sie nicht so lange warten wollen, können Sie uns auch direkt kontaktieren.',
        },
      },
    },
  },
};
