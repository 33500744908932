export default {
  auth: {
    form: {
      title: 'Bitte melden Sie sich mit Ihrem Passwort an',
      explanation:
        'Verwenden Sie das Passwort, das Sie telefonisch von Ihrer Kundenbetreuerin oder Ihrem Kundenbetreuer erhalten haben.',
      password: {
        label: 'Passwort',
      },
      submit: 'Anmelden',
      errors: {
        passWordFormatIsInvalid: {
          message: 'Ungültiges Passwort. Bitte überprüfen Sie Ihre Eingabe.',
          label:
            'Ihr eingegebenes Passwort ist nicht korrekt. Verwenden Sie das Passwort, das Sie telefonisch von Ihrer Kundenbetreuerin oder Ihrem Kundenbetreuer erhalten haben.',
        },
        passWordIsIncorrect: {
          label:
            'Die Anmeldung war leider nicht erfolgreich. Bitte überprüfen Sie das Passwort und wiederholen Sie Ihre Eingabe.',
          message:
            'Ihr eingegebenes Passwort ist nicht korrekt. Verwenden Sie das Passwort, das Sie telefonisch von Ihrer Kundenbetreuerin oder Ihrem Kundenbetreuer erhalten haben.',
        },
        passWordIsBlocked: {
          label: 'Ihr Zugang ist für 24 Stunden gesperrt',
          message: `Ihr Passwort wurde wiederholt falsch eingegeben. Aus Sicherheitsgründen wird Ihr Zugang für 24 Stunden gesperrt. Wenn Sie Ihr Passwort vergessen haben, wenden Sie sich bitte per E-Mail an business@ing.de oder rufen Sie uns Montag bis Freitag zwischen 9 und 18 Uhr an unter: 030 / 398 205 269`,
        },
        passwordHasExpired: {
          label: 'Diese Seite existiert nicht mehr',
          message:
            'Die Seite ist wegen Inaktivität abgelaufen. Bitte wenden Sie sich an unseren Kundenservice. Sie erreichen uns per E-Mail an business@ing.de oder telefonisch von Montag bis Freitag zwischen 9 und 18 Uhr unter 030 / 398 205 269. Vielen Dank.',
        },
        generalError: {
          label:
            'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
          message:
            'Ihr eingegebenes Passwort ist nicht korrekt. Verwenden Sie das Passwort, das Sie telefonisch von Ihrer Kundenbetreuerin oder Ihrem Kundenbetreuer erhalten haben.',
        },
      },
    },
  },
};
