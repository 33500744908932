<template>
  <div class="display--inline-block grid__item col-xs-12 col-md-12 text-block">
    <p align="center">
      {{ $t('signersMissingInfo.formPage.privacyPolicy.preHighlightedText') }}
      <em>
        {{ $t('signersMissingInfo.formPage.privacyPolicy.highlightedText') }}
      </em>
      {{ $t('signersMissingInfo.formPage.privacyPolicy.preLinkPageText') }}
      <a
        :href="
          process.env.VUE_APP_LENDICO_BASE_URL_CMS_API +
          $t('signersMissingInfo.formPage.privacyPolicy.link')
        "
        target="_blank"
        >{{ $t('signersMissingInfo.formPage.privacyPolicy.linkPageText') }}</a
      >
      {{ $t('signersMissingInfo.formPage.privacyPolicy.preLinkDocumentText') }}
      <a
        :href="$t('signersMissingInfo.formPage.privacyPolicy.linkIng')"
        target="_blank"
        >{{
          $t('signersMissingInfo.formPage.privacyPolicy.linkDocumentText')
        }}</a
      >
      {{ $t('signersMissingInfo.formPage.privacyPolicy.postLinkText') }}
    </p>
  </div>
</template>
<script>
  export default {};
</script>

<style scoped>
  * {
    font-family: 'Source Sans Pro', Calibri, Helvetica, sans-serif;
  }
  p {
    font-size: 14px;
    color: #808080;
  }
  a {
    font-size: inherit !important;
  }
  em {
    font-weight: bold;
    color: #2e2e2e;
  }
</style>
