import LocalStorage from '@/common/localStorage';
import moment from 'moment';
import { LOCAL_STORAGE_KEYS } from '@/constants/LOCAL_STORAGE_KEYS';

/**
 * @typedef {Object} Locale
 * @property {string} locale
 */
const state = {
  locale: null,
  localesList: [],
};

const actions = {};
const getters = {};

const mutations = {
  STORE_TO_LOCAL_STORAGE: (state) => {
    LocalStorage.setItem(LOCAL_STORAGE_KEYS.LOCALE, state.locale);
  },
  SET_LOCALE: (state) => {
    moment.locale(state.locale);
  },
  INITIALIZE: (state, envVars) => {
    state.localesList = (envVars.VUE_APP_LENDICO_LOCALES_LIST || '').split('|');
    let currentLocale = LocalStorage.getItem(LOCAL_STORAGE_KEYS.LOCALE);
    if (!currentLocale) {
      LocalStorage.setItem(
        LOCAL_STORAGE_KEYS.LOCALE,
        envVars.VUE_APP_LENDICO_COUNTRY
      );
    }
    moment.locale(state.locale);
  },
};

export default { state, getters, actions, mutations, namespaced: true };
