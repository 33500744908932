<template>
  <div>
    <div
      class="col-sm-12 account-details"
      :class="{ 'account-details--no-margin': isBookkeepingVisible }"
    >
      <h3 class="account-details__header">
        {{ $t('forms.signupForm.ingAccountDetails.header') }}
      </h3>
      <p class="account-details__sub-header">
        {{ $t('forms.signupForm.ingAccountDetails.subHeader') }}
      </p>
    </div>
    <div class="col-sm-6 ing-column-left">
      <ing-iban-field />
    </div>
    <div class="col-sm-6 ing-column-right">
      <ing-bic-field @blur="$emit('lastFieldBlurred')" />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import IngIbanField from '@/components/ing/forms/ing-user-account-details/ing-iban-field';
  import IngBicField from '@/components/ing/forms/ing-user-account-details/ing-bic-field';

  export default {
    components: {
      IngIbanField,
      IngBicField,
    },
    computed: {
      ...mapGetters('signupForm', ['isBookkeepingVisible']),
    },
  };
</script>

<style scoped lang="scss">
  .account-details {
    margin-top: 10px;

    &--no-margin {
      margin-top: 0;
    }

    &__header {
      font-size: 19px;
      line-height: 27px;
      margin-bottom: 4px;
      font-weight: bold;
    }
    &__sub-header {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 20px;
    }
  }
</style>
