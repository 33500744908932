<template>
  <div class="container-fluid">
    <header class="row">
      <len-header class="col-lg-12" />
    </header>
    <main class="row centered-container">
      <section class="confirmation-container">
        <div class="confirmation-section">
          <len-icon icon="thumbs-up" />
          <h1>{{ $t('signersMissingInfo.successPage.h1') }}</h1>
          <p class="confirmation-subtitle">
            {{ $t('signersMissingInfo.successPage.p') }}
          </p>
        </div>
      </section>
    </main>
    <len-footer class="row" />
  </div>
</template>

<script>
  import $ from 'jquery/dist/jquery.slim.min';
  import Window from '@/common/window';
  import LenIcon from '@/components/common/len-icon';
  import { enableGTM } from '@/mixins/gtmMixins';
  import lenHeader from '@/components/common/len-header';
  export default {
    name: 'SignersSuccessPage',
    components: {
      LenIcon,
      lenHeader,
    },
    mixins: [enableGTM],
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
    computed: {
      isMobile: function () {
        return $(Window).width() < 768;
      },
    },
  };
</script>

<style scoped>
  * {
    font-family: 'Source Sans Pro', Calibri, Helvetica, sans-serif;
  }
  :deep(.icon-svg svg) {
    width: 150px;
    height: 150px;
  }
  .confirmation-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .confirmation-subtitle {
    font-size: 24px;
    line-height: 1.5;
    color: #808080;
  }
  h1 {
    font-size: 40px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #29bdba;
    margin-bottom: 20px;
    margin-top: 40px;
  }
  .centered-container {
    flex-grow: 1;
    margin-top: 53px;
    padding: 40px;
    text-align: center;
  }
</style>
