<template>
  <div class="ing-wrapper">
    <ing-header />
    <IngButton
      type="button"
      class="outlined small align-right"
      @click="userLogoutMixin(productName)"
      >{{ $t('logoutSection.logoutButton') }}</IngButton
    >
    <main class="row centered-container">
      <form
        class="ing-signer-form-container col-lg-9 col-md-11 col-xs-11"
        @submit.prevent="submit"
      >
        <ing-form-loader storeModule="signers" />
        <section>
          <ing-offer-conditions-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <ing-company-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <ing-client-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <ing-ident-type-information class="col-lg-12 col-md-12" />
        </section>
        <section :class="{ 'no-section-margin': isRegularLoanRequest }">
          <ing-submit-form class="col-lg-12 col-md-12" />
        </section>
        <section class="last-container">
          <ing-privacy-policy class="col-xs-12 col-md-12" />
        </section>
      </form>
      <ing-authentication-modal
        :id="opportunityId"
        :show="isUserUnauthorised"
        @success="clearServerError()"
      />
    </main>
    <ing-footer />
  </div>
</template>

<script>
  import $ from 'jquery/dist/jquery.slim.min';
  import Window from '@/common/window';
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
  import IngHeader from '@/components/ing/ing-header';
  import IngFooter from '@/components/ing/ing-footer';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngOfferConditionsInformation from '@/components/ing/signers/ing-offer-conditions-information';
  import IngCompanyInformation from '@/components/ing/signers/ing-signers-company-information';
  import IngClientInformation from '@/components/ing/signers/ing-signers-client-information';
  import IngIdentTypeInformation from '@/components/ing/signers/ing-ident-type-information';
  import IngSubmitForm from '@/components/ing/signers/ing-submit-form';
  import IngPrivacyPolicy from '@/components/ing/signers/ing-signers-privacy-policy';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import {
    isRegularLoanRequest,
    isTestAndLearnEnabled,
  } from '@/mixins/signersMixin';
  import IngAuthenticationModal from '@/components/ing/authentication/ing-authentication-modal';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import { userLogoutMixin } from '@/mixins/userLogoutMixin';
  import { TRACK_EVENTS } from '@/constants/WEBTREKK';

  export default {
    name: 'IngSignersMissingFormPage',
    components: {
      IngHeader,
      IngFooter,
      IngButton,
      IngOfferConditionsInformation,
      IngCompanyInformation,
      IngClientInformation,
      IngIdentTypeInformation,
      IngSubmitForm,
      IngPrivacyPolicy,
      IngFormLoader,
      IngAuthenticationModal,
    },
    mixins: [
      isRegularLoanRequest,
      isTestAndLearnEnabled,
      webtrekkMixin,
      userLogoutMixin,
    ],
    props: {
      opportunityId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isAllDataProvided: false,
      };
    },
    computed: {
      ...mapState('signers', ['signers', 'company', 'success', 'errors']),
      ...mapGetters('signers', [
        'isValid',
        'areSignersEmailsUnique',
        'areSignersPhonesUnique',
        'isTermLoanProduct',
      ]),
      isUserUnauthorised() {
        return (
          this.getDataReturnsUnauthorised ||
          this.companyUpdateReturnsUnauthorised ||
          this.clientUpdateReturnsUnauthorised
        );
      },
      productName() {
        return this.isTermLoanProduct ? 'firmenkredit' : 'flexkredit';
      },
      getDataReturnsUnauthorised() {
        return !!this.errors.server && this.errors.server.status === 401;
      },
      companyUpdateReturnsUnauthorised() {
        return (
          !!this.errors.companyServer &&
          this.errors.companyServer.status === 401
        );
      },
      clientUpdateReturnsUnauthorised() {
        return (
          !!this.errors.clientServer && this.errors.clientServer.status === 401
        );
      },
      isMobile: function () {
        return $(Window).width() < 768;
      },
    },
    mounted() {
      this.setOpportunityId();
      this.fetchClientsInfo();
    },
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
    methods: {
      ...mapMutations('signers', [
        'SET_OPPORTUNITY_ID',
        'SET_SUBMIT_CLICKED',
        'SET_LOADING',
        'CLEAR_REQUEST_ERROR',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
      ...mapActions('signers', [
        'getSignersMissingDetails',
        'getCompanyDetails',
        'updateSignersMissingDetails',
      ]),
      setOpportunityId() {
        this.SET_OPPORTUNITY_ID(this.opportunityId);
      },
      clearServerError() {
        this.fetchClientsInfo();
      },
      updateValidators() {
        this.updateSignersPostalAddress();
      },
      async fetchClientsInfo() {
        this.SET_LOADING(true);
        this.CLEAR_REQUEST_ERROR();
        await this.getSignersMissingDetails();
        await this.getCompanyDetails();
        this.redirectIfNoMissingData();
        this.trackClientUI();
        this.updateValidators();
        this.SET_LOADING(false);
      },
      redirectIfNoMissingData() {
        if (
          !this.isUserUnauthorised &&
          this.errors.server &&
          this.errors.server.data &&
          this.errors.server.data.code === 70004
        ) {
          this.isAllDataProvided = true;
          this.trackAndRedirectToSuccess();
        }
      },
      updateSignersPostalAddress() {
        Object.keys(this.signers).forEach((signerId) =>
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(signerId)
        );
      },
      async submit() {
        this.CLEAR_REQUEST_ERROR();
        this.SET_SUBMIT_CLICKED(true);
        if (
          !this.isValid ||
          !this.areSignersEmailsUnique ||
          !this.areSignersPhonesUnique
        ) {
          return;
        }
        this.SET_LOADING(true);
        await this.updateSignersMissingDetails();
        this.SET_LOADING(false);
        if (this.success) {
          this.trackAndRedirectToSuccess();
          this.logOut();
        }
      },
      trackClientUI() {
        if (!this.isUserUnauthorised && !this.isAllDataProvided) {
          if (this.isTermLoanProduct) {
            this.trackEvent(TRACK_EVENTS.AMAZON_FIRMENKREDIT_CLIENT_UI);
          } else {
            this.trackEvent(TRACK_EVENTS.AMAZON_FLEXKREDIT_CLIENT_UI);
          }
        }
      },
      trackAndRedirectToSuccess() {
        if (this.isTermLoanProduct) {
          this.trackEvent(TRACK_EVENTS.AMAZON_FIRMENKREDIT_CLIENT_UI_SUCCESS);
        } else {
          this.trackEvent(TRACK_EVENTS.AMAZON_FLEXKREDIT_CLIENT_UI_SUCCESS);
        }
        this.$router.push({ name: 'ing-signers-missing-info-success' });
      },
    },
  };
</script>

<style scoped lang="scss">
  .centered-container {
    flex-grow: 1;
    margin-top: 53px;
    text-align: center;
  }
  .centered-child {
    margin: 0 auto;
  }
  .last-container {
    margin: 20px auto 80px;
    max-width: 726px;
  }
  section {
    margin-top: 60px;
  }
  .no-section-margin {
    margin-top: 0;
  }
  .half-margin-section {
    margin-top: 30px;
  }
</style>
