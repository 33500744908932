import forOwn from 'lodash/forOwn';

export default function StateValidator(validators, state) {
  let errors = {};
  forOwn(state, (item, key) => {
    let validResponse = {};
    validResponse[key] = undefined;
    Object.assign(
      errors,
      validators[key] && typeof validators[key] === 'function'
        ? validators[key](item, state)
        : validResponse
    );
  });

  return errors;
}
