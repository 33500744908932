import {
  isEmpty,
  matchesCityOfBirthPattern,
  hasAtLeastTwoAlphabeticChars,
} from '../helpers/string';
import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';

export default function ValidateCityOfBirth(cityOfBirth) {
  if (isEmpty(cityOfBirth)) {
    return { cityOfBirth: 'CityOfBirthIsRequired' };
  }

  if (!matchesCityOfBirthPattern(cityOfBirth)) {
    return { cityOfBirth: 'CityOfBirthIsNotAlphabetic' };
  }

  if (!hasAtLeastTwoAlphabeticChars(cityOfBirth)) {
    return { cityOfBirth: 'CityOfBirthIsNotAlphabetic' };
  }

  if (hasForbiddenCharacters(cityOfBirth)) {
    return { cityOfBirth: 'ForbiddenCharacters' };
  }

  return { cityOfBirth: undefined };
}
