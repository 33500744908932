<template>
  <div ref="container" class="form-group addMaterialDesign">
    <label :for="'lastNameAtBirth-' + signerId" class="lastNameAtBirth-label">
      {{ $t('forms.signupForm.lastNameAtBirth.label') }}
    </label>
    <div class="radio-input-container">
      <len-radio
        v-model="isLastNameAtBirthSame"
        :label="$t('forms.signupForm.lastNameAtBirth.dropdown.yes')"
        :name="'lastNameAtBirth-' + signerId"
        class="last-name-birth-radio"
        value="true"
      />
      <len-radio
        v-model="isLastNameAtBirthSame"
        :label="$t('forms.signupForm.lastNameAtBirth.dropdown.no')"
        :name="'lastNameAtBirth-' + signerId"
        class="last-name-birth-radio"
        value="false"
      />
      <input
        v-model="lastNameAtBirth"
        :class="{
          hidden: !isLastNameAtBirthSame || isLastNameAtBirthSame === 'true',
        }"
        :placeholder="$t('forms.signupForm.lastNameAtBirth.placeholder')"
        type="text"
        class="form-control"
      />
    </div>
    <div
      role="alert"
      class="text-block text-align--right display--block error-container"
    >
      <p v-if="displayError" class="validation">
        {{
          errors[signerId] &&
          errors[signerId].lastNameAtBirth &&
          $t('forms.errors.' + errors[signerId].lastNameAtBirth)
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  export default {
    components: { LenRadio },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isLastNameAtBirthSame: null,
      };
    },
    computed: {
      displayError() {
        return (
          (this.submitClicked || this.signers[this.signerId].submitClicked) &&
          !!this.errors[this.signerId] &&
          !!this.errors[this.signerId].lastNameAtBirth
        );
      },
      lastNameAtBirth: {
        get() {
          return this.signers[this.signerId].lastNameAtBirth;
        },
        set(value) {
          this.SET_LAST_NAME_AT_BIRTH({
            signerId: this.signerId,
            lastNameAtBirth: value,
          });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    watch: {
      isLastNameAtBirthSame(newValue) {
        if (newValue === 'true') {
          this.lastNameAtBirth = '';
        }
      },
    },
    mounted() {
      if (this.lastNameAtBirth) {
        this.isLastNameAtBirthSame = 'false';
      }
    },
    methods: {
      ...mapMutations('signers', ['SET_LAST_NAME_AT_BIRTH']),
    },
  };
</script>

<style scoped lang="scss">
  .form-group {
    max-height: 89px;
  }
  .radio-input-container {
    display: flex;
  }
  :deep(.last-name-birth-radio) {
    margin-right: 20px;
    padding-top: 7px;
    text-align: center;
  }
  .error-container {
    height: 25px;
  }
  .validation {
    margin-bottom: 0px;
  }
  .hidden {
    visibility: hidden;
  }
</style>
