import { isValidNationality } from '../helpers/nationality';
import { isEmpty } from '../helpers/string';

export default function ValidateSecondNationality(iso) {
  if (isEmpty(iso)) {
    return { secondNationality: undefined };
  }
  if (!isValidNationality(iso)) {
    return { secondNationality: 'SecondNationalityIsNotCorrect' };
  }
  return { secondNationality: undefined };
}
