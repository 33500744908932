import numeral from 'numeral';

if (numeral.locales['de'] === undefined) {
  numeral.register('locale', 'de', {
    delimiters: {
      thousands: '.',
      decimal: ',',
    },
    abbreviations: {
      thousand: 'k',
      million: 'm',
      billion: 'b',
      trillion: 't',
    },
    ordinal: function () {
      return '.';
    },
    currency: {
      symbol: 'EUR',
    },
  });
}

export default numeral;
