export default function ValidateEmployeesNumber(employeesNumber) {
  if (!employeesNumber) {
    return { employeesNumber: 'EmployeesNumberIsRequired' };
  }
  if (isNaN(employeesNumber)) {
    return { employeesNumber: 'EmployeesNumberIsNotCorrect' };
  }
  if (employeesNumber < 1 || employeesNumber > 999) {
    return { employeesNumber: 'EmployeesNumberIsNotCorrect' };
  }
  return { employeesNumber: undefined };
}
