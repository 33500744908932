import axios from 'axios';
import { momentjs } from '@/filters/momentjs';
import findKey from 'lodash/findKey';

import { getFormattedNumber } from '@/validators/helpers/phone';
import LocalStorage from '@/common/localStorage';
import {
  stringToFloatWithTwoDecimals,
  monthsToDays,
  removeUrlProtocol,
} from '@/utils/stringUtilities';

import { LOCAL_STORAGE_KEYS } from '@/constants/LOCAL_STORAGE_KEYS';

export async function getFWCompanies({ state, commit }) {
  if (state.errors.company) {
    return;
  }
  try {
    commit('SET_COMPANIES', null);
    let response = await axios.get(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/companies`,
      { params: { name: state.company }, timeout: 60 * 1000 }
    );
    if (!response.data || !findKey(response.data)) {
      throw new Error('No Companies available');
    }

    commit('SET_COMPANIES', response.data);

    return response.data;
  } catch (error) {
    console.error(error.message);
  }
}

export async function getCompanyLegalForm({ commit }, mappingId) {
  if (!mappingId) {
    return;
  }

  let { data: response, status } = await axios.get(
    `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/companies/${mappingId}/legal-form`
  );
  if (!response || !findKey(response)) {
    throw new Error('No Legal Form available', status);
  }

  commit('SET_LEGAL_STATUS', response.legalForm);
}

export async function signUp({
  state,
  getters,
  commit,
  rootState,
  rootGetters,
}) {
  if (!getters['isValid']) {
    return;
  }

  commit('SET_LOADING', true);
  try {
    let signUpRequest = {
      gaFullReferrer: removeUrlProtocol(document.referrer),
      mappingNumber: state.mappingId,
      legalStatus: state.legalStatus,
      dateFounded: momentjs(state.dateFounded, 'DD.MM.YYYY').format(
        'YYYY-MM-DD'
      ),
      street: state.street,
      city: state.city,
      postcode: state.postcodeDe,
      dob: momentjs(state.dob, 'DD.MM.YYYY').format('YYYY-MM-DD'),
      userStreet: state.userStreet,
      userCity: state.userCity,
      userPostcode: state.userPostcodeDe,
      loanPurpose: state.loanPurposeBorrower,
      bookkeepingStandard: state.bookkeepingStandard,
      partnerAccountId: rootState.partner.partnerAccountId,
      partnerContactId: rootState.partner.partnerContactId,
      partnerAccepted: rootState.partner.partnerAccepted,
      partnerCompanyName: rootState.partner.partnerCompanyName,
      partnerFirstName: rootState.partner.partnerFirstName,
      partnerLastName: rootState.partner.partnerLastName,
      partnerEmail: rootState.partner.partnerEmail,
      title: state.title,
      salutation: state.salutation,
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      nationality: state.nationality,
      mobilePhone: getFormattedNumber(state.mobilePhoneBorrower),
      companyName: state.company,
      revenueThreshold: {
        before50K: 'less than 50000',
        after50K: 'more than 50000',
      }[state.revenue],
      marketingAgreementAccepted: state.marketingAgreement,
      privacyPolicyAccepted: state.privacyPolicy,
      termsConditionsAccepted: state.termsAndConditions,
      gclid: LocalStorage.getItem(LOCAL_STORAGE_KEYS.GCLID) || undefined,
      loanAmount: stringToFloatWithTwoDecimals(state.loanAmount),
      loanDurationDays: monthsToDays(state.length).toString(),
      firstPayout: stringToFloatWithTwoDecimals(state.disbursementAmount),
      medium: state.medium,
      source: state.source,
      campaignName: state.campaignName,
      product: state.product,
      term: state.term,
      content: state.content,
      contentInfo: state.contentInfo,
      keywordInfo: state.keywordInfo,
      mediaCodeInfo: state.mediaCodeInfo,
      gcid: state.gcid,
      offerId: rootState.blacksea.offer
        ? rootState.blacksea.offer.offerId
        : null,
    };

    if (
      process.env.VUE_APP_IS_BLACKSEA === 'true' &&
      process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true' &&
      rootGetters['appSettings/isAmazonApplication']
    ) {
      signUpRequest.iban = state.iban;
      signUpRequest.bic = state.bic;
    }
    let response = await axios.post(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/borrower/lead`,
      signUpRequest
    );
    if (response.data && response.data.status) {
      const { status, prospectId } = response.data;
      commit('SET_APPLICATION_STATUS', status[0]);
      commit('SET_PROSPECT_ID', prospectId);
    }

    if (!response.data || !findKey(response.data)) {
      throw new Error('No Company available', response.status);
    }

    // Send tracking event
    sendTrackingEvent(this, signUpRequest, rootGetters);

    let smeSignUpToThankYouPage = {
      mappingId: state.mappingId,
      revenue: state.revenue,
      partnerCompanyName: rootState.partner.partnerCompanyName,
      partnerFirstName: rootState.partner.partnerFirstName,
      partnerLastName: rootState.partner.partnerLastName,
      partnerEmail: rootState.partner.partnerEmail,
      salutation: signUpRequest.salutation,
      firstName: signUpRequest.firstName,
      lastName: signUpRequest.lastName,
      dob: signUpRequest.dob,
      email: signUpRequest.email,
      userCountry: signUpRequest.nationality,
      mobilePhone: signUpRequest.mobilePhone,
      userCity: signUpRequest.userCity,
      userPostcode: signUpRequest.userPostcode,
      userStreet: signUpRequest.userStreet,
      loanAmount: signUpRequest.loanAmount,
      length: (signUpRequest.loanDurationDays / 365).toFixed(0),
      companyName: signUpRequest.companyName,
      dateFounded: signUpRequest.dateFounded,
      postcode: signUpRequest.postcode,
      city: signUpRequest.city,
      legalForm: signUpRequest.legalStatus,
      loanPurpose: signUpRequest.loanPurpose,
      billingMethod: signUpRequest.bookkeepingStandard,
      street: state.street,
      iban: state.iban,
      firstPayout: signUpRequest.firstPayout,
      bic: state.bic,
      productCode: rootState.blacksea.offer
        ? rootState.blacksea.offer.productCode
        : null,
    };

    LocalStorage.setItem(
      LOCAL_STORAGE_KEYS.SME_SIGNUP,
      smeSignUpToThankYouPage
    );
  } catch (e) {
    if (e.response) {
      commit('SET_ERROR', e.response.data);
    }
    throw e;
  } finally {
    commit('SET_LOADING', false);
  }
}

function sendTrackingEvent(_this, signUpRequest, rootGetters) {
  const isBlacksea = rootGetters['appSettings/isAmazonApplication'];
  const eventAction = signUpRequest.partnerAccountId
    ? 'partner-loan'
    : 'customer-loan';
  const applicationLocation = isBlacksea ? 'BlackSea' : 'Lendico';
  const legalForm = `${signUpRequest.legalStatus}-${signUpRequest.bookkeepingStandard}`;
  const loanDurationInMonth = (
    parseInt(signUpRequest.loanDurationDays) / 30
  ).toString();
  const vueModel = _this._vm;

  if (vueModel && vueModel.$gtm) {
    vueModel.$gtm.trackEvent({
      event: 'GTMevent',
      eventCategory: 'application-form',
      eventAction: eventAction,
      eventLabel: `${applicationLocation} | ${legalForm}`,
      loanAmount: signUpRequest.loanAmount,
      loanLength: loanDurationInMonth,
      applicationLocation: applicationLocation,
      legalForm: legalForm,
    });
  }
}

export function setAnalyticsData({ commit }, payload) {
  commit('SET_ANALYTICS_DATA', payload);
}

export function setUTMParams({ commit }, payload) {
  commit('SET_UTM_PARAMS', payload);
}

export async function clear({ commit }) {
  commit('CLEAR');
}
