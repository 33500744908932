import $ from 'jquery/dist/jquery.slim.min';
import Window from '@/common/window';
import debounce from 'lodash/debounce';

class Tabs {
  constructor(element) {
    this.element = element;
    this.windowResize = debounce(this.resetIndicatorPosition, 500);
  }

  bindEvents() {
    this.tabs = this.element.find('.tab');
    // Set listeners for mobile navigation buttons clicks
    this.element.find('.mobile-nav .next').on('click', () => {
      this.element.find('.tab').eq(this.activeTabId).next().click();
    });
    this.element.find('.mobile-nav .prev').on('click', () => {
      this.element.find('.tab').eq(this.activeTabId).prev().click();
    });

    // Set listeners for desctop navigation buttons clicks
    this.element.on('click', (event) => {
      this.mainClick(event);
    });
    this.tabs.on('click', (event) => {
      this.tabClick(event);
    });

    // Move Indicator to hovered item
    this.tabs.on('mouseenter', (event) => {
      this.tabMouseEnter(event);
    });
    this.tabs.on('mouseleave', (event) => {
      this.tabMouseLeave(event);
    });

    // Reset Position on Screen Resize
    $(Window).on('resize', this.windowResize.bind(this));
    $(Window).on('keydown', this.windowKeyDown.bind(this));

    // Open submenu
    this.element.find('.click-dropdown').on('click', () => {
      if (window.innerWidth < 769) {
        this.element.find('nav').toggleClass('open');
      }
    });
    this.element.find('.submenu-toggler').on('click', () => {
      this.element.find('nav').toggleClass('open');
    });
  }

  initialise() {
    this.tabClick();
  }

  setActiveTabId(activeTabId) {
    this.activeTabId = activeTabId;
  }

  unbindEvents() {
    $(Window).off('resize', this.windowResize);
    $(Window).off('keydown', this.windowKeyDown);
    this.element.find('.tab').off('click').off('mouseenter').off('mouseleave');
    this.element.find('.mobile-nav .next').off('click');
    this.element.find('.mobile-nav .prev').off('click');
    this.element.find('.click-dropdown').off('click');
    this.element.find('.submenu-toggler').off('click');
  }

  moveIndicator(pos) {
    this.element.find('.moving-indicator').css({
      width: pos.width,
      left: pos.left + 'px',
    });
  }

  moveMobileIndicator() {
    let length = this.element.find('.tab').length;
    let activeTab = this.element.find('.tab.active').index();
    let width = this.element.width() - 40;

    this.element.find('.mobile-moving-indicator').css({
      width: width / length + 'px',
      left: activeTab * (width / length) + 'px',
      marginLeft: '20px',
    });
  }

  resetIndicatorPosition() {
    let activeTab = this.element.find('.tab.active');
    let pos;
    if (activeTab.length) {
      let tabWidth = activeTab.width();
      let linkWidth = activeTab.find('a').width();
      let left = activeTab.position().left + (tabWidth - linkWidth) / 2;
      pos = {
        left: left,
        width: linkWidth,
      };
    } else {
      pos = {
        left: this.element.find('nav').width() / 2,
        width: 0,
      };
    }

    this.moveMobileIndicator();
    this.moveIndicator(pos);
  }

  tabMouseEnter(event) {
    let currentTab = $(event.currentTarget);
    if (!currentTab.hasClass('tab')) {
      currentTab = currentTab.closest('.tab');
    }
    if (this.getIsMobile()) {
      return;
    }
    if (currentTab.hasClass('inactive') || currentTab.hasClass('dropdown')) {
      currentTab.removeClass('closed');
      return;
    }
    let tabWidth = currentTab.width();
    let linkWidth = currentTab.find('a').width();
    let left = currentTab.position().left + (tabWidth - linkWidth) / 2;
    let pos = {
      left: left,
      width: linkWidth,
    };

    this.moveIndicator(pos);
  }

  tabMouseLeave() {
    if (!this.getIsMobile()) {
      this.resetIndicatorPosition();
    }
  }

  mainClick(event) {
    let currentTab = $(event.currentTarget);
    if (currentTab.hasClass('inactive')) {
      return;
    }

    if (this.element.hasClass('submenu')) {
      currentTab.addClass('closed');
      setTimeout(() => {
        currentTab.removeClass('closed');
      }, 300);
    }

    if (currentTab.hasClass('active')) {
      this.element.find('nav').toggleClass('open');
      return;
    }

    this.resetIndicatorPosition();
    this.moveMobileIndicator();
  }

  tabClick(event) {
    let clickedTab;
    if (!event && this.activeTabId !== undefined) {
      clickedTab = this.element.find('.tab').eq(this.activeTabId);
    } else {
      clickedTab = $(event.currentTarget);
      if (
        this.activeTabId !== undefined &&
        this.activeTabId === clickedTab.index()
      ) {
        return;
      }
    }

    this.tabs.removeClass('active');
    clickedTab.addClass('active');
    this.activeTabId = clickedTab.index();
    this.resetIndicatorPosition(this.activeTabId);
    this.element.find('.tab-content').removeClass('active');
    this.element.find('.tab-content').eq(this.activeTabId).addClass('active');
  }

  getIsMobile() {
    return $(Window).width() < 768;
  }

  windowKeyDown(event) {
    if (
      !this.element.hasClass('has-content') &&
      !this.element.find('nav:in-viewport').length
    ) {
      return;
    }

    let activeTab = this.element
      .find('nav:in-viewport')
      .first()
      .find('.tab.active');
    if (event.which === 39) {
      // next pressed
      activeTab.next().click();
    } else if (event.which === 37) {
      // prev pressed
      activeTab.prev().click();
    }
  }
}

export const lenTabsBehaviour = {
  inserted: function (el, binding) {
    let activeTabId = 0;
    if ($(el).find('.tab.active').index() !== -1) {
      activeTabId = $(el).find('.tab.active').index();
    }
    activeTabId =
      binding.value && binding.value.activeTabId
        ? parseInt(binding.value.activeTabId)
        : activeTabId;

    el.__lenTabsBehaviour = new Tabs($(el));
    setTimeout(() => {
      el.__lenTabsBehaviour.bindEvents();
      el.__lenTabsBehaviour.setActiveTabId(activeTabId);
      el.__lenTabsBehaviour.initialise();
    }, 300);
  },
  update(el) {
    setTimeout(() => {
      if (!el.__lenTabsBehaviour) {
        throw new Error('Tabs were not initialised');
      }
      el.__lenTabsBehaviour.initialise();
    }, 300);
  },
  unbind(el) {
    if (!el.__lenTabsBehaviour) {
      throw new Error('Tabs were not initialised');
    }
    el.__lenTabsBehaviour.unbindEvents();
  },
};

export default function install(Vue) {
  Vue.directive('len-tabs-behaviour', lenTabsBehaviour);
}
