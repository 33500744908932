<template>
  <div>
    <div class="controlling-company-overview-container">
      <div class="col-lg-7 col-sm-10">
        <h3
          v-if="isFeatureFlagMLLFEnabled && isComplexLegalForm"
          data-unit-test="ing-mllf-controlling-company-title"
          class="controllingCompanyTitle"
        >
          {{
            $t(
              'signersMissingInfo.formPage.companySection.MLLFControllingCompany.h3'
            )
          }}
          <ing-bs-tooltip
            key="mllf-controlling-company-title-tooltip"
            :tooltipMessage="
              $t(
                'signersMissingInfo.formPage.companySection.MLLFControllingCompany.tooltip'
              )
            "
            messageClass="controlling-tooltip-message"
          />
        </h3>
        <h3
          v-else
          class="controllingCompanyTitle"
          data-unit-test="ing-controlling-company-title"
        >
          {{
            $t(
              'signersMissingInfo.formPage.companySection.controllingCompany.h3'
            )
          }}
          <ing-bs-tooltip
            key="controlling-company-title-tooltip"
            :tooltipMessage="
              $t(
                'signersMissingInfo.formPage.companySection.controllingCompany.tooltip'
              )
            "
            messageClass="controlling-tooltip-message"
          />
        </h3>
        <div data-unit-test="ing-controlling-legal-name-overview" class="row">
          <p class="field-label col-sm-4">
            <em>{{ $t('forms.signupForm.companyName.label') }}:</em>
            <ing-bs-tooltip
              key="company-name-tooltip"
              :tooltipMessage="$t('forms.signupForm.companyName.tooltip')"
            />
          </p>
          <p class="field-value col-sm-8">
            {{ controllingCompany.legalName | emptyValueReplacer }}
          </p>
        </div>
        <div data-unit-test="ing-controlling-tax-number-overview" class="row">
          <p class="field-label col-sm-4">
            <em
              >{{
                $t('forms.signupForm.controllingCompanyTaxNumber.label')
              }}:</em
            >
            <ing-bs-tooltip
              key="tax-number-tooltip"
              :tooltipMessage="
                $t('forms.signupForm.controllingCompanyTaxNumber.tooltip')
              "
            />
          </p>
          <p class="field-value col-sm-8">
            {{
              controllingCompany.controllingCompanyTaxNumber
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div
          data-unit-test="ing-controlling-country-of-incorporation-overview"
          class="row"
        >
          <p class="field-label col-sm-4">
            <em>{{ $t('forms.signupForm.countryOfIncorporation.label') }}:</em>
          </p>
          <p class="field-value col-sm-8">
            {{
              controllingCompany.countryOfIncorporation
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  import { isFeatureFlagMLLFEnabled } from '@/mixins/signersMixin';
  export default {
    components: {
      IngBsTooltip,
    },
    mixins: [isFeatureFlagMLLFEnabled],
    computed: {
      ...mapState('signers', ['controllingCompany']),
      ...mapGetters('signers', ['isComplexLegalForm']),
    },
  };
</script>

<style scoped>
  .controlling-company-overview-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
  }
  .ing-controlling-title-alignment {
    display: flex;
    align-items: baseline;
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
</style>
