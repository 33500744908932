<template>
  <svg
    :class="{ up: up }"
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="triangle-down"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.4732 7.39794C6.75208 7.71667 7.24792 7.71667 7.5268 7.39794L12.9842 1.16095C13.3802 0.708344 13.0588 0 12.4574 0H1.54264C0.941227 0 0.619802 0.708345 1.01583 1.16095L6.4732 7.39794Z"
      fill="#333333"
    />
  </svg>
</template>

<script>
  export default {
    props: {
      up: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped lang="scss">
  .triangle-down {
    transform: rotate(0deg);
    transition: transform 0.2s ease;

    &.up {
      transform: rotate(180deg);
      transition: transform 0.2s ease;
    }
  }
</style>
