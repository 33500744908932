export default {
  amazon: {
    meta: {
      termLoan: {
        title: 'Firmenkredit anfragen',
        description:
          'Fragen Sie hier direkt unseren Firmenkredit bis 750.000 Euro für kleine und mittlere Unternehmen an. Das klappt vollständig digital und geht ganz schnell.',
      },
      wcl: {
        title: 'Kredit beantragen',
        description:
          'Ein schneller und einfacher Kredit: Kreditentscheidung in 48 Stunden, ohne Businessplan. Auszahlung in einer Woche. Kostenlose vorzeitige Rückzahlung | Keine Besicherung | Feste monatliche Zahlung | Laufzeit: 1 bis 5 Jahre | € 10.000 bis € 450.000 | Zins ab 3%. Beantragen Sie unverbindlich einen Kredit!',
      },
    },
    application: {
      termLoan: {
        header: {
          title: 'Firmenkredit anfragen',
          description:
            'Stellen Sie in vier einfachen Schritten eine unverbindliche Anfrage für Ihren Firmenkredit. Das dauert nur wenige Minuten und funktioniert komplett online.',
        },
      },
      wcl: {
        header: {
          title: 'Flexkredit anfragen',
          description:
            'Stellen Sie in vier einfachen Schritten eine unverbindliche Anfrage für Ihren Flexkredit. Das dauert nur wenige Minuten und funktioniert komplett online.',
        },
      },
      form: {
        creditSection: {
          termLoan: {
            title: '1. Kreditwunsch',
            para1:
              'Der vorausgefüllte Betrag entspricht der maximalen Kreditsumme, die Sie anfragen können. Sie können den Betrag auch reduzieren, der Mindestbetrag für die Kreditanfrage ist jedoch 10.000 Euro.',
          },
          wcl: {
            title: '1. Kreditwunsch',
            para1:
              'Bis zum Kreditrahmen können Sie sich flexibel Geld auszahlen lassen. Aus- und Einzahlungen sind jederzeit möglich, die Laufzeit ist unbegrenzt. Den ersten Auszahlungsbetrag erhalten Sie direkt nach erfolgreicher Kreditanfrage - Sie können den Betrag später im Prozess noch ändern. ',
          },
        },
        companySection: {
          title: '2. Firmeninformationen',
          para1:
            'Wir brauchen noch ein paar Informationen zu Ihrem Unternehmen.',
          para2:
            'Wenn Sie Ihren Firmennamen eingeben und aus der Liste auswählen, wird das Anfrageformular automatisch mit Ihren Firmendaten vervollständigt. So sparen Sie noch mehr Zeit.',
          fields: {
            purpose: {
              label: 'Verwendungszweck',
              placeHolder: '- Bitte auswählen -',
            },
          },
          subSection: {
            title: 'Kontodaten',
            para1:
              'Bitte verwenden Sie dieselbe IBAN und BIC, die in Ihrem Verkäuferkonto in Seller Central hinterlegt sind.',
          },
        },
        representativeSection: {
          title: '3. Vertretungs{shyCharacter}berechtigte/-r',
          para1:
            'Um eine Kreditanfrage zu stellen, müssen Sie Vertretungsberechtigte/-r oder Inhaber/-in der Firma sein.',
          para2:
            'Als Vertretungsberechtigte werden derzeit <em>nur Geschäftsführer/-innen oder Vorstandsmitglieder</em> akzeptiert. Eine Anfrage durch Prokurist/-innen und sonstige Bevollmächtigte ist leider nicht möglich.',
          fields: {
            emailInfo:
              'Keine Sorge, wir nutzen Ihre E-Mail-Adresse, um Ihnen wichtige Unterlagen zu Ihrem Produkt zu senden und Sie über unsere Services zu informieren. Dem können Sie jederzeit per E-Mail an <a href="mailto: info@ing-diba.de">info@ing-diba.de</a> widersprechen.',
          },
        },
        termsSection: {
          title: '4. Kreditanfrage abschicken',
          para1: {
            description:
              'Mit dem Klicken auf „{boldText}” bestätige ich,  dass ich sowie alle (weiteren) im Handelsregister eingetragenen Geschäftsführer/-innen und Gesellschafter/-innen und wirtschaftlich Berechtigte im Sinne des § 3 Abs. 1 GwG - Geldwäschegesetz die {linkText} zur Kenntnis genommen haben.',
            linkText: 'Datenschutzerklärung',
            termLoan: {
              boldText: 'Firmenkredit anfragen',
            },
            wcl: {
              boldText: 'Flexkredit anfragen',
            },
          },
          para2: {
            description:
              'Damit die ING meine Finanzierungsanfrage prüfen kann, werden mit dem Klicken auf „{boldText}“ automatisch folgende Seller-Daten von Amazon übertragen: Laufzeit der Seller-Aktivitäten bei Amazon, Verkäuferstatus (aktiv/inaktiv), aktueller Bestandswert (FBA – „Fulfillment by Amazon“), Hauptproduktkategorie (Produktkategorie mit dem höchsten Anteil der Verkäufe für die letzten drei Monate), Verkaufszahlen nach Woche, Monat und Quartal, ein Wert zur Umsatzstabilität sowie eine eindeutige Nutzerkennung für die Zuordnung. Die ING wird außerdem die Eckdaten (Art, Laufzeit, Betrag, Gebundener Sollzinssatz) und den Status meiner Kreditanfrage und meines Kredits an meinen Seller Central Account übertragen und aktualisieren, damit ich jederzeit den aktuellen Status einsehen kann und Amazon die für die Kreditvermittlung erforderlichen Daten erhält.',
            termLoan: {
              boldText: 'Firmenkredit anfragen',
            },
            wcl: {
              boldText: 'Flexkredit anfragen',
            },
          },
          para3: {
            description:
              'Die ING übermittelt unternehmens- und personenbezogene Daten an {linkText1} und die {linkText2} bzw. fragt Daten von den genannten Stellen ab. Dies ist zur Beurteilung der Bonität erforderlich.',
            linkText1: 'Creditreform',
            linkText2: 'SCHUFA',
          },
          submitButton: {
            label: 'Flexkredit anfragen',
          },
        },
      },
    },
    success: {
      header: {
        title: 'Vielen Dank für Ihre Anfrage',
        description:
          'Wir bestätigen Ihre Finanzierungsanfrage noch einmal in einer separaten E-Mail und informieren Sie über die nächsten Schritte.',
      },
      details: {
        title: 'Ihre Daten auf einen Blick:',
      },
      nextSteps: {
        title: 'Und so geht’s weiter',
        para1:
          'Schicken Sie uns bitte folgende Unterlagen per E-Mail an <a href="mailto:business@ing.de">business@ing.de</a>:',
        list1:
          '<strong>Jahresabschlüsse</strong> der letzten zwei Geschäftsjahre ({currentYearMinusTwo} und {currentYearMinusOne}). Sofern für {currentYearMinusOne} keine finalen Dokumente vorliegen, senden Sie uns bitte Ihre <strong>Betriebswirtschaftliche Auswertung</strong> mit Summen- und Saldenliste.',
        list2:
          '<strong>Betriebswirtschaftliche Auswertung</strong> mit Summen- und Saldenliste von <strong>{currentYear}</strong>, nicht älter als drei Monate.',
        list3:
          '<strong>Kontoauszüge</strong> der letzten drei Monate, nicht älter als 14 Tage.',
        para2:
          'Sobald Ihre Unterlagen vollständig sind, senden wir Ihnen <strong>innerhalb von 48 Stunden</strong> einen kostenfreien und unverbindlichen Finanzierungsvorschlag.',
        para3:
          'Wenn Sie diesen annehmen, identifizieren Sie sich noch kurz und unterschreiben den Kreditvertrag.',
        para4:
          'Dann wird das Geld <strong>direkt auf Ihr Konto</strong> überwiesen.',
        dataSection: [
          {
            title: 'Kreditdetails',
            dataList: {
              amount: 'Kreditbetrag:',
              years: 'Laufzeit:',
              duration: '{n} Jahr | {n} Jahre',
              purpose: 'Verwendungszweck:',
            },
          },
          {
            title: 'Firmeninformationen',
            dataList: {
              companyName: 'Firmenname:',
              address: 'Adresse:',
              foundedDate: 'Gründungsdatum:',
              revenue: 'Umsatz des letzten Jahres:',
              legalForm: 'Rechtsform:',
              billingMethod: 'Bilanzierungsmethode:',
            },
          },
          {
            title: 'Vertretungsberechtigte/-r',
            dataList: {
              gender: 'Anrede:',
              firstName: 'Vorname:',
              lastName: 'Nachname:',
              address: 'Adresse:',
              dateOfBirth: 'Geburtsdatum:',
              nationality: 'Staatsangehörigkeit:',
              mobile: 'Mobilfunknummer:',
              email: 'E-Mail-Adresse:',
            },
          },
        ],
      },
    },
  },
};
