<template>
  <div class="tax-number-wrapper">
    <ing-text-input-tooltip
      v-model="controllingCompanyTaxNumber"
      :label="$t('forms.signupForm.controllingCompanyTaxNumber.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
      name="controlling-tax-number"
      :tooltipMessage="
        $t('forms.signupForm.controllingCompanyTaxNumber.tooltip')
      "
      messageClass="tax-number-tooltip-message"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';
  export default {
    components: { IngTextInputTooltip },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'controllingCompany',
      ]),
      controllingCompanyTaxNumber: {
        get() {
          return this.controllingCompany.controllingCompanyTaxNumber;
        },
        set(newValue) {
          this.SET_CONTROLLING_COMPANY_TAX_NUMBER(newValue);
        },
      },
      error() {
        const { controllingCompany } = this.errors;
        if (
          controllingCompany &&
          controllingCompany.controllingCompanyTaxNumber
        ) {
          return this.$t(
            'forms.errors.' + controllingCompany.controllingCompanyTaxNumber
          );
        }
        return '';
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_CONTROLLING_COMPANY_TAX_NUMBER']),
    },
  };
</script>

<style lang="scss" scoped>
  .tax-number-wrapper {
    position: relative;
  }
  .tax-number-tooltip {
    position: absolute;
    left: 90px;
    top: 5px;
    &.on-input-focus {
      top: -12px;
    }
  }
</style>
