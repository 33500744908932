<template>
  <div>
    <div class="error-wrapper">
      <FieldStatus
        v-if="errorMessage || partnerNotAcceptedError"
        :error="errorMessage"
        class="general-error-container"
      >
        <template v-if="partnerNotAcceptedError" #error>
          <i18n :path="'partner.application.form.notAcceptedError'" tag="div">
            <a
              :href="`mailto:${$translationConstantValues.theContact.partner.contact.email}`"
              place="linkTo"
              >{{
                $translationConstantValues.theContact.partner.contact.email
              }}</a
            >
          </i18n>
        </template>
      </FieldStatus>
    </div>
    <IngButton
      id="ingpartnerSubmitButton"
      type="button"
      data-qa-id="submit"
      @click="handleSubmit()"
    >
      {{ $t('formFields.loginButton.label') }}
    </IngButton>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
  import lodashIsEmpty from 'lodash/isEmpty';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  export default {
    name: 'PartnerSubmitButton',
    components: { IngButton, FieldStatus },
    computed: {
      ...mapGetters('partner', ['isValid']),
      ...mapState('partner', [
        'requestError',
        'submitClicked',
        'partnerAccepted',
      ]),
      errorMessage: function () {
        let errorStatus = this.requestError.status;
        if (errorStatus) {
          if (errorStatus === 400 || errorStatus === 404) {
            return this.$t('partner.login.form.fields.serverErrorMessage');
          } else if (errorStatus === 500) {
            return this.$t('forms.signupForm.errors.500');
          }
        }
        return '';
      },
      partnerNotAcceptedError: function () {
        return this.submitClicked && this.partnerAccepted === false;
      },
    },
    methods: {
      ...mapMutations('partner', [
        'SET_SUBMIT_CLICKED',
        'SET_REQUEST_ERROR',
        'SET_LOADING',
        'CLEAR_REQUEST_ERROR',
      ]),
      ...mapActions('partner', ['searchPartner']),
      async handleSubmit() {
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        try {
          await this.CLEAR_REQUEST_ERROR();
          await this.SET_LOADING(true);
          await this.searchPartner();
        } catch (error) {
          let errorBody;
          let errorStatus;
          if (error.response) {
            errorStatus = error.response.status;
            errorBody = error.response.data;
          } else {
            errorStatus = 500;
            errorBody = error.message || 'Something went wrong';
          }
          await this.SET_REQUEST_ERROR({
            status: errorStatus,
            body: errorBody,
          });
        } finally {
          await this.SET_LOADING(false);
          if (
            lodashIsEmpty(this.requestError) &&
            this.partnerAccepted === true
          ) {
            this.$emit('success');
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_settings.scss';
  @import '@/variables.scss';

  .error-wrapper {
    font-size: 16px;
    line-height: 24px;
    .general-error-container {
      margin-block: 0 40px;
    }
  }
  .button {
    margin-top: 50px;
  }
</style>
