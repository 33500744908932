<template>
  <len-date-input
    :label="label"
    :displayErrorIfDirty="!submitClicked"
    :error="errors.dateFounded && $t('forms.errors.' + errors.dateFounded)"
    :value="dateFounded"
    :minYear="1000"
    :maskFormat="maskFormat"
    name="Date_Founded__c"
    data-qa-id="company-info-founded-date"
    @accept="onAccept"
    @focus="handleFocus"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenDateInput from '@/components/common/len-date-input';
  import { removeDayFromDate } from '@/utils/stringUtilities';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenDateInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    props: {
      isPartner: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      label: function () {
        if (this.isPartner) {
          return this.$t('forms.signupForm.dateFounded.partnerLabel');
        }

        return this.$t('forms.signupForm.dateFounded.label');
      },
      maskFormat: function () {
        return this.isPartner ? 'TT.MM.JJJJ' : 'MM.JJJJ';
      },
      dateFounded: {
        get() {
          const dateFounded = this.$store.state.signupForm.dateFounded;

          if (this.isPartner) {
            return dateFounded;
          }

          // remove the day from the date as it has to be shown like MM.JJJJ
          return removeDayFromDate(dateFounded);
        },
        set(value) {
          if (this.isPartner) {
            this.SET_DATE_FOUNDED(value);
            return;
          }

          // Add the first day of the month as a fixed value according to ACQ-410 specs
          this.SET_DATE_FOUNDED(`01.${value}`);
        },
      },
      error() {
        return (
          this.errors.dateFounded &&
          this.$t('forms.errors.' + this.errors.dateFounded)
        );
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_DATE_FOUNDED']),
      onAccept(value) {
        this.dateFounded = value;
      },
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
