export default {
  signersMissingInfo: {
    metaTitle: 'Vorbereitung Identifikation',
    metaDescription: 'Vorbereitung Identifikation',
    formPage: {
      offerConditions: {
        title: {
          h2: 'Unverbindlicher Finanzierungsvorschlag',
          p: 'Wir freuen uns Ihnen eine Finanzierung zu folgenden Konditionen anbieten zu können:',
        },
        form: {
          regular: {
            loanAmount: 'Kreditbetrag',
            duration: 'Laufzeit',
            interestRate: 'Gebundener Sollzinssatz',
            monthlyRate: 'Monatliche Rate',
          },
          wcl: {
            loanAmount: 'Kreditrahmen',
            duration: 'Laufzeit',
            durationValue: 'unbefristet',
            interestRate: 'Gebundener Sollzinssatz p.a.',
            monthlyRate: 'Monatliche Rückzahlungsrate',
            monthlyRateValue: '8 % des ausstehenden Kreditbetrages',
            anyAmount: 'Beliebiger Betrag',
            fullDisbursement: ' 100 % des Kreditbetrages: {amount}',
            firstInstallment: {
              label: 'Erster Auszahlungsbetrag',
              description: {
                editMode:
                  'Einen Betrag bis zur Höhe Ihres Flexkredits können Sie sich direkt nach der Kreditzusage auf Ihr Referenzkonto auszahlen lassen.',
                viewMode:
                  'Einen Betrag bis zur Höhe Ihres Flexkredits können Sie sich direkt nach der Kreditzusage auf Ihr Referenzkonto überweisen lassen. Mit einem Klick auf „Bearbeiten“ können Sie den Betrag ändern.',
              },
            },
            explanatoryText: {
              first:
                '*Zinssatz ist für 12 Monate gebunden. Weitere Infos entnehmen Sie dem Vertragsangebot.',
              second:
                '**Die Berechnung geht davon aus, dass die Auszahlung zum ersten des Monats erfolgte und keine weiteren Beträge in Anspruch genommen wurden.',
            },
            interestFirstMonth: 'Zins im ersten Monat',
            repaymentPortion: 'Rückzahlungsanteil',
            repaymentPortionValue: '8 % auf abgerufenen Betrag',
            firstMonthRepaymentAmount: 'Rate im ersten Monat',
            calculation: {
              title: 'Berechnung der ersten Monatsrate',
              content: `Die monatliche Rate besteht aus dem Zins und einem Rückzahlungsanteil. Die Zinsen berechnen sich taggenau anhand des abgerufenen Kreditbetrags. Der Rückzahlungsanteil beträgt 8 % 
              des abgerufenen Betrags und wird zum Ende des Monats berechnet. Wenn Sie weitere Auszahlungen oder Rückzahlungen vornehmen, kann sich die Rate ändern.`,
            },
          },
        },
      },
      cancel: 'ABBRECHEN',
      save: 'Speichern',
      edit: 'BEARBEITEN',
      companySection: {
        title: {
          p: 'Überprüfen Sie die Angaben aus Ihrer Finanzierungsanfrage und klicken Sie auf <strong>„Bearbeiten”</strong>, um einzelne Felder zu korrigieren. Beantworten Sie bitte zusätzlich die unten stehende Frage.',
        },
        loanOwnerCompany: {
          h3: 'Kreditnehmer',
        },
        controllingCompany: {
          h3: 'Komplementär GmbH',
          tooltip: 'Die Komplementär GmbH ist die vollhaftende Gesellschaft.',
        },
        MLLFControllingCompany: {
          h3: 'Muttergesellschaft',
          tooltip: 'Die Muttergesellschaft ist die vollhaftende Gesellschaft.',
        },
      },
      clientsSection: {
        overview: {
          p: 'Klicken Sie auf <strong>„Bearbeiten"</strong>  und ergänzen Sie die leeren Felder.',
          missingUbos:
            'Fehlen Geschäftsführer(innen) und/oder wirtschaftlich Berechtigte mit direktem Kapital- oder Stimmrechtsanteil von über 25 Prozent? Dann schicken Sie uns eine E-Mail an ',
        },
        microLoanRequest: {
          title: {
            h2: 'Persönliche Daten zur Vertragserstellung',
            p: 'Wir sind gesetzlich dazu verpflichtet, Ihre persönlichen Daten zu verifizieren. Bitte überprüfen und vervollständigen Sie Ihre Daten.',
          },
          form: {
            p: 'Bitte füllen Sie alle Felder aus für',
          },
        },
        regularLoanRequest: {
          title: {
            h2: 'Ermittlung der wirtschaftlich Berechtigten',
            p: 'Wir sind gesetzlich dazu verpflichtet, die persönlichen Daten von Ihnen und allen mit dem Unternehmen verbundenen Personen zu verifizieren. In der Übersicht finden Sie alle uns bekannten wirtschaftlich Berechtigten. Bitte überprüfen und vervollständigen Sie die Angaben.',
          },
          form: {
            p: 'Bitte füllen Sie alle Felder aus für',
          },
        },
      },
      identificationTypeSection: {
        title: {
          h2: 'Identifizierungsverfahren auswählen',
          p1: 'Entscheiden Sie sich für eines der beiden Identifizierungsverfahren.',
          p2: 'Sie können Ihre Auswahl nachträglich ändern.',
        },
        form: {
          option1a: 'Online per Video-Ident',
          option1b:
            'Identifizierung mit einem gültigen Ausweisdokument – jederzeit von überall per Smartphone, Tablet, Laptop oder Computer.',
          option2a: 'In der Postfiliale mit POSTIDENT Coupon',
          option2b:
            'Identifizierung durch Vorlage des POSTIDENT Coupons in der Postfiliale.',
          option2warning:
            'Bitte schicken Sie uns zusätzlich eine Kopie Ihres Ausweisdokuments <strong>(Vorder- und Rückseite)</strong> an ',
        },
      },
      regularLoanRequestCheckbox:
        'Hiermit bestätige ich, dass es keine weiteren Geschäftsführer(innen) und wirtschaftliche Berechtigte gibt.',
      smeConfirmationCheckbox:
        'Hiermit bestätige ich, dass mein Unternehmen weniger als 250 Mitarbeiter beschäftigt sowie einen Jahresumsatz von weniger als 50 Mio.' +
        ' Euro und eine Jahresbilanzsumme von weniger als 43 Mio. Euro hat.',
      submit: 'EINREICHEN',
      privacyPolicy: {
        preHighlightedText: 'Mit dem Klicken auf ',
        highlightedText: '„Einreichen“',
        preLinkPageText:
          ' bestätige ich, dass ich die Datenschutzerklärungen der ',
        linkPageText: 'Lendico Deutschland GmbH',
        preLinkDocumentText: ' sowie der ',
        linkDocumentText: 'ING-DiBa AG',
        linkIng: 'https://ing.de/dokumente/datenschutz-geschaeftskunden',
        link: 'datenschutz',
        postLinkText:
          ' zur Kenntnis genommen habe. Ich versichere, dass alle oben genannte Personen über die Datenverarbeitung im Sinne der Datenschutzerklärung informiert sind.',
      },
    },
    successPage: {
      h1: 'Sie haben Ihre Daten erfolgreich übermittelt.',
      p: 'Innerhalb von 24 Stunden erhalten Sie einen Link zum Start des Video-Ident-Verfahrens oder die erforderlichen Postident-Dokumente.',
    },
  },
};
