<template>
  <fieldset
    v-if="isBookkeepingVisible"
    :class="{ error: displayError }"
    class="form-field addMaterialDesign fieldset-container"
  >
    <div class="form-group">
      <div class="radio__wrapper">
        <div class="radio__label">
          {{ label }}
          <div class="radio__tooltip">
            <a
              type="button"
              class="radio__tooltip__icon"
              @mouseover="showTooltip()"
              @mouseleave="hideTooltip()"
              ><img src="/tooltip_icon.svg" alt="?"
            /></a>
            <div
              :class="{
                radio__tooltip__message: mouseOver,
                'radio__tooltip__message--hidden': !mouseOver,
              }"
              @mouseover="showTooltip()"
              @mouseleave="hideTooltip()"
              v-html="$t('forms.signupForm.bookkeeping.tooltip')"
            />
          </div>
        </div>
        <div class="radio__container">
          <len-radio
            v-for="item in bookkeepingOptions"
            :key="item.id"
            v-model="bookkeepingStandard"
            :label="item.name"
            :value="item.name"
            name="loanPurpose__c"
            :hasError="displayError"
          />
        </div>
      </div>
    </div>
    <div
      role="alert"
      class="text-block text-align--left display--block error-container"
    >
      <p v-if="displayError" class="validation">
        {{
          errors.bookkeepingStandard &&
          $t('forms.errors.' + errors.bookkeepingStandard)
        }}
      </p>
    </div>
  </fieldset>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: {
      LenRadio,
    },
    mixins: [lenFormFieldGTMTrackingMixin],
    data() {
      return {
        bookkeepingOptions: [
          {
            id: this.$t('forms.signupForm.bookkeeping.dropdown.hgb'),
            name: this.$t('forms.signupForm.bookkeeping.dropdown.hgb'),
          },
          {
            id: this.$t(
              'forms.signupForm.bookkeeping.dropdown.gewinnUndVerlustrechnung'
            ),
            name: this.$t(
              'forms.signupForm.bookkeeping.dropdown.gewinnUndVerlustrechnung'
            ),
          },
          {
            id: this.$t('forms.signupForm.bookkeeping.dropdown.unbekannt'),
            name: this.$t('forms.signupForm.bookkeeping.dropdown.unbekannt'),
          },
        ],
        mouseOver: false,
        timeoutId: null,
        bilanzLegalStatus: [
          'GmbH',
          'GmbH & Co. KG',
          'AG',
          'KG',
          'OHG',
          'UG (haftungsbeschränkt)',
          'Limited',
        ],
      };
    },
    computed: {
      label() {
        return this.$t('forms.signupForm.bookkeeping.label.termLoan');
      },
      displayError() {
        return this.submitClicked && !!this.errors.bookkeepingStandard;
      },
      ...mapState('signupForm', ['errors', 'submitClicked', 'legalStatus']),
      ...mapState('signupForm', { bkStandard: 'bookkeepingStandard' }),
      ...mapGetters('signupForm', ['isBookkeepingVisible']),
      bookkeepingStandard: {
        get() {
          return this.bkStandard;
        },
        set(newValue) {
          this.SET_BOOKKEEPING_STANDARD(newValue);
          this.$emit('select');
          this.sendFieldTrackingEvent(`radio:${this.label}`, newValue);
        },
      },
    },
    watch: {
      legalStatus: function (newVal, oldVal) {
        this.SET_BOOKKEEPING_VISIBILITY();
        if (this.bilanzLegalStatus.includes(newVal)) {
          this.SET_BOOKKEEPING_STANDARD('Bilanz (HGB/Steuerrecht)');
        } else if (
          this.bilanzLegalStatus.includes(oldVal) &&
          !this.bilanzLegalStatus.includes(newVal)
        ) {
          this.SET_BOOKKEEPING_STANDARD(null);
        }
      },
    },
    beforeDestroy() {
      clearTimeout(this.timeoutId);
    },
    methods: {
      onSelect(standard) {
        this.SET_BOOKKEEPING_STANDARD(standard);
        this.$emit('select');
      },
      showTooltip() {
        this.mouseOver = true;
        clearTimeout(this.timeoutId);
      },
      hideTooltip() {
        this.timeoutId = setTimeout(() => (this.mouseOver = false), 500);
      },
      ...mapMutations('signupForm', [
        'SET_BOOKKEEPING_STANDARD',
        'SET_BOOKKEEPING_VISIBILITY',
      ]),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_settings.scss';
  @import '@/variables.scss';

  .fieldset-container {
    margin: 11px 0 30px 0;
  }
  .radio {
    &__wrapper {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      min-height: 34px;
    }

    &__label {
      font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
      display: inline-block;
      font-size: 16px;
      line-height: 18px;
      color: #2e2e2e;
      margin-right: 20px;
    }

    &__container {
      display: inline-block;

      label.radio-container {
        margin: 0 0 15px;

        &:last-child {
          margin: 0;
        }
      }
    }

    &__tooltip {
      display: inline-block;
      &__icon {
        margin-left: 5px;
      }

      &__message {
        visibility: visible;
        position: absolute;
        bottom: 110%;
        left: 2px;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 4px rgba(46, 46, 46, 0.1);
        border-radius: 1px;
        font-size: 12px;
        line-height: 14px;
        padding: 8px;
        color: $darkGrey;
        z-index: 100;
        width: 400px;

        &:after {
          content: '';
          position: absolute;
          bottom: -5px;
          left: 126px;
          border-style: solid;
          border-width: 5px 5px 0;
          border-color: #ffffff transparent;
          display: block;
          width: 0;
          z-index: 1;
        }

        &--hidden {
          visibility: hidden;
          position: absolute;
        }
      }
    }
  }

  .error-container {
    height: 20px;
    padding-left: 161px;
    width: 100%;
    margin-top: -4px;
  }
  .validation {
    margin-bottom: 0;
  }

  @media (max-width: $tablet) {
    .radio__tooltip__message {
      width: 380px;
    }
    .radio {
      &__container {
        margin-top: 15px;
      }
    }
    .error-container {
      padding-left: 0;
      margin-top: 0;
      margin-bottom: 5px;
    }
    .validation {
      margin-top: 0;
    }
  }

  @media (max-width: $mobile-l) {
    .radio__tooltip__message {
      width: 100%;
    }
    .fieldset-container {
      margin: 0 0 28px 0;
    }
  }
</style>
