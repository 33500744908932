<template>
  <div>
    <div v-if="errorMessage !== ''" class="error-message">
      <FieldStatus :error="errorMessage" />
    </div>
    <IngButton type="submit">
      {{ $t('signers.form.buttons.submit') }}
    </IngButton>
  </div>
</template>
<script>
  import { mapState, mapGetters } from 'vuex';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';

  export default {
    components: {
      IngButton,
      FieldStatus,
    },
    props: {
      applicant: {
        type: String,
        default: '',
      },
    },
    computed: {
      errorMessage() {
        const serverError = this.errors.server;
        if (serverError) {
          if (serverError >= 400 && serverError < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          if (serverError === 70007) {
            return this.$t('forms.signupForm.errors.70007');
          }
          if (serverError === 70011) {
            return this.$t('forms.signupForm.errors.70011');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        if (
          this.submitClicked &&
          (this.isCompanyEditable || this.isClientEditable)
        ) {
          return this.$t('forms.signupForm.errors.IsEditableOpen');
        }
        if (this.submitClicked && !this.isValid) {
          const extraMsg =
            !this.areSignersPhonesUnique || !this.areSignersEmailsUnique
              ? this.$t('forms.errors.repetitions')
              : '';
          return this.$t('forms.signupForm.errors.fields') + ' ' + extraMsg;
        }
        return '';
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'isCompanyEditable',
        'isClientEditable',
      ]),
      ...mapGetters('signers', [
        'isValid',
        'areSignersEmailsUnique',
        'areSignersPhonesUnique',
      ]),
    },
  };
</script>
<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .error-message {
    border: solid 3px $Functional-Minus500;
    border-radius: 4px;
    padding: 0 2px 4px 3px;
    margin-bottom: 3rem;
  }
</style>
