import { isEmpty } from '../helpers/string';
import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';

export default function ValidateIdentificationIssuingAuthority(
  identificationIssuingAuthority
) {
  if (isEmpty(identificationIssuingAuthority)) {
    return {
      identificationIssuingAuthority:
        'IdentificationIssuingAuthorityIsRequired',
    };
  }
  if (hasForbiddenCharacters(identificationIssuingAuthority)) {
    return { identificationIssuingAuthority: 'ForbiddenCharacters' };
  }
  return { identificationIssuingAuthority: undefined };
}
