var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ing-privacy-policy"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.p1'))}}),_c('p',[_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.p2'))+" "),_c('a',{staticClass:"ing-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('CreditreformModal')}}},[_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.cta1')))]),_vm._v("."),_c('br'),_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.p3'))+" "),_c('a',{staticClass:"ing-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('CreditreformModal')}}},[_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.cta1')))]),_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.p4'))+" "),_c('a',{staticClass:"ing-link",attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openModal('SchufaModal')}}},[_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.cta2')))]),_vm._v(_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.p5')))]),(_vm.isSchufaModalOpen)?_c('Modal',{on:{"onClose":function($event){return _vm.closeModal('SchufaModal')}}},[_c('div',{staticClass:"modal-inner-content"},[_c('h2',{staticClass:"modal-inner-header"},[_vm._v(" "+_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.schufaModalContent.title'))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('ingSubmission.ingPrivacyPolicy.schufaModalContent.content')
        )}})])]):_vm._e(),(_vm.isCreditreformModalOpen)?_c('Modal',{on:{"onClose":function($event){return _vm.closeModal('CreditreformModal')}}},[_c('div',{staticClass:"modal-inner-content"},[_c('h2',{staticClass:"modal-inner-header"},[_vm._v(" "+_vm._s(_vm.$t('ingSubmission.ingPrivacyPolicy.creditreFormModalContent.title'))+" ")]),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t(
            'ingSubmission.ingPrivacyPolicy.creditreFormModalContent.content'
          )
        )}})])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }