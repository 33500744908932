import axios from 'axios';

/**
 * Fetch offer's contracts.
 *
 * @return {Promise.<TResult>|axios.Promise}
 */

const axiosWithCredentials = axios.create({ withCredentials: true });

export function getContract({ rootState, commit }, contractType) {
  axios
    .get(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/contracts/${rootState.navigation.offerId}/${contractType}`
    )
    .then(({ data }) => {
      if (data) {
        commit(`SET_${contractType}`, data.result);
      }
      return data;
    })
    .catch((error) => {
      const { status, data } = error.response || {};
      commit(`SET_${contractType}_ERROR`, { status, data });
    });
}

export async function getIngContract({ commit }, contractType) {
  await axiosWithCredentials
    .get(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/loan-request/contracts/${contractType}`
    )
    .then(({ data }) => {
      if (data) {
        commit(`SET_${contractType}`, data.result);
      }
      return data;
    })
    .catch((error) => {
      const { status, data } = error.response || {};
      commit(`SET_${contractType}_ERROR`, { status, data });
    });
}

export function getRegularContracts({ rootState, commit }) {
  const tasks = [
    getContract({ rootState, commit }, 'CREDIT'),
    getContract({ rootState, commit }, 'GUARANTEE'),
  ];

  Promise.all(tasks)
    // eslint-disable-next-line
        .then(console.log('All loading finished...'))
    // eslint-disable-next-line
        .catch(e => console.error(e))

}

export function getMicroContract({ rootState, commit }) {
  getContract({ rootState, commit }, 'MICRO_CREDIT');
}

export async function getIngMicroContract({ rootState, commit }) {
  await getIngContract({ rootState, commit }, 'MICRO_CREDIT');
}

export async function getIngRegularContracts({ rootState, commit }) {
  const creditContract = await getIngContract({ rootState, commit }, 'CREDIT');
  const blanketAssignmentContract = await getIngContract(
    { rootState, commit },
    'BLANKET_ASSIGNMENT'
  );

  Promise.all([creditContract, blanketAssignmentContract])
    // eslint-disable-next-line
        .then(console.log('All loading finished...'))
    // eslint-disable-next-line
        .catch(e => console.error(e))

}

export async function getWclMicroContract({ rootState, commit }) {
  const creditContract = await getIngContract(
    { rootState, commit },
    'MICRO_CREDIT'
  );

  Promise.all([creditContract])
    // eslint-disable-next-line
      .then(console.log('All loading finished...'))
    // eslint-disable-next-line
      .catch(e => console.error(e))

}

export async function getWclRegularContracts({ rootState, commit }) {
  const creditContract = await getIngContract({ rootState, commit }, 'CREDIT');
  const blanketAssignmentContract = await getIngContract(
    { rootState, commit },
    'BLANKET_ASSIGNMENT'
  );
  const powerOfAttorneyContract = await getIngContract(
    { rootState, commit },
    'POWER_OF_ATTORNEY'
  );

  Promise.all([
    creditContract,
    blanketAssignmentContract,
    powerOfAttorneyContract,
  ]);
}
