<template>
  <div class="ing-header-container">
    <img
      alt="Representative Icon"
      class="representative-icon header-icon"
      src="/ing_representative_icon.svg"
    />
    <div class="ing-header-container__content">
      <h2>{{ $t('ingBorrowerSignupForm.representativeHeader.title') }}</h2>
      <p>
        {{ $t('ingBorrowerSignupForm.representativeHeader.explanation') }}
        <ing-bs-tooltip
          placement="bottom"
          :tooltipMessage="
            $t('ingBorrowerSignupForm.representativeHeader.tooltip')
          "
        />
      </p>
    </div>
  </div>
</template>

<script>
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';

  export default {
    components: {
      IngBsTooltip,
    },
  };
</script>

<style scoped>
  .ing-header-container {
    margin-bottom: 42px;
  }
  h2 {
    font-weight: bold;
  }
</style>
