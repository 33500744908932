<template>
  <div>
    <div v-if="hasError" class="error-wrapper">
      <div
        v-show="partnerDataError"
        id="partnerDataError"
        class="partner-general-error"
      >
        <p class="validation">
          {{ $t('forms.errors.GeneralPartnerError.part1') }}
          <br />
          {{ $t('forms.errors.GeneralPartnerError.part2') }}
          <a
            :href="VUE_APP_LENDICO_BASE_URL_CMS_API + 'partner-werden'"
            class="base-link error-link"
            >{{ $t('forms.errors.GeneralPartnerError.link') }}</a
          >
          {{ $t('forms.errors.GeneralPartnerError.part3') }}
        </p>
      </div>
      <p
        v-show="serverError"
        id="serverError"
        class="partner-general-error validation"
      >
        {{ $t('forms.signupForm.errors.500') }}
      </p>
    </div>
    <button
      id="partnerSubmitButton"
      class="button"
      :class="{ 'has-error': hasError }"
      type="button"
      @click="handleSubmit()"
    >
      {{ $t('partnerSubmissionPageNew.form.partnerSubmissionButton.label') }}
    </button>
    <p class="help-message">
      {{ $t('partnerSubmissionPageNew.form.partnerHelpMessage.label') }}
      <a
        class="base-link"
        :href="
          'tel:' + $t('partnerSubmissionPageNew.form.partnerHelpMessage.phone')
        "
        >{{ $t('partnerSubmissionPageNew.form.partnerHelpMessage.phone') }}</a
      >
    </p>
  </div>
</template>

<script>
  import { mapGetters, mapMutations, mapActions, mapState } from 'vuex';
  import lodashIsEmpty from 'lodash/isEmpty';
  export default {
    name: 'PartnerSubmitButton',
    computed: {
      ...mapGetters('partner', ['isValid']),
      ...mapState('partner', ['requestError']),
      VUE_APP_LENDICO_BASE_URL_CMS_API: () => {
        return process.env.VUE_APP_LENDICO_BASE_URL_CMS_API;
      },
      partnerDataError: function () {
        return (
          this.requestError.status &&
          (this.requestError.status === 400 || this.requestError.status === 404)
        );
      },
      serverError: function () {
        return this.requestError.status && this.requestError.status === 500;
      },
      hasError: function () {
        return this.partnerDataError || this.serverError;
      },
    },
    methods: {
      ...mapMutations('partner', [
        'SET_SUBMIT_CLICKED',
        'SET_REQUEST_ERROR',
        'SET_LOADING',
        'CLEAR_REQUEST_ERROR',
      ]),
      ...mapActions('partner', ['searchPartner']),
      async handleSubmit() {
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        try {
          await this.CLEAR_REQUEST_ERROR();
          await this.SET_LOADING(true);
          await this.searchPartner();
        } catch (error) {
          let errorBody;
          let errorStatus;
          if (error.response) {
            errorStatus = error.response.status;
            errorBody = error.response.data;
          } else {
            errorStatus = 500;
            errorBody = error.message || 'Something went wrong';
          }
          await this.SET_REQUEST_ERROR({
            status: errorStatus,
            body: errorBody,
          });
        } finally {
          await this.SET_LOADING(false);
          if (lodashIsEmpty(this.requestError)) {
            this.$emit('success');
          }
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_settings.scss';
  @import '@/variables.scss';

  .help-message {
    font-size: 18px;
    line-height: 24px;
    margin-top: 20px;

    .base-link {
      font-weight: $bold;
    }
  }

  .error-wrapper {
    margin-top: 50px;

    .validation {
      font-size: 18px;
      line-height: 24px;
      .error-link {
        color: $validation;
      }
    }
  }
  .partner-general-error {
    font-size: 14px;
    text-align: center;
  }
  #serverError {
    padding-top: 20px;
  }
  .button {
    margin-top: 50px;

    &.has-error {
      margin-top: 20px;
    }
  }
  @media screen and (max-width: $mobile-l) {
    .help-message {
      font-size: 16px;
      line-height: 18px;
    }
    .error-wrapper {
      margin-top: 10px;
    }
    .button {
      margin-top: 15px;

      &.has-error {
        margin-top: 20px;
      }
    }
  }
</style>
