export default {
  ingHeroText: {
    header: {
      termLoan: {
        first: 'Finanzieren ohne Geschäftsunterlagen?',
        second: 'Geht: Mit dem Firmenkredit der ING für Händler',
      },
      wcl: {
        first: 'Immer da, wenn Sie ihn brauchen',
        second: 'Der Flexkredit der ING für Händler',
      },
    },
  },
  ingSubmission: {
    ingTermsAndConditions:
      "Ich bestätige, dass ich sowie alle (weiteren) im Handelsregister eingetragenen Geschäftsführer/-innen, Gesellschafter/-innen und wirtschaftlich Berechtigte im Sinne des § 3 Abs. 1 GwG – Geldwäschegesetz die <a href='@:ingPrivacyPolicyUrl' target='_blank' class='ing-link'>Datenschutzerklärung</a>  zur Kenntnis genommen haben.",
    ingSubmitButton: 'Unverbindlich anfragen',
    ingPrivacyPolicy: {
      p1: 'Damit die ING meine Finanzierungsanfrage prüfen kann, werden mit dem Klicken auf  <em>„Anfragen“</em> automatisch folgende Seller-Daten von Amazon übertragen: Laufzeit der Seller-Aktivitäten bei Amazon, Verkäuferstatus (aktiv/inaktiv), aktueller Bestandswert (FBA – „Fulfillment by Amazon“), Hauptproduktkategorie (Produktkategorie mit dem höchsten Anteil der Verkäufe für die letzten drei Monate), Verkaufszahlen nach Woche, Monat und Quartal, ein Wert zur Umsatzstabilität sowie eine eindeutige Nutzerkennung für die Zuordnung. Die ING wird außerdem die Eckdaten (Art, Laufzeit, Betrag, Gebundener Sollzinssatz) und den Status meiner Kreditanfrage und meines Kredits an meinen Seller Central Account übertragen und aktualisieren, damit ich jederzeit den aktuellen Status einsehen kann und Amazon die für die Kreditvermittlung erforderlichen Daten erhält.',
      p2: 'Im Rahmen der sog. Autofill-Funktion sowie zum Zwecke einer Vorabprüfung erhält Lendico Informationen von der ',
      p3: 'Zur Beurteilung der Bonität übermittelt Lendico im Auftrag der ING Daten an die ',
      p4: ' und die ',
      p5: ' bzw. es werden Daten von den genannten Stellen abgefragt.',
      cta1: 'Creditreform',
      cta2: 'SCHUFA',
      schufaModalContent: {
        title: 'SCHUFA',
        content:
          'Die ING übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die <em>SCHUFA Holding AG</em>, Kormoranweg 5, 65201 Wiesbaden (im Folgenden "SCHUFA"). Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 lit. b und Artikel 6 Absatz 1 lit. f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 lit. f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. ' +
          '<br /><br /><em>Der Kunde befreit die ING insoweit auch vom Bankgeheimnis.</em><br /><br />\n' +
          'Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht oder Standardvertragsklauseln vereinbart wurden, die unter www.schufa.de eingesehen werden können) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter <a rel="noopener noreferrer" href="https://www.ing.de/datenschutz/schufa" class="ing-link" target="_blank">www.ing.de/datenschutz/schufa</a> eingesehen werden.',
      },
      creditreFormModalContent: {
        title: 'Creditreform',
        content:
          'Zur Bereitstellung der sog. Autofill-Funktion und zur Durchführung einer Vorabprüfung erhält die ING relevante Informationen (z.B. Adressdaten, allgemeine Unternehmensinformationen) von der <em>Creditreform Frankfurt Emil Vogt KG;</em> Bleichstraße 59, 60313 Frankfurt (Main) (im Folgenden „Crefo“). Nach erfolgreicher Vorabprüfung übermittelt Lendico im Auftrag der ING Daten (Name, Adresse und gegebenenfalls Geburtsdatum) zum Zwecke der Bonitätsprüfung an die Crefo. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der ING oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen.\n' +
          '<br /><br /><em>Der Kunde befreit die ING insoweit auch vom Bankgeheimnis.</em><br /><br />\n' +
          'Detaillierte Informationen zur Crefo im Sinne des Artikels 14 DSGVO, d.h. Informationen zum Geschäftszweck, zu Zwecken der Datenspeicherung, zu den Datenempfängern, zum Selbstauskunftsrecht, zum Anspruch auf Löschung oder Berichtigung etc. können online unter <a rel="noopener noreferrer" href="https://www.creditreform.de/frankfurt-am-main/datenschutz" class="ing-link" target="_blank">Datenschutz | Creditreform Frankfurt am Main</a> eingesehen werden.',
      },
    },
    ingAdvantages: {
      termLoan: {
        panel1: {
          title: 'Einfach:',
          text: 'Digitale Anfrage, keine Finanzdokumente nötig',
        },
        panel2: {
          title: 'Schnell:',
          text: 'Kreditentscheidung in 48 Stunden',
        },
        panel3: {
          title: 'Flexibel:',
          text: 'Kostenlose Sondertilgungen und vorzeitige Rückzahlung',
        },
      },
      wcl: {
        panel1: {
          title: 'Einfach:',
          text: 'Digitale Anfrage, keine Finanzdokumente nötig',
        },
        panel2: {
          title: 'Schnell:',
          text: 'Kreditentscheidung in 48 Stunden',
        },
        panel3: {
          title: 'Flexibel:',
          text: 'Bis zum vereinbarten Limit jederzeit spontan Geld abrufen',
        },
      },
    },
    ingCampaignAdvantages: {
      title: 'Jetzt Cashback-Aktion nutzen',
      desc: 'cJetzt Cashback-Aktion nutzen',
      flexkredit: {
        para1:
          'Für alle neuen Flexkredite, die bis zum 15.11. angefragt werden, <b>erstatten wir die Zinsen des ersten Monats zurück</b> (<a href="https://www.ing.de/business/cashback-flexkredit/" class="ing-link" target="_blank">Konditionen anzeigen</a>)',
        para2:
          '<b>Einfach und schnell:</b> Digitale Anfrage ohne Geschäfts-unterlagen, Kreditentscheidung innerhalb von 48 Stunden',
        para3: '<b>Flexibel:</b> Bis zum eingeräumten Limit Geld abrufen',
      },
      firmenkredit:
        'Für alle neuen Firmenkredite, die bis zum 15.11. angefragt werden, <b>erstatten wir die Zinsen des ersten Monats zurück</b>. Lesen Sie <a href="https://www.ing.de/business/cashback-firmenkredit/" class="ing-link" target="_blank">hier alle Konditionen der Cashback-Aktion</a>. Einfach die Anfrage ausfüllen – die Kreditentscheidung erhalten Sie innerhalb von 48 Stunden.',
    },
  },
  ingDropdownDefault: '- Bitte auswählen -',
  ingSalutationLabel: 'Anrede',
  ingEmail: 'unternehmen@ing.de',
  ingPhone: '030 / 800 935 230',
  ingSocialMedia: 'Folge uns',
  ingAgbUrl: 'https://www.ing.de/agb/',
  ingPrivacyPolicyUrl:
    'https://www.ing.de/binaries/content/assets/pdf/hilfe/datenschutz/datenschutzerklaerung-der-ing-diba-ag-fuer-geschaeftskunden.pdf',
  ingFooterLinks: {
    dataProtection: '@:ingPrivacyPolicyUrl',
    agb: '@:ingAgbUrl',
    impressum: 'https://www.ing.de/ueber-uns/unternehmen/impressum',
    socialNetworks: {
      facebookLink: 'https://de-de.facebook.com/ing.deutschland',
      flckrLink: 'https://www.flickr.com/photos/inggroup',
      linkedInLink: 'https://de.linkedin.com/company/ing-de',
      slideshareLink: 'https://de.slideshare.net/ING',
      twitterLink: 'https://twitter.com/ing_deutschland',
    },
  },
  ingBorrowerSignupForm: {
    formHeader: {
      termLoan: 'Firmenkredit anfragen',
      wcl: 'Flexkredit anfragen',
    },
    calculatorHeader: {
      termLoan: {
        title: '1. Kreditwunsch',
        explanation:
          'Der vorausgefüllte Betrag entspricht der maximalen Kreditsumme, die Sie anfragen können. Sie können den Betrag auch reduzieren, der Mindestbetrag für die Kreditanfrage ist jedoch 10.000 Euro.',
      },
      wcl: {
        title: '1. Kreditwunsch',
        explanation:
          'Bis zum Kreditrahmen können Sie sich flexibel Geld auszahlen lassen. Aus- und Einzahlungen sind jederzeit möglich, die Laufzeit ist unbegrenzt. Den ersten Auszahlungsbetrag erhalten Sie direkt nach erfolgreicher Kreditanfrage – Sie können den Betrag später im Prozess noch ändern.',
      },
    },
    companyHeader: {
      title: '2. Firmeninformationen',
      explanation:
        'Wir benötigen nur ein paar Informationen, um Ihr Unternehmen einschätzen zu können. Eine Übersendung von Unterlagen wie Jahresabschlüsse oder Kontoauszüge ist nicht notwendig.',
    },
    company: {
      label: 'Geben Sie bitte Ihren Firmennamen ein',
      tooltip:
        'Wenn Sie Ihren Firmennamen eingeben und aus der Liste auswählen, wird das Anfrageformular automatisch mit Ihren Firmendaten vervollständigt. So sparen Sie noch mehr Zeit.',
    },
    representativeHeader: {
      title: '3. Firmenvertreter',
      explanation:
        'Um eine Kreditanfrage zu stellen, müssen Sie ein Vertretungsberechtigter oder Inhaber der Firma sein.',
      tooltip:
        'Als Vertretungsberechtigte werden derzeit nur Geschäftsführer oder Vorstandsmitglieder akzeptiert. Eine Anfrage durch Prokuristen und sonstige Bevollmächtigte ist leider nicht möglich.',
    },
    submitHeader: {
      title: 'Noch zwei Klicks',
    },
  },
  ingSignupFormThankYouPage: {
    ingThankYouPage: {
      testAndLearnThankYouText:
        'Wir bestätigen Ihre Finanzierungsanfrage noch einmal in einer separaten E-Mail und informieren Sie über die nächsten Schritte.',
      thankYouText:
        'Wir bestätigen Ihre Anfrage noch einmal in einer separaten E-Mail.\n' +
        'Um den Antragsprozess zu beschleunigen, bitten wir Sie, sich den Abschnitt "So geht es jetzt weiter" gründlich durchzulesen und die notwendigen Dokumente zusammenzutragen.',
      getBackToYouText:
        'Wir melden uns in Kürze bei Ihnen zurück.<br />Falls Sie noch Fragen haben, freuen wir uns über Ihren Anruf unter 030 800 935 230 oder eine E-Mail an unternehmen@ing.de',
    },
    content: {
      data: {
        sectionHeader2: 'Kreditdetails',
        sectionHeader3: 'Firmeninformationen',
        lastName: 'Nachname:',
      },
    },
    ingNextSteps: {
      header: 'So geht es jetzt weiter',
      subHeader1:
        'Vielen Dank für Ihr Interesse an einer Firmenfinanzierung mit ING.',
      subHeader2:
        'Um Ihnen schnellstmöglich ein Angebot machen zu können, benötigen wir folgende Unterlagen:',
      footnote:
        '* Falls Sie uns <u>Einnahmenüberschussrechnungen</u> senden, benötigen wir zusätzlich Ihren Einkommensteuerbescheid (2017 oder 2018).',
    },
    sellerNextSteps: {
      testAndLearnHeader: "Und so geht's weiter",
      header: 'Nächste Schritte',
      step1:
        "Schicken Sie uns Ihre vollständigen Unterlagen per E-Mail an <a href='mailto:@:ingEmail' class='ing-link'>@:ingEmail</a>",
      step2:
        'Nach Prüfung durch unsere Kreditexperten erhalten Sie innerhalb von 48 Stunden ein kostenfreies und unverbindliches Angebot.',
      step3:
        'Wenn Sie dem Finanzierungsvorschlag zustimmen, müssen Sie sich nur noch identifizieren und Ihre (digitale) Unterschrift leisten.',
      step4: 'Im Anschluss überweist ING die Kreditsumme direkt auf Ihr Konto.',
      button: 'Zurück zum Seller Central',
      testAndLearn: {
        termLoan: {
          step1:
            'Wenn die Bonitätsprüfung durch unsere Kreditexperten positiv ausfällt, schicken wir Ihnen innerhalb von 48 Stunden einen kostenfreien und unverbindlichen Finanzierungsvorschlag.',
          step2:
            'Wenn Sie diesen annehmen, brauchen Sie sich nur noch zu identifizieren und den Kreditvertrag zu unterschreiben. ',
          step3:
            'Wenn alles passt, überweisen wir Ihnen die Kreditsumme innerhalb von wenigen Tagen direkt auf Ihr Konto.',
        },
        wcl: {
          step1:
            'Wenn die Bonitätsprüfung durch unsere Kreditexperten positiv ausfällt, schicken wir Ihnen innerhalb von 48 Stunden einen kostenfreien und unverbindlichen Finanzierungsvorschlag. Hier können Sie auch Ihren gewünschten Auszahlungsbetrag der Höhe nach festlegen.',
          step2:
            'Wenn Sie den Finanzierungsvorschlag annehmen, brauchen Sie sich nur noch zu identifizieren und den Kreditvertrag zu unterschreiben.',
          step3:
            'Passt alles, überweisen wir die von Ihnen festgelegte Auszahlungssumme innerhalb von wenigen Tagen direkt auf Ihr Konto.',
          step4:
            'Parallel erhalten Sie Ihre Zugangsdaten zum ING-Kundenportal. Hier können Sie Ihren Flexkredit bequem verwalten und weitere Auszahlungen vornehmen.',
        },
      },
    },
  },
  ingSignersMissingInfo: {
    save: 'Speichern',
    cancel: 'Abbrechen',
    edit: 'Bearbeiten',
    submit: 'Abschicken',
    privacyPolicy: {
      preHighlightedText: 'Mit dem Klicken auf ',
      highlightedText: '„Abschicken“',
      preLinkPageText:
        ' bestätige ich, dass es keine weiteren Geschäftsführer/-innen und wirtschaftliche Berechtigten gibt.',
    },
  },
  ingSuccessPage: {
    p: 'Wir melden uns mit Ihren Vertragsunterlagen und Informationen zur Identifizierung bei Ihnen zurück. ',
  },
  ingOfferPage: {
    progressBar: {
      firstStep: '1 Finanzierungsvorschlag',
      secondStep: '2 Vertragsunterlagen',
    },
    offerDetailsSection: {
      title: 'Ihre Finanzierungskonditionen auf einen Blick',
      regular: {
        loanAmount: 'Kreditbetrag:',
        duration: 'Laufzeit:',
        interestRate: 'Gebundener Sollzinssatz:',
        monthlyRate: 'Monatliche Rate:',
      },
      wcl: {
        loanAmount: 'Flexkredit:',
        duration: 'Laufzeit:',
        durationValue: 'unbefristet',
        interestRate: 'Gebundener Sollzinssatz p.a.:',
        firstPayoutAmount: 'Erster Auszahlungsbetrag:',
        rateOnFirstMonth: 'Rate im ersten Monat:',
        rateTooltip:
          'Die Rate im ersten Monat besteht aus den Zinsen auf den ersten Auszahlungsbetrag sowie einem Rückzahlungsanteil von 8 % des ersten Auszahlungsbetrags. Falls Sie zusätzliche Auszahlungen oder Rückzahlungen tätigen, kann sich dieser Betrag ändern. ',
        explanatoryText:
          '*Zinssatz ist für 12 Monate gebunden. Weitere Infos entnehmen Sie dem Vertragsangebot.',
      },
      p: 'Klicken Sie auf den Button, um Ihre Vertragsunterlagen herunterzuladen und die Identifizierung zu starten.',
      button: 'Identifizierung starten',
    },
    contractSection: {
      contracts: {
        title: 'Ihre Vertragsunterlagen',
        missingContract: 'Nicht verfügbar',
        preview: 'Vorschau',
        download: 'Herunterladen',
        page: 'Seite',
        close: 'Schließen',
      },
      identification: {
        title: 'Identifizieren und unterschreiben in nur einem Schritt',
        p1:
          'In Ihrem E-Mail-Postfach finden Sie eine Schritt-für-Schritt-Anleitung zur Identifizierung per Videochat ' +
          'sowie eine weitere E-Mail der Deutschen Post AG. Diese enthält einen Link, mit dem Sie das Online-Verfahren starten. ',
        p2:
          'Wenn Sie und mögliche weitere Vertretungsberechtigte sich erfolgreich identifiziert und digital unterschrieben haben,' +
          ' erhalten Sie die Vertragsunterlagen per E-Mail. Bitte beachten Sie, dass für die Identitätsprüfung ein Computer, ' +
          'ein Smartphone oder ein Tablet mit Kamera und Mikrofon sowie eine stabile Internetverbindung notwendig sind.',
      },
      nextSteps: {
        title: 'Wie geht es weiter?',
        li1: 'Prüfen Sie Ihre Vertragsunterlagen',
        li2: 'Klicken Sie auf den Link in der E-Mail der Deutschen Post AG',
        li3: 'Identifizieren Sie sich mit einem gültigen Ausweisdokument ',
        li4: 'Unterschreiben Sie die Verträge durch Eingabe einer SMS-TAN',
      },
    },
    signingInfoSection: {
      title: 'Sichere Identifikation und Vertragsunterzeichnung',
      p1: {
        title: 'Vertrauenswürdiger Partner: ',
        content:
          'Die Deutsche Post AG bietet ein sicheres Online-Verfahren zur Überprüfung Ihrer ' +
          'Identität und zur Vertragsunterzeichnung. Damit sparen Sie sich den Weg zur Postfiliale.',
      },
      p2: {
        title: 'Einfach und schnell: ',
        content:
          'Sie können die Identifizierung und die Vertragsunterzeichnung jederzeit vornehmen und auch' +
          ' erneut starten, wann immer es Ihnen am besten passt. Alle Geschäftsführer(innen) und mögliche weitere Vertretungsberechtigte ' +
          'unterschreiben nacheinander. Sie werden von der Deutschen Post AG informiert, sobald alle Unterschriften eingegangen sind.',
      },
    },
    contactSection: {
      title: 'Haben Sie noch Fragen?',
      p1: 'Sie erreichen uns von Montag bis Freitag zwischen 9 und 18 Uhr telefonisch unter ',
      p2: ' oder per E-Mail an ',
    },
  },
  ingPageNotFound: {
    title: 'Fehler 404 - Seite nicht gefunden',
    metaTitle: '@:ingPageNotFound.title',
    metaDescription: '@:ingPageNotFound.title',
    content: {
      firstLine: 'BITTE LADEN SIE DIE AKTUELLE SEITE NEU!',
      secondLine:
        'Sollte sie immer noch nicht korrekt angezeigt werden, kehren Sie zur vorigen zurück oder kontaktieren Sie uns unter ',
      tel: '@:ingPhone',
      join: 'oder',
      mail: '@:ingEmail',
    },
  },
  ingTechnicalErrorPage: {
    title: 'Es ist ein technischer Fehler aufgetreten',
    metaTitle: '@:ingTechnicalErrorPage.title',
    metaDescription: '@:ingTechnicalErrorPage.title',
    content: {
      caption: 'Bitte wenden Sie sich an',
      mail: '@:ingEmail',
    },
  },
  ingOfferExpiredErrorPage: {
    title: 'Die Seite ist abgelaufen.',
    metaTitle: '@:ingOfferExpiredErrorPage.title',
    metaDescription: '@:ingOfferExpiredErrorPage.title',
    content: {
      caption:
        "Falls Sie Fragen haben, wenden Sie sich an unseren Kundenservice. Sie erreichen uns per E-Mail an <a href='mailto:@:ingEmail' class='ing-link'>@:ingEmail</a> oder telefonisch von Montag bis Freitag zwischen 9 und 18 Uhr unter <a href='tel:@:ingPhone' class='ing-link'>@:ingPhone</a> . Vielen Dank.",
      mail: '@:ingEmail',
    },
  },
  ingOfferAlreadyUsedErrorPage: {
    title: 'Der Link ist abgelaufen.',
    metaTitle: '@:ingOfferAlreadyUsedErrorPage.title',
    metaDescription: '@:ingOfferAlreadyUsedErrorPage.title',
    content: {
      caption:
        "Der Link, den Sie benutzt haben, ist abgelaufen. Wenn Sie für das Amazon Kreditvermittlungsprogramm qualifiziert sind, können Sie über Seller Central eine neue Kreditanfrage einreichen.<br /> <br /> Haben Sie Fragen? Sie erreichen uns per E-Mail an <a href='mailto:@:ingEmail' class='ing-link'>@:ingEmail</a> oder telefonisch von Montag bis Freitag zwischen 9 und 18 Uhr unter <a href='tel:@:ingPhone' class='ing-link'>@:ingPhone</a>. Vielen Dank.",
      mail: '@:ingEmail',
    },
  },
  ingContact: {
    notAppliedBefore: {
      title: 'Unser Team ist persönlich für Sie da',
      description:
        'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail. Unsere Kundenbetreuung ist <strong>Montag bis Freitag von 9 bis 18 Uhr</strong> für Sie da:',
      union: 'oder',
      phone: '@:ingPhone',
      email: '@:ingEmail',
    },
    alreadyApplied: {
      title: 'Ihre Fragen',
      description:
        'Für Fragen und Informationen zum Anmeldeprozess oder den Kreditmodalitäten steht Ihnen unser Support-Team zur Verfügung. Unsere Service-Hotline ist <strong>von Montag bis Freitag von 9 bis 18 Uhr für Sie da</strong>:',
      union: 'oder',
      phone: '@:ingPhone',
      email: '@:ingEmail',
    },
  },
};
