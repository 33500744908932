<template>
  <div>
    <len-header :loanCalculator="true">
      <div slot="headerCopy" class="hero-copy max-width text-align--center">
        <h2 v-html="$t('partnerSubmissionPageNew.title')" />
      </div>
    </len-header>

    <len-section paddingVertical="45" maxWidth="true">
      <partner-submission-form @success="partnerSubmissionSuccess()" />
    </len-section>

    <len-promises />
    <len-contact rootTranslationKey="partnerSubmissionPageNew" />

    <len-footer />
  </div>
</template>

<script>
  import LenHeader from '@/components/common/len-header';
  import LenContact from '@/components/contact';
  import LenPromises from '@/components/promises';
  import PartnerSubmissionForm from '@/components/partner/forms/partner-submission-form';
  import { routerPushByName } from '@/mixins/routerMixin';
  import { enableGTM } from '@/mixins/gtmMixins';
  import { calculatorLoaderMixin } from '@/mixins/calculatorLoaderMixin';

  export default {
    name: 'PartnerSubmissionPage',
    components: {
      LenHeader,
      LenContact,
      LenPromises,
      PartnerSubmissionForm,
    },
    mixins: [enableGTM, routerPushByName, calculatorLoaderMixin],
    beforeMount() {
      this.loadCalculator();
    },
    methods: {
      partnerSubmissionSuccess() {
        this.routerPushByName('partner-submission-thankyou-page');
      },
    },
    metaInfo() {
      return {
        title: this.$i18n.t('partnerSubmissionPageNew.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('partnerSubmissionPageNew.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('partnerSubmissionPageNew.metaDescription'),
          },
        ],
        link: [
          {
            rel: 'alternate',
            hreflang: 'nl-nl',
            href: 'https://lendico.nl/lenen/registratie',
          },
          {
            rel: 'alternate',
            hreflang: 'de-ch',
            href: 'https://www.lendico.ch/firmenkredit-registrieren.html',
          },
          {
            rel: 'alternate',
            hreflang: 'en-ch',
            href: 'https://www.lendico.ch/business-loan-signup.html',
          },
          {
            rel: 'alternate',
            hreflang: 'de-de',
            href: 'https://antrag.lendico.de/partner-antrag',
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .len-contact-wrapper {
    display: flex;
    width: 100%;
    height: 265px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-header-text {
    margin-bottom: 30px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #2e2e2e;
    font-size: 40px;
    text-align: center;
    text-transform: uppercase;
  }

  .header-content-text {
    margin-bottom: 30px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #2e2e2e;
    font-size: 19px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
  }

  .contact-contacts-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mail-contact {
    margin-right: 0px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #3cbdb9;
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
    text-transform: none;
  }

  .mail-contact:hover {
    text-decoration: underline;
  }

  .bold-text-9:hover {
    color: #0c3f55;
  }

  .text-block-2 {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #3cbdb9;
    font-size: 26px;
    line-height: 30px;
    font-weight: 700;
  }

  .text-block-2:hover {
    color: #0c3f55;
  }

  .text-block-3 {
    margin-right: 30px;
    margin-left: 30px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #2e2e2e;
    font-size: 19px;
    line-height: 30px;
    font-weight: 500;
    text-transform: uppercase;
  }

  .link-block {
    text-decoration: none;
  }

  @media (max-width: 991px) {
    .contact-header-text {
      font-size: 30px;
    }
    .header-content-text {
      font-size: 16px;
    }
  }

  @media (max-width: 767px) {
    .len-contact-wrapper {
      height: 220px;
    }
    .contact-header-text {
      margin-bottom: 15px;
      font-size: 25px;
    }
    .header-content-text {
      margin-bottom: 15px;
      font-size: 14px;
      line-height: 18px;
    }
    .bold-text-9 {
      font-size: 18px;
      line-height: 24px;
    }
  }

  @media (max-width: 479px) {
    .len-contact-wrapper {
      height: 200px;
    }
    .contact-header-text {
      font-size: 16px;
      line-height: 20px;
    }
    .header-content-text {
      font-size: 10px;
      line-height: 12px;
    }
    .mail-contact {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .bold-text-9 {
      font-size: 16px;
      line-height: 20px;
    }
    .text-block-2 {
      font-size: 16px;
      line-height: 20px;
    }
    .text-block-3 {
      margin-right: 20px;
      margin-left: 20px;
      font-size: 16px;
      line-height: 20px;
    }
  }
</style>
