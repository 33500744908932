<template>
  <ing-text-input
    v-model="userCity"
    :label="label"
    :error="errors.userCity && $t('forms.errors.' + errors.userCity)"
    :displayErrorIfDirty="!submitClicked"
    name="userCity"
    data-qa-id="applicant-city"
    @focus="onFocus"
    @keydown="onKeyDown"
    @blur="onFinished()"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTextInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    data() {
      return {
        justLeftField: false,
      };
    },
    computed: {
      label() {
        return this.$t('forms.signupForm.userCity.label');
      },
      userCity: {
        get() {
          return this.$store.state.signupForm.userCity;
        },
        set(value) {
          this.SET_USER_CITY(value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_USER_CITY']),
      onKeyDown(e) {
        if (e.key === 'Enter' || e.key === 'Tab') {
          this.justLeftField = true;
          return false;
        } else {
          this.justLeftField = false;
        }
      },
      onFinished() {
        if (!this.errors.userCity) {
          this.$emit('filled');
        }
      },
      onFocus() {
        this.justLeftField = false;
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
