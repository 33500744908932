<template>
  <div class="ing-wrapper partner-success-page">
    <TheHeader />
    <PageHeader page="partner.success" />
    <PageSection class="section-form" :darkTheme="true">
      <template #section-content>
        <FormSectionHeader section="partner.success.details" :paraCount="0" />
        <div class="data-section">
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('partner.success.nextSteps.dataSection[0].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[0].dataList.firstName'
                  )
                }}
              </span>
              <span> {{ partnerFirstName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[0].dataList.lastName'
                  )
                }}
              </span>
              <span> {{ partnerLastName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('partner.success.nextSteps.dataSection[0].dataList.email')
                }}
              </span>
              <span> {{ partnerEmail }}</span>
            </div>
          </div>
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('partner.success.nextSteps.dataSection[1].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('partner.success.nextSteps.dataSection[1].dataList.amount')
                }}
              </span>
              <span> {{ loanAmount | currencyShort }} EUR</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('partner.success.nextSteps.dataSection[1].dataList.years')
                }}
              </span>
              <span>
                {{
                  $tc(
                    'partner.success.nextSteps.dataSection[1].dataList.duration',
                    length
                  )
                }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[1].dataList.purpose'
                  )
                }}
              </span>
              <span>{{ loanPurpose }}</span>
            </div>
          </div>
        </div>
        <div class="data-section">
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('partner.success.nextSteps.dataSection[2].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[2].dataList.companyName'
                  )
                }}
              </span>
              <span> {{ companyName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[2].dataList.address'
                  )
                }}
              </span>
              <span class="data-section__address">
                {{ address }}
              </span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[2].dataList.foundedDate'
                  )
                }}
              </span>
              <span> {{ dateFounded }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[2].dataList.revenue'
                  )
                }}
              </span>
              <span> {{ revenueData }} </span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[2].dataList.legalForm'
                  )
                }}
              </span>
              <span>{{ legalForm }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[2].dataList.billingMethod'
                  )
                }}
              </span>
              <span>{{ billingMethod }}</span>
            </div>
          </div>
          <div class="data-section__list">
            <div class="data-section__row data-section__row--header">
              <span>
                {{ $t('partner.success.nextSteps.dataSection[3].title') }}
              </span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('partner.success.nextSteps.dataSection[3].dataList.gender')
                }}
              </span>
              <span> {{ salutation }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[3].dataList.firstName'
                  )
                }}
              </span>
              <span> {{ firstName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[3].dataList.lastName'
                  )
                }}
              </span>
              <span> {{ lastName }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[3].dataList.address'
                  )
                }}
              </span>
              <span class="data-section__address">
                {{ userAddress }}
              </span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[3].dataList.dateOfBirth'
                  )
                }}
              </span>
              <span> {{ dob }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t(
                    'partner.success.nextSteps.dataSection[3].dataList.nationality'
                  )
                }}
              </span>
              <span> {{ nationality }}</span>
            </div>
            <div class="data-section__row data-section__row--divider">
              <span>
                {{
                  $t('partner.success.nextSteps.dataSection[3].dataList.mobile')
                }}
              </span>
              <span> {{ mobilePhone }}</span>
            </div>
            <div class="data-section__row">
              <span>
                {{
                  $t('partner.success.nextSteps.dataSection[3].dataList.email')
                }}
              </span>
              <span> {{ email }} </span>
            </div>
          </div>
        </div>
      </template>
    </PageSection>
    <PageSection
      v-if="isPrechecksPassed"
      class="section-form"
      sectionPadding="bottom-no"
    >
      <template #section-content>
        <FormSectionHeader section="partner.success.nextSteps" :paraCount="0">
          <template #form-content>
            <div class="form-section__para">
              <h3 class="form-section__para__title">{{
                $t('partner.success.nextSteps.step1.title')
              }}</h3>
              <i18n path="partner.success.nextSteps.step1.text" tag="p">
                <a
                  place="linkText"
                  :href="
                    'mailto:' + $t('partner.success.nextSteps.step1.linkText')
                  "
                  >{{ $t('partner.success.nextSteps.step1.linkText') }}</a
                >
              </i18n>
              <ul class="form-section__unordered-list">
                <li class="form-section__unordered-list__item">
                  <i18n
                    path="partner.success.nextSteps.step1.list.item1.text"
                    tag="p"
                  >
                    <em place="boldContent">{{
                      $t(
                        'partner.success.nextSteps.step1.list.item1.boldContent'
                      )
                    }}</em>
                  </i18n>
                </li>
                <li class="form-section__unordered-list__item">
                  <i18n
                    path="partner.success.nextSteps.step1.list.item2.text"
                    tag="p"
                  >
                    <em place="boldContent">{{
                      $t(
                        'partner.success.nextSteps.step1.list.item2.boldContent'
                      )
                    }}</em>
                  </i18n>
                </li>
                <li class="form-section__unordered-list__item">
                  <i18n
                    path="partner.success.nextSteps.step1.list.item3.text"
                    tag="p"
                  >
                    <em place="boldContent">{{
                      $t(
                        'partner.success.nextSteps.step1.list.item3.boldContent'
                      )
                    }}</em>
                  </i18n>
                </li>
              </ul>
            </div>

            <div class="form-section__para">
              <h3 class="form-section__para__title">{{
                $t('partner.success.nextSteps.step2.title')
              }}</h3>
              <i18n path="partner.success.nextSteps.step2.text" tag="p">
                <em
                  v-for="(content, index) in $t(
                    'partner.success.nextSteps.step2.boldContents'
                  )"
                  :key="'step3' + index"
                  :place="index"
                  >{{ content }}</em
                >
              </i18n>
            </div>
            <div class="form-section__para">
              <h3 class="form-section__para__title">{{
                $t('partner.success.nextSteps.step3.title')
              }}</h3>
              <i18n path="partner.success.nextSteps.step3.text" tag="p">
                <em place="boldContent">{{
                  $t('partner.success.nextSteps.step3.boldContent')
                }}</em>
              </i18n>
            </div>
            <div class="form-section__para">
              <h3 class="form-section__para__title">{{
                $t('partner.success.nextSteps.step4.title')
              }}</h3>
              <i18n path="partner.success.nextSteps.step4.text" tag="p">
                <em place="boldContent">{{
                  $t('partner.success.nextSteps.step4.boldContent')
                }}</em>
              </i18n>
            </div>
            <div class="form-section__para">
              <h3 class="form-section__para__title">{{
                $t('partner.success.nextSteps.step5.title')
              }}</h3>
              <i18n path="partner.success.nextSteps.step5.text" tag="p">
                <em place="boldContent">{{
                  $t('partner.success.nextSteps.step5.boldContent')
                }}</em>
              </i18n>
            </div>
          </template>
        </FormSectionHeader>
      </template>
    </PageSection>
    <TheContact container="partner" />
    <TheFooter />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import {
    successPage,
    formatDate,
    removeLocalStorageUserData,
  } from '@/mixins/successPageMixins';

  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import TheContact from '@/components/ingOrangeJuice/Structure/TheContact';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';

  export default {
    name: 'IngPartnerSuccessPage',
    components: {
      TheHeader,
      PageHeader,
      PageSection,
      FormSectionHeader,
      TheContact,
      TheFooter,
    },
    mixins: [successPage, formatDate, removeLocalStorageUserData],
    metaInfo() {
      return {
        title: this.$t('borrowerSignupPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$t('borrowerSignupPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$t('borrowerSignupPage.metaDescription'),
          },
        ],
      };
    },
    data() {
      return {
        mappingId: null,
        revenueData: null,
        revenue: null,
        partnerCompanyName: null,
        partnerFirstName: null,
        partnerLastName: null,
        partnerEmail: null,
        salutation: null,
        firstName: null,
        lastName: null,
        dob: null,
        email: null,
        mobilePhone: null,
        userAddress: null,
        loanAmount: null,
        length: null,
        companyName: '',
        dateFounded: null,
        address: null,
        nationality: null,
        loanPurpose: null,
        legalForm: null,
        billingMethod: null,
        userData: {
          type: Object,
          default: () => {},
        },
      };
    },
    computed: {
      ...mapGetters('signupForm', ['isPrechecksPassed']),
      getPreviousYears: function () {
        return {
          currentYearMinusOne: this.currentYearMinusOne,
          currentYearMinusTwo: this.currentYearMinusTwo,
        };
      },
    },
    beforeMount() {
      this.loadUserData();
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .partner-success-page {
    .form-section {
      &__unordered-list {
        margin-top: px2rem(18px);
        padding-bottom: 0px;
        margin-left: px2rem(25px);
        font-size: px2rem(18px);
        line-height: px2rem(27px);
        &__item {
          &::marker {
            font-size: px2rem(14px);
          }
        }
      }

      &__para {
        font-size: px2rem(18px);
        margin: 19px 0 27px;
        padding-bottom: 0;
        &__title {
          font-size: px2rem(28px);
          color: $Primary-Orange;
          padding-bottom: px2rem(3px);
        }
      }
    }
    .data-section {
      display: flex;
      column-gap: 3%;
      row-gap: 48px;
      flex-wrap: wrap;
      padding-bottom: 48px;
      &:last-child {
        padding-bottom: 0;
      }
      &__list {
        flex-basis: 100%;
        @include mq-size(xl) {
          flex-basis: 48.5%;
        }
      }
      &__address {
        white-space: pre-line;
      }
      &__row {
        display: flex;
        column-gap: 3%;
        flex-wrap: wrap;
        @include mq-size(s) {
          column-gap: 3%;
        }
        @include mq-size(xl) {
          column-gap: 5%;
        }
        &--divider {
          padding-top: 24px;
        }
        &--header {
          padding-bottom: 8px;
          @include mq-size(s) {
            text-align: right;
          }
        }
        &--header#{&} span {
          font-size: 19px;
          line-height: 28px;
          font-weight: 700;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          flex-basis: 100%;
          word-break: break-all;
          @include mq-size(s) {
            flex-basis: 48.5%;
          }
          @include mq-size(xl) {
            flex-basis: 47.5%;
          }
          &:first-child {
            @include mq-size(s) {
              text-align: right;
            }
          }
          &:last-child {
            font-weight: 700;
            padding-bottom: 5px;
            min-height: 24px;
            @include mq-size(s) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
