<template>
  <ing-text-input
    v-model="userStreet"
    :label="label"
    :error="errors.userStreet && $t('forms.errors.' + errors.userStreet)"
    :displayErrorIfDirty="!submitClicked"
    name="UserStreet"
    data-qa-id="applicant-street-name-and-number"
    @focus="onFocus"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTextInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.userStreet.label');
      },
      userStreet: {
        get() {
          return this.$store.state.signupForm.userStreet;
        },
        set(value) {
          this.SET_USER_STREET(value);
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_USER_STREET']),
      onFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
