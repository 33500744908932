<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('signers.companyDetails.businessAddress') }}
      </h3>
      <TextInput
        v-model="street"
        :label="$t('signers.form.address.street')"
        :error="getError('street')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="streetNumber"
        inputSize="xs"
        :label="$t('signers.form.address.houseNumber')"
        :error="getError('streetNumber')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="zip"
        inputSize="xs"
        :label="$t('signers.form.address.postalCode')"
        :error="getError('zip')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="city"
        inputSize="m"
        :label="$t('signers.form.address.city')"
        :error="getError('city')"
        :displayErrorIfDirty="displayError()"
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  export default {
    components: {
      TextInput,
      FormSubSection,
    },
    computed: {
      street: {
        get() {
          return this.company.street;
        },
        set(value) {
          this.SET_COMPANY_STREET(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      streetNumber: {
        get() {
          return this.company.streetNumber;
        },
        set(value) {
          this.SET_COMPANY_STREET_NUMBER(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      zip: {
        get() {
          return this.company.zip;
        },
        set(value) {
          this.SET_COMPANY_ZIP(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      city: {
        get() {
          return this.company.city;
        },
        set(value) {
          this.SET_COMPANY_CITY(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_ZIP',
        'SET_COMPANY_CITY',
        'SET_COMPANY_STREET',
        'SET_COMPANY_STREET_NUMBER',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
      getError(field) {
        return (
          this.errors.company &&
          this.errors.company[field] &&
          this.$t('forms.errors.' + this.errors.company[field])
        );
      },
      displayError() {
        return !this.submitClicked && !this.companySubmitClicked;
      },
    },
  };
</script>
