<template>
  <div>
    <p class="ing-offer-explanation">
      {{ $t('ingOfferPage.offerDetailsSection.p') }}
    </p>
    <button
      class="ing-medium-button button-fixed-width"
      type="button"
      @click="handleAcceptOffer"
    >
      {{ $t('ingOfferPage.offerDetailsSection.button') }}
    </button>
    <div :class="{ active: errorMessage }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p class="error-message" v-html="errorMessage" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapState('offer', ['error', 'success']),
      errorMessage() {
        const serverError = this.error.status;
        if (serverError) {
          if (serverError === 401) {
            return '';
          } else if (serverError >= 400 && serverError < 500) {
            return this.$t('forms.signupForm.errors.400');
          } else if (serverError >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
    methods: {
      ...mapActions('offer', ['acceptOffer', 'getOfferDetails']),
      ...mapMutations('offer', ['SET_LOADING', 'SET_CURRENT_STEP']),
      async handleAcceptOffer() {
        this.SET_LOADING(true);
        await this.acceptOffer();
        if (!Object.keys(this.error).length) {
          await this.getOfferDetails();
          this.SET_CURRENT_STEP(2);
        }
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../../sass/custom/variables';
  .ing-offer-explanation {
    margin: 40px 0 20px 0;
    font-size: 14px;
    line-height: 18px;
    color: $ing-gray-advantages;
  }
  .button-fixed-width {
    max-width: 225px;
    width: fit-content;
    padding: 5px 10px;
  }
</style>
