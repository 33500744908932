<template>
  <div class="partner-form">
    <IngFormLoader key="partnerCheckLoader" storeModule="partner" />
    <TextInput
      v-model="partnerCompanyName"
      :label="$t('formFields.companyName.label')"
      :error="getError('partnerCompanyName')"
      :displayErrorIfDirty="!submitClicked"
      class="partner-company-name"
      name="PartnerCompanyName"
      data-qa-id="company-name"
    />
    <TextInput
      v-model="partnerFirstName"
      :label="$t('formFields.firstName.label')"
      :error="getError('partnerFirstName')"
      :displayErrorIfDirty="!submitClicked"
      name="PartnerFirstName"
      data-qa-id="first-name"
    />
    <TextInput
      v-model="partnerLastName"
      :label="$t('formFields.lastName.label')"
      :error="getError('partnerLastName')"
      :displayErrorIfDirty="!submitClicked"
      name="PartnerLastName"
      data-qa-id="last-name"
    />
    <TextInput
      v-model="partnerEmail"
      :label="$t('formFields.email.label')"
      :error="getError('partnerEmail')"
      :displayErrorIfDirty="!submitClicked"
      name="PartnerEmail"
      inputmode="email"
      data-qa-id="email"
    />
    <IngPartnerSubmit @success="$emit('partner-logged-in')" />
  </div>
</template>

<script>
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import IngPartnerSubmit from './IngPartnerSubmit';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import { mapState, mapMutations } from 'vuex';

  export default {
    name: 'IngPartnerLogin',
    components: {
      IngFormLoader,
      TextInput,
      IngPartnerSubmit,
    },
    data() {
      return {
        partner: {
          method: '',
          consent: '',
        },
      };
    },
    computed: {
      partnerCompanyName: {
        get() {
          return this.$store.state.partner.partnerCompanyName;
        },
        set(value) {
          this.SET_PARTNER_COMPANY_NAME(value);
        },
      },
      partnerFirstName: {
        get() {
          return this.$store.state.partner.partnerFirstName;
        },
        set(value) {
          this.SET_PARTNER_FIRST_NAME(value);
        },
      },
      partnerLastName: {
        get() {
          return this.$store.state.partner.partnerLastName;
        },
        set(value) {
          this.SET_PARTNER_LAST_NAME(value);
        },
      },
      partnerEmail: {
        get() {
          return this.$store.state.partner.partnerEmail;
        },
        set(value) {
          this.SET_PARTNER_EMAIL(value);
        },
      },
      ...mapState('partner', ['validationErrors', 'submitClicked']),
    },
    methods: {
      getError(field) {
        return (
          this.validationErrors[field] &&
          this.$t('forms.errors.' + this.validationErrors[field])
        );
      },
      ...mapMutations('partner', [
        'SET_PARTNER_COMPANY_NAME',
        'SET_PARTNER_FIRST_NAME',
        'SET_PARTNER_LAST_NAME',
        'SET_PARTNER_EMAIL',
      ]),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .partner-form {
    @include mq-size(s) {
      width: 86.6%;
    }
    @include mq-size(m) {
      width: 57.1%;
    }
    @include mq-size(xl) {
      width: 48.8%;
    }
  }
</style>
