import { isEmpty } from '../helpers/string';
import { isDatePastExcludingToday, isInvalidDate } from '../helpers/date';

const FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export default function ValidateIdentificationExpiryDate(
  identificationExpiryDate
) {
  if (isEmpty(identificationExpiryDate)) {
    return { identificationExpiryDate: 'IdentificationExpiryDateIsRequired' };
  }
  if (isInvalidDate(identificationExpiryDate, FORMAT)) {
    return { identificationExpiryDate: 'IdentificationExpiryDateIsNotCorrect' };
  }
  if (isDatePastExcludingToday(identificationExpiryDate, FORMAT)) {
    return {
      identificationExpiryDate: 'IdentificationExpiryDateMustBeFuture',
    };
  }

  return { identificationExpiryDate: undefined };
}
