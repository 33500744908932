<template>
  <div class="header-container">
    <len-icon icon="ident" iconSvg="false" class="ident-icon-size" />
    <div class="text-container">
      <h2>
        {{
          $t('signersMissingInfo.formPage.identificationTypeSection.title.h2')
        }}
      </h2>
      <p>
        {{
          $t('signersMissingInfo.formPage.identificationTypeSection.title.p1')
        }}
      </p>
      <p>
        {{
          $t('signersMissingInfo.formPage.identificationTypeSection.title.p2')
        }}
      </p>
    </div>
  </div>
</template>
<script>
  import lenIcon from '@/components/common/len-icon';
  export default {
    components: {
      lenIcon,
    },
  };
</script>
<style scoped>
  .ident-icon-size {
    width: 74px;
    height: 74px;
  }
</style>
