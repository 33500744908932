import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';
import { isEmpty, hasDigit } from '../helpers/string';

export default function ValidateCity(postalCity) {
  if (isEmpty(postalCity)) {
    return { postalCity: 'PostalCityIsRequired' };
  }
  if (hasForbiddenCharacters(postalCity)) {
    return { postalCity: 'ForbiddenCharacters' };
  }
  if (hasDigit(postalCity)) {
    return { postalCity: 'PostalCityHasNumeric' };
  }
  return { postalCity: undefined };
}
