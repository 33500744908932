<template>
  <div v-show="loading" class="loading-wrapper">
    <div class="loading" :class="{ small: small }">
      <svg class="circular">
        <circle class="path-bg" />
        <circle class="path" />
      </svg>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'PageLoader',
    props: {
      storeModule: {
        type: String,
        default: '',
      },
      manuallyShow: {
        type: Boolean,
        default: false,
      },
      small: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      loading: function () {
        const storeLoadingStatus =
          this.storeModule && this.$store.state[this.storeModule].loading;
        return storeLoadingStatus || this.manuallyShow;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/variables';
  @import '@/sass/components/common/ing.orange.juice';

  $color: $ing-orange;
  $bgColor: #dadada;
  $defaultWidth: 60px;
  $defaultBorderSize: calc(
    stripUnit($defaultWidth) * 3 / 32
  ); // base width is 32px and the base border width is 3pt

  $smallWidth: 24px;
  $smallBorderSize: calc(stripUnit($smallWidth) * 3 / 32);

  .loading-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=);
    z-index: 9999;

    .loading {
      position: relative;
      width: $defaultWidth;
      height: $defaultWidth;

      .circular {
        animation: rotate 2s linear infinite;
        height: inherit;
        position: relative;
        width: inherit;

        .path,
        .path-bg {
          cx: 50%;
          cy: 50%;
          fill: none;
          r: calc(stripUnit($defaultWidth - $defaultBorderSize) / 2);
          stroke-width: $defaultBorderSize;
          stroke-linecap: round;
        }
        .path {
          animation: dash 1.5s ease-in-out infinite;
          stroke: $color;
        }

        .path-bg {
          stroke: $bgColor;
        }
      }

      &.small {
        width: $smallWidth;
        height: $smallWidth;

        .circular {
          .path,
          .path-bg {
            r: calc(stripUnit($smallWidth - $smallBorderSize) / 2);
            stroke-width: $smallBorderSize;
          }
        }
      }
    }
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1%, 300%;
      stroke-dashoffset: 0;
    }

    50% {
      stroke-dasharray: 190%, 300%;
      stroke-dashoffset: -100%;
    }

    100% {
      stroke-dasharray: 190%, 300%;
      stroke-dashoffset: -280%;
    }
  }
</style>
