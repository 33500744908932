<template>
  <div class="contract-container">
    <div class="contract-icon">
      <a
        :download="downloadName"
        :href="contractIsAvailable ? downloadLink : null"
        ><img class="pdf-icon" src="/ing_pdf_icon.svg" alt="Download Icon"
      /></a>
      <div class="contract-text">
        <p>
          {{
            $t(
              `offer.contractSection.contractDocuments.${contractType}Contract`
            )
          }}
        </p>
      </div>
    </div>
    <div class="contract-actions">
      <ContractModal
        v-if="contractIsAvailable"
        :contract-data-string="contractData"
      />
      <a
        v-if="contractIsAvailable"
        :download="downloadName"
        :href="downloadLink"
        class="download-link"
      >
        <IngButton class="button-secondary small download-button">
          {{ $t('offer.contractSection.contracts.download') }}
        </IngButton>
      </a>
      <p v-else-if="!loading" class="unavailable-contract">
        {{ $t('offer.contractSection.contracts.missingContract') }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import ContractModal from '@/components/ingOffer/ContractModal.vue';
  export default {
    name: 'ContractBlock',
    components: { IngButton, ContractModal },
    props: {
      contractType: {
        type: String,
        required: true,
      },
    },
    computed: {
      ...mapState('offer', ['loading']),
      ...mapState('contract', [
        'credit',
        'micro_credit',
        'blanket_assignment',
        'power_of_attorney',
      ]),
      contractIsAvailable() {
        return (
          !this[this.contractType].getReqErrorCode &&
          this[this.contractType].data
        );
      },
      downloadName() {
        return this[this.contractType].fileName;
      },
      contractData() {
        return this[this.contractType].data;
      },
      downloadLink() {
        return 'data:application/octet-stream;base64,' + this.contractData;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import 'src/sass/components/common/ing.orange.juice.scss';

  .pdf-icon {
    height: 56px;
    width: 40px;
  }
  .contract-container {
    padding-bottom: 40px;
  }
  .contract-icon {
    display: flex;
    align-items: center;
    justify-content: left;
    margin: 0 0 20px 10px;
  }
  .contract-text {
    margin-left: 10px;
    font-size: 19px;
    font-weight: 700;
  }
  .contract-actions {
    display: flex;
    flex-direction: row;
  }
  .unavailable-contract {
    font-size: 16px;
    color: $Functional-Minus500;
  }
  .download-link {
    height: 36px;
    margin-left: 10px;
  }
  .download-button {
    font-size: 16px;
  }
</style>
