<template>
  <div>
    <div class="bottom-line" />
    <div class="copyright-text">
      {{
        $t('borrowerJourney.footer.copyright.p1') +
        ' ' +
        getCurrentYear +
        ' ' +
        $t('borrowerJourney.footer.copyright.p2')
      }}
    </div>
    <div class="len-footer-bottom-links">
      <a
        :href="
          VUE_APP_LENDICO_BASE_URL_CMS_API +
          $t('borrowerJourney.footer.kreditnehmer.href')
        "
        class="footer-bottom-link"
        target="_blank"
        >{{ $t('borrowerJourney.footer.kreditnehmer.text') }}</a
      >
      <div class="separator"> | </div>
      <a
        :href="
          VUE_APP_LENDICO_BASE_URL_CMS_API +
          $t('borrowerJourney.footer.dataProtection.href')
        "
        class="footer-bottom-link"
        target="_blank"
        >{{ $t('borrowerJourney.footer.dataProtection.text') }}</a
      >
      <div class="separator"> | </div>
      <a
        :href="
          VUE_APP_LENDICO_BASE_URL_CMS_API +
          $t('borrowerJourney.footer.impressum.href')
        "
        class="footer-bottom-link"
        target="_blank"
        >{{ $t('borrowerJourney.footer.impressum.text') }}</a
      >
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { getCurrentYear } from '@/mixins/currentYearMixin';
  export default {
    name: 'BorrowerDealFooter',
    mixins: [getCurrentYear],
    computed: {
      ...mapState('config', ['VUE_APP_LENDICO_BASE_URL_CMS_API']),
    },
  };
</script>

<style scoped>
  .copyright-text {
    margin-bottom: 10px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #b5b5b5;
    font-size: 14px;
    text-align: center;
  }

  .bottom-line {
    width: 100%;
    height: 1px;
    margin-bottom: 30px;
    background-color: #b5b5b5;
  }

  .len-footer-bottom-links {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .footer-bottom-link {
    margin-right: 3px;
    margin-left: 3px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #b5b5b5;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    text-decoration: none;
    text-transform: none;
  }

  .footer-bottom-link:hover {
    text-decoration: underline;
  }

  .separator {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #b5b5b5;
    font-size: 14px;
    font-weight: 400;
  }

  .footer-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: auto;
    margin-bottom: 30px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .footer-header {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #2e2e2e;
    font-size: 14px !important;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .footer-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    font-size: 14px !important;
  }

  .footer-text-process-number {
    margin-top: -1px;
    margin-right: 2px;
    margin-bottom: 0px;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #2e2e2e;
    font-size: 14px;
    font-weight: 700;
  }

  .footer-text-caption {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    color: #2e2e2e;
  }

  @media (max-width: 1100px) {
    .footer-wrapper {
      width: 80%;
      height: auto;
      margin-bottom: 20px;
      padding-right: 30px;
      padding-left: 30px;
      margin-left: auto;
      margin-right: auto;
    }
    .copyright-text {
      width: 80%;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .bottom-line {
      width: 80%;
      margin-left: auto !important;
      margin-right: auto !important;
    }
    .len-footer-bottom-links {
      width: 80%;
      margin-left: auto !important;
      margin-right: auto !important;
    }
  }

  @media (max-width: 991px) {
    .copyright-text {
      font-size: 12px;
    }
    .bottom-line {
      /* width: 100%; */
      margin-right: 0px;
      margin-bottom: 20px;
      margin-left: 0px;
      padding-right: 0px;
      padding-left: 0px;
    }
    .footer-bottom-link {
      font-size: 12px;
    }
    .separator {
      font-size: 12px;
    }
  }

  @media (max-width: 767px) {
    .copyright-text {
      font-size: 11px;
      line-height: 14px;
    }
    .len-footer-bottom-links {
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
    .footer-bottom-link {
      font-size: 11px;
      line-height: 14px;
    }
    .footer-wrapper {
      height: auto;
    }
    .footer-header {
      font-size: 13px !important;
    }
    .footer-text-process-number {
      font-size: 12px;
      line-height: 16px;
    }
    .footer-text-caption {
      font-size: 12px;
      line-height: 16px;
    }
  }

  @media (max-width: 479px) {
    .copyright-text {
      font-size: 10px;
      line-height: 12px;
    }
    .footer-bottom-link {
      font-size: 10px;
      line-height: 12px;
    }
    .separator {
      line-height: 12px;
    }
    .footer-wrapper {
      margin-bottom: 15px;
    }
    .footer-header {
      font-size: 10px !important;
      line-height: 10px !important;
    }
    .footer-text-process-number {
      font-size: 10px;
      line-height: 12px;
    }
    .footer-text-caption {
      font-size: 10px;
      line-height: 12px;
    }
  }
</style>
