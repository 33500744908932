<template>
  <div :class="{ alert: isInvalid }" class="overview-container flex--column">
    <p
      v-show="isFirstSigner"
      class="edit-overview"
      v-html="$t('signersMissingInfo.formPage.clientsSection.overview.p')"
    />
    <div class="signer-header-wrapper">
      <div>
        <h3>
          {{ genderValue }} {{ signerInformation.firstName }}
          {{ signerInformation.lastName }}
        </h3>
        <p>
          {{ roleValue }}
        </p>
      </div>
      <edit-button
        class="edit-button"
        @edit-button-clicked="$emit('edit-button-clicked')"
      />
    </div>
    <div class="signer-information-container">
      <div class="signer-column-container">
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.salutation.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ genderValue | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.title.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ titleValue | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.firstName.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.firstName | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.lastName.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.lastName | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.lastNameAtBirth.shortLabel') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.lastNameAtBirth | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.dob.shortLabel') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.birthday
                | momentjsFormat('L')
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.countryOfBirth.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.countryOfBirth
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.cityOfBirth.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.cityOfBirth | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.nationality.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.nationality
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.secondNationality.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.secondNationality
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.email.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.email | emptyValueReplacer }}
          </p>
        </div>
      </div>
      <div class="signer-column-container">
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.userCurrentAddress.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ currentAddress | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.postalAddress.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ postalAddress | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.phone.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.mobilePhone | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.identificationIdType.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ idTypevalue | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationIdNumber.ID') }}/{{
                $t('forms.signupForm.identificationIdNumber.PASSPORT')
              }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{ signerInformation.identificationIdNumber | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationIssuingAuthority.label') }}:
            </em>
            <tooltip
              key="identification-issuing-authority"
              :tooltipMessage="
                $t('forms.signupForm.identificationIssuingAuthority.tooltip')
              "
              messageClass="issuing-authority-tooltip-message"
            />
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationIssuingAuthority
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationPlaceOfIssue.label') }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationPlaceOfIssue
                | convertCountryIsoToName
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationIssuingDate.label') }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationIssuingDate
                | momentjsFormat('L')
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>
              {{ $t('forms.signupForm.identificationExpiryDate.label') }}:
            </em>
          </p>
          <p class="col-sm-6">
            {{
              signerInformation.identificationExpiryDate
                | momentjsFormat('L')
                | emptyValueReplacer
            }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.taxNumber.label') }}:</em>
            <tooltip
              key="tax-number"
              :tooltipMessage="$t('forms.signupForm.taxNumber.tooltip')"
              messageClass="tax-number-tooltip-message"
            />
          </p>
          <p class="col-sm-6">
            {{ signerInformation.taxNumber | emptyValueReplacer }}
          </p>
        </div>
      </div>
    </div>
    <p
      v-show="shouldShowMissingUBOsSentence"
      class="missing-ubo-sentence col-md-8 col-sm-9 col-xs-12"
    >
      {{
        $t('signersMissingInfo.formPage.clientsSection.overview.missingUbos')
      }}
      <a :href="'mailto:' + contactEmail">{{ contactEmail }}</a>
    </p>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { genderValues } from '@/validators/helpers/gender';
  import { idTypeValues } from '@/validators/helpers/identificationIdType';
  import { titleValues } from '@/validators/helpers/title';
  import { roleValues } from '@/validators/helpers/role';
  import EditButton from '@/components/signers/edit-button';
  import Tooltip from '@/components/signers/signers-tooltip';
  import {
    isPartnerChannel,
    isRegularLoanRequest,
  } from '@/mixins/signersMixin';
  export default {
    components: {
      EditButton,
      Tooltip,
    },
    mixins: [isPartnerChannel, isRegularLoanRequest],
    props: {
      signerId: {
        type: String,
        default: '',
      },
      signerIndex: {
        type: Number,
        default: 0,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        genderValues,
        idTypeValues,
        titleValues,
        roleValues,
      };
    },
    computed: {
      ...mapState('signers', ['signers']),
      signerInformation() {
        return this.signers[this.signerId];
      },
      isFirstSigner() {
        return this.signerIndex === 0;
      },
      isLastSigner() {
        return this.signerIndex === Object.keys(this.signers).length - 1;
      },
      shouldShowMissingUBOsSentence() {
        return (
          this.isLastSigner &&
          (this.isRegularLoanRequest || this.isPartnerChannel)
        );
      },
      contactEmail() {
        if (this.isPartnerChannel) {
          return this.$t('partnerContactEmail');
        } else {
          return this.$t('companiesEmail');
        }
      },
      currentAddress() {
        if (this.signerInformation.street) {
          return `${this.signerInformation.street} ${this.signerInformation.streetNumber}, ${this.signerInformation.zip} ${this.signerInformation.city}, ${this.signerInformation.country}`;
        } else {
          return null;
        }
      },
      postalAddress() {
        if (this.signerInformation.postalStreet) {
          return `${this.signerInformation.postalStreet} ${this.signerInformation.postalStreetNumber}, ${this.signerInformation.postalZip} ${this.signerInformation.postalCity}, ${this.signerInformation.postalCountry}`;
        } else if (this.signerInformation.homeAddressIsPostalAddress) {
          return this.currentAddress;
        } else {
          return null;
        }
      },
      genderValue() {
        return Object.keys(this.genderValues).find(
          (key) => this.genderValues[key] === this.signerInformation.gender
        );
      },
      titleValue() {
        return Object.keys(this.titleValues).find(
          (key) => this.titleValues[key] === this.signerInformation.title
        );
      },
      idTypevalue() {
        return Object.keys(this.idTypeValues).find(
          (key) =>
            this.idTypeValues[key] ===
            this.signerInformation.identificationIdType
        );
      },
      roleValue() {
        let deRoleValues = this.signerInformation.roles.map((role) => {
          return Object.keys(this.roleValues).find(
            (key) => this.roleValues[key] === role
          );
        });
        return deRoleValues.filter((role) => !!role).join(', ');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/_settings.scss';
  .edit-overview {
    padding-bottom: 40px;
    color: $mediumGrey;
  }
  .field-label {
    display: inline-block;
    vertical-align: top;
  }
  .signer-overview-container {
    padding: 40px 0;
  }
  .signer-header-wrapper {
    display: flex;
    justify-content: space-between;
    vertical-align: center;
    padding-bottom: 20px;
  }
  h3 {
    font-size: 16px;
    font-weight: bold;
    color: $darkerGrey;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 21px;
    color: $darkerGrey;
  }
  .missing-ubo-sentence {
    padding: 40px 40px 0 0;
    a {
      color: $petroleum;
    }
  }
  .position {
    margin-bottom: 40px;
  }
  .signer-information-container {
    display: flex;
  }
  .signer-column-container {
    flex-grow: 1;
    word-break: break-all;
  }

  .signer-information-container {
    justify-content: space-between;
  }
  .overview-container {
    &.alert {
      border: solid 1px $red;
    }
  }
  @media (max-width: 990px) {
    .signer-information-container {
      flex-wrap: wrap;
    }
  }
  @media (max-width: 1024px) {
    .signer-information-container {
      flex-direction: column;
    }
  }
  @media (max-width: 476px) {
    .signer-header-wrapper {
      flex-direction: column;
      justify-content: space-between;
    }
    .edit-button {
      padding-top: 10px;
    }
  }
</style>
