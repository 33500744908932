<template>
  <div v-if="searchingCompany" class="lds-rolling" />
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('signupForm', ['searchingCompany']),
    },
  };
</script>
<style scoped lang="scss">
  @-webkit-keyframes lds-rolling {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .lds-rolling {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 20px;
    height: 20px;
    border: 0.15rem solid #29bdba;
    border-top-color: transparent;
    border-radius: 50%;
    -webkit-animation: lds-rolling 1s linear infinite;
    animation: lds-rolling 1s linear infinite;
  }
</style>
