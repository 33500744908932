<template>
  <ing-country-input
    :label="$t('forms.signupForm.countryOfIncorporation.label')"
    :initialCountry="company.countryOfIncorporation"
    :error="
      errors.company &&
      errors.company.countryOfIncorporation &&
      $t('forms.errors.' + errors.company.countryOfIncorporation)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    :preferredCountries="['DE']"
    name="country-of-incorporation"
    data-unit-test="ing-country-of-incorporation-field"
    @change="onChange"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngCountryInput from '@/components/ing/ing-country-input';

  export default {
    components: { IngCountryInput },
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      onChange(iso) {
        this.SET_COUNTRY_OF_INCORPORATION(iso);
      },
      ...mapMutations('signers', ['SET_COUNTRY_OF_INCORPORATION']),
    },
  };
</script>
