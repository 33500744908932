<template>
  <svg viewBox="0 0 55 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18 37.4281L0 19.4281L2.828 16.6001L19.414 33.1861L52 0.600098L54.828 3.4281L20.828 37.4281C20.046 38.2101 18.782 38.2101 18 37.4281Z"
      fill="#FF6200"
    />
  </svg>
</template>
<script>
  export default {
    name: 'IconTick',
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  svg {
    width: px2rem(55);
    height: px2rem(39);
  }
</style>
