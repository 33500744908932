<template>
  <div class="ing-wrapper">
    <TheHeader />
    <main class="row centered-container">
      <form
        class="ing-ident-form-container col-lg-9 col-md-11 col-xs-11"
        @submit.prevent="submit"
      >
        <ing-form-loader storeModule="ident" />
        <section class="first-container">
          <ing-ident-information class="col-lg-12 col-md-12" />
        </section>
        <section>
          <ing-ident-form />
        </section>
        <section class="submit-container">
          <ing-ident-submit-form />
        </section>
        <section class="last-container">
          <ing-ident-privacy-policy class="col-lg-12 col-md-12" />
        </section>
      </form>
    </main>
    <TheFooter />
  </div>
</template>

<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import IngIdentInformation from '@/components/ing/ident/ing-ident-information';
  import IngIdentForm from '@/components/ing/ident/ing-ident-form';
  import IngIdentSubmitForm from '@/components/ing/ident/ing-ident-submit-form';
  import IngIdentPrivacyPolicy from '@/components/ing/ident/ing-ident-privacy-policy';
  export default {
    name: 'IngIdentPage',
    components: {
      TheHeader,
      TheFooter,
      IngFormLoader,
      IngIdentInformation,
      IngIdentForm,
      IngIdentSubmitForm,
      IngIdentPrivacyPolicy,
    },
    props: {
      referenceNumber: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('ident', ['success', 'postIdentRedirectUrl']),
      ...mapGetters('ident', ['isValid']),
    },
    mounted() {
      this.setReferenceNumber();
    },
    methods: {
      ...mapMutations('ident', [
        'SET_REFERENCE_NUMBER',
        'SET_REFERENCE_NUMBER_EDITABLE',
        'SET_SUBMIT_CLICKED',
        'SET_LOADING',
        'CLEAR_REQUEST_ERROR',
      ]),
      ...mapActions('ident', ['startIdentCase']),
      setReferenceNumber() {
        this.SET_REFERENCE_NUMBER(this.referenceNumber);
        if (this.referenceNumber) {
          this.SET_REFERENCE_NUMBER_EDITABLE(false);
        }
      },
      async submit() {
        this.CLEAR_REQUEST_ERROR();
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        this.SET_LOADING(true);
        await this.startIdentCase();
        if (this.success) {
          this.redirect();
        }
        this.SET_LOADING(false);
      },
      redirect: function () {
        window.location.href = this.postIdentRedirectUrl;
      },
    },
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  .centered-container {
    flex-grow: 1;
    margin-top: 53px;
    text-align: center;
  }

  section {
    margin-top: 60px;
  }

  .first-container {
    margin-top: 0;
  }

  .submit-container {
    margin-top: 0;
    width: 100%;
    padding: 20px 10px;
  }

  .last-container {
    margin-bottom: 60px;
  }

  :deep(.icon-svg svg) {
    width: 150px;
    height: 150px;
  }
</style>
