<template>
  <div class="display--inline-block grid__item text-block">
    <p>
      {{ $t('ingSignersMissingInfo.privacyPolicy.preHighlightedText') }}
      <em>{{ $t('ingSignersMissingInfo.privacyPolicy.highlightedText') }}</em>
      {{ $t('ingSignersMissingInfo.privacyPolicy.preLinkPageText') }}
    </p>
  </div>
</template>
<script>
  export default {};
</script>

<style scoped>
  p {
    color: #808080;
  }
  .ing-link,
  p {
    font-size: 12px;
  }
</style>
