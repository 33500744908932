<template>
  <footer class="ing-footer">
    <div class="footer-links-wrapper">
      <div class="internal-links-wrapper">
        <a
          :aria-label="$t('borrowerJourney.footer.cookieSettings.ariaLabel')"
          href="#uc-corner-modal-show"
          class="internal-link"
          @click="openCookieBanner"
          >{{ $t('borrowerJourney.footer.cookieSettings.text') }}</a
        >
        <a
          :href="$t('ingFooterLinks.agb')"
          target="_blank"
          class="internal-link"
          >{{ $t('borrowerJourney.footer.kreditnehmer.text') }}</a
        >
        <a
          :href="$t('ingFooterLinks.dataProtection')"
          target="_blank"
          class="internal-link"
          >{{ $t('borrowerJourney.footer.dataProtection.text') }}</a
        >
        <a
          :href="$t('ingFooterLinks.impressum')"
          target="_blank"
          class="internal-link"
          >{{ $t('borrowerJourney.footer.impressum.text') }}</a
        >
      </div>
      <div v-if="showSocialMediaLinks" class="social-media-wrapper">
        <p class="follow-us">
          {{ $t('ingSocialMedia') }}
        </p>
        <div class="icon-wrapper">
          <a
            :href="$t('ingFooterLinks.socialNetworks.facebookLink')"
            target="_blank"
            rel="noopener noreferrer"
            class="social-media-link"
          >
            <img src="/fb_icon.png" alt="Facebook Icon" />
          </a>
          <a
            :href="$t('ingFooterLinks.socialNetworks.flckrLink')"
            target="_blank"
            rel="noopener noreferrer"
            class="social-media-link"
          >
            <img src="/flckr_icon.png" alt="Flckr Icon" class="social-image" />
          </a>
          <a
            :href="$t('ingFooterLinks.socialNetworks.linkedInLink')"
            target="_blank"
            rel="noopener noreferrer"
            class="social-media-link"
          >
            <img
              src="/linkedin_icon.png"
              alt="Linkedin Icon"
              class="social-image"
            />
          </a>
          <a
            :href="$t('ingFooterLinks.socialNetworks.slideshareLink')"
            target="_blank"
            rel="noopener noreferrer"
            class="social-media-link"
          >
            <img
              src="/slideshare_icon.png"
              alt="Slideshare Icon"
              class="social-image"
            />
          </a>
          <a
            :href="$t('ingFooterLinks.socialNetworks.twitterLink')"
            target="_blank"
            rel="noopener noreferrer"
            class="social-media-link"
          >
            <img
              src="/twitter_icon.png"
              alt="Twitter Icon"
              class="social-image"
            />
          </a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
    props: {
      showSocialMediaLinks: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      openCookieBanner() {
        window.dispatchEvent(
          new CustomEvent('ingde-cookie-consent:open', { detail: { level: 1 } })
        );
      },
    },
  };
</script>
