var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.process.env.VUE_APP_LENDICO_BASE_URL_CONTENT)?_c('div',[(!_vm.ratio)?_c('img',{staticClass:"image-component",class:[_vm.align ? 'horizontal-align--' + _vm.align : ''],style:({
      width: _vm.width ? _vm.width + 'px' : '100%',
      height: _vm.Height ? _vm.Height + 'px' : 'auto',
      margin: _vm.margin ? _vm.margin : '0',
    }),attrs:{"src":_vm.srcUrl}}):_vm._e(),(_vm.ratio)?_c('div',{staticClass:"image-component",class:[_vm.align ? 'horizontal-align--' + _vm.align : ''],staticStyle:{"background-repeat":"no-repeat","background-size":"cover","background-position":"center"},style:({
      'background-image': 'url(' + _vm.srcUrl + ')',
      'background-position': _vm.position,
      height: _vm.Height ? _vm.Height + 'px' : 'inherit',
    })}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }