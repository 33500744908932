<template>
  <ing-text-input
    v-model="userPostcodeDe"
    :label="label"
    :error="
      errors.userPostcodeDe && $t('forms.errors.' + errors.userPostcodeDe)
    "
    :displayErrorIfDirty="!submitClicked"
    name="userPostcodeDe"
    inputmode="numeric"
    data-qa-id="applicant-postcode"
    @focus="onFocus"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTextInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.userPostcodeDe.label');
      },
      userPostcodeDe: {
        get() {
          return this.$store.state.signupForm.userPostcodeDe;
        },
        set(value) {
          this.SET_USER_POSTCODE_DE(value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_USER_POSTCODE_DE']),
      onFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
