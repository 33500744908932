<template>
  <div>
    <ing-representative-information-header />
    <div class="ing-fields-container">
      <div class="col-sm-6 ing-column-left">
        <div ref="salutation-field">
          <ing-salutation-field />
        </div>
        <ing-firstname-field />
        <ing-lastname-field />
        <ing-date-of-birth-field />
        <ing-nationality-field />
      </div>
      <div class="col-sm-6 ing-column-right">
        <ing-mobile-phone-field />
        <ing-email-field />
        <ing-user-street-field />
        <ing-user-postcode-field />
        <ing-user-city-field />
      </div>
    </div>
  </div>
</template>

<script>
  import IngRepresentativeInformationHeader from '@/components/ing/forms/ing-representative-information/ing-representative-information-header';
  import IngSalutationField from '@/components/ing/forms/ing-representative-information/ing-salutation-field';
  import IngFirstnameField from '@/components/ing/forms/ing-representative-information/ing-firstname-field';
  import IngLastnameField from '@/components/ing/forms/ing-representative-information/ing-lastname-field';
  import IngDateOfBirthField from '@/components/ing/forms/ing-representative-information/ing-date-of-birth-field';
  import IngNationalityField from '@/components/ing/forms/ing-representative-information/ing-nationality-field';
  import IngMobilePhoneField from '@/components/ing/forms/ing-representative-information/ing-mobile-phone-field';
  import IngEmailField from '@/components/ing/forms/ing-representative-information/ing-email-field';
  import IngUserStreetField from '@/components/ing/forms/ing-representative-information/ing-user-street-field';
  import IngUserPostcodeField from '@/components/ing/forms/ing-representative-information/ing-user-postcode-field';
  import IngUserCityField from '@/components/ing/forms/ing-representative-information/ing-user-city-field';

  export default {
    components: {
      IngRepresentativeInformationHeader,
      IngSalutationField,
      IngFirstnameField,
      IngLastnameField,
      IngDateOfBirthField,
      IngNationalityField,
      IngMobilePhoneField,
      IngEmailField,
      IngUserStreetField,
      IngUserPostcodeField,
      IngUserCityField,
    },
  };
</script>
