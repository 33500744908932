import Window from '@/common/window';
import findKey from 'lodash/findKey';
import {
  applicantSignupFormsValidator,
  applicantFormWithBookKeepingValidator,
  partnerSignupFormsValidator,
  ingApplicantSignupFormsValidator,
  ingApplicantFormWithBookKeepingValidator,
} from '@/validators';

export function isValid(state) {
  return !findKey(state.errors);
}

export function isPartner(state) {
  return state.submitter === 'partner';
}

export function isBookkeepingVisible(state) {
  return state.isBookkeepingStandardVisible;
}
export function isPrechecksPassed(state) {
  return state.applicationStatus === 'OK';
}
export function getProspectId(state) {
  return state.prospectId;
}

export function isIngAntrag() {
  // TODO: Window.location.href.includes('ing-') needs to adjust after moving to ING domain
  return Window && Window.location
    ? Window.location.href.includes('ing-') ||
        Window.location.href.includes('offerId')
    : false;
}

export function getStateValidator(state) {
  if (isPartner(state)) {
    return partnerSignupFormsValidator;
  } else if (isIngAntrag() && isBookkeepingVisible(state)) {
    return ingApplicantFormWithBookKeepingValidator;
  } else if (isIngAntrag()) {
    return ingApplicantSignupFormsValidator;
  } else if (isBookkeepingVisible(state)) {
    return applicantFormWithBookKeepingValidator;
  } else {
    return applicantSignupFormsValidator;
  }
}
