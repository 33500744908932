import moment from 'moment';
import { isInvalidDate } from '@/validators/helpers/date';

export const isBeforeFirstDateFounded = (date, firstDate, format) => {
  if (isInvalidDate(date, format)) {
    return false;
  }
  const parsed = moment(date, format, true);
  const parsedFirstDate = moment(firstDate, format, true);
  return parsed.isBefore(parsedFirstDate, 'day');
};
