export default function ValidateBookkeepingStandard(bookkeepingStandard) {
  if (!bookkeepingStandard) {
    return { bookkeepingStandard: 'BookkeepingStandardIsRequired' };
  }
  const keys = [
    'Bilanz (HGB/Steuerrecht)',
    'Einnahmenüberschussrechnung',
    'Unbekannt',
  ];
  if (keys.indexOf(bookkeepingStandard) === -1) {
    return { bookkeepingStandard: 'BookkeepingStandardIsNotCorrect' };
  }

  return { bookkeepingStandard: undefined };
}
