<template>
  <div :class="{ open: open }" class="dropdown-custom btn-group" role="group">
    <button
      v-if="withButton"
      type="button"
      class="toggler toggler-select btn btn-default dropdown-toggle"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
      <span v-if="isIngDropdown">
        <ing-down-arrow />
      </span>
      <span v-else>
        <len-down-arrow />
      </span>
    </button>
    <ul class="dropdown-menu">
      <li
        v-for="item in optionsToDisplay"
        :key="item.id"
        class="item-wrapper"
        :class="{ 'selected-item': item.id === selectedItem }"
        @click="onSelectOption(item.id)"
      >
        <a class="item" v-html="item.name" />
      </li>
    </ul>
  </div>
</template>

<script>
  import LenDownArrow from '@/components/common/len-down-arrow';
  import IngDownArrow from '@/components/ing/ing-down-arrow';
  export default {
    components: { LenDownArrow, IngDownArrow },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      options: {
        type: Array,
        required: true,
      },
      withButton: {
        type: Boolean,
        default: false,
      },
      maxOptions: {
        type: Number,
        default: 0,
      },
      isIngDropdown: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        selectedItem: null,
      };
    },
    computed: {
      optionsToDisplay() {
        if (this.maxOptions) {
          return this.options.slice(0, this.maxOptions);
        }
        return this.options;
      },
    },
    watch: {
      options: function () {
        if (this.options.length === 0) {
          this.selectedItem = null;
        }
      },
    },
    methods: {
      onSelectOption(id) {
        this.selectedItem = id;
        this.$emit('select', id);
      },
    },
  };
</script>
<style scoped>
  .dropdown-menu a.item {
    white-space: normal;
    cursor: pointer;
  }

  .dropdown.company-lookup ul.dropdown-menu {
    max-height: 200px;
    overflow-y: scroll;
  }

  .ing-dropdown.company-lookup ul.dropdown-menu {
    max-height: 152px;
    overflow-y: scroll;
  }
</style>
