var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex--between options-container addMaterialDesign"},[_c('div',{staticClass:"option-box"},[_c('span',{staticClass:"display--flex text-padding"},[_c('len-radio',{key:"AUTOMATION",attrs:{"id":"AUTOMATION","label":_vm.$t(
              'signersMissingInfo.formPage.identificationTypeSection.form.option1a'
            ),"value":"AUTOMATION","name":"identType"},model:{value:(_vm.identOption),callback:function ($$v) {_vm.identOption=$$v},expression:"identOption"}})],1),_c('p',{staticClass:"text-padding"},[_vm._v(" "+_vm._s(_vm.$t( 'signersMissingInfo.formPage.identificationTypeSection.form.option1b' ))+" ")])]),_c('div',{staticClass:"option-box flex--between flex--column"},[_c('span',{staticClass:"display--flex text-padding"},[_c('len-radio',{key:"MANUAL",attrs:{"id":"MANUAL","label":_vm.$t(
              'signersMissingInfo.formPage.identificationTypeSection.form.option2a'
            ),"value":"MANUAL","name":"identType"},model:{value:(_vm.identOption),callback:function ($$v) {_vm.identOption=$$v},expression:"identOption"}})],1),_c('p',{staticClass:"text-padding"},[_vm._v(" "+_vm._s(_vm.$t( 'signersMissingInfo.formPage.identificationTypeSection.form.option2b' ))+" ")]),_c('div',{staticClass:"display--flex text-padding option-2-warning"},[_c('len-icon',{attrs:{"icon":"info"}}),_c('span',{staticClass:"option-2-text-wrapper"},[_c('p',{staticClass:"option-2-text",domProps:{"innerHTML":_vm._s(
              _vm.$t(
                'signersMissingInfo.formPage.identificationTypeSection.form.option2warning'
              )
            )}}),_c('a',{staticClass:"option-2-text mail-contact",attrs:{"href":'mailto:' + _vm.contactEmail}},[_vm._v(_vm._s(_vm.contactEmail))])])],1)])]),(_vm.displayError)?_c('span',{staticClass:"validation validation-text"},[_vm._v(" "+_vm._s(_vm.errors.identificationProcess && _vm.$t('forms.errors.' + _vm.errors.identificationProcess))+" ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }