import { isEmpty, hasWrongLength, isNotNumeric } from '../helpers/string';
import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';

export default function ValidateZip(zip) {
  if (isEmpty(zip)) {
    return { zip: 'ZipIsRequired' };
  }
  if (isNotNumeric(zip)) {
    return { zip: 'ZipIsNAN' };
  }
  if (hasWrongLength(zip, 5)) {
    return { zip: 'ZipIsInvalidLength' };
  }
  if (hasForbiddenCharacters(zip)) {
    return { zip: 'ForbiddenCharacters' };
  }

  return { zip: undefined };
}
