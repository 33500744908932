import url from 'url';

function getParsedUrl() {
  if (typeof window !== 'undefined') {
    const currentUrl = window.location.href;
    return url.parse(currentUrl, true);
  }
  return null;
}

export const getUrlParameterByName = {
  methods: {
    getUrlParameterByName(parameterName) {
      const parsedUrl = getParsedUrl();
      if (parsedUrl) {
        const parametersObject = parsedUrl.query;
        return parametersObject[parameterName] || null;
      }
      return null;
    },
  },
};

export const updateUrlWithParams = {
  methods: {
    updateUrlWithParams(newParameters) {
      const parsedUrl = getParsedUrl();
      if (parsedUrl) {
        const parametersObject = parsedUrl.query;
        Object.assign(parametersObject, newParameters);
        parsedUrl.search = new URLSearchParams(parametersObject).toString();
        window.history.pushState(null, '', url.format(parsedUrl));
      }
    },
  },
};
