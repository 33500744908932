<template>
  <div class="ing-progress-bar-container col-lg-7 col-md-9 col-sm-10 col-xs-12">
    <div
      class="step-container first-step-container"
      :class="{ active: shouldShowStep1 }"
      @click="setCurrentStep(1)"
    >
      {{ $t('ingOfferPage.progressBar.firstStep') }}
    </div>
    <div class="step-arrow" />
    <div
      class="step-container second-step-container"
      :class="{ active: shouldShowStep2, disabled: !offerIsResolved }"
      @click="setCurrentStep(2)"
    >
      {{ $t('ingOfferPage.progressBar.secondStep') }}
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapState('offer', ['state', 'currentStep']),
      offerIsResolved() {
        return this.state !== 'OFFER_SENT';
      },

      shouldShowStep1() {
        return this.currentStep === 1;
      },
      shouldShowStep2() {
        return this.currentStep === 2;
      },
    },
    methods: {
      ...mapMutations('offer', ['SET_CURRENT_STEP']),
      setCurrentStep(step) {
        if (this.offerIsResolved) {
          this.SET_CURRENT_STEP(step);
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/variables';
  .ing-progress-bar-container {
    position: relative;
    display: flex;
    margin-bottom: 50px;
    align-items: baseline;
  }
  .step-container {
    padding: 2%;
    height: 36px;
    line-height: normal;
    cursor: pointer;
    width: fit-content;
    display: flex;
    align-items: center;
  }
  .first-step-container {
    color: $ing-gray;
    border: 1px solid $ing-gray;
    border-right: none;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  .first-step-container.active {
    color: $ing-orange;
    border: 1px solid $ing-orange;
    border-right: none;
  }
  .second-step-container {
    color: $ing-gray;
    border: 1px solid $ing-gray;
    border-left: none;
    padding-left: 6%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .second-step-container.active {
    color: $ing-orange;
    border: 1px solid $ing-orange;
    border-left: none;
  }

  .second-step-container.disabled {
    cursor: default;
  }

  .step-arrow {
    position: absolute;
    left: 190px;
    top: 5px;
    border: solid $ing-orange;
    border-width: 0 1px 1px 0;
    border-bottom-right-radius: 4px;
    display: inline-block;
    padding: 12px;
    height: 12px;
    transform: rotate(-45deg);
  }
  @media (max-width: 512px) {
    .step-container {
      font-size: 12px;
    }
    .step-arrow {
      left: 135px;
    }
  }
  @media (max-width: 300px) {
    .step-container {
      font-size: 10px;
    }
    .step-arrow {
      left: 105px;
    }
  }
</style>
