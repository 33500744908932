<template>
  <div class="ing-wrapper signer-form">
    <TheHeader />
    <div class="maintenance-page-container">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <h1 class="title">
          {{ $t('signers.maintenancePage.title') }}
        </h1>
        <p class="sub-title">
          {{ $t('signers.maintenancePage.message') }}
        </p>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  export default {
    name: 'IngClientUIMaintenancePage',
    components: {
      TheHeader,
      TheFooter,
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .maintenance-page-container {
    min-height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 840px) {
    .maintenance-page-container {
      min-height: calc(100vh - 240px);
    }
  }

  .title {
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    padding: 8rem 2rem 0rem 2rem;
    color: $Primary-Orange;
  }

  .sub-title {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    padding: 2rem;
    color: $Primary-Grey600;
  }
</style>
