<template>
  <div
    class="ing-native-select"
    :class="{ 'ing-native-select--disabled': disabled }"
  >
    <select
      class="ing-native-select__element form-control"
      :class="{ 'ing-native-select__element--error': hasError }"
      v-bind="$attrs"
      data-lpignore="true"
      :disabled="disabled"
      v-on="listeners"
    >
      <option disabled data-default value="">
        {{ placeholder }}
      </option>
      <option
        v-for="(option, index) in options"
        :key="`${option.value}${index}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <div class="ing-native-select__icon">
      <Triangle />
    </div>
  </div>
</template>

<script>
  import Triangle from '@/assets/triangle';
  import inputMixin from '@/mixins/inputMixin';

  export default {
    name: 'NativeSelect',
    components: {
      Triangle,
    },
    mixins: [inputMixin],
    inheritAttrs: false,
    props: {
      options: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: ' ',
      },
      hasError: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables.scss';

  .ing-native-select {
    position: relative;
    display: flex;
    border-radius: 4px;

    &:before {
      content: '';
      position: absolute;
      top: 1px;
      right: 1px;
      bottom: 1px;
      left: 1px;
      background: #fff;
    }

    &__icon,
    &__element {
      font-size: 16px;
      line-height: 20px;
    }

    &__icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 6px 12px;
      z-index: 0;
    }

    &__element {
      cursor: pointer;
      padding: 6px 36px 6px 12px;
      background: transparent;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
      outline: none;
      border: solid 1px #ccc;
      width: 100%;
      position: relative;
      z-index: 1;
      font-family: inherit;

      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
      /* For IE10 */
      &::-ms-expand {
        display: none;
      }

      &--error {
        border: 1px solid #ff0000;
      }
    }
  }
</style>

<style lang="scss">
  .ing-native-select--disabled {
    .ing-native-select__element,
    .ing-native-select__icon {
      color: #9d9d9d;
      cursor: default;
      opacity: 1;

      svg path {
        stroke: #9d9d9d;
      }
    }
  }
</style>
