<template>
  <div class="ing-wrapper">
    <ing-header />

    <ing-section
      :darkTheme="false"
      class="section-form thank-you-section"
      maxWidth="ing-section-content"
    >
      <template slot="section-content">
        <ing-thank-you :getBackToYou="userAlreadyApplied" />
      </template>
    </ing-section>

    <div v-if="userAlreadyApplied">
      <ing-section
        :darkTheme="false"
        maxWidth="ing-section-content"
        class="section-form"
        :class="{ 'seller-central-button-wrapper': userAlreadyApplied }"
      >
        <template slot="section-content">
          <seller-next-steps :userAlreadyApplied="userAlreadyApplied" />
        </template>
      </ing-section>
    </div>

    <div v-if="!userAlreadyApplied">
      <ing-section
        :darkTheme="true"
        maxWidth="ing-section-content"
        class="section-form"
      >
        <template slot="section-content">
          <ing-submission-overview
            :smeSignUpToThankYouPage="smeSignUpToThankYouPage"
          />
        </template>
      </ing-section>
      <ing-section
        v-if="!isTestAndLearnEnabled"
        :darkTheme="false"
        maxWidth="ing-section-content"
        class="section-form"
      >
        <template slot="section-content">
          <ing-next-steps />
        </template>
      </ing-section>
      <ing-section
        :darkTheme="!isTestAndLearnEnabled"
        maxWidth="ing-section-content"
        class="section-form"
      >
        <template slot="section-content">
          <seller-next-steps
            :smeSignUpToThankYouPage="smeSignUpToThankYouPage"
          />
        </template>
      </ing-section>
    </div>
    <ing-section
      :darkTheme="isTestAndLearnEnabled"
      maxWidth="ing-section-content"
      class="section-form contact-us-section"
    >
      <template slot="section-content">
        <ing-contact-us :userAlreadyApplied="userAlreadyApplied" />
      </template>
    </ing-section>
    <ing-footer />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import LocalStorage from '@/common/localStorage';
  import { LOCAL_STORAGE_KEYS } from '@/constants/LOCAL_STORAGE_KEYS';

  import IngHeader from '@/components/ing/ing-header';
  import IngFooter from '@/components/ing/ing-footer';
  import IngSection from '@/components/ing/ing-section';
  import IngContactUs from '@/components/ing/ing-contact-us';
  import IngThankYou from '@/components/ing/ing-thank-you';
  import IngSubmissionOverview from '@/components/ing/ing-submission-overview';
  import IngNextSteps from '@/components/ing/ing-next-steps';
  import SellerNextSteps from '@/components/ing/seller-next-steps';

  export default {
    components: {
      IngHeader,
      IngFooter,
      IngSection,
      IngThankYou,
      IngContactUs,
      IngSubmissionOverview,
      IngNextSteps,
      SellerNextSteps,
    },
    computed: {
      ...mapState('blacksea', ['offer']),
      userAlreadyApplied: function () {
        return this.offer && this.offer.status === 'APPLIED';
      },
      isTestAndLearnEnabled() {
        return process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true';
      },
    },
    beforeMount() {
      this.smeSignUpToThankYouPage =
        LocalStorage.getItem(LOCAL_STORAGE_KEYS.SME_SIGNUP) || {};
    },
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerSignupPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerSignupPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerSignupPage.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  .seller-central-button-wrapper {
    padding-top: 0;
    padding-bottom: 57px;
  }

  @media screen and (max-width: 768px) {
    .thank-you-section {
      padding-top: 20px;
      padding-bottom: 40px;
    }
    .seller-central-button-wrapper {
      padding-bottom: 14px;
    }

    .contact-us-section {
      padding-top: 50px;
    }
  }

  @media (max-width: 522px) {
    .seller-central-button-wrapper {
      padding-bottom: 0;
    }

    .contact-us-section {
      padding-top: 58px;
    }
  }
</style>
