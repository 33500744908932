<template>
  <len-text-input
    v-model="streetNumber"
    :name="'street-number-' + signerId"
    :label="$t('forms.signupForm.streetNumber.label')"
    :error="
      errors[signerId] &&
      errors[signerId].streetNumber &&
      $t('forms.errors.' + errors[signerId].streetNumber)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      streetNumber: {
        get() {
          return this.signers[this.signerId].streetNumber;
        },
        set(value) {
          this.SET_STREET_NUMBER({
            signerId: this.signerId,
            streetNumber: value,
          });
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(this.signerId);
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_STREET_NUMBER',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
    },
  };
</script>
