import { render, staticRenderFns } from "./ing-submit-button.vue?vue&type=template&id=22f51783&scoped=true"
import script from "./ing-submit-button.vue?vue&type=script&lang=js"
export * from "./ing-submit-button.vue?vue&type=script&lang=js"
import style0 from "./ing-submit-button.vue?vue&type=style&index=0&id=22f51783&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22f51783",
  null
  
)

export default component.exports