import {
  IBAN_LENGTH_WITH_SPACES,
  IBAN_LENGTH_NO_SPACES,
} from '@/constants/IBAN_FORMAT';

export const getFullIbanWithSpaces = (value) => {
  if (!value) {
    return '';
  }
  return value.substr(0, IBAN_LENGTH_WITH_SPACES);
};

export const removeSpacesFromIban = (value) => {
  if (!value) {
    return '';
  }
  return value.replace(/\s/g, '').substr(0, IBAN_LENGTH_NO_SPACES);
};

export const forceDEformat = (value) => {
  if (/^DE(.*)+/.test(value)) {
    return value;
  } else {
    return 'DE';
  }
};

export const addSpacesToIban = (value) => {
  if (!value) {
    return '';
  }
  return value.match(/.{1,4}/g).join(' ');
};
