export default function ValidateLoanPurposeBorrower(loanPurposeBorrower) {
  if (!loanPurposeBorrower) {
    return { loanPurposeBorrower: 'LoanPurposeIsRequired' };
  }
  const keys = [
    'Umlaufvermögen',
    'Umschuldung',
    'Investition in Anlagevermögen',
    'Wachstumskapital',
    'Schuldenfinanzierung',
    'Sonstige Zwecke',
  ];
  if (keys.indexOf(loanPurposeBorrower) === -1) {
    return { loanPurposeBorrower: 'LoanPurposeIsNotCorrect' };
  }
  return { loanPurposeBorrower: undefined };
}
