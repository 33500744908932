import { render, staticRenderFns } from "./loan-owner-company-title.vue?vue&type=template&id=33e4e1c5"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports