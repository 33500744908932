import lenTabsBehaviour from '@/directives/len-tabs-behaviour';
import clickOutside from '@/directives/click-outside';
/**
 * General install function.
 *
 * @param {Object} Vue
 */
export default function install(Vue) {
  Vue.use(lenTabsBehaviour);
  Vue.use(clickOutside);
}
