<template>
  <span ref="tooltip" :title="tooltipMessage">
    <ing-tooltip-icon class="ing-tooltip" />
  </span>
</template>

<script>
  /**
   * This is a "bridge" component that uses the bootstrap tooltip plugin
   * The aim is to replace the ing-tooltip and the custom tooltip code
   */
  import $ from 'jquery/dist/jquery.slim.min';
  import IngTooltipIcon from '@/components/ing/ing-tooltip-icon';

  export default {
    components: { IngTooltipIcon },
    props: {
      tooltipMessage: {
        type: String,
        required: true,
      },
      placement: {
        type: String,
        default: 'top',
      },
    },
    mounted() {
      $(this.$refs.tooltip).tooltip({
        placement: this.placement,
        // viewport: '.ing-header-container'
        html: true,
      });
    },
  };
</script>
