<template>
  <div class="col-lg-6 col-lg-offset-3 col-md-offset-1 col-md-11 col-sm-11">
    <button type="submit" class="ing-ident-submit-button">
      {{ $t('forms.signupForm.ident.submit') }}
    </button>
    <div v-if="errorMessage !== ''" role="alert" class="error-container">
      <p class="validation" v-html="errorMessage" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapState('ident', ['errors', 'submitClicked']),
      ...mapGetters('ident', ['isValid']),
      errorMessage() {
        const serverErrorStatus =
          this.errors && this.errors.server && this.errors.server.status;
        if (serverErrorStatus) {
          if (serverErrorStatus >= 400 && serverErrorStatus < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverErrorStatus >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        if (this.submitClicked && !this.isValid) {
          return this.$t('forms.signupForm.errors.fields');
        }
        return '';
      },
    },
  };
</script>

<style scoped>
  .validation {
    text-align: left;
    font-size: 16px;
    line-height: 1.64;
  }
</style>
