<template>
  <div>
    <PageSection class="section-form" :darkTheme="true">
      <template #section-content>
        <FormSectionHeader section="signers.companyDetails" :paraCount="1" />
        <div :class="{ alert: isInvalid }">
          <div class="section-header">
            <span class="section-header__title">
              {{
                company.companyName ||
                $t('signers.companyDetails.controllingCompany.title')
              }}
            </span>
            <IngButton
              type="submit"
              class="outlined small edit-button-alignment"
              @click="$emit('edit-button-clicked')"
            >
              {{ $t('signers.form.buttons.edit') }}
            </IngButton>
          </div>
          <div class="company-info-container">
            <div class="company-info-container__col">
              <div class="section">
                <div class="section__list">
                  <div class="section__row">
                    <span> {{ $t('signers.companyDetails.name') }}: </span>
                    <span>
                      {{ company.companyName | emptyValueReplacer }}
                    </span>
                  </div>
                  <div class="section__row">
                    <span> {{ $t('signers.companyDetails.taxNumber') }}: </span>
                    <span>
                      {{ company.companyTaxNumber | emptyValueReplacer }}
                    </span>
                  </div>
                  <div class="section__row">
                    <span> {{ $t('signers.companyDetails.tradeName') }}: </span>
                    <span>
                      {{ company.companyTradingName | emptyValueReplacer }}
                    </span>
                  </div>
                  <div class="section__row">
                    <span>
                      {{ $t('signers.companyDetails.countryOfIncorporation') }}:
                    </span>
                    <span>
                      {{
                        company.countryOfIncorporation
                          | convertCountryIsoToName
                          | emptyValueReplacer
                      }}
                    </span>
                  </div>
                  <div class="section__row section__row--divider">
                    <span>
                      {{ $t('signers.companyDetails.businessAddress') }}:
                    </span>
                    <span class="section__address">
                      {{ currentAddress }}
                    </span>
                  </div>
                  <div class="section__row">
                    <span>
                      {{ $t('signers.companyDetails.mailingAddress') }}:
                    </span>
                    <span class="section__address">
                      {{ postalAddress }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="company-info-container__col">
              <div class="section">
                <div class="section__list">
                  <div class="section__row">
                    <span>
                      {{ $t('signers.companyDetails.cashIntensity.label') }}
                    </span>
                    <span>
                      {{ cashIntensityValue | emptyValueReplacer }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-show="hasControllingCompany" class="company-info-container">
            <div class="company-info-container__col">
              <div class="section">
                <div class="section__list">
                  <div class="section__row section__row--divider">
                    <span
                      v-if="isFeatureFlagMLLFEnabled && isComplexLegalForm"
                      class="section__row title"
                    >
                      {{
                        $t('signers.companyDetails.MLLFControllingCompany.name')
                      }}
                    </span>
                    <span v-else class="section__row title">
                      {{ $t('signers.companyDetails.controllingCompany.name') }}
                    </span>
                  </div>
                  <div class="section__row section__row--divider">
                    <span> {{ $t('signers.companyDetails.name') }}: </span>
                    <span>
                      {{ controllingCompany.legalName | emptyValueReplacer }}
                    </span>
                  </div>
                  <div class="section__row">
                    <span>
                      {{
                        $t(
                          'forms.signupForm.controllingCompanyTaxNumber.label'
                        )
                      }}:
                    </span>
                    <span>
                      {{
                        controllingCompany.controllingCompanyTaxNumber
                          | emptyValueReplacer
                      }}
                    </span>
                  </div>
                  <div class="section__row">
                    <span>
                      {{ $t('signers.companyDetails.countryOfIncorporation') }}:
                    </span>
                    <span>
                      {{
                        controllingCompany.countryOfIncorporation
                          | convertCountryIsoToName
                          | emptyValueReplacer
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sme-section">
            <h3 class="sme-section__header">
              {{ $t('forms.signupForm.smeHeader.h3') }}
            </h3>
            <p class="sme-section__description">
              {{ $t('forms.signupForm.smeHeader.p') }}
            </p>
            <FieldStatus
              data-unit-test="ing-mllf-sme-header-info"
              class="sme-section__mllf-info"
              :info="$t('forms.signupForm.smeHeader.info')"
            />
            <dl class="sme-section__questions">
              <dt>{{ $t('forms.signupForm.employeesNumber.label') }}</dt>
              <dd>{{ company.employeesNumber | emptyValueReplacer }}</dd>
              <dt>{{ $t('forms.signupForm.companyRevenues.label') }}</dt>
              <dd>{{ revenues }}</dd>
              <div v-if="isRegularLoanRequest">
                <dt>{{ $t('forms.signupForm.companyAssets.label') }}</dt>
                <dd>{{ assets }}</dd>
              </div>
            </dl>
          </div>
        </div>
      </template>
    </PageSection>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import { cashIntensityValues } from '@/validators/helpers/cashIntensity';
  import {
    isRegularLoanRequest,
    hasControllingCompany,
    isFeatureFlagMLLFEnabled,
  } from '@/mixins/signersMixin';

  export default {
    components: {
      PageSection,
      FormSectionHeader,
      IngButton,
      FieldStatus,
    },
    mixins: [
      isRegularLoanRequest,
      hasControllingCompany,
      isFeatureFlagMLLFEnabled,
    ],
    props: {
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        cashIntensityValues,
      };
    },
    computed: {
      ...mapState('signers', ['company', 'controllingCompany']),
      ...mapGetters('signers', ['isComplexLegalForm']),
      currentAddress() {
        const { street, streetNumber, zip, city, country } = this.company;
        if (this.company.street) {
          return `${street} ${streetNumber},\r\n ${zip} ${city}, ${country}`;
        } else {
          return '-';
        }
      },
      postalAddress() {
        const {
          postalStreet,
          postalStreetNumber,
          postalZip,
          postalCity,
          postalCountry,
          businessAddressIsPostalAddress,
        } = this.company;
        if (postalStreet) {
          return `${postalStreet} ${postalStreetNumber},\r\n ${postalZip} ${postalCity}, ${postalCountry}`;
        } else if (businessAddressIsPostalAddress) {
          return this.currentAddress;
        } else {
          return '-';
        }
      },
      cashIntensityValue() {
        return this.$t(
          `signers.companyDetails.cashIntensity.options.${this.company.cashIntensity}`
        );
      },
      assets() {
        if (this.company.assets) {
          return (
            '€ ' + this.$options.filters.europeanCurrency(this.company.assets)
          );
        } else {
          return '-';
        }
      },
      revenues() {
        if (this.company.revenues) {
          return (
            '€ ' + this.$options.filters.europeanCurrency(this.company.revenues)
          );
        } else {
          return '-';
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';
  @import '@/sass/components/common/ing.orange.juice';

  .section-header {
    display: flex;
    padding-bottom: 2rem;
    flex-direction: column;
    gap: 1rem;

    @include mq-size(s) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 28px;
      color: $Primary-Grey600;
    }
  }

  .alert {
    border: solid 3px $Functional-Minus500;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .company-info-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    &__col {
      flex-basis: 100%;
      box-sizing: content-box;
      @include mq-size(s) {
        flex-basis: 87.15%;
      }
      @include mq-size(m) {
        flex-basis: 48.5%;
      }
      @include mq-size(l) {
        flex-basis: 48.5%;
      }
    }

    .section {
      display: flex;
      column-gap: 3%;
      row-gap: 48px;
      flex-wrap: wrap;
      padding-bottom: 48px;
      &:last-child {
        padding-bottom: 0;
      }
      &__list {
        flex-basis: 100%;
        @include mq-size(xl) {
          flex-basis: 98.5%;
        }
      }
      &__address {
        white-space: pre-line;
      }
      &__row {
        display: flex;
        column-gap: 3%;
        flex-wrap: wrap;
        @include mq-size(s) {
          column-gap: 3%;
        }
        @include mq-size(xl) {
          column-gap: 5%;
        }
        &--divider {
          padding-top: 24px;
        }
        &--header {
          padding-bottom: 8px;
          @include mq-size(s) {
            text-align: right;
          }
        }
        &--header#{&} span {
          font-size: 19px;
          line-height: 28px;
          font-weight: 700;
        }
        .title {
          font-weight: 700;
          font-size: 19px;
          line-height: 28px;
          color: $Primary-Grey600;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          flex-basis: 100%;
          word-break: break-all;
          @include mq-size(s) {
            flex-basis: 48.5%;
          }
          @include mq-size(xl) {
            flex-basis: 47.5%;
          }
          &:first-child {
            @include mq-size(s) {
              text-align: right;
            }
          }
          &:last-child {
            font-weight: 700;
            padding-bottom: 5px;
            min-height: 24px;
            @include mq-size(s) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
  .sme-section {
    margin-top: 64px;
    width: 100%;
    @include mq-size(m) {
      width: 89.6%;
    }
    @include mq-size(xl) {
      width: 69.3%;
    }
    &__header {
      color: $Primary-Grey600;
      font-size: 19px;
      font-weight: 700;
      line-height: 28px;
      margin-bottom: 16px;
    }
    &__description,
    &__mllf-info {
      font-size: 16px;
      line-height: 24px;
    }
    &__mllf-info {
      margin: 16px 0 24px 0;
    }
    &__questions {
      dt {
        margin-top: 20px;
      }
    }
  }
</style>
