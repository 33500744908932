import { getters } from '@/vuex/signers/getters';
import store from '@/vuex';
const { getSignersValidator } = getters;
import { isValidNationality } from '@/validators/helpers/nationality';
import {
  controllingCompanyLegalForm,
  complexLegalForm,
} from '@/validators/helpers/legalStatus';
import { signerInitialState } from './index';
import { initialState } from './index';
import trim from 'lodash/trim';
import { trimValuesInObject } from '@/utils/stringUtilities';

/*
 * This file needs to be completely refactored
 * Not only it's too large, but it includes so much logic which is terrible
 * Disclaimer:
 * Because of putting logic in this, we apply a bad practice to get access to getters here
 * even though if we didn't have business logic in mutations in the first place
 * we didn't need this.
 * */
export const mutateSignerField = (state, [signerId, fieldValue]) => {
  const signersValidator = getSignersValidator(state);
  const selectedSigner = state.signers[signerId];
  trimValuesInObject(fieldValue);
  state.signers[signerId] = { ...selectedSigner, ...fieldValue };
  state.errors = {
    ...state.errors,
    [signerId]: signersValidator(state.signers[signerId]),
  };
};

const validateCountryDropdownFields = (signer) => {
  signer.nationality = isValidNationality(signer.nationality)
    ? signer.nationality
    : '';
  signer.countryOfBirth = isValidNationality(signer.countryOfBirth)
    ? signer.countryOfBirth
    : '';
  signer.identificationPlaceOfIssue = isValidNationality(
    signer.identificationPlaceOfIssue
  )
    ? signer.identificationPlaceOfIssue
    : '';
};

export const mutations = {
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  SET_GENDER(state, { signerId, gender }) {
    mutateSignerField(state, [signerId, { gender: gender }]);
  },

  SET_LAST_NAME_AT_BIRTH(state, { signerId, lastNameAtBirth }) {
    mutateSignerField(state, [signerId, { lastNameAtBirth: lastNameAtBirth }]);
  },

  SET_NATIONALITY(state, { signerId, nationality }) {
    mutateSignerField(state, [signerId, { nationality: nationality }]);
  },
  SET_SECOND_NATIONALITY(state, { signerId, secondNationality }) {
    mutateSignerField(state, [
      signerId,
      { secondNationality: secondNationality },
    ]);
  },
  SET_EMAIL(state, { signerId, email }) {
    mutateSignerField(state, [signerId, { email: email }]);
  },

  SET_TITLE(state, { signerId, title }) {
    mutateSignerField(state, [signerId, { title: title }]);
  },

  SET_COUNTRY_OF_BIRTH(state, { signerId, countryOfBirth }) {
    mutateSignerField(state, [signerId, { countryOfBirth: countryOfBirth }]);
  },

  SET_CITY_OF_BIRTH(state, { signerId, cityOfBirth }) {
    mutateSignerField(state, [signerId, { cityOfBirth: cityOfBirth }]);
  },

  SET_TAX_NUMBER(state, { signerId, taxNumber }) {
    mutateSignerField(state, [signerId, { taxNumber: taxNumber }]);
  },

  SET_IDENTIFICATION_ID_TYPE(state, { signerId, identificationIdType }) {
    mutateSignerField(state, [
      signerId,
      { identificationIdType: identificationIdType },
    ]);
  },

  SET_IDENTIFICATION_ID_NUMBER(state, { signerId, identificationIdNumber }) {
    mutateSignerField(state, [
      signerId,
      { identificationIdNumber: identificationIdNumber },
    ]);
  },

  SET_IDENTIFICATION_ISSUING_AUTHORITY(
    state,
    { signerId, identificationIssuingAuthority }
  ) {
    mutateSignerField(state, [
      signerId,
      { identificationIssuingAuthority: identificationIssuingAuthority },
    ]);
  },

  SET_IDENTIFICATION_PLACE_OF_ISSUE(
    state,
    { signerId, identificationPlaceOfIssue }
  ) {
    mutateSignerField(state, [
      signerId,
      { identificationPlaceOfIssue: identificationPlaceOfIssue },
    ]);
  },

  SET_IDENTIFICATION_ISSUING_DATE(
    state,
    { signerId, identificationIssuingDate }
  ) {
    mutateSignerField(state, [
      signerId,
      { identificationIssuingDate: identificationIssuingDate },
    ]);
  },

  SET_IDENTIFICATION_EXPIRY_DATE(
    state,
    { signerId, identificationExpiryDate }
  ) {
    mutateSignerField(state, [
      signerId,
      { identificationExpiryDate: identificationExpiryDate },
    ]);
  },

  SET_MOBILE_PHONE(state, { signerId, mobilePhone }) {
    mutateSignerField(state, [signerId, { mobilePhone: mobilePhone }]);
  },

  SET_STREET(state, { signerId, street }) {
    mutateSignerField(state, [signerId, { street: street }]);
  },

  SET_STREET_NUMBER(state, { signerId, streetNumber }) {
    mutateSignerField(state, [signerId, { streetNumber: streetNumber }]);
  },

  SET_ZIP(state, { signerId, zip }) {
    mutateSignerField(state, [signerId, { zip: zip }]);
  },

  SET_CITY(state, { signerId, city }) {
    mutateSignerField(state, [signerId, { city: city }]);
  },

  SET_COUNTRY(state, { signerId, country }) {
    mutateSignerField(state, [signerId, { country: country }]);
  },

  SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS(
    state,
    { signerId, homeAddressIsPostalAddress }
  ) {
    mutateSignerField(state, [
      signerId,
      { homeAddressIsPostalAddress: homeAddressIsPostalAddress },
    ]);
  },

  SET_POSTAL_STREET(state, { signerId, postalStreet }) {
    mutateSignerField(state, [signerId, { postalStreet: postalStreet }]);
  },

  SET_POSTAL_STREET_NUMBER(state, { signerId, postalStreetNumber }) {
    mutateSignerField(state, [
      signerId,
      { postalStreetNumber: postalStreetNumber },
    ]);
  },

  SET_POSTAL_ZIP(state, { signerId, postalZip }) {
    mutateSignerField(state, [signerId, { postalZip: postalZip }]);
  },

  SET_POSTAL_CITY(state, { signerId, postalCity }) {
    mutateSignerField(state, [signerId, { postalCity: postalCity }]);
  },

  SET_POSTAL_COUNTRY(state, { signerId, postalCountry }) {
    mutateSignerField(state, [signerId, { postalCountry: postalCountry }]);
  },

  SET_CLIENT_SUBMIT_CLICKED(state, { signerId, submitClicked }) {
    mutateSignerField(state, [signerId, { submitClicked: submitClicked }]);
  },

  SET_IDENT_PROCESS(state, identificationProcessValue) {
    const signersValidator = getSignersValidator(state);
    state.identificationProcess = identificationProcessValue;
    const { identificationProcess } = signersValidator(state);
    state.errors = { ...state.errors, identificationProcess };
  },
  SET_OPPORTUNITY_ID(state, opportunityId) {
    state.opportunityId = opportunityId;
  },
  SET_NO_OTHER_MD_CHECKBOX(state, value) {
    const signersValidator = getSignersValidator(state);
    state.noOtherMDCheckbox = value;
    const { noOtherMDCheckbox } = signersValidator(state);
    state.errors = { ...state.errors, noOtherMDCheckbox };
  },

  SET_SIGNERS(state, lrmPayload) {
    const signersObject = {};
    lrmPayload.forEach((signer) => {
      validateCountryDropdownFields(signer);
      trimValuesInObject(signer);
      const homeAddress = signer.homeAddress || {};
      const postalAddress = signer.postalAddress || {};
      trimValuesInObject(homeAddress);
      trimValuesInObject(postalAddress);
      const { street, city, country, zip, num: streetNumber } = homeAddress;
      const {
        street: postalStreet,
        city: postalCity,
        country: postalCountry,
        zip: postalZip,
        num: postalStreetNumber,
      } = postalAddress;
      signersObject[signer.clientId] = {
        ...signerInitialState,
        clientId: signer.clientId,
        gender: signer.gender,
        roles: signer.roles,
        title: signer.title,
        firstName: signer.firstName,
        lastName: signer.lastName,
        lastNameAtBirth: signer.lastNameAtBirth,
        birthday: signer.birthday,
        countryOfBirth: signer.countryOfBirth,
        homeAddressIsPostalAddress: !signer.homeAddressIsPostalAddress,
        cityOfBirth: signer.cityOfBirth,
        nationality: signer.nationality,
        secondNationality: signer.secondNationality,
        email: signer.email,
        mobilePhone: signer.mobilePhone,
        identificationIdType: signer.identificationIdType,
        identificationIdNumber: signer.identificationIdNumber,
        identificationIssuingAuthority: signer.identificationIssuingAuthority,
        identificationPlaceOfIssue: signer.identificationPlaceOfIssue,
        identificationIssuingDate: signer.identificationIssuingDate,
        identificationExpiryDate: signer.identificationExpiryDate,
        taxNumber: signer.taxNumber,
        street,
        city,
        country,
        zip,
        streetNumber,
        postalStreet,
        postalCity,
        postalCountry,
        postalZip,
        postalStreetNumber,
      };
      const signersValidator = getSignersValidator(state);
      state.errors = {
        ...state.errors,
        [signer.clientId]: signersValidator(signersObject[signer.clientId]),
      };
    });
    state.signers = { ...state.signers, ...signersObject };
  },
  SET_COMPANY_INFORMATION(state, lrmPayload) {
    trimValuesInObject(lrmPayload);
    const businessAddress = lrmPayload.businessAddress || {};
    const postalAddress = lrmPayload.postalAddress || {};
    const bankAccount = lrmPayload.bankAccount || {};
    const { cashIntensity, assets, revenues } =
      lrmPayload.financialDetails || {};
    trimValuesInObject(businessAddress);
    trimValuesInObject(postalAddress);
    const { street, city, zip, num: streetNumber } = businessAddress;
    const {
      street: postalStreet,
      city: postalCity,
      zip: postalZip,
      num: postalStreetNumber,
    } = postalAddress;
    // Set company default country if not present
    const country = businessAddress.country ? businessAddress.country : 'DE';
    const postalCountry = postalAddress.country ? postalAddress.country : 'DE';
    let {
      companyClientId,
      companyName,
      companyTradingName,
      companyTaxNumber,
      employeesNumber,
      businessAddressIsPostalAddress,
      legalForm,
      countryOfIncorporation,
    } = lrmPayload;
    const { productType } = state.offerConditions;
    if (productType && productType.includes('REGULAR')) {
      countryOfIncorporation = isValidNationality(
        lrmPayload.countryOfIncorporation
      )
        ? lrmPayload.countryOfIncorporation
        : '';
    }
    Object.assign(state.company, {
      street,
      city,
      country,
      zip,
      streetNumber,
      postalStreet,
      postalCity,
      postalCountry,
      postalZip,
      postalStreetNumber,
      cashIntensity,
      companyClientId,
      companyName,
      companyTradingName,
      companyTaxNumber,
      countryOfIncorporation,
      businessAddressIsPostalAddress,
      legalForm,
    });

    /**
     * This change is some work around for EVA-1900 and description included in
     * PR (https://github.com/Lendico/antrag/pull/763).
     */
    Object.assign(state.company, {
      businessAddressIsPostalAddress: !businessAddressIsPostalAddress,
    });

    const { bic, iban } = bankAccount;
    if (
      process.env.VUE_APP_IS_BLACKSEA === 'true' &&
      process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true' &&
      store.getters['appSettings/isAmazonApplication']
    ) {
      state.company = Object.assign({}, state.company, {
        bic,
        iban,
        employeesNumber,
        assets,
        revenues,
      });
      state.company.onlineRevenuesAtLeast75Percent =
        lrmPayload.financialDetails &&
        lrmPayload.financialDetails.onlineRevenuesAtLeast75Percent !== false;
    } else {
      state.nonEditableBankData = { bic, iban };
      state.company = Object.assign({}, state.company, {
        employeesNumber,
        assets,
        revenues,
      });
    }
    const signersValidator = getSignersValidator(state);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_CONTROLLING_COMPANY(state, lrmPayload) {
    const signersValidator = getSignersValidator(state);
    const { controllingCompanies, legalForm } = lrmPayload;
    if (
      legalForm === controllingCompanyLegalForm ||
      (process.env.VUE_APP_FEATURE_FLAG_MLLF_ENABLED === 'true' &&
        legalForm === complexLegalForm &&
        controllingCompanies.length !== 0)
    ) {
      Object.assign(state.controllingCompany, controllingCompanies[0]);
      state.errors = {
        ...state.errors,
        controllingCompany: signersValidator(state.controllingCompany),
      };
    }
  },
  SET_OFFER_INFORMATION(state, lrmPayload) {
    Object.assign(state.offerConditions, lrmPayload.minimalLoanDetails);
  },
  SET_COMPANY_NAME(state, companyName) {
    const signersValidator = getSignersValidator(state);
    state.company.companyName = trim(companyName);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_TRADING_NAME(state, companyTradingName) {
    const signersValidator = getSignersValidator(state);
    state.company.companyTradingName = trim(companyTradingName);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_TAX_NUMBER(state, companyTaxNumber) {
    const signersValidator = getSignersValidator(state);
    state.company.companyTaxNumber = trim(companyTaxNumber);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_STREET(state, street) {
    const signersValidator = getSignersValidator(state);
    state.company.street = trim(street);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_STREET_NUMBER(state, streetNumber) {
    const signersValidator = getSignersValidator(state);
    state.company.streetNumber = trim(streetNumber);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_ZIP(state, zip) {
    const signersValidator = getSignersValidator(state);
    state.company.zip = trim(zip);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_CITY(state, city) {
    const signersValidator = getSignersValidator(state);
    state.company.city = trim(city);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COUNTRY_OF_INCORPORATION(state, countryOfIncorporation) {
    const signersValidator = getSignersValidator(state);
    state.company.countryOfIncorporation = trim(countryOfIncorporation);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_POSTAL_STREET(state, postalStreet) {
    const signersValidator = getSignersValidator(state);
    state.company.postalStreet = trim(postalStreet);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_POSTAL_STREET_NUMBER(state, postalStreetNumber) {
    const signersValidator = getSignersValidator(state);
    state.company.postalStreetNumber = trim(postalStreetNumber);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_POSTAL_ZIP(state, postalZip) {
    const signersValidator = getSignersValidator(state);
    state.company.postalZip = trim(postalZip);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_POSTAL_CITY(state, postalCity) {
    const signersValidator = getSignersValidator(state);
    state.company.postalCity = trim(postalCity);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_IS_COMPANY_POSTAL_ADDRESS_SAME(state, boolean) {
    state.company.businessAddressIsPostalAddress = boolean;
  },
  SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(state) {
    const signersValidator = getSignersValidator(state);
    if (!state.company.businessAddressIsPostalAddress) {
      state.company.postalCity = state.company.city;
      state.company.postalStreetNumber = state.company.streetNumber;
      state.company.postalStreet = state.company.street;
      state.company.postalZip = state.company.zip;
    }
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  CLEAR_COMPANY_POSTAL_ADDRESS(state) {
    const signersValidator = getSignersValidator(state);
    if (state.company.businessAddressIsPostalAddress) {
      state.company.postalCity = '';
      state.company.postalStreetNumber = '';
      state.company.postalStreet = '';
      state.company.postalZip = '';
    }
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(state, signerId) {
    const signersValidator = getSignersValidator(state);
    if (!state.signers[signerId].homeAddressIsPostalAddress) {
      state.signers[signerId].postalCity = state.signers[signerId].city;
      state.signers[signerId].postalStreetNumber =
        state.signers[signerId].streetNumber;
      state.signers[signerId].postalStreet = state.signers[signerId].street;
      state.signers[signerId].postalZip = state.signers[signerId].zip;
      state.signers[signerId].postalCountry = state.signers[signerId].country;
    }
    state.errors = {
      ...state.errors,
      [signerId]: signersValidator(state.signers[signerId]),
    };
  },
  CLEAR_CLIENT_POSTAL_ADDRESS(state, signerId) {
    const signersValidator = getSignersValidator(state);
    if (state.signers[signerId].homeAddressIsPostalAddress) {
      state.signers[signerId].postalCity = '';
      state.signers[signerId].postalStreetNumber = '';
      state.signers[signerId].postalStreet = '';
      state.signers[signerId].postalZip = '';
      state.signers[signerId].postalCountry = '';
    }
    state.errors = {
      ...state.errors,
      [signerId]: signersValidator(state.signers[signerId]),
    };
  },
  SET_CONTROLLING_LEGAL_NAME(state, companyName) {
    const signersValidator = getSignersValidator(state);
    state.controllingCompany.legalName = trim(companyName);
    state.errors = {
      ...state.errors,
      controllingCompany: signersValidator(state.controllingCompany),
    };
  },
  SET_CONTROLLING_COMPANY_TAX_NUMBER(state, controllingCompanyTaxNumber) {
    const signersValidator = getSignersValidator(state);
    state.controllingCompany.controllingCompanyTaxNumber = trim(
      controllingCompanyTaxNumber
    );
    state.errors = {
      ...state.errors,
      controllingCompany: signersValidator(state.controllingCompany),
    };
  },
  SET_CONTROLLING_COUNTRY_OF_INCORPORATION(state, countryOfIncorporation) {
    const signersValidator = getSignersValidator(state);
    state.controllingCompany.countryOfIncorporation = trim(
      countryOfIncorporation
    );
    state.errors = {
      ...state.errors,
      controllingCompany: signersValidator(state.controllingCompany),
    };
  },
  SET_COMPANY_IBAN(state, iban) {
    const signersValidator = getSignersValidator(state);
    state.company.iban = iban;
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_BIC(state, bic) {
    const signersValidator = getSignersValidator(state);
    state.company.bic = bic;
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_CASH_INTENSITY(state, cashIntensity) {
    const signersValidator = getSignersValidator(state);
    state.company.cashIntensity = cashIntensity;
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_ONLINE_REVENUE(state, onlineRevenue) {
    state.company.onlineRevenuesAtLeast75Percent = onlineRevenue;
  },
  SET_EMPLOYEES_NUMBER(state, employeesNumber) {
    const signersValidator = getSignersValidator(state);
    state.company.employeesNumber = employeesNumber;
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_REVENUES(state, revenues) {
    const signersValidator = getSignersValidator(state);
    state.company.revenues = revenues;
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
    };
  },
  SET_COMPANY_ASSETS(state, assets) {
    const signersValidator = getSignersValidator(state);
    state.company.assets = assets;
    if (!state.company.noAssetsInformation) {
      state.errors = {
        ...state.errors,
        company: signersValidator(state.company),
      };
    }
  },
  SET_COMPANY_ASSETS_CHECKBOX(state, assetsCheckbox) {
    const signersValidator = getSignersValidator(state);
    state.company.noAssetsInformation = assetsCheckbox;
    if (!assetsCheckbox) {
      state.errors = {
        ...state.errors,
        company: signersValidator(state.company),
      };
    }
  },
  CLEAR_COMPANY_ASSETS_ERROR(state) {
    state.errors.company.assets = undefined;
  },
  SET_COMPANY_ASSETS_EMPTY(state) {
    state.company.assets = '';
  },
  SET_OFFER_DISBURSEMENT_AMOUNT(state, amount) {
    state.offerConditions.wclDisbursementAmount = amount;
  },
  SET_REQUEST_ERROR(state, error) {
    state.errors.server = error;
  },
  CLEAR_REQUEST_ERROR(state) {
    state.errors.server = null;
  },
  SET_CLIENT_REQUEST_ERROR(state, error) {
    state.errors.clientServer = error;
  },
  CLEAR_CLIENT_REQUEST_ERROR(state) {
    state.errors.clientServer = null;
  },
  SET_COMPANY_REQUEST_ERROR(state, error) {
    state.errors.companyServer = error;
  },
  CLEAR_COMPANY_REQUEST_ERROR(state) {
    state.errors.companyServer = null;
  },
  SET_OFFER_CONDITIONS_REQUEST_ERROR(state, error) {
    state.errors.offerConditions = error;
  },
  CLEAR_OFFER_CONDITIONS_REQUEST_ERROR(state, error) {
    state.errors.offerConditions = error;
  },
  SET_SUCCESS(state, successMsg) {
    state.success = successMsg;
  },
  SET_SUBMIT_CLICKED(state, submitClicked) {
    state.submitClicked = submitClicked;
  },
  SET_COMPANY_SUBMIT_CLICKED(state, companySubmitClicked) {
    state.companySubmitClicked = companySubmitClicked;
  },
  SET_IS_OFFER_EDITABLE(state, isOfferEditable) {
    state.isOfferEditable = isOfferEditable;
  },
  SET_IS_COMPANY_EDITABLE(state, isCompanyEditable) {
    state.isCompanyEditable = isCompanyEditable;
  },
  SET_IS_CLIENT_EDITABLE(state, isClientEditable) {
    state.isClientEditable = isClientEditable;
  },
  SET_COMPANY_SNAPSHOT(state) {
    Object.assign(state.companySnapshot, state.company);
    Object.assign(state.controllingCompanySnapshot, state.controllingCompany);
  },
  REVERT_COMPANY_CHANGES(state) {
    state.company = Object.assign({}, state.companySnapshot);
    state.controllingCompany = Object.assign(
      {},
      state.controllingCompanySnapshot
    );
  },
  CLEAR_COMPANY_SNAPSHOT(state) {
    state.companySnapshot = {};
    state.controllingCompanySnapshot = {};
  },
  SET_CLIENT_SNAPSHOT(state, signerId) {
    Object.assign(state.clientSnapshot, state.signers[signerId]);
  },
  REVERT_CLIENT_CHANGES(state, signerId) {
    state.signers[signerId] = Object.assign({}, state.clientSnapshot);
  },
  CLEAR_CLIENT_SNAPSHOT(state) {
    state.clientSnapshot = {};
  },
  RUN_COMPANY_VALIDATOR(state) {
    const signersValidator = getSignersValidator(state);
    state.errors = {
      ...state.errors,
      company: signersValidator(state.company),
      controllingCompany: signersValidator(state.controllingCompany),
    };
  },
  CLEAR_SIGNERS(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};
