import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';
import { isEmpty } from '../helpers/string';

export default function ValidateStreet(postalStreet) {
  if (isEmpty(postalStreet)) {
    return { postalStreet: 'PostalStreetIsRequired' };
  }
  if (hasForbiddenCharacters(postalStreet)) {
    return { postalStreet: 'ForbiddenCharacters' };
  }

  return { postalStreet: undefined };
}
