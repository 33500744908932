// displays the amount with the currency
export const currencyAmount = (amount, options = {}) =>
  new Intl.NumberFormat('de-DE', {
    ...options,
    style: 'currency',
    currency: 'EUR',
  }).format(amount);

// displays the amount without currency unless specified
export const decimalAmount = (amount, options = {}) =>
  new Intl.NumberFormat('de-DE', options).format(amount);

// displays the amount with the currency code
export const currencyAmountWithCode = (amount, options = {}) =>
  new Intl.NumberFormat('de-DE', {
    ...options,
    style: 'currency',
    currency: 'EUR',
    currencyDisplay: 'code',
  }).format(amount);
