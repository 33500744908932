<template>
  <div class="ing-wrapper">
    <ing-header />
    <main class="tech-error-page-container">
      <section class="content-wrapper">
        <img alt="Technical Error" class="icon" src="/ing-wrench.svg" />
        <h1>{{ $t('ingTechnicalErrorPage.title') }}</h1>
        <p class="caption">
          {{ $t('ingTechnicalErrorPage.content.caption') }}
          <a :href="`mailto:${$t('ingTechnicalErrorPage.content.mail')}`">{{
            $t('ingTechnicalErrorPage.content.mail')
          }}</a>
        </p>
      </section>
    </main>
    <ing-footer class="row" />
  </div>
</template>

<script>
  import { enableGTM } from '@/mixins/gtmMixins';
  import IngHeader from '@/components/ing/ing-header';
  import IngFooter from '@/components/ing/ing-footer';

  export default {
    components: {
      IngHeader,
      IngFooter,
    },
    mixins: [enableGTM],
    metaInfo() {
      return {
        title: this.$i18n.t('ingTechnicalErrorPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('ingTechnicalErrorPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('ingTechnicalErrorPage.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .tech-error-page-container {
    display: flex;
    flex-grow: 1;
    padding: 80px 35px;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 64px;
    margin-bottom: 20px;
  }
  h1 {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #ff6200;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #808080;
    max-width: 740px;
  }
  .caption {
    padding-top: 22px;
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: 667px) {
    .icon {
      width: 50px;
      margin-bottom: 28px;
    }
    h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
  @media screen and (max-width: 568px) {
    h1 {
      padding: 0 10px;
    }
  }
</style>
