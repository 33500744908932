<template>
  <div>
    <h2>{{ $t('borrowerJourney.borrowerOfferComponent.title') }}</h2>
    <div>
      <div class="row">
        <p class="col-sm-6">
          {{ $t('borrowerJourney.borrowerOfferComponent.loanAmount') }}
        </p>
        <p class="col-sm-6">
          <em>{{ loanAmount | emptyValueReplacer | europeanCurrency }} EUR</em>
        </p>
      </div>
      <div class="row">
        <p class="col-sm-6">
          {{ $t('borrowerJourney.borrowerOfferComponent.duration') }}
        </p>
        <p class="col-sm-6">
          <em>
            {{ duration | momentjsDuration('month') | emptyValueReplacer }}
          </em>
        </p>
      </div>
      <div class="row">
        <p class="col-sm-6">
          {{ $t('borrowerJourney.borrowerOfferComponent.fee') }}
        </p>
        <p class="col-sm-6">
          <em>{{ interestRate | emptyValueReplacer | europeanCurrency }} %</em>
        </p>
      </div>
      <div class="row">
        <p class="col-sm-6">
          {{ $t('borrowerJourney.borrowerOfferComponent.monthlyPayment') }}
        </p>
        <p class="col-sm-6">
          <em>{{ monthlyRate | emptyValueReplacer | europeanCurrency }} EUR</em>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  export default {
    computed: {
      ...mapState('offer', [
        'loanAmount',
        'duration',
        'interestRate',
        'monthlyRate',
      ]),
    },
  };
</script>
