var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ing-wrapper"},[_c('TheHeader'),_c('div',{staticClass:"page-container"},[_c('PageHeader',{attrs:{"page":"offer.dpagContractSuccess.message"}}),_c('PageSection',{staticClass:"section-form",attrs:{"sectionPadding":"sm"},scopedSlots:_vm._u([{key:"section-content",fn:function(){return [_c('h3',{staticClass:"contact__headline"},[_vm._v(" "+_vm._s(_vm.$t('offer.dpagContractSuccess.contact.title'))+" ")]),_c('i18n',{staticClass:"contact__content",attrs:{"path":"offer.dpagContractSuccess.contact.para1","tag":"p"}},[_c('em',{attrs:{"place":"boldText"}},[_vm._v(" "+_vm._s(_vm.$t('theContact.para1.boldText'))+" ")])]),_c('i18n',{staticClass:"contact__link-container",attrs:{"path":"offer.dpagContractSuccess.contact.para2.text","tag":"p"}},[_c('a',{staticClass:"contact__link",attrs:{"href":`tel:${
              _vm.$translationConstantValues.theContact[_vm.$route.meta.channel]
                .contact.phone
            }`,"place":"phoneNumber"}},[_vm._v(_vm._s(_vm.$translationConstantValues.theContact[_vm.$route.meta.channel] .contact.phone))]),_c('a',{staticClass:"contact__link",attrs:{"href":`mailto:${
              _vm.$translationConstantValues.theContact[_vm.$route.meta.channel]
                .contact.email
            }`,"place":"emailAddress"}},[_vm._v(_vm._s(_vm.$translationConstantValues.theContact[_vm.$route.meta.channel] .contact.email))])])]},proxy:true}])})],1),_c('TheFooter')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }