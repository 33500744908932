import { isEmpty } from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateUserCity(userCity) {
  if (isEmpty(userCity)) {
    return { userCity: 'UserCityIsRequired' };
  }
  if (hasForbiddenCharacters(userCity)) {
    return { userCity: 'ForbiddenCharacters' };
  }

  return { userCity: undefined };
}
