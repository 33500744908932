import nationalities from '@/components/common/assets/nationalities';
import Fuse from 'fuse.js';
import safariAutocompleteName from '@/utils/safariAutocompleteName';

const fuse = new Fuse(nationalities, {
  keys: ['name'],
  threshold: 0.1,
  sortFn: () => (a, b) => a.name > b.name ? -1 : 1, // sort the search result ascending
});

export const countryInputFunctionality = {
  name: 'VueCountryInput',
  props: {
    error: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Staatsangehörigkeit',
    },
    name: {
      type: String,
      default: '',
    },
    preferredCountries: {
      type: Array,
      default: () => [],
    },
    enabledFlags: {
      type: Boolean,
      default: true,
    },
    displayErrorIfDirty: {
      type: Boolean,
      default: true,
    },
    initialCountry: {
      type: String,
      default: '',
    },
    longErrorMessage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      country: '',
      nationalities,
      open: false,
      selectedIndex: null,
      isFocused: false,
      isDirty: false,
    };
  },
  computed: {
    hasError() {
      return !!this.error && this.error.length > 0;
    },
    displayError() {
      const result = this.hasError && !this.isFocused;

      if (this.displayErrorIfDirty) {
        return this.isDirty && result;
      }
      return result;
    },
    sortedCountries() {
      // Sort the list countries: from preferred countries to all countries
      const preferredCountries = this.preferredCountries
        .map((country) => this.findCountry(country))
        .filter(Boolean)
        .map((country) => ({ ...country, preferred: true }));
      return [...preferredCountries, ...nationalities];
    },
    filteredCountries() {
      if (!this.country) {
        return this.sortedCountries;
      }
      return fuse.search(this.country);
    },
    activeCountry() {
      const country = this.findCountryByName(this.country);
      return country || this.country;
    },
    safariAutocompleteOffName() {
      return safariAutocompleteName(this.name);
    },
  },
  watch: {
    activeCountry: function (newCountry, oldCountry) {
      if (this.isNative) {
        return;
      }

      if (!newCountry.iso) {
        this.$emit('change', newCountry);
      }
      if (newCountry.iso !== oldCountry.iso) {
        this.$emit('change', newCountry.iso);
      }
    },
    initialCountry: function () {
      this.setCountry();
    },
  },
  mounted() {
    this.setCountry();
  },
  methods: {
    findCountry(iso = '') {
      return (
        nationalities.find((country) => country.iso === iso.toUpperCase()) || {}
      );
    },
    findCountryByName(countryName) {
      return nationalities.find(({ name }) => name === countryName);
    },
    shouldDisplayPrefferedCountries() {
      return !this.country;
    },
    setCountry() {
      if (!this.initialCountry) {
        this.country = '';
      } else {
        const countryName =
          this.findCountry(this.initialCountry).name || this.initialCountry;
        this.country = countryName;
      }
    },
    getItemClass(index, iso) {
      const highlighted = this.selectedIndex === index;
      const preferred =
        this.shouldDisplayPrefferedCountries() &&
        !!this.preferredCountries.find(({ iso: i }) => i === iso.toUpperCase());
      const lastPreferred =
        this.shouldDisplayPrefferedCountries() &&
        index === this.preferredCountries.length - 1;
      return {
        highlighted,
        'last-preferred': lastPreferred,
        preferred,
      };
    },
    choose(country) {
      this.country = country.name;
      this.open = false;
    },
    onInput(event) {
      this.country = event.target.value;
      this.$refs.list.scrollTop = 0;
    },
    onFocus() {
      this.$emit('onFocus');
      this.open = true;
      this.isFocused = true;
      this.isDirty = true;
    },
    onBlur() {
      this.isFocused = false;
    },
    clickedOutside() {
      this.isFocused = false;
      this.open = false;
    },
    toggleDropdown() {
      this.open = !this.open;
    },
    keyboardNav(e) {
      if (e.keyCode === 40) {
        // down arrow
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(
            this.filteredCountries.length - 1,
            this.selectedIndex + 1
          );
        }
        let selEle = this.$refs.list.children[this.selectedIndex];
        if (
          selEle.offsetTop + selEle.clientHeight >
          this.$refs.list.scrollTop + this.$refs.list.clientHeight
        ) {
          this.$refs.list.scrollTop =
            selEle.offsetTop -
            this.$refs.list.clientHeight +
            selEle.clientHeight;
        }
      } else if (e.keyCode === 38) {
        // up arrow
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.filteredCountries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        let selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop < this.$refs.list.scrollTop) {
          this.$refs.list.scrollTop = selEle.offsetTop;
        }
      } else if (e.keyCode === 13) {
        e.preventDefault();
        // enter key
        if (this.selectedIndex !== null) {
          this.choose(this.filteredCountries[this.selectedIndex]);
        }
        this.open = !this.open;
        return false;
      } else if (e.keyCode === 9 || (e.shiftKey && e.keyCode === 9)) {
        // Press Tab Key
        this.open = false;
      } else {
        this.open = true;
      }
    },
  },
};
