export default function ValidateGender(gender) {
  if (!gender) {
    return { gender: 'GenderIsRequired' };
  }
  if (['MALE', 'FEMALE'].indexOf(gender) === -1) {
    return { gender: 'GenderIsNotCorrect' };
  }

  return { gender: undefined };
}
