<template>
  <div>
    <div>
      <h3 class="sme-header header">
        {{ $t('forms.signupForm.smeHeader.h3') }}
      </h3>
      <p class="sme-description description">
        {{ $t('forms.signupForm.smeHeader.p') }}
      </p>
      <FieldStatus
        data-unit-test="ing-mllf-sme-header-info"
        class="mllf-info"
        :info="$t('forms.signupForm.smeHeader.info')"
      />
    </div>
    <div>
      <div class="employees-number-title-container">
        <p class="field-label">
          <em>{{ $t('forms.signupForm.employeesNumber.label') }}</em>
        </p>
      </div>
      <p class="sme-value">
        {{ company.employeesNumber | emptyValueReplacer }}
      </p>
    </div>
    <div>
      <div class="company-revenues-title-container">
        <p class="field-label">
          <em>{{ $t('forms.signupForm.companyRevenues.label') }}</em>
          <ing-bs-tooltip
            :tooltipMessage="$t('forms.signupForm.companyRevenues.tooltip')"
          />
        </p>
      </div>
      <p class="sme-value">
        {{ revenues }}
      </p>
    </div>
    <div>
      <div class="company-assets-title-container">
        <p class="field-label">
          <em>{{ $t('forms.signupForm.companyAssets.label') }}</em>
          <ing-bs-tooltip
            :tooltipMessage="$t('forms.signupForm.companyAssets.tooltip')"
          />
        </p>
      </div>
      <p class="sme-value">
        {{ assets }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import { isFeatureFlagMLLFEnabled } from '@/mixins/signersMixin';
  export default {
    components: {
      IngBsTooltip,
      FieldStatus,
    },
    mixins: [isFeatureFlagMLLFEnabled],
    computed: {
      ...mapState('signers', ['company']),
      ...mapGetters('signers', ['isComplexLegalForm']),
      assets() {
        if (this.company.assets) {
          return (
            '€ ' + this.$options.filters.europeanCurrency(this.company.assets)
          );
        } else {
          return '-';
        }
      },
      revenues() {
        if (this.company.revenues) {
          return (
            '€ ' + this.$options.filters.europeanCurrency(this.company.revenues)
          );
        } else {
          return '-';
        }
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_settings.scss';
  @import '@/sass/custom/_variables.scss';
  @import '@/variables.scss';
  .sme-header {
    margin-bottom: 16px;
  }
  .sme-description {
    margin-top: 10px;
  }
  .employees-number-title-container {
    margin-top: 24px;
  }
  .field-label {
    display: inline-flex;
    align-items: baseline;
  }
  em {
    color: $ing-dark-gray;
  }
  .company-revenues-title-container {
    margin-top: 16px;
  }
  .company-assets-title-container {
    margin-top: 16px;
  }
  .sme-value {
    font-weight: normal;
    font-size: 14px;
  }
  .mllf-info {
    font-size: 14px;
  }
  @media (max-width: $tablet) {
    .employees-number-title-container,
    .company-revenues-title-container,
    .company-assets-title-container {
      max-width: 80%;
    }
  }
</style>
