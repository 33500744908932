export const titleValues = {
  'Dr.': 'DR',
  'Prof.': 'PROF',
  'Ing.': 'ING',
  'Dipl.-Ing.': 'DPL_ING',
  Sonstiges: 'SONSTIGES',
};

export const IsValidTitle = (titleValue) => {
  const validTitleValues = Object.values(titleValues);
  return validTitleValues.includes(titleValue);
};
