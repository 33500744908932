<template>
  <div
    ref="container"
    class="form-group salutation-container addMaterialDesign"
    data-qa-id="representative-info-salutation"
  >
    <len-radio
      v-model="salutation"
      label="Herr"
      value="Herr"
      name="Salutation"
      :hasError="displayError"
    />
    <len-radio
      v-model="salutation"
      label="Frau"
      value="Frau"
      name="Salutation"
      :hasError="displayError"
    />
    <div
      role="alert"
      class="text-block text-align--left display--block error-container"
    >
      <p v-if="displayError" class="validation">
        {{ errors.salutation && $t('forms.errors.' + errors.salutation) }}
      </p>
    </div>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenRadio },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      displayError() {
        return this.submitClicked && !!this.errors.salutation;
      },
      salutation: {
        get() {
          return this.$store.state.signupForm.salutation;
        },
        set(value) {
          this.SET_SALUTATION(value);
          this.sendFieldTrackingEvent('radio:Geschlecht', value);
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_SALUTATION']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .salutation-container {
    display: flex;
  }
  .salutation-container > label {
    margin-right: 30px;
    margin-left: 0;
  }
  .validation {
    text-align: left;
    margin-top: 2px;
    margin-bottom: 8px;
  }
  @media (max-width: $mobile-l) {
    .validation {
      margin: 0;
    }
  }
</style>
