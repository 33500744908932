<template>
  <len-country-input
    :label="label"
    :error="errors.nationality && $t('forms.errors.' + errors.nationality)"
    :displayErrorIfDirty="!submitClicked"
    :preferredCountries="['DE']"
    name="nationality"
    data-qa-id="representative-info-nationality"
    @change="onChange"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenCountryInput from '@/components/common/len-country-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenCountryInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.nationality.label');
      },
    },
    methods: {
      onChange(iso) {
        this.SET_NATIONALITY(iso);
        this.sendFieldTrackingEvent(`select:${this.label}`, iso);
      },
      ...mapMutations('signupForm', ['SET_NATIONALITY']),
    },
  };
</script>
