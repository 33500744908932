<template>
  <ing-dropdown-input
    :value="loanPurposeBorrower"
    name="loanPurpose__c"
    :placeholder="$t('ingDropdownDefault')"
    :options="loanPurposeOptions"
    :label="label"
    :error="errorMessage"
    :displayErrorIfSubmitted="displayError"
    data-qa-id="loan-purpose"
    @input="handleSelect"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngDropdownInput from '@/components/ing/ing-dropdown-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngDropdownInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    props: {
      isTermLoan: {
        required: true,
        type: Boolean,
      },
    },
    data() {
      return {
        isFocused: false,
      };
    },
    computed: {
      ...mapState('signupForm', [
        'errors',
        'loanPurposeBorrower',
        'submitClicked',
      ]),
      loanPurposeOptions() {
        const options = this.isTermLoan
          ? [
              'workingCapital',
              'debtRefinancing',
              'assetPurchase',
              'expansionGrowthFinancing',
            ]
          : ['workingCapital', 'debtFinancing', 'otherPurposes'];

        return options
          .map((option) =>
            this.$t(`forms.signupForm.loanPurpose.dropdown.${option}`)
          )
          .map((option) => ({
            value: option,
            label: option,
          }));
      },
      label() {
        return this.$t('forms.signupForm.loanPurpose.label');
      },
      displayError() {
        return this.submitClicked && !!this.errors.loanPurposeBorrower;
      },
      errorMessage() {
        return (
          this.errors.loanPurposeBorrower &&
          this.$t('forms.errors.' + this.errors.loanPurposeBorrower)
        );
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_LOAN_PURPOSE']),
      handleSelect(purpose) {
        this.SET_LOAN_PURPOSE(purpose);
        this.$emit('select');
        this.sendFieldTrackingEvent(`select:${this.label}`, purpose);
      },
    },
  };
</script>

<style scoped lang="scss">
  .ing-dropdown-input {
    background-color: #ffffff;
  }
</style>
