<template>
  <div class="form-section">
    <h2 class="form-section__title">
      {{ $t(`${section}.title`, { shyCharacter: '&shy;' }) }}
    </h2>
    <div v-if="paraContent" class="form-section__content">
      <div
        v-for="(para, index) in parasData"
        :key="index"
        class="form-section__para"
        v-html="para"
      />
    </div>
    <div v-else class="form-section__content">
      <slot name="form-content" />
    </div>
  </div>
</template>

<script>
  export default {
    name: 'FormSectionHeader',
    props: {
      section: {
        type: String,
        required: true,
      },
      paraCount: {
        type: Number,
        default: 1,
      },
      i18nUserData: {
        type: Object,
        default: () => {},
      },
    },
    computed: {
      paraContent() {
        return this.paraCount > 0;
      },
      parasData() {
        let paras = [this.$t(`${this.section}.para1`)];
        if (this.paraCount > 1) {
          for (let i = 2; i <= this.paraCount; i++) {
            paras.push(this.$t(`${this.section}.para${i}`, this.i18nUserData));
          }
        }
        return paras;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .form-section {
    font-size: 16px;
    &__title {
      line-height: 36px;
      font-size: 28px;
      padding-bottom: 16px;
      font-weight: 700;
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__content {
      padding-bottom: 40px;
      font-size: 16px;
      line-height: 24px;
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__ordered-list,
    &__unordered-list {
      font-size: 16px;
      line-height: 24px;
      margin-left: 20px;
    }
    &__ordered-list {
      list-style: decimal;
    }
    &__unordered-list {
      list-style: square;
      padding-bottom: 20px;
      margin-top: -15px;
    }
    &__para {
      padding-bottom: 16px;
      &:last-child {
        padding-bottom: 0;
      }
    }
  }
</style>
