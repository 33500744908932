<template>
  <div>
    <label class="label-checkbox">
      <input v-model="noAssetsInformation" type="checkbox" />
      <span class="check" />
      <p class="label-text ing-label">
        {{ $t('forms.signupForm.companyAssets.checkbox') }}
      </p>
    </label>
  </div>
</template>

<script>
  import { mapMutations, mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('signers', ['company']),
      noAssetsInformation: {
        get() {
          return this.company.noAssetsInformation;
        },
        set(value) {
          this.SET_COMPANY_ASSETS_CHECKBOX(value);
          if (value) {
            this.SET_COMPANY_ASSETS_EMPTY();
            this.CLEAR_COMPANY_ASSETS_ERROR();
          }
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_ASSETS_CHECKBOX',
        'SET_COMPANY_ASSETS_EMPTY',
        'CLEAR_COMPANY_ASSETS_ERROR',
      ]),
    },
  };
</script>

<style scoped lang="css">
  .label-text {
    line-height: 22px;
    padding-left: 30px;
  }
</style>
