<template>
  <len-text-input
    v-model="streetNumber"
    :label="$t('forms.signupForm.streetNumber.label')"
    :error="
      errors.company &&
      errors.company.streetNumber &&
      $t('forms.errors.' + errors.company.streetNumber)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="business-street-number"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      streetNumber: {
        get() {
          return this.company.streetNumber;
        },
        set(value) {
          this.SET_COMPANY_STREET_NUMBER(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_STREET_NUMBER',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
    },
  };
</script>
