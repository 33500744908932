<template>
  <div class="header-container">
    <img
      v-if="isBlackSea"
      alt="Company Icon"
      class="company-icon-size"
      src="/ing_company_icon.svg"
    />
    <!-- eslint-disable-next-line vue/max-attributes-per-line -->
    <len-icon v-else icon="old-factory-icon" medium="true" align="center" />
    <div class="text-container">
      <h2>{{ $t('borrowerSignupForm.companyHeader.title') }}</h2>
      <p v-html="$t('signersMissingInfo.formPage.companySection.title.p')" />
    </div>
  </div>
</template>

<script>
  import LenIcon from '@/components/common/len-icon';
  import { isBlackSeaMixin } from '@/mixins/blackSeaMixin';
  export default {
    components: {
      LenIcon,
    },
    mixins: [isBlackSeaMixin],
  };
</script>

<style scoped>
  .company-icon-size {
    width: 64px;
  }
</style>
