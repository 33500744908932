<template>
  <len-date-input
    :label="label"
    :displayErrorIfDirty="!submitClicked"
    :error="errors.dob && $t('forms.errors.' + errors.dob)"
    :value="dob"
    name="dob"
    data-qa-id="representative-info-dob"
    @accept="onAccept"
    @focus="handleFocus"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenDateInput from '@/components/common/len-date-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenDateInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.dob.label');
      },
      dob: {
        get() {
          return this.$store.state.signupForm.dob;
        },
        set(value) {
          this.SET_DOB(value);
        },
      },
      error() {
        return this.errors.dob && this.$t('forms.errors.' + this.errors.dob);
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_DOB']),
      onAccept(value) {
        this.dob = value;
      },
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
