<template>
  <router-link v-if="to && !html" :to="to">
    <slot />
  </router-link>
  <router-link v-else-if="to" :to="to" />
  <a v-else-if="html" :href="href || '/'" v-html="html" />
  <a v-else :href="href || '/'"><slot /></a>
</template>
<script>
  import { mapState } from 'vuex';

  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['name', 'html', 'params'],
    data() {
      return {
        to: null,
        href: null,
      };
    },
    computed: {
      ...mapState('locale', ['locale']),
    },
    watch: {
      name() {
        this.render();
      },
    },
    beforeMount() {
      this.render();
    },
    methods: {
      render() {
        try {
          const { location } = this.$router.resolve(
            { name: this.name + '-' + this.locale, params: this.params },
            this.$route,
            false
          );
          this.to = location;
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
      },
    },
  };
</script>
