import { isValidNationality } from '../helpers/nationality';
import { isEmpty } from '../helpers/string';

export default function ValidateCountryOfIncorporation(iso) {
  if (isEmpty(iso)) {
    return { countryOfIncorporation: 'CountryIsRequired' };
  }
  if (!isValidNationality(iso)) {
    return { countryOfIncorporation: 'CountryIsNotCorrect' };
  }

  return { countryOfIncorporation: undefined };
}
