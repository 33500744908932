import mutations from './auth-mutations';
import * as actions from './auth-actions';
export const initialState = {
  id: null,
  password: null,
  success: {},
  error: {},
  loading: false,
};
const state = () => JSON.parse(JSON.stringify(initialState));
export default { state, mutations, actions, namespaced: true };
