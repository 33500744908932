<template>
  <div class="ing-wrapper">
    <TheHeader />
    <The404 :container="channel" class="not-found__page" />
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import The404 from '@/components/ingOrangeJuice/Structure/The404';
  import APPLICATION_CHANNELS from '@/constants/APPLICATION_CHANNELS';

  export default {
    components: {
      The404,
      TheHeader,
      TheFooter,
    },
    props: {
      channel: {
        type: String,
        default: APPLICATION_CHANNELS.BRIDGE,
      },
    },
    metaInfo() {
      return {
        title: this.$i18n.t('ingPageNotFound.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('ingPageNotFound.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('ingPageNotFound.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .not-found__page {
    display: flex;
    flex-grow: 1;
    padding: 80px 35px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
</style>
