const EMOJIS = [
  '\u00a9',
  '\u00ae',
  '[\u2000-\u3300]',
  '\ud83c[\ud000-\udfff]',
  '\ud83d[\ud000-\udfff]',
  '\ud83e[\ud000-\udfff]',
];

const EMOJIS_REGEX = new RegExp(`(${EMOJIS.join('|')})`, 'g');

const removeEmojis = (value) => {
  if (!value) {
    return '';
  }
  return value.replace(EMOJIS_REGEX, '');
};

export default removeEmojis;
