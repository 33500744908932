import { isEmpty } from '../helpers/string';
import { IsValidIdType } from '../helpers/identificationIdType';

export default function ValidateIdentificationIdType(identificationIdType) {
  if (isEmpty(identificationIdType)) {
    return { identificationIdType: 'IdentificationIdTypeIsRequired' };
  }
  if (!IsValidIdType(identificationIdType)) {
    return { identificationIdType: 'IdentificationIdTypeIsNotCorrect' };
  }

  return { identificationIdType: undefined };
}
