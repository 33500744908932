<template>
  <div class="header-container">
    <len-icon icon="percentage" medium="true" class="percentage-icon-size" />
    <div class="text-container">
      <h2>{{ $t('signersMissingInfo.formPage.offerConditions.title.h2') }}</h2>
      <p>{{ $t('signersMissingInfo.formPage.offerConditions.title.p') }}</p>
    </div>
  </div>
</template>

<script>
  import lenIcon from '@/components/common/len-icon';
  export default {
    components: {
      lenIcon,
    },
  };
</script>

<style scoped>
  .percentage-icon-size {
    width: 74px;
    height: 74px;
  }
</style>
