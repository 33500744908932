import * as actions from './actions';
import mutations from './mutations';

export const initialState = {
  state: null,
  currency: null,
  fee: null,
  loanAmount: null,
  payoutAmount: null,
  interestRate: null,
  monthlyRate: null,
  duration: null,
  error: {},
  success: {},
  currentStep: 1,
  loading: false,
  productType: null,
  wclDisbursementAmount: null,
};
const state = () => JSON.parse(JSON.stringify(initialState));
export default { state, actions, mutations, namespaced: true };
