import { isEmpty } from '../helpers/string';
import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';

export default function ValidateLegalName(company) {
  if (isEmpty(company)) {
    return { legalName: 'LegalNameIsRequired' };
  }
  if (hasForbiddenCharacters(company)) {
    return { legalName: 'ForbiddenCharacters' };
  }

  return { legalName: undefined };
}
