<template>
  <img class="finance-ads-img" alt="" :src="generateImageUrl()" />
</template>

<script>
  import cookie from '@/common/cookie';

  export default {
    name: 'IngFinanceAdsImage',
    props: {
      orderId: {
        type: Number,
        required: true,
      },
    },
    methods: {
      generateImageUrl() {
        const cIdCookie = cookie.get('ci1');
        const financeURL = 'https://www.financeads.net/tl.php';
        const category = 'firmenkredit';
        const programID = '124';
        if (cIdCookie) {
          return `${financeURL}?p=${programID}&oid=${this.orderId}&ocategory=${category}&ovalue=0&s_id=${cIdCookie}`;
        }
        return '';
      },
    },
  };
</script>
<style scoped>
  .finance-ads-img {
    border: none;
    width: 0;
    height: 0;
  }
</style>
