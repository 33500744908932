<template>
  <div>
    <h2>{{ $t(`${keyName}.title`) }}</h2>
    <p class="caption" v-html="$t(`${keyName}.description`)" />
    <p class="ing-phone-email">
      <a :href="'tel:' + $t(`${keyName}.phone`)" class="ing-link">{{
        $t(`${keyName}.phone`)
      }}</a>
      <span class="union">
        {{ $t(`${keyName}.union`) }}
      </span>
      <a :href="'mailto:' + $t(`${keyName}.email`)" class="ing-link">{{
        $t(`${keyName}.email`)
      }}</a>
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      userAlreadyApplied: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      keyName: function () {
        if (this.userAlreadyApplied) {
          return 'ingContact.alreadyApplied';
        }
        return 'ingContact.notAppliedBefore';
      },
    },
  };
</script>
<style scoped lang="scss">
  h2 {
    font-weight: bold;
  }

  .caption {
    font-size: 18px;
    line-height: 27px;
  }

  .ing-phone-email {
    font-size: 24px;
    line-height: 31px;
  }

  .union {
    padding: 0 30px;
    font-size: 18px;
    line-height: 23px;
  }

  @media (max-width: 522px) {
    .ing-wrapper {
      padding-top: 20px;
    }
    .ing-phone-email {
      padding-top: 30px;
    }

    .union {
      display: block;
      padding: 20px 0;
    }
  }
</style>
