import { isEmpty } from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidateStreet(street) {
  if (isEmpty(street)) {
    return { street: 'StreetIsRequired' };
  }
  if (hasForbiddenCharacters(street)) {
    return { street: 'ForbiddenCharacters' };
  }

  return { street: undefined };
}
