import { isEmpty, hasDigit } from '../helpers/string';
import { hasForbiddenCharacters } from '../helpers/nonLatinCharacters';

export default function ValidateLastNameAtBirth(lastNameAtBirth) {
  if (isEmpty(lastNameAtBirth)) {
    return { lastNameAtBirth: undefined };
  }
  if (hasForbiddenCharacters(lastNameAtBirth)) {
    return { lastNameAtBirth: 'ForbiddenCharacters' };
  }
  if (hasDigit(lastNameAtBirth)) {
    return { lastNameAtBirth: 'LastNameAtBirthHasNumeric' };
  }

  return { lastNameAtBirth: undefined };
}
