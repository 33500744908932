<template>
  <div class="tax-number-wrapper">
    <ing-text-input-tooltip
      v-model="taxNumber"
      :label="$t('forms.signupForm.taxNumber.label')"
      :error="error || duplicatedTaxNumberMessage"
      :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
      name="tax-number"
      :tooltipMessage="$t('forms.signupForm.taxNumber.tooltip')"
    />
  </div>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';

  export default {
    components: { IngTextInputTooltip },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      ...mapGetters('signers', [
        'areSignersTaxNumberUnique',
        'personTaxNumberEqualsCompanyTaxNumber',
      ]),
      taxNumber: {
        get() {
          return this.signers[this.signerId].taxNumber;
        },
        set(value) {
          this.SET_TAX_NUMBER({ signerId: this.signerId, taxNumber: value });
        },
      },
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].taxNumber &&
          this.$t('forms.errors.' + this.errors[this.signerId].taxNumber)
        );
      },
      duplicatedTaxNumberMessage() {
        if (
          !this.areSignersTaxNumberUnique ||
          this.personTaxNumberEqualsCompanyTaxNumber(this.signerId)
        ) {
          return this.$t('forms.errors.PersonTaxNumberNotUnique');
        } else {
          return null;
        }
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_TAX_NUMBER']),
    },
  };
</script>

<style scoped lang="css">
  .tax-number-wrapper {
    position: relative;
  }

  :deep(.ing-error) {
    height: fit-content;
  }
</style>
