<template>
  <ing-text-input
    v-model="postcodeDe"
    :label="label"
    :error="errors.postcodeDe && $t('forms.errors.' + errors.postcodeDe)"
    :displayErrorIfDirty="!submitClicked"
    name="postcodeDe"
    inputmode="numeric"
    data-qa-id="company-postcode"
    @focus="handleFocus"
  />
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { IngTextInput },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        return this.$t('forms.signupForm.postcodeDe.label');
      },
      postcodeDe: {
        get() {
          return this.$store.state.signupForm.postcodeDe;
        },
        set(value) {
          this.SET_POSTCODE_DE(value);
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_POSTCODE_DE']),
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
