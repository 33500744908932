<template>
  <ing-text-input
    v-model="city"
    :label="$t('forms.signupForm.city.label')"
    :error="
      errors.company &&
      errors.company.city &&
      $t('forms.errors.' + errors.company.city)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="business-city"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      city: {
        get() {
          return this.company.city;
        },
        set(value) {
          this.SET_COMPANY_CITY(value);
          this.SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS();
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_CITY',
        'SET_COMPANY_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
    },
  };
</script>
