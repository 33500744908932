<template>
  <div class="overview-container__subsection dark-background">
    <ing-edit-button class="pull-right" @edit-button-clicked="$emit('edit')" />
    <div class="row">
      <div class="col-lg-9 col-sm-10">
        <div class="row">
          <div class="col-sm-4 field-label">
            <em>
              {{
                $t(
                  'signersMissingInfo.formPage.offerConditions.form.wcl.firstInstallment.label'
                )
              }}:
            </em>
          </div>
          <div class="field-value col-sm-8">
            {{ disbursementAmountText }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 field-label">
            <em>
              {{
                $t(
                  'signersMissingInfo.formPage.offerConditions.form.wcl.interestFirstMonth'
                )
              }}:
            </em>
          </div>
          <div class="field-value col-sm-8">
            {{ firstMonthInterestRate }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 field-label">
            <em>
              {{
                $t(
                  'signersMissingInfo.formPage.offerConditions.form.wcl.repaymentPortion'
                )
              }}:
            </em>
          </div>
          <div class="field-value col-sm-8">
            {{
              $t(
                'signersMissingInfo.formPage.offerConditions.form.wcl.repaymentPortionValue'
              )
            }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4 field-label">
            <em>
              {{
                $t(
                  'signersMissingInfo.formPage.offerConditions.form.wcl.firstMonthRepaymentAmount'
                )
              }}:
            </em>
          </div>
          <div
            class="field-value col-sm-8"
            data-test-id="first-month-repayment"
          >
            {{ firstMonthRepaymentAmount }} **
          </div>
        </div>
      </div>
      <p class="offer-conditions-details-information__description">
        {{
          $t(
            'signersMissingInfo.formPage.offerConditions.form.wcl.firstInstallment.description.viewMode'
          )
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import IngEditButton from '@/components/ing/signers/ing-edit-button';
  import { currencyAmount } from '@/utils/currency';
  import {
    firstMonthRepaymentAmount,
    firstMonthInterestRate,
  } from '@/utils/wcl';

  export default {
    name: 'IngOfferConditionsDetailsInformation',
    components: {
      IngEditButton,
    },
    computed: {
      ...mapState('signers', ['offerConditions']),
      disbursementAmountText() {
        return this.isPartialDisbursement
          ? this.formattedAmount
          : this.$t(
              'signersMissingInfo.formPage.offerConditions.form.wcl.fullDisbursement',
              {
                amount: this.formattedAmount,
              }
            );
      },
      isPartialDisbursement() {
        return (
          this.offerConditions.wclDisbursementAmount !==
          this.offerConditions.loanAmount
        );
      },
      formattedAmount() {
        return currencyAmount(this.offerConditions.wclDisbursementAmount, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
      firstMonthInterestRate() {
        return currencyAmount(
          firstMonthInterestRate(
            Number(this.offerConditions.wclDisbursementAmount),
            Number(this.offerConditions.interestRate) / 100
          )
        );
      },
      firstMonthRepaymentAmount() {
        return currencyAmount(
          firstMonthRepaymentAmount(
            Number(this.offerConditions.wclDisbursementAmount),
            Number(this.offerConditions.interestRate) / 100
          )
        );
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../../../../sass/custom/variables';
  .offer-conditions-details-information__description {
    margin: 10px 0 10px -0.25em;
    font-size: 14px;
  }

  .field-label {
    em {
      color: $ing-dark-gray;
    }
  }
</style>
