import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import debounce from 'lodash/debounce';
import Dropdown from '@/components/common/components/base-dropdown';
import IngFormLoader from '@/components/common/forms/ing-form-loader';
import CompanyLoader from '@/components/common/forms/company-information/company-loader.vue';
import safariAutocompleteName from '@/utils/safariAutocompleteName';
import removeEmojis from '@/utils/emojis';

export const companyFieldFunctionality = {
  components: {
    Dropdown,
    IngFormLoader,
    CompanyLoader,
  },
  data() {
    return {
      isFocused: false,
      isDirty: false,
      crefoIdIsChanged: false,
    };
  },
  computed: {
    company: {
      get() {
        return this.$store.state.signupForm.company;
      },
      set(value) {
        this.SET_COMPANY_NAME(value);
        this.RESET_PREFILLED_FIELDS();
        if (!value || value.length < 3) {
          this.SET_COMPANIES(null);
          return;
        }
        this.getFWCompaniesDebounced(this);
      },
    },
    searchingCompany() {
      return this.$store.state.signupForm.searchingCompany;
    },
    companiesOptions() {
      if (!Array.isArray(this.companies)) {
        return [];
      }

      return this.companies.map(
        ({ mappingId, companyName, street, postalCode, city }) => {
          const countOfTheSameCompanies = this.companies.filter(
            (company) => company.companyName === companyName
          ).length;
          if (countOfTheSameCompanies > 1) {
            return {
              id: mappingId,
              name: `<span class="company-name">
                    ${companyName}
                  </span>
                  <span class="company-address">
                    ${street}, ${postalCode} ${city}
                  </span>`,
            };
          }
          return {
            id: mappingId,
            name: `<span class="company-name">${companyName}</span>`,
          };
        }
      );
    },
    displayError() {
      const c = this.errors.company && !this.isFocused;
      if (!this.submitClicked) {
        return c && this.isDirty;
      }
      return c;
    },
    displayCompanyDropdown() {
      return this.companiesOptions.length > 0 && this.isFocused;
    },
    safariAutocompleteOffName() {
      return safariAutocompleteName('Firmenname');
    },
    ...mapState('signupForm', [
      'errors',
      'companies',
      'submitClicked',
      'searchingCompany',
      'mappingId',
    ]),
    ...mapGetters('signupForm', ['isPartner']),
  },
  watch: {
    mappingId(newValue, oldValue) {
      this.crefoIdIsChanged = newValue !== oldValue;
    },
  },
  methods: {
    onInput(event) {
      const value = removeEmojis(event.target.value);
      this.company = value;
      event.target.value = value;
    },
    onBlur() {
      setTimeout(() => {
        this.isFocused = false;

        if (this.crefoIdIsChanged) {
          if (this.mappingId) {
            this.sendTrackingEvent();
          } else {
            this.sendTrackingEvent(false);
          }
          this.crefoIdIsChanged = false;
        }
      }, 200);
    },
    onFocus() {
      this.isFocused = true;
      this.isDirty = true;

      this.sendFieldTrackingEvent(`click:${this.label}`);
    },
    onSelectCompany(mappingId) {
      const companyItem = this.companies.find((c) => c.mappingId === mappingId);

      if (companyItem) {
        this.setFwCompany(companyItem);
        this.getCompanyLegalForm(companyItem.mappingId);
      }
    },
    sendTrackingEvent(companyLookupIsUsed = true) {
      this.$gtm &&
        this.$gtm.trackEvent({
          event: 'GTMevent',
          eventCategory: 'application-form',
          eventAction: 'company-lookup',
          eventLabel: companyLookupIsUsed
            ? 'Company Lookup'
            : 'Company Lookup was aborted',
        });
    },
    setFwCompany(companyItem) {
      this.SET_FW_COMPANY(companyItem);
    },
    setSearchingCompany(searchingCompany) {
      this.SET_SEARCHING_COMPANY(searchingCompany);
    },
    getFWCompaniesDebounced: debounce(function (self) {
      self.setSearchingCompany(true);
      self
        .getFWCompanies()
        .then(() => {
          self.setSearchingCompany(false);
        })
        .catch((e) => {
          // eslint-disable-next-line
            console.error(e);
          self.setSearchingCompany(false);
        });
    }, 500),
    ...mapActions('signupForm', ['getFWCompanies', 'getCompanyLegalForm']),
    ...mapMutations('signupForm', [
      'SET_COMPANY_NAME',
      'SET_FW_COMPANY',
      'SET_SEARCHING_COMPANY',
      'SET_COMPANIES',
      'RESET_PREFILLED_FIELDS',
    ]),
  },
};
