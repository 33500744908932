<template>
  <div
    class="len-native-select"
    :class="{ 'len-native-select--disabled': disabled }"
  >
    <select
      class="len-native-select__element form-control"
      v-bind="$attrs"
      data-lpignore="true"
      :disabled="disabled"
      v-on="listeners"
    >
      <option disabled value=""> &nbsp; </option>
      <option
        v-for="(option, index) in options"
        :key="`${option.value}${index}`"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>
    <div class="len-native-select__icon">
      <Arrow />
    </div>
  </div>
</template>

<script>
  import Arrow from '@/assets/arrow';
  import inputMixin from '@/mixins/inputMixin';

  export default {
    name: 'NativeSelect',
    components: {
      Arrow,
    },
    mixins: [inputMixin],
    inheritAttrs: false,
    props: {
      options: {
        type: Array,
        required: true,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables.scss';

  .len-native-select {
    position: relative;
    display: flex;
    border-radius: 4px;

    &__icon,
    &__element {
      font-size: 16px;
      line-height: 20px;
    }

    &__icon {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      padding: 6px 0 6px 15px;
      z-index: 0;
    }

    &__element {
      cursor: pointer;
      padding-right: 29px;
      background: transparent;
      outline: none;
      border: none;
      width: 100%;
      position: relative;
      z-index: 1;
      font-family: inherit;
      line-height: 20px !important;

      /* for Firefox */
      -moz-appearance: none;
      /* for Chrome */
      -webkit-appearance: none;
      /* For IE10 */
      &::-ms-expand {
        display: none;
      }
    }
  }
</style>

<style lang="scss">
  .len-native-select--disabled {
    .len-native-select__element,
    .len-native-select__icon {
      color: #9d9d9d;
      cursor: default;
      opacity: 1;

      svg path {
        stroke: #9d9d9d;
      }
    }
  }
</style>
