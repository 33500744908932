import { render, staticRenderFns } from "./ing-ident-privacy-policy.vue?vue&type=template&id=9281fe34&scoped=true"
var script = {}
import style0 from "./ing-ident-privacy-policy.vue?vue&type=style&index=0&id=9281fe34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9281fe34",
  null
  
)

export default component.exports