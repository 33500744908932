<template>
  <div :class="{ alert: isInvalid }">
    <div class="section-header-row">
      <span class="section-header__title">
        <ClientTitleSection :signerId="signerId" />
      </span>
      <div>
        <IngButton
          type="submit"
          class="outlined small edit-button"
          @click="$emit('edit-button-clicked')"
        >
          {{ $t('signers.form.buttons.edit') }}
        </IngButton>
      </div>
    </div>
    <div class="client-info-container">
      <div class="client-info-container__col">
        <div class="section">
          <div class="section__list">
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.salutation') }}:
              </span>
              <span>
                {{ genderValue | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span> {{ $t('signers.clientDetails.client.title') }}: </span>
              <span>
                {{ titleValue | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span> {{ $t('signers.clientDetails.client.firstName') }}: </span>
              <span>
                {{ signerInformation.firstName | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span> {{ $t('signers.clientDetails.client.lastName') }}: </span>
              <span>
                {{ signerInformation.lastName | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.lastNameAtBirth') }}:
              </span>
              <span>
                {{ signerInformation.lastNameAtBirth | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row section__row--divider">
              <span>
                {{ $t('signers.clientDetails.client.dateOfBirth') }}:
              </span>
              <span>
                {{
                  signerInformation.birthday
                    | momentjsFormat('L')
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.countryOfBirth') }}:
              </span>
              <span>
                {{
                  signerInformation.countryOfBirth
                    | convertCountryIsoToName
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.cityOfBirth') }}:
              </span>
              <span>
                {{ signerInformation.cityOfBirth | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row section__row--divider">
              <span>
                {{ $t('signers.clientDetails.client.nationality') }}:
              </span>
              <span>
                {{
                  signerInformation.nationality
                    | convertCountryIsoToName
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.secondNationality') }}:
              </span>
              <span>
                {{
                  signerInformation.secondNationality
                    | convertCountryIsoToName
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row section__row--divider">
              <span> {{ $t('signers.clientDetails.client.mobile') }}: </span>
              <span>
                {{ signerInformation.mobilePhone | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span class="data-section__address">
                {{ $t('signers.clientDetails.client.email') }}:
              </span>
              <span>
                {{ signerInformation.email | emptyValueReplacer }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="client-info-container__col">
        <div class="section">
          <div class="section__list">
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.registrationAddress') }}:
              </span>
              <span class="section__address">
                {{ currentAddress }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.mailingAddress') }}:
              </span>
              <span class="section__address">
                {{ currentAddress }}
              </span>
            </div>
            <div class="section__row section__row--divider">
              <span>
                {{ $t('signers.clientDetails.client.identificationDocument') }}:
              </span>
              <span>
                {{ idTypevalue | emptyValueReplacer }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('forms.signupForm.identificationIdNumber.ID') }}/{{
                  $t('forms.signupForm.identificationIdNumber.PASSPORT')
                }}:
              </span>
              <span>
                {{
                  signerInformation.identificationIdNumber | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span> {{ $t('signers.clientDetails.client.authority') }}: </span>
              <span>
                {{
                  signerInformation.identificationIssuingAuthority
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.placeOfIssue') }}:
              </span>
              <span>
                {{
                  signerInformation.identificationPlaceOfIssue
                    | convertCountryIsoToName
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.dateOfIssue') }}:
              </span>
              <span>
                {{
                  signerInformation.identificationIssuingDate
                    | momentjsFormat('L')
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row">
              <span>
                {{ $t('signers.clientDetails.client.dateOfExpiry') }}:
              </span>
              <span>
                {{
                  signerInformation.identificationExpiryDate
                    | momentjsFormat('L')
                    | emptyValueReplacer
                }}
              </span>
            </div>
            <div class="section__row section__row--divider">
              <span>
                {{ $t('signers.clientDetails.client.taxIdNumber') }}:
              </span>
              <span>
                {{ signerInformation.taxNumber | emptyValueReplacer }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="section-header-row">
      <span class="section-header__title">
        <p
          v-show="shouldShowMissingUBOsSentence"
          class="ubo-sentence col-md-9 col-sm-9 col-xs-12"
        >
          {{ $t('signers.clientDetails.footNote') }}
          <a
            class="link"
            :href="`mailto:${$translationConstantValues.businessEmail}`"
          >
            {{ $translationConstantValues.businessEmail }}
          </a>
        </p>
      </span>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { genderValues } from '@/validators/helpers/gender';
  import { idTypeValues } from '@/validators/helpers/identificationIdType';
  import { titleValues } from '@/validators/helpers/title';
  import { roleValues } from '@/validators/helpers/role';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import ClientTitleSection from '@/components/signers/ClientInformation/ClientTitleSection';
  import {
    isPartnerChannel,
    isRegularLoanRequest,
  } from '@/mixins/signersMixin';
  export default {
    components: {
      IngButton,
      ClientTitleSection,
    },
    mixins: [isPartnerChannel, isRegularLoanRequest],
    props: {
      signerId: {
        type: String,
        default: '',
      },
      signerIndex: {
        type: Number,
        default: 0,
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        genderValues,
        idTypeValues,
        titleValues,
        roleValues,
      };
    },
    computed: {
      ...mapState('signers', ['signers']),
      signerInformation() {
        return this.signers[this.signerId];
      },
      isLastSigner() {
        return this.signerIndex === Object.keys(this.signers).length - 1;
      },
      shouldShowMissingUBOsSentence() {
        return (
          this.isLastSigner &&
          (this.isRegularLoanRequest || this.isPartnerChannel)
        );
      },
      currentAddress() {
        const { street, streetNumber, zip, city, country } =
          this.signerInformation;
        if (street) {
          return `${street} ${streetNumber},\r\n${zip} ${city}, ${country}`;
        } else {
          return '-';
        }
      },
      postalAddress() {
        const {
          postalStreet,
          postalStreetNumber,
          postalZip,
          postalCity,
          postalCountry,
          homeAddressIsPostalAddress,
        } = this.signerInformation;
        if (postalStreet) {
          return `${postalStreet} ${postalStreetNumber},\r\n${postalZip} ${postalCity}, ${postalCountry}`;
        } else if (homeAddressIsPostalAddress) {
          return this.currentAddress;
        } else {
          return '-';
        }
      },
      genderValue() {
        return Object.keys(this.genderValues).find(
          (key) => this.genderValues[key] === this.signerInformation.gender
        );
      },
      titleValue() {
        return Object.keys(this.titleValues).find(
          (key) => this.titleValues[key] === this.signerInformation.title
        );
      },
      idTypevalue() {
        return Object.keys(this.idTypeValues).find(
          (key) =>
            this.idTypeValues[key] ===
            this.signerInformation.identificationIdType
        );
      },
      roleValue() {
        let deRoleValues = this.signerInformation.roles.map((role) => {
          return Object.keys(this.roleValues).find(
            (key) => this.roleValues[key] === role
          );
        });
        return deRoleValues.filter((role) => !!role).join(', ');
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';
  @import '@/sass/components/common/ing.orange.juice';

  .section-header-row {
    display: flex;
    padding-bottom: 2rem;
    flex-direction: column;
    gap: 1rem;

    @include mq-size(s) {
      flex-direction: row;
      justify-content: space-between;
    }

    &__title {
      font-weight: 700;
      font-size: 19px;
      line-height: 28px;
      color: $Primary-Grey600;
    }

    .ubo-sentence {
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      color: $Primary-Grey600;
      padding-top: 2rem;
      .link {
        color: $Secondary-Indigo500;
        font-weight: 700;
        text-decoration: underline;
      }
    }
  }

  .alert {
    border: solid 3px $Functional-Minus500;
    padding: 6px;
    border-radius: 4px;
    margin-bottom: 1rem;
  }

  .client-info-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    &__col {
      flex-basis: 100%;
      box-sizing: content-box;
      @include mq-size(s) {
        flex-basis: 87.15%;
      }
      @include mq-size(m) {
        flex-basis: 48.5%;
      }
      @include mq-size(l) {
        flex-basis: 48.5%;
      }
    }

    .section {
      display: flex;
      column-gap: 3%;
      row-gap: 48px;
      flex-wrap: wrap;
      padding-bottom: 48px;
      &:last-child {
        padding-bottom: 0;
      }
      &__list {
        flex-basis: 100%;
        @include mq-size(xl) {
          flex-basis: 98.5%;
        }
      }
      &__address {
        white-space: pre-line;
      }
      &__row {
        display: flex;
        column-gap: 3%;
        flex-wrap: wrap;
        @include mq-size(s) {
          column-gap: 3%;
        }
        @include mq-size(xl) {
          column-gap: 5%;
        }
        &--divider {
          padding-top: 24px;
        }
        &--header {
          padding-bottom: 8px;
          @include mq-size(s) {
            text-align: right;
          }
        }
        &--header#{&} span {
          font-size: 19px;
          line-height: 28px;
          font-weight: 700;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          flex-basis: 100%;
          word-break: break-all;
          @include mq-size(s) {
            flex-basis: 48.5%;
          }
          @include mq-size(xl) {
            flex-basis: 47.5%;
          }
          &:first-child {
            @include mq-size(s) {
              text-align: right;
            }
          }
          &:last-child {
            font-weight: 700;
            padding-bottom: 5px;
            min-height: 24px;
            @include mq-size(s) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
