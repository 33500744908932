<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('signers.clientDetails.client.contact') }}
      </h3>
      <TextInput
        v-model="mobilePhone"
        inputSize="s"
        prefix="+49"
        :label="$t('signers.clientDetails.client.mobile')"
        :error="getError('mobilePhone')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="email"
        :label="$t('signers.clientDetails.client.email')"
        :error="getError('email')"
        :displayErrorIfDirty="displayError()"
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import { getFormattedNumber } from '@/validators/helpers/phone';

  export default {
    components: {
      TextInput,
      FormSubSection,
    },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['submitClicked', 'signers', 'errors']),
      email: {
        get() {
          return this.signers[this.signerId].email;
        },
        set(value) {
          this.SET_EMAIL({ signerId: this.signerId, email: value });
        },
      },
      mobilePhone: {
        get() {
          return this.signers[this.signerId].mobilePhone?.replace('+49', '');
        },
        set(value) {
          this.SET_MOBILE_PHONE({
            signerId: this.signerId,
            mobilePhone: getFormattedNumber(value),
          });
        },
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_EMAIL', 'SET_MOBILE_PHONE']),
      getError(field) {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId][field] &&
          this.$t('forms.errors.' + this.errors[this.signerId][field])
        );
      },
      displayError() {
        return (
          !this.submitClicked && !this.signers[this.signerId].submitClicked
        );
      },
    },
  };
</script>
