<template>
  <form
    v-if="!formSuccessfullySent"
    method="post"
    accept-charset="utf-8"
    @submit.prevent="onSubmit"
  >
    <PageHeader
      key="becomePartnerFormHeader"
      page="becomePartner"
      sectionPadding="sm"
    />
    <IngFormLoader :manuallyShow="showLoader" class="loader-style" />
    <PageSection sectionPadding="no">
      <template #section-content>
        <div class="become-partner-form">
          <fieldset class="become-partner-form__fieldset">
            <legend>{{ $t('becomePartner.form.person.header') }}</legend>
            <RadioGroup
              v-model="partner.gender"
              :radioOptions="genderOptions"
              :rowList="true"
              inputSize="xs"
              :title="$t('formFields.gender.label')"
            />
            <TextInput
              v-model="partner.firstName"
              :error="getError('firstName')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.firstName.label')"
              data-qa-id="first-name"
            />
            <TextInput
              v-model="partner.lastName"
              :error="getError('lastName')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.lastName.label')"
              data-qa-id="last-name"
            />
          </fieldset>
          <fieldset class="become-partner-form__fieldset">
            <legend>{{ $t('becomePartner.form.contact.header') }}</legend>
            <TextInput
              v-model="partner.phone"
              :error="getError('phone')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.mobilePhone.label')"
              inputSize="s"
              prefix="+49"
              inputmode="decimal"
              data-qa-id="mobile-number"
            />
            <TextInput
              v-model="partner.email"
              :error="getError('email')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.email.label')"
              inputmode="email"
              data-qa-id="email"
            >
              <template #info>
                <i18n path="formFields.email.emailInfo.registerForm" tag="div">
                  <a
                    :href="`mailto:${$translationConstantValues.serviceEmail}`"
                    place="linkTo"
                    >{{ $translationConstantValues.serviceEmail }}</a
                  >
                </i18n>
              </template>
            </TextInput>
          </fieldset>
          <fieldset class="become-partner-form__fieldset">
            <legend>{{ $t('becomePartner.form.firm.header') }}</legend>
            <TextInput
              v-model="partner.company"
              :error="getError('company')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.companyName.label')"
              data-qa-id="company-name"
            />
            <TextInput
              v-model="partner.companyTaxNumber"
              :error="getError('companyTaxNumber')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.companyTaxNumber.label')"
              data-qa-id="company-tax-number"
            />
            <TextInput
              v-model="partner.street"
              :error="getError('street')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.street.label')"
              data-qa-id="street-name-and-number"
            />
            <TextInput
              v-model="partner.address"
              :error="getError('address')"
              :displayErrorIfDirty="!submitClicked"
              :label="
                $t('formFields.address.label', {
                  optional: $t('formFields.optional'),
                })
              "
              data-qa-id="additional-address"
            />
            <TextInput
              v-model="partner.postcodeDe"
              :error="getError('postcodeDe')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.postcode.label')"
              inputSize="xs"
              data-qa-id="applicant-postcode"
            />
            <TextInput
              v-model="partner.city"
              :error="getError('city')"
              :displayErrorIfDirty="!submitClicked"
              :label="$t('formFields.city.label')"
              data-qa-id="applicant-city"
            />
          </fieldset>
          <fieldset class="become-partner-form__fieldset">
            <legend>{{ $t('becomePartner.form.info.header') }}</legend>
            <CheckBox
              v-model="partner.privacyPolicy"
              :error="getError('privacyPolicy')"
              :displayErrorIfSubmitted="submitClicked"
              data-qa-id="consent"
            >
              <template #label>
                <strong>
                  <i18n
                    path="becomePartner.form.info.privacyPolicy.label"
                    :tag="false"
                  >
                    <a
                      :href="$translationConstantValues.privacyPolicyUrl"
                      place="linkText"
                      target="_blank"
                      >{{
                        $t('becomePartner.form.info.privacyPolicy.linkText')
                      }}</a
                    >
                  </i18n>
                </strong>
              </template>
            </CheckBox>
          </fieldset>
          <FieldStatus
            v-if="serverError"
            :error="serverError"
            class="general-error-container"
          />
          <IngButton type="submit" data-qa-id="submit">
            {{ $t('formFields.registerPartnerButton.label') }}
          </IngButton>
        </div>
      </template>
    </PageSection>
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
  </form>
  <PageHeader
    v-else
    key="becomePartnerSuccessFormHeader"
    page="becomePartner.success"
  />
</template>

<script>
  import findKey from 'lodash/findKey';

  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import RadioGroup from '@/components/ingOrangeJuice/Base/RadioGroup';
  import CheckBox from '@/components/ingOrangeJuice/Base/CheckBox';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';

  import { becomePartnerFormValidator } from '@/validators';
  import { postPartnerApplication } from '@/webservice/partner';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';

  export default {
    name: 'IngBecomePartnerForm',
    components: {
      TextInput,
      RadioGroup,
      CheckBox,
      IngButton,
      IngFormLoader,
      PageSection,
      PageHeader,
      FieldStatus,
    },
    mixins: [dropdownOptionsMixin],
    data() {
      return {
        partner: {
          gender: '',
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          company: '',
          companyTaxNumber: '',
          street: '',
          address: '',
          postcodeDe: '',
          city: '',
          privacyPolicy: false,
        },
        formSuccessfullySent: false,
        submitClicked: false,
        errors: {},
        showLoader: false,
        serverError: '',
      };
    },
    watch: {
      partner: {
        deep: true,
        immediate: true,
        handler() {
          this.validateForm();
        },
      },
    },
    methods: {
      getError(field) {
        return (
          this.errors[field] && this.$t('forms.errors.' + this.errors[field])
        );
      },
      async onSubmit() {
        this.submitClicked = true;
        this.serverError = '';
        this.showLoader = true;
        if (this.formDataIsValid()) {
          try {
            await postPartnerApplication(this.getPayload());
            this.formSuccessfullySent = true;
            this.$emit('partner-register-success');
          } catch (error) {
            if (error.message === 'PartnerEmailAlreadyExists') {
              this.errors.email = error.message;
            } else {
              this.serverError = this.$t('forms.signupForm.errors.500');
            }
          }
        }
        this.showLoader = false;
      },
      validateForm() {
        this.errors = becomePartnerFormValidator(this.partner);
      },
      formDataIsValid() {
        return !findKey(this.errors);
      },
      getPayload() {
        const {
          company,
          companyTaxNumber,
          phone,
          postcodeDe,
          address,
          ...restOfTheFieldsToSend
        } = this.partner;

        return {
          ...restOfTheFieldsToSend,
          companyName: company,
          companyTaxNumber: companyTaxNumber,
          phoneNumber: phone,
          postalCode: postcodeDe,
          addressLine2: address,
        };
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .become-partner-form {
    @include mq-size(s) {
      width: 92.36%;
    }
    @include mq-size(m) {
      width: 69.9%;
    }
    @include mq-size(xl) {
      width: 62.02%;
    }

    &__fieldset {
      padding: 0 0 px2rem(24px);
      legend {
        background: transparent;
        color: $Primary-Grey600;
        text-align: left;
        padding: 0;
        margin: 0 0 px2rem(12px) 0;
        font-size: px2rem(19px);
      }
    }
  }
  .general-error-container {
    margin-block: 0 40px;
  }
</style>
