import trim from 'lodash/trim';

export function trimValuesInObject(objectToTrim = {}) {
  for (let key in objectToTrim) {
    if (
      objectToTrim.hasOwnProperty(key) &&
      typeof objectToTrim[key] === 'string'
    ) {
      objectToTrim[key] = trim(objectToTrim[key]);
    }
  }
}

export function removeDayFromDate(dateString) {
  return dateString ? dateString.substr(3) : dateString;
}

export function sanitizePath(path) {
  return path ? path.replace(/(\/{2,})/g, '/') : path;
}

export function stringToFloatWithTwoDecimals(value) {
  const parsedValue = Number.parseFloat(value);
  if (Number.isNaN(parsedValue)) {
    return null;
  }

  return parsedValue.toFixed(2);
}

export function monthsToDays(months) {
  const parsedValue = Number.parseInt(months);

  if (Number.isNaN(parsedValue)) {
    return null;
  }

  return parsedValue * 30;
}

export function removeUrlProtocol(url) {
  if (!url || typeof url !== 'string') {
    return null;
  }
  return url.replace(/^.*?:\/\//, '');
}
