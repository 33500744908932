<template>
  <div class="company-representative-container">
    <div class="company-representative-container__col--fullWidth">
      <RadioGroup
        v-model="salutation"
        :radioOptions="salutationOptions"
        :rowList="true"
        inputSize="xs"
        :title="$t('formFields.salutation.label')"
        :error="getError('salutation')"
        :displayErrorIfSubmitted="submitClicked"
      />
    </div>
    <div class="company-representative-container__col">
      <TextInput
        v-model="firstName"
        :label="$t('formFields.firstName.label')"
        name="FirstName"
        data-qa-id="first-name"
        :error="getError('firstName')"
        :displayErrorIfDirty="!submitClicked"
      />
      <TextInput
        v-model="lastName"
        :label="$t('formFields.lastName.label')"
        name="LastName"
        data-qa-id="last-name"
        :error="getError('lastName')"
        :displayErrorIfDirty="!submitClicked"
      />
      <TextInput
        v-model="userStreet"
        :label="$t('formFields.street.label')"
        name="Street"
        data-qa-id="applicant-street-name-and-number"
        :error="getError('userStreet')"
        :displayErrorIfDirty="!submitClicked"
      />
      <TextInput
        v-model="userPostcodeDe"
        :label="$t('formFields.postcode.label')"
        :error="getError('userPostcodeDe')"
        :displayErrorIfDirty="!submitClicked"
        inputSize="xs"
        name="userPostcodeDe"
        inputmode="numeric"
        data-qa-id="applicant-postcode"
      />
      <TextInput
        v-model="userCity"
        :label="$t('formFields.city.label')"
        name="userCity"
        data-qa-id="applicant-city"
        :error="getError('userCity')"
        :displayErrorIfDirty="!submitClicked"
      />
    </div>
    <div class="company-representative-container__col">
      <DateInput
        ref="userDateOfBirth"
        v-model="dob"
        :label="
          $t('formFields.dateOfBirth.label', {
            maskFormat,
          })
        "
        name="dob"
        inputSize="s"
        data-qa-id="date-of-birth"
        :displayErrorIfDirty="!submitClicked"
        :error="getError('dob')"
      />
      <Dropdown
        v-model="nationality"
        :label="$t('formFields.nationality.label')"
        :options="nationalityDropdownOptions(['DE'])"
        name="nationality"
        data-qa-id="nationality"
        :error="getError('nationality')"
        :displayErrorIfSubmitted="displayError('nationality')"
      />
      <TextInput
        v-model="mobilePhoneBorrower"
        name="mobilePhone"
        :label="$t('formFields.mobilePhone.label')"
        inputmode="numeric"
        inputSize="m"
        prefix="+49"
        :error="getError('mobilePhoneBorrower')"
        :displayErrorIfDirty="!submitClicked"
        autocomplete="nofill"
        data-qa-id="mobile-number"
      />
      <TextInput
        v-model="email"
        :label="$t('formFields.email.label')"
        name="Email"
        data-qa-id="email"
        :error="getError('email')"
        :displayErrorIfDirty="!submitClicked"
      >
        <template #info>
          <i18n :path="emailInfoi18nPath" tag="div">
            <template #linkTo>
              <a :href="`mailto:${$translationConstantValues.serviceEmail}`">{{
                $translationConstantValues.serviceEmail
              }}</a>
            </template>
          </i18n>
        </template>
      </TextInput>
    </div>
  </div>
</template>

<script>
  import RadioGroup from '@/components/ingOrangeJuice/Base/RadioGroup';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import DateInput from '@/components/ingOrangeJuice/Base/DateInput';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import { mapState, mapGetters, mapMutations } from 'vuex';
  import nationalities from '@/components/common/assets/nationalities';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  import DATE_FORMAT from '@/constants/DATE_FORMAT';

  export default {
    components: {
      TextInput,
      DateInput,
      Dropdown,
      RadioGroup,
    },
    mixins: [dropdownOptionsMixin],
    data() {
      return {
        maskFormat: DATE_FORMAT.FULL_DATE,
      };
    },
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      ...mapGetters('signupForm', ['isPartner']),
      emailInfoi18nPath: function () {
        let formName = this.isPartner
          ? 'partnerApplicationForm'
          : 'applicationForm';
        return `formFields.email.emailInfo.${formName}`;
      },
      salutation: {
        get() {
          return this.$store.state.signupForm.salutation;
        },
        set(value) {
          this.SET_SALUTATION(value);
        },
      },
      firstName: {
        get() {
          return this.$store.state.signupForm.firstName;
        },
        set(value) {
          this.SET_FIRST_NAME(value);
        },
      },
      lastName: {
        get() {
          return this.$store.state.signupForm.lastName;
        },
        set(value) {
          this.SET_LAST_NAME(value);
        },
      },
      userStreet: {
        get() {
          return this.$store.state.signupForm.userStreet;
        },
        set(value) {
          this.SET_USER_STREET(value);
        },
      },
      userPostcodeDe: {
        get() {
          return this.$store.state.signupForm.userPostcodeDe;
        },
        set(value) {
          this.SET_USER_POSTCODE_DE(value);
        },
      },
      userCity: {
        get() {
          return this.$store.state.signupForm.userCity;
        },
        set(value) {
          this.SET_USER_CITY(value);
        },
      },
      dob: {
        get() {
          return this.$store.state.signupForm.dob;
        },
        set(value) {
          this.SET_DOB(value);
        },
      },
      nationality: {
        get() {
          return this.$store.state.signupForm.nationality;
        },
        set(value) {
          this.SET_NATIONALITY(value);
        },
      },
      email: {
        get() {
          return this.$store.state.signupForm.email;
        },
        set(value) {
          this.SET_EMAIL(value);
        },
      },
      mobilePhoneBorrower: {
        get() {
          return this.$store.state.signupForm.mobilePhoneBorrower;
        },
        set(value) {
          this.SET_MOBILE_PHONE(value);
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', [
        'SET_SALUTATION',
        'SET_FIRST_NAME',
        'SET_LAST_NAME',
        'SET_NATIONALITY',
        'SET_USER_POSTCODE_DE',
        'SET_USER_STREET',
        'SET_USER_CITY',
        'SET_DOB',
        'SET_EMAIL',
        'SET_MOBILE_PHONE',
      ]),
      displayError(field) {
        return this.submitClicked && !!this.errors[field];
      },
      getError(field) {
        return (
          this.errors[field] && this.$t('forms.errors.' + this.errors[field])
        );
      },
      nationalityDropdownOptions(preferredCountries) {
        const preferredCountryList = [];
        const countries = nationalities.map((country) => {
          if (preferredCountries.includes(country.iso)) {
            preferredCountryList.push({
              label: country.name,
              value: country.iso,
            });
          }
          return {
            label: country.name,
            value: country.iso,
          };
        });

        return [...preferredCountryList, ...countries];
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';

  .company-representative-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    &__col {
      flex-basis: 100%;
      box-sizing: content-box;
      @include mq-size(s) {
        flex-basis: 87.15%;
      }
      @include mq-size(m) {
        flex-basis: 48.5%;
      }
      &--fullWidth {
        flex-basis: 100%;
      }
    }
  }
</style>
