export const TRACK_EVENTS = {
  BRIDGE_APPLICATION: 'BRIDGE_APPLICATION',
  BRIDGE_APPLICATION_SUCCESS: 'BRIDGE_APPLICATION_SUCCESS',
  BRIDGE_CLIENT_UI: 'BRIDGE_CLIENT_UI',
  BRIDGE_CLIENT_UI_SUCCESS: 'BRIDGE_CLIENT_UI_SUCCESS',
  BRIDGE_CLIENT_UI_LOGOUT: 'BRIDGE_CLIENT_UI_LOGOUT',
  BRIDGE_OFFER_UI: 'BRIDGE_OFFER_UI',
  BRIDGE_OFFER_UI_LOGOUT: 'BRIDGE_OFFER_UI_LOGOUT',
  BRIDGE_PARTNER_APPLICATION: 'BRIDGE_PARTNER_APPLICATION',
  BRIDGE_PARTNER_APPLICATION_SUCCESS: 'BRIDGE_PARTNER_APPLICATION_SUCCESS',

  AMAZON_FIRMENKREDIT_APPLICATION: 'AMAZON_FIRMENKREDIT_APPLICATION',
  AMAZON_FIRMENKREDIT_APPLICATION_SUCCESS:
    'AMAZON_FIRMENKREDIT_APPLICATION_SUCCESS',
  AMAZON_FIRMENKREDIT_CLIENT_UI: 'AMAZON_FIRMENKREDIT_CLIENT_UI',
  AMAZON_FIRMENKREDIT_CLIENT_UI_SUCCESS:
    'AMAZON_FIRMENKREDIT_CLIENT_UI_SUCCESS',
  AMAZON_FIRMENKREDIT_CLIENT_UI_LOGOUT: 'AMAZON_FIRMENKREDIT_CLIENT_UI_LOGOUT',
  AMAZON_FIRMENKREDIT_OFFER_UI: 'AMAZON_FIRMENKREDIT_OFFER_UI',
  AMAZON_FIRMENKREDIT_OFFER_UI_LOGOUT: 'AMAZON_FIRMENKREDIT_OFFER_UI_LOGOUT',

  AMAZON_FLEXKREDIT_APPLICATION: 'AMAZON_FLEXKREDIT_APPLICATION',
  AMAZON_FLEXKREDIT_APPLICATION_SUCCESS:
    'AMAZON_FLEXKREDIT_APPLICATION_SUCCESS',
  AMAZON_FLEXKREDIT_CLIENT_UI: 'AMAZON_FLEXKREDIT_CLIENT_UI',
  AMAZON_FLEXKREDIT_CLIENT_UI_SUCCESS: 'AMAZON_FLEXKREDIT_CLIENT_UI_SUCCESS',
  AMAZON_FLEXKREDIT_CLIENT_UI_LOGOUT: 'AMAZON_FLEXKREDIT_CLIENT_UI_LOGOUT',
  AMAZON_FLEXKREDIT_OFFER_UI: 'AMAZON_FLEXKREDIT_OFFER_UI',
  AMAZON_FLEXKREDIT_OFFER_UI_LOGOUT: 'AMAZON_FLEXKREDIT_OFFER_UI_LOGOUT',
};

export const WEBTREKK_OPTIONS = {
  [TRACK_EVENTS.BRIDGE_APPLICATION]: {
    contentId: 'regular.firmenkredit.applicationPage',
  },
  [TRACK_EVENTS.BRIDGE_APPLICATION_SUCCESS]: {
    contentId: 'regular.firmenkredit.successPage',
  },
  [TRACK_EVENTS.BRIDGE_CLIENT_UI]: {
    contentId: 'regular.firmenkredit.ClientUI',
  },
  [TRACK_EVENTS.BRIDGE_CLIENT_UI_SUCCESS]: {
    contentId: 'regular.firmenkredit.ClientUI_SuccessPage',
  },
  [TRACK_EVENTS.BRIDGE_CLIENT_UI_LOGOUT]: {
    contentId: 'regular.firmenkredit.ClientUI_Logout',
  },
  [TRACK_EVENTS.BRIDGE_OFFER_UI]: {
    contentId: 'regular.firmenkredit.OfferUI',
  },
  [TRACK_EVENTS.BRIDGE_OFFER_UI_LOGOUT]: {
    contentId: 'regular.firmenkredit.OfferUI_Logout',
  },
  [TRACK_EVENTS.BRIDGE_PARTNER_APPLICATION]: {
    contentId: 'partners.firmenkredit.applicationPage',
  },
  [TRACK_EVENTS.BRIDGE_PARTNER_APPLICATION_SUCCESS]: {
    contentId: 'partners.firmenkredit.successPage',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_APPLICATION]: {
    contentId: 'amazon.firmenkredit.applicationPage',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_APPLICATION_SUCCESS]: {
    contentId: 'amazon.firmenkredit.successPage',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_CLIENT_UI]: {
    contentId: 'amazon.firmenkredit.ClientUI',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_CLIENT_UI_SUCCESS]: {
    contentId: 'amazon.firmenkredit.ClientUI_SuccessPage',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_CLIENT_UI_LOGOUT]: {
    contentId: 'amazon.firmenkredit.ClientUI_Logout',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_OFFER_UI]: {
    contentId: 'amazon.firmenkredit.OfferUI',
  },
  [TRACK_EVENTS.AMAZON_FIRMENKREDIT_OFFER_UI_LOGOUT]: {
    contentId: 'amazon.firmenkredit.OfferUI_Logout',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_APPLICATION]: {
    contentId: 'amazon.flexkredit.applicationPage',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_APPLICATION_SUCCESS]: {
    contentId: 'amazon.flexkredit.successPage',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_CLIENT_UI]: {
    contentId: 'amazon.flexkredit.ClientUI',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_CLIENT_UI_SUCCESS]: {
    contentId: 'amazon.flexkredit.ClientUI_SuccessPage',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_CLIENT_UI_LOGOUT]: {
    contentId: 'amazon.flexkredit.ClientUI_Logout',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_OFFER_UI]: {
    contentId: 'amazon.flexkredit.OfferUI',
  },
  [TRACK_EVENTS.AMAZON_FLEXKREDIT_OFFER_UI_LOGOUT]: {
    contentId: 'amazon.flexkredit.OfferUI_Logout',
  },
};

export const TRACK_PRODUCT_NAME = {
  BRIDGE_APPLICATION: 'firmenkredit',
  BRIDGE_PARTNER_APPLICATION: 'firmenkredit-ptr',
  AMAZON_FIRMENKREDIT_APPLICATION: 'firmenkredit-amz',
  AMAZON_FLEXKREDIT_APPLICATION: 'flexkredit-amz',
};

export const TRACK_PRODUCT_STATUS = {
  ADD: 'add',
  SUCCESS: 'conf',
};
export const TRACK_PRODUCT_CUSTOM_EC_PARAMETER = {
  customEcommerceParameter: {
    3: 'abschluss',
  },
};
