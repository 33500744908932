import axios from 'axios';

const axiosWithCredentials = axios.create({ withCredentials: true });

export async function authenticate({ state, commit }) {
  const { id, password } = state;
  try {
    const response = await axiosWithCredentials.post(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/login`,
      { id, password }
    );
    const { status, data } = response;
    commit('SET_SUCCESS', { status, data });
  } catch (error) {
    const { status, data } = error.response;
    commit('SET_ERROR', { status, data });
  }
}

export async function logOut({ commit }) {
  try {
    const response = await axiosWithCredentials.post(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/logout`
    );
    const { status, data } = response;
    commit('SET_SUCCESS', { status, data });
  } catch (error) {
    const { status, data } = error.response;
    commit('SET_ERROR', { status, data });
  }
}
