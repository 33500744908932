import { mutations } from './mutations';
import * as actions from './actions';
import { getters } from './getters';

export const initialState = {
  offerConditions: {
    loanAmount: '',
    durationInMonths: '',
    interestRate: '',
    installment: '',
    channel: '',
    productType: '',
    wclDisbursementAmount: '',
  },
  company: {
    cashIntensity: '',
    companyClientId: '',
    companyName: '',
    companyTradingName: '',
    companyTaxNumber: '',
    countryOfIncorporation: '',
    legalForm: '',
    businessAddressIsPostalAddress: true,
    city: '',
    streetNumber: '',
    street: '',
    zip: '',
    country: '',
    postalCity: '',
    postalStreetNumber: '',
    postalStreet: '',
    postalZip: '',
    postalCountry: '',
    onlineRevenuesAtLeast75Percent: true,
    noAssetsInformation: false,
    server: '',
  },
  controllingCompany: {
    legalName: null,
    countryOfIncorporation: '',
    controllingCompanyTaxNumber: '',
  },
  signers: {},
  identificationProcess: 'AUTOMATION',
  errors: {
    server: null,
    clientServer: null,
    companyServer: null,
    controllingCompany: null,
    offerConditions: null,
  },
  companySnapshot: {},
  controllingCompanySnapshot: {},
  clientSnapshot: {},
  isOfferEditable: false,
  isCompanyEditable: false,
  isClientEditable: false,
  submitClicked: false,
  companySubmitClicked: false,
  noOtherMDCheckbox: false,
  opportunityId: null,
  success: null,
  loading: false,
};

export const signerInitialState = {
  clientId: '',
  gender: '',
  title: '',
  firstName: '',
  lastName: '',
  lastNameAtBirth: '',
  birthday: '',
  countryOfBirth: '',
  homeAddressIsPostalAddress: true,
  cityOfBirth: '',
  nationality: '',
  email: '',
  mobilePhone: '',
  identificationIdType: '',
  identificationIdNumber: '',
  identificationIssuingAuthority: '',
  identificationPlaceOfIssue: '',
  identificationIssuingDate: '',
  identificationExpiryDate: '',
  taxNumber: '',
  street: '',
  streetNumber: '',
  zip: '',
  city: '',
  country: '',
  postalStreet: '',
  postalStreetNumber: '',
  postalZip: '',
  postalCity: '',
  postalCountry: '',
  secondNationality: '',
  submitClicked: false,
  server: '',
};
const state = () => JSON.parse(JSON.stringify(initialState));
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
