<template>
  <div class="edit-button-container" @click="$emit('edit-button-clicked')">
    <img alt="Edit Icon" class="edit-icon-size" src="/ing_edit_pencil.png" />
    <p>{{ $t('ingSignersMissingInfo.edit') }}</p>
  </div>
</template>

<script>
  export default {
    name: 'IngEditButton',
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/settings';
  @import '../../../sass/custom/variables';
  .edit-button-container {
    display: flex;
    flex-direction: row;
    height: fit-content;
    cursor: pointer;
    align-items: center;
    position: relative;
    z-index: 9;
    p {
      font-size: 19px;
      color: $ing-orange;
      margin-left: 10px;
      font-weight: bold;
      margin-bottom: 0;
    }
  }
  .edit-icon-size {
    width: 24px;
    height: 24px;
  }
</style>
