import { render, staticRenderFns } from "./accept-offer-button.vue?vue&type=template&id=10b04093&scoped=true"
import script from "./accept-offer-button.vue?vue&type=script&lang=js"
export * from "./accept-offer-button.vue?vue&type=script&lang=js"
import style0 from "./accept-offer-button.vue?vue&type=style&index=0&id=10b04093&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b04093",
  null
  
)

export default component.exports