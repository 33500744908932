<template>
  <div>
    <ing-section class="hero-skeleton" maxWidth="hero-skeleton-child">
      <template #section-content>
        <div>
          <div class="col-sm-6 form-skeleton__left-col block-container">
            <ing-skeleton-loader type="block" :height="325" />
          </div>
          <div class="col-sm-6 form-skeleton__right-col">
            <ing-skeleton-loader
              type="two-line"
              class="form-skeleton__section"
            />
            <ing-skeleton-loader
              v-for="i in [1, 2, 3]"
              :key="i"
              type="block"
              class="hero-skeleton__section"
            />
          </div>
        </div>
      </template>
    </ing-section>
    <ing-section maxWidth="ing-section-content">
      <template #section-content>
        <ing-skeleton-loader type="one-line" class="form-skeleton__section" />
        <ing-skeleton-loader type="header" class="form-skeleton__section" />
        <ing-skeleton-loader type="block" :height="180" />
      </template>
    </ing-section>
    <ing-section maxWidth="ing-section-content">
      <template #section-content>
        <ing-skeleton-loader type="header" class="form-skeleton__section" />
        <ing-skeleton-loader type="two-line" class="form-skeleton__section" />
        <div class="row">
          <div class="col-sm-6 form-skeleton__left-col">
            <ing-skeleton-loader
              v-for="i in rowCount(4)"
              :key="i"
              class="form-skeleton__section"
              type="two-line"
            />
          </div>
          <div class="col-sm-6 form-skeleton__right-col">
            <ing-skeleton-loader
              v-for="i in rowCount(4)"
              :key="i"
              class="form-skeleton__section"
              type="two-line"
            />
          </div>
        </div>
      </template>
    </ing-section>
    <ing-section maxWidth="ing-section-content">
      <template #section-content>
        <ing-skeleton-loader type="two-line" class="form-skeleton__section" />
        <div class="row">
          <div class="col-sm-6 form-skeleton__left-col">
            <ing-skeleton-loader
              class="form-skeleton__section"
              type="two-line"
            />
          </div>
          <div class="col-sm-6 form-skeleton__right-col">
            <ing-skeleton-loader
              class="form-skeleton__section"
              type="two-line"
            />
          </div>
        </div>
      </template>
    </ing-section>
    <ing-section maxWidth="ing-section-content">
      <template #section-content>
        <ing-skeleton-loader type="header" class="form-skeleton__section" />
        <div class="row">
          <div class="col-sm-6 form-skeleton__left-col">
            <ing-skeleton-loader
              v-for="i in rowCount(5)"
              :key="i"
              class="form-skeleton__section"
              type="two-line"
            />
          </div>
          <div class="col-sm-6 form-skeleton__right-col">
            <ing-skeleton-loader
              v-for="i in rowCount(5)"
              :key="i"
              class="form-skeleton__section"
              type="two-line"
            />
          </div>
        </div>
        <ing-skeleton-loader type="two-line" class="form-skeleton__section" />
      </template>
    </ing-section>

    <ing-section maxWidth="ing-section-content">
      <template #section-content>
        <ing-skeleton-loader type="header" class="form-skeleton__section" />
        <div class="row">
          <div class="col-sm-6 form-skeleton__left-col">
            <ing-skeleton-loader
              class="form-skeleton__section"
              type="one-line"
            />
          </div>
        </div>
        <ing-skeleton-loader
          class="form-skeleton__section"
          type="block"
          :height="110"
        />
        <ing-skeleton-loader
          class="form-skeleton__section"
          type="block"
          :height="55"
        />
      </template>
    </ing-section>
  </div>
</template>

<script>
  import IngSection from '@/components/ing/ing-section';
  import IngSkeletonLoader from '@/components/ing/ing-skeleton-loader';

  export default {
    components: {
      IngSection,
      IngSkeletonLoader,
    },
    methods: {
      rowCount(n) {
        return Array.from(Array(n).keys());
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables.scss';

  .hero-skeleton {
    padding: 80px 115px;
    justify-content: center;
    @media screen and (max-width: $desktop-l) {
      padding: 40px 40px 0 40px;
    }

    @media screen and (max-width: $tablet) {
      padding: 40px 20px 0 20px;
    }

    @media screen and (max-width: $mobile-l) {
      padding: 0 20px;
      flex-direction: column;
      align-items: center;
    }

    &__section {
      margin-top: 24px;
    }

    .block-container {
      @media screen and (max-width: $mobile-l) {
        margin: 0 -20px 24px -20px;
      }
    }
  }

  .form-skeleton {
    &__section {
      margin-bottom: 48px;
    }

    &__left-col {
      padding-right: 30px;

      @media screen and (max-width: $mobile-l) {
        padding-right: 0;
      }
    }

    &__right-col {
      padding-left: 30px;

      @media screen and (max-width: $mobile-l) {
        padding-left: 0;
      }
    }
  }
</style>
