<template>
  <div class="ing-wrapper">
    <ing-header />
    <main class="offer-expired-error-page-container">
      <section class="content-wrapper">
        <img
          alt="Offer Expired Error"
          class="icon"
          :src="imageSrc"
          data-test="image"
        />
        <h1 class="title" data-test="title">
          {{ title }}
        </h1>
        <p class="caption" data-test="caption" v-html="contentCaption" />
      </section>
    </main>
    <ing-footer class="row" />
  </div>
</template>

<script>
  import { enableGTM } from '@/mixins/gtmMixins';
  import IngHeader from '@/components/ing/ing-header';
  import IngFooter from '@/components/ing/ing-footer';

  export default {
    components: {
      IngHeader,
      IngFooter,
    },
    mixins: [enableGTM],
    props: {
      errorPageKey: {
        type: String,
        default: 'ingOfferExpiredErrorPage',
      },
      imageSrc: {
        type: String,
        default: '/ing_event_busy.svg',
      },
    },
    data() {
      return {
        title: this.$i18n.t(`${this.errorPageKey}.title`),
        contentCaption: this.$i18n.t(`${this.errorPageKey}.content.caption`),
      };
    },
    metaInfo() {
      return {
        title: this.$i18n.t(`${this.errorPageKey}.metaTitle`),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t(`${this.errorPageKey}.metaTitle`),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t(`${this.errorPageKey}.metaDescription`),
          },
        ],
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '~@/variables.scss';

  .offer-expired-error-page-container {
    display: flex;
    flex-grow: 1;
    padding: 80px 35px;
    align-items: center;
    justify-content: center;
  }
  .icon {
    width: 64px;
    margin-bottom: 20px;
  }
  .title {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #ff6200;
    margin-bottom: 20px;
  }
  .content-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #808080;
    max-width: 900px;
  }
  .caption {
    font-size: 16px;
    line-height: 24px;
  }

  @media screen and (max-width: $tablet) {
    .content-wrapper {
      max-width: 641px;
    }

    .offer-expired-error-page-container {
      padding: 80px 63px;
    }
  }

  @media screen and (max-width: $mobile-l) {
    .offer-expired-error-page-container {
      padding: 80px 16px;
    }
    .icon {
      width: 50px;
      margin-bottom: 28px;
    }
    .title {
      font-size: 24px;
      line-height: 32px;
      padding: 0 57px;
    }
  }
</style>
