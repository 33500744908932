import { render, staticRenderFns } from "./ident-type-header.vue?vue&type=template&id=1155be49&scoped=true"
import script from "./ident-type-header.vue?vue&type=script&lang=js"
export * from "./ident-type-header.vue?vue&type=script&lang=js"
import style0 from "./ident-type-header.vue?vue&type=style&index=0&id=1155be49&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1155be49",
  null
  
)

export default component.exports