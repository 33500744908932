import LEGAL_STATUS_VALUES from '@/constants/LEGAL_STATUS_VALUES';

export default [
  LEGAL_STATUS_VALUES.GMBH,
  LEGAL_STATUS_VALUES.GMBH_AND_COKG,
  LEGAL_STATUS_VALUES.AG,
  LEGAL_STATUS_VALUES.KG,
  LEGAL_STATUS_VALUES.OHG,
  LEGAL_STATUS_VALUES.UG_LIMITED_LIABILITY,
  LEGAL_STATUS_VALUES.LIMITED,
];
