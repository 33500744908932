var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ing-text-input"},[(_vm.displayLabel)?_c('label',{staticClass:"ing-text-input__label"},[_vm._t("label",function(){return [_vm._v(" "+_vm._s(_vm.label)+" ")]})],2):_vm._e(),(_vm.caption)?_c('div',{staticClass:"ing-text-input__caption"},[_vm._v(" "+_vm._s(_vm.caption)+" ")]):_vm._e(),(_vm.displayPrefix)?_c('div',{staticClass:"ing-text-input__prefix"},[_vm._t("prefix",function(){return [_vm._v(" "+_vm._s(_vm.prefix)+" ")]})],2):_vm._e(),(!_vm.textArea)?_c('input',{ref:"self",staticClass:"ing-text-input__element",class:[
      _vm.inputSize ? 'ing-text-input__element--' + _vm.inputSize : '',
      { 'ing-text-input__element--error': _vm.displayError },
      { 'ing-text-input__element--prefixPadding': _vm.displayPrefix },
    ],attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"inputmode":_vm.inputmode,"type":_vm.inputType,"placeholder":_vm.placeholder,"autocomplete":"nofill","data-lpignore":"true","data-qa-id":_vm.dataQaId,"data-test-id":_vm.dataTestId},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}}):_c('textarea',{staticClass:"ing-text-input__element ing-text-input__element--textarea",class:[
      _vm.inputSize ? 'ing-text-input__element--' + _vm.inputSize : '',
      { 'ing-text-input__element--error': _vm.displayError },
    ],attrs:{"disabled":_vm.disabled,"readonly":_vm.readonly,"inputmode":_vm.inputmode,"type":_vm.inputType,"autocomplete":"nofill","data-lpignore":"true","data-qa-id":_vm.dataQaId,"data-test-id":_vm.dataTestId},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}}),_vm._v(" "),(_vm.displayError)?_c('FieldStatus',{scopedSlots:_vm._u([{key:"error",fn:function(){return [_vm._t("error",function(){return [_vm._v(" "+_vm._s(_vm.error)+" ")]})]},proxy:true}],null,true)}):_vm._e(),(_vm.displayInfo)?_c('FieldStatus',{scopedSlots:_vm._u([{key:"info",fn:function(){return [_vm._t("info",function(){return [_vm._v(" "+_vm._s(_vm.info)+" ")]})]},proxy:true}],null,true)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }