import { isEmpty } from '@/validators/helpers/string';
import { hasForbiddenCharacters } from '@/validators/helpers/nonLatinCharacters';

export default function ValidatePartnerCompanyName(partnerCompanyName) {
  if (isEmpty(partnerCompanyName)) {
    return { partnerCompanyName: 'PartnerCompanyNameIsRequired' };
  }
  if (hasForbiddenCharacters(partnerCompanyName)) {
    return { partnerCompanyName: 'ForbiddenCharacters' };
  }

  return { partnerCompanyName: undefined };
}
