import { initialState } from './index';
export default {
  SET_ID(state, id) {
    state.id = id;
  },
  SET_PASSWORD(state, id) {
    state.password = id;
  },
  CLEAR_PASSWORD(state) {
    state.password = null;
  },
  SET_SUCCESS(state, success) {
    state.success = success;
  },
  SET_ERROR(state, error) {
    state.error = error;
  },
  CLEAR_ERROR(state) {
    state.error = {};
  },
  CLEAR_SUCCESS(state) {
    state.success = {};
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  CLEAR_AUTHENTICATION(state) {
    Object.assign(state, JSON.parse(JSON.stringify(initialState)));
  },
};
