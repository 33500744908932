<template>
  <fieldset :class="{ error: displayError }">
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !value,
        'is-readonly': readonly,
      }"
    >
      <label class="ing-label">
        {{ label }}
        <ing-bs-tooltip
          :key="name + '-tooltip'"
          :tooltipMessage="tooltipMessage"
        />
      </label>

      <input
        ref="input"
        :name="name"
        :value="value"
        :readonly="readonly"
        :inputmode="inputmode"
        :class="{ 'ing-input-error': displayError }"
        type="text"
        class="form-control"
        @input="$emit('input', $event.target.value)"
        @focus="onFocus"
        @keydown="$emit('keydown', $event)"
        @blur="onBlur"
      />
      <div :class="{ active: displayError }" role="alert" class="ing-error">
        <img src="/alert_icon.png" alt="Alert Icon" />
        <p>
          {{ error }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { textInputFunctionality } from '@/mixins/forms/textInputMixin';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  import IMask from 'imask';

  export default {
    components: { IngBsTooltip },
    mixins: [textInputFunctionality],
    props: {
      tooltipMessage: {
        type: String,
        required: true,
      },
      messageClass: {
        type: String,
        default: '',
        required: false,
      },
      maskOptions: {
        type: Object,
        default: undefined,
      },
    },
    data() {
      return {
        mouseOver: false,
      };
    },
    watch: {
      value: function (newVal) {
        if (this.maskOptions && newVal !== this.maskRef.value) {
          this._updateValue();
        }
      },
    },
    mounted() {
      this._initMask();
    },
    methods: {
      showTooltip() {
        this.mouseOver = true;
      },
      hideTooltip() {
        this.mouseOver = false;
      },
      _initMask() {
        if (this.maskOptions) {
          this.maskRef = new IMask(this.$refs['input'], this.maskOptions).on(
            'accept',
            this.onAccept.bind(this)
          );
          this._updateValue();
        }
      },
      onAccept() {
        this.$emit('accept', this.maskRef.value);
      },
      _updateValue() {
        const value = this.value == null ? '' : this.value;
        this.maskRef.value = value;
        this.$emit('input', this.maskRef.value);
      },
    },
  };
</script>

<style type="css" scoped>
  .ing-label {
    padding-right: 5px;
    display: flex;
    align-items: baseline;
  }
</style>
