import axios from 'axios';

export async function validateSubscription({ payload }) {
  try {
    const URL = process.env.VUE_APP_FUNNEL_GATEWAY_API;
    await axios.post(
      `${URL}/email/confirmations`,
      { token: payload },
      { withCredentials: true }
    );
    return 'REGISTERED';
  } catch (error) {
    const { status } = error.response;
    if (status === 301) {
      return 'ALREADY_REGISTERED';
    } else {
      throw new Error('Token validation failed');
    }
  }
}
