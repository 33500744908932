<template>
  <fieldset ref="container" data-qa-id="representative-info-salutation">
    <label>{{ $t('ingSalutationLabel') }} </label>
    <div class="salutation-input-container">
      <len-radio
        v-model="salutation"
        label="Herr"
        value="Herr"
        name="Salutation"
        :hasError="displayError"
        data-qa-id="male"
      />
      <len-radio
        v-model="salutation"
        label="Frau"
        value="Frau"
        name="Salutation"
        :hasError="displayError"
        data-qa-id="female"
      />
    </div>
    <div :class="{ active: displayError }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p>
        {{ errors.salutation && $t('forms.errors.' + errors.salutation) }}
      </p>
    </div>
  </fieldset>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenRadio },
    mixins: [ingFormFieldGTMTrackingMixin],
    computed: {
      displayError() {
        return this.submitClicked && !!this.errors.salutation;
      },
      salutation: {
        get() {
          return this.$store.state.signupForm.salutation;
        },
        set(value) {
          this.SET_SALUTATION(value);
          this.sendFieldTrackingEvent('radio:Geschlecht', value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_SALUTATION']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .salutation-input-container {
    display: flex;
    flex-direction: row;
    margin: 8px 0 14px 0;

    .radio-container {
      margin-bottom: 0;

      &:first-child {
        margin-right: 20px !important;
      }
    }
  }

  @media screen and (max-width: $mobile-l) {
    .salutation-input-container {
      margin: 5px 0 2px 0;
    }
  }
</style>
