<template>
  <len-text-input
    v-model="lastName"
    :label="label"
    :error="errors.lastName && $t('forms.errors.' + errors.lastName)"
    :displayErrorIfDirty="!submitClicked"
    name="LastName"
    data-qa-id="representative-info-lastname"
    @focus="handleFocus"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    components: { LenTextInput },
    mixins: [lenFormFieldGTMTrackingMixin],
    computed: {
      label() {
        return this.$t('forms.signupForm.lastName.label');
      },
      lastName: {
        get() {
          return this.$store.state.signupForm.lastName;
        },
        set(value) {
          this.SET_LAST_NAME(value);
        },
      },
      ...mapState('signupForm', ['errors', 'submitClicked']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_LAST_NAME']),
      handleFocus() {
        this.sendFieldTrackingEvent(`click:${this.label}`);
      },
    },
  };
</script>
