<template>
  <div v-if="process.env.VUE_APP_LENDICO_BASE_URL_CONTENT">
    <img
      v-if="!ratio"
      :src="srcUrl"
      :style="{
        width: width ? width + 'px' : '100%',
        height: Height ? Height + 'px' : 'auto',
        margin: margin ? margin : '0',
      }"
      :class="[align ? 'horizontal-align--' + align : '']"
      class="image-component"
    />
    <div
      v-if="ratio"
      :style="{
        'background-image': 'url(' + srcUrl + ')',
        'background-position': position,
        height: Height ? Height + 'px' : 'inherit',
      }"
      :class="[align ? 'horizontal-align--' + align : '']"
      class="image-component"
      style="
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      "
    />
  </div>
</template>
<script>
  import Window from '@/common/window';

  export default {
    // eslint-disable-next-line vue/require-prop-types
    props: ['width', 'height', 'src', 'ratio', 'position', 'align', 'margin'],
    data() {
      return { Height: 0 };
    },
    computed: {
      srcUrl() {
        return `${process.env.VUE_APP_LENDICO_BASE_URL_CONTENT}/de/images/${this.src}`;
      },
    },
    watch: {
      height() {
        this.Height = this.height;
      },
    },
    beforeMount() {
      this.Height = this.height;

      if (!this.ratio) {
        return;
      }

      Window.addEventListener('resize', this.handleResize);
    },
    mounted() {
      if (this.ratio) {
        this.handleResize();
      }
    },
    beforeDestroy() {
      Window.removeEventListener('resize', this.handleResize);
    },
    methods: {
      handleResize() {
        this.Height =
          (this.$el.offsetWidth || 0) / (parseFloat(this.ratio) || 1);
      },
    },
  };
</script>
