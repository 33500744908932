<template>
  <div>
    <signers-client-information-header />
    <div v-if="!isClientEditable">
      <signer-client-information-overview
        v-for="(signer, id, index) in signers"
        :key="id"
        :signerIndex="index"
        :signerId="id"
        :isInvalid="isClosedFormInvalid(id)"
        @edit-button-clicked="handleEdit(id)"
      />
    </div>
    <signer-client-information-form
      v-if="isClientEditable"
      :signerId="clientToBeEdited"
      :isInvalid="isOpenFormInvalid"
      @cancel-button-clicked="handleClientCancel()"
      @submit-button-clicked="handleClientSubmission()"
    />
  </div>
</template>

<script>
  import { mapMutations, mapState, mapActions, mapGetters } from 'vuex';
  import SignersClientInformationHeader from '@/components/signers/signers-client-information/signers-client-information-header';
  import SignerClientInformationOverview from '@/components/signers/signers-client-information/signer-client-information-overview';
  import SignerClientInformationForm from '@/components/signers/signers-client-information/signer-client-information-form';
  export default {
    components: {
      SignersClientInformationHeader,
      SignerClientInformationOverview,
      SignerClientInformationForm,
    },
    data() {
      return {
        clientToBeEdited: null,
      };
    },
    computed: {
      ...mapGetters('signers', ['isClientValid']),
      ...mapState('signers', [
        'signers',
        'errors',
        'submitClicked',
        'isClientEditable',
      ]),
      isOpenFormMissingData() {
        return (
          this.signers[this.clientToBeEdited] &&
          this.signers[this.clientToBeEdited].submitClicked &&
          !this.isClientValid(this.clientToBeEdited)
        );
      },
      isOpenFormInvalid() {
        if (!this.clientToBeEdited) {
          return false;
        }
        return this.isOpenFormMissingData;
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CLIENT_SUBMIT_CLICKED',
        'SET_IS_CLIENT_EDITABLE',
        'SET_LOADING',
        'CLEAR_CLIENT_REQUEST_ERROR',
        'SET_CLIENT_SNAPSHOT',
        'REVERT_CLIENT_CHANGES',
        'CLEAR_CLIENT_SNAPSHOT',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
      ...mapActions('signers', [
        'updateSignerMissingDetails',
        'getSignersMissingDetails',
      ]),
      isClosedFormInvalid(signerId) {
        return (
          this.submitClicked &&
          !this.isClientEditable &&
          !this.isClientValid(signerId)
        );
      },
      async handleClientSubmission() {
        this.CLEAR_CLIENT_REQUEST_ERROR();
        this.SET_CLIENT_SUBMIT_CLICKED({
          signerId: this.clientToBeEdited,
          submitClicked: true,
        });
        if (!this.isClientValid(this.clientToBeEdited)) {
          return;
        }
        this.submitSigner();
      },
      async submitSigner() {
        this.SET_LOADING(true);
        await this.updateSignerMissingDetails(this.clientToBeEdited);
        if (!this.errors.clientServer) {
          await this.getSignersMissingDetails();
          this.updateSignersPostalAddress();
          this.CLEAR_CLIENT_SNAPSHOT();
          this.SET_IS_CLIENT_EDITABLE(false);
          this.clientToBeEdited = null;
        }
        this.SET_LOADING(false);
      },
      updateSignersPostalAddress() {
        Object.keys(this.signers).forEach((signerId) =>
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(signerId)
        );
      },
      async handleClientCancel() {
        this.CLEAR_CLIENT_REQUEST_ERROR();
        this.REVERT_CLIENT_CHANGES(this.clientToBeEdited);
        this.CLEAR_CLIENT_SNAPSHOT();
        this.SET_IS_CLIENT_EDITABLE(false);
        this.clientToBeEdited = null;
      },
      handleEdit(signerId) {
        this.SET_CLIENT_SNAPSHOT(signerId);
        this.SET_IS_CLIENT_EDITABLE(true);
        this.clientToBeEdited = signerId;
      },
    },
  };
</script>

<style scoped>
  p {
    font-size: 14px;
    line-height: 21px;
  }
  .flex--column {
    flex-direction: column;
  }
</style>
