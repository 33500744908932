export default {
  subscriptionPage: {
    meta: {
      title: 'Für unseren Business-Newsletter anmelden',
      description:
        'Melden Sie sich hier für unseren Newsletter mit Wissen und Inspiration für kleine und mittlere Unternehmen an',
    },
    form: {
      header: {
        title:
          'Produktinfos, Tipps und Inspirationen rund ums Business direkt ins Postfach',
        description1:
          'Melden Sie sich hier für unseren Newsletter an: Und wir melden uns regelmäßig bei Ihnen mit frischem Know-how fürs Business und mit Produktneuigkeiten.',
        description2:
          'Wir schicken Ihnen nach Eingabe Ihrer E-Mail-Adresse eine E-Mail mit einem Link, über den Sie uns erlauben, Ihnen weitere Nachrichten zu schicken.',
        description3: 'Wichtig: Sie müssen keine weiteren Daten eingeben.',
      },
      submit: 'Newsletter abonnieren',
      email: {
        label: 'Ihre E-Mail-Adresse',
      },
      warning: {
        label: 'Bitte beachten Sie unsere {linkText}.',
        linkText: 'Datenschutzerklärung für Geschäftskundinnen und -kunden',
      },
    },
    success: {
      header: {
        title:
          'Vielen Dank für die Anmeldung – bitte schauen Sie einmal in Ihr Postfach',
      },
      body: {
        part1: {
          text: 'Dort sollten Sie innerhalb der nächsten Minuten eine E-Mail von uns bekommen. Mit einem Klick auf den Link erlauben Sie uns, Ihnen E-Mails mit Neuigkeiten und Infos zu schicken. Sie müssen keine weiteren Daten eingeben.',
        },
        part2: {
          text: '{boldContent} Vielleicht ist sie im Spam-Ordner gelandet, schauen Sie dort bitte einmal nach.',
          boldContent: 'Keine E-Mail erhalten?',
        },
        part3: {
          label:
            'Mehr Wissenswertes für Ihr Unternehmen finden Sie auf unserer Website:{linkText}',
          linkText: 'Zum Business Banking',
        },
      },
    },
  },
};
