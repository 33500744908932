import { render, staticRenderFns } from "./last-name-at-birth-field.vue?vue&type=template&id=238ed1d6&scoped=true"
import script from "./last-name-at-birth-field.vue?vue&type=script&lang=js"
export * from "./last-name-at-birth-field.vue?vue&type=script&lang=js"
import style0 from "./last-name-at-birth-field.vue?vue&type=style&index=0&id=238ed1d6&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "238ed1d6",
  null
  
)

export default component.exports