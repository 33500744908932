export default {
  lenderPremiumKontoPage: {
    title: 'Mein Premium-Konto',
    prepaidBalance: 'Kontostand',
    buttonWithdraw: 'Auszahlen',
    p1: 'In diesem Bereich sehen Sie den aktuellen Kontostand Ihres Premium-Kontos mit der Möglichkeit eine Auszahlung zu beantragen, sowie eine Historie der Geldein- und ausgänge der letzten 180 Tage.',
    p2: '',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
  },
  lenderLandingPage: {
    metaTitle: 'Geld anlegen zu starken Renditen',
    metaDescription:
      'Lendico ist die Plattform für Anlagen in Schweizer Unternehmen. Attraktive Renditen und flexible Laufzeiten ermöglichen individuelle Anlagestrategien.',
    title: 'Investieren Sie direkt in <strong>Schweizer Unternehmen</strong>',
    marketItemsList: {
      title: 'Aktuelle Kreditprojekte',
    },
    headerSection: {
      imageAlt:
        'PostFinance: Der Kooperationspartner für den Firmenkredit von Lendico',
      imageSrc: 'PF_Logo/pflogo-white-400px-DE.png',
      panel1: {
        title: 'Seriös und sicher',
        content: 'Jedes Projekt durchläuft eine gewissenhafte Bonitätsprüfung.',
        linkTitle: 'MEHR ERFAHREN &raquo;',
      },
      panel2: {
        title: 'Attraktive Rendite',
        content: 'Monatliche Rückzahlungen direkt auf Ihr Konto.',
        linkTitle: 'MEHR ERFAHREN &raquo;',
      },
    },
    infoTabs: {
      tab1: {
        label: 'ATTRAKTIVE RENDITE',
        title: 'Attraktive Rendite* dank Investition ohne Umwege',
        imageSrc: 'others/infographics/Graphic_risk-classes_trans.png',
        content:
          'Wir geben die Zinserträge der digitalen Kreditvergabe direkt an Sie weiter. Die Anlageprojekte werden nach steigendem Risiko in die fünf Lendico-Klassen A bis E eingeteilt. Mithilfe der Risikoklassen können Sie das gewünschte Verhältnis von Rendite und Risiko selbst festlegen. Je höher Ihre Risikobereitschaft, desto höher auch Ihre Renditechancen. Für unsere Dienstleistung erheben wir lediglich eine Gebühr von 1 % der erhaltenen Rückzahlungen. <br/><br/><i>* Die angestrebte Rendite ist der jährliche Zinssatz vor Abzug der Service-Gebühr, den Anleger für ihr bei Lendico angelegtes Kapital erhalten können. Dabei werden die zu erwartenden Verluste durch evtl. auftretende Zahlungsausfälle berücksichtigt. Die angestrebte Rendite kann daher auch ohne das Auftreten von Ausfällen höher bzw. bei mehr als den zu erwartenden Ausfällen niedriger liegen. Lendico übernimmt keine Garantie dafür, dass ausgewiesene Zinsen (Habenzins) tatsächlich erreicht werden.</i>',
      },
      tab2: {
        label: 'DER LENDICO PROZESS',
        title: 'So funktioniert der Lendico-Prozess',
        imageSrc:
          'others/signup-process/lendico_signup-process_INVEST-full.png',
        content: {
          line1:
            'Lendico ist ein transparenter Marktplatz für Firmenkredite. Wir bringen Kreditnehmer und Anleger zusammen – und vermeiden so die hohen Kosten traditioneller Banken. Diese Ersparnisse geben wir direkt an Sie weiter: In Form von hohen Renditechancen und günstigen Zinsen.',
          line2:
            'Auf der Lendico-Plattform können Sie sich ein individuelles Portfolio zusammenstellen und in konkrete Projekte in der Schweiz investieren. Damit finanzieren Sie gemeinsam mit vielen anderen privaten und institutionellen Investoren die Kredite mittelständischer Unternehmer.',
        },
      },
      tab3: {
        label: 'INDIVIDUELLES PORTFOLIO',
        title: 'Ihre Investitionen immer unter Kontrolle',
        content: {
          line1:
            'Sie entscheiden transparent und flexibel, welches Risiko Sie eingehen wollen. Lendico gewährt Ihnen Einblick in eine Vielzahl von verschiedenen Kreditanfragen und ermöglicht Ihnen so eine fundiert Entscheidung.',
          line2:
            'Je mehr Sie Ihre Investitionen streuen, desto besser können Sie eventuelle Ausfälle ausgleichen und sich so eine konstante monatliche Rendite sichern.',
          line3:
            'Unser Tipp: Mit der Reinvestition Ihrer monatlichen Rückflüsse lassen Sie den Zinseszinseffekt für sich arbeiten.',
        },
      },
      tab4: {
        label: 'SICHERHEIT',
        title: 'Sicher investieren durch verlässliche Bonitätsprüfung',
        content: {
          line1:
            'Um Ihnen die grösstmögliche Stabilität zu gewährleisten, unterziehen unsere erfahrenen Risikoanalysten jedes Projekt einer strengen Bonitätsprüfung.',
          line2:
            "Die Unternehmen müssen mehr als zwei Jahre operativ am Markt tätig sein, im Handelsregister eingetragen sowie einen Mindestumsatz von CHF 100'000 pro Jahr ausweisen. Ferner verlangt Lendico eine grundsätzlich gesunde Finanzlage sowie die Bereitschaft, den Kredit durch einen Garantiegeber abzusichern.",
          line3:
            'Nur wenn Unternehmen diese Kriterien vollständig erfüllen, ziehen wir sie in Betracht für einen Kredit. Auch während der Rückzahlungsphase werden die Unternehmen weiter begleitet.',
        },
        linkTitle: 'Mehr erfahren',
      },
    },
    contactLoginSection: {
      panel1: {
        title: 'Gern beantworten wir Ihre Fragen!',
        linkTitle: 'Kontaktieren Sie unser Service-Team &raquo;',
      },
      panel2: {
        title: 'Sie haben bereits ein Anleger-konto?',
        linkTitle: 'Loggen sie sich hier ein &raquo;',
      },
    },
    advantagesSection: {
      title: 'Ihre Vorteile auf einen Blick',
      panel1: {
        line1:
          '<big>TRANSPARENT:</big> Es gibt bei uns keine versteckten Kosten.',
        line2:
          '<big>ATTRAKTIVE RENDITEN:</big> Die direkte Investition in Schweizer Unternehmen lohnt sich für Sie.',
        line3:
          '<big>FLEXIBILITÄT:</big> Sie haben volle Kontrolle über Ihr persönliches Portfolio.',
      },
      panel2: {
        line1:
          '<big>RISIKOSTREUUNG:</big> Investieren Sie in unterschiedlichste Unternehmen, Branchen und Risikoklassen.',
        line2:
          '<big>MONATLICHE RÜCKZAHLUNGEN:</big> Zins- und Tilgungszahlungen finden Sie direkt in Ihrem Konto.',
        line3:
          '<big>SICHERHEIT:</big> Jedes Projekt unterliegt strenger Bonitätsprüfung.',
      },
    },
    newsTabs: {
      title: 'Die Medien Über Lendico',
      tab1: {
        label: 'NZZ am Sonntag',
        title:
          'Der Finanzintermediär bringt auf seiner Online-Plattform Anleger und Kreditsuchende zusammen.',
        imageSrc:
          'others/media-logos/logos_external-testimonials_Neue_Zuercher_Zeitung.png',
        linkTitle: 'LENDICO IN DER PRESSE &raquo;',
      },
      tab2: {
        label: 'Der Bund',
        title: 'Crowdfunding ist der Finanzierungstrend der Stunde.',
        imageSrc: 'others/media-logos/logos_external-testimonials_DerBund.png',
        linkTitle: 'LENDICO IN DER PRESSE &raquo;',
      },
      tab3: {
        label: '20 minuten',
        title:
          'Crowdlending bietet Unternehmen einen einfachen Zugang zu Kapital sowie alternative Anlagemöglichkeiten für Investoren.',
        imageSrc:
          'others/media-logos/logos_external-testimonials_20minuten.png',
        linkTitle: 'LENDICO IN DER PRESSE &raquo;',
      },
    },
    supportSection: {
      title: 'UNSER TEAM IST PERSÖNLICH FÜR SIE DA',
      content:
        'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail. Unsere Kundenbetreuung ist von <strong>Montag bis Freitag von 9 bis 18 Uhr</strong> für Sie da:',
      phone: '030 / 3982052 – 77',
      union: 'ODER',
      email: 'investor@lendico.de',
    },
  },
  lenderSignupForm: {
    title:
      '<strong>Registrieren Sie Ihr Anlegerkonto in nur 2 Minuten.</strong>',
    promotion: {
      label: 'Startaktion',
      amount: 'CHF 25',
      title: 'Zusatz-Rendite',
      description: 'auf Ihre erste Investionen per Gutschrift auf Ihr Konto',
      termsInfo:
        '<i>Das Angebot richtet sich an alle Privatinvestoren, die die allgemeinen Voraussetzungen zu einem Gebot auf dem Lendico Schweiz Kreditmarktplatz erfüllen (siehe {link}). Alle bezahlten Erstgebote zwischen dem 24.2.2017 und dem 30.11.2017 erhalten eine Gutschrift in Höhe von CHF 25 auf ihr hinterlegtes Konto. Die Gutschrift erfolgt im April nach Beendigung der Aktion. Ausschlaggebend ist der Zeitpunkt des Gebots, nicht der Kreditauszahlung.</i>',
      termsInfoLinkTitle: 'AGB',
    },
    form: {
      submit: 'Weiter &raquo;',
      errors: {
        400: 'Anfrage nicht gültig. Bitte überprüfen Sie Ihre E-Mail-Adresse.',
        500: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
      },
      salutation: {
        label: 'Anrede',
        dropdown: {
          m: 'Herr',
          f: 'Frau',
        },
      },
      firstName: {
        label: 'Vorname',
      },
      lastName: {
        label: 'Nachname',
      },
      email: {
        label: 'Email Adresse',
      },
      password: {
        label: 'Passwort',
        rules:
          'Ihr Passwort muss aus mind. 8 Zeichen, inkl. Sonderzeichen, Zahlen u. Klein- und Grossbuchstaben bestehen.',
      },
      termsAndConditions: {
        label: 'Ich akzeptiere die {link}.',
        labelLinkTitle: 'Nutzungsbestimmungen für Anleger',
      },
      privacyPolicy: {
        label:
          'Ich willige in die Erhebung, Verarbeitung und Nutzung meiner Personendaten gem. {link} ein.',
        labelLinkTitle: 'Datenschutzvereinbarung',
      },
      marketingAgreement: {
        label:
          'Ich bin damit einverstanden, interessante Angebote der Lendico Schweiz AG zu erhalten – jederzeit widerrufbar.',
      },
    },
  },
  lenderDashboardPage: {
    title: 'Kontoübersicht',
    accountBalance: {
      updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
      panels: {
        investments: {
          title: 'Anlageportfolio',
          sections: {
            summary: {
              title: 'Anlagen',
              pending: 'zu bezahlende Kreditprojekte',
              tooltipPending:
                'Gebote für noch nicht realisierte Kreditprojekte die Sie noch nicht bezahlt haben.',
              paid: 'bezahlte Kreditprojekte',
              tooltipPaid:
                'Gebote für noch nicht realisierte Kreditprojekte die Sie bereits bezahlt haben.',
              realised: 'realisierte Kreditprojekte',
              tooltipRealised:
                'Gebote für realisierte Kreditprojekte, d.h. der entsprechende Kredit wurde voll finanziert und an den Kreditnehmer ausgezahlt.',
              canceled: 'stornierte Kreditprojekte',
              tooltipCanceled: undefined,
            },
            portfolio: {
              title: 'Portfolio',
              outstanding: 'Ausstehender Betrag',
              tooltipOutstanding:
                'Aktuell für Ihr (realisiertes) Anlageportfolio ausstehender Tilgungsbetrag',
              invested: 'Anlagebetrag',
              tooltipInvested: 'Ursprünglicher Anlagebetrag in Kreditprojekte',
              ga: 'Kulanzangebot',
              active: {
                title: 'laufende Kredite',
                current: 'planmäßig',
                tooltipCurrent: 'Kredite ohne jegliche Zahlungsrückstände.',
                late: 'überfällig',
                tooltipLate: 'Kredite mit Zahlungsrückständen.',
              },
              terminated: {
                title: 'ausgefallene Kredite',
                tooltipTitle:
                  'Aufgrund von Zahlungsverzügen gekündigte Kredite.',
                inHouse: 'interne Beitreibung',
                tooltipInHouse:
                  'Ausgefallene Kredite deren offene Forderungen für Sie aktuell durch Lendico beigetrieben werden.',
                dca: 'Inkasso',
                tooltipDca:
                  'Ausgefallene Kredite deren offene Forderungen für Sie aktuell durch einen externen Dienstleister (Inkassobüro) beigetrieben werden.',
                sold: 'verkauft',
                tooltipSold:
                  'Ausgefallene Kredite deren offene Forderung von Ihnen an ein Inkassobüro verkauft wurde.',
              },
              completed: 'zurückgezahlte Kredite',
              tooltipCompleted:
                'Vollständig vom Kreditnehmer zurückgezahlte Kredite.',
              canceled: 'stornierte Kreditprojekte',
              tooltipCanceled:
                'Kreditprojekte, die nach Auszahlung storniert wurden, z.B. innerhalb der gesetzlichen Widerrufsfrist.',
            },
          },
        },
        cashflow: {
          title: 'Zahlungsübersicht',
          sections: {
            summary: {
              title: 'Anlagen',
              incoming: 'gezahlte Anlagebeträge',
              tooltipIncoming:
                'Gesamtbetrag der von Ihnen bezahlten Gebote für Kreditprojekte.',
              outgoing: 'erstattete Anlagebeträge',
              tooltipOutgoing:
                'Gesamtbetrag der Erstattungszahlungen für nicht zustande gekommene Kreditprojekte.',
            },
            portfolio: {
              title: 'Portfolio',
              receivedPrincipal: 'erhaltene Tilgung',
              tooltipReceivedPrincipal:
                'Gesamtbetrag der für Ihr Anlageportfolio erhaltenen Tilgungszahlungen.',
              receivedInterest: 'erhaltene Zinsen',
              tooltipReceivedInterest:
                'Gesamtbetrag der für Ihr Anlageportfolio erhaltenen Zinszahlungen.',
              receivedPromotional: 'erhaltenes Rendite-Upgrade',
              tooltipReceivedPromotional:
                'Gesamtbetrag der für Ihr Anlageportfolio erhaltenen Rendite-Upgrade-Zahlungen.',
              receivedSpecial: 'erhaltene Sonderzahlungen',
              tooltipReceivedSpecial:
                'Gesamtbetrag der durch Lendico geleisteten Sonderzahlungen und aus Promo-Aktionen.',
              postDefaultRecoveryAmount: 'erhaltene Rückgewinne',
              tooltipPostDefaultRecoveryAmount:
                'Gesamtbetrag der für Ihr Anlageportfolio erhaltenen Rückgewinne aus ausgefallenen Krediten.',
              chargedFee: 'einbehaltene Service-Gebühr',
              tooltipChargedFee:
                'Gesamtbetrag der von Lendico einbehaltenen Service-Gebühr für Anleger, welche 1% auf alle Zins-und Tilgungszahlungen entspricht.',
              totalEarnings: 'Zahlungen gesamt',
              tooltipTotalEarnings:
                '(Netto-)Gesamtbetrag der aus Ihrem Anlageportfolio generierten Zahlungen.',
              outstandingEarnings: 'davon ausstehende Zahlungen',
              tooltipOutstandingEarnings:
                'Gesamtbetrag der Zahlungen die sich aktuell noch in Auszahlung an Sie befinden.',
              receivedEarnings: 'erhaltene Zahlungen',
              tooltipReceivedEarnings:
                'Gesamtbetrag der Zahlungen die bereits von Lendico an Sie ausbezahlt wurden.',
              ga: 'erhaltene Kulanzzahlungen',
            },
            prepaid: {
              title: 'Premium-Konto',
              balance: 'Kontostand',
              buttonWithdraw: 'Auszahlen',
            },
          },
        },
      },
    },
    marketItemsList: {
      title: 'Aktuelle Kreditprojekte',
      tableHead: {
        loanName: 'Kreditprojekte',
        amount: 'Betrag',
        interest: 'Zinssatz',
        duration: 'Laufzeit',
        timeLeft: 'Restzeit',
        funded: '% Finanziert<br />(noch offen)',
      },
      bidLabel: 'Anlegen',
      bidAmountWarningMessage:
        "* Anlagebeträge müssen mindestens CHF 1'000 oder einem Vielfachen davon entsprechen.",
      noItemsWarningMessage:
        'Leider konnte derzeit kein Kreditprojekt geladen werden.',
      pleaseWaitWarningMessage:
        'Bitte warten Sie, während die Kreditprojekte geladen werden.',
      moreLoansLinkTitle: 'Weitere Kreditprojekte »',
    },
  },
  lenderInvestmentDashboardPage: {
    title: 'Kreditübersicht',
  },
  lenderPledgeDashboardPage: {
    title: 'Gebote',
  },
  lenderPledgesInvestmentsPage: {
    title: 'Meine Anlagen',
    p1: 'Hier finden Sie alle realisierten Kreditprojekte angereichert mit dem aktuellen Status und den wichtigsten Informationen. Sollten Sie mehr als 10 realisierte Kreditprojekte vorweisen, so können Sie die Filter-Funktion nutzen.',
    p2: 'Durch einen Klick auf die Beschreibung des Kreditprojekts gelangen Sie auf dessen Detailseite.',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
  },
  lenderTransactionsPage: {
    title: 'Meine Transaktionen',
    p1: 'Auf dieser Seite sehen Sie alle Zahlungen, welche Ihnen zugeflossen sind. Ab 10 Zahlungen können Sie die Filter-Funktion nutzen.',
    p2: 'Durch Klick auf eine der Zeilen öffnet sich eine detaillierte Übersicht. Diese zeigt Ihnen, aus welchen Bestandteilen sich diese Zahlung zusammensetzt.',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
  },
  lenderTransactionsItemPage: {
    title: 'Meine Transaktionen',
    p1: 'Auf diese Seite sehen Sie, aus welchen Bestandteilen sich diese Zahlung zusammensetzt.',
    p2: '',
    updatedDateWarningMessage: '* Daten zuletzt aktualisiert: {date}.',
  },
  lenderLoanMarketplacePage: {
    title: 'Aktuelle Kreditprojekte',
  },
  lenderMyProfilePage: {
    title: 'Persönliche Daten',
    p1: 'Hier sehen Sie die für Sie hinterlegten Daten. Sollte hier etwas nicht stimmen, <a href="mailto:investor@lendico.de">melden Sie sich</a> bei uns. Wir helfen Ihnen gerne.',
    p2: '',
  },
  lenderDashboardSubMenu: {
    questionnaire: 'Anleger-Fragebogen',
    dashboard: 'Kontoübersicht',
    loanMarketplace: 'Aktuelle Kreditprojekte',
    myProfile: 'Mein Profil',
    pledgesInvestments: 'Meine Anlagen',
    myTransactions: 'Umsätze',
    help: 'Hilfe',
    premiumKonto: 'Premium-Konto',
  },
  lenderGuaranteeAgreementsListBlock: {
    title: 'Kulanzangebot',
    columns: {
      loanNr: 'Kreditprojekte',
      amount: 'Gesamtbetrag',
      amountPaid: 'Ausgezahlte Summe',
      amountLeft: 'Restbetrag',
    },
    bottomMessage: '',
  },
  lenderAgreementDashboardPage: {
    back: '<< Zurück zur projektliste',
    title: 'Zahlungsplan',
  },
  lenderAgreementDashboardBlock: {
    paymentPlan: {
      title: 'Zahlungsplan',
      columns: {
        date: 'Datum',
        planEntryType: 'Beschreibung',
        remainingOutstandingPrincipal: 'Restschuld',
        totalPrincipal: 'Tilgung',
        totalInterest: 'Zins',
        totalPayment: 'Betrag',
        paymentStatus: 'Status',
      },
      planEntryType: {
        old: 'Vorlaufzinsen',
        early_repayment: 'Sondertilgung',
        initial_installment_with_interest: 'Rate mit Vorlaufzinsen',
        initial_interest: 'Vorlaufzinsen',
        installment: 'Rate',
        rdiCancellation: 'Kündigung',
        recission: 'Stornierung',
        termination: 'Kündigung',
      },
      paymentStatus: {
        future: '-',
        paid: 'bezahlt',
        late: 'in Verzug',
        partially_paid: 'Teilbezahlt',
        overpaid: 'Überzahlung',
      },
      noItemsMessage: 'Leider konnte derzeit kein Zahlungsplan geladen werden.',
      pleaseWaitMessage:
        'Bitte warten Sie, während die Zahlungsplan geladen werden.',
    },
    bottomMessage: '',
  },
  lenderPrepaidTransactionsListBlock: {
    filterButtonTitle: 'Filter',
    columns: {
      date: 'Datum',
      amount: 'Betrag',
      reference: 'Referenz / Zweck',
    },
    emptyValues: {
      referenceText: '<i style="font-weight: normal;">(Nicht verfügbar)</i>',
      type: 'Unbekannt',
    },
    type: {
      PRINCIPAL: 'Tilgung',
      INVESTOR_FEE_REIMBURSEMENT: 'Service-Gebühr',
      INTEREST: 'Zins',
      OUTGOING_PAYMENT: 'Ausgehende Zahlung',
      PRINCIPAL_REIMBURSEMENT: 'Rückforderung Tilgung',
      INTEREST_REIMBURSEMENT: 'Rückforderung Zins',
      INVESTOR_FEE: 'Service-Gebühr',
      LOAN_FUNDING: 'Darlehensfinanzerierung',
      INCOMING_PAYMENT: 'Zahlung',
      PRINCIPAL_RECOVERY: 'Rückgewinn Tilgung',
      GOODWILL: 'Sonderzahlung',
      LOAN_FUNDING_REVERSAL: 'Darlehensfinanzierung Rückzahlung',
      PROMOTION: 'Promotion',
      REJECTED_CT: 'Zurückgelaufene Zahlung',
      PROMOTION_REIMBURSEMENT: 'Rückforderung Rendite-Upgrade',
      PRINCIPAL_RECOVERY_REIMBURSEMENT: 'Rückforderung Rückgewinn Tilgung',
      INTEREST_RECOVERY: 'Rückgewinn Zins',
      INTEREST_RECOVERY_REIMBURSEMENT: 'Rückforderung Rückgewinn Zins',
    },
    noItemsMessage: 'Leider konnte derzeit kein Transaktionen geladen werden.',
    pleaseWaitMessage:
      'Bitte warten Sie, während die Transaktionen geladen werden.',
  },
  lenderMarketItemPage: {
    back: '<< Zurück zur projektliste',
    title: 'Kreditübersicht',
  },
  lenderPledgeDashboardBlock: {
    back: '<< Zurück zur projektliste',
    title: 'Meine Gebote',
    rows: {
      amount: 'Gebote',
      duration: 'Laufzeit',
      monthlyPayment: 'Monatliche Rate',
      status: 'Status',
    },
  },
  lenderPledgesListBlock: {
    title: 'Meine Gebote',
    columns: {
      title: 'Kreditprojekte',
      amount: 'Kreditbetrag',
      interestPercentage: 'Zinssatz',
      duration: 'Laufzeit',
      timeLeft: 'Restzeit',
      fundedPercentage: '% Finanziert<br />(noch offen)',
      bidAmount: 'Gebotsbetrag',
      status: 'Status',
    },
    noItemsMessage: 'Leider konnte derzeit kein Gebote geladen werden.',
    pleaseWaitMessage: 'Bitte warten Sie, während die Gebote geladen werden.',
    bottomMessage: '',
  },
  lenderTransactionsItemBlock: {
    filterButtonTitle: 'Filter',
    columns: {
      loanNr: 'Kreditprojekt',
      amount: 'Betrag',
      type: 'Typ',
    },
    emptyValues: {
      referenceText: '<i style="font-weight: normal;">(Nicht verfügbar)</i>',
      type: 'Unbekannt',
    },
    type: {
      PRINCIPAL: 'Tilgung',
      INVESTOR_FEE_REIMBURSEMENT: 'Service-Gebühr',
      INTEREST: 'Zins',
      OUTGOING_PAYMENT: 'Ausgehende Zahlung',
      PRINCIPAL_REIMBURSEMENT: 'Rückforderung Tilgung',
      INTEREST_REIMBURSEMENT: 'Rückforderung Zins',
      INVESTOR_FEE: 'Service-Gebühr',
      LOAN_FUNDING: 'Darlehensfinanzerierung',
      INCOMING_PAYMENT: 'Zahlung',
      PRINCIPAL_RECOVERY: 'Rückgewinn Tilgung',
      GOODWILL: 'Sonderzahlung',
      LOAN_FUNDING_REVERSAL: 'Darlehensfinanzierung Rückzahlung',
      PROMOTION: 'Rendite-Upgrade',
      REJECTED_CT: 'Zurückgelaufene Zahlung',
      PROMOTION_REIMBURSEMENT: 'Rückforderung Rendite-Upgrade',
      PRINCIPAL_RECOVERY_REIMBURSEMENT: 'Rückforderung Rückgewinn Tilgung',
      INTEREST_RECOVERY: 'Rückgewinn Zins',
      INTEREST_RECOVERY_REIMBURSEMENT: 'Rückforderung Rückgewinn Zins',
    },
    unknownType: 'Nicht zugeordneter Betrag',
    unknownLoanNr: '',
    noItemsMessage: 'Leider konnte derzeit kein Details geladen werden.',
    pleaseWaitMessage: 'Bitte warten Sie, während die Details geladen werden.',
  },
  lenderTransactionsListBlock: {
    filterButtonTitle: 'Filter',
    columns: {
      date: 'Datum',
      amount: 'Betrag',
      reference: 'Referenz / Zweck',
      account: 'Typ',
    },
    emptyValues: {
      referenceText: '<i style="font-weight: normal;">(Nicht verfügbar)</i>',
      type: 'Unbekannt',
    },
    accountTypes: {
      CREDITOR_OUTGOING_PAYMENT: 'Auszahlung für Kreditprojekte',
      CREDITOR_REJECTED_CT: 'rückgelaufene Auszahlung für Kreditprojekte',
      CREDITOR_GUARANTEE_AGREEMENT_OUTGOING_PAYMENT:
        'Auszahlung für Kulanzangebot',
      CREDITOR_GUARANTEE_AGREEMENT_REJECTED_CT:
        'rückgelaufene Auszahlung für Kulanzangebot',
      CREDITOR_GUARANTEE_AGREEMENT_INCOMING_PAYMENT:
        'Zahlungseingang für Kulanzangebot-Überzahlung',
    },
    type: {
      PRINCIPAL: 'Tilgung',
      INVESTOR_FEE_REIMBURSEMENT: 'Service-Gebühr',
      INTEREST: 'Zins',
      OUTGOING_PAYMENT: 'Ausgehende Zahlung',
      PRINCIPAL_REIMBURSEMENT: 'Rückforderung Tilgung',
      INTEREST_REIMBURSEMENT: 'Rückforderung Zins',
      INVESTOR_FEE: 'Service-Gebühr',
      LOAN_FUNDING: 'Darlehensfinanzerierung',
      INCOMING_PAYMENT: 'Zahlung',
      PRINCIPAL_RECOVERY: 'Rückgewinn Tilgung',
      GOODWILL: 'Sonderzahlung',
      LOAN_FUNDING_REVERSAL: 'Darlehensfinanzierung Rückzahlung',
      PROMOTION: 'Rendite-Upgrade',
      REJECTED_CT: 'Zurückgelaufene Zahlung',
      PROMOTION_REIMBURSEMENT: 'Rückforderung Rendite-Upgrade',
      PRINCIPAL_RECOVERY_REIMBURSEMENT: 'Rückforderung Rückgewinn Tilgung',
      INTEREST_RECOVERY: 'Rückgewinn Zins',
      INTEREST_RECOVERY_REIMBURSEMENT: 'Rückforderung Rückgewinn Zins',
    },
    noItemsMessage: 'Leider konnte derzeit kein Transaktionen geladen werden.',
    pleaseWaitMessage:
      'Bitte warten Sie, während die Transaktionen geladen werden.',
  },
  lenderTransactionsFilterBlock: {
    resetAction: 'Filter zurücksetzen',
    filterAction: 'Filter anwenden',
    loanNumber: 'Kreditnummer',
    type: 'Transactionen Kategorie',
    amount: 'Transaktionen Betrag',
    startDate: 'Datum (ab)',
    endDate: 'Datum (bis)',
  },
  lenderInvestmentDashboardBlock: {
    backToList: '<< Zurück zur Projektliste',
    loanRequestLinkTitle: 'Ursprüngliche Kreditprojektdaten',
    summary: {
      title: 'Zusammenfassung',
      investmentTitle: 'Kredit',
      loanNr: 'Kredit-Nr',
      principalAmount: 'Betrag',
      investedAmount: 'Meine Anlage',
      interestRate: 'Zinssatz',
      duration: 'Laufzeit',
      payoutDate: 'Kreditauszahlung',
      status: 'Status',
    },
    investments: {
      title: 'Ihre Anlage',
      earnings: 'Ihre Erträge',
      paidPrincipal: 'Erhaltene Tilgung',
      paidPromotion: 'erhaltenes Rendite-Upgrade',
      postDefaultRecoveryAmount: 'erhaltene Rückgewinne',
      earningsPending: 'davon ausstehende Zahlungen',
      paidInterest: 'Erhaltene Zinsen',
      paidServiceFee: 'einbehaltene Service-Gebühr',
    },
    paymentPlan: {
      title: 'Zahlungsplan',
      columns: {
        date: 'Datum',
        planEntryType: 'Beschreibung',
        remainingOutstandingPrincipal: 'Restschuld',
        totalPrincipal: 'Tilgung',
        totalInterest: 'Zins',
        totalPayment: 'Betrag',
        totalFee: 'Service-Gebühr',
        totalPromotion: 'Rendite-Upgrade',
        paymentStatus: 'Status',
      },
      planEntryType: {
        old: 'Vorlaufzinsen',
        early_repayment: 'Sondertilgung',
        initial_installment_with_interest: 'Rate mit Vorlaufzinsen',
        initial_interest: 'Vorlaufzinsen',
        installment: 'Rate',
        rdiCancellation: 'Kündigung',
        recission: 'Stornierung',
        termination: 'Kündigung',
      },
      paymentStatus: {
        future: '-',
        paid: 'bezahlt',
        late: 'in Verzug',
        partially_paid: 'Teilbezahlt',
        overpaid: 'Überzahlung',
      },
      noItemsMessage: 'Leider konnte derzeit kein Zahlungsplan geladen werden.',
      pleaseWaitMessage:
        'Bitte warten Sie, während die Zahlungsplan geladen werden.',
    },
  },
  lenderInvestmentsListBlock: {
    filterButtonTitle: 'Filter',
    title: 'Meine Anlagen',
    columns: {
      title: 'Kreditprojekte',
      amount: 'Kreditbetrag',
      interestRate: 'Zinssatz',
      liveSinse: 'Aktiv Seit',
      duration: 'Laufzeit',
      investedAmount: 'Anlagebetrag',
      status: 'Status',
    },
    noItemsMessage: 'Leider konnte derzeit kein Investitionen geladen werden.',
    pleaseWaitMessage:
      'Bitte warten Sie, während die Investitionen geladen werden.',
    bottomMessage: '',
  },
  lenderInvestmentsFilterBlock: {
    resetAction: 'Filter zurücksetzen',
    filterAction: 'Filter anwenden',
    loanNumber: 'Kreditnummer',
    investmentId: 'Investment ID',
    risk: 'Risikoklass',
    status: 'Kredit Status',
    statuses: {
      origination: 'In Auszahlung',
      current: 'Laufend - planmäßig',
      completed: 'Zurückgezahlt',
      late: 'Laufend - überfällig',
      terminated: 'Ausgefallen - interne Beitreibung',
      terminatedDcaServicing: 'Ausgefallen - Inkasso',
      terminatedSold: 'Ausgefallen - verkauft',
      canceled: 'Storniert',
    },
    investment: 'Anlagebetrag',
    principal: 'Kreditbetrag',
    startDate: 'Aktiv Seit (ab)',
    endDate: 'Aktiv Seit (bis)',
  },
  lenderQuestionnairePage: {
    title: 'Anleger-Fragebogen',
    ourTeam: {
      title: 'Unser Team ist persönlich für Sie da',
      description:
        'Sprechen Sie uns an. Gerne beantwortet unser Service-Team Ihre Fragen persönlich oder per E-Mail.<br />Unsere Kundenbetreuung ist von <strong>Montag bis Freitag von 9 bis 18 Uhr</strong> für Sie da:',
      phone: '(+41) 43 508 71 98',
      union: 'ODER',
      email: 'investor@lendico.ch',
    },
  },
  lenderQuestionnaireBlock: {
    menu: {
      step1: '1. Anlegerinformationen',
      step2: '2. Identifizierungsdokumente',
      step3: '3. Überprüfung der Bankverbindung',
    },
    step1: {
      description:
        'Um auf dem Kreditmarktplatz von Lendico aktiv mitbieten zu können benötigen wir noch einige Informationen von Ihnen. Bitte füllen Sie den folgenden Bogen aus und klicken Sie anschliessend auf "weiter".',
      submit: 'Weiter &raquo;',
      fields: {
        street: 'Strasse',
        streetNumber: 'Nr.',
        addressComplement: 'Adresszusatz (optional)',
        postalCode: 'Postleitzahl',
        city: 'Ort',
        phone: 'Telefonnummer',
        userIncomeEmploymentStatus: {
          title: 'Berufsstatus',
          salaried: 'Angestellter',
          manual_worker: 'Arbeiter',
          freelancer: 'Freiberufler',
          other_self_employed: 'Sonstiger Selbstständiger',
          retired: 'Rentner/Pensionär',
          person_of_independent_means: 'Privatier',
          house_wife_husband: 'Hausfrau/-mann',
          soldier: 'Militär-/Zivildienstpflichtiger',
          student: 'Student/Schüler',
          other: 'ohne Tätigkeit',
        },
        userIncomePosition: 'Beruf',
        userIncomeEmployerName: 'Arbeitgeber',
        userIncomeIndustry: {
          title: 'Branche',
          financial_and_insurance_services:
            'Finanz- und Versicherungsdienstleistungen',
          power_supply: 'Energieversorgung',
          information_and_communication:
            'Informations- und Kommunikationsindustrie',
          professional_scientific_and_technical_services:
            'Professionelle, wissenschaftliche und technische Dienstleistungen',
          education: 'Bildungswesen',
          mining_and_minerals: 'Bergbau und Mineralindustrie',
          land_and_housing: 'Grundstücks- und Wohnungswirtschaft',
          manufacturing: 'Verarbeitende Industrie',
          arts_entertainment_and_recreation:
            'Kunst-, Unterhaltungs- und Freizeitsektor',
          health_and_social_care: 'Gesundheits- und Sozialwesen',
          services: 'Dienstleistungssektor',
          public_administration_and_defense_compulsory_social_security:
            'Öffentliche Verwaltung, Verteidigung, Sozialversicherung',
          trade: 'Handel',
          transportation_and_storage: 'Transport und Lagerung',
          construction: 'Bauindustrie',
          hospitality: 'Gastgewerbe',
          internet: 'Internetindustrie',
        },
        userIncomeAnnualIncome: {
          title: 'Einkommen p.a.',
          income_under_50000: "bis 50'000 CHF",
          income_under_100000: "bis 100'000 CHF",
          income_under_500000: "bis 500'000 CHF",
          income_over_500000: "über 500'000 CHF",
        },
        userNetWealth: {
          title: 'Vermögen (ohne selbstgenutztes Wohneigentum)',
          wealth_under_200000: "bis 200'000 CHF",
          wealth_under_500000: "bis 500'000 CHF",
          wealth_under_1000000: "bis 1'000'000 CHF",
          wealth_over_1000000: "über 1'000'000 CHF",
        },
        userSourceOfWealth: {
          title: 'Herkunft der Vermögenswerte',
          wealth_source_savings: 'Ersparnis',
          wealth_source_own_business: 'Eigener Geschäftsbetrieb',
          wealth_source_heritage: 'Erbschaft',
          wealth_source_other: 'Anderes',
        },
        actingOwnAccount:
          'Hiermit bestätige ich, dass keine Drittpersonen an den im Rahmen der Vertragsbeziehung verwendeten Vermögenswerten wirtschaftlich berechtigt sind. Ich handle auf eigene Rechnung und nicht auf fremde Veranlassung – insbesondere nicht als Treuhänder.<br><br><span class="bold" style="left: -2em;position: relative;">Die vorsätzliche Angabe falscher Informationen in Bezug auf die wirtschaftlich Berechtigten und Kontrollinhaber in diesem Formular ist eine strafbare Handlung (Urkundenfälschung gemäss Artikel 251 des Schweizerischen Strafgesetzbuches).</span>',
      },
    },
    step2: {
      submit: 'Weiter &raquo;',
      browse: 'Browse',
      clear: 'Clear',
      p1: 'Vielen Dank für die Übersendung des Fragebogens. Aus rechtlichen Gründen sind wir verpflichtet, die persönliche Identifikation unserer Anleger sicherzustellen. Aus diesem Grund benötigen wir die Kopie Ihrer Identitätskarte sowie ein zusätzliches Portraitfoto von Ihnen. Ihre Daten werden selbstverständlich verschlüsselt übermittelt und zu keinen weiteren Zwecken als der Verifikation Ihrer Person verwendet.',
      p2: 'Bitte laden Sie hier die folgenden, eingescannten Dokumente hoch:',
      p3: 'Beachten Sie, dass Ihr Ausweisdokument gültig sein muss und nicht abgelaufen sein darf. Die folgenden Dateiformate werden akzeptiert: JPG, PNG oder PDF. Jede Datei muss kleiner als 6MB sein.',
      checkbox1:
        'Kopie von <b>Vorder- und Rückseite</b> Ihrer <b>Identitätskarte</b> oder Ihres Reisepasses',
      checkbox2:
        'Ein <b>weiteres Portraitfoto</b> (d.h. ein weiteres Foto, auf dem Ihr Gesicht deutlich zu erkennen ist)',
    },
    step3: {
      description:
        'Zum Abschluss benötigen wir noch Ihre Bankverbindung. Bitte beachten Sie, dass diese auch die Referenzverbindung für die mit Ihren Investitionen verbundenen Zahlungsströme darstellt.',
      submit: 'Weiter &raquo;',
      fields: {
        owner: 'Kontoinhaber',
        iban: 'IBAN (z.B. CH9300762011623852957)',
      },
    },
    step4: {
      title: 'Bankkonto bestätigen',
      description:
        'Bitte überweisen Sie CHF 5.- unter Angabe des unten genannten Verwendungszwecks an die nachfolgende Bankverbindung, um die Überprüfung Ihres Kontos abzuschliessen.',
      from: {
        title: 'Von',
        owner: 'Kontoinhaber',
        iban: 'IBAN',
        bic: 'BIC',
      },
      to: {
        title: 'An',
        owner: 'Kontoinhaber',
        iban: 'IBAN',
        bic: 'BIC',
        bank: 'Bank',
        amount: 'Betrag',
        token: 'Verwendungszweck',
      },
      bottomMessage: {
        preLinkTitleText:
          'Sobald wir den Eingang der oben genannten Transaktion mit dem korrekten Verwendungszweck bestätigt haben, können Sie beginnen, in Kreditprojekte zu investieren. ',
        linkTitleText: 'Zu den aktuellen Projekten',
        postLinkText: '.',
      },
    },
  },
  lenderInvestmentStatuses: {
    origination: 'In Auszahlung',
    current: 'Planmäßig',
    completed: 'Zurückgezahlt',
    late: 'Überfällig',
    terminated: 'Ausgefallen - interne Betreibung',
    terminatedDCAservicing: 'Ausgefallen - Inkasso',
    terminatedsold: 'Ausgefallen - verkauft',
    canceled: 'Storniert',
    terminatedGeneral: 'Ausgefallen',
  },
  lenderPledgeStatuses: {
    open_for_bidding: 'Aktuelle Projekte',
    fully_funded: 'Finanziert',
    ready_for_origination: 'Aus-/Zahlungsphase',
    waiting_for_documents: 'Warten auf Dokumente',
  },
  lenderHelpPage: {
    title: 'Hilfe',
    dashboard: {
      title: 'Kontoübersicht',
      p1: 'Der neuen Kontoübersicht entnehmen Sie den zusammenfassenden Status Ihrer Anlagen.',
      p2: 'Die Ansicht ist in die Bereiche Anlageportfolio und Zahlungsübersicht unterteilt. Im Bereich Anlageportfolio sehen Sie unter Anlagen, wie viele Gebote auf Kreditprojekte von Ihnen noch zu zahlen, bereits bezahlt oder schon realisiert sind. Ein realisiertes Kreditprojekt entspricht einem voll finanzierten Projekt, welches bereits an den Kreditnehmer ausgezahlt wurde.',
      p3: 'Im Bereich Portfolio sehen Sie, in welchem Status sich Ihre Kreditprojekte befinden. Dabei entspricht die Zahl hinter dem Status der Anzahl Ihrer Kreditprojekte mit dem jeweiligen Status. Die Spalte Ausstehender Betrag beschreibt den noch ausstehenden Tilgungsanteil während die Spalte Anlagebetrag die Summe des ursprünglichen Anlagebetrages der Kreditprojekte beschreibt.',
      p4: 'Bitte beachten Sie, dass hier lediglich ausstehende Tilgungsbeträge angezeigt werden, wohingegen die entsprechende Tabelle in Ihrem alten Dashboard auch zukünftige Zinszahlungen enthielt. Mit dieser angepassten Darstellung kommen wir dem Wunsch vieler Investoren nach und hoffen so die Nachvollziehbarkeit der Portfolioübersicht zu verbessern.',
      p5: 'Direkt daneben im Bereich Zahlungsübersicht entnehmen Sie unter der Überschrift Portfolio welche Zahlungen bereits für Ihr gesamtes Portfolio gebucht wurden. Die Summe Zahlungen Gesamt beschreibt dabei die Gesamtsumme, der Zahlungen die Ihr Portfolio bereits generiert hat. Sofern sich Teile davon noch im Auszahlungsprozess befinden, so erkennen Sie dies an dem Betrag der davon ausstehenden Zahlungen direkt darunter.',
      p6: 'Unten rechts finden Sie den aktuellen Kontostand Ihres Premium-Kontos mit der Möglichkeit eine Auszahlung zu beantragen.',
    },
    pledgesInvestments: {
      title: 'Meine Anlagen',
      p1: 'Im Bereich Meine Anlagen finden Sie alle realisierten Kreditprojekte angereichert mit dem aktuellen Status und den wichtigsten Informationen.',
      p2: 'Durch einen Klick auf die Beschreibung des Kreditprojekts gelangen Sie auf dessen Detailseite.',
      p3: 'Unter Zusammenfassung finden Sie die wichtigsten Merkmale des Investments. Daneben unter Ihre Anlage finden Sie die Summe aller getätigten Zahlungen für dieses Kreditprojekt.',
      p4: 'Darüber hinaus sehen Sie darunter den aktuellen Zahlungsplan inkl. dem Zahlungsstatus der einzelnen Planeinträge.',
      p5: 'Unter dem Status Ihrer Investments finden Sie die Liste aller Ihrer ehemaligen Investments im Rahmen des Kulanzangebots. Die insgesamt von Lendico zu zahlende Summe, die bereits gezahlte Summe und die noch ausstehende Summe sind dort ebenfalls ersichtlich. Durch Klick auf den Namen des Kreditprojekts, können Sie den Zahlungsplan anzeigen.',
    },
    myTransactions: {
      title: 'Umsätze',
      p: 'Auf dieser Seite sehen Sie alle Zahlungen, welche Ihnen zugeflossen sind. In der Spalte Referenz sehen Sie den Verwendungszweck, über welchen Sie die Transaktion auch in dem Kontoauszug Ihrer Bank finden können. Durch Klick auf eine der Zeilen öffnet sich eine detaillierte Übersicht. Diese zeigt Ihnen, aus welchen Bestandteilen sich diese Zahlung zusammensetzt.',
    },
    premiumKonto: {
      title: 'Premium-Konto',
      p: 'In diesem Bereich sehen Sie den aktuellen Kontostand Ihres Premium-Kontos mit der Möglichkeit eine Auszahlung zu beantragen, sowie eine Historie der letzten Geldein- und ausgänge.',
    },
    myProfile: {
      title: 'Mein Profil',
      p: 'Im Bereich Mein Profil sehen Sie die für Sie hinterlegten Daten. Sollte hier etwas nicht stimmen, melden Sie sich bei uns. Wir helfen Ihnen gerne.',
    },
  },
};
