import { isEmpty } from '@/validators/helpers/string';
import { hasEmailFormat } from '@/validators/helpers/email';

export default function ValidateEmail(email) {
  if (isEmpty(email)) {
    return { email: 'EmailIsRequired' };
  }
  if (!hasEmailFormat(email)) {
    return { email: 'EmailIsNotCorrect' };
  }

  return { email: undefined };
}
