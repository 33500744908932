<template>
  <div>
    <p class="ing-offer-explanation">
      {{ $t('offer.detailsSection.acceptOfferText') }}
    </p>
    <IngButton type="submit" @click="handleAcceptOffer">
      {{ $t('offer.detailsSection.acceptButton') }}
    </IngButton>
    <div
      v-if="errorMessage"
      :class="{ active: errorMessage }"
      role="alert"
      class="accept-button-error"
    >
      <IconError class="accept-button-error__icon" />
      <p class="accept-button-error__message" v-html="errorMessage" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IconError from '@/assets/icon-error';
  import ERROR_CODES from '@/constants/ERROR_CODES';
  export default {
    name: 'AcceptOfferButton',
    components: {
      IngButton,
      IconError,
    },
    computed: {
      ...mapState('offer', ['error', 'success']),
      errorMessage() {
        const { status } = this.error;
        if (status) {
          if (status === ERROR_CODES.UNAUTHORIZED) {
            return '';
          }
          if (
            status >= ERROR_CODES.BAD_REQUEST &&
            status < ERROR_CODES.INTERNAL_SERVER_ERROR
          ) {
            return this.$t('forms.signupForm.errors.400');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
    methods: {
      ...mapActions('offer', ['acceptOffer', 'getOfferDetails']),
      ...mapMutations('offer', ['SET_LOADING', 'SET_CURRENT_STEP']),
      async handleAcceptOffer() {
        this.SET_LOADING(true);
        await this.acceptOffer();
        if (!Object.keys(this.error).length) {
          await this.getOfferDetails();
          this.SET_CURRENT_STEP(2);
        }
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import 'src/sass/components/common/ing.orange.juice.scss';

  .ing-offer-explanation {
    margin: 40px 0 20px 0;
    font-size: 16px;
    line-height: 24px;
    color: $Primary-Grey600;

    @include mq-size(s) {
      width: 94%;
    }
    @include mq-size(m) {
      width: 86%;
    }
    @include mq-size(l) {
      width: 78%;
    }
  }
  .button-fixed-width {
    max-width: 225px;
    width: fit-content;
    padding: 5px 10px;
  }
  .accept-button-error {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 0 0;
    &__message {
      display: flex;
      align-items: center;
      font-size: px2rem(16px);
    }
    &__icon {
      margin-right: 10px;
      flex-basis: px2rem(18);
      flex-shrink: 0;
    }
  }
</style>
