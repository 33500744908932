import { render, staticRenderFns } from "./Ing404Page.vue?vue&type=template&id=acfbddac&scoped=true"
import script from "./Ing404Page.vue?vue&type=script&lang=js"
export * from "./Ing404Page.vue?vue&type=script&lang=js"
import style0 from "./Ing404Page.vue?vue&type=style&index=0&id=acfbddac&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acfbddac",
  null
  
)

export default component.exports