import parsePhoneNumber from 'libphonenumber-js/max';

const isMobileType = 'MOBILE';
const validTypes = ['FIXED_LINE_OR_MOBILE', 'MOBILE'];
export const isValidGermanPhone = (num) => {
  const n = getFormattedNumber(num);
  const phoneNumber = parsePhoneNumber(n, 'DE');

  if (phoneNumber) {
    return phoneNumber.isValid() && validTypes.includes(phoneNumber.getType());
  }
  return false;
};

export const isValidMobileNumber = (num) => {
  try {
    const phoneNumber = parsePhoneNumber(num, 'DE');
    return phoneNumber.isValid() && phoneNumber.getType() === isMobileType;
  } catch {
    return false;
  }
};
export const getFormattedNumber = (num) => {
  let nb = getNumberWithoutLeadingZeros(num);
  nb = nb.replace(/\s+/g, '');
  return '+49' + nb;
};

export const getNumberWithoutLeadingZeros = (num) => {
  while (num && num.charAt(0) === '0') {
    num = num.substr(1);
  }
  return num;
};

export const arePhonesUnique = (signers) => {
  const phonesArray = Object.keys(signers)
    .filter((signer) => signers[signer].mobilePhone)
    .map((signer) => signers[signer].mobilePhone);
  const phonesSet = new Set(phonesArray);
  return phonesArray.length === phonesSet.size;
};
