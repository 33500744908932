<template>
  <ing-text-input
    v-model="lastName"
    :label="$t('forms.signupForm.lastName.label')"
    name="lastname"
    :error="error"
    :maxlength="35"
    data-qa-id="ident-lastname"
    data-unit-test="ing-ident-last-name-field"
    :displayErrorIfDirty="!submitClicked"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      ...mapState('ident', ['ident', 'errors', 'submitClicked']),
      lastName: {
        get() {
          return this.ident.lastName;
        },
        set(value) {
          this.SET_LAST_NAME(value);
        },
      },
      error() {
        return (
          this.errors &&
          this.errors.lastName &&
          this.$t(`forms.errors.${this.errors.lastName}`)
        );
      },
    },
    methods: {
      ...mapMutations('ident', ['SET_LAST_NAME']),
    },
  };
</script>
