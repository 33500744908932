import { mapState, mapGetters } from 'vuex';
export const submitFormFunctionality = {
  props: {
    applicant: String,
  },
  computed: {
    errorMessage() {
      const { status, code } = this.errors.server || {};

      if (
        code === 'bic_not_passed_validation' ||
        code === 'iban_not_passed_validation' ||
        code === 'bic_or_iban_server_check_error' ||
        code === 'bic_or_iban_not_passed_validation'
      ) {
        return this.$t('forms.signupForm.errors.ibanOrBic');
      }
      if (code === 'invalid_email_error') {
        return this.$t('forms.signupForm.errors.email');
      }

      if (status) {
        if (this.isPartner && status === 404) {
          return this.$t('forms.signupForm.errors.404');
        }
        if (status >= 400 && status < 500) {
          return this.$t('forms.signupForm.errors.400');
        }
        if (status >= 500) {
          return this.$t('forms.signupForm.errors.500');
        }
        return this.$t('forms.signupForm.errors.500');
      }
      if (this.submitClicked && !this.isValid) {
        return this.$t('forms.signupForm.errors.fields');
      }
      return '';
    },
    ...mapState('signupForm', ['errors', 'submitClicked']),
    ...mapGetters('signupForm', ['isValid', 'isPartner']),
  },
};
