import { isEmpty } from '@/validators/helpers/string';
import { isPastDate } from '@/validators/helpers/date';
import { isBeforeFirstDateFounded } from '@/validators/helpers/dateFounded';
const FORMAT = 'DD.MM.YYYY';
const FIRST_DATE_FOUNDED = '01.01.1700';

export default function ValidateDateFounded(dateFounded) {
  if (isEmpty(dateFounded)) {
    return { dateFounded: 'DateFoundedRequired' };
  }
  if (!isPastDate(dateFounded, FORMAT)) {
    return { dateFounded: 'DateFoundedIsNotCorrect' };
  }
  if (isBeforeFirstDateFounded(dateFounded, FIRST_DATE_FOUNDED, FORMAT)) {
    return { dateFounded: 'DateFoundedIsNotCorrect' };
  }

  return { dateFounded: undefined };
}
