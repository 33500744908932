<template>
  <div>
    <offer-conditions-header />
    <offer-conditions-details />
  </div>
</template>

<script>
  import OfferConditionsHeader from '@/components/signers/offer-conditions-information/offer-conditions-header';
  import OfferConditionsDetails from '@/components/signers/offer-conditions-information/offer-conditions-details';
  export default {
    components: {
      OfferConditionsHeader,
      OfferConditionsDetails,
    },
  };
</script>

<style scoped></style>
