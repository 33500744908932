<template>
  <div class="radio-group">
    <label class="radio-group__title">
      {{ title }}
      <ToolTip v-if="toolTip">
        <template #tooltip>
          <slot name="tooltip">
            {{ toolTip }}
          </slot>
        </template>
      </ToolTip>
    </label>
    <div
      class="radio-group__elements"
      :class="{ 'radio-group__elements--row': rowList }"
    >
      <RadioButton
        v-for="option in radioOptions"
        :key="option.value"
        :label="option.label"
        :value="getValue(option)"
        :name="title"
        class="radio-group__element"
        :error="error"
        :displayErrorIfSubmitted="displayErrorIfSubmitted"
        v-bind="$attrs"
        :data-qa-id="option.dataQaId"
        v-on="$listeners"
      />
    </div>
    <FieldStatus v-if="displayError">
      <template #error>
        <slot name="error">
          {{ error }}
        </slot>
      </template>
    </FieldStatus>
  </div>
</template>
<script>
  import RadioButton from '@/components/ingOrangeJuice/Base/RadioGroup/RadioButton';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import ToolTip from '@/components/ingOrangeJuice/Base/ToolTip';

  export default {
    name: 'RadioGroup',
    components: { RadioButton, FieldStatus, ToolTip },
    inheritAttrs: false,
    model: {
      prop: 'selectedValue',
      event: 'change',
    },
    props: {
      radioOptions: {
        type: Array,
        required: true,
      },
      title: {
        type: String,
        default: '',
      },
      toolTip: {
        type: String,
        default: null,
      },
      rowList: {
        type: Boolean,
        default: false,
      },
      labelAsValue: {
        type: Boolean,
        default: false,
      },
      error: {
        type: String,
        default: null,
      },
      displayErrorIfSubmitted: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      hasError() {
        const error = this.$slots.error || this.error;
        return !!error && error.length > 0;
      },
      displayError() {
        return this.displayErrorIfSubmitted && this.hasError;
      },
    },
    methods: {
      getValue(option) {
        return this.labelAsValue ? option.label : option.value;
      },
    },
  };
</script>
<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .radio-group {
    padding-bottom: px2rem(33px);
    position: relative;
    &__title {
      font-size: px2rem(16px);
      font-weight: normal;
      display: block;
      position: relative;
    }
    &__elements {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      column-gap: px2rem(24px);
      &--row {
        flex-direction: row;
      }
    }
    &__element {
      flex-basis: auto;
    }
  }
</style>
