export default {
  subscription: {
    messages: {
      already: {
        registered: {
          title: 'Vielen Dank für Ihr Interesse!',
          subtitle: 'Ihre Anmeldung ist bereits erfolgt.',
          text: 'Ab sofort erhalten Sie aktuelle Informationen rund um Lendico und die ING-DiBa AG.',
        },
      },
      registered: {
        title: 'Ihre Anmeldung ist abgeschlossen',
        subtitle: 'Ihre E-Mail-Adresse wurde erfolgreich bestätigt',
        text: 'Sie wurden unserem Verteiler hinzugefügt und erhalten ab sofort Informationen von Lendico und der ING-DiBa AG.',
      },
    },
  },
};
