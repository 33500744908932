<template>
  <div>
    <PageSection :darkTheme="true">
      <template #section-content>
        <FormSectionHeader section="signers.identInformation" :paraCount="1" />
        <div class="ident-types row">
          <div class="ident-type-automation">
            <RadioGroup
              v-model="identOption"
              :radioOptions="[identOptions[0]]"
              :rowList="true"
              :displayErrorIfSubmitted="displayError"
            />
            <h6 class="sub-text">
              {{ $t('signers.identInformation.identOptions.video.hint') }}
            </h6>
            <IdentTypeWarning v-if="identOption === idenValues.AUTOMATION" />
          </div>
          <div class="ident-type-manual">
            <RadioGroup
              v-model="identOption"
              :radioOptions="[identOptions[1]]"
              :rowList="true"
              :displayErrorIfSubmitted="displayError"
            />
            <p class="sub-text">
              {{ $t('signers.identInformation.identOptions.postIdent.hint') }}
            </p>
            <IdentTypeWarning v-if="identOption === idenValues.MANUAL" />
          </div>
        </div>
      </template>
    </PageSection>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import { isPartnerChannel } from '@/mixins/signersMixin';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  import RadioGroup from '@/components/ingOrangeJuice/Base/RadioGroup';
  import IDENT_VALUES from '@/constants/IDENT_VALUES';

  import IdentTypeWarning from '@/components/signers/IdentTypeInformation/IdentTypeWarning';
  export default {
    components: {
      RadioGroup,
      PageSection,
      IdentTypeWarning,
      FormSectionHeader,
    },
    mixins: [isPartnerChannel, dropdownOptionsMixin],
    data() {
      return {
        idenValues: IDENT_VALUES,
      };
    },
    computed: {
      ...mapState('signers', [
        'errors',
        'identificationProcess',
        'submitClicked',
      ]),
      identOption: {
        get() {
          return this.identificationProcess;
        },
        set(value) {
          this.SET_IDENT_PROCESS(value);
        },
      },
      contactEmail() {
        if (this.isPartnerChannel) {
          return this.$t('partnerContactEmail');
        } else {
          return this.$t('companiesEmail');
        }
      },
      displayError() {
        return this.submitClicked && !!this.errors.identificationProcess;
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENT_PROCESS']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ident-types {
    display: flex;
    flex-direction: column;
    @include mq-size(m) {
      flex-direction: row;
    }
    .ident-type-automation {
      flex: 5.5;
    }
    .ident-type-manual {
      flex: 4.5;
    }

    .radio-group {
      padding-bottom: 0;
    }

    .radio-container__label {
      font-weight: 700;
    }

    .sub-text {
      font-weight: 400;
      font-size: px2rem(16px);
      line-height: 24px;
      color: $Primary-Grey600;
      padding: 0.5rem 2.4rem;

      .warning-icon {
        position: relative;
        top: 2px;
      }
    }
  }
</style>
