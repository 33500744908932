import { isEmpty, isNotNumeric, lengthIsOutOfRange } from '../helpers/string';

export default function ValidateCompanyTaxNumber(CompanyTaxNumber) {
  if (isEmpty(CompanyTaxNumber)) {
    return { companyTaxNumber: 'CompanyTaxNumberIsRequired' };
  }
  if (isNotNumeric(CompanyTaxNumber)) {
    return { companyTaxNumber: 'CompanyTaxNumberIsNAN' };
  }
  if (lengthIsOutOfRange(CompanyTaxNumber, 10, 13)) {
    return { companyTaxNumber: 'CompanyTaxNumberIsIncorrect' };
  }
  return { companyTaxNumber: undefined };
}
