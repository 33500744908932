<template>
  <div class="ing-wrapper">
    <TheHeader />
    <div class="page-container">
      <PageHeader page="offer.dpagContractSuccess.message" />
      <PageSection class="section-form" sectionPadding="sm">
        <template #section-content>
          <h3 class="contact__headline">
            {{ $t('offer.dpagContractSuccess.contact.title') }}
          </h3>
          <i18n
            path="offer.dpagContractSuccess.contact.para1"
            tag="p"
            class="contact__content"
          >
            <em place="boldText">
              {{ $t('theContact.para1.boldText') }}
            </em>
          </i18n>

          <i18n
            path="offer.dpagContractSuccess.contact.para2.text"
            tag="p"
            class="contact__link-container"
          >
            <a
              :href="`tel:${
                $translationConstantValues.theContact[$route.meta.channel]
                  .contact.phone
              }`"
              place="phoneNumber"
              class="contact__link"
              >{{
                $translationConstantValues.theContact[$route.meta.channel]
                  .contact.phone
              }}</a
            >
            <a
              :href="`mailto:${
                $translationConstantValues.theContact[$route.meta.channel]
                  .contact.email
              }`"
              place="emailAddress"
              class="contact__link"
              >{{
                $translationConstantValues.theContact[$route.meta.channel]
                  .contact.email
              }}</a
            >
          </i18n>
        </template>
      </PageSection>
    </div>
    <TheFooter />
  </div>
</template>
<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  export default {
    name: 'IngSignContractSuccessPage',
    components: {
      TheHeader,
      TheFooter,
      PageHeader,
      PageSection,
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .page-container {
    flex-grow: 1;

    .contact {
      &__headline {
        font-size: 32px;
        line-height: 40px;
        color: $Primary-Orange;
        font-weight: 400;
        padding-bottom: 24px;
        margin-top: 5vh;
        @include mq-size(m) {
          width: 89.6%;
        }
        @include mq-size(xl) {
          width: 69.3%;
        }
      }
      &__content {
        font-size: 16px;
        line-height: 24px;
        padding-bottom: 24px;
        &:last-child {
          padding-bottom: 0;
        }
        @include mq-size(m) {
          width: 89.6%;
        }
        @include mq-size(xl) {
          width: 69.3%;
        }
      }
      &__link {
        font-size: 24px;
        line-height: 32px;
        text-decoration: underline;
        color: $Secondary-Indigo500;

        &:nth-child(1) {
          margin-right: 20px;
        }
        &:nth-child(2) {
          margin-left: 20px;
        }
      }
      &__link-container {
        font-size: 18px;
        line-height: 32px;
      }
    }
  }
</style>
