<template>
  <div class="col-sm-12 flex--center change-partner-button__wrapper">
    <button
      id="changePartnerButton"
      class="button secondary-button"
      type="button"
      @click="$emit('modify-partner')"
    >
      {{ $t('partnerSubmissionPageNew.form.changePartnerButton.label') }}
    </button>
  </div>
</template>

<script>
  export default {
    name: 'ModifyPartnerButton',
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .change-partner-button__wrapper {
    margin-top: 35px;
    margin-bottom: 40px;
  }
  .flex--center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .button {
    display: flex;
    align-items: center;
    background-image: url('~@/assets/img/len_rgb_back_arrow_icon.svg');
    background-position: 32px center;
    background-repeat: no-repeat;
    padding-left: 63px;

    &.active,
    &:focus,
    &:hover {
      background-image: url('~@/assets/img/len_white_back_arrow_icon.svg');
      background-position: 32px center;
      background-repeat: no-repeat;
    }
  }

  @media screen and (max-width: $mobile-l) {
    .change-partner-button__wrapper {
      margin-top: 0;
      margin-bottom: 40px;
    }
  }
</style>
