<template>
  <div>
    <a class="open-preview-text" @click="showContract">{{
      $t('ingOfferPage.contractSection.contracts.preview')
    }}</a>
    <div :class="{ hidden: !show }" class="modal col-xs-12">
      <div class="modal-dialog modal-content">
        <div class="close-modal-container" @click="hideContract">
          <img src="/ing_close_icon.png" alt="close" />
          {{ $t('ingOfferPage.contractSection.contracts.close') }}
        </div>
        <canvas ref="contact-canvas" />
        <div class="pagination-container">
          <img
            class="pagination-arrow"
            src="/ing_back_arrow.png"
            alt="back"
            @click="goBack"
          />
          <p>
            {{ $t('ingOfferPage.contractSection.contracts.page') }}
            {{ activePage }} / {{ maxPage }}
          </p>
          <img
            class="pagination-arrow"
            src="/ing_forward_arrow.png"
            alt="forward"
            @click="goNext"
          />
        </div>
      </div>
      <div class="modal-backdrop fade in" />
    </div>
  </div>
</template>

<script>
  import pdfjs from '@/utils/pdfjsLib.js';

  export default {
    props: {
      contractDataString: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        activePage: 1,
        scale: 1,
        show: false,
        maxPage: '',
      };
    },
    methods: {
      async showContract() {
        await this.renderPdfPage();
        this.show = true;
      },
      hideContract() {
        this.show = false;
      },
      goNext() {
        if (this.activePage === this.maxPage) {
          return;
        }
        this.activePage++;
        this.renderPdfPage();
      },
      goBack() {
        if (this.activePage <= 1) {
          return;
        }
        this.activePage--;
        this.renderPdfPage();
      },
      renderDocument() {
        return pdfjs.getDocument({
          data: atob(this.contractDataString),
        });
      },
      async renderPdfPage() {
        this.renderDocument().promise.then(
          (pdf) => {
            this.maxPage = pdf.numPages;
            pdf.getPage(this.activePage).then((page) => {
              const viewport = page.getViewport({ scale: this.scale });
              const canvas = this.$refs['contact-canvas'];
              const context = canvas.getContext('2d');
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext);
            });
          },
          (error) => {
            // PDF loading error
            // eslint-disable-next-line
            console.error(error);
          }
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/variables';
  .open-preview-text {
    margin-right: 10px;
    cursor: pointer;
  }
  .modal {
    &.hidden {
      visibility: hidden;
    }

    display: block;

    .modal-content {
      overflow-x: hidden;
      overflow-y: scroll;
      max-height: 100vh;
    }
    .pagination-container {
      display: flex;
      flex-direction: row;
      width: 200px;
      justify-content: space-between;
      position: relative;
      bottom: 70px;
      margin: 0 auto;
      padding: 5px;
      .pagination-arrow {
        cursor: pointer;
      }
      p {
        color: $ing-orange;
        font-weight: bold;
        font-size: 14px;
      }
    }
    .close-modal-container {
      display: flex;
      flex-direction: row;
      position: relative;
      top: 16px;
      left: 24px;
      width: 90px;
      justify-content: space-between;
      cursor: pointer;
      color: $ing-orange;
      font-weight: bold;
      font-size: 14px;
    }
  }
</style>
