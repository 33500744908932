<template>
  <footer class="footer__wrapper" data-qa-id="antrag-footer-section">
    <len-section maxWidth="true" theme="dark" paddingBottom="2">
      <div data-qa-id="antrag-footer-child-top">
        <div class="footer__row">
          <img
            src="/logo_white.svg"
            alt=""
            class="footer__logo"
            data-qa-id="antrag-footer-top-image"
          />
          <div class="footer__social-media">
            <a
              href="https://www.facebook.com/lendico"
              target="_blank"
              data-qa-id="antrag-footer-links-facebook"
              class="footer__social-media__link"
              rel="noopener noreferrer"
            >
              <img
                src="/facebook_icon.svg"
                class="footer__social-media__link__icon"
                alt="facebook"
              />
            </a>
            <a
              href="https://twitter.com/LendicoDE"
              target="_blank"
              data-qa-id="antrag-footer-links-twitter"
              class="footer__social-media__link"
              rel="noopener noreferrer"
            >
              <img
                src="/twitter_icon.svg"
                class="footer__social-media__link__icon"
                alt="twitter"
              />
            </a>
            <a
              href="https://www.linkedin.com/company/5000225"
              target="_blank"
              data-qa-id="antrag-footer-links-linkedin"
              class="footer__social-media__link"
              rel="noopener noreferrer"
            >
              <img
                src="/linkedin_icon.svg"
                class="footer__social-media__link__icon"
                alt="linkedin"
              />
            </a>
            <a
              href="https://www.xing.com/companies/lendico"
              target="_blank"
              data-qa-id="antrag-footer-links-xing"
              class="footer__social-media__link"
              rel="noopener noreferrer"
            >
              <img
                src="/xing_icon.svg"
                class="footer__social-media__link__icon"
                alt="xing"
              />
            </a>
          </div>
        </div>
      </div>

      <div>
        <div
          class="footer__row footer__nav-menu"
          data-qa-id="antrag-footer-contacts"
        >
          <len-link
            class="footer__nav-menu__link"
            lenTarget="static-webpage"
            route="wie-funktionierts"
            text="Wie funktioniert Lendico?"
            data-qa-id="antrag-footer-contact-link-1"
          /><len-link
            class="footer__nav-menu__link"
            lenTarget="static-webpage"
            route="partner-werden"
            text="Partner Werden"
            data-qa-id="antrag-footer-contact-link-2"
          /><len-link
            class="footer__nav-menu__link"
            lenTarget="lendico-blog"
            route=""
            text="Blog"
            data-qa-id="antrag-footer-contact-link-3"
          />
          <len-link
            class="footer__nav-menu__link"
            lenTarget="static-webpage"
            route="faq"
            text="FAQ"
            data-qa-id="antrag-footer-link-internal-1"
          /><len-link
            class="footer__nav-menu__link"
            lenTarget="static-webpage"
            route="kontakt"
            text="Kontakt"
            data-qa-id="antrag-footer-link-internal-2"
          /><len-link
            class="footer__nav-menu__link"
            lenTarget="static-webpage"
            route="karriere"
            text="Karriere"
            data-qa-id="antrag-footer-link-internal-3"
          />
        </div>
      </div>

      <div data-qa-id="antrag-footer-child-bottom">
        <div class="footer__row footer__row--no-margin">
          <div class="footer__bottom">
            <div
              class="footer__bottom__copyright"
              data-qa-id="antrag-footer-bottom-left"
            >
              {{
                $t('borrowerJourney.footer.copyright.p1') +
                ' ' +
                getCurrentYear +
                ' ' +
                $t('borrowerJourney.footer.copyright.p2')
              }}
            </div>
            <div
              data-qa-id="antrag-footer-bottom-right"
              class="footer__bottom__links"
            >
              <a
                :aria-label="
                  $t('borrowerJourney.footer.cookieSettings.ariaLabel')
                "
                class="footer__bottom__link"
                href="#uc-corner-modal-show"
                >{{ $t('borrowerJourney.footer.cookieSettings.text') }}</a
              >
              <a
                href="https://www.lendico.de/firmenkredit-agb"
                class="footer__bottom__link"
                data-qa-id="antrag-footer-bottom-right-link-1"
                >AGB Kreditnehmer</a
              >
              <a
                href="https://www.lendico.de/datenschutz"
                class="footer__bottom__link"
                data-qa-id="antrag-footer-bottom-right-link-2"
                >Datenschutz</a
              >
              <a
                href="https://www.lendico.de/impressum"
                class="footer__bottom__link"
                data-qa-id="antrag-footer-bottom-right-link-3"
                >Impressum</a
              >
            </div>
          </div>
        </div>
      </div>
    </len-section>
  </footer>
</template>
<script>
  import LenLink from '@/components/common/len-link';
  import { getCurrentYear } from '@/mixins/currentYearMixin';

  export default {
    components: {
      LenLink,
    },
    mixins: [getCurrentYear],
    props: {
      showInternational: {
        type: Boolean,
        default: false,
      },
      showNavigation: {
        type: Boolean,
        default: false,
      },
      showFaq: {
        type: Boolean,
        default: false,
      },
      addTeam: {
        type: Boolean,
        default: false,
      },
      showSocial: {
        type: Boolean,
        default: false,
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .footer {
    &__wrapper {
      position: static;
      width: 100%;
      background: #2e2e2e;
      padding: 40px 0;
    }

    &__row {
      display: flex;
      align-items: center;
      margin-bottom: 24px;

      &--no-margin {
        margin-bottom: 0;
      }
    }

    &__logo {
      width: auto;
      height: 30px;
    }
    &__social-media {
      margin-left: auto;

      &__link {
        margin-right: 30px;
        display: inline-block;
        width: 30px;
        height: 30px;

        &__icon {
          max-width: 100%;
          height: auto;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &__nav-menu__link {
      font-weight: 600;
      font-size: 14px;
      color: #fff;
      line-height: 14px;
      margin-right: 20px;

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }

    &__bottom {
      display: flex;
      width: 100%;
      justify-content: space-between;

      &__copyright {
        color: #9d9d9d;
        font-size: 14px;
        line-height: 14px;
      }

      &__links {
        display: flex;
        align-items: center;
      }

      &__link {
        color: #9d9d9d;
        font-size: 14px;
        line-height: 14px;
        position: relative;
        margin-right: 16px;

        &:hover {
          text-decoration: none;
          color: #9d9d9d;
        }

        &:after {
          content: '';
          position: absolute;
          top: 1px;
          right: -8px;
          height: 13px;
          width: 1px;
          background: #9d9d9d;
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (max-width: $desktop-s) {
    .footer__row {
      flex-direction: column;
      align-items: flex-start;
    }
    .footer__social-media {
      margin-left: 0;
      margin-top: 24px;
    }

    .footer__social-media__link {
      margin-right: 15px;
    }

    .footer__nav-menu {
      flex-direction: row;
    }

    .footer__bottom {
      flex-direction: column-reverse;
    }

    .footer__bottom__copyright {
      margin-top: 10px;
    }
  }

  @media screen and (max-width: $mobile-l) {
    .footer__wrapper {
      padding: 30px 0;
    }

    .footer__logo {
      height: 20px;
    }

    .footer__nav-menu {
      flex-direction: column;

      &__link {
        margin-bottom: 12px;

        &:last-child {
          margin-bottom: 26px;
        }
      }
    }
    .footer__bottom__links {
      flex-direction: column;
      align-items: flex-start;
    }
    .footer__bottom__link {
      margin-right: 0;
      margin-bottom: 10px;

      &:after {
        display: none;
      }
    }
    .footer__bottom__copyright {
      line-height: 16px;
    }
  }
</style>
