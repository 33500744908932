<template>
  <div class="thank-you-container">
    <img
      class="ing-thank-you-image"
      srcset="/ING_thankyou.png 728w, /ING_thankyou_small.png 569w"
      sizes="(max-width: 767px) 728px, 569px"
      alt="Ing thank you image"
    />
    <div class="ing-thank-you-text">
      <div class="ing-thank-you-title">
        <img alt="Tick Icon" src="/ing_submit_icon.png" class="submit-icon" />
        <h2>
          {{ $t('borrowerSignupFormthankYouPage.mainHeader') }}
        </h2>
      </div>
      <p v-if="!getBackToYou && !isTestAndLearnEnabled">
        {{ $t('ingSignupFormThankYouPage.ingThankYouPage.thankYouText') }}
      </p>
      <p v-if="!getBackToYou && isTestAndLearnEnabled">
        {{
          $t(
            'ingSignupFormThankYouPage.ingThankYouPage.testAndLearnThankYouText'
          )
        }}
      </p>
      <p
        v-if="getBackToYou"
        v-html="
          $t('ingSignupFormThankYouPage.ingThankYouPage.getBackToYouText')
        "
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      getBackToYou: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isTestAndLearnEnabled() {
        return process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true';
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../sass/custom/variables';
  .thank-you-container {
    display: flex;
    flex-direction: row;
  }
  .ing-thank-you-image {
    width: 369px;
  }
  .submit-icon {
    width: 48px;
  }
  .ing-thank-you-title {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
    h2 {
      font-weight: normal;
      color: $ing-orange;
      font-size: 30px;
      margin-left: 20px;
      padding-bottom: 0;
    }
  }
  .ing-thank-you-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 2%;
    margin-top: 5%;
    min-height: 168px;
    p {
      margin-left: 80px;
      font-size: 14px;
      color: $ing-gray-advantages;
      word-break: break-word;
    }
  }

  @media (max-width: 899px) {
    .thank-you-container {
      flex-direction: column;
    }
    .ing-thank-you-image {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 522px) {
    .ing-thank-you-title {
      flex-direction: column;
      align-self: baseline;
      align-items: flex-start;
      margin-bottom: 40px;
      h2 {
        margin-left: 0;
        font-size: 28px;
        line-height: 36px;
        padding-bottom: 0;
      }
    }
    .submit-icon {
      margin: 20px 0;
      width: 32px;
    }
    .ing-thank-you-text {
      p {
        margin-left: 0;
      }
    }
  }
</style>
