<template>
  <div class="position--relative display--block">
    <div
      :class="{
        'skewed-edge-top': skewedTop,
        'skewed-edge-bottom': skewedBottom,
        'join-top': joinTop,
      }"
    >
      <div
        v-if="skewedTop"
        :class="{
          'to-left': topAscending,
          'to-right': !topAscending,
          dark: theme == 'dark',
          light: theme == 'light',
        }"
        class="skewed-top"
      />
      <section
        :class="{
          'skewed-content': skewedTop || skewedBottom,
          'dark-section':
            theme == 'dark' || theme == 'image' || theme == 'dark-overlay',
          'white-section': !theme || theme == 'white',
          'dark-overlay-section': theme == 'dark-overlay',
          'light-section': theme == 'light',
          image: theme == 'image',
          'top-border': borderTop,
          'bottom-border': borderBottom,
        }"
        :style="{
          paddingTop: (!!paddingTop ? paddingTop : paddingVertical) + 'px',
          paddingBottom:
            (!!paddingBottom ? paddingBottom : paddingVertical) + 'px',
          backgroundImage: background
            ? 'url(\'' + (path || '/') + background + '\')'
            : '',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }"
        class="position--relative display--block"
      >
        <div :class="{ 'max-width': maxWidth }">
          <slot />
        </div>
      </section>
      <div
        v-if="skewedBottom"
        :class="{
          'to-right': bottomAscending,
          'to-left': !bottomAscending,
          dark: theme == 'dark',
          light: theme == 'light',
          'join-bottom': joinBottom,
        }"
        class="skewed-bottom"
      />
    </div>
  </div>
</template>
<script>
  export default {
    /*eslint-disable */
    props: [
      'theme',
      'skewedTop',
      'topAscending',
      'skewedBottom',
      'bottomAscending',
      'joinTop',
      'joinBottom',
      'maxWidth',
      'borderTop',
      'borderBottom',
      'paddingVertical',
      'paddingTop',
      'paddingBottom',
      'background',
      'extension',
      'path'
    ]
    /*eslint-enable */
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  @media screen and (max-width: $desktop-s) {
    .max-width {
      padding: 0 24px;
    }
  }
  @media screen and (max-width: $mobile-l) {
    .max-width {
      padding: 0 16px;
    }
  }
</style>
