<template>
  <div v-show="loading" class="loader_layer">
    <div class="loader small" />
  </div>
</template>
<script>
  export default {
    props: {
      storeModule: {
        type: String,
        required: true,
      },
    },
    computed: {
      loading: function () {
        return this.$store.state[this.storeModule].loading;
      },
    },
  };
</script>
<style scoped>
  .loader {
    position: fixed;
  }
</style>
