<template>
  <div class="ing-privacy-policy">
    <p v-html="$t('ingSubmission.ingPrivacyPolicy.p1')" />
    <p
      >{{ $t('ingSubmission.ingPrivacyPolicy.p2') }}
      <a
        href="#"
        class="ing-link"
        @click.prevent="openModal('CreditreformModal')"
        >{{ $t('ingSubmission.ingPrivacyPolicy.cta1') }}</a
      >.<br />{{ $t('ingSubmission.ingPrivacyPolicy.p3') }}
      <a
        href="#"
        class="ing-link"
        @click.prevent="openModal('CreditreformModal')"
        >{{ $t('ingSubmission.ingPrivacyPolicy.cta1') }}</a
      >{{ $t('ingSubmission.ingPrivacyPolicy.p4') }}
      <a href="#" class="ing-link" @click.prevent="openModal('SchufaModal')">{{
        $t('ingSubmission.ingPrivacyPolicy.cta2')
      }}</a
      >{{ $t('ingSubmission.ingPrivacyPolicy.p5') }}</p
    >

    <Modal v-if="isSchufaModalOpen" @onClose="closeModal('SchufaModal')">
      <div class="modal-inner-content">
        <h2 class="modal-inner-header">
          {{ $t('ingSubmission.ingPrivacyPolicy.schufaModalContent.title') }}
        </h2>
        <div
          v-html="
            $t('ingSubmission.ingPrivacyPolicy.schufaModalContent.content')
          "
        />
      </div>
    </Modal>
    <Modal
      v-if="isCreditreformModalOpen"
      @onClose="closeModal('CreditreformModal')"
    >
      <div class="modal-inner-content">
        <h2 class="modal-inner-header">
          {{
            $t('ingSubmission.ingPrivacyPolicy.creditreFormModalContent.title')
          }}
        </h2>
        <div
          v-html="
            $t(
              'ingSubmission.ingPrivacyPolicy.creditreFormModalContent.content'
            )
          "
        />
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/common/modal';

  export default {
    components: { Modal },
    data() {
      return {
        isSchufaModalOpen: false,
        isCreditreformModalOpen: false,
      };
    },
    methods: {
      openModal(target) {
        this[`is${target}Open`] = true;
      },
      closeModal(target) {
        this[`is${target}Open`] = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/variables';
  @import '@/variables.scss';

  .ing-privacy-policy {
    font-size: 12px;
    margin: 20px 0 40px 0;
    color: $ing-gray;

    @media screen and (max-width: $tablet) and (min-width: $mobile-l) {
      margin: 40px 0;
    }

    p {
      padding-bottom: 10px;
    }

    .modal-inner-content {
      font-size: 16px;
      line-height: 24px;
      color: #333;
      letter-spacing: -0.02em;
      padding-bottom: 50px;
    }
    .modal-inner-header {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
      padding: 0;
    }
  }
</style>
