export default function ValidateLegalStatus(legalStatus) {
  if (!legalStatus) {
    return { legalStatus: 'LegalStatusIsRequired' };
  }

  if (
    [
      'GmbH',
      'GmbH & Co. KG',
      'Freie Berufe',
      'Einzelfirma',
      'KG',
      'OHG',
      'GbR',
      'AG',
      'UG (haftungsbeschränkt)',
      'Limited',
      'Andere',
    ].indexOf(legalStatus) === -1
  ) {
    return { legalStatus: 'LegalStatusIsNotCorrect' };
  }

  return { legalStatus: undefined };
}
