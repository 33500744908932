var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    dark: _vm.theme == 'dark',
    image: _vm.theme == 'image' || _vm.theme == 'dark-overlay',
    'text-align--center': _vm.align == 'center',
    'text-align--left': _vm.align == 'left',
    'text-align--right': _vm.align == 'right',
    m: _vm.medium == 'true',
    sm: _vm.small == 'true',
    'icon-svg': _vm.iconSvg == 'true',
  },style:({
    marginTop: (!!_vm.marginTop ? _vm.marginTop : _vm.marginVertical) + 'px',
    marginBottom: (!!_vm.marginBottom ? _vm.marginBottom : _vm.marginVertical) + 'px',
  })},[(_vm.icon === 'tick')?_c('tick-icon'):_vm._e(),(_vm.icon === 'thumbs-up')?_c('thumbs-up-icon'):_vm._e(),(_vm.icon === 'price-tag')?_c('price-tag-icon'):_vm._e(),(_vm.icon === 'percentage')?_c('percentage-icon'):_vm._e(),(_vm.icon === 'message')?_c('message-icon'):_vm._e(),(_vm.icon === 'lock')?_c('lock-icon'):_vm._e(),(_vm.icon === 'info')?_c('info-icon'):_vm._e(),(_vm.icon === 'group')?_c('group-icon'):_vm._e(),(_vm.icon === 'factory')?_c('factory-icon'):_vm._e(),(_vm.icon === 'old-factory-icon')?_c('old-factory-icon'):_vm._e(),(_vm.icon === 'graph')?_c('graph-icon'):_vm._e(),(_vm.icon === 'avatar')?_c('avatar-icon'):_vm._e(),(_vm.icon === 'ident')?_c('ident-card-icon'):_vm._e(),(_vm.icon === 'handshake1')?_c('handshake1-icon'):_vm._e(),(_vm.icon === 'handshake')?_c('handshake-icon'):_vm._e(),(_vm.icon === 'edit-pencil')?_c('edit-pencil-icon'):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }