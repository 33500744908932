<template>
  <Modal @onClose="$emit('onClose', 'CreditreformModal')">
    <div class="modal-inner">
      <h2 class="modal-inner__header">
        {{ $t('creditReformModalContent.title') }}
      </h2>
      <i18n path="creditReformModalContent.content.para1" tag="p">
        <em place="boldContent1">
          {{ $t('creditReformModalContent.content.boldContent1') }}
        </em>
      </i18n>
      <br />
      <i18n path="creditReformModalContent.content.para2" tag="p">
        <em place="boldContent2">
          {{ $t('creditReformModalContent.content.boldContent2') }}
        </em>
        <a
          :href="$translationConstantValues.creditReformPrivacy"
          rel="noopener noreferrer"
          place="linkText"
          target="_blank"
          >{{ $t('creditReformModalContent.content.linkText') }}</a
        >
      </i18n>
    </div>
  </Modal>
</template>

<script>
  import Modal from '@/components/common/modal';

  export default {
    name: 'CreditReformModal',
    components: { Modal },
  };
</script>

<style scoped lang="scss">
  .modal-inner {
    font-size: 16px;
    line-height: 24px;
    color: #333;
    letter-spacing: -0.02em;
    padding-bottom: 50px;

    &__header {
      text-transform: none;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      margin-bottom: 24px;
    }
  }
</style>
