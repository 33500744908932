<template>
  <ing-text-input
    v-model="referenceNumber"
    :label="$t('forms.signupForm.referenceNumber.label')"
    :readonly="readonly"
    name="referenceNumber"
    :error="error"
    :maxlength="16"
    data-qa-id="ident-reference-number"
    data-unit-test="ing-ident-reference-number-field"
    :displayErrorIfDirty="displayErrorIfDirty"
  />
</template>

<script>
  import { mapMutations, mapState } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    computed: {
      ...mapState('ident', [
        'ident',
        'errors',
        'referenceNumberEditable',
        'submitClicked',
      ]),
      referenceNumber: {
        get() {
          return this.ident.referenceNumber;
        },
        set(value) {
          this.SET_REFERENCE_NUMBER(value);
        },
      },
      error() {
        return (
          this.errors &&
          this.errors.referenceNumber &&
          this.$t(`forms.errors.${this.errors.referenceNumber}`)
        );
      },
      readonly() {
        return !this.referenceNumberEditable;
      },
      displayErrorIfDirty() {
        return !this.submitClicked && this.referenceNumberEditable;
      },
    },
    methods: {
      ...mapMutations('ident', ['SET_REFERENCE_NUMBER']),
    },
  };
</script>
