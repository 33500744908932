<template>
  <div>
    <h2>{{ $t('ingOfferPage.offerDetailsSection.title') }}</h2>
    <div class="col-lg-12 ing-offer-details-container">
      <div class="col-lg-6">
        <div class="row">
          <p class="col-sm-6">
            {{
              $t(`ingOfferPage.offerDetailsSection.${productKey}.loanAmount`)
            }}
          </p>
          <p class="col-sm-6">
            <em>{{ currencyAmount(loanAmount) }}</em>
          </p>
        </div>
        <div class="row">
          <p class="col-sm-6">
            {{ $t(`ingOfferPage.offerDetailsSection.${productKey}.duration`) }}
          </p>
          <p class="col-sm-6">
            <em v-if="isCreditLineLoan">
              {{
                $t(
                  `ingOfferPage.offerDetailsSection.${productKey}.durationValue`
                )
              }}
            </em>
            <em v-else>
              {{ duration | momentjsDuration('month') | emptyValueReplacer }}
            </em>
          </p>
        </div>
        <div class="row">
          <p class="col-sm-6">
            {{
              $t(`ingOfferPage.offerDetailsSection.${productKey}.interestRate`)
            }}
          </p>
          <p class="col-sm-6">
            <em>
              {{ interestRate }} % p.a. {{ isCreditLineLoan ? '*' : '' }}
            </em>
          </p>
        </div>
        <div v-if="isTermLoan" class="row">
          <p class="col-sm-6">
            {{ $t('ingOfferPage.offerDetailsSection.regular.monthlyRate') }}
          </p>
          <p class="col-sm-6" data-test-id="montly-rate">
            <em>{{ currencyAmount(monthlyRate) }}</em>
          </p>
        </div>
        <template v-else-if="isCreditLineLoan">
          <div class="row">
            <p class="col-sm-6">
              {{ $t('ingOfferPage.offerDetailsSection.wcl.firstPayoutAmount') }}
            </p>
            <p class="col-sm-6">
              <em>
                {{ currencyAmount(wclDisbursementAmount) }}
              </em>
            </p>
          </div>
          <div class="row">
            <p class="col-sm-6">
              {{ $t('ingOfferPage.offerDetailsSection.wcl.rateOnFirstMonth') }}
              <ing-bs-tooltip
                placement="bottom"
                :tooltipMessage="
                  $t('ingOfferPage.offerDetailsSection.wcl.rateTooltip')
                "
              />
            </p>
            <p class="col-sm-6" data-test-id="rate-on-first-month">
              <em>
                {{ currencyAmount(rateOnFirstMonth) }}
              </em>
            </p>
          </div>
        </template>
        <ing-accept-offer-button />
      </div>
      <div v-if="isCreditLineLoan" class="explanatory-text">
        {{ $t('ingOfferPage.offerDetailsSection.wcl.explanatoryText') }}
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import IngAcceptOfferButton from '@/components/ing/offer/ing-accept-offer-button';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';
  import { currencyAmount } from '@/utils/currency';
  import * as PRODUCT_TYPES from '@/constants/PRODUCT_TYPES';

  export default {
    components: { IngAcceptOfferButton, IngBsTooltip },
    computed: {
      ...mapState('offer', [
        'loanAmount',
        'duration',
        'interestRate',
        'monthlyRate',
        'productType',
        'wclDisbursementAmount',
      ]),
      isTermLoan() {
        // if this is used anywhere else in the future, move to vuex module getter
        return [
          PRODUCT_TYPES.BLACKSEA_REGULAR,
          PRODUCT_TYPES.BLACKSEA_MICRO,
          PRODUCT_TYPES.REGULAR,
          PRODUCT_TYPES.MICRO,
        ].includes(this.productType);
      },
      isCreditLineLoan() {
        // if this is used anywhere else in the future, move to vuex module getter
        return [
          PRODUCT_TYPES.BLACKSEA_WCL_REGULAR,
          PRODUCT_TYPES.BLACKSEA_WCL_MICRO,
        ].includes(this.productType);
      },
      productKey() {
        return this.isCreditLineLoan ? 'wcl' : 'regular';
      },
      rateOnFirstMonth() {
        const interestRate = Number(this.interestRate).toFixed(2);
        return (
          this.wclDisbursementAmount * (interestRate / 100 / 12) +
          this.wclDisbursementAmount * 0.08
        ).toFixed(2);
      },
    },
    methods: {
      currencyAmount,
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../sass/custom/variables';
  h2 {
    font-weight: bold;
  }

  .ing-offer-details-container {
    margin: 40px 0;
    line-height: 27px;
    color: $ing-gray-advantages;
    em {
      color: $ing-dark-gray;
    }
  }

  .explanatory-text {
    font-size: 14px;
    line-height: 22px;
    color: #696969;
  }
</style>
