<template>
  <div class="client">
    <h3 class="client__title">
      {{ clientTitle }}
    </h3>
    <p class="client__designation">
      {{ roleValue }}
    </p>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import { genderValues } from '@/validators/helpers/gender';
  import { roleValues } from '@/validators/helpers/role';
  export default {
    props: {
      signerId: {
        type: String,
        default: '',
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        genderValues,
        roleValues,
      };
    },
    computed: {
      ...mapState('signers', ['signers', 'errors']),
      signerInformation() {
        return this.signers[this.signerId];
      },
      genderValue() {
        if (this.signerInformation) {
          return Object.keys(this.genderValues).find(
            (key) => this.genderValues[key] === this.signerInformation.gender
          );
        }
        return null;
      },
      roleValue() {
        let deRoleValues = this.signerInformation.roles.map((role) => {
          return Object.keys(this.roleValues).find(
            (key) => this.roleValues[key] === role
          );
        });
        return deRoleValues.filter((role) => !!role).join(', ');
      },
      clientTitle() {
        const { firstName, lastName } = this.signerInformation;
        return `${this.genderValue || ''} ${firstName} ${lastName}`;
      },
    },
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';

  .client {
    &__title {
      font-style: normal;
      font-weight: 700;
      font-size: 19px;
      line-height: 28px;
      color: $Primary-Grey600;
      margin-bottom: unset;
    }
    &__designation {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: $Primary-Grey400;
    }
  }
</style>
