<template>
  <div>
    <div class="cash-intensity-container addMaterialDesign col-sm-5">
      <len-radio
        key="low"
        v-model="cashIntensity"
        :label="$t('forms.signupForm.cashIntensity.options.LOW')"
        name="cashIntensity"
        value="LOW"
        :hasError="displayError"
      />
      <len-radio
        key="high"
        v-model="cashIntensity"
        :label="$t('forms.signupForm.cashIntensity.options.HIGH')"
        name="cashIntensity"
        value="HIGH"
        :hasError="displayError"
      />
    </div>
    <div
      role="alert"
      class="text-block text-align--right display--block error-container"
    >
      <p v-if="displayError" class="validation">
        {{
          errors.company &&
          errors.company.cashIntensity &&
          $t('forms.errors.' + errors.company.cashIntensity)
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { cashIntensityValues } from '@/validators/helpers/cashIntensity';
  export default {
    components: { LenRadio },
    data() {
      return {
        cashIntensityValues,
      };
    },
    computed: {
      ...mapState('signers', [
        'company',
        'errors',
        'submitClicked',
        'companySubmitClicked',
      ]),
      displayError() {
        return (
          (this.submitClicked || this.companySubmitClicked) &&
          !!this.errors.company &&
          !!this.errors.company.cashIntensity
        );
      },
      cashIntensity: {
        get() {
          return this.company.cashIntensity;
        },
        set(newValue) {
          this.SET_CASH_INTENSITY(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_CASH_INTENSITY']),
    },
  };
</script>

<style scoped lang="scss">
  .cash-intensity-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 20px;
  }
  .error-container {
    height: 25px;
  }
  .validation {
    text-align: left;
  }
  @media (max-width: 870px) {
    .cash-intensity-container {
      flex-direction: column;

      label.radio-container {
        margin-bottom: 20px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
</style>
