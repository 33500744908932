import { isValidNationality } from '../helpers/nationality';
import { isEmpty } from '../helpers/string';

export default function ValidatePostalCountry(iso) {
  if (isEmpty(iso)) {
    return { postalCountry: 'CountryIsRequired' };
  }

  if (!isValidNationality(iso)) {
    return { postalCountry: 'CountryIsNotCorrect' };
  }
  return { postalCountry: undefined };
}
