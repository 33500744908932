<template>
  <div :class="{ alert: isInvalid }" class="overview-container flex--column">
    <div class="company-overview-container">
      <div class="company-data-container col-lg-7 col-sm-10">
        <loan-owner-company-title v-show="hasControllingCompany" />
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.companyName.label') }}:</em>
            <tooltip
              key="company-name-tooltip"
              :tooltipMessage="$t('forms.signupForm.companyName.tooltipBridge')"
              messageClass="company-name-tooltip-message"
            />
          </p>
          <p class="col-sm-6">
            {{ company.companyName | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.companyTaxNumber.label') }}:</em>
            <tooltip
              key="tax-number-tooltip"
              :tooltipMessage="$t('forms.signupForm.companyTaxNumber.tooltip')"
              messageClass="company-tax-number-tooltip-message"
            />
          </p>
          <p class="col-sm-6">
            {{ company.companyTaxNumber | emptyValueReplacer }}
          </p>
        </div>
        <div id="tradingNameOverview" class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.tradingName.label') }}:</em>
            <tooltip
              key="trading-name-tooltip"
              :tooltipMessage="$t('forms.signupForm.tradingName.tooltip')"
              messageClass="trading-name-tooltip-message"
            />
          </p>
          <p class="col-sm-6">
            {{ company.companyTradingName | emptyValueReplacer }}
          </p>
        </div>
        <regular-loan-request-overview v-show="isRegularLoanRequest" />
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.businessAddress.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ currentAddress | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-6">
            <em>{{ $t('forms.signupForm.postalAddress.label') }}:</em>
          </p>
          <p class="col-sm-6">
            {{ postalAddress | emptyValueReplacer }}
          </p>
        </div>
      </div>
      <div>
        <edit-button
          class="edit-button-alignment"
          @edit-button-clicked="$emit('edit-button-clicked')"
        />
      </div>
    </div>
    <controlling-company-overview v-show="hasControllingCompany" />
    <div class="separator" />
    <cash-intensity-title />
    <p>{{ cashIntensityValue | emptyValueReplacer }}</p>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import CashIntensityTitle from '@/components/signers/signers-company-information/cash-intensity-title';
  import ControllingCompanyOverview from '@/components/signers/signers-company-information/company-information-overview/controlling-company-overview';
  import LoanOwnerCompanyTitle from '@/components/signers/signers-company-information/loan-owner-company-title';
  import RegularLoanRequestOverview from '@/components/signers/signers-company-information/company-information-overview/regular-loan-request-overview';
  import EditButton from '@/components/signers/edit-button';
  import { cashIntensityValues } from '@/validators/helpers/cashIntensity';
  import {
    isRegularLoanRequest,
    hasControllingCompany,
  } from '@/mixins/signersMixin';
  import Tooltip from '@/components/signers/signers-tooltip';

  export default {
    components: {
      CashIntensityTitle,
      ControllingCompanyOverview,
      LoanOwnerCompanyTitle,
      RegularLoanRequestOverview,
      EditButton,
      Tooltip,
    },
    mixins: [isRegularLoanRequest, hasControllingCompany],
    props: {
      isInvalid: {
        type: Boolean,
      },
    },
    data() {
      return {
        cashIntensityValues,
      };
    },
    computed: {
      ...mapState('signers', ['company']),
      currentAddress() {
        if (this.company.street) {
          return `${this.company.street} ${this.company.streetNumber}, ${this.company.zip} ${this.company.city}, ${this.company.country}`;
        } else {
          return null;
        }
      },
      postalAddress() {
        if (this.company.postalStreet) {
          return `${this.company.postalStreet} ${this.company.postalStreetNumber}, ${this.company.postalZip} ${this.company.postalCity}, ${this.company.postalCountry}`;
        } else if (this.company.businessAddressIsPostalAddress) {
          return this.currentAddress;
        } else {
          return null;
        }
      },
      cashIntensityValue() {
        return this.$t(
          `forms.signupForm.cashIntensity.options.${this.company.cashIntensity}`
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/settings';
  .company-overview-container {
    display: flex;
    justify-content: space-between;
  }
  .edit-button-alignment {
    margin-left: auto;
  }
  .flex--column {
    flex-direction: column;
  }

  p {
    font-size: 14px;
    line-height: 21px;
  }
  .separator {
    border-top: 1px solid $lightGrey;
    width: 90%;
    margin: 20px 40px;
  }
  .overview-container {
    &.alert {
      border: solid 1px $red;
    }
  }
  @media (max-width: 990px) {
    .company-overview-container {
      flex-wrap: wrap;
      word-break: break-all;
    }
    .company-data-container {
      flex: 1 0 33%;
    }
    .edit-button-alignment {
      flex-basis: 100%;
      padding: 20px 0;
    }
  }
  @media (max-width: 400px) {
    .company-overview-container {
      flex-direction: column-reverse;
    }
  }
</style>
