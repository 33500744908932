<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('signers.companyDetails.cashIntensity.title') }}
      </h3>
      <RadioGroup
        v-model="cashIntensity"
        :radioOptions="cashIntensityOptions"
        :rowList="false"
        inputSize="xs"
        :title="$t('signers.companyDetails.cashIntensity.message')"
        :toolTip="$t('forms.signupForm.cashIntensity.tooltip')"
        :displayErrorIfSubmitted="displayError()"
        :error="getError('cashIntensity')"
      />
    </template>
  </FormSubSection>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import RadioGroup from '@/components/ingOrangeJuice/Base/RadioGroup';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  export default {
    components: { RadioGroup, FormSubSection },
    mixins: [dropdownOptionsMixin],
    computed: {
      ...mapState('signers', [
        'company',
        'errors',
        'submitClicked',
        'companySubmitClicked',
      ]),
      cashIntensity: {
        get() {
          return this.company.cashIntensity;
        },
        set(newValue) {
          this.SET_CASH_INTENSITY(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_CASH_INTENSITY']),
      getError(field) {
        return (
          this.errors.company &&
          this.errors.company[field] &&
          this.$t('forms.errors.' + this.errors.company[field])
        );
      },
      displayError() {
        return !this.submitClicked && !this.companySubmitClicked;
      },
    },
  };
</script>
