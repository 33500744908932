import { isEmpty } from '@/validators/helpers/string';
import { isValidGermanPhone } from '@/validators/helpers/phone';

export default function ValidateIdentPhone(phone) {
  if (isEmpty(phone)) {
    return { mobilePhone: 'PhoneIsRequired' };
  }
  if (!isValidGermanPhone(phone)) {
    return { mobilePhone: 'PhoneIsNotCorrect' };
  }

  return { mobilePhone: undefined };
}
