<template>
  <div class="header-container">
    <len-icon icon="group" medium="true" align="center" />
    <div v-if="isRegularLoanRequest" class="text-container">
      <h2>
        {{
          $t(
            'signersMissingInfo.formPage.clientsSection.regularLoanRequest.title.h2'
          )
        }}
      </h2>
      <p
        v-html="
          $t(
            'signersMissingInfo.formPage.clientsSection.regularLoanRequest.title.p'
          )
        "
      />
    </div>
    <div v-else class="text-container">
      <h2>
        {{
          $t(
            'signersMissingInfo.formPage.clientsSection.microLoanRequest.title.h2'
          )
        }}
      </h2>
      <p
        v-html="
          $t(
            'signersMissingInfo.formPage.clientsSection.microLoanRequest.title.p'
          )
        "
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import LenIcon from '@/components/common/len-icon';
  import { isRegularLoanRequest } from '@/mixins/signersMixin';

  export default {
    components: {
      LenIcon,
    },
    mixins: [isRegularLoanRequest],
    computed: {
      ...mapState('signers', ['company']),
    },
  };
</script>

<style scoped></style>
