<template>
  <div class="ing-wrapper signer-form">
    <TheHeader />
    <IngAuthentication
      v-if="isUserUnauthorised"
      :id="opportunityId"
      @onResponse="onResponse"
    />
    <IngButton
      type="button"
      class="outlined small align-right"
      @click="userLogoutMixin()"
      >{{ $t('logoutSection.logoutButton') }}</IngButton
    >
    <form @submit.prevent="submit">
      <PageLoader storeModule="signers" />
      <OfferConditionsInformation />
      <CompanyInformation />
      <ClientInformation />
      <IdentTypeInformation />
      <FormSubmission />
    </form>
    <TheFooter />
  </div>
</template>
<script>
  import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import PageLoader from '@/components/ingOrangeJuice/Structure/PageLoader';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import OfferConditionsInformation from '@/components/signers/OfferConditionsInformation';
  import CompanyInformation from '@/components/signers/CompanyInformation';
  import ClientInformation from '@/components/signers/ClientInformation';
  import IdentTypeInformation from '@/components/signers/IdentTypeInformation';
  import FormSubmission from '@/components/signers/FormSubmission';
  import IngAuthentication from '@/components/IngAuthentication';
  import { userLogoutMixin } from '@/mixins/userLogoutMixin';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import { TRACK_EVENTS } from '@/constants/WEBTREKK';
  import { LINK_DEACTIVATED } from '@/constants/ERROR';
  export default {
    name: 'IngClientUIPage',
    components: {
      TheHeader,
      TheFooter,
      PageLoader,
      IngButton,
      OfferConditionsInformation,
      CompanyInformation,
      ClientInformation,
      IdentTypeInformation,
      FormSubmission,
      IngAuthentication,
    },
    mixins: [webtrekkMixin, userLogoutMixin],
    props: {
      opportunityId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        isAllDataProvided: false,
      };
    },
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
    computed: {
      ...mapState('signers', ['signers', 'company', 'success', 'errors']),
      ...mapGetters('signers', ['isValid']),
      isUserUnauthorised() {
        return (
          this.getDataReturnsUnauthorised ||
          this.companyUpdateReturnsUnauthorised ||
          this.clientUpdateReturnsUnauthorised
        );
      },
      getDataReturnsUnauthorised() {
        return !!this.errors.server && this.errors.server.status === 401;
      },
      companyUpdateReturnsUnauthorised() {
        return (
          !!this.errors.companyServer &&
          this.errors.companyServer.status === 401
        );
      },
      clientUpdateReturnsUnauthorised() {
        return (
          !!this.errors.clientServer && this.errors.clientServer.status === 401
        );
      },
    },
    mounted() {
      this.setOpportunityId();
      this.fetchClientsInfo();
    },
    methods: {
      ...mapMutations('signers', [
        'SET_OPPORTUNITY_ID',
        'SET_SUBMIT_CLICKED',
        'SET_LOADING',
        'CLEAR_REQUEST_ERROR',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
      ]),
      ...mapActions('signers', [
        'getSignersMissingDetails',
        'getCompanyDetails',
        'updateSignersMissingDetails',
      ]),
      setOpportunityId() {
        this.SET_OPPORTUNITY_ID(this.opportunityId);
      },
      clearServerError() {
        this.fetchClientsInfo();
      },
      async fetchClientsInfo() {
        this.SET_LOADING(true);
        this.CLEAR_REQUEST_ERROR();
        await this.getSignersMissingDetails();
        await this.getCompanyDetails();
        this.redirectIfNoMissingData();
        this.trackPageView();
        this.updateSignersPostalAddress();
        this.SET_LOADING(false);
      },
      redirectIfNoMissingData() {
        if (
          !this.isUserUnauthorised &&
          this.errors.server &&
          this.errors.server.data &&
          this.errors.server.data.code === 70004
        ) {
          this.isAllDataProvided = true;
          this.trackAndRedirectToSuccess();
        }
      },
      trackPageView() {
        if (!this.isUserUnauthorised && !this.isAllDataProvided) {
          this.trackEvent(TRACK_EVENTS.BRIDGE_CLIENT_UI);
        }
      },
      trackAndRedirectToSuccess() {
        this.$router.push({ name: 'web-firmenkredit-vorschlag-danke' });
        this.trackEvent(TRACK_EVENTS.BRIDGE_CLIENT_UI_SUCCESS);
      },
      updateSignersPostalAddress() {
        Object.keys(this.signers).forEach((signerId) =>
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(signerId)
        );
      },
      async submit() {
        this.CLEAR_REQUEST_ERROR();
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        this.SET_LOADING(true);
        await this.updateSignersMissingDetails();
        this.SET_LOADING(false);
        if (this.success) {
          this.trackAndRedirectToSuccess();
          this.logOut();
        }
      },
      onResponse(status) {
        if (status === LINK_DEACTIVATED) {
          this.trackAndRedirectToSuccess();
        }
        this.clearServerError();
      },
    },
  };
</script>
