<template>
  <div class="col-sm-12" data-qa-id="submit-section-marketing">
    <fieldset
      class="form-field u-mt- u-pv- addMaterialDesign u-mv-"
      data-qa-id="submit-marketing-fieldset"
    >
      <div class="checkbox" data-qa-id="submit-marketing-checkbox">
        <label>
          <input
            v-model="marketingAgreement"
            type="checkbox"
            name="MarketingAgreement__c"
          />
          <span class="checkbox-material">
            <span class="check" />
          </span>
          <div class="display--inline-table">
            <p
              class="checkbox-label"
              data-qa-id="submit-marketing-checkbox-text"
            >
              {{
                $t('forms.signupForm.marketingAgreement.preMailLabel.label1')
              }}
              <span
                class="tooltip-icon"
                @mouseover="showTooltip()"
                @mouseleave="hideTooltip()"
                >{{
                  $t('forms.signupForm.marketingAgreement.preMailLabel.label2')
                }}
                <span
                  :class="{ 'show-tooltip': mouseOver }"
                  class="tooltip-content"
                  @mouseover="showTooltip()"
                  @mouseleave="hideTooltip()"
                >
                  FinCompare GmbH
                </span>
              </span>
              {{
                $t('forms.signupForm.marketingAgreement.preMailLabel.label3')
              }}
              <a
                :href="'mailto:' + $t('companiesEmail')"
                target="_blank"
                class="colored-link remove-white-space"
                data-qa-id="submit-terms-link"
                >{{ $t('companiesEmail') }}</a
              >
              {{ $t('forms.signupForm.marketingAgreement.postMailLabel') }}
            </p>
          </div>
        </label>
      </div>
    </fieldset>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    mixins: [lenFormFieldGTMTrackingMixin],
    data() {
      return {
        mouseOver: false,
      };
    },
    computed: {
      label() {
        return `${this.$t(
          'forms.signupForm.marketingAgreement.preMailLabel.label1'
        )}${this.$t(
          'forms.signupForm.marketingAgreement.preMailLabel.label3'
        )}${this.$t('companiesEmail')}${this.$t(
          'forms.signupForm.marketingAgreement.postMailLabel'
        )}`;
      },
      marketingAgreement: {
        get() {
          return this.$store.state.signupForm.marketingAgreement;
        },
        set(value) {
          this.SET_MARKETING_AGREEMENT(value);
          this.sendFieldTrackingEvent(
            `checkbox:${this.label}`,
            value.toString()
          );
        },
      },
      ...mapState('signupForm', ['errors']),
    },
    methods: {
      ...mapMutations('signupForm', ['SET_MARKETING_AGREEMENT']),
      showTooltip() {
        this.mouseOver = true;
      },
      hideTooltip() {
        setTimeout(() => (this.mouseOver = false), 500);
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/custom/_settings.scss';

  .checkbox-label {
    font-size: 16px;
    line-height: 18px;
  }
  .tooltip-icon {
    position: relative;
    background: url('~@/assets/img/tooltip_icon.svg') 96% 2px no-repeat;
    padding-right: 28px;
    display: inline-block;
  }
  .tooltip-content {
    display: none;
    position: absolute;
    bottom: 25px;
    left: 93px;
    background-color: #ffffff;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25), 0 0 4px rgba(46, 46, 46, 0.1);
    border-radius: 1px;
    font-size: 12px;
    line-height: 14px;
    padding: 15px 20px;
    color: $darkGrey;
    z-index: 100;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    white-space: nowrap;

    &:after {
      content: '';
      position: absolute;
      bottom: -5px;
      left: 50%;
      border-style: solid;
      border-width: 5px 5px 0;
      border-color: #ffffff transparent;
      display: block;
      width: 0;
      z-index: 1;
    }
  }
  .show-tooltip {
    display: block;
  }
  .remove-white-space {
    margin-right: -2px;
  }
</style>
