<template>
  <len-text-input
    v-model="postalStreetNumber"
    :name="'postal-street-number-' + signerId"
    :label="$t('forms.signupForm.streetNumber.label')"
    :error="
      errors[signerId] &&
      errors[signerId].postalStreetNumber &&
      $t('forms.errors.' + errors[signerId].postalStreetNumber)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      postalStreetNumber: {
        get() {
          return this.signers[this.signerId].postalStreetNumber;
        },
        set(value) {
          this.SET_POSTAL_STREET_NUMBER({
            signerId: this.signerId,
            postalStreetNumber: value,
          });
        },
      },
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      ...mapMutations('signers', ['SET_POSTAL_STREET_NUMBER']),
    },
  };
</script>
