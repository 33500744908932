<template>
  <div>
    <PageHeader page="signers.client" />
    <PageSection class="section-form" sectionPadding="top-no">
      <template #section-content>
        <FormSectionHeader section="signers.offerConditions" :paraCount="1" />
        <div class="offer-conditions-container">
          <div class="offer-conditions-container__col">
            <div class="section">
              <div class="section__list">
                <div class="section__row">
                  <span>
                    {{ $t('signers.offerConditions.loanInfo.loanAmount') }}:
                  </span>
                  <span>
                    {{
                      offerConditions.loanAmount
                        | emptyValueReplacer
                        | currencyShort
                    }}
                    EUR
                  </span>
                </div>
                <div class="section__row">
                  <span>
                    {{ $t('signers.offerConditions.loanInfo.duration') }}:
                  </span>
                  <span>
                    {{
                      offerConditions.durationInMonths
                        | momentjsDuration('month')
                        | emptyValueReplacer
                    }}
                  </span>
                </div>
                <div class="section__row">
                  <span>
                    {{ $t('signers.offerConditions.loanInfo.interestRate') }}:
                  </span>
                  <span>
                    {{
                      offerConditions.interestRate
                        | emptyValueReplacer
                        | europeanCurrency
                    }}
                    %
                  </span>
                </div>
                <div class="section__row">
                  <span>
                    {{ $t('signers.offerConditions.loanInfo.monthlyRate') }}:
                  </span>
                  <span>
                    {{
                      offerConditions.installment
                        | emptyValueReplacer
                        | europeanCurrency
                    }}
                    EUR
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="offer-conditions-container__col" />
        </div>
      </template>
    </PageSection>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  export default {
    name: 'OfferConditionsInformation',
    components: {
      PageHeader,
      FormSectionHeader,
      PageSection,
    },
    computed: {
      ...mapState('signers', ['offerConditions']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';

  .offer-conditions-container {
    display: flex;
    flex-wrap: wrap;
    column-gap: 3%;
    &__col {
      flex-basis: 100%;
      box-sizing: content-box;
      @include mq-size(s) {
        flex-basis: 87.15%;
      }
      @include mq-size(m) {
        flex-basis: 48.5%;
      }
      @include mq-size(l) {
        flex-basis: 48.5%;
      }
    }

    .section {
      display: flex;
      column-gap: 3%;
      row-gap: 48px;
      flex-wrap: wrap;
      padding-bottom: 48px;
      &:last-child {
        padding-bottom: 0;
      }
      &__list {
        flex-basis: 100%;
        @include mq-size(xl) {
          flex-basis: 98.5%;
        }
      }
      &__row {
        display: flex;
        column-gap: 3%;
        flex-wrap: wrap;
        @include mq-size(s) {
          column-gap: 3%;
        }
        @include mq-size(xl) {
          column-gap: 5%;
        }
        span {
          font-size: 16px;
          line-height: 24px;
          flex-basis: 100%;
          word-break: break-all;
          @include mq-size(s) {
            flex-basis: 48.5%;
          }
          @include mq-size(xl) {
            flex-basis: 47.5%;
          }
          &:first-child {
            @include mq-size(s) {
              text-align: right;
            }
          }
          &:last-child {
            font-weight: 700;
            padding-bottom: 5px;
            min-height: 24px;
            @include mq-size(s) {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
</style>
