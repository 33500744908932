import { isEmpty } from '../helpers/string';
import { isValidNationality } from '../helpers/nationality';

export default function ValidateIdentificationPlaceOfIssue(
  identificationPlaceOfIssue
) {
  if (isEmpty(identificationPlaceOfIssue)) {
    return {
      identificationPlaceOfIssue: 'IdentificationPlaceOfIssueIsRequired',
    };
  }
  if (!isValidNationality(identificationPlaceOfIssue)) {
    return {
      identificationPlaceOfIssue: 'IdentificationPlaceOfIssueIsNotCorrect',
    };
  }
  return { identificationPlaceOfIssue: undefined };
}
