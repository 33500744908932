<template>
  <div>
    <len-header :loanCalculator="showLoanCalculator">
      <div slot="headerCopy" class="hero-copy max-width text-align--center">
        <h2 data-qa-id="header-text" v-html="$t('borrowerSignupPage.title')" />
      </div>
      <div
        v-if="!showLoanCalculator"
        slot="loanCalcPlaceholder"
        class="loanCalcPlaceholder"
      >
        <span class="loader small centered-loader" />
      </div>
    </len-header>

    <len-section paddingVertical="40" maxWidth="true">
      <borrower-signup-form @success="borrowerSignupSuccess()" />
    </len-section>

    <len-promises />

    <len-contact rootTranslationKey="borrowerSignupPage" />

    <len-footer />
  </div>
</template>

<script>
  import LenHeader from '@/components/common/len-header';
  import LenContact from '@/components/contact';
  import LenPromises from '@/components/promises';
  import BorrowerSignupForm from '@/components/borrower/forms/borrower-signup-form';
  import { routerPushByName } from '@/mixins/routerMixin';
  import { enableGTM } from '@/mixins/gtmMixins';
  import { calculatorLoaderMixin } from '@/mixins/calculatorLoaderMixin';
  import {
    getUrlParameterByName,
    updateUrlWithParams,
  } from '@/mixins/urlParamMixin';

  export default {
    name: 'BorrowerSignupPage',
    components: {
      LenHeader,
      BorrowerSignupForm,
      LenContact,
      LenPromises,
    },
    mixins: [
      enableGTM,
      routerPushByName,
      getUrlParameterByName,
      updateUrlWithParams,
      calculatorLoaderMixin,
    ],
    data() {
      return {
        showLoanCalculator: true,
      };
    },
    beforeMount() {
      this.loadCalculator();
    },
    methods: {
      borrowerSignupSuccess() {
        this.$router.replace({ name: 'borrower-signup-thankyou-page' });
      },
    },
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerSignupPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerSignupPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerSignupPage.metaDescription'),
          },
        ],
        link: [
          {
            rel: 'alternate',
            hreflang: 'nl-nl',
            href: 'https://lendico.nl/lenen/registratie',
          },
          {
            rel: 'alternate',
            hreflang: 'de-ch',
            href: 'https://www.lendico.ch/firmenkredit-registrieren.html',
          },
          {
            rel: 'alternate',
            hreflang: 'en-ch',
            href: 'https://www.lendico.ch/business-loan-signup.html',
          },
          {
            rel: 'alternate',
            hreflang: 'de-de',
            href: 'https://antrag.lendico.de/new',
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .loanCalcPlaceholder {
    height: 242px;
    width: 100%;
    background-color: #2e2e2e;
    opacity: 0.6;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .centered-loader {
    position: static;
  }
</style>
