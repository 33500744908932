<template>
  <len-text-input
    v-model="postalStreet"
    :label="$t('forms.signupForm.streetOnly.label')"
    :error="
      errors.company &&
      errors.company.postalStreet &&
      $t('forms.errors.' + errors.company.postalStreet)
    "
    :displayErrorIfDirty="!submitClicked && !companySubmitClicked"
    name="company-postal-street"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenTextInput from '@/components/common/len-text-input';

  export default {
    components: { LenTextInput },
    computed: {
      postalStreet: {
        get() {
          return this.company.postalStreet;
        },
        set(value) {
          this.SET_COMPANY_POSTAL_STREET(value);
        },
      },
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
    },
    methods: {
      ...mapMutations('signers', ['SET_COMPANY_POSTAL_STREET']),
    },
  };
</script>
