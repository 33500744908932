<template>
  <header class="ing-header">
    <ing-logo class="ing-logo" />
  </header>
</template>

<script>
  import IngLogo from '@/components/ing/ing-logo';

  export default {
    components: { IngLogo },
  };
</script>
