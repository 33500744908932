<template>
  <div class="col-sm-12 submit-container" data-qa-id="submit-form-button-div">
    <p v-if="applicant === 'partner'">
      {{ $t('partnerSubmissionPageNew.form.submitHeader.explanation') }}
    </p>
    <div
      v-if="errorMessage !== ''"
      role="alert"
      class="general-error-container"
    >
      <p class="validation" v-html="errorMessage" />
    </div>
    <button
      class="button"
      type="submit"
      data-qa-id="submit-form-button"
      v-html="$t('forms.signupForm.submit')"
    />
  </div>
</template>
<script>
  import { submitFormFunctionality } from '@/mixins/forms/submitFormMixin';
  export default {
    mixins: [submitFormFunctionality],
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .submit-container {
    margin-top: 80px;
    text-align: center;
  }
  .general-error-container {
    margin-bottom: 20px;
  }
  .general-error-container p.validation {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
  }
  @media only screen and (max-width: $mobile-l) {
    .submit-container {
      margin-top: 40px;
    }
    .general-error-container p.validation {
      line-height: 18px;
    }
  }
</style>
