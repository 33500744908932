<template>
  <svg viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.1582 5.7954C9.3882 5.8084 8.6512 5.2124 8.6582 4.4014C8.6522 3.5954 9.3802 2.9874 10.1582 3.0004C10.9362 2.9864 11.6572 3.5964 11.6512 4.4014C11.6582 5.2134 10.9262 5.8094 10.1582 5.7954ZM11.3252 14.3874C10.1782 14.8404 9.7662 15.0084 9.0772 14.9964C8.3772 15.0394 7.1242 14.6384 7.1502 13.4204C7.1522 12.0484 8.1192 10.0274 8.1332 8.8964C8.2032 8.2274 7.6472 7.8724 6.3492 8.3564L6.5152 7.6704C7.8422 7.1354 8.2842 7.0674 8.7622 7.0624C9.7892 6.9944 10.6842 7.6564 10.6532 8.6374C10.6532 10.0184 9.7182 11.9224 9.6722 13.1604C9.5652 14.0314 10.6032 14.0784 11.4912 13.7024L11.3252 14.3874ZM16.0002 0.000396729H2.0002C0.897198 0.000396729 0.000198364 0.897397 0.000198364 2.0004V16.0004C0.000198364 17.1024 0.897198 18.0004 2.0002 18.0004H16.0002C17.1022 18.0004 18.0002 17.1024 18.0002 16.0004V2.0004C18.0002 0.897397 17.1022 0.000396729 16.0002 0.000396729Z"
      fill="#559BD1"
    />
  </svg>
</template>
<script>
  export default {
    name: 'IconInfo',
  };
</script>
<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  svg {
    width: px2rem(18);
    height: px2rem(18);
  }
</style>
