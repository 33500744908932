<template>
  <PageSection class="contact" sectionPadding="lg">
    <template #section-content>
      <h3 class="contact__headline">
        {{ $t('theContact.title') }}
      </h3>
      <i18n path="theContact.para1.text" tag="p" class="contact__content">
        <template #boldText>
          <em>
            {{ $t('theContact.para1.boldText') }}
          </em>
        </template>
      </i18n>

      <i18n
        path="theContact.para2.text"
        tag="p"
        class="contact__link-container"
      >
        <template #phoneNumber>
          <a
            :href="`tel:${$translationConstantValues.theContact[container].contact.phone}`"
            class="contact__link"
            >{{
              $translationConstantValues.theContact[container].contact.phone
            }}</a
          >
        </template>
        <template #emailAddress>
          <a
            :href="`mailto:${$translationConstantValues.theContact[container].contact.email}`"
            class="contact__link"
            >{{
              $translationConstantValues.theContact[container].contact.email
            }}</a
          >
        </template>
      </i18n>
    </template>
  </PageSection>
</template>

<script>
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  export default {
    name: 'TheContact',
    components: {
      PageSection,
    },
    props: {
      container: {
        type: String,
        required: true,
        validator: (prop) =>
          ['amazon', 'bridge', 'partner', 'partnerRegister'].includes(prop),
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .contact {
    &__headline {
      font-size: 32px;
      line-height: 40px;
      color: $Primary-Orange;
      font-weight: 400;
      padding-bottom: 24px;
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__content {
      font-size: 16px;
      line-height: 24px;
      padding-bottom: 24px;
      &:last-child {
        padding-bottom: 0;
      }
      @include mq-size(m) {
        width: 89.6%;
      }
      @include mq-size(xl) {
        width: 69.3%;
      }
    }
    &__link {
      font-size: 24px;
      line-height: 32px;
      text-decoration: underline;
    }
    &__link-container {
      font-size: 18px;
      line-height: 32px;
      display: flex;
      column-gap: 20px;
      flex-wrap: wrap;
    }
  }
</style>
