import { controllingCompanyLegalForm } from '@/validators/helpers/legalStatus';
import { mapState, mapGetters } from 'vuex';

export const isRegularLoanRequest = {
  computed: {
    ...mapState('signers', ['offerConditions']),
    isRegularLoanRequest: function () {
      const { productType } = this.offerConditions;
      return productType && productType.includes('REGULAR');
    },
  },
};

export const hasControllingCompany = {
  computed: {
    ...mapState('signers', ['company']),
    ...mapGetters('signers', ['isComplexLegalForm']),
    hasControllingCompany: function () {
      let isGMBH_CO_KG = this.company.legalForm === controllingCompanyLegalForm; //GMBH_CO_KG
      return process.env.VUE_APP_FEATURE_FLAG_MLLF_ENABLED === 'true'
        ? isGMBH_CO_KG || this.isComplexLegalForm //GMBH
        : isGMBH_CO_KG;
    },
  },
};
export const isPartnerChannel = {
  computed: {
    ...mapState('signers', ['offerConditions']),
    isPartnerChannel: function () {
      return this.offerConditions.channel === 'PARTNER';
    },
  },
};

export const mapErrorMessage = {
  methods: {
    mapErrorMessage: function (data) {
      const hasDuplicates = data.message.includes('duplicates');
      if (
        hasDuplicates &&
        data.message.includes('cellphones') &&
        data.message.includes('emails')
      ) {
        return this.$t('forms.signupForm.errors.70007.both');
      }
      if (hasDuplicates && data.message.includes('cellphone')) {
        return this.$t('forms.signupForm.errors.70007.phones');
      }
      if (hasDuplicates && data.message.includes('emails')) {
        return this.$t('forms.signupForm.errors.70007.emails');
      }
      if (data.message.includes('emails')) {
        return this.$t('forms.signupForm.errors.70011');
      }
      return this.$t('forms.signupForm.errors.500');
    },
  },
};

export const isTestAndLearnEnabled = {
  computed: {
    isTestAndLearnEnabled: function () {
      return process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true';
    },
  },
};
export const isFeatureFlagMLLFEnabled = {
  computed: {
    isFeatureFlagMLLFEnabled: function () {
      return process.env.VUE_APP_FEATURE_FLAG_MLLF_ENABLED === 'true';
    },
  },
};
