import { isValidNationality } from '@/validators/helpers/nationality';
import { isEmpty } from '@/validators/helpers/string';

export default function ValidateNationality(iso) {
  if (isEmpty(iso)) {
    return { nationality: 'NationalityIsRequired' };
  }
  if (!isValidNationality(iso)) {
    return { nationality: 'NationalityIsNotCorrect' };
  }
  return { nationality: undefined };
}
