<template>
  <div class="partner-information-container">
    <form-loader key="partnerCheckLoader" storeModule="partner" />
    <div class="col-sm-12">
      <partner-information-header />
    </div>
    <div class="col-sm-12">
      <partner-company-name-field />
    </div>
    <div class="partner-info-container row">
      <div class="col-sm-6 first-column">
        <partner-first-name-field />
        <partner-last-name-field />
      </div>
      <div class="col-sm-6 second-column">
        <partner-email-field />
      </div>
    </div>
    <div class="col-sm-12">
      <partner-submit
        class="flex--center"
        @success="$emit('partner-complete')"
      />
    </div>
  </div>
</template>

<script>
  import PartnerInformationHeader from './partner-information-header';
  import PartnerCompanyNameField from './partner-company-name-field/index';
  import PartnerFirstNameField from './partner-first-name-field';
  import PartnerLastNameField from './partner-last-name-field';
  import PartnerEmailField from './partner-email-field';
  import PartnerSubmit from './partner-submit';
  import FormLoader from '@/components/common/forms/form-loader';

  export default {
    components: {
      PartnerInformationHeader,
      PartnerCompanyNameField,
      PartnerFirstNameField,
      PartnerLastNameField,
      PartnerEmailField,
      PartnerSubmit,
      FormLoader,
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .first-column,
  .second-column {
    vertical-align: top;
    margin-top: 4px;
  }
  .first-column {
    padding-right: 3.5%;
  }
  .second-column {
    padding-left: 3.5%;
  }
  .flex--center {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  @media screen and (max-width: $mobile-l) {
    .first-column,
    .second-column {
      padding: 0;
      margin-top: 0;
    }
    .first-column {
      margin-top: 4px;
    }
  }
</style>
