import { mapState, mapActions, mapMutations } from 'vuex';
import { webtrekkMixin } from '@/mixins/webtrekkMixin';
const trackRoute = {
  bridge: {
    client: {
      routerName: 'web-firmenkredit-vorschlag-logout',
      firmenkredit: 'BRIDGE_CLIENT_UI_LOGOUT',
    },
    offer: {
      routerName: 'web-firmenkredit-vertrag-logout',
      firmenkredit: 'BRIDGE_OFFER_UI_LOGOUT',
    },
  },
  amazon: {
    client: {
      routerName: 'ing-signers-missing-info-form-logout',
      firmenkredit: 'AMAZON_FIRMENKREDIT_CLIENT_UI_LOGOUT',
      flexkredit: 'AMAZON_FLEXKREDIT_CLIENT_UI_LOGOUT',
    },
    offer: {
      routerName: 'ing-offer-page-logout',
      firmenkredit: 'AMAZON_FIRMENKREDIT_OFFER_UI_LOGOUT',
      flexkredit: 'AMAZON_FLEXKREDIT_OFFER_UI_LOGOUT',
    },
  },
};
export const userLogoutMixin = {
  mixins: [webtrekkMixin],
  computed: {
    ...mapState('authentication', ['error', 'success']),
  },
  methods: {
    ...mapActions('authentication', ['logOut']),
    ...mapMutations('authentication', [
      'CLEAR_ERROR',
      'CLEAR_SUCCESS',
      'CLEAR_AUTHENTICATION',
    ]),
    ...mapMutations('signers', ['CLEAR_SIGNERS', 'SET_LOADING']),
    ...mapMutations('offer', ['CLEAR_OFFER']),
    ...mapMutations('contract', ['CLEAR_CONTRACT']),
    async userLogoutMixin(productName = 'firmenkredit') {
      let channel = this.$store.state.appSettings.channel;
      let page = this.$store.state.appSettings.page;
      this.SET_LOADING(true);
      this.CLEAR_AUTHENTICATION();

      await this.logOut();
      const successData = this.$store.state.authentication?.success?.data;
      if (successData) {
        if (page === 'client') {
          this.CLEAR_SIGNERS();
        } else {
          this.CLEAR_OFFER();
          this.CLEAR_CONTRACT();
        }
        this.trackEvent(trackRoute[channel][page][productName]);
        this.$router.push({
          name: trackRoute[channel][page].routerName,
        });
        this.CLEAR_AUTHENTICATION();
      }
      this.SET_LOADING(false);
    },
  },
};
