<template>
  <div class="col-sm-12" data-qa-id="submit-section-terms">
    <fieldset
      :class="{ error: displayError }"
      class="form-field addMaterialDesign"
      data-qa-id="submit-terms-fieldset"
    >
      <div class="checkbox" data-qa-id="submit-terms-checkbox">
        <label>
          <input
            ref="terms-and-conditions-input"
            v-model="termsAndConditions"
            type="checkbox"
            name="Terms_Conditions_Timestamp__c"
          />
          <span class="checkbox-material">
            <span class="check" :class="{ 'check--error': displayError }" />
          </span>
          <div class="display--inline-table">
            <p class="checkbox-label" data-qa-id="submit-term-checkbox-text">
              {{ $t('forms.signupForm.termsAndConditions.text1') }}
              <a
                :href="
                  process.env.VUE_APP_LENDICO_BASE_URL_CMS_API +
                  $t('forms.signupForm.termsAndConditions.link1Src')
                "
                target="_blank"
                >{{ $t('forms.signupForm.termsAndConditions.link1Text') }}</a
              >
              {{ $t('forms.signupForm.termsAndConditions.text2') }}
              <a
                :href="
                  process.env.VUE_APP_LENDICO_BASE_URL_CMS_API +
                  $t('forms.signupForm.termsAndConditions.link2Src')
                "
                target="_blank"
                >{{ $t('forms.signupForm.termsAndConditions.link2Text') }}</a
              >
              {{ $t('forms.signupForm.termsAndConditions.text3') }}
              <a
                :href="$t('forms.signupForm.termsAndConditions.link3Src')"
                target="_blank"
                >{{ $t('forms.signupForm.termsAndConditions.link3Text') }}</a
              >
              {{ $t('forms.signupForm.termsAndConditions.text4') }}
            </p>
          </div>
        </label>
      </div>
      <div
        role="alert"
        class="text-block text-align--left display--block error-container"
      >
        <p v-if="displayError" class="validation">
          {{
            errors.termsAndConditions &&
            $t('forms.errors.' + errors.termsAndConditions)
          }}
        </p>
      </div>
    </fieldset>
  </div>
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import { goToElement } from '@/mixins/scrollMixin';
  import { lenFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    mixins: [goToElement, lenFormFieldGTMTrackingMixin],
    computed: {
      ...mapState('config', ['VUE_APP_LENDICO_BASE_URL_CMS_API']),
      ...mapState('signupForm', ['errors', 'submitClicked']),
      label() {
        const translationRoot = 'forms.signupForm.termsAndConditions';
        const text1 = this.$t(`${translationRoot}.text1`);
        const link1Text = this.$t(`${translationRoot}.link1Text`);
        const text2 = this.$t(`${translationRoot}.text2`);
        const link2Text = this.$t(`${translationRoot}.link2Text`);
        const text3 = this.$t(`${translationRoot}.text3`);
        const link3Text = this.$t(`${translationRoot}.link3Text`);
        const text4 = this.$t(`${translationRoot}.text4`);

        return `${text1}${link1Text}${text2}${link2Text}${text3}${link3Text}${text4}`;
      },
      displayError() {
        return this.submitClicked && !!this.errors.termsAndConditions;
      },
      termsAndConditions: {
        get() {
          return this.$store.state.signupForm.termsAndConditions;
        },
        set(value) {
          this.SET_TERMS_AND_CONDITIONS(value);
          this.sendFieldTrackingEvent(
            `checkbox:${this.label}`,
            value.toString()
          );
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_TERMS_AND_CONDITIONS']),
      goTo() {
        this.goToElement('terms-and-conditions-input');
      },
    },
  };
</script>
<style scoped>
  .checkbox-label {
    font-size: 16px;
    line-height: 18px;
  }
  .display--inline-table {
    vertical-align: middle;
  }
  .error-container {
    height: 25px;
  }
  .validation {
    margin: 5px 0 0 0;
    text-align: left;
    padding-left: 30px;
  }
</style>
