<template>
  <span
    class="signers-tooltip-wrapper"
    @mouseover="isTooltipVisible = true"
    @mouseleave="isTooltipVisible = false"
  >
    <len-icon icon="info" class="signers-tooltip-info-icon" />
    <div
      :class="{ active: isTooltipVisible, [messageClass]: true }"
      class="signers-tooltip-message"
      v-html="tooltipMessage"
    />
    <div :class="{ 'signers-tooltip-arrow': isTooltipVisible }" />
  </span>
</template>

<script>
  import LenIcon from '@/components/common/len-icon';
  export default {
    components: {
      LenIcon,
    },
    props: {
      tooltipMessage: {
        type: String,
        required: true,
      },
      messageClass: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isTooltipVisible: false,
      };
    },
  };
</script>

<style scoped lang="scss">
  @import '../../sass/custom/_settings.scss';
  .signers-tooltip-wrapper {
    position: relative;
  }
  .signers-tooltip-message.active {
    visibility: visible;
  }
  .signers-tooltip-message {
    visibility: hidden;
    position: absolute;
    padding: 20px;
    font-size: 12px;
    color: $mediumGrey;
    background: white;
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  .signers-tooltip-arrow {
    position: absolute;
    height: 0;
    width: 0;
    border: 8px solid transparent;
    border-top: 8px solid white;
    left: 10px;
    top: -15px;
    z-index: 1;
  }
  .signers-tooltip-info-icon {
    margin-left: 10px;
    :deep(svg) {
      vertical-align: text-top;
      width: 16px;
      height: 16px;
    }
    display: inline-block;
  }
</style>
