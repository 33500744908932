<template>
  <div class="company-information-container">
    <modify-partner-button
      v-if="isPartner"
      @modify-partner="$emit('modify-partner')"
    />
    <div class="col-sm-12">
      <company-information-header />
    </div>
    <div ref="company-name-field" class="col-sm-12">
      <company-name-field />
    </div>
    <div class="company-info-container row">
      <div class="col-sm-6 first-column">
        <legal-status-field />
        <street-field />
        <postcode-field />
        <city-field />
      </div>
      <div class="col-sm-6 second-column">
        <revenue-field />
        <date-founded-field :isPartner="isPartner" />
        <loan-purpose-field
          @select="isBookkeepingVisible ? '' : $emit('last-field-filled')"
        />
        <bookkeeping-standard-field @select="$emit('last-field-filled')" />
      </div>
    </div>
  </div>
</template>

<script>
  import CompanyInformationHeader from './company-information/company-information-header';
  import CompanyNameField from './company-information/company-name-field';
  import LegalStatusField from './company-information/legal-status-field';
  import RevenueField from './company-information/revenue-field';
  import LoanPurposeField from './company-information/loan-purpose-field';
  import BookkeepingStandardField from './company-information/bookkeeping-standard-field';
  import StreetField from './company-information/street-field';
  import PostcodeField from './company-information/postcode-field';
  import CityField from './company-information/city-field';
  import DateFoundedField from './company-information/date-founded-field';
  import { mapGetters, mapState } from 'vuex';
  import ModifyPartnerButton from '@/components/partner/forms/modify-partner-button';

  export default {
    components: {
      CompanyInformationHeader,
      CompanyNameField,
      LegalStatusField,
      RevenueField,
      LoanPurposeField,
      BookkeepingStandardField,
      CityField,
      StreetField,
      PostcodeField,
      DateFoundedField,
      ModifyPartnerButton,
    },
    computed: {
      ...mapState('signupForm', ['legalStatus']),
      ...mapGetters('signupForm', ['isPartner', 'isBookkeepingVisible']),
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .company-information-container {
    margin-bottom: 50px;
  }

  .first-column {
    margin-top: 17px;
    padding-right: 3.5%;
  }
  .second-column {
    vertical-align: top;
    margin-top: 17px;
    padding-left: 3.5%;
  }
  @media (max-width: $desktop-s) {
    .first-column {
      margin-top: 17px;
      padding-right: 3.5%;

      fieldset:nth-child(2) {
        margin-top: 10px;
      }
    }
    .second-column {
      margin-top: 5px;
      padding-left: 3.5%;
    }
  }
  @media (max-width: $mobile-l) {
    .company-information-container {
      margin-bottom: 12px;
    }
    .first-column {
      margin-top: 0;
      padding-right: 0;

      fieldset:nth-child(2) {
        margin-top: 0;
      }
    }
    .second-column {
      margin-top: 0;
      padding-left: 0;
    }
  }
</style>
