<template>
  <div class="ing-wrapper">
    <ing-header />
    <main class="row centered-container">
      <section class="confirmation-container">
        <div class="confirmation-section">
          <img
            alt="Submit Icon"
            class="submit-icon-size"
            src="/ing_submit_icon.png"
          />
          <h1>{{ $t('signersMissingInfo.successPage.h1') }}</h1>
          <p class="confirmation-subtitle">
            {{ $t('ingSuccessPage.p') }}
          </p>
        </div>
      </section>
    </main>
    <ing-footer class="row" />
  </div>
</template>

<script>
  import $ from 'jquery/dist/jquery.slim.min';
  import Window from '@/common/window';
  import { enableGTM } from '@/mixins/gtmMixins';
  import IngHeader from '@/components/ing/ing-header';
  import IngFooter from '@/components/ing/ing-footer';
  export default {
    name: 'IngSignersSuccessPage',
    components: {
      IngHeader,
      IngFooter,
    },
    mixins: [enableGTM],
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
    computed: {
      isMobile: function () {
        return $(Window).width() < 768;
      },
    },
  };
</script>

<style scoped>
  :deep(.icon-svg svg) {
    width: 150px;
    height: 150px;
  }
  .confirmation-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .confirmation-subtitle {
    padding-top: 22px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #808080;
  }
  h1 {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #ff6200;
  }
  .centered-container {
    display: flex;
    flex-grow: 1;
    padding: 40px;
    align-items: center;
    justify-content: center;
  }
  .submit-icon-size {
    height: 43.5px;
    width: 48px;
    margin-bottom: 20px;
  }
</style>
