<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 v-show="hasControllingCompany" class="section-title">
        {{ $t('signers.companyDetails.controllingCompany.title') }}
      </h3>
      <TextInput
        v-model="companyName"
        :readonly="true"
        :label="$t('signers.companyDetails.name')"
        :error="getError('companyName')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="companyTaxNumber"
        inputSize="s"
        :label="$t('signers.companyDetails.companyTaxNumber')"
        :error="getError('companyTaxNumber')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="companyTradingName"
        :label="$t('signers.companyDetails.tradeName')"
        :error="getError('companyTradingName')"
        :displayErrorIfDirty="displayError()"
      />
      <Dropdown
        v-show="isRegularLoanRequest"
        id="countryOfIncorporationField"
        v-model="countryOfIncorporation"
        :label="$t('signers.companyDetails.countryOfIncorporation')"
        :options="countryDropdownOptions()"
        :error="getError('countryOfIncorporation')"
        :displayErrorIfSubmitted="submitClicked || companySubmitClicked"
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import {
    isRegularLoanRequest,
    hasControllingCompany,
  } from '@/mixins/signersMixin';
  import countryDropdownMixin from '@/mixins/countryDropdownMixin';
  export default {
    components: {
      FormSubSection,
      Dropdown,
      TextInput,
    },
    mixins: [isRegularLoanRequest, hasControllingCompany, countryDropdownMixin],
    computed: {
      ...mapState('signers', [
        'errors',
        'submitClicked',
        'companySubmitClicked',
        'company',
      ]),
      companyName: {
        get() {
          return this.company.companyName;
        },
        set(newValue) {
          this.SET_COMPANY_NAME(newValue);
        },
      },
      companyTaxNumber: {
        get() {
          return this.company.companyTaxNumber;
        },
        set(value) {
          this.SET_COMPANY_TAX_NUMBER(value);
        },
      },
      companyTradingName: {
        get() {
          return this.company.companyTradingName;
        },
        set(value) {
          this.SET_COMPANY_TRADING_NAME(value);
        },
      },
      countryOfIncorporation: {
        get() {
          return this.company.countryOfIncorporation;
        },
        set(value) {
          this.SET_COUNTRY_OF_INCORPORATION(value);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_COMPANY_NAME',
        'SET_COMPANY_TAX_NUMBER',
        'SET_COMPANY_TRADING_NAME',
        'SET_COUNTRY_OF_INCORPORATION',
      ]),
      getError(field) {
        return (
          this.errors.company &&
          this.errors.company[field] &&
          this.$t('forms.errors.' + this.errors.company[field])
        );
      },
      displayError() {
        return !this.submitClicked && !this.companySubmitClicked;
      },
    },
  };
</script>
