<template>
  <div class="ing-custom-select">
    <div
      ref="select"
      class="ing-custom-select__input"
      :class="{ 'ing-custom-select__input--disabled': disabled }"
      @click.stop="handleInputClick"
    >
      <input
        ref="inputHelper"
        type="text"
        :value="selectedOption.label"
        class="ing-custom-select__input__element form-control"
        :class="{ 'ing-custom-select__input__element--error': hasError }"
        data-lpignore="true"
        v-bind="$attrs"
        readonly
        v-on="$listeners"
        @focus="handleInputFocus"
        @keydown="handleInputKeydown"
      />
      <div class="ing-custom-select__input__icon">
        <Triangle :up="isOpen" />
      </div>
    </div>
    <div
      v-if="showMenu"
      class="ing-custom-select__options"
      :style="overflowStyle"
    >
      <span
        v-for="option in options"
        :key="option.value"
        class="ing-custom-select__options__item"
        :class="{
          'ing-custom-select__options__item--selected':
            selectedOption.value === option.value,
        }"
        @click.stop="handleOptionClick(option)"
      >
        {{ option.label }}
      </span>
    </div>
  </div>
</template>

<script>
  import Triangle from '@/assets/triangle';

  export default {
    name: 'CustomSelect',
    components: {
      Triangle,
    },
    inheritAttrs: false,
    props: {
      /* eslint-disable */
      value: {
        type: String | Number | Object | Boolean | null | undefined,
        default: ''
      },
      /* eslint-enable */
      options: {
        type: Array,
        required: true,
      },
      maxVisibleOptions: {
        type: String,
        default: '',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      hasError: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isOpen: false,
      };
    },
    computed: {
      selectedOption: function () {
        return (
          (this.options &&
            this.options.find((option) => option.value === this.value)) ||
          {}
        );
      },
      showMenu: function () {
        return !!(this.isOpen && this.options && this.options.length !== 0);
      },
      overflowStyle: function () {
        const visibleItems = parseInt(this.maxVisibleOptions);

        if (!Number.isNaN(visibleItems)) {
          return {
            overflowX: 'hidden',
            overflowY: 'scroll',
            maxHeight: visibleItems * 28 + 'px',
          };
        }

        return {};
      },
    },
    mounted() {
      window.addEventListener('click', this.closeMenu);
    },
    beforeDestroy() {
      window.removeEventListener('click', this.closeMenu);
    },
    methods: {
      toggle: function () {
        if (!this.disabled) {
          this.isOpen = !this.isOpen;
        }
      },
      closeMenu: function () {
        if (!this.disabled) {
          this.isOpen = false;
        }
      },
      handleOptionClick: function (option) {
        if (!this.disabled) {
          this.toggle();
          this.$emit('input', option.value);
        }
      },
      handleInputClick: function () {
        if (this.disabled) {
          return;
        }

        if (!this.isOpen) {
          this.$refs.inputHelper.focus();
        } else {
          this.closeMenu();
        }
      },
      handleInputFocus: function () {
        if (!this.disabled) {
          this.toggle();
        }
      },
      handleInputKeydown: function ({ keyCode }) {
        if (keyCode === 9) {
          this.closeMenu();
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/variables.scss';

  .ing-custom-select {
    position: relative;

    &__input {
      position: relative;

      display: flex;
      border-radius: 4px;

      &:after {
        content: '';
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: transparent;
      }

      &__icon,
      &__element {
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
        cursor: pointer;
      }

      &__icon {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 0;
        padding: 6px 12px;
        display: flex;
        align-items: center;
      }

      &__element {
        padding: 6px 36px 6px 12px;
        background: #fff;
        box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
        outline: none;
        border: solid 1px #ccc;
        width: 100%;
        font-family: inherit;

        &::selection {
          background: transparent;
        }
        &::-moz-selection {
          background: transparent;
        }

        &::placeholder {
          color: #333;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #333;
        }
        &::-ms-input-placeholder {
          color: #333;
        }

        &--error {
          border: 1px solid #ff0000;
        }
      }
    }

    &__options {
      background: white;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      border-radius: 4px;

      position: absolute;
      display: -ms-grid;
      display: grid;
      right: 0;
      left: 0;
      top: 38px;
      overflow: hidden;
      z-index: 1;

      font-size: 16px;
      font-weight: 400;
      line-height: 16px;

      &__item {
        padding: 6px 20px;
        text-align: initial;
        color: #333;

        &:hover {
          background-color: #f5f5f5;
          color: #262626;
          cursor: pointer;
        }

        &--selected {
          color: #ff6200 !important;
        }
      }
    }
  }

  /*
    * Mobile
    */
  @media screen and (max-width: $mobile-l) {
    .ing-custom-select {
      &__input {
        &__icon,
        &__element {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }
  }
</style>

<style lang="scss">
  .ing-custom-select__input--disabled {
    &:after {
      cursor: default !important;
    }

    .ing-custom-select__input__element,
    .ing-custom-select__input__icon {
      color: #9d9d9d;
      cursor: default;

      svg path {
        stroke: #9d9d9d;
      }
    }
  }
</style>
