<template>
  <div class="header-container">
    <div class="title-wrapper">
      <img
        src="/partner_icon.svg"
        width="40"
        alt="partner icon"
        class="title-icon"
      />
      <h1 class="title">
        {{ $t('partnerSubmissionPageNew.informationHeader.title') }}
      </h1>
    </div>
    <p class="explanation">
      {{ $t('partnerSubmissionPageNew.informationHeader.explanation1') }}
      <a
        :href="VUE_APP_LENDICO_BASE_URL_CMS_API + 'partner-werden'"
        class="base-link"
        target="_blank"
        >{{ VUE_APP_LENDICO_BASE_URL_CMS_API }}partner-werden</a
      >
      {{ $t('partnerSubmissionPageNew.informationHeader.explanation2') }}
    </p>
  </div>
</template>

<script>
  export default {
    computed: {
      VUE_APP_LENDICO_BASE_URL_CMS_API: () => {
        return process.env.VUE_APP_LENDICO_BASE_URL_CMS_API;
      },
    },
  };
</script>

<!-- <style scoped lang="scss">
  @import '@/variables.scss';

  .header-container {
    margin: 35px 0 53px 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  .title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
  }
  .title {
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 38px;
    letter-spacing: normal;
    color: #29bdba;
    margin-left: 12px;
    margin-top: 0;
  }
  .explanation {
    text-align: left;
    font-family: 'Source Sans Pro', Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 24px;
    letter-spacing: normal;
    color: #2e2e2e;
    width: 100%;
  }
  @media screen and (max-width: $mobile-l) {
    .title-wrapper {
      flex-direction: column;
    }
    .header-container {
      flex-direction: column;
      margin-top: 0;
      margin-bottom: 40px;
    }
    .title {
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      margin-left: 0;
    }
    .explanation {
      font-size: 16px;
      line-height: 18px;
    }
    .title-icon {
      margin-bottom: 10px;
    }
  }
</style> -->
