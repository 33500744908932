<template>
  <PageSection>
    <template #section-content>
      <TermsAgreement />
      <SubmissionButton />
    </template>
  </PageSection>
</template>

<script>
  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import SubmissionButton from '@/components/signers/FormSubmission/SubmissionButton';
  import TermsAgreement from '@/components/signers/FormSubmission/TermsAgreement';

  export default {
    components: {
      PageSection,
      SubmissionButton,
      TermsAgreement,
    },
  };
</script>
