<template>
  <div>
    <h1 class="title">
      {{ $t('offer.contractSection.contracts.title') }}
    </h1>
    <ContractBlock :key="contractType" :contractType="contractType" />
    <ContractBlock
      v-show="!!blanket_assignment.data"
      key="blanket_assignment"
      contractType="blanket_assignment"
    />
    <ContractBlock
      v-show="!!power_of_attorney.data"
      key="power_of_attorney"
      contractType="power_of_attorney"
    />
    <h2 class="identification-title">
      {{ $t('offer.contractSection.identification.title') }}
    </h2>
    <p class="content">
      {{ $t('offer.contractSection.identification.p1') }}
    </p>
    <p class="content p-last">
      {{ $t('offer.contractSection.identification.p2') }}
    </p>
    <h3 class="nextstep-title">
      {{ $t('offer.contractSection.nextSteps.title') }}
    </h3>
    <ol class="next-step-list">
      <li class="next-step-item">
        {{ $t('offer.contractSection.nextSteps.li1') }}
      </li>
      <li class="next-step-item">
        {{ $t('offer.contractSection.nextSteps.li2') }}
      </li>
      <li class="next-step-item">
        {{ $t('offer.contractSection.nextSteps.li3') }}
      </li>
      <li class="next-step-item">
        {{ $t('offer.contractSection.nextSteps.li4') }}
      </li>
    </ol>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import ContractBlock from '@/components/ingOffer/ContractBlock';
  import {
    REGULAR,
    MICRO,
    BLACKSEA_REGULAR,
    BLACKSEA_MICRO,
    BLACKSEA_WCL_REGULAR,
    BLACKSEA_WCL_MICRO,
  } from '@/constants/PRODUCT_TYPES';
  import CONTRACT_TYPES from '@/constants/CONTRACT_TYPES';
  export default {
    name: 'OfferContracts',
    components: { ContractBlock },
    computed: {
      ...mapState('offer', ['productType']),
      ...mapState('contract', ['blanket_assignment']),
      ...mapState('contract', [
        'credit',
        'micro_credit',
        'blanket_assignment',
        'power_of_attorney',
      ]),
      contractToFetch() {
        switch (this.productType) {
          case BLACKSEA_WCL_REGULAR:
            return 'getWclRegularContracts';
          case BLACKSEA_WCL_MICRO:
            return 'getWclMicroContract';
          case BLACKSEA_REGULAR:
          case REGULAR:
            return 'getIngRegularContracts';
          case BLACKSEA_MICRO:
          case MICRO:
            return 'getIngMicroContract';
          default:
            return '';
        }
      },

      contractType() {
        if (
          [REGULAR, BLACKSEA_REGULAR, BLACKSEA_WCL_REGULAR].includes(
            this.productType
          )
        ) {
          return CONTRACT_TYPES.CREDIT;
        } else {
          return CONTRACT_TYPES.MICRO_CREDIT;
        }
      },
    },
    mounted() {
      this.getContracts();
    },
    methods: {
      ...mapMutations('offer', ['SET_LOADING']),
      async getContracts() {
        this.SET_LOADING(true);
        await this.$store.dispatch(`contract/${this.contractToFetch}`);
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import 'src/sass/components/common/ing.orange.juice.scss';

  .title {
    color: $Primary-Orange;
    font-size: 36px;
    font-weight: 400;
    line-height: 48px;
    margin-bottom: 40px;
  }
  .identification-title {
    font-weight: 700;
    font-size: 28px;
    line-height: 36px;
  }
  .nextstep-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
  .content {
    line-height: 24px;
    margin-bottom: 16px;
    color: $Primary-Grey600;
  }
  .p-last {
    margin-bottom: 40px;
  }
  .next-step-list {
    list-style: decimal;
    padding-left: 16px;
  }
  .next-step-item {
    line-height: 24px;
    padding-bottom: 20px;
  }
  .next-step-item:last-child {
    padding-bottom: 0;
  }
</style>
