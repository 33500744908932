<template>
  <div class="country-field-wrapper">
    <label
      class="ing-label"
      v-html="$t('forms.signupForm.countryOfIncorporation.label')"
    />
    <div class="country-input-wrapper">
      <input
        ref="input"
        :value="country"
        name="country-of-incorporation"
        class="country-input form-control"
        type="text"
        disabled="disabled"
        data-unit-test="ing-controlling-country-of-incorporation-field"
      />
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex';
  import nationalities from '@/components/common/assets/nationalities';

  export default {
    data() {
      return {
        nationalities,
      };
    },
    computed: {
      ...mapState('signers', ['controllingCompany']),
      country() {
        return this.findCountry(this.controllingCompany.countryOfIncorporation)
          .name;
      },
    },
    methods: {
      findCountry(iso = '') {
        return (
          nationalities.find((country) => country.iso === iso.toUpperCase()) ||
          {}
        );
      },
    },
  };
</script>
