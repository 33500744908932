<template>
  <!-- Amazon client -->
  <div class="ing-wrapper">
    <ing-header />
    <main class="row centered-container">
      <section class="confirmation-container">
        <div class="confirmation-section">
          <h1>{{ $t('logoutSection.clientUI.title') }}</h1>
          <p class="confirmation-subtitle">
            {{ $t('logoutSection.clientUI.description') }}<br /><br />
            <IngButton
              type="button"
              class="outlined small"
              @click="$router.go(-1)"
              >{{ $t('logoutSection.loginButton') }}</IngButton
            ></p
          >
        </div>
      </section>
    </main>
    <ing-footer class="row" />
  </div>
</template>

<script>
  import IngHeader from '@/components/ing/ing-header';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import IngFooter from '@/components/ing/ing-footer';
  export default {
    name: 'SignersLogoutPage',
    components: {
      IngHeader,
      IngButton,
      IngFooter,
    },
    metaInfo() {
      return {
        title: this.$i18n.t('signersMissingInfo.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('signersMissingInfo.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('signersMissingInfo.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  :deep(.icon-svg svg) {
    width: 150px;
    height: 150px;
  }
  .confirmation-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .confirmation-subtitle {
    padding-top: 22px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #808080;
  }
  h1 {
    font-size: 40px;
    line-height: 40px;
    text-align: center;
    color: #ff6200;
  }
  .centered-container {
    display: flex;
    flex-grow: 1;
    padding: 40px;
    align-items: center;
    justify-content: center;
  }
  .submit-icon-size {
    height: 43.5px;
    width: 48px;
    margin-bottom: 20px;
  }
</style>
