import nationalities from './nationalities';
import allCountries from './all-countries';

const allCountriesPrefixes = nationalities.map((country) => {
  const countryPrefix = allCountries.find(
    (countyPhone) => countyPhone.iso2 === country.iso
  ).dialCode;
  country['prefix'] = countryPrefix;
  return country;
});

export default allCountriesPrefixes;
