<template>
  <div>
    <h2>
      {{ $t('borrowerJourney.borrowerContractsComponent.contractsTitle') }}
    </h2>

    <div class="icon-and-modals-wrapper">
      <img
        src="/kreditsumme.svg"
        width="140"
        alt=""
        class="len-download-icon"
      />
      <div class="contract-modals-wrapper">
        <open-contract-block
          v-show="productType === 'REGULAR'"
          key="credit"
          contractType="credit"
        />
        <open-contract-block
          v-show="productType === 'REGULAR' && blanket_assignment.data"
          key="blanket_assignment"
          contractType="blanket_assignment"
        />
        <open-contract-block
          v-show="productType === 'MICRO'"
          key="micro_credit"
          contractType="micro_credit"
        />
      </div>
    </div>
    <h2>
      {{
        $t('borrowerJourney.borrowerContractsComponent.identification.title')
      }}
    </h2>
    <p>
      {{ $t('borrowerJourney.borrowerContractsComponent.identification.p1') }}
    </p>
    <p>
      {{ $t('borrowerJourney.borrowerContractsComponent.identification.p2') }}
    </p>
    <p class="bold">
      {{ $t('borrowerJourney.borrowerContractsComponent.identification.p3') }}
    </p>
    <ul>
      <li>
        {{
          $t('borrowerJourney.borrowerContractsComponent.identification.li1')
        }}
      </li>
      <li>
        {{
          $t('borrowerJourney.borrowerContractsComponent.identification.li2')
        }}
      </li>
      <li>
        {{
          $t('borrowerJourney.borrowerContractsComponent.identification.li3')
        }}
      </li>
      <li>
        {{
          $t('borrowerJourney.borrowerContractsComponent.identification.li4')
        }}
      </li>
    </ul>
  </div>
</template>

<script>
  import openContractBlock from '@/components/borrower/blocks/open-contract-block';
  import { mapState, mapMutations } from 'vuex';
  export default {
    components: {
      openContractBlock,
    },
    computed: {
      ...mapState('offer', ['productType']),
      ...mapState('contract', ['blanket_assignment']),
      contractTypeToFetch() {
        switch (this.productType) {
          case 'REGULAR':
            return 'getIngRegularContracts';
          case 'MICRO':
            return 'getIngMicroContract';
          default:
            return '';
        }
      },
    },
    mounted() {
      this.getContracts();
    },
    methods: {
      ...mapMutations('offer', ['SET_LOADING']),
      async getContracts() {
        this.SET_LOADING(true);
        await this.$store.dispatch(`contract/${this.contractTypeToFetch}`);
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style scoped>
  p {
    padding: 20px 0;
    line-height: 1;
  }
  li:before {
    content: ' ◦ ';
    width: 12px;
    height: 12px;
    color: #2cbdb7;
    font-size: 24px;
    vertical-align: middle;
  }
  .icon-and-modals-wrapper,
  .contract-modals-wrapper {
    display: flex;
    align-items: center;
  }
  .contract-modals-wrapper {
    flex-direction: column;
  }
  .icon-and-modals-wrapper {
    padding: 0 0 40px 0;
  }
</style>
