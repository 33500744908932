<template>
  <div
    class="form-container col-lg-6 col-lg-offset-3 col-md-offset-1 col-md-11 col-sm-11"
  >
    <div class="form-section-container">
      <div class="row">
        <div class="col-lg-5 col-md-5 col-sm-11">
          <ing-ident-reference-number />
        </div>
      </div>
    </div>
    <div class="form-section-container">
      <div class="row">
        <div class="section-header">
          <h3>{{ $t('forms.signupForm.ident.personInfoHeader') }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11">
          <ing-ident-first-name />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11">
          <ing-ident-last-name />
        </div>
      </div>
    </div>
    <div class="form-section-container">
      <div class="row">
        <div class="section-header">
          <h3>{{ $t('forms.signupForm.ident.contactInfoHeader') }}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11">
          <ing-ident-phone />
        </div>
      </div>
      <div class="row">
        <div class="col-sm-11">
          <ing-ident-email />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import IngIdentReferenceNumber from '@/components/ing/ident/ing-ident-form-components/ing-ident-reference-number-field';
  import IngIdentFirstName from '@/components/ing/ident/ing-ident-form-components/ing-ident-first-name-field';
  import IngIdentLastName from '@/components/ing/ident/ing-ident-form-components/ing-ident-last-name-field';
  import IngIdentPhone from '@/components/ing/ident/ing-ident-form-components/ing-ident-phone-field';
  import IngIdentEmail from '@/components/ing/ident/ing-ident-form-components/ing-ident-email-field';

  export default {
    components: {
      IngIdentReferenceNumber,
      IngIdentFirstName,
      IngIdentLastName,
      IngIdentPhone,
      IngIdentEmail,
    },
  };
</script>

<style scoped>
  .section-header {
    margin-bottom: 10px;
  }
</style>
