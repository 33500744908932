import isObject from 'lodash/isObject';
import isString from 'lodash/isString';

export const trimPayloadData = (data) => {
  if (isObject(data)) {
    Object.keys(data).map((key) => {
      if (isObject(data[key])) {
        return trimPayloadData(data[key]);
      }
      if (isString(data[key])) {
        return (data[key] = data[key].trim());
      }
      return data[key];
    });
  }
  return data;
};
