<template>
  <div>
    <div
      :class="{ 'error-link': !isContractAvailable }"
      class="contract-link"
      @click="openModal"
    >
      {{
        $t(`borrowerJourney.borrowerContractsComponent.${contractType}Contract`)
      }}
    </div>
    <contract-modal
      :key="contractType"
      :show="isModalOpen"
      :isContractAvailable="isContractAvailable"
      :contract="getContract()"
      @close="isModalOpen = false"
    />
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import contractModal from '@/components/borrower/modals/contract-modal';
  export default {
    name: 'OpenContractBlock',
    components: {
      contractModal,
    },
    props: {
      contractType: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        isModalOpen: false,
      };
    },
    computed: {
      ...mapState('contract', [
        'credit',
        'micro_credit',
        'guarantee',
        'blanket_assignment',
      ]),
      isContractAvailable: function () {
        return (
          !this[this.contractType].getReqErrorCode &&
          this[this.contractType].contractGetReqReady
        );
      },
    },

    methods: {
      openModal() {
        if (this.isContractAvailable) {
          this.isModalOpen = true;
        }
      },
      getContract() {
        return this[this.contractType];
      },
    },
  };
</script>

<style scoped>
  .contract-link {
    direction: ltr;
    font-family: 'Source Sans Pro', sans-serif;
    color: #3cbdb9;
    font-size: 17px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }

  .contract-link:hover {
    color: #0c3f55;
    text-decoration: underline;
  }

  .error-link {
    color: grey !important;
    cursor: default !important;
  }

  .error-link:hover {
    color: grey !important;
    text-decoration: none !important;
  }

  @media (max-width: 767px) {
    .contract-link {
      font-size: 16px;
      line-height: 18px;
    }
  }
</style>
