<template>
  <div>
    <div ref="company-name-field">
      <ing-company-information-header />
    </div>
    <div class="col-sm-12">
      <ing-company-name-field />
    </div>
    <div class="ing-fields-container">
      <div class="col-sm-6 ing-column-left">
        <ing-legal-status-field />
        <ing-street-field />
        <ing-postcode-field />
        <ing-city-field />
      </div>
      <div class="col-sm-6 ing-column-right">
        <ing-revenue-field />
        <ing-date-founded-field />
        <ing-loan-purpose-field :isTermLoan="isTermLoan" />
        <ing-bookkeeping-standard-field
          :isTermLoan="isTermLoan"
          @select="isTestAndLearnEnabled ? '' : $emit('last-field-filled')"
        />
      </div>
    </div>
    <div class="col-sm-12">
      <ing-user-account-details
        v-if="showUserAccountDetails"
        @lastFieldBlurred="$emit('last-field-filled')"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import IngCompanyInformationHeader from '@/components/ing/forms/ing-company-information/ing-company-information-header';
  import IngCompanyNameField from '@/components/ing/forms/ing-company-information/ing-company-name-field';
  import IngLegalStatusField from '@/components/ing/forms/ing-company-information/ing-legal-status-field';
  import IngStreetField from '@/components/ing/forms/ing-company-information/ing-street-field';
  import IngPostcodeField from '@/components/ing/forms/ing-company-information/ing-postcode-field';
  import IngCityField from '@/components/ing/forms/ing-company-information/ing-city-field';
  import IngRevenueField from '@/components/ing/forms/ing-company-information/ing-revenue-field';
  import IngDateFoundedField from '@/components/ing/forms/ing-company-information/ing-date-founded-field';
  import IngLoanPurposeField from '@/components/ing/forms/ing-company-information/ing-loan-purpose-field';
  import IngBookkeepingStandardField from '@/components/ing/forms/ing-company-information/ing-bookkeeping-standard-field';
  import IngUserAccountDetails from '@/components/ing/forms/ing-user-account-details';

  export default {
    components: {
      IngCompanyInformationHeader,
      IngCompanyNameField,
      IngLegalStatusField,
      IngStreetField,
      IngPostcodeField,
      IngCityField,
      IngRevenueField,
      IngDateFoundedField,
      IngLoanPurposeField,
      IngBookkeepingStandardField,
      IngUserAccountDetails,
    },
    computed: {
      ...mapGetters('blacksea', ['isTermLoan', 'isCreditLineLoan']),
      isTestAndLearnEnabled: function () {
        return process.env.VUE_APP_TEST_AND_LEARN_ENABLED === 'true';
      },
      showUserAccountDetails() {
        return this.isTestAndLearnEnabled || this.isCreditLineLoan;
      },
    },
  };
</script>
