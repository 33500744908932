import { isEmpty } from '../helpers/string';
import { IsValidTitle } from '../helpers/title';

export default function ValidateTitle(title) {
  if (isEmpty(title)) {
    return { title: undefined };
  }
  if (!IsValidTitle(title)) {
    return { title: 'TitleIsNotCorrect' };
  }
  return { title: undefined };
}
