import { LINE_OF_CREDIT } from '@/constants/PRODUCT_CODES';

export const successMixin = {
  props: {
    smeSignUpToThankYouPage: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCreditLineProduct() {
      return this.smeSignUpToThankYouPage.productCode === LINE_OF_CREDIT;
    },
    productKey() {
      return this.isCreditLineProduct ? 'wcl' : 'termLoan';
    },
  },
};
