<template>
  <div>
    <div
      class="display--inline-block grid__item col-sm-12 text-block"
      data-qa-id="borrower-privacy-policy-div"
    >
      <p class="policy-text" data-qa-id="borrower-privacy-policy-text">
        {{ $t('borrowerSignupForm.privacyPolicy.p1') }}
        <a href="#" @click.prevent="openModal('CreditreformModal')">{{
          $t('borrowerSignupForm.privacyPolicy.cta1Text')
        }}</a>
        .<br />
        {{ $t('borrowerSignupForm.privacyPolicy.p2') }}
        <a href="#" @click.prevent="openModal('CreditreformModal')">{{
          $t('borrowerSignupForm.privacyPolicy.cta1Text')
        }}</a>
        {{ $t('borrowerSignupForm.privacyPolicy.p3') }}
        <a href="#" @click.prevent="openModal('SchufaModal')">{{
          $t('borrowerSignupForm.privacyPolicy.cta2Text')
        }}</a>
        {{ $t('borrowerSignupForm.privacyPolicy.p4') }}
      </p>
    </div>
    <Modal v-if="isSchufaModalOpen" @onClose="closeModal('SchufaModal')">
      <div class="modal-inner-content">
        <h2 class="modal-inner-header">
          {{ $t('borrowerSignupForm.privacyPolicy.schufaModalContent.title') }}
        </h2>
        <div
          v-html="
            $t('borrowerSignupForm.privacyPolicy.schufaModalContent.content')
          "
        />
      </div>
    </Modal>
    <Modal
      v-if="isCreditreformModalOpen"
      @onClose="closeModal('CreditreformModal')"
    >
      <div class="modal-inner-content">
        <h2 class="modal-inner-header">
          {{
            $t(
              'borrowerSignupForm.privacyPolicy.creditreFormModalContent.title'
            )
          }}
        </h2>
        <div
          v-html="
            $t(
              'borrowerSignupForm.privacyPolicy.creditreFormModalContent.content'
            )
          "
        />
      </div>
    </Modal>
  </div>
</template>

<script>
  import Modal from '@/components/common/modal';

  export default {
    components: { Modal },
    data() {
      return {
        isSchufaModalOpen: false,
        isCreditreformModalOpen: false,
      };
    },
    methods: {
      openModal(target) {
        this[`is${target}Open`] = true;
      },
      closeModal(target) {
        this[`is${target}Open`] = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .policy-text {
    font-size: 18px;
    line-height: 24px;
    margin-top: 40px;
    margin-bottom: 0;
  }

  @media only screen and (max-width: $mobile-l) {
    .policy-text {
      margin-top: 30px;
      font-size: 16px;
      line-height: 18px;
    }
  }

  .modal-inner-content {
    font-size: 16px;
    line-height: 20px;
    color: #333;
    letter-spacing: -0.02em;
    padding-bottom: 50px;
  }
  .modal-inner-header {
    text-transform: none;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 24px;
  }
</style>
