<template>
  <div class="identification-issuing-authority-wrapper">
    <ing-text-input-tooltip
      v-model="identificationIssuingAuthority"
      :label="$t('forms.signupForm.identificationIssuingAuthority.label')"
      :error="error"
      :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
      name="identification-issuing-authority"
      :tooltipMessage="
        $t('forms.signupForm.identificationIssuingAuthority.tooltip')
      "
    />
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInputTooltip from '@/components/ing/ing-text-input-tooltip';
  export default {
    components: { IngTextInputTooltip },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      identificationIssuingAuthority: {
        get() {
          return this.signers[this.signerId].identificationIssuingAuthority;
        },
        set(value) {
          this.SET_IDENTIFICATION_ISSUING_AUTHORITY({
            signerId: this.signerId,
            identificationIssuingAuthority: value,
          });
        },
      },
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].identificationIssuingAuthority &&
          this.$t(
            'forms.errors.' +
              this.errors[this.signerId].identificationIssuingAuthority
          )
        );
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENTIFICATION_ISSUING_AUTHORITY']),
    },
  };
</script>

<style scoped lang="scss">
  .identification-issuing-authority-wrapper {
    position: relative;
  }
</style>
