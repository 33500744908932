<template>
  <div class="ing-legal-status">
    <ing-dropdown-input
      :value="legalStatus"
      name="LegalStatus__c"
      :placeholder="$t('ingDropdownDefault')"
      :options="legalStatusOptions"
      maxVisibleOptions="5"
      :label="label"
      :error="errorMessage"
      :displayErrorIfSubmitted="displayError"
      data-qa-id="legal-form"
      @input="handleChange"
    />
    <div v-if="notSupported" class="ing-legal-status__not-supported">
      {{ $t('forms.signupForm.legalStatus.warningMessage') }}
    </div>
  </div>
</template>

<script>
  import IngDropdownInput from '@/components/ing/ing-dropdown-input';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';
  import legalStatusInputMixin from '@/mixins/legalStatusInputMixin';

  export default {
    components: { IngDropdownInput },
    mixins: [ingFormFieldGTMTrackingMixin, legalStatusInputMixin],
  };
</script>

<style lang="scss">
  .ing-legal-status {
    position: relative;

    &__not-supported {
      position: absolute;
      left: 0;
      top: 68px;
      font-size: 12px;
      line-height: 12px;
      color: #333;
      padding: 2px 0 2px 20px;
      background: url('~@/assets/img/ing_info_icon.svg') 0 0 no-repeat;
    }
  }
</style>
