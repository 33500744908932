<template>
  <form
    id="partnerForm"
    method="post"
    accept-charset="utf-8"
    class="partner-form"
    @submit.prevent="onSubmit"
  >
    <h4 class="partner-form__title">
      {{ $t('becomePartnerForm.form.header.main') }}
      <span
        class="subHeader"
        v-html="$t('becomePartnerForm.form.header.subHeader')"
      />
    </h4>

    <div class="cell">
      <len-text-input
        v-model="partner.company"
        :error="errors.company && $t('forms.errors.' + errors.company)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.company.label')"
        :name="$t('becomePartnerForm.form.company.label')"
      />
    </div>
    <div class="cell">
      <len-dropdown-input
        v-model="partner.gender"
        :options="genderOptions"
        :label="$t('becomePartnerForm.form.gender.label')"
        :name="$t('becomePartnerForm.form.gender.label')"
      />
    </div>
    <div class="cell">
      <len-text-input
        v-model="partner.firstName"
        :error="errors.firstName && $t('forms.errors.' + errors.firstName)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.firstName.label')"
        :name="$t('becomePartnerForm.form.firstName.label')"
      />
    </div>
    <div class="cell">
      <len-text-input
        v-model="partner.lastName"
        :error="errors.lastName && $t('forms.errors.' + errors.lastName)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.lastName.label')"
        :name="$t('becomePartnerForm.form.lastName.label')"
      />
    </div>
    <div class="cell">
      <len-text-input
        v-model="partner.email"
        :error="errors.email && $t('forms.errors.' + errors.email)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.email.label')"
        :name="$t('becomePartnerForm.form.email.label')"
        inputmode="email"
      />
    </div>
    <div class="cell">
      <len-text-input
        v-model="partner.phone"
        :error="errors.phone && $t('forms.errors.' + errors.phone)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.phone.label')"
        :name="$t('becomePartnerForm.form.phone.label')"
      />
    </div>
    <div class="cell">
      <len-text-input
        v-model="partner.postcodeDe"
        :error="errors.postcodeDe && $t('forms.errors.' + errors.postcodeDe)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.postcodeDe.label')"
        :name="$t('becomePartnerForm.form.postcodeDe.label')"
      />
    </div>
    <div class="cell">
      <len-text-input
        v-model="partner.city"
        :error="errors.city && $t('forms.errors.' + errors.city)"
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.city.label')"
        :name="$t('becomePartnerForm.form.city.label')"
      />
    </div>
    <div class="cell full-width">
      <len-textarea-input
        v-model="partner.message"
        :label="$t('becomePartnerForm.form.message.label')"
        :name="$t('becomePartnerForm.form.message.label')"
      />
    </div>
    <div class="cell full-width">
      <len-checkbox
        v-model="partner.privacyPolicy"
        :error="
          errors.privacyPolicy && $t('forms.errors.' + errors.privacyPolicy)
        "
        :displayErrorIfDirty="!submitClicked"
        :label="$t('becomePartnerForm.form.privacyPolicy.label')"
        class="checkbox"
      />
    </div>
    <button type="submit" class="button submit-button">
      {{ $t('becomePartnerForm.form.submit.label') }}
    </button>

    <transition name="fade">
      <div v-if="formSuccessfullySent" class="success-message">
        <div class="success-message-content">
          <div class="title">
            {{ $t('becomePartnerForm.form.success.title') }}
          </div>
          <div
            class="text"
            v-html="$t('becomePartnerForm.form.success.text')"
          />
        </div>
      </div>
    </transition>
  </form>
</template>

<script>
  import LenTextInput from '@/components/common/len-text-input';
  import LenDropdownInput from '@/components/common/len-dropdown-input';
  import LenTextareaInput from '@/components/common/len-textarea-input';
  import LenCheckbox from '@/components/common/len-checkbox';
  import { becomePartnerFormValidator } from '@/validators';
  import findKey from 'lodash/findKey';
  import { enableGTM } from '@/mixins/gtmMixins';
  import { postPartnerApplication } from '@/webservice/partner';

  export default {
    name: 'BecomePartnerForm',
    components: {
      LenTextInput,
      LenDropdownInput,
      LenTextareaInput,
      LenCheckbox,
    },
    mixins: [enableGTM],
    data() {
      return {
        partner: {
          company: '',
          gender: '',
          firstName: '',
          lastName: '',
          postcodeDe: '',
          city: '',
          email: '',
          phone: '',
          message: '',
          privacyPolicy: false,
        },
        genderOptions: [
          {
            value: 'm',
            label: this.$t('becomePartnerForm.form.gender.dropdown.m'),
          },
          {
            value: 'f',
            label: this.$t('becomePartnerForm.form.gender.dropdown.f'),
          },
        ],
        formSuccessfullySent: false,
        submitClicked: false,
        errors: {},
      };
    },
    watch: {
      partner: {
        deep: true,
        handler() {
          this.validateForm();
        },
      },
    },
    mounted() {
      this.validateForm();
    },
    methods: {
      async onSubmit() {
        this.submitClicked = true;
        if (this.formDataIsValid()) {
          try {
            await postPartnerApplication(this.getPayload());
            this.formSuccessfullySent = true;
            this.sendTrackingEvent();
          } catch (error) {
            this.errors.email = error.message;
          }
        }
      },
      validateForm() {
        this.errors = becomePartnerFormValidator(this.partner);
      },
      formDataIsValid() {
        return !findKey(this.errors);
      },
      sendTrackingEvent() {
        this.$gtm &&
          this.$gtm.trackEvent({
            event: 'GTMevent',
            eventCategory: 'application-form',
            eventAction: 'become-partner',
            eventLabel: '',
          });
      },
      getPayload() {
        const {
          company,
          phone,
          message,
          postcodeDe,
          ...restOfTheFieldsToSend
        } = this.partner;

        return {
          ...restOfTheFieldsToSend,
          companyName: company,
          phoneNumber: phone,
          comment: message,
          postalCode: postcodeDe,
        };
      },
    },
  };
</script>

<style>
  body {
    background: transparent;
  }
</style>

<style lang="scss" scoped>
  .partner-form {
    position: relative;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-content: flex-start;
    max-width: 635px;
    background: #ffffff;
    border: 1px solid #f5f5f5;
    box-sizing: border-box;
    box-shadow: 0 2px 4px rgba(46, 46, 46, 0.1);
    border-radius: 4px;
    padding: 40px;
    -webkit-font-smoothing: antialiased;
    margin: 0 auto;

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      flex: 0 0 100%;
      margin-bottom: 50px;

      .subHeader {
        display: block;
        margin-top: 4px;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
      }
    }

    .sup {
      position: relative;
      top: -0.1em;
    }

    .cell {
      flex: 0 0 47%;

      &.full-width {
        flex: 0 0 100%;
      }
    }

    .checkbox {
      :deep(.link) {
        text-decoration: underline;
        color: #9d9d9d;
        transition: none;

        &:hover {
          text-decoration: none;

          /* smartphones, touchscreens */
          @media (hover: none) and (pointer: coarse) {
            text-decoration: underline;
          }
        }
      }
    }

    .submit-button {
      margin: 40px 0 0 auto;
      padding: 11px 24px;
    }

    .success-message {
      background: rgba(0, 0, 0, 0.7);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;

      .success-message-content {
        padding: 60px 32px;
        background: #fff;
        width: 360px;
        height: 208px;
        border-radius: 4px;
        text-align: center;

        .title {
          font-weight: 600;
          font-size: 32px;
          line-height: 32px;
          color: #29bdba;
          margin-bottom: 20px;
        }

        .text {
          font-size: 16px;
          line-height: 18px;
          color: #575757;
        }
      }
    }
  }

  @media (max-width: 568px) {
    .partner-form {
      flex-flow: column wrap;
      padding: 25px 16px;

      h4 {
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
        flex: 0 0 auto;

        .subHeader {
          font-size: 12px;
          line-height: 12px;
        }
      }
      .cell {
        flex: 0 0 auto;

        &.full-width {
          flex: 0 0 auto;
        }
      }

      .submit-button {
        margin: 40px auto 0 auto;
      }

      .success-message {
        .success-message-content {
          padding: 30px 20px;
          width: 260px;
          height: 130px;

          .title {
            font-size: 18px;
            line-height: 18px;
          }

          .text {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
</style>
