<template>
  <div class="error-wrapper" data-qa-id="submit-form-button-div">
    <FieldStatus
      v-if="errorMessage"
      :error="errorMessage"
      class="general-error-container"
    />
    <IngButton
      type="submit"
      data-qa-id="submit"
      wtlink="firmenkredit_anfragen_antrag.content.link_intern"
    >
      {{ submitButtonLabel }}
    </IngButton>
  </div>
</template>
<script>
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import { mapGetters, mapState } from 'vuex';

  export default {
    name: 'IngSubmitForm',
    components: {
      IngButton,
      FieldStatus,
    },
    computed: {
      ...mapState('appSettings', ['channel']),
      ...mapState('signupForm', ['errors', 'submitClicked']),
      ...mapGetters('signupForm', ['isValid', 'isPartner']),
      ...mapGetters('blacksea', ['isCreditLineLoan']),
      errorMessage() {
        const { status, code } = this.errors.server || {};
        if (
          code === 'bic_not_passed_validation' ||
          code === 'iban_not_passed_validation' ||
          code === 'bic_or_iban_server_check_error' ||
          code === 'bic_or_iban_not_passed_validation'
        ) {
          return this.$t('forms.signupForm.errors.ibanOrBic');
        }

        if (code === 'invalid_email_error') {
          return this.$t('forms.signupForm.errors.email');
        }

        if (status) {
          if (this.isPartner && status === 404) {
            return this.$t('forms.signupForm.errors.404');
          }
          if (status >= 400 && status < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (status >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        if (this.submitClicked && !this.isValid) {
          return this.$t('forms.signupForm.errors.fields');
        }
        return '';
      },
      submitButtonLabel() {
        if (this.channel == 'amazon' && this.isCreditLineLoan == true) {
          return this.$t(
            this.channel + '.application.form.termsSection.submitButton.label'
          );
        }
        return this.$t('formFields.submitButton.label');
      },
    },
  };
</script>

<style scoped lang="scss">
  .error-wrapper {
    font-size: 16px;
    line-height: 24px;
    .general-error-container {
      margin-block: 0 40px;
    }
  }
</style>
