<template>
  <ing-text-input
    v-model="postalZip"
    :name="'postal-zip-' + signerId"
    :label="$t('forms.signupForm.postcodeDe.label')"
    :error="error"
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    inputmode="numeric"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import IngTextInput from '@/components/ing/ing-text-input';

  export default {
    components: { IngTextInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      postalZip: {
        get() {
          return this.signers[this.signerId].postalZip;
        },
        set(value) {
          this.SET_POSTAL_ZIP({ signerId: this.signerId, postalZip: value });
        },
      },
      error() {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId].postalZip &&
          this.$t('forms.errors.' + this.errors[this.signerId].postalZip)
        );
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_POSTAL_ZIP']),
    },
  };
</script>
