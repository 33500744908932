<template>
  <FormSubSection>
    <template #sub-section-content>
      <FieldStatus v-if="errorMessage !== ''" :error="errorMessage" />
      <div class="submit-buttons">
        <IngButton
          type="submit"
          class="outlined"
          @click="$emit('cancel-button-clicked')"
        >
          {{ $t('signers.form.buttons.cancel') }}
        </IngButton>
        <IngButton type="submit" class="button-secondary submit-button">
          {{ $t('signers.form.buttons.save') }}
        </IngButton>
      </div>
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState } from 'vuex';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import { mapErrorMessage } from '@/mixins/signersMixin';
  export default {
    components: {
      IngButton,
      FieldStatus,
      FormSubSection,
    },
    mixins: [mapErrorMessage],
    computed: {
      ...mapState('signers', ['signers', 'errors']),
      errorMessage() {
        const serverError = this.errors.clientServer;
        if (serverError) {
          const hasErrorMessage = serverError.data && serverError.data.message;
          if (hasErrorMessage && serverError.data.statusCode === 500) {
            return this.mapErrorMessage(serverError.data);
          }
          if (serverError.status >= 400 && serverError.status < 500) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError.status >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';

  .submit-buttons {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    margin-bottom: 2rem;

    @include mq-size(m) {
      flex-direction: row;
      justify-content: flex-end;
    }
  }
</style>
