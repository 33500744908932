<template>
  <header class="main-header" :style="mainHeaderBackground">
    <div class="len-navbar" data-qa-id="len-navbar">
      <a
        :href="process.env.VUE_APP_LENDICO_BASE_URL_CMS_API"
        class="len-navbar__logo"
      >
        <img
          src="/logo_rgb.svg"
          :alt="$t('logoTitle')"
          class="len-navbar__logo__img"
        />
        <h1 class="len-navbar__logo__text">
          {{ $t('logoTitle') }}
        </h1></a
      >
    </div>
    <!-- /* Submenu */ -->
    <section class="dark-section">
      <slot name="subMenu" />
    </section>
    <!-- /* Submenu */ -->

    <slot name="headerCopy" />
    <div v-if="loanCalculator" class="loan-calculator">
      <lendico-loan-calculator type="new" />
    </div>
    <slot name="loanCalcPlaceholder" />
    <slot name="section" />
  </header>
</template>

<script>
  export default {
    props: {
      background: {
        type: String,
        default: '',
      },
      loanCalculator: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      mainHeaderBackground() {
        return {
          'background-image': `url('${process.env.VUE_APP_LENDICO_BASE_URL_CONTENT}/de/images/photos/lendico_antrag_hero.jpg')`,
        };
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';

  .main-header {
    background-position: 64% 60px;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .len-navbar {
    display: flex;
    justify-content: space-between;
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    height: 72px;
    padding: 21px 30px;
    box-sizing: border-box;
    background: #fff;
    box-shadow: 0 2px 8px rgba(46, 46, 46, 0.1);
    z-index: 101;

    &__logo {
      display: flex;
      line-height: 1;

      &__img {
        height: 30px;
      }
      &__text {
        font-size: 0;
        visibility: hidden;
      }
    }
  }

  .loan-calculator {
    margin: 30px 0 100px 0;
  }

  @media screen and (max-width: $desktop-s) {
    .main-header {
      background-position: 40% 60px;
    }
    .loan-calculator {
      margin: 28px 60px 40px 60px;
    }
  }

  @media screen and (max-width: $mobile-l) {
    .main-header {
      background-position: 62% 35px;
    }
    .len-navbar {
      height: 48px;
      padding: 14px 15px;

      &__logo {
        &__img {
          height: 20px;
        }
      }
    }

    .loan-calculator {
      margin: 29px 15px 40px 15px;
    }
  }
</style>
