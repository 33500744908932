<template>
  <!-- Direct Offer -->
  <div class="ing-wrapper signer-form">
    <TheHeader />
    <div class="logout-page-container">
      <div class="col-sm-12 col-md-10 col-lg-8">
        <h1 class="title">{{ $t('logoutSection.offerUI.title') }}</h1>
        <p class="sub-title"
          >{{ $t('logoutSection.offerUI.description') }}<br /><br />
          <IngButton
            type="button"
            class="button-secondary small"
            @click="$router.go(-1)"
            >{{ $t('logoutSection.loginButton') }}</IngButton
          >
        </p>
      </div>
    </div>
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import IngButton from '@/components/ingOrangeJuice/Base/Button';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  export default {
    name: 'IngOfferUILogoutPage',
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerJourney.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerJourney.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerJourney.metaDescription'),
          },
        ],
      };
    },
    components: {
      TheHeader,
      IngButton,
      TheFooter,
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .logout-page-container {
    min-height: calc(100vh - 150px);
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 840px) {
    .logout-page-container {
      min-height: calc(100vh - 240px);
    }
  }

  .title {
    font-weight: 400;
    font-size: 36px;
    line-height: 48px;
    padding: 8rem 2rem 0rem 2rem;
    color: $Primary-Orange;
  }

  .sub-title {
    font-weight: 400;
    font-size: 19px;
    line-height: 28px;
    padding: 2rem;
    color: $Primary-Grey600;
  }
</style>
