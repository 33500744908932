<template>
  <div class="ing-terms-container">
    <div
      data-qa-id="submit-terms-checkbox"
      class="ing-checkbox-container"
      :class="{
        'ing-checkbox-container--focus': isFocused,
      }"
    >
      <label class="label-checkbox" for="ing-terms">
        <input
          id="ing-terms"
          v-model="termsAndConditions"
          type="checkbox"
          name="Terms_Conditions_Timestamp__c"
          data-qa-id="consent"
          @focus="isFocused = true"
          @blur="isFocused = false"
        />
        <span class="check" :class="{ 'check--error': displayError }" />
        <p
          id="ingTermsLabel"
          class="label-text ing-label"
          v-html="$t('ingSubmission.ingTermsAndConditions')"
        />
      </label>
    </div>
    <div :class="{ active: displayError }" role="alert" class="ing-error">
      <img src="/alert_icon.png" alt="Alert Icon" />
      <p>
        {{
          errors.termsAndConditions &&
          $t('forms.errors.' + errors.termsAndConditions)
        }}
      </p>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { goToElement } from '@/mixins/scrollMixin';
  import { ingFormFieldGTMTrackingMixin } from '@/mixins/formFieldGTMTrackingMixin';

  export default {
    mixins: [goToElement, ingFormFieldGTMTrackingMixin],
    data() {
      return {
        isFocused: false,
      };
    },
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      displayError() {
        return this.submitClicked && !!this.errors.termsAndConditions;
      },

      termsAndConditions: {
        get() {
          return this.$store.state.signupForm.termsAndConditions;
        },
        set(value) {
          this.SET_TERMS_AND_CONDITIONS(value);
          this.sendTrackingEvent();
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_TERMS_AND_CONDITIONS']),
      goTo() {
        this.goToElement('terms-and-conditions-input');
      },
      sendTrackingEvent() {
        const label = document.getElementById('ingTermsLabel').innerText;
        const value = this.$store.state.signupForm.termsAndConditions;
        this.sendFieldTrackingEvent(`checkbox:${label}`, value.toString());
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/variables.scss';
  @import '@/sass/components/common/ing.orange.juice';
  .ing-checkbox-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    &--focus {
      .label-checkbox .check {
        border: 1px solid $Primary-Orange;
      }
    }

    label.label-checkbox {
      font-weight: normal;
      font-size: 16px;
    }
  }
  .ing-terms-container {
    margin-top: 40px;

    @media screen and (max-width: $mobile-l) {
      margin-top: 20px;
    }
  }
</style>
