<template>
  <div
    :class="{
      dark: theme == 'dark',
      image: theme == 'image' || theme == 'dark-overlay',
      'text-align--center': align == 'center',
      'text-align--left': align == 'left',
      'text-align--right': align == 'right',
      m: medium == 'true',
      sm: small == 'true',
      'icon-svg': iconSvg == 'true',
    }"
    :style="{
      marginTop: (!!marginTop ? marginTop : marginVertical) + 'px',
      marginBottom: (!!marginBottom ? marginBottom : marginVertical) + 'px',
    }"
  >
    <tick-icon v-if="icon === 'tick'" />

    <thumbs-up-icon v-if="icon === 'thumbs-up'" />

    <price-tag-icon v-if="icon === 'price-tag'" />

    <percentage-icon v-if="icon === 'percentage'" />

    <message-icon v-if="icon === 'message'" />

    <lock-icon v-if="icon === 'lock'" />

    <info-icon v-if="icon === 'info'" />

    <group-icon v-if="icon === 'group'" />

    <factory-icon v-if="icon === 'factory'" />

    <old-factory-icon v-if="icon === 'old-factory-icon'" />

    <graph-icon v-if="icon === 'graph'" />

    <avatar-icon v-if="icon === 'avatar'" />

    <ident-card-icon v-if="icon === 'ident'" />

    <handshake1-icon v-if="icon === 'handshake1'" />

    <handshake-icon v-if="icon === 'handshake'" />

    <edit-pencil-icon v-if="icon === 'edit-pencil'" />
  </div>
</template>
<script>
  import TickIcon from '@/components/common/components/tick-icon';
  import ThumbsUpIcon from '@/components/common/components/thumbs-up-icon';
  import PriceTagIcon from '@/components/common/components/price-tag-icon';
  import PercentageIcon from '@/components/common/components/percentage-icon';
  import MessageIcon from '@/components/common/components/message-icon';
  import LockIcon from '@/components/common/components/lock-icon';
  import InfoIcon from '@/components/common/components/info-icon';
  import GroupIcon from '@/components/common/components/group-icon';
  import FactoryIcon from '@/components/common/components/factory-icon';
  import OldFactoryIcon from '@/components/common/components/old-factory-icon';
  import GraphIcon from '@/components/common/components/graph-icon';
  import AvatarIcon from '@/components/common/components/avatar-icon';
  import Handshake1Icon from '@/components/common/components/handshake1-icon';
  import HandshakeIcon from '@/components/common/components/handshake-icon';
  import IdentCardIcon from '@/components/common/components/ident-card-icon';
  import EditPencilIcon from '@/components/common/components/edit-pencil-icon';
  export default {
    components: {
      TickIcon,
      ThumbsUpIcon,
      PriceTagIcon,
      PercentageIcon,
      MessageIcon,
      LockIcon,
      InfoIcon,
      GroupIcon,
      FactoryIcon,
      OldFactoryIcon,
      GraphIcon,
      AvatarIcon,
      Handshake1Icon,
      HandshakeIcon,
      IdentCardIcon,
      EditPencilIcon,
    },
    props: {
      theme: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      medium: {
        type: String,
        default: '',
      },
      small: {
        type: String,
        default: '',
      },
      align: {
        type: String,
        default: '',
      },
      marginVertical: {
        type: String,
        default: '',
      },
      marginTop: {
        type: String,
        default: '',
      },
      marginBottom: {
        type: String,
        default: '',
      },
      iconSvg: {
        type: String,
        default: 'true',
      },
    },
  };
</script>
