import { initialState } from './index';
import { partnerInformationValidator } from '@/validators';

const allPersonPartnerFieldsError = {
  partnerFirstName: 'PartnerFirstNameIsNotCorrect',
  partnerLastName: 'PartnerLastNameIsNotCorrect',
  partnerEmail: 'PartnerEmailIsNotCorrect',
};

function requestErrorHandler(state) {
  const exceptionType = state.requestError.body.exceptionType;
  const notMatchedFields = state.requestError.body.notMatchedFields;
  const notMatchedPersonPartnerFields = {};
  switch (exceptionType) {
    case 'COMPANY_NOT_FOUND':
      Object.assign(state.validationErrors, allPersonPartnerFieldsError, {
        partnerCompanyName: 'PartnerCompanyNameIsNotCorrect',
      });
      break;
    case 'PERSON_NOT_FOUND':
      Object.assign(state.validationErrors, allPersonPartnerFieldsError);
      break;
    case 'PERSON_NOT_MATCH':
      notMatchedFields.forEach(
        (fieldName) =>
          (notMatchedPersonPartnerFields[fieldName] =
            allPersonPartnerFieldsError[fieldName])
      );
      Object.assign(state.validationErrors, notMatchedPersonPartnerFields);
  }
}

export default {
  SET_PARTNER_COMPANY_NAME: (state, data) => {
    state.partnerCompanyName = data;
    state.validationErrors = partnerInformationValidator(state);
  },
  SET_PARTNER_EMAIL: (state, data) => {
    state.partnerEmail = data;
    state.validationErrors = partnerInformationValidator(state);
  },
  SET_PARTNER_FIRST_NAME: (state, data) => {
    state.partnerFirstName = data;
    state.validationErrors = partnerInformationValidator(state);
  },
  SET_PARTNER_LAST_NAME: (state, data) => {
    state.partnerLastName = data;
    state.validationErrors = partnerInformationValidator(state);
  },
  SET_PARTNER_ACCOUNT_ID: (state, data) => {
    state.partnerAccountId = data;
  },
  SET_PARTNER_CONTACT_ID: (state, data) => {
    state.partnerContactId = data;
  },
  SET_PARTNER_ACCEPTED: (state, data) => {
    state.partnerAccepted = data;
  },
  SET_SUBMIT_CLICKED: (state, submitClicked) => {
    state.submitClicked = submitClicked;
  },
  SET_LOADING: (state, loading) => {
    state.loading = loading;
  },
  SET_REQUEST_ERROR: (state, data) => {
    state.requestError = data;
    requestErrorHandler(state);
  },
  CLEAR_REQUEST_ERROR: (state) => {
    state.requestError = {};
  },
  CLEAR_ALL: (state) => {
    Object.assign(state, initialState());
  },
};
