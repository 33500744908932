import * as actions from './actions';
import * as getters from './getters';
import { mutations } from './mutations';

const state = {
  returnUrl: null,
  blackSeaRedirectURLQueryString: null,
  offerStatus: null,
  error: null,
  offer: null,
};

export default { state, actions, getters, mutations, namespaced: true };
