import { isEmpty, isNotNumeric, lengthIsOutOfRange } from '../helpers/string';

export default function ValidateTaxNumber(taxNumber) {
  if (isEmpty(taxNumber)) {
    return { taxNumber: 'TaxNumberIsRequired' };
  }
  if (isNotNumeric(taxNumber)) {
    return { taxNumber: 'TaxNumberIsNAN' };
  }
  if (lengthIsOutOfRange(taxNumber, 11, 11)) {
    return { taxNumber: 'PersonTaxNumberIncorrectLength' };
  }
  return { taxNumber: undefined };
}
