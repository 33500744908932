import { isEmpty, isNotNumeric, lengthIsOutOfRange } from '../helpers/string';

export default function ValidateControllingCompanyTaxNumber(
  controllingCompanyTaxNumber
) {
  if (isEmpty(controllingCompanyTaxNumber)) {
    return {
      controllingCompanyTaxNumber: 'CompanyTaxNumberIsRequired',
    };
  }
  if (isNotNumeric(controllingCompanyTaxNumber)) {
    return { controllingCompanyTaxNumber: 'CompanyTaxNumberIsNAN' };
  }
  if (lengthIsOutOfRange(controllingCompanyTaxNumber, 10, 13)) {
    return {
      controllingCompanyTaxNumber: 'CompanyTaxNumberIsIncorrect',
    };
  }
  return { controllingCompanyTaxNumber: undefined };
}
