<template>
  <div class="addMaterialDesign">
    <div class="checkbox">
      <label>
        <input v-model="homeAddressIsPostalAddress" type="checkbox" />
        <span class="checkbox-material">
          <span class="check" />
        </span>
        <p class="label-text">
          {{ $t('forms.signupForm.postalAddress.checkbox.client') }}
        </p>
      </label>
    </div>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  export default {
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['signers']),
      homeAddressIsPostalAddress: {
        get() {
          return this.signers[this.signerId].homeAddressIsPostalAddress;
        },
        set(newValue) {
          this.SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS({
            signerId: this.signerId,
            homeAddressIsPostalAddress: newValue,
          });
          this.handleAddressChange(newValue);
        },
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_CURRENT_ADDRESS_IS_POSTAL_ADDRESS',
        'SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS',
        'CLEAR_CLIENT_POSTAL_ADDRESS',
      ]),
      handleAddressChange(newAddressvalue) {
        if (newAddressvalue) {
          this.SET_CLIENT_POSTAL_ADDRESS_TO_CURRENT_ADDRESS(this.signerId);
        } else {
          this.CLEAR_CLIENT_POSTAL_ADDRESS(this.signerId);
        }
      },
    },
  };
</script>

<style scoped>
  .label-text {
    line-height: 22px;
  }
  .checkbox .checkbox-material .check {
    z-index: -1;
  }
</style>
