/**
 * Object uses as wrapper for window.
 */
let Window = {
  isFake: true,
  addEventListener: function () {},
  removeEventListener: function () {},
  navigator: {
    userAgent: '',
  },
};

if (typeof window !== 'undefined') {
  Window = window;
}

export default Window;
