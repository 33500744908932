<template>
  <div class="ing-wrapper">
    <TheHeader />
    <IngAmazonSignupForm />
    <TheContact container="amazon" />
    <TheFooter />
  </div>
</template>

<script>
  import { mapState, mapGetters } from 'vuex';
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheContact from '@/components/ingOrangeJuice/Structure/TheContact';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngAmazonSignupForm from '@/components/amazon/IngAmazonSignupForm';

  export default {
    name: 'IngAmazonSignupPage',
    components: {
      TheHeader,
      TheContact,
      TheFooter,
      IngAmazonSignupForm,
    },
    computed: {
      ...mapState('appSettings', ['channel']),
      ...mapGetters('blacksea', ['productKey']),
      metaTitle() {
        return `${this.channel}.meta.${this.productKey}.title`;
      },
      metaDescription() {
        return `${this.channel}.meta.${this.productKey}.description`;
      },
    },
    metaInfo() {
      return {
        title: this.$t(this.metaTitle),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$t(this.metaTitle),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$t(this.metaDescription),
          },
        ],
      };
    },
  };
</script>
