const cyrillic = /[\u0400-\u04FF]/;
const arabic = /[\u0600-\u06FF]/;
const cjk =
  /[\u2E80-\u2FD5\u3190-\u319f\u3400-\u4DBF\u4E00-\u9FCC\uF900-\uFAAD]/;

// The purpose of this validator is to exclude some common alphabets used by applicants that create trouble for the Sales team.
// It is (obviously) not a test for latin characters only
export const hasForbiddenCharacters = (str) => {
  return cyrillic.test(str) || arabic.test(str) || cjk.test(str);
};
