import axios from 'axios';

export async function searchPartner({ state, commit }) {
  const searchParams = {
    partnerCompanyName: state.partnerCompanyName,
    partnerEmail: state.partnerEmail,
    partnerFirstName: state.partnerFirstName,
    partnerLastName: state.partnerLastName,
  };
  const queryString = new URLSearchParams(searchParams).toString();
  await axios
    .get(
      `${process.env.VUE_APP_FUNNEL_GATEWAY_API}/partner/search?${queryString}`
    )
    .then(async ({ data }) => {
      if (data) {
        await commit('SET_PARTNER_ACCOUNT_ID', data.partnerAccountId);
        await commit('SET_PARTNER_CONTACT_ID', data.partnerContactId);
        await commit('SET_PARTNER_ACCEPTED', data.accepted);
      }
      return data;
    })
    .catch((error) => {
      let errorBody;
      let errorStatus;
      if (error.response) {
        errorStatus = error.response.status;
        errorBody = error.response.data;
      } else {
        errorStatus = 500;
        errorBody = error.message || 'Something went wrong';
      }
      commit('SET_REQUEST_ERROR', { status: errorStatus, body: errorBody });
      commit('SET_PARTNER_ACCEPTED', null);
    });
}
