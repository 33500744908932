<template>
  <div class="ing-wrapper">
    <TheHeader />
    <IngBorrowerSignupForm />
    <TheContact container="bridge" />
    <TheFooter />
  </div>
</template>

<script>
  import TheHeader from '@/components/ingOrangeJuice/Structure/TheHeader';
  import TheContact from '@/components/ingOrangeJuice/Structure/TheContact';
  import TheFooter from '@/components/ingOrangeJuice/Structure/TheFooter';
  import IngBorrowerSignupForm from '@/components/borrower/forms/IngBorrowerSignupForm';

  export default {
    name: 'IngBorrowerSignupPage',
    components: {
      TheHeader,
      TheContact,
      TheFooter,
      IngBorrowerSignupForm,
    },
    metaInfo() {
      return {
        title: this.$t('signers.meta.title'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$t('signers.meta.title'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$t('signers.meta.description'),
          },
        ],
      };
    },
  };
</script>
