<template>
  <fieldset :class="{ error: displayError }">
    <div
      :class="{
        'is-focused': isFocused,
        'is-empty': !phone,
      }"
    >
      <label class="ing-label">
        {{ $t('forms.signupForm.phone.label') }}
      </label>
      <div
        ref="inputDiv"
        :class="{
          'ing-input-error': displayError,
          'ing-input-focus': isFocused || open,
        }"
        class="form-control input-container"
      >
        <div
          v-click-outside="clickedOutside"
          :class="{ open: open, 'change-country-disabled': !canChangeCountry }"
          class="dropdown"
          tabindex="0"
          @click="toggleDropdown"
          @keydown="keyboardNav"
          @keydown.esc="reset"
        >
          <span class="selection">
            <div
              v-if="enabledFlags"
              :class="activeCountry && activeCountry.iso.toLowerCase()"
              class="iti-flag"
            />
            <span v-if="canChangeCountry" class="dropdown-arrow">
              {{ open ? '▲' : '▼' }}
            </span>
          </span>
          <ul v-show="open" ref="list" :style="dropdownStyles">
            <li
              v-for="(pb, index) in sortedCountries"
              :key="pb.iso + (pb.preferred ? '-preferred' : '')"
              :class="getItemClass(index, pb.iso)"
              class="dropdown-item"
              @click="choose(pb)"
              @mousemove="selectedIndex = index"
            >
              <div
                v-if="enabledFlags"
                :class="pb.iso.toLowerCase()"
                class="iti-flag"
              />
              <strong>{{ pb.name }} </strong>
              <span>&#8203;</span>
              <span>+{{ pb.prefix }}</span>
            </li>
          </ul>
        </div>
        <input
          v-model="phone"
          inputmode="numeric"
          :name="'mobile-phone-' + signerId"
          @focus="onFocus"
          @blur="onBlur"
        />
      </div>
      <div :class="{ active: displayError }" role="alert" class="ing-error">
        <img src="/alert_icon.png" alt="Alert Icon" />
        <p>
          {{
            errors[signerId].mobilePhone &&
            $t('forms.errors.' + errors[signerId].mobilePhone)
          }}
        </p>
      </div>
    </div>
  </fieldset>
</template>

<script>
  import { parsePhoneNumber } from 'libphonenumber-js';
  import Vue from 'vue';
  import allCountriesPrefixes from '@/components/common/assets/all-countries-phone-prefix';
  import getCountry from '@/components/common/assets/default-country';
  import metadata from 'libphonenumber-js/metadata.full.json';
  import { mapState, mapMutations } from 'vuex';
  import { getNumberWithoutLeadingZeros } from '@/validators/helpers/phone';

  export default {
    props: {
      signerId: {
        type: String,
        default: '',
      },
      placeholder: {
        type: String,
        default: '',
      },
      disabledFetchingCountry: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      preferredCountries: {
        type: Array,
        default: () => [],
      },
      invalidMsg: {
        type: String,
        default: '',
      },
      required: {
        type: Boolean,
        default: false,
      },
      defaultCountry: {
        type: String,
        default: '',
      },
      enabledFlags: {
        type: Boolean,
        default: true,
      },
      canChangeCountry: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        allCountriesPrefixes,
        unformattedNumber: null,
        activeInput: false,
        activeCountry: { iso: '' },
        open: false,
        dropdownStyles: {},
        selectedIndex: null,
        typeToFindInput: '',
        typeToFindTimer: null,
        isFocused: false,
        isDirty: false,
      };
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      submitWasClicked() {
        return this.submitClicked || this.signers[this.signerId].submitClicked;
      },
      displayError() {
        const hasErrorAndIsNotFocused =
          this.errors[this.signerId].mobilePhone && !this.isFocused;
        if (!this.submitWasClicked) {
          return this.isDirty && hasErrorAndIsNotFocused;
        }
        return hasErrorAndIsNotFocused;
      },
      phone: {
        get() {
          if (this.activeInput) {
            return this.removePrefix(this.signers[this.signerId].mobilePhone);
          } else {
            return this.signers[this.signerId].mobilePhone;
          }
        },
        set(value) {
          this.unformattedNumber = value;
        },
      },
      sortedCountries() {
        // Sort the list countries: from preferred countries to all countries
        const preferredCountries = this.preferredCountries
          .map((country) => this.findCountry(country))
          .filter(Boolean)
          .map((country) => ({ ...country, preferred: true }));

        return [...preferredCountries, ...this.allCountriesPrefixes];
      },
    },
    watch: {
      activeInput(value) {
        if (!value && this.unformattedNumber) {
          this.SET_MOBILE_PHONE({
            signerId: this.signerId,
            mobilePhone: this.formatResult(),
          });
        } else if (!value && !this.unformattedNumber) {
          this.SET_MOBILE_PHONE({
            signerId: this.signerId,
            mobilePhone: this.unformattedNumber,
          });
        }
      },
    },
    mounted() {
      this.initializeCountry();
      this.setDropdownWidth();
    },
    destroyed() {
      window.removeEventListener('resize', this.setDropdownWidth);
    },
    created() {
      window.addEventListener('resize', this.setDropdownWidth);
    },
    methods: {
      ...mapMutations('signers', ['SET_MOBILE_PHONE']),
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;
        this.activeInput = true;
      },
      onBlur() {
        setTimeout(() => {
          this.isFocused = false;
          this.activeInput = false;
        }, 200); // On some browsers (Chrome macOS) it's impossible to click on suggestions without this
      },
      removePrefix(phone) {
        if (!this.activeCountry) {
          return null;
        }
        if (phone && phone[0] === '+') {
          const stringToRemove = '+' + this.activeCountry.prefix;
          return phone.replace(stringToRemove, '');
        } else {
          return phone;
        }
      },
      reset() {
        this.selectedIndex = this.sortedCountries
          .map((c) => c.iso)
          .indexOf(this.activeCountry.iso);
        this.open = false;
      },
      processNumberWithPrefix(numberWithPrefix) {
        const phoneNumber = parsePhoneNumber(numberWithPrefix, metadata);
        if (phoneNumber && phoneNumber.country) {
          this.activeCountry = this.findCountry(phoneNumber.country);
          this.unformattedNumber = this.removePrefix(numberWithPrefix);
        } else {
          this.unformattedNumber = numberWithPrefix;
        }
      },
      formatResult() {
        // Calculate phone number based on mode
        if (!this.allCountriesPrefixes || !this.unformattedNumber) {
          return '';
        }

        if (this.unformattedNumber.charAt(0) === '+') {
          this.processNumberWithPrefix(this.unformattedNumber);
        }
        if (this.activeCountry.iso === 'DE') {
          this.unformattedNumber = getNumberWithoutLeadingZeros(
            this.unformattedNumber
          );
        }
        return '+' + this.activeCountry.prefix + this.unformattedNumber;
      },
      initializeCountry() {
        const phoneFromLRM = this.signers[this.signerId].mobilePhone;
        if (phoneFromLRM) {
          this.processNumberWithPrefix(phoneFromLRM);
          return;
        }
        /**
         * 1. Use default country if passed from parent
         */
        if (this.defaultCountry) {
          const defaultCountry = this.findCountry(this.defaultCountry);
          if (defaultCountry) {
            this.activeCountry = defaultCountry;
            return;
          }
        }
        /**
         * 2. Use the first country from preferred list (if available) or all countries list
         */
        this.activeCountry =
          this.findCountry(this.preferredCountries[0]) ||
          this.allCountriesPrefixes[0];
        /**
         * 3. Check if fetching country based on user's IP is allowed, set it as the default country
         */
        if (!this.disabledFetchingCountry) {
          getCountry().then((res) => {
            this.activeCountry = this.findCountry(res) || this.activeCountry;
          });
        }
      },
      findCountry(iso = '') {
        return this.allCountriesPrefixes.find(
          (country) => country.iso === iso.toUpperCase()
        );
      },
      getItemClass(index, iso) {
        const highlighted = this.selectedIndex === index;
        const lastPreferred = index === this.preferredCountries.length - 1;
        const preferred = !!~this.preferredCountries
          .map((c) => c.toUpperCase())
          .indexOf(iso);
        return {
          highlighted,
          'last-preferred': lastPreferred,
          preferred,
        };
      },
      choose(country) {
        this.activeCountry = country;
        this.SET_MOBILE_PHONE({
          signerId: this.signerId,
          mobilePhone: this.formatResult(),
        });
      },
      toggleDropdown() {
        if (this.disabled || !this.canChangeCountry) {
          return;
        }
        this.open = !this.open;
      },
      clickedOutside() {
        this.open = false;
      },
      downArrowKeyPress() {
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = 0;
        } else {
          this.selectedIndex = Math.min(
            this.sortedCountries.length - 1,
            this.selectedIndex + 1
          );
        }
        let selEle = this.$refs.list.children[this.selectedIndex];
        if (
          selEle.offsetTop + selEle.clientHeight >
          this.$refs.list.scrollTop + this.$refs.list.clientHeight
        ) {
          this.$refs.list.scrollTop =
            selEle.offsetTop -
            this.$refs.list.clientHeight +
            selEle.clientHeight;
        }
      },
      upArrowKeyPress() {
        this.open = true;
        if (this.selectedIndex === null) {
          this.selectedIndex = this.sortedCountries.length - 1;
        } else {
          this.selectedIndex = Math.max(0, this.selectedIndex - 1);
        }
        let selEle = this.$refs.list.children[this.selectedIndex];
        if (selEle.offsetTop < this.$refs.list.scrollTop) {
          this.$refs.list.scrollTop = selEle.offsetTop;
        }
      },
      enterKeyPress() {
        if (this.selectedIndex !== null) {
          this.choose(this.sortedCountries[this.selectedIndex]);
        }
        this.open = !this.open;
      },
      searchCountry(e) {
        this.typeToFindInput += e.key;
        clearTimeout(this.typeToFindTimer);
        this.typeToFindTimer = setTimeout(() => {
          this.typeToFindInput = '';
        }, 700);
        // don't include preferred countries so we jump to the right place in the alphabet
        let typedCountryI = this.sortedCountries
          .slice(this.preferredCountries.length)
          .findIndex((c) =>
            c.name.toLowerCase().startsWith(this.typeToFindInput)
          );
        if (~typedCountryI) {
          this.selectedIndex = this.preferredCountries.length + typedCountryI;
          let selEle = this.$refs.list.children[this.selectedIndex];
          if (
            selEle.offsetTop < this.$refs.list.scrollTop ||
            selEle.offsetTop + selEle.clientHeight >
              this.$refs.list.scrollTop + this.$refs.list.clientHeight
          ) {
            this.$refs.list.scrollTop =
              selEle.offsetTop - this.$refs.list.clientHeight / 2;
          }
        }
      },
      keyboardNav(e) {
        const downArrowKeyCode = 40;
        const upArrowKeyCode = 38;
        const enterKeyCode = 13;
        switch (e.keyCode) {
          case downArrowKeyCode:
            this.downArrowKeyPress();
            break;
          case upArrowKeyCode:
            this.upArrowKeyPress();
            break;
          case enterKeyCode:
            this.enterKeyPress();
            break;
          default:
            this.searchCountry(e);
        }
      },
      setDropdownWidth() {
        let inputWidth = this.$refs.inputDiv.clientWidth + 'px';
        Vue.set(this.dropdownStyles, 'width', inputWidth);
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../../sass/custom/variables';
  .input-container {
    display: flex;
    align-items: center;
  }
  .input-container input,
  .input-container div {
    font-size: 16px;
  }
  .input-container input {
    border: none;
    border-radius: 0 var(--border-radius) var(--border-radius) 0;
    width: 100%;
    outline: none;
    padding-left: 5px;
  }
  .input-container input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #b5b5b5;
    opacity: 1; /* Firefox */
  }
  .input-container input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #b5b5b5;
  }
  .input-container input::-ms-input-placeholder {
    /* Microsoft Edge */
    color: #b5b5b5;
  }

  :local {
    --border-radius: 2px;
  }

  li.last-preferred {
    border-bottom: 1px solid #cacaca;
  }
  .iti-flag {
    margin-right: 5px;
    margin-left: 5px;
  }
  .dropdown-item .iti-flag {
    display: inline-block;
  }
  .selection {
    font-size: 0.8em;
    display: flex;
    align-items: center;
  }
  ul {
    padding: 0;
    margin: 0;
    text-align: left;
    list-style: none;
    max-height: 200px;
    overflow-y: scroll;
    position: absolute;
    top: 26px;
    left: -12px;
    background-color: #fff;
    border: 1px solid $ing-orange;
    z-index: 1;
  }
  .dropdown {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: relative;
    margin-right: 10px;
    cursor: pointer;
  }
  .dropdown.open {
    background-color: #f3f3f3;
  }
  .dropdown:hover {
    background-color: #f3f3f3;
  }
  .change-country-disabled.dropdown {
    background-color: white;
    cursor: auto;
  }
  .dropdown-arrow {
    transform: scaleY(0.5);
    display: inline-block;
    color: #666;
  }
  .dropdown-item {
    cursor: pointer;
    padding: 4px 0;
    display: flex;
    align-items: center;
  }
  .dropdown-item.highlighted {
    background-color: #f3f3f3;
  }
</style>
