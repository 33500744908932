<template>
  <form
    name="clientInfo"
    autocomplete="off"
    @submit.prevent="$emit('submit-button-clicked')"
  >
    <div class="client-form-container">
      <PersonalDetailsSection :signerId="signerId" />
      <ContactDetailsSection :signerId="signerId" />
      <RegistrationAddressSection :signerId="signerId" />
      <MailingAddressSection :signerId="signerId" />
      <IndentityDetailsSection :signerId="signerId" />
      <SubmitClientInfoSection
        :signerId="signerId"
        @cancel-button-clicked="$emit('cancel-button-clicked')"
        @submit-button-clicked="$emit('submit-button-clicked')"
      />
    </div>
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
  </form>
</template>

<script>
  import { mapState } from 'vuex';
  import { mapErrorMessage } from '@/mixins/signersMixin';
  import ERROR_CODES from '@/constants/ERROR_CODES';
  import PersonalDetailsSection from '@/components/signers/ClientInformation/ClientInformationForm/PersonalDetailsSection';
  import ContactDetailsSection from '@/components/signers/ClientInformation/ClientInformationForm/ContactDetailsSection';
  import RegistrationAddressSection from '@/components/signers/ClientInformation/ClientInformationForm/RegistrationAddressSection';
  import MailingAddressSection from '@/components/signers/ClientInformation/ClientInformationForm/MailingAddressSection';
  import IndentityDetailsSection from '@/components/signers/ClientInformation/ClientInformationForm/IndentityDetailsSection';
  import SubmitClientInfoSection from '@/components/signers/ClientInformation/ClientInformationForm/SubmitClientInfoSection';
  export default {
    components: {
      PersonalDetailsSection,
      ContactDetailsSection,
      MailingAddressSection,
      RegistrationAddressSection,
      IndentityDetailsSection,
      SubmitClientInfoSection,
    },
    mixins: [mapErrorMessage],
    props: {
      signerId: {
        type: String,
        default: '',
      },
      isInvalid: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ...mapState('signers', ['signers', 'errors']),
      errorMessage() {
        const serverError = this.errors.clientServer;
        if (serverError) {
          const hasErrorMessage = serverError.data && serverError.data.message;
          if (
            hasErrorMessage &&
            serverError.data.statusCode === ERROR_CODES.INTERNAL_SERVER_ERROR
          ) {
            return this.mapErrorMessage(serverError.data);
          }
          if (
            serverError.status >= ERROR_CODES.BAD_REQUEST &&
            serverError.status < ERROR_CODES.INTERNAL_SERVER_ERROR
          ) {
            return this.$t('forms.signupForm.errors.400');
          }
          if (serverError.status >= ERROR_CODES.INTERNAL_SERVER_ERROR) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
  };
</script>
