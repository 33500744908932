<template>
  <div class="len-body-wrapper">
    <len-header-big-logo />

    <div class="success-logo-wrapper">
      <img
        src="/ICON_signingsuccessful.svg"
        width="200"
        alt=""
        class="success-logo"
      />
    </div>

    <div class="success-text-wrapper">
      <!-- <div style="font-family: 'Source Sans Pro', sans-serif; color: #3cbdb9; font-size: 68px; font-weight: 500; text-align: justify;">Success!</div> -->
      <div
        class="success-text"
        v-html="$t('borrowerSuccessPage.successText')"
      />

      <div
        class="success-text"
        v-html="$t('borrowerSuccessPage.contactText')"
      />
    </div>

    <len-footer class="len-footer-custom" />
  </div>
</template>

<script>
  import { enableGTM } from '@/mixins/gtmMixins';
  export default {
    mixins: [enableGTM],
    metaInfo() {
      return {
        title: this.$i18n.t('borrowerSuccessPage.metaTitle'),
        meta: [
          {
            vmid: 'title',
            name: 'title',
            content: this.$i18n.t('borrowerSuccessPage.metaTitle'),
          },
          {
            vmid: 'description',
            name: 'description',
            content: this.$i18n.t('borrowerSuccessPage.metaDescription'),
          },
        ],
      };
    },
  };
</script>

<style scoped>
  .len-body-wrapper {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
  }

  .len-footer-custom {
    bottom: 0;
    width: 100%;
  }

  .success-logo-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
  }

  .success-text-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 50px 100px;
    padding-bottom: 300px !important;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .success-text {
    font-family: 'Source Sans Pro', sans-serif;
    color: #9d9d9d;
    font-size: 24px;
    margin-bottom: 28px;
    line-height: 28px;
    font-weight: 500;
    width: 100%;
    text-align: center;
  }

  .success-logo {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  @media (max-width: 767px) {
    .success-text-wrapper {
      padding-top: 0px;
    }
    .success-text {
      margin-top: 0px;
      font-size: 22px;
      line-height: 24px;
    }
  }

  @media (max-width: 479px) {
    .success-text-wrapper {
      padding: 0px 50px;
    }
    .success-text {
      margin-top: 0px;
      font-size: 20px;
      line-height: 24px;
    }
  }
</style>
