<template>
  <div class="terms-and-conditions-container">
    <i18n
      tag="p"
      :path="channel + '.application.form.termsSection.para1.description'"
    >
      <template #linkText>
        <a
          :href="$translationConstantValues.dataProtectionUrl"
          target="_blank"
          >{{
            $t(channel + '.application.form.termsSection.para1.linkText')
          }}</a
        >
      </template>
      <template #boldText>
        <em>{{
          $t(
            channel +
              '.application.form.termsSection.para1.' +
              productKey +
              '.boldText'
          )
        }}</em>
      </template>
    </i18n>
    <i18n
      :path="channel + '.application.form.termsSection.para2.description'"
      tag="p"
    >
      <template #boldText>
        <b>
          {{
            $t(
              channel +
                '.application.form.termsSection.para2.' +
                productKey +
                '.boldText'
            )
          }}
        </b>
      </template>
    </i18n>
    <i18n
      :path="channel + '.application.form.termsSection.para3.description'"
      tag="p"
    >
      <template #linkText1>
        <a href="#" @click.prevent="openModal('CreditreformModal')">{{
          $t(channel + '.application.form.termsSection.para3.linkText1')
        }}</a>
      </template>
      <template #linkText2>
        <a href="#" @click.prevent="openModal('SchufaModal')">{{
          $t(channel + '.application.form.termsSection.para3.linkText2')
        }}</a>
      </template>
    </i18n>

    <CreditReformModal v-if="isCreditreformModalOpen" @onClose="closeModal" />
    <SchufaModal v-if="isSchufaModalOpen" @onClose="closeModal" />
  </div>
</template>

<script>
  import CreditReformModal from '@/components/CreditReformModal';
  import SchufaModal from '@/components/SchufaModal';
  import { mapMutations, mapState, mapGetters } from 'vuex';

  export default {
    name: 'IngBorrowerPrivacyPolicy',
    components: { SchufaModal, CreditReformModal },
    data() {
      return {
        isSchufaModalOpen: false,
        isCreditreformModalOpen: false,
      };
    },
    computed: {
      ...mapState('signupForm', ['errors', 'submitClicked']),
      ...mapState('appSettings', ['channel']),
      ...mapGetters('blacksea', ['productKey']),
      termsAndConditions: {
        get() {
          return this.$store.state.signupForm.termsAndConditions;
        },
        set(value) {
          this.SET_TERMS_AND_CONDITIONS(value);
        },
      },
    },
    methods: {
      ...mapMutations('signupForm', ['SET_TERMS_AND_CONDITIONS']),
      getError(field) {
        return (
          this.errors[field] && this.$t('forms.errors.' + this.errors[field])
        );
      },
      openModal(target) {
        this[`is${target}Open`] = true;
      },
      closeModal(target) {
        this[`is${target}Open`] = false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .terms-and-conditions-container {
    padding-top: 24px;
    & p ~ p {
      padding-top: 24px;
    }
  }
</style>
