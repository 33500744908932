<template>
  <div>
    <div class="flex--between options-container">
      <div
        class="option-box automation-box"
        data-unit-test="ing-ident-automation-option"
      >
        <span class="display--flex text-padding">
          <len-radio
            id="AUTOMATION"
            key="AUTOMATION"
            v-model="identOption"
            :label="
              $t(
                'signersMissingInfo.formPage.identificationTypeSection.form.option1a'
              )
            "
            :value="identityValues.AUTOMATION"
            name="identType"
            :hasError="displayError"
          />
        </span>
        <p class="radio-sub-text option-description automation-description">
          {{
            $t(
              'signersMissingInfo.formPage.identificationTypeSection.form.option1b'
            )
          }}
        </p>
        <ing-ident-type-warning
          v-if="identOption === identityValues.AUTOMATION"
        />
      </div>
      <div class="option-box" data-unit-test="ing-ident-manual-option">
        <span class="display--flex text-padding">
          <len-radio
            id="MANUAL"
            key="MANUAL"
            v-model="identOption"
            :label="
              $t(
                'signersMissingInfo.formPage.identificationTypeSection.form.option2a'
              )
            "
            :value="identityValues.MANUAL"
            name="identType"
            :hasError="displayError"
          />
        </span>
        <p class="radio-sub-text option-description">
          {{
            $t(
              'signersMissingInfo.formPage.identificationTypeSection.form.option2b'
            )
          }}
        </p>
        <ing-ident-type-warning v-if="identOption === identityValues.MANUAL" />
      </div>
    </div>
    <span v-if="displayError" class="validation validation-text">
      {{ $t('forms.errors.' + errors.identificationProcess) }}
    </span>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import LenRadio from '@/components/common/len-radio';
  import { isPartnerChannel } from '@/mixins/signersMixin';
  import IDENTITY_VALUES from '@/constants/IDENT_VALUES';
  import IngIdentTypeWarning from '@/components/ing/signers/ing-ident-type-information/ing-ident-type-warning';
  export default {
    components: {
      LenRadio,
      IngIdentTypeWarning,
    },
    mixins: [isPartnerChannel],
    data() {
      return {
        identityValues: IDENTITY_VALUES,
      };
    },
    computed: {
      ...mapState('signers', [
        'errors',
        'identificationProcess',
        'submitClicked',
      ]),
      identOption: {
        get() {
          return this.identificationProcess;
        },
        set(value) {
          this.SET_IDENT_PROCESS(value);
        },
      },
      contactEmail() {
        if (this.isPartnerChannel) {
          return this.$t('partnerContactEmail');
        } else {
          return this.$t('companiesEmail');
        }
      },
      displayError() {
        return this.submitClicked && !!this.errors.identificationProcess;
      },
    },
    methods: {
      ...mapMutations('signers', ['SET_IDENT_PROCESS']),
    },
  };
</script>

<style scoped lang="scss">
  @import '../../../../sass/custom/variables';
  p {
    margin-top: 20px;
  }
  .flex--between {
    display: flex;
    justify-content: space-between;
  }
  .flex--column {
    display: flex;
    flex-direction: column;
  }
  .display--flex {
    display: flex;
  }
  :deep(.icon-svg svg) {
    width: 24px;
    height: 24px;
  }
  .automation-box {
    margin-right: 40px;
  }
  .option-box {
    font-size: 14px;
    width: 560px;
    border: solid 1px #e3e3e3;
    padding-top: 20px;
  }
  .option-description {
    color: $ing-gray;
  }
  .option-2-warning {
    display: flex;
    align-items: center;
    height: 54px;
    border-top: solid 1px #e3e3e3;
    background-color: #f6f6f6;
    margin-top: 20px;
  }
  .option-2-text-wrapper {
    padding-left: 10px;
    line-height: 0.5;
  }
  p.option-2-text {
    display: inline;
  }
  .option-2-text {
    font-size: 12px;
    color: #2e2e2e;
    margin: 0;
    line-height: normal;
  }
  .mail-contact {
    color: #3cbdb9;
  }
  .text-padding {
    padding-left: 20px;
    padding-right: 20px;
  }
  .radio-sub-text {
    padding-left: 55px;
    padding-right: 20px;
  }

  .validation-text {
    font-size: 14px;
    margin: 2% 5%;
    display: block;
  }
  @media (max-width: 767px) {
    .options-container {
      flex-direction: column;
    }
    .option-box {
      width: 100%;
      margin: 2% 0% 0% 0%;
    }
  }
  @media (max-width: 358px) {
    .option-2-text {
      font-size: 10px;
    }
  }
</style>
