import { isEmpty } from '@/validators/helpers/string';
import { hasEmailFormat } from '@/validators/helpers/email';

export default function ValidatePartnerEmail(partnerEmail) {
  if (isEmpty(partnerEmail)) {
    return { partnerEmail: 'PartnerEmailIsRequired' };
  }
  if (!hasEmailFormat(partnerEmail)) {
    return { partnerEmail: 'PartnerEmailIsNotCorrect' };
  }

  return { partnerEmail: undefined };
}
