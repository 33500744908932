<template>
  <div class="accept-offer-container">
    <p>{{ $t('borrowerJourney.borrowerOfferComponent.resolveOffer.label') }}</p>
    <button class="u-mv button" type="submit" @click="handleAcceptOffer()">
      {{ $t('borrowerJourney.borrowerOfferComponent.resolveOffer.button') }}
    </button>
    <div
      v-if="errorMessage !== ''"
      role="alert"
      class="general-error-container"
    >
      <p class="validation" v-html="errorMessage" />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations } from 'vuex';
  export default {
    computed: {
      ...mapState('offer', ['error', 'success']),
      ...mapState('authentication', ['id']),
      errorMessage() {
        const serverError = this.error.status;
        if (serverError) {
          if (serverError === 401) {
            return '';
          } else if (serverError >= 400 && serverError < 500) {
            return this.$t('forms.signupForm.errors.400');
          } else if (serverError >= 500) {
            return this.$t('forms.signupForm.errors.500');
          }
          return this.$t('forms.signupForm.errors.500');
        }
        return '';
      },
    },
    methods: {
      ...mapActions('offer', ['acceptOffer', 'getOfferDetails']),
      ...mapMutations('offer', ['SET_LOADING', 'SET_CURRENT_STEP']),
      async handleAcceptOffer() {
        this.SET_LOADING(true);
        await this.acceptOffer();
        if (!Object.keys(this.error).length) {
          await this.getOfferDetails();
          this.SET_CURRENT_STEP(2);
        }
        this.SET_LOADING(false);
      },
    },
  };
</script>

<style scoped>
  .accept-offer-container {
    margin-top: 60px;
  }
</style>
