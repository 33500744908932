<template>
  <FormSubSection>
    <template #sub-section-content>
      <h3 class="section-title">
        {{ $t('signers.clientDetails.client.proofOfIdentity') }}
      </h3>
      <RadioGroup
        v-model="identificationIdType"
        :radioOptions="identityOptions"
        :rowList="true"
        inputSize="xs"
        :title="$t('signers.clientDetails.client.identificationDocument')"
        :error="getError('identificationIdType')"
        :displayErrorIfSubmitted="
          submitClicked || signers[signerId].submitClicked
        "
      />
      <p
        v-if="identificationIdType === IDENTITY_TYPES.PASSPORT"
        class="passport-hint"
      >
        <IconWarning class="warning-icon" />
        {{ $t('signers.clientDetails.client.identityHint') }}
        <a :href="`mailto:${$translationConstantValues.businessEmail}`">
          {{ $translationConstantValues.businessEmail }}
        </a>
      </p>
      <TextInput
        v-model="identificationIdNumber"
        :label="$t('signers.clientDetails.client.identDocumentNumber')"
        :error="getError('identificationIdNumber')"
        :displayErrorIfDirty="displayError()"
      />
      <TextInput
        v-model="identificationIssuingAuthority"
        :label="$t('signers.clientDetails.client.authority')"
        :error="getError('identificationIssuingAuthority')"
        :displayErrorIfDirty="displayError()"
      />
      <Dropdown
        v-model="identificationPlaceOfIssue"
        :label="$t('signers.clientDetails.client.placeOfIssue')"
        :options="countryDropdownOptions()"
        :error="getError('identificationPlaceOfIssue')"
        :displayErrorIfSubmitted="displayError()"
      />
      <DateInput
        v-model="identificationIssuingDate"
        :label="$t('signers.clientDetails.client.dateOfIssueInput')"
        data-qa-id="`identificationIssuingDate-${signerId}`"
        :error="getError('identificationIssuingDate')"
        :displayErrorIfDirty="!displayError()"
      />
      <DateInput
        v-model="identificationExpiryDate"
        :label="$t('signers.clientDetails.client.dateOfExpiryInput')"
        data-qa-id="`identificationExpiryDate-${signerId}`"
        :error="getError('identificationExpiryDate')"
        :displayErrorIfDirty="!displayError()"
      />
      <h3 class="section-title">
        {{ $t('signers.companyDetails.taxNumber') }}
      </h3>
      <TextInput
        v-model="taxNumber"
        :label="$t('signers.clientDetails.client.personTaxId')"
        :error="getError('taxNumber') || duplicatedTaxNumberMessage"
        inputSize="s"
        :displayErrorIfDirty="displayError()"
      />
    </template>
  </FormSubSection>
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import IDENTITY_TYPES from '@/constants/IDENTITY_TYPES';
  import { isPartnerChannel } from '@/mixins/signersMixin';
  import RadioGroup from '@/components/ingOrangeJuice/Base/RadioGroup';
  import TextInput from '@/components/ingOrangeJuice/Base/TextInput';
  import Dropdown from '@/components/ingOrangeJuice/Base/Dropdown';
  import DateInput from '@/components/ingOrangeJuice/Base/DateInput';
  import FormSubSection from '@/components/ingOrangeJuice/Structure/FormSubSection';
  import IconWarning from '@/assets/icon-warning';
  import countryDropdownMixin from '@/mixins/countryDropdownMixin';
  import dropdownOptionsMixin from '@/mixins/dropdownOptionsMixin';
  export default {
    components: {
      RadioGroup,
      TextInput,
      Dropdown,
      DateInput,
      IconWarning,
      FormSubSection,
    },
    mixins: [isPartnerChannel, countryDropdownMixin, dropdownOptionsMixin],
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        IDENTITY_TYPES,
      };
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
      ...mapGetters('signers', [
        'areSignersTaxNumberUnique',
        'personTaxNumberEqualsCompanyTaxNumber',
      ]),
      contactEmail() {
        if (this.isPartnerChannel) {
          return this.$t('partnerContactEmail');
        } else {
          return this.$t('companiesEmail');
        }
      },
      identificationIdType: {
        get() {
          return this.signers[this.signerId].identificationIdType;
        },
        set(value) {
          this.SET_IDENTIFICATION_ID_TYPE({
            signerId: this.signerId,
            identificationIdType: value,
          });
        },
      },
      identificationIdNumber: {
        get() {
          return this.signers[this.signerId].identificationIdNumber;
        },
        set(value) {
          this.SET_IDENTIFICATION_ID_NUMBER({
            signerId: this.signerId,
            identificationIdNumber: value,
          });
        },
      },
      identificationIssuingAuthority: {
        get() {
          return this.signers[this.signerId].identificationIssuingAuthority;
        },
        set(value) {
          this.SET_IDENTIFICATION_ISSUING_AUTHORITY({
            signerId: this.signerId,
            identificationIssuingAuthority: value,
          });
        },
      },
      identificationPlaceOfIssue: {
        get() {
          return this.signers[this.signerId].identificationPlaceOfIssue;
        },
        set(value) {
          this.SET_IDENTIFICATION_PLACE_OF_ISSUE({
            signerId: this.signerId,
            identificationPlaceOfIssue: value,
          });
        },
      },
      identificationIssuingDate: {
        get() {
          return this.$options.filters.momentjsFormat(
            this.signers[this.signerId].identificationIssuingDate,
            'L',
            'YYYY-MM-DDTHH:mm:ss'
          );
        },
        set(value) {
          this.SET_IDENTIFICATION_ISSUING_DATE({
            signerId: this.signerId,
            identificationIssuingDate: this.$options.filters.momentjsFormat(
              value,
              'YYYY-MM-DDTHH:mm:ss',
              'L'
            ),
          });
        },
      },
      identificationExpiryDate: {
        get() {
          return this.$options.filters.momentjsFormat(
            this.signers[this.signerId].identificationExpiryDate,
            'L',
            'YYYY-MM-DDTHH:mm:ss'
          );
        },
        set(value) {
          this.SET_IDENTIFICATION_EXPIRY_DATE({
            signerId: this.signerId,
            identificationExpiryDate: this.$options.filters.momentjsFormat(
              value,
              'YYYY-MM-DDTHH:mm:ss',
              'L'
            ),
          });
        },
      },
      taxNumber: {
        get() {
          return this.signers[this.signerId].taxNumber;
        },
        set(value) {
          this.SET_TAX_NUMBER({ signerId: this.signerId, taxNumber: value });
        },
      },
      duplicatedTaxNumberMessage() {
        if (
          !this.areSignersTaxNumberUnique ||
          this.personTaxNumberEqualsCompanyTaxNumber(this.signerId)
        ) {
          return this.$t('forms.errors.PersonTaxNumberNotUnique');
        } else {
          return null;
        }
      },
    },
    methods: {
      ...mapMutations('signers', [
        'SET_TAX_NUMBER',
        'SET_IDENTIFICATION_ID_TYPE',
        'SET_IDENTIFICATION_ID_NUMBER',
        'SET_IDENTIFICATION_EXPIRY_DATE',
        'SET_IDENTIFICATION_ISSUING_DATE',
        'SET_IDENTIFICATION_PLACE_OF_ISSUE',
        'SET_IDENTIFICATION_ISSUING_AUTHORITY',
      ]),
      getError(field) {
        return (
          this.errors[this.signerId] &&
          this.errors[this.signerId][field] &&
          this.$t('forms.errors.' + this.errors[this.signerId][field])
        );
      },
      displayError() {
        return this.submitClicked || this.signers[this.signerId].submitClicked;
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '@/sass/components/common/ing.orange.juice';
  .identity-details {
    .radio-group {
      padding-bottom: 1rem;
    }
    .passport-hint {
      margin-bottom: 1rem;
      font-weight: 400;
      font-size: 1rem;

      .warning-icon {
        position: relative;
        top: 2px;
      }
    }
  }
</style>
