import localStorage from '@/common/localStorage';

export const enableGTM = {
  beforeMount() {
    if (this.$gtm) {
      this.$gtm.enable(true);
    }
  },

  mounted() {
    if (this.$gtm) {
      const locale = localStorage.getItem('locale');
      this.$gtm.trackEvent({
        event: 'page',
        pageType: this.$route.path,
        lang: locale,
      });
      this.$gtm.trackEvent({
        event: 'optimize.activate',
        pageType: this.$route.path,
        lang: locale,
      });
    }
  },
};
