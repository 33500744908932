<template>
  <nav class="my-4">
    <ul v-if="lastPage() > 1" class="pagination pagination-circle pg-blue mb-0">
      <!--First-->
      <li
        v-if="showFirst()"
        :class="{ disabled: currentPage() <= 1 }"
        class="page-item"
      >
        <a href="javascript:void(0)" class="page-link" @click="changePage(1)">{{
          $t('pagination.first')
        }}</a>
      </li>

      <!--Arrow left-->
      <li
        v-if="showPrevious()"
        :class="{ disabled: currentPage() <= 1 }"
        class="page-item"
      >
        <a
          href="javascript:void(0)"
          class="page-link"
          aria-label="$t('pagination.previous')"
          @click="changePage(currentPage() - 1)"
        >
          <span aria-hidden="true"> &laquo; </span>
          <span class="sr-only">{{ $t('pagination.previous') }}</span></a
        >
      </li>

      <!--Numbers-->
      <li
        v-for="(page, index) in pages"
        :key="index"
        :class="{ active: page === currentPage() }"
      >
        <a
          href="javascript:void(0)"
          class="page-link"
          @click="changePage(page)"
          >{{ page }}</a
        >
      </li>

      <!--Arrow right-->
      <li
        v-if="showNext()"
        :class="{ disabled: currentPage() >= lastPage() }"
        class="page-item"
      >
        <a
          href="javascript:void(0)"
          class="page-link"
          aria-label="$t('pagination.next')"
          @click="changePage(currentPage() + 1)"
        >
          <span aria-hidden="true"> &raquo; </span>
          <span class="sr-only">{{ $t('pagination.next') }}</span></a
        >
      </li>

      <!--Last-->
      <li
        v-if="showLast()"
        :class="{ disabled: currentPage() >= lastPage() }"
        class="page-item"
      >
        <a
          href="javascript:void(0)"
          class="page-link"
          @click="changePage(lastPage())"
          >{{ $t('pagination.last') }}</a
        >
      </li>
    </ul>
  </nav>
</template>
<script>
  export default {
    props: {
      pagination: {
        type: Object,
        required: true,
      },
      callback: {
        type: Function,
        required: true,
      },
    },
    computed: {
      pages() {
        if (this.pagination.total === 0) {
          return [];
        }
        let from = this.currentPage() - this.config.offset;
        if (from < 1) {
          from = 1;
        }
        let to = from + this.config.offset * 2;
        if (to >= this.lastPage()) {
          to = this.lastPage();
        }
        const arr = [];
        while (from <= to) {
          arr.push(from);
          from += 1;
        }
        return arr;
      },
      config() {
        return Object.assign(
          {
            offset: 3,
            alwaysShowPrevNext: false,
            alwaysShowFirstLast: false,
          },
          this.pagination
        );
      },
    },
    methods: {
      showPrevious() {
        return this.config.alwaysShowPrevNext || this.currentPage() > 1;
      },
      showNext() {
        return (
          this.config.alwaysShowPrevNext || this.currentPage() < this.lastPage()
        );
      },
      showFirst() {
        return this.config.alwaysShowFirstLast || this.currentPage() > 1;
      },
      showLast() {
        return (
          this.config.alwaysShowFirstLast ||
          this.currentPage() < this.lastPage()
        );
      },
      currentPage() {
        if (this.pagination.skip === 0) {
          return 1;
        }

        return this.pagination.skip / this.pagination.count + 1;
      },
      lastPage() {
        if (this.pagination.total === 0) {
          return 0;
        }

        return Math.ceil(this.pagination.total / this.pagination.count);
      },
      changePage(page) {
        if (page < 1) {
          page = 1;
        }
        if (page > this.lastPage()) {
          page = this.lastPage();
        }

        if (this.currentPage() === page) {
          return;
        }

        this.$set(this.pagination, 'skip', (page - 1) * this.pagination.count);
        this.callback(this.pagination.skip, this.pagination.count);
      },
    },
  };
</script>
<style>
  .pagination > li > a,
  .pagination > li > span {
    color: #29bdba;
    border: none !important;
  }

  .pagination > .active > a,
  .pagination > .active > a:focus,
  .pagination > .active > a:hover,
  .pagination > .active > span,
  .pagination > .active > span:focus,
  .pagination > .active > span:hover {
    background-color: #29bdba;
    border-color: #29bdba;
  }

  .pagination > li > a:focus,
  .pagination > li > a:hover,
  .pagination > li > span:focus,
  .pagination > li > span:hover {
    color: #00667b;
    background-color: transparent !important;
    border-color: transparent !important;
  }

  .pagination > .disabled > a,
  .pagination > .disabled > a:focus,
  .pagination > .disabled > a:hover,
  .pagination > .disabled > span,
  .pagination > .disabled > span:focus,
  .pagination > .disabled > span:hover {
    color: #00667b;
  }
</style>
