<template>
  <div class="ing-text-input">
    <label v-if="label" class="ing-text-input__label" v-html="label" />
    <div v-if="caption" class="ing-text-input__caption" v-html="caption" />
    <input
      ref="date-input"
      class="ing-text-input__element"
      :class="[
        inputSize ? 'ing-text-input__element--' + inputSize : '',
        { 'ing-text-input__element--error': displayError },
      ]"
      :disabled="disabled"
      inputmode="numeric"
      data-lpignore="true"
      autocomplete="nofill"
      v-bind="$attrs"
      @focus="onFocus"
      @blur="onBlur"
    />
    <FieldStatus v-if="displayError">
      <template #error>
        <slot name="error">
          {{ error }}
        </slot>
      </template>
    </FieldStatus>
    <FieldStatus v-if="displayInfo">
      <template #info>
        <slot name="info">
          {{ info }}
        </slot>
      </template>
    </FieldStatus>
  </div>
</template>

<script>
  import IMask, { MaskedRange } from 'imask';
  import FieldStatus from '@/components/ingOrangeJuice/Base/FieldStatus';
  import DATE_FORMAT from '@/constants/DATE_FORMAT';

  export default {
    name: 'DateInput',
    components: { FieldStatus },
    inheritAttrs: false,
    props: {
      value: {
        type: String,
        default: '',
      },
      label: {
        type: String,
        default: '',
      },
      caption: {
        type: String,
        default: '',
      },
      maskFormat: {
        type: String,
        default: DATE_FORMAT.FULL_DATE,
      },
      maxYear: {
        type: Number,
        required: false,
        default: 2999,
      },
      minYear: {
        type: Number,
        required: false,
        default: 1900,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      inputSize: {
        type: String,
        default: 's',
      },
      info: {
        type: String,
        default: null,
      },
      error: {
        type: String,
        default: null,
      },
      name: {
        type: String,
        default: '',
      },
      displayErrorIfDirty: {
        type: Boolean,
        default: true,
      },
      longErrorMessage: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        isFocused: false,
        isDirty: false,
        maskRef: null,
      };
    },
    computed: {
      hasError() {
        const error = this.$slots.error || this.error;
        return !!error && error.length > 0;
      },
      displayInfo() {
        return this.$slots.info || this.info;
      },
      displayError() {
        const result = this.hasError && !this.isFocused;
        if (this.displayErrorIfDirty) {
          return this.isDirty && result;
        }
        return result;
      },
    },
    watch: {
      value(newVal) {
        if (newVal !== this.maskRef.value) {
          this._updateValue();
        }
      },
    },
    mounted() {
      this.maskOptions = {
        mask: this.maskFormat,
        lazy: false,
        overwrite: true,
        autofix: true,
        blocks: {
          TT: {
            mask: MaskedRange,
            placeholderChar: 'T',
            from: 0,
            to: 39,
            maxLength: 2,
          },
          MM: {
            mask: MaskedRange,
            placeholderChar: 'M',
            from: 0,
            to: 19,
            maxLength: 2,
          },
          JJJJ: {
            mask: MaskedRange,
            placeholderChar: 'J',
            from: this.minYear,
            to: this.maxYear,
            maxLength: 4,
          },
        },
      };

      if (this.maskFormat === DATE_FORMAT.MONTH_AND_YEAR) {
        delete this.maskOptions.blocks.TT;
      }

      this._initMask();
    },
    beforeDestroy() {
      //TODO: find a way to properly destroy the input, "destroy" is not a valid function
      this.maskRef = null;
    },
    methods: {
      _initMask() {
        this.maskRef = new IMask(this.$refs['date-input'], this.maskOptions).on(
          'accept',
          this.onAccept.bind(this)
        );
        this._updateValue();
      },
      onAccept() {
        this.$emit('input', this.maskRef.value);
      },
      _updateValue() {
        this.maskRef.value = this.value == null ? '' : this.value;
      },
      onFocus() {
        this.isFocused = true;
        this.isDirty = true;

        this.$emit('focus');
      },
      onBlur() {
        setTimeout(() => {
          this.isFocused = false;
          this.$emit('blur');
        }, 200); // On some browsers (Chrome macOS) it's impossible to click on suggestions without this
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/components/common/ing.orange.juice';
  .ing-text-input {
    position: relative;
    align-items: center;
    width: 100%;
    font-family: inherit;
    padding-bottom: px2rem(33px);
    &__element {
      font-family: inherit;
      font-weight: bold;
      background: $Primary-white;
      padding: 8px 12px;
      border: 1px solid $Primary-Grey400;
      box-shadow: inset 0 2px 2px $Primary-Grey200;
      border-radius: 4px;
      width: 100%;
      outline: none;
      box-sizing: border-box;
      color: $Primary-Grey600;
      height: px2rem(40px);
      font-size: px2rem(16px);
      line-height: px2rem(24px);
      &:focus {
        border: 3px solid $Secondary-Indigo500;
        padding: 6px 10px;
      }
      &:disabled {
        border: 1px solid $Primary-Grey200;
        background: $Primary-Grey100;
      }
      &--textarea {
        height: px2rem(80px);
      }
      &--error {
        border: 3px solid $Functional-Minus500;
        padding: 6px 10px;
      }
      &--xs {
        width: 96px;
      }
      &--s {
        width: 120px;
      }
    }
    &__label {
      display: block;
      font-family: inherit;
      color: $Primary-Grey600;
      font-weight: 400;
      font-size: px2rem(16px);
      line-height: px2rem(24px);
    }
    &__caption {
      font-family: inherit;
      font-size: 14px;
      line-height: 20px;
      font-weight: normal;
      margin-bottom: 4px;
      color: $Primary-Grey400;
      text-align: left;
      width: 100%;
    }
  }
</style>
