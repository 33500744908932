<template>
  <len-country-input
    :name="'nationality-' + signerId"
    :label="$t('forms.signupForm.nationality.label')"
    :initialCountry="signers[signerId].nationality"
    :error="
      errors[signerId] &&
      errors[signerId].nationality &&
      $t('forms.errors.' + errors[signerId].nationality)
    "
    :displayErrorIfDirty="!submitClicked && !signers[signerId].submitClicked"
    :preferredCountries="['DE']"
    @change="onChange"
  />
</template>
<script>
  import { mapState, mapMutations } from 'vuex';
  import LenCountryInput from '@/components/common/len-country-input';

  export default {
    components: { LenCountryInput },
    props: {
      signerId: {
        type: String,
        default: '',
      },
    },
    computed: {
      ...mapState('signers', ['errors', 'submitClicked', 'signers']),
    },
    methods: {
      onChange(iso) {
        this.SET_NATIONALITY({ signerId: this.signerId, nationality: iso });
      },
      ...mapMutations('signers', ['SET_NATIONALITY']),
    },
  };
</script>
