<template>
  <div>
    <ing-submit-header />
    <div ref="terms-and-conditions">
      <ing-terms-and-conditions />
    </div>
    <ing-privacy-policy />
    <ing-submit-button />
    <ing-advantages />
  </div>
</template>

<script>
  import IngSubmitHeader from '@/components/ing/forms/ing-submit-form/ing-submit-header';
  import IngPrivacyPolicy from '@/components/ing/forms/ing-submit-form/ing-privacy-policy';
  import IngAdvantages from '@/components/ing/forms/ing-submit-form/ing-advantages';
  import IngTermsAndConditions from '@/components/ing/forms/ing-submit-form/ing-terms-and-conditions';
  import IngSubmitButton from '@/components/ing/forms/ing-submit-form/ing-submit-button';
  import { goToElement } from '@/mixins/scrollMixin';
  export default {
    components: {
      IngSubmitHeader,
      IngPrivacyPolicy,
      IngAdvantages,
      IngTermsAndConditions,
      IngSubmitButton,
    },
    mixins: [goToElement],
    methods: {
      goTo() {
        this.goToElement('terms-and-conditions');
      },
    },
  };
</script>
