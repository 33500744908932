<template>
  <div
    :class="{ alert: isInvalid }"
    class="overview-container --no-padding flex--column"
  >
    <div class="overview-container__subsection">
      <div class="overview__loan-owner-button-container">
        <div class="overview__loan-owner-container col-lg-7 col-sm-10">
          <h3>
            {{
              $t(
                'signersMissingInfo.formPage.companySection.loanOwnerCompany.h3'
              )
            }}
          </h3>
          <div class="row">
            <p class="field-label col-sm-4">
              <em>{{ $t('forms.signupForm.companyName.label') }}:</em>
              <ing-bs-tooltip
                :tooltipMessage="$t('forms.signupForm.companyName.tooltip')"
              />
            </p>
            <p class="field-value col-sm-8">
              {{ company.companyName | emptyValueReplacer }}
            </p>
          </div>
          <div class="row">
            <p class="field-label col-sm-4">
              <em>{{ $t('forms.signupForm.companyTaxNumber.label') }}:</em>
              <ing-bs-tooltip
                key="tax-number-tooltip"
                :tooltipMessage="
                  $t('forms.signupForm.companyTaxNumber.tooltip')
                "
              />
            </p>
            <p class="field-value col-sm-8">
              {{ company.companyTaxNumber | emptyValueReplacer }}
            </p>
          </div>
          <div data-unit-test="ing-trading-name-overview" class="row">
            <p class="field-label col-sm-4">
              <em>{{ $t('forms.signupForm.tradingName.label') }}:</em>
              <ing-bs-tooltip
                key="trading-name-tooltip"
                :tooltipMessage="$t('forms.signupForm.tradingName.tooltip')"
              />
            </p>
            <p class="field-value col-sm-8">
              {{ company.companyTradingName | emptyValueReplacer }}
            </p>
          </div>
          <ing-regular-loan-request-overview v-show="isRegularLoanRequest" />
          <div class="row">
            <p class="field-label col-sm-4">
              <em>{{ $t('forms.signupForm.businessAddress.label') }}:</em>
            </p>
            <p class="field-value col-sm-8">
              {{ currentAddress | emptyValueReplacer }}
            </p>
          </div>
          <div class="row">
            <p class="field-label col-sm-4">
              <em>{{ $t('forms.signupForm.postalAddress.label') }}:</em>
            </p>
            <p class="field-value col-sm-8">
              {{ postalAddress | emptyValueReplacer }}
            </p>
          </div>
        </div>
        <div>
          <ing-edit-button
            class="edit-button-alignment"
            @edit-button-clicked="$emit('edit-button-clicked')"
          />
        </div>
      </div>
    </div>
    <div v-if="isTestAndLearnEnabled" class="overview-container__subsection">
      <h3>
        {{ $t('forms.signupForm.ingAccountDetails.header') }}
      </h3>
      <p class="overview__iban-subtitle">
        {{ $t('forms.signupForm.iban.tooltip') }}
      </p>
      <div class="col-lg-7 col-sm-10">
        <div class="row">
          <p class="field-label col-sm-4">
            <em>{{ $t('forms.signupForm.iban.label') }}:</em>
          </p>
          <p class="field-value col-sm-8">
            {{ iban | emptyValueReplacer }}
          </p>
        </div>
        <div class="row">
          <p class="field-label col-sm-4">
            <em>{{ $t('forms.signupForm.bic.label') }}:</em>
          </p>
          <p class="field-value col-sm-8">
            {{ company.bic | emptyValueReplacer }}
          </p>
        </div>
      </div>
    </div>
    <div class="overview-container__subsection">
      <ing-controlling-company-overview v-if="hasControllingCompany" />
      <ing-cash-intensity-title />
      <p>{{ cashIntensityValue | emptyValueReplacer }}</p>
      <ing-online-revenue-title
        v-if="isTestAndLearnEnabled"
        class="online-revenue-section"
      />
      <p v-if="isTestAndLearnEnabled">
        {{ onlineRevenueValue | emptyValueReplacer }}
      </p>
    </div>
    <div
      v-if="isTestAndLearnEnabled"
      class="overview-container__subsection dark-background"
    >
      <ing-sme-classification-overview />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'vuex';
  import IngCashIntensityTitle from '@/components/ing/signers/ing-signers-company-information/ing-cash-intensity-title';
  import IngOnlineRevenueTitle from '@/components/ing/signers/ing-signers-company-information/ing-online-revenue-title';
  import IngRegularLoanRequestOverview from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-overview/ing-regular-loan-request-overview';
  import IngControllingCompanyOverview from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-overview/ing-controlling-company-overview';
  import IngSmeClassificationOverview from '@/components/ing/signers/ing-signers-company-information/ing-signers-company-overview/ing-sme-classification-overview';
  import IngEditButton from '@/components/ing/signers/ing-edit-button';
  import { cashIntensityValues } from '@/validators/helpers/cashIntensity';
  import { onlineRevenueValues } from '@/validators/helpers/onlineRevenue';
  import {
    isRegularLoanRequest,
    hasControllingCompany,
    isTestAndLearnEnabled,
  } from '@/mixins/signersMixin';
  import IngBsTooltip from '@/components/ing/ing-bs-tooltip';

  export default {
    components: {
      IngBsTooltip,
      IngCashIntensityTitle,
      IngOnlineRevenueTitle,
      IngEditButton,
      IngRegularLoanRequestOverview,
      IngControllingCompanyOverview,
      IngSmeClassificationOverview,
    },
    mixins: [
      isRegularLoanRequest,
      hasControllingCompany,
      isTestAndLearnEnabled,
    ],
    props: {
      isInvalid: {
        type: Boolean,
      },
    },
    data() {
      return {
        cashIntensityValues,
        onlineRevenueValues,
      };
    },
    computed: {
      ...mapState('signers', ['company']),
      currentAddress() {
        if (this.company.street) {
          return `${this.company.street} ${this.company.streetNumber}, ${this.company.zip} ${this.company.city}, ${this.company.country}`;
        } else {
          return null;
        }
      },
      postalAddress() {
        if (this.company.postalStreet) {
          return `${this.company.postalStreet} ${this.company.postalStreetNumber}, ${this.company.postalZip} ${this.company.postalCity}, ${this.company.postalCountry}`;
        } else if (this.company.businessAddressIsPostalAddress) {
          return this.currentAddress;
        } else {
          return null;
        }
      },
      iban() {
        if (this.company.iban) {
          return this.company.iban.match(/.{1,4}/g).join(' ');
        } else {
          return null;
        }
      },
      cashIntensityValue() {
        return this.$t(
          `forms.signupForm.cashIntensity.options.${this.company.cashIntensity}`
        );
      },
      onlineRevenueValue() {
        const revenueOption = this.company.onlineRevenuesAtLeast75Percent
          ? 'yes'
          : 'no';
        return this.$t(
          'forms.signupForm.onlineRevenuesAtLeast75Percent.dropdown.' +
            revenueOption
        );
      },
    },
  };
</script>

<style scoped lang="scss">
  @import '@/sass/custom/_variables.scss';
  .online-revenue-section {
    margin-top: 40px;
  }
  .overview__loan-owner-button-container {
    display: flex;
    justify-content: space-between;
  }
  .overview-container__subsection {
    padding: 20px 40px;
    &:first-child {
      padding-top: 40px;
    }
    &:last-child {
      padding-bottom: 40px;
    }
    &.dark-background {
      padding-top: 40px;
      background-color: $ing-signer-section-background;
    }
  }

  .edit-button-alignment {
    margin-left: auto;
  }
  .flex--column {
    flex-direction: column;
  }
  .overview__iban-subtitle {
    padding-bottom: 16px;
  }
  p {
    font-size: 14px;
    line-height: 21px;
  }
  .separator {
    border: 0.5px solid $ing-section-gray;
    width: 90%;
    margin: 20px 40px;
  }
  .overview-container {
    &.--no-padding {
      padding: 0;
    }
    &.alert {
      border: solid 1px $ing-red;
    }
  }
  @media (max-width: 990px) {
    .overview__loan-owner-button-container {
      flex-wrap: wrap;
      word-break: break-all;
    }
    .overview__loan-owner-container {
      flex: 1 0 33%;
    }
    .edit-button-alignment {
      flex-basis: 100%;
      padding: 20px 0;
    }
  }
  @media (max-width: 476px) {
    .overview__loan-owner-button-container {
      flex-direction: column-reverse;
    }
  }
</style>
