import { isEmpty, matchesStreetNumberPattern } from '../helpers/string';
export default function ValidateStreetNumber(postalStreetNumber) {
  if (isEmpty(postalStreetNumber)) {
    return { postalStreetNumber: 'PostalStreetNumberIsRequired' };
  }
  if (!matchesStreetNumberPattern(postalStreetNumber)) {
    return { postalStreetNumber: 'PostalStreetNumberIsIncorrect' };
  }

  return { postalStreetNumber: undefined };
}
