import '@/libs/bootstrap-dropdown';
import '@/libs/bootstrap-tooltip';
import '@/sass/screen.scss';
import BootstrapMaterialDesign from '@/libs/bootstrap-material-design';
import 'babel-polyfill';
import Vue from 'vue';
import IsInVueport from '@/libs/is-in-vueport';
import i18n from '@/i18n';
// import Raven from 'raven-js';
// import RavenVue from 'raven-js/plugins/vue';
import LoadScript from '@/plugins/loadScript';
import Meta from 'vue-meta';
import App from '@/components/app.vue';
import store from '@/vuex';
import { createRouter } from '@/router';
import directives from '@/directives';
import modal from '@/common/modal';
import LenFooter from '@/components/footer/footer';
import LenHeaderBigLogo from '@/components/common/len-header-big-logo';
import filters from './filters';
import commonComponents from '@/components/common';

// TODO: Migrate to Datadog - as the following doesn't work
// apparently this doesn't work as there is no SENTRY_API in our env vars
// if (process.env.SENTRY_API) {
//   Raven.config(process.env.SENTRY_API)
//     .addPlugin(RavenVue, Vue)
//     .install();
// }

Vue.config.ignoredElements = [
  'lendico-ing-loan-calculator',
  'lendico-loan-calculator',
  'lendico-wcl-loan-calculator',
  'lendico-ing-omni-loan-calculator',
  'ing-cc-manager',
];
Vue.use(LoadScript);
Vue.use(filters);
Vue.use(commonComponents);
Vue.use(directives);
Vue.use(modal);
Vue.use(BootstrapMaterialDesign);
Vue.use(IsInVueport);

Vue.component('LenHeaderBigLogo', LenHeaderBigLogo);
Vue.component('LenFooter', LenFooter);

Vue.use(Meta);

Vue.config.performance = true;

// set translation constant values for each locale in a global property
// https://v2.vuejs.org/v2/cookbook/adding-instance-properties.html?redirect=true#Base-Example
Object.defineProperty(Vue.prototype, '$translationConstantValues', {
  get() {
    let script;
    try {
      script =
        require(`@/constants/translationConstantValues/${this.$i18n.locale}`).default;
    } catch (ex) {
      // fallback to German
      script = require(`@/constants/translationConstantValues/de`).default;
    }

    return script;
  },
});

export function createApp() {
  const router = createRouter();
  const app = new Vue({
    router,
    store,
    i18n,
    ...App,
    render: (h) => h(App),
  });
  return { app, router, store };
}
