<template>
  <form
    class="signup-form-container"
    name="registration"
    autocomplete="off"
    @submit.prevent="submit"
  >
    <IngFormLoader key="partnerSubmissionLoader" storeModule="signupForm" />
    <PageHeader page="signup.application" />
    <PageSection class="section-form" sectionPadding="top-no">
      <template #section-content>
        <FormSectionHeader section="signup.application.form.creditSection" />
        <lendico-loan-calculator theme="ING" />
      </template>
    </PageSection>
    <PageSection class="section-form" :darkTheme="true">
      <template #section-content>
        <FormSectionHeader
          section="signup.application.form.companySection"
          :paraCount="2"
        />
        <IngCompanyInformation ref="company-information" class="section-form" />
      </template>
    </PageSection>
    <PageSection class="section-form">
      <template #section-content>
        <FormSectionHeader
          section="signup.application.form.representativeSection"
          :paraCount="2"
        />
        <IngRepresentativeInformation
          ref="representative-information"
          class="section-form"
        />
      </template>
    </PageSection>
    <PageSection class="section-form" :darkTheme="true">
      <template #section-content>
        <FormSectionHeader
          section="signup.application.form.termsSection"
          :paraCount="0"
        >
          <template #form-content>
            <IngBorrowerTermsAndConditions />
          </template>
        </FormSectionHeader>
        <IngSubmitForm />
      </template>
    </PageSection>
    <div />
    <input
      autocomplete="false"
      name="hidden"
      type="text"
      style="display: none"
    />
    <IngFinanceAdsImage v-if="displayImage" :orderId="orderId" />
  </form>
</template>
<script>
  import { mapState, mapActions, mapMutations, mapGetters } from 'vuex';
  import { webtrekkMixin } from '@/mixins/webtrekkMixin';
  import {
    setAmountAndLength,
    utmParamsExtraction,
    mediaCodesExtraction,
    wtCookieRemove,
  } from '@/mixins/forms/formSubmissionMixin';
  import { routerPushByName } from '@/mixins/routerMixin';
  import cookie from '@/common/cookie';
  import {
    TRACK_EVENTS,
    TRACK_PRODUCT_NAME,
    TRACK_PRODUCT_STATUS,
    TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
  } from '@/constants/WEBTREKK';

  import PageSection from '@/components/ingOrangeJuice/Structure/PageSection';
  import PageHeader from '@/components/ingOrangeJuice/Structure/PageHeader';
  import FormSectionHeader from '@/components/ingOrangeJuice/Structure/FormSectionHeader';
  import IngCompanyInformation from '@/components/common/forms/IngCompanyInformation';
  import IngRepresentativeInformation from '@/components/common/forms/IngRepresentativeInformation';
  import IngSubmitForm from '@/components/common/forms/IngSubmitForm';
  import IngBorrowerTermsAndConditions from '@/components/borrower/forms/IngBorrowerTermsAndConditions';
  import IngFormLoader from '@/components/common/forms/ing-form-loader';
  import { calculatorLoaderMixin } from '@/mixins/calculatorLoaderMixin';
  import IngFinanceAdsImage from '@/components/common/IngFinanceAdsImage';
  export default {
    name: 'IngBorrowerSignupForm',
    components: {
      IngFormLoader,
      PageHeader,
      FormSectionHeader,
      PageSection,
      IngCompanyInformation,
      IngRepresentativeInformation,
      IngSubmitForm,
      IngBorrowerTermsAndConditions,
      IngFinanceAdsImage,
    },
    mixins: [
      mediaCodesExtraction,
      utmParamsExtraction,
      wtCookieRemove,
      setAmountAndLength,
      routerPushByName,
      webtrekkMixin,
      calculatorLoaderMixin,
    ],
    data() {
      return {
        displayImage: false,
        orderId: 0,
      };
    },
    computed: {
      ...mapState('signupForm', ['submitter']),
      ...mapGetters('signupForm', ['isValid', 'getProspectId']),
    },
    beforeMount() {
      this.loadCalculator();
    },
    mounted() {
      this.SET_SUBMITTER('applicant');
      this.trackEvent(
        TRACK_EVENTS.BRIDGE_APPLICATION,
        TRACK_PRODUCT_NAME.BRIDGE_APPLICATION,
        TRACK_PRODUCT_STATUS.ADD
      );
    },
    methods: {
      ...mapActions('signupForm', ['signUp']),
      ...mapMutations('signupForm', [
        'SET_GC_ID',
        'SET_SUBMIT_CLICKED',
        'SET_SUBMITTER',
      ]),
      async submit(event) {
        this.addExtraFields();
        this.SET_SUBMIT_CLICKED(true);
        if (!this.isValid) {
          return;
        }
        try {
          await this.signUp();

          this.displayImage = true;

          this.trackClick(event.submitter.attributes['wtlink'].value);
          this.routerPushByName('web-firmenkredit-danke');
          this.removeWTCookies();
          const { loanAmount } = this.$store.state.signupForm;
          this.orderId = this.getProspectId;
          this.trackEvent(
            TRACK_EVENTS.BRIDGE_APPLICATION_SUCCESS,
            TRACK_PRODUCT_NAME.BRIDGE_APPLICATION,
            TRACK_PRODUCT_STATUS.SUCCESS,
            {
              orderValue: loanAmount,
              orderId: this.orderId,
              ...TRACK_PRODUCT_CUSTOM_EC_PARAMETER,
            }
          );
          window.scrollTo(0, 0);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);
        }
      },
      addExtraFields() {
        this.setAmountAndLength();
        this.extractUTMParams();
        this.extractMediaCodes();
        this.SET_GC_ID(cookie.get('_ga'));
      },
    },
  };
</script>
