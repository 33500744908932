var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header-container"},[_c('img',{staticClass:"representative-icon-size",attrs:{"alt":"Representative Icon","src":"/ing_representative_icon.svg"}}),(_vm.isRegularLoanRequest)?_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t( 'signersMissingInfo.formPage.clientsSection.regularLoanRequest.title.h2' ))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          'signersMissingInfo.formPage.clientsSection.regularLoanRequest.title.p'
        )
      )}})]):_c('div',{staticClass:"text-container"},[_c('h2',[_vm._v(" "+_vm._s(_vm.$t( 'signersMissingInfo.formPage.clientsSection.microLoanRequest.title.h2' ))+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(
        _vm.$t(
          'signersMissingInfo.formPage.clientsSection.microLoanRequest.title.p'
        )
      )}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }