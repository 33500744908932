export default {
  computed: {
    listeners: function () {
      const vm = this;
      return {
        ...vm.$listeners,
        input: function (event) {
          vm.$emit('input', event.target.value);
        },
        change: function (event) {
          vm.$emit('change', event.target.value);
        },
      };
    },
  },
};
