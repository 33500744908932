<template>
  <IngOfferErrorPage
    errorPageKey="ingOfferExpiredErrorPage"
    imageSrc="/ing_event_busy.svg"
  />
</template>

<script>
  import IngOfferErrorPage from './ing-offer-error-page';

  export default {
    components: {
      IngOfferErrorPage,
    },
  };
</script>
